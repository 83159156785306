<template>
  <div class="user-img-status" @click.stop="readNotification()">
    <div class="online-status" v-if="item.online">
      <span></span>
    </div>
    <img :src="item.user_img ? '' : defaultImage" alt="" />
    <img
      v-if="item.massageFrom === 'facebook'"
      class="social-icon"
      src="@/assets/soft-ware-img/Component 30.svg"
      alt=""
    />
    <img
      v-else-if="item.massageFrom === 'instagram'"
      class="social-icon"
      src="@/assets/soft-ware-img/instgrapm.svg"
      alt=""
    />
    <img
      v-else-if="item.massageFrom === 'tiktok'"
      class="social-icon"
      src="@/assets/soft-ware-img/tikTopkcampaign.svg"
      alt=""
    />
    <img
      v-else-if="item.massageFrom === 'snapchat'"
      class="social-icon"
      src="@/assets/soft-ware-img/snapchat-icoon-ccampaign.svg"
      alt=""
    />
  </div>
  <div class="user-msg w-100" @click.stop="readNotification()">
    <p
      style="
        display: flex;
        flex-wrap: wrap;
        min-width: 100px;
        max-width: 100%;
        text-wrap: auto;
      "
    >
      {{
        item.message.message.length > 50
          ? item.message.message.substring(0, 30) + "..."
          : item.message.message
      }}
    </p>
    <span class="msg-text">{{ item.created_at }}</span>
    <button v-if="item.notificationType === 'comment'">
      <img src="@/assets/soft-ware-img/replay-msg.svg" alt="" />
      <span> {{ $t("replay") }} </span>
    </button>
  </div>

  <div>
    <v-menu :close-on-content-click="false">
      <template v-slot:activator="{ props }">
        <button v-bind="props">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#5f6368"
          >
            <path
              d="M479.79-192Q450-192 429-213.21t-21-51Q408-294 429.21-315t51-21Q510-336 531-314.79t21 51Q552-234 530.79-213t-51 21Zm0-216Q450-408 429-429.21t-21-51Q408-510 429.21-531t51-21Q510-552 531-530.79t21 51Q552-450 530.79-429t-51 21Zm0-216Q450-624 429-645.21t-21-51Q408-726 429.21-747t51-21Q510-768 531-746.79t21 51Q552-666 530.79-645t-51 21Z"
            />
          </svg>
        </button>
      </template>

      <v-list>
        <v-list-item
          v-if="item.read_at === null"
          @click="store.makeAsRead(item.id)"
        >
          <v-list-item-title>
            <img :src="checkRead" alt="" />
            <p>Make as read </p>
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-else @click="store.makeAsRead(item.id)">
          <v-list-item-title>
            <img :src="checkRead" alt="" />
            <p>Readed</p>
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="store.deleteNotification(item.id)">
          <v-list-item-title>
            <img :src="deleteIcon" alt="" />
            <p style="color: rgba(204, 0, 0, 1)">Delete</p>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { notification } from "@/store/notifications/notification";
import defaultImage from "@/assets/images/icon-512-512.png";
import deleteIcon from "@/assets/images/deleteIcon.svg";
import checkRead from "@/assets/images/checkRead.svg";
export default {
  props: ["item"],
  data() {
    return {
      defaultImage,
      deleteIcon,
      checkRead,
      store: notification(),
    };
  },
  methods: {
    readNotification() {
      this.$router.push({ name: "AllNotifications" });
    },
  },
};
</script>
<style scoped>
.show-hidden-massage:hover svg path {
  fill: #fff !important;
}
.show-hidden-massage:focus svg path {
  fill: #fff !important;
}
.advanced-filter-massage .v-list {
  width: 384px !important;
  padding: 20px 20px 30px 20px !important;
  top: 14px !important;
  min-height: 55vh !important;
}
.advanced-filter-massage a.see-all {
  color: #237276;
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-align: end;
  text-decoration: none;
  position: absolute;
  bottom: 8px;
  right: 20px;
}
.advanced-filter-massage .v-list h4 {
  font-weight: 700;
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
}
.advanced-filter-massage .v-list-item {
  padding: 5px !important;
}
.v-list-item:hover {
  border-radius: 4px !important;
}
.v-list-item-title {
  padding: 6px 0 0 2px;
}
.user-img-status {
  position: relative;
}
.user-img-status img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.user-img-status .online-status {
  width: 13px;
  height: 13px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: -1px;
  left: -1px;
  border: 2px solid #fff;
}
.user-img-status .online-status span {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #31a24c;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.user-img-status img.social-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  right: -3px;
}
.user-msg {
  line-height: normal;
}
.user-msg p {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.user-msg span.msg-text {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  display: block;
}
.user-msg button img {
  width: 16px;
  height: 16px;
}
.user-msg button {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 10px;
}
.user-msg button span {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.massage-icon span {
  background-color: #edf1f1;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.massage-icon span:hover {
  background-color: #1f666a;
}
.massage-icon span:hover svg path {
  fill: #e6e6e6;
}
</style>
