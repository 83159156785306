<template>
  <v-row>
    <v-col cols="12" md="8">
      <div class="contact-card">
        <div class="contact-title">{{ contact.first_name }}</div>
        <div class="d-flex flex-wrap align-center ga-3 data">
          <div class="element d-flex align-center ga-2">
            <span class="title">Location :</span><span class="value">{{ contact.country.name }} - {{ contact.city.name
            }}</span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Job Title :</span>
            <span class="value">{{ contact.job_title.name }}</span>
          </div>
        </div>
        <div class="d-flex flex-wrap align-center ga-3 data mt-4">
          <div class="element d-flex align-center ga-2">
            <span class="title">Potentiality :</span><span class="value">{{ contact.potentiality }}</span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Phone :</span>
            <span class="value"><a class="link-open" href="tel:{{ contact.phone }}">{{
              contact.phone
                }}</a></span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">E-mail :</span>
            <span class="value"><a class="link-open" href="mailto:{{ contact.email }}">{{
              contact.email
                }}</a></span>
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="4" class="">
      <div class="contact-card h-100">
        <div class="contact-title">Related Account</div>
        <div v-for="(account, index) in contact.account" :key="account?.id || index"
          class="d-flex justify-space-between align-center">
          <div class="d-flex ga-3 align-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_3379_27107)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.25533 2.18182V15.2727H1.16442C0.963589 15.2727 0.800781 15.4356 0.800781 15.6364C0.800781 15.8372 0.963589 16 1.16442 16H16.4371C16.6379 16 16.8008 15.8372 16.8008 15.6364C16.8008 15.4356 16.6379 15.2727 16.4371 15.2727H15.3462V2.18182C15.3462 0.976836 14.3694 0 13.1644 0H4.43714C3.23216 0 2.25533 0.976836 2.25533 2.18182ZM14.619 15.2727V2.18182C14.619 1.37849 13.9678 0.727273 13.1644 0.727273H4.43714C3.63382 0.727273 2.9826 1.37849 2.9826 2.18182V15.2727H5.89169V11.6364C5.89169 10.4313 6.86853 9.45455 8.07351 9.45455H9.52805C10.7331 9.45455 11.7099 10.4313 11.7099 11.6364V15.2727H14.619ZM10.9826 15.2727V11.6364C10.9826 10.833 10.3314 10.1818 9.52805 10.1818H8.07351C7.27018 10.1818 6.61896 10.833 6.61896 11.6364V15.2727H10.9826ZM5.16442 4C5.16442 3.79917 5.32722 3.63636 5.52805 3.63636H7.70987C7.91067 3.63636 8.07351 3.79917 8.07351 4C8.07351 4.20083 7.91067 4.36364 7.70987 4.36364H5.52805C5.32722 4.36364 5.16442 4.20083 5.16442 4ZM5.52805 6.54545C5.32722 6.54545 5.16442 6.70829 5.16442 6.90909C5.16442 7.10989 5.32722 7.27273 5.52805 7.27273H7.70987C7.91067 7.27273 8.07351 7.10989 8.07351 6.90909C8.07351 6.70829 7.91067 6.54545 7.70987 6.54545H5.52805ZM9.52805 4C9.52805 3.79917 9.69089 3.63636 9.89169 3.63636H12.0735C12.2743 3.63636 12.4371 3.79917 12.4371 4C12.4371 4.20083 12.2743 4.36364 12.0735 4.36364H9.89169C9.69089 4.36364 9.52805 4.20083 9.52805 4ZM9.89169 6.54545C9.69089 6.54545 9.52805 6.70829 9.52805 6.90909C9.52805 7.10989 9.69089 7.27273 9.89169 7.27273H12.0735C12.2743 7.27273 12.4371 7.10989 12.4371 6.90909C12.4371 6.70829 12.2743 6.54545 12.0735 6.54545H9.89169Z"
                  fill="#666666" />
              </g>
              <defs>
                <clipPath id="clip0_3379_27107">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>{{ account?.account_number }}</span>
          </div>
          <div v-if="account?.account_number" class="ml-auto eye">
            <svg @click="
              this.$router.push({
                name: 'ShowAccount',
                params: { id: account.id },
              })
              " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer">
              <path
                d="M15.8983 7.68858C15.7554 7.49304 12.3496 2.90088 7.99992 2.90088C3.65019 2.90088 0.244313 7.49304 0.101531 7.68839C-0.0338438 7.87389 -0.0338438 8.12548 0.101531 8.31098C0.244313 8.50652 3.65019 13.0987 7.99992 13.0987C12.3496 13.0987 15.7554 8.50648 15.8983 8.31114C16.0339 8.12567 16.0339 7.87389 15.8983 7.68858ZM7.99992 12.0437C4.79588 12.0437 2.02085 8.99583 1.19938 7.99942C2.01979 7.00214 4.78901 3.95582 7.99992 3.95582C11.2038 3.95582 13.9787 7.0032 14.8005 8.00014C13.9801 8.99739 11.2108 12.0437 7.99992 12.0437Z"
                fill="#666666" />
              <path
                d="M8.00079 4.83496C6.25572 4.83496 4.83594 6.25474 4.83594 7.99981C4.83594 9.74488 6.25572 11.1647 8.00079 11.1647C9.74585 11.1647 11.1656 9.74488 11.1656 7.99981C11.1656 6.25474 9.74585 4.83496 8.00079 4.83496ZM8.00079 10.1097C6.83735 10.1097 5.89091 9.16322 5.89091 7.99981C5.89091 6.8364 6.83738 5.88993 8.00079 5.88993C9.1642 5.88993 10.1107 6.8364 10.1107 7.99981C10.1107 9.16322 9.16423 10.1097 8.00079 10.1097Z"
                fill="#666666" />
            </svg>
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="12" class="">
      <div class="contact-card h-100">
        <div class="contact-title d-flex align-center justify-space-between">
          <span> Related Projects </span>
          <div v-if="hidden_projects_headers.length > 0">
            <v-btn id="show-hidden-columns-4" variant="text"
              class="show-hidden-columns pa-0  text-none text-subtitle-2 px-2">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6L8 10L12 6" stroke="#666666" stroke-width="1.6" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-columns-4">
              <v-list>
                <v-list-item v-for="(item, index) in hidden_projects_headers" :key="index" :value="index">
                  <v-list-item-title class="d-flex align-center ga-2"
                    @click="showHiddenRelatedProjectsColumn(item.key)">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3141_74103)">
                        <path
                          d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                          stroke="#666666" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3141_74103">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RelatedProject :removeSelect="true" :projects="contact.projects" />
      </div>
    </v-col>
    <v-col cols="12" md="12" class="">
      <div class="contact-card h-100">
        <div class="contact-title d-flex align-center justify-space-between">
          <span> Related Deals </span>
          <div v-if="hidden_deals_headers.length > 0">
            <v-btn id="show-hidden-related-deals-columns" variant="text"
              class=" text-none text-subtitle-2 px-2 show-hidden-columns pa-0">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6L8 10L12 6" stroke="#666666" stroke-width="1.6" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-related-deals-columns">
              <v-list>
                <v-list-item v-for="(item, index) in hidden_deals_headers" :key="index" :value="index">
                  <v-list-item-title class="d-flex align-center ga-2" @click="showHiddenDealsColumn(item.key)">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3141_74103)">
                        <path
                          d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                          stroke="#666666" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3141_74103">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RelatedDeals :removeSelect="true" :deals="contact.deals" />
      </div>
    </v-col>
    <v-col cols="12" md="12" class="">
      <div class="contact-card h-100">
        <div class="contact-title d-flex align-center justify-space-between">
          <span> Related Events </span>
          <div v-if="hidden_events_headers.length > 0">
            <v-btn id="show-hidden-related-event-columns" variant="text" class="text-none text-subtitle-2 px-2  show-hidden-columns pa-0">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6L8 10L12 6" stroke="#666666" stroke-width="1.6" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-related-event-columns">
              <v-list>
                <v-list-item v-for="(item, index) in hidden_events_headers" :key="index" :value="index">
                  <v-list-item-title class="d-flex align-center ga-2" @click="showHiddenEventsColumn(item.key)">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3141_74103)">
                        <path
                          d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                          stroke="#666666" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3141_74103">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RelatedEvents :removeSelect="true" :event="contact.events" />
      </div>
    </v-col>
    <v-col cols="12" md="12" class="">
      <div class="contact-card h-100">
        <div class="contact-title d-flex align-center justify-space-between">
          <span> Related Tasks </span>
          <div v-if="hidden_tasks_headers.length > 0">
            <v-btn id="show-hidden-related-tasks-columns" variant="text" class="text-none text-subtitle-2 px-2 show-hidden-columns pa-0">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6L8 10L12 6" stroke="#666666" stroke-width="1.6" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-related-tasks-columns">
              <v-list>
                <v-list-item v-for="(item, index) in hidden_tasks_headers" :key="index" :value="index">
                  <v-list-item-title class="d-flex align-center ga-2">
                    <div class="d-flex align-center ga-2" @click="showHiddenTasksColumn(item.key)">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3141_74103)">
                          <path
                            d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                            stroke="#666666" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath id="clip0_3141_74103">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      {{ item.title }}
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RelatedTasks :removeSelect="true" :tasks="contact.tasks" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import RelatedProject from "@/components/crm/projects/table.vue";
import RelatedDeals from "@/components/crm/deals/table.vue";
import RelatedEvents from "@/components/crm/events/table.vue";
import RelatedTasks from "@/components/crm/tasks/table.vue";
import { useContactsStore } from "@/store/crm/useContactsStore";
import { useProjectsStore } from "@/store/crm/useProjectsStore";
import { useDealsStore } from "@/store/crm/useDealsStore";
import { useEventsStore } from "@/store/crm/useEventsStore";
import { useTasksStore } from "@/store/crm/useTasksStore";

import apiClient from "@/core/buildRequest";

export default {
  name: "ContactCard",
  components: {
    RelatedProject,
    RelatedDeals,
    RelatedEvents,
    RelatedTasks,
  },
  data() {
    return {
      store: null,
      contact: {
        id: 1,
        contact_number: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        account: [
          {
            id: "",
            name: "",
            account_number: null,
          },
        ],
        city: {
          id: "0",
          name: "",
        },
        country: {
          id: "0",
          name: "",
        },
        job_title: {
          id: "0",
          name: "",
        },
        potentiality: "",
        projects: [],
        deals: [],
        events: [],
        tasks: [],
      },
      projects_store: null,
      deals_store: null,
      events_store: null,
    };
  },
  computed: {
    contacts() {
      return this.store?.contacts || [];
    },
    hidden_headers() {
      const headers = this.store?.headers.filter((header) => !header.show);
      return headers;
    },
    hidden_projects_headers() {
      const headers = this.projects_store?.headers.filter(
        (header) => !header.show
      );
      return headers;
    },
    hidden_deals_headers() {
      const headers = this.deals_store?.headers.filter(
        (header) => !header.show
      );
      return headers;
    },
    hidden_events_headers() {
      const headers = this.events_store?.headers.filter(
        (header) => !header.show
      );
      return headers;
    },
    hidden_tasks_headers() {
      const headers = this.tasks_store?.headers.filter(
        (header) => !header.show
      );
      return headers;
    },
  },
  mounted() {
    this.getContact();

  },
  created() {
    this.store = useContactsStore();
    this.projects_store = useProjectsStore();
    this.deals_store = useDealsStore();
    this.events_store = useEventsStore();
    this.tasks_store = useTasksStore();
  },
  methods: {
    async getContact() {
      await apiClient
        .get(`/leads-managment/contacts/${this.$route.params.id}`)
        .then((res) => {
          this.contact = res.data.data;
        });
    },
    showHiddenRelatedProjectsColumn(key) {
      const header = this.projects_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenColumn(key) {
      const header = this.store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenDealsColumn(key) {
      const header = this.deals_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenEventsColumn(key) {
      const header = this.events_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenTasksColumn(key) {
      const header = this.tasks_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
  },
};
</script>

<style scoped>
.contact-card {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px #dedede80;
}

.contact-card .data .element {
  height: 40px;
  padding: 0px 24px 0px 16px;
  background: #f7f8f8;
  border-radius: 8px;
}

.contact-card .data .element .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #0d0d0d;
}

.contact-card .data .element .value {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #666666;
}

.contact-title {
  color: #0d0d0d;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  margin-bottom: 30px;
}
</style>
