<template>
  <div class="boost-post-card duration-card">
    <div class="card-header mb-4">
      <label for="" class="title d-block">Duration</label>
    </div>
    <div class="card-body">
      <v-container class="ma-0 pa-0">
        <v-row class="mb-2">
          <v-col cols="12" md="6" sm="6" lg="6">
            <v-number-input
              :reverse="false"
              controlVariant="stacked"
              label="days"
              :hideInput="false"
              :inset="false"
              variant="solo"
              v-model="store.days"
              elevation="0"
              hide-details
            ></v-number-input>
          </v-col>
          <v-col cols="12" md="6" sm="6" lg="6">
            <v-date-input
              label="End Date"
              @update:modelValue="store.setEndDate"
              prepend-icon=""
              prepend-inner-icon="$calendar"
              variant="solo"
              v-model="store.endDate"
              elevation="0"
              hide-details
            ></v-date-input>
          </v-col>
        </v-row>
        <v-row class="range-header">
          <v-col cols="12">
            <label for="" class="d-block title">Budget</label>
          </v-col>
          <v-col cols="12">
            <p>
              Estimated
              <span>1.5K-4.3K Accounts Centre accounts</span> reached per day
            </p>
          </v-col>
        </v-row>
        <v-row class="range-view">
          <v-col class="d-flex align-center justify-center" cols="12">
            <div class="number-of-range">
              <p class="title">$ {{ range[1] }}</p>
              <button @click="dialog = true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20px"
                  viewBox="0 -960 960 960"
                  width="20px"
                  fill="rgba(0, 115, 221, 1)"
                >
                  <path
                    d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
                  />
                </svg>
              </button>

              <v-dialog v-model="dialog" width="auto" class="edit-budget">
                <v-card width="600">
                  <v-card-text>
                    <h4>Edit Budget</h4>
                    <input
                      class="w-100 no-controls"
                      type="number"
                      v-model="max_range"
                    />

                    <div class="d-flex justify-end footer my-4">
                      <button @click="dialog = false">save</button>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0">
          <v-col cols="12" class="px-0 select-range">
            <v-range-slider
              v-model="range"
              :max="max_range"
              :min="1"
              :step="1"
              class="align-center"
              hide-details
              color="primary"
            >
              <template v-slot:prepend>
                <v-text-field
                  v-model="range[0]"
                  density="compact"
                  style="width: 70px"
                  type="number"
                  variant="outlined"
                  hide-details
                  single-line
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model="max_range"
                  density="compact"
                  style="width: 70px"
                  type="number"
                  variant="outlined"
                  hide-details
                  single-line
                  @change="setMaxRange"
                ></v-text-field>
              </template>
            </v-range-slider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <EstimatedResults />
            <PaymentSummary />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { VNumberInput } from "vuetify/labs/VNumberInput";
import { boostPost } from "@/store/publishPost/boostPost";
import { VDateInput } from "vuetify/labs/VDateInput";
import EstimatedResults from "./EstimatedResults.vue";
import PaymentSummary from "./PaymentSummary.vue";

export default {
  name: "DurationsCom",
  components: { VNumberInput, VDateInput, EstimatedResults, PaymentSummary },
  data() {
    return {
      store: boostPost(),
      max_range: 300,
      range: [1, this.max_range],
      dialog: false,
    };
  },
  methods: {
    
  },
  watch: {
    range: function (newValue) {
      localStorage.setItem("max_range_life_time", newValue[1]);
    },
  },
};
</script>

<style scoped>
.v-field__input {
  padding: 0;
}
</style>
