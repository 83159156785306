import TeamIndex from "@/views/MyTeam/index.vue";
import AllRoles from "@/views/MyTeam/AllRoles.vue";
import AllUsers from "@/views/MyTeam/AllUsers.vue";
import NewRole from "@/views/MyTeam/NewRole.vue";
import EditRole from "@/views/MyTeam/EditRole.vue";
const TeamRoutes = [
  {
    path: "/my-team",
    name: "MyTeam",
    component: TeamIndex,
    meta: {
      title: "My Team",
      description: "View My Team",
      
      permissions: [
        "my-team.create",
        "my-team.read",
        "my-team.update",
        "my-team.delete",
      ],
    },
  },
  {
    path: "/all-roles",
    name: "AllRoles",
    component: AllRoles,
    meta: {
      title: "All Roles",
      description: "View All Roles",
      
      permissions: [
        "my-team.create",
        "my-team.read",
        "my-team.update",
        "my-team.delete",
      ],
    },
  },
  {
    path: "/all-users",
    name: "AllUsers",
    component: AllUsers,
    meta: {
      title: "All Users",
      description: "View All Users",
      
      permissions: [
        "my-team.create",
        "my-team.read",
        "my-team.update",
        "my-team.delete",
      ],
    },
  },
  {
    path: "/new-role",
    name: "NewRole",
    component: NewRole,
    meta: {
      title: "NewR ole",
      description: "Add New Role",
      
      permissions: [
        "my-team.create",
        "my-team.read",
        "my-team.update",
        "my-team.delete",
      ],
    },
  },
  {
    path: "/edit-role/:id",
    name: "EditRole",
    component: EditRole,
    meta: {
      title: "Edit Role",
      description: "EditRole",
      
      permissions: [
        "my-team.create",
        "my-team.read",
        "my-team.update",
        "my-team.delete",
      ],
    },
  },
];

export default TeamRoutes;
