<template>
  
  <v-container fluid class="main-container">
    <v-row class="w-100 h-100" no-gutters>
      <v-col cols="4" class="h-100 w-100">
        <div class="gif-container h-100 w-100">
          <div class="pa-5">
            <img :src="Logo" alt="PROMXA" />
          </div>

          <div class="content h-100 w-100">
            <!-- <img :src="LoginRotation" alt="PROMXA" /> -->
            <LoginImageRotation />
          </div>
        </div>
      </v-col>
      <v-col
        cols="8"
        class="h-100 w-100 d-flex align-center justify-center flex-column ga-3 login-form"
      >
        <form
          @submit.prevent="login"
          action=""
          class="d-flex align-start flex-column"
        >
          <div
            class="d-flex align-center justify-center flex-column w-100 ga-3"
          >
            <h1>{{ $t("welcom_back!") }}</h1>
            <p>{{ $t("please_login_to_your_promxa_account") }}</p>
          </div>
          <div class="d-flex flex-column w-100 ga-2">
            <label for="email"
              >{{ $t("email") }} <span class="error-text">*</span>
            </label>
            <input
              v-model="email"
              class="login-input"
              type="email"
              name="email"
              id="email"
            />
            <span
              v-if="errors.find((e) => e.key === 'email')"
              class="error-text"
            >
              {{ errors.find((e) => e.key === "email").value }}</span
            >
          </div>
          <div class="d-flex flex-column w-100 ga-2">
            <div class="d-flex align-center justify-space-between">
              <label for="password"
                >{{ $t("password") }} <span class="error-text">*</span></label
              >
              <label
                class="d-flex align-center ga-2 cursor-pointer"
                @click="() => (showPassword = !showPassword)"
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5679 4.56766L15.9546 3.95437C15.7812 3.78105 15.4613 3.80772 15.2613 4.04768L13.1278 6.16765C12.1677 5.75436 11.1145 5.55436 10.0078 5.55436C6.71433 5.56764 3.86118 7.48762 2.48775 10.2478C2.40773 10.4211 2.40773 10.6344 2.48775 10.781C3.1277 12.0877 4.08775 13.1678 5.28776 13.981L3.5411 15.7544C3.3411 15.9544 3.31442 16.2743 3.44779 16.4477L4.06108 17.061C4.23441 17.2343 4.5544 17.2076 4.7544 16.9677L16.461 5.26109C16.7143 5.0612 16.741 4.74123 16.5677 4.56789L16.5679 4.56766ZM10.7144 8.59423C10.4877 8.54088 10.2478 8.47425 10.0211 8.47425C8.88773 8.47425 7.98116 9.38094 7.98116 10.5142C7.98116 10.7409 8.03451 10.9808 8.10114 11.2075L7.20771 12.0875C6.94108 11.6209 6.79442 11.1008 6.79442 10.5142C6.79442 8.74091 8.22111 7.31422 9.99443 7.31422C10.5811 7.31422 11.1011 7.46088 11.5677 7.72751L10.7144 8.59423Z"
                    fill="#666666"
                    fill-opacity="0.8"
                  />
                  <path
                    d="M17.5279 10.2478C17.0613 9.31441 16.4479 8.47446 15.688 7.79443L13.2079 10.2478V10.5144C13.2079 12.2877 11.7812 13.7144 10.0079 13.7144H9.74129L8.16797 15.2877C8.75468 15.4077 9.36797 15.4877 9.96797 15.4877C13.2614 15.4877 16.1146 13.5677 17.488 10.7943C17.608 10.6076 17.608 10.421 17.5279 10.2476L17.5279 10.2478Z"
                    fill="#666666"
                    fill-opacity="0.8"
                  />
                </svg>
                <span> {{ showPassword ? "Hide" : "Show" }} </span>
              </label>
            </div>
            <input
              v-model="password"
              class="login-input"
              :type="showPassword ? 'text' : 'password'"
              name="password"
              id="password"
            />
            <span
              v-if="errors.find((e) => e.key === 'password')"
              class="error-text"
            >
              {{ errors.find((e) => e.key === "password").value }}</span
            >
          </div>
          <v-btn variant="text" type="submit" class="login-btn">{{
            $t("sign_in")
          }}</v-btn>
          <div
            v-if="loginForm?.getAccounts?.length > 0"
            class="d-flex justify-start align-start w-100 flex-column ga-8"
          >
            <label for=""> {{ $t("login_as") }} </label>
            <div class="d-flex align-center justify-start ga-3 accounts">
              <div
                v-for="account in loginForm?.getAccounts"
                :key="account.email"
                class="account d-flex flex-column ga-1 cursor-pointer"
                @click="LoginByClick(account.id)"
              >
                <div class="d-flex justify-end">
                  <svg
                    @click.stop="deleteOldAccount(account.id)"
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#666666"
                  >
                    <path
                      d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
                    />
                  </svg>
                </div>
                <div class="d-flex align-center ga-2 flex-column">
                  <img :src="account.image" :alt="account.name" />
                </div>
                <div class="d-flex align-center ga-1 flex-column">
                  <span class="account-name">{{ account.name }}</span>
                  <span class="account-login-time">{{
                    account?.last_active
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginBtn from "@/components/buttons/LoginBtn.vue";
import { loginForm } from "@/store/logIn";
import { useGeneralStore } from "@/store/General";
import LoginRotation from "@/assets/images/logoRotation.svg";
import Logo from "@/assets/logo.svg";
import LoginImageRotation from "@/views/LoginImageRotation.vue";
export default {
  components: {
    LoginBtn,
    LoginImageRotation,
  },
  name: "LoginPage",
  data() {
    return {
      LoginRotation,
      Logo,
      showPassword: false,
      generalStore: useGeneralStore(),
      errors: [],
      password: "",
      email: "",
      loginForm: loginForm(),
    };
  },
  watch: {
    password() {
      this.errors = this.errors.filter((e) => e.key !== "password");
    },
    email() {
      this.errors = this.errors.filter((e) => e.key !== "email");
    },
  },
  methods: {
    validation() {
      let errors = [];
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (this.email === "") {
        errors.push({
          key: "email",
          value: "Email is required",
        });
      } else if (!emailRegex.test(this.email)) {
        errors.push({
          key: "email",
          value: "Invalid email format",
        });
      }
      if (this.password === "") {
        errors.push({
          key: "password",
          value: "Password is required",
        });
      } else if (this.password.length < 6) {
        errors.push({
          key: "password",
          value: "Password must be at least 6 characters",
        });
      }
      this.errors = errors;
      return this.errors.length > 0 ? false : true;
    },
    login() {
      if (!this.validation()) {
        this.generalStore.showSnackbar(
          "Please fill all required fields",
          "error"
        );
        return;
      }
      this.loginForm.login(this.email, this.password);
    },
    LoginByClick(id) {
      this.loginForm.LoginByAccountId(id);
    },
    deleteOldAccount(id) {
      this.loginForm.deleteOldAccount(id);
    },
  },
  mounted() {
    this.loginForm.getAllAccounts();
  },
};
</script>
<style scoped>
.main-container {
  background: rgba(239, 248, 255, 1);
  min-height: 100vh;
}
.gif-container {
  position: relative;
  overflow: hidden;
}
.v-container {
  padding: 0px 0px 0px 0px !important;
}
:lang(ar) .gif-container .content {
  right: 0px;
  transform: translate(-50%, 20%);
  left: unset;
}
.gif-container .content {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(50%, 20%);
}
.login-form {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 30.9px 0px rgba(0, 0, 0, 0.12);
}
.login-btn {
  background: linear-gradient(90deg, #1f666a 0%, #3f878d 42%, #4f979f 100%);
  width: 100%;
  height: 52px;
  border-radius: 12px;
  color: white;
  transition: all 0.5s ease-in-out;
}
.login-btn:hover {
  background: linear-gradient(90deg, #174d50 0%, #2a5c61 42%, #326166 100%);
}
.login-form form {
  gap: 50px;
  width: 50%;
  padding: 20px;
}
.login-input {
  width: 100%;
  height: 52px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  transition: all 0.5s ease-in-out;
}
.login-input:focus-visible {
  outline: rgba(102, 102, 102, 1);
}
.login-input {
  padding: 0px 15px;
  color: rgba(102, 102, 102, 1);
}
.login-form form h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 38.73px;
  color: rgba(26, 26, 26, 1);
}
.login-form form p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0%;
  color: rgba(102, 102, 102, 1);
}
.accounts .account {
  width: 145px;
  height: 164px;
  border-radius: 9px;
  background: rgba(239, 248, 255, 1);
  padding: 10px;
  transition: all 0.5s ease-in-out;
}
.accounts .account:hover {
  background: rgba(217, 223, 228, 0.815);
}
.accounts .account img {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  object-fit: cover;
}
.account-name {
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
  color: rgba(0, 0, 0, 1);
}
.account-login-time {
  font-weight: 300;
  font-size: 13px;
  line-height: 19.5px;
  letter-spacing: 0%;
  color: rgba(173, 173, 173, 1);
}
.account svg {
  transition: all 0.5s ease-in-out;
}
.account svg:hover {
  fill: red;
}
label {
  text-transform: capitalize;
}
.error-text {
  font-size: 12px;
  color: red;
}
</style>
