<template>
    <div class="ad-details-traffic mb-3">
        <v-container fluid class="pa-0 ma-0">
            <v-row no-gutters class="position-relative">
                <v-col cols="7" class="bg-white pa-7 left-side ">
                    <div class="ad-details-title">
                        <h4>Ad details</h4>
                    </div>
                    <div class="ad-creative-title">
                        <h4 class=" title">Ad creative</h4>
                    </div>
                    <AdCreative />


                    <!-- v-if=" mediaStore.selectedMedia.length > 0" -->
                    <IdentityComponent />

                    <div class="post-text mb-3 mt-4">
                        <h4 class="mb-3 title">Text</h4>
                        <div :style="store.postText.length < 1 && store.postText.length < 100 ? 'border: 1px solid #ca242e' : 'border: 1px solid #d3d4d5'"
                            class="d-flex align-center justify-space-between input-contain mb-3">
                            <input type="text" v-model="store.postText" maxlength="100" placeholder="Enter ad text">
                            <span> {{ store.postText.length }} / 100</span>
                        </div>
                        <div class="alert" v-if="store.alert">
                            Text must be between 1 and 100 characters. (For Chinese, Japanese or Korean: 1-50 fullwidth
                            characters or 1- 100 halfwidth)
                        </div>
                        <button class="btn my-3 mb-5 smart-text">Smart Text</button>
                    </div>
                    <v-divider></v-divider>
                    <CallToAction />
                    <Destination />
                </v-col>
                <v-col cols="4" class="px-4">
                    <MediaPreview />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AdCreative from './AdCreative.vue';
import { adCreative } from '@/store/tiktokStore/adCreative';
import { uploadMedia } from '@/store/tiktokStore/uploadMedia';
import CallToAction from './CallToAction.vue';
import Destination from './Destination.vue';
import MediaPreview from './MediaPreview.vue';
import IdentityComponent from './Identity.vue';
export default {
    components: {
        AdCreative,
        CallToAction,
        Destination,
        MediaPreview,
        IdentityComponent
    },
    data() {
        return {
            store: null,
            mediaStore: uploadMedia()
        }
    },
    setup() {
        const store = adCreative();
        return { store };
    },
    watch: {
        'store.postText': function (newVal, oldVal) {
            if (newVal.length < 1 || newVal.length > 100) {
                this.store.alert = true;

            } else {
                this.store.alert = false;
            }
        }
    }
}
</script>