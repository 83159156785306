import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";
import deleteIcon from "@/assets/images/deleteIcon.svg";
import checkRead from "@/assets/images/checkRead.svg";
import { useGeneralStore } from "../General.js";

export const notification = defineStore("notification", {
  state: () => ({
    checkRead,
    deleteIcon,
    notificationsArr: [],
    items: [
      { id: 1, title: "Mark as read", icon: checkRead, value: "read" },
      { id: 2, title: "Delete", icon: deleteIcon, value: "delete" },
    ],
    activeTab: "all",
    store: useGeneralStore(),
    items_per_page: 5,
    current_page: 1,
    total_items: 0,
    total_pages: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    filtersBy: [],
    listNotifications: [],
    currentPage: 1,
  }),
  getters: {
    // getPagination: (state) => ({
    //   currentPage: state.current_page,
    //   totalPages: state.total_pages,
    //   itemsPerPage: state.items_per_page,
    //   totalContacts: state.total_items,
    // }),
  },
  actions: {
    // load({ side, done }) {
    //   setTimeout(() => {
    //     if (side === "start") {
    //       if (this.currentPage > 1) {
    //         this.currentPage = 1;
    //         this.notificationsArr = [];
    //         this.getNotifications(this.activeTab);
    //       }
    //       done("ok");
    //     } else if (side === "end") {
    //       this.currentPage++;
    //       if (this.currentPage <= this.total_pages) {
    //         this.getNotifications(this.activeTab);
    //         alert("i'm Down");
    //       }
    //       done("ok");
    //     }
    //   }, 1000);
    // },
    showMoreNotifications(){
      this.currentPage += 1;
      this.getNotifications(this.activeTab);
    },
    getNotifications(type) {
      this.activeTab = type;
      apiClient
        .get("/get/notifications", {
          params: {
            type: type,
            limit: 10,
            page: this.currentPage,
          },
        })
        .then((response) => {
          let res = response.data.data.notifications.data;
          this.total_pages =
            response.data.data.notifications.pagination.total_pages;
          this.notificationsArr = [...this.notificationsArr, ...res];
          this.current_page =
            response.data.data.notifications.pagination.current_page;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    makeAsRead(item) {
      apiClient
        .get(`/mark-as-read/${item}`)
        .then((response) => {
          if (response.data?.key === "success") {
            this.listNotifications.find((n) => n.id === item).read_at =
              new Date().toISOString();
            this.notificationsArr.find((n) => n.id === item).read_at =
              new Date().toISOString();
          }
        })
        .catch((error) => {
          console.error("Error marking as read:", error);
        });
    },
    markAllAsRead() {
      apiClient.get("/mark-all-as-read").then((response) => {
        if (response.data?.key === "success") {
          this.listNotifications.forEach((n) => {
            n.read_at = new Date().toISOString();
          });
          this.notificationsArr.forEach((n) => {
            n.read_at = new Date().toISOString();
          });
        }
      });
    },
    deleteNotification(item) {
      apiClient
        .get(`/del-notification`, {
          params: { id: item },
        })
        .then((response) => {
          if (response.data?.key === "success") {
            this.listNotifications = this.listNotifications.filter(
              (n) => n.id !== item
            );
            this.notificationsArr = this.notificationsArr.filter(
              (n) => n.id !== item
            );
          }
        })
        .catch((error) => {
          console.error("Error deleting notification:", error);
        });
    },
    deleteAllNotifications() {
      apiClient
        .get("/del-notification/", {
          params: { all: "all" },
        })
        .then((response) => {
          this.listNotifications = [];
          this.notificationsArr = [];
          this.store.showSnackbar(response.data.msg, "success");
        })
        .catch((error) => {
          console.error("Error deleting notifications:", error);
        });
    },
    getUnreadNotifications(type) {
      this.activeTab = type;
      this.notificationsArr = []; // Reset array before fetching
      this.currentPage = 1; // Reset page number
      
      apiClient
        .get("/get/notifications", {
          params: {
            type: type,
            limit: 10,
            page: this.currentPage,
          },
        })
        .then((response) => {
          let res = response.data.data.notifications.data;
          this.total_pages = response.data.data.notifications.pagination.total_pages;
          this.notificationsArr = res;
          this.current_page = response.data.data.notifications.pagination.current_page;
        })
        .catch((error) => {
          console.log(error);
          this.notificationsArr = []; // Ensure array is empty on error
        });
    },
    getReadNotifications(type) {
      this.activeTab = type;
      this.notificationsArr = []; // Reset array before fetching
      this.currentPage = 1; // Reset page number
      
      apiClient
        .get("/get/notifications", {
          params: {
            type: type,
            limit: 10,
            page: this.currentPage,
          },
        })
        .then((response) => {
          let res = response.data.data.notifications.data;
          this.total_pages = response.data.data.notifications.pagination.total_pages;
          this.notificationsArr = res;
          this.current_page = response.data.data.notifications.pagination.current_page;
        })
        .catch((error) => {
          console.log(error);
          this.notificationsArr = []; // Ensure array is empty on error
        });
    },

    // onPageChange() {
    //   return this.getNotifications();
    // },
    // onItemsPerPageChange() {
    //   this.current_page = 1;
    //   return this.getNotifications();
    // },
    // previousPage() {
    //   this.current_page -= 1;
    //   return this.getNotifications();
    // },
    // nextPage() {
    //   this.current_page += 1;
    //   return this.getNotifications();
    // },
    getListNotifications(type) {
      this.activeTab = type;
      apiClient
        .get("/get/notifications", {
          params: {
            type: "all",
            limit: 7,
          },
        })
        .then((response) => {
          this.listNotifications = response.data.data.notifications.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
