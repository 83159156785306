<template>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    location="top"
    :close-on-content-click="true"
  >
    {{ snackbar.message }}
  </v-snackbar>

  <v-dialog v-model="localDialog" width="auto">
    <v-card>
      <v-card-text class="scrollable-dialog">
        <div class="new-form d-flex flex-column justify-space-between">
          <div class="title mb-5">Create Video</div>
          <div class="">
            <div class="d-flex ga-5 flex-column">
              <label for="TaskName" class="label-name"
                >Task Name <span style="color: red">*</span>
              </label>
              <Input
                v-model="TaskName"
                type="text"
                :icon="SearchIcon"
                placeholder="Task Name"
              />
              <label for="users" class="label-name">Share With</label>
              <v-autocomplete
                v-model="usersId"
                :items="store?.getUsers"
                color="blue-grey-lighten-2"
                item-title="first_name"
                item-value="id"
                chips
                closable-chips
                multiple
                hide-details
                density="compact"
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip v-bind="props"
                    ><img
                      style="height: 20px; width: 20px"
                      class="circle-img"
                      :src="item.raw.image"
                      alt=""
                  /></v-chip>
                </template>
                <template v-slot:item="{ props, item }">
                  <v-list-item
                    v-bind="props"
                    :prepend-avatar="item.raw.image"
                    :title="item.raw.first_name + ' ' + item.raw.last_name"
                  ></v-list-item>
                </template>
              </v-autocomplete>
              <label for="folderId" class="label-name">Choose Folder </label>
              <v-select
                class="new-form-v-select"
                :items="folders"
                v-model="folderId"
                item-title="name"
                item-value="file_id"
              ></v-select>
              <v-file-upload
                v-model="file"
                :color="primary"
                density="comfortable"
                variant="comfortable"
                clearable
              ></v-file-upload>
            </div>
          </div>
          <div class="button-container d-flex justify-end mt-5">
            <v-btn
              color="white"
              variant="text"
              @click="UploadImage"
              class="upload-btn"
              hide-details
              clearable
            >
              Create Video
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VFileUpload } from "vuetify/labs/VFileUpload";
import Input from "@/components/crm/assets/Input.vue";
import apiClient from "@/core/buildRequest";
import { DriveStore } from "@/store/drive/DriveStore.js";
export default {
  name: "NewImage",
  props: ["dialog"],
  components: { Input, VFileUpload },
  data() {
    return {
      localDialog: this.dialog,
      TaskName: "",
      snackbar: {
        color: "",
        show: false,
        message: "",
        timeout: 3000,
      },
      folders: [],
      file: null,
      folderId: "",
      usersId: [],
      store: DriveStore(),
    };
  },
  watch: {
    dialog(val) {
      this.localDialog = val;
    },
    localDialog(val) {
      if (this.localDialog) {
        this.store?.getAllUsers();
        this.getAllFolders();
      }
      this.$emit("update:dialog", val);
    },
  },
  methods: {
    async getAllFolders() {
      await apiClient
        .get("/my-drive/folders")
        .then((response) => {
          this.folders = response.data.data;
        })
        .catch((error) => {
          this.folders = [];
        });
    },
    closeDialog() {
      this.localDialog = false;
    },
    UploadImage() {
      if (this.TaskName === "") {
        this.showSnackbar("Task name is required", "error");
        return;
      }
      if (!this.file) {
        this.showSnackbar("Image is required", "error");
        return;
      }
      apiClient
        .post("/my-drive/upload-file", {
          file: this.file,
          folder_id: this.folderId,
          task_name: this.TaskName,
          user_id: this.usersId,
        })
        .then((response) => {
          this.store?.getAllDrive();
          this.showSnackbar(`Image Uploaded successfully`, "success");
          this.TaskName = "";
          this.file = null;
          this.folderId = "";
          this.closeDialog();
        });
    },
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
  },
  mounted() {
    let access = localStorage.getItem("drive_token") ? true : false;
    if (access) {
      this.getAllFolders();
    }
  },
};
</script>
