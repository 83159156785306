<template>
  <div class="map-container">
    <div id="map" style="height: 500px; width: 100%"></div>
    <!-- <div class="selected-regions">
      <h3>Selected Regions</h3>
      <div v-if="selectedMapLocatoins.length === 0" class="no-regions">
        No regions selected. Draw a circle on the map to select a region.
      </div>
      <div
        v-for="(location, index) in selectedMapLocatoins"
        :key="index"
        class="region-item"
      >
        <div class="location-info">
          <p><strong>Region:</strong> {{ location.region }}</p>
          <p><strong>Country:</strong> {{ location.country }}</p>
          <p><strong>Continent:</strong> {{ location.continent }}</p>
          <p><strong>Latitude:</strong> {{ location.latitude.toFixed(6) }}°</p>
          <p>
            <strong>Longitude:</strong> {{ location.longitude.toFixed(6) }}°
          </p>
          <p><strong>Radius:</strong> {{ location.radius.toFixed(2) }} km</p>
        </div>
        <button @click="removeLocation(index)" class="remove-btn">
          Remove
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { reverseGeocode } from "@/utils/geocoding.js";

export default {
  name: "MapRegionSelector",
  data() {
    return {
      map: null,
      drawingManager: null,
      selectedMapLocatoins: [],
      selectedShape: null,
      google: null,
    };
  },
  mounted() {
    this.initializeMap();
  },
  methods: {
    async loadSavedLocations() {
      const savedLocations = JSON.parse(
        localStorage.getItem("selectedMapLocatoins") || "[]"
      );

      for (const location of savedLocations) {
        const circle = new this.google.maps.Circle({
          map: this.map,
          center: { lat: location.latitude, lng: location.longitude },
          radius: location.radius * 1000, // Convert km to meters
          fillColor: "#FF0000",
          fillOpacity: 0.3,
          strokeWeight: 2,
          strokeColor: "#FF0000",
          editable: true,
          draggable: true,
        });

        const locationData = {
          ...location,
          circle: circle,
        };

        this.selectedMapLocatoins.push(locationData);

        // Add listeners for circle modifications
        this.google.maps.event.addListener(circle, "radius_changed", () => {
          this.updateCircleData(circle, this.selectedMapLocatoins.length - 1);
        });

        this.google.maps.event.addListener(circle, "center_changed", () => {
          this.updateCircleData(circle, this.selectedMapLocatoins.length - 1);
        });
      }
    },
    async initializeMap() {
      try {
        const loader = new Loader({
          apiKey: "AIzaSyAYugMkEQTvSJa9xsKlA2ixqZD7UFCeOtU",
          version: "weekly",
          libraries: ["drawing", "geometry"],
        });

        this.google = await loader.load();

        this.map = new this.google.maps.Map(document.getElementById("map"), {
          center: { lat: 20, lng: 0 },
          zoom: 2,
          mapTypeId: "terrain",
          mapTypeControl: true,
          fullscreenControl: true,
          streetViewControl: false,
        });

        this.drawingManager = new this.google.maps.drawing.DrawingManager({
          drawingMode: this.google.maps.drawing.OverlayType.CIRCLE,
          drawingControl: true,
          drawingControlOptions: {
            position: this.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [this.google.maps.drawing.OverlayType.CIRCLE],
          },
          circleOptions: {
            fillColor: "#FF0000",
            fillOpacity: 0.3,
            strokeWeight: 2,
            strokeColor: "#FF0000",
            editable: true,
            draggable: true,
          },
        });

        this.drawingManager.setMap(this.map);

        // Load saved locations after map is initialized
        await this.loadSavedLocations();

        this.google.maps.event.addListener(
          this.drawingManager,
          "circlecomplete",
          async (circle) => {
            try {
              const center = circle.getCenter();
              const radius = circle.getRadius() / 1000; // Convert to kilometers

              const locationInfo = await reverseGeocode(
                center.lat(),
                center.lng()
              );

              const locationData = {
                region: locationInfo.region,
                country: locationInfo.country,
                continent: locationInfo.continent,
                latitude: center.lat(),
                longitude: center.lng(),
                radius: radius,
                circle: circle,
              };

              this.selectedMapLocatoins.push(locationData);

              // Add listeners for circle modifications
              this.google.maps.event.addListener(
                circle,
                "radius_changed",
                () => {
                  this.updateCircleData(
                    circle,
                    this.selectedMapLocatoins.length - 1
                  );
                }
              );

              this.google.maps.event.addListener(
                circle,
                "center_changed",
                () => {
                  this.updateCircleData(
                    circle,
                    this.selectedMapLocatoins.length - 1
                  );
                }
              );

              // Save to localStorage
              this.saveLocations();
            } catch (error) {
              console.error("Error handling circle completion:", error);
            }
          }
        );
      } catch (error) {
        console.error("Error initializing map:", error);
      }
    },
    saveLocations() {
      const locationsToSave = this.selectedMapLocatoins.map((location) => ({
        region: location.region,
        country: location.country,
        continent: location.continent,
        latitude: location.latitude,
        longitude: location.longitude,
        radius: location.radius,
      }));
      localStorage.setItem(
        "selectedMapLocatoins",
        JSON.stringify(locationsToSave)
      );
      this.$emit("map-selected", this.selectedMapLocatoins);
    },
    async updateCircleData(circle, index) {
      const center = circle.getCenter();
      const radius = circle.getRadius() / 1000; // Convert to kilometers

      try {
        const locationInfo = await reverseGeocode(center.lat(), center.lng());

        this.selectedMapLocatoins[index] = {
          ...this.selectedMapLocatoins[index],
          region: locationInfo.region,
          country: locationInfo.country,
          continent: locationInfo.continent,
          latitude: center.lat(),
          longitude: center.lng(),
          radius: radius,
        };

        this.$emit("region-selected", this.selectedMapLocatoins[index]);
        this.saveLocations();
      } catch (error) {
        console.error("Error updating circle data:", error);
      }
    },
    removeLocation(index) {
      const location = this.selectedMapLocatoins[index];
      if (location.circle) {
        location.circle.setMap(null);
      }
      this.selectedMapLocatoins.splice(index, 1);
      this.saveLocations();
    },
  },
};
</script>

<style scoped>
.map-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.selected-regions {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-regions {
  text-align: center;
  color: #666;
  padding: 20px;
}

.region-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
}

.location-info p {
  margin: 5px 0;
}

.remove-btn {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.remove-btn:hover {
  background-color: #cc0000;
}

h3 {
  margin-top: 0;
  color: #333;
  font-size: 1.5em;
  margin-bottom: 20px;
}
</style>
