<template>
  <div class="snapchat parent w-100 position-relative">
    <header class="">
      <div>
        <span> Snapchat Ads </span>
      </div>
      <div>
        <img src="@/assets/snapchatImage/download.svg" alt="" />
      </div>
      <div style="width: 5%">
        <button type="button">logout</button>
      </div>
    </header>
    <v-container
      fluid
      style="min-height: calc(100vh - 55px); display: grid; padding: 0"
    >
      <v-row class="h-100 ma-0" style="background-color: #f9f9fa">
        <v-col cols="3" class="py-0 px-0" style="position: relative">
          <SideNav :firstTile="type" />
        </v-col>
        <v-col cols="8" class="main-contain pa-8">
          <div v-if="validations.data?.length > 0" class="validations">
            <div class="d-flex align-center ga-3 validation-message">
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="#444546"
                xmlns="http://www.w3.org/2000/svg"
                class="blue-warning-svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
                ></path>
              </svg>
              <span
                >There Are {{ validations.data.length }} Items To Review Before
                Publishing</span
              >
            </div>
            <div v-for="validation in validations.data" :key="validation.type">
              <div class="d-flex align-center ga-2 validation-message">
                <span class="text-capitalize key">{{ validation.key }}</span>
                <span>{{ validation.message }}</span>
              </div>
              <!-- <div class="d-flex justify-end review-button mt-2">
                <button @click="this.$router.push({ name: validations.path })">
                  Start Review
                </button>
              </div> -->
            </div>
          </div>
          <div class="all-contain-form">
            <div class="ad-set-details secound-step">
              <div class="main-contain-inputs">
                <AdSetDetailsForm ref="adSetIsValid" @changeDriectTrafficLocation="direct_traffic_location = $event" />
                <TrackingForm />
                <GoalAndBid :direct_traffic_location="direct_traffic_location" />
                <BudgetAndSchedule />
                <PlacementsForm />
                <LocationComponanet @country:selected="SelectCountries" />
                <DemographicsForm />
                <AudiencesForm
                  v-if="selected_countries.length > 0"
                  :countries="selected_countries"
                />
                <DevicesForm />
                <DeliveryForm />
              </div>
              <div class="" style="height: 500px"></div>
            </div>
          </div>
          <div class="footer d-flex align-center justify-center">
            <div
              class="d-flex align-center justify-space-between footer-contain"
            >
              <v-btn
                type="button"
                color="#444546"
                rounded
                class="footer-back-btn"
                variant="outlined"
                @click="goBackStep"
                >Back</v-btn
              >
              <div class="d-flex align-center">
                <p>
                  <span>
                    <svg
                      viewBox="0 0 16 16"
                      fill="#39CA8E"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      class="sds-icon sds-icon-check-circle styles-15ro776"
                    >
                      <path
                        d="M14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992ZM11.1422 5.2486L6.90277 9.488L4.88826 7.47349L3.94545 8.4163L6.90277 11.3736L12.085 6.19141L11.1422 5.2486Z"
                      ></path>
                    </svg>
                  </span>
                  Draft Saved
                </p>
                <v-btn
                  type="button"
                  color="#444546"
                  rounded
                  @click="nextStep"
                  class="footer-next-btn"
                  variant="outlined"
                  >Next</v-btn
                >
              </div>
            </div>
          </div>
        </v-col>
        <!-- <v-col cols="2"></v-col> -->
      </v-row>
    </v-container>
  </div>
</template>
<script>
import AdSetDetailsForm from "@/components/snapchat/Ad/AdSetDetailsForm.vue";
import TrackingForm from "@/components/snapchat/Ad/TrackingForm.vue";
import GoalAndBid from "@/components/snapchat/Ad/GoalAndBid.vue";
import BudgetAndSchedule from "@/components/snapchat/Ad/BudgetAndSchedule.vue";
import PlacementsForm from "@/components/snapchat/Ad/PlacementsForm.vue";
import DemographicsForm from "@/components/snapchat/Ad/DemographicsForm.vue";
import AudiencesForm from "@/components/snapchat/Ad/AudiencesForm.vue";
import DevicesForm from "@/components/snapchat/Ad/DevicesForm.vue";
import DeliveryForm from "@/components/snapchat/Ad/DeliveryForm.vue";
import LocationComponanet from "@/components/snapchat/LocationComponanet.vue";

import SideNav from "@/components/snapchat/SideNav.vue";

import "@/assets/snapchatStyle/snapchatStyle.css";
import { useAwarenessStore } from "@/store/snapchat/awareness";

export default {
  components: {
    SideNav,
    LocationComponanet,
    AdSetDetailsForm,
    TrackingForm,
    GoalAndBid,
    BudgetAndSchedule,
    PlacementsForm,
    DemographicsForm,
    AudiencesForm,
    DevicesForm,
    DeliveryForm,
  },
  data() {
    return {
      validations: {},
      type: localStorage.getItem("selectedOption"),
      show_audiance: false,
      selected_countries: [],
      direct_traffic_location:localStorage.getItem("direct_traffic_location")
    };
  },
  methods: {
    nextStep() {
      let isBuildCampaignValid = this.$refs.adSetIsValid?.sendValidation();
      if (isBuildCampaignValid) {
        this.$router.push({ name: "SnapThirdStep" });
      }
    },
    goBackStep() {
      this.$router.push({ name: "SnapFirstStep" });
    },
    SelectCountries($v) {
      this.selected_countries = $v;
      if ($v.length > 0) {
        this.show_audiance = true;
      } else {
        this.show_audiance = false;
      }
    },
  },
  computed: {},
  watch: {},
  mounted() {
    const awarenessStore = useAwarenessStore();
    const adsquadValidation = awarenessStore.validationData.find(
      (item) => item.type === "adsquad"
    );

    if (adsquadValidation) {
      this.validations = adsquadValidation;
    }
    this.selected_countries = localStorage.getItem("selectedCountries")
      ? JSON.parse(localStorage.getItem("selectedCountries"))
      : [];
  },
};
</script>
