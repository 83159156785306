<template>
  <div class="mobile-store mt-5 mb-2">
    <label for="" class="d-block" style="font-size: 14px; color: #1c2b33"
      >Mobile app store</label
    >
    <v-select
      v-model="mobileAppStoreSelected"
      :items="mobileAppStore"
      density="compact"
      variant="outlined"
      return-object
      item-value="value"
      item-title="title"
      hide-details
    >
      <template v-slot:selection="{ item }">
        {{ item?.title || "" }}
      </template>
      <template v-slot:item="{ item }">
        <v-list-item @click="selectedAppStore(item)">
          <div class="my-form">
            <div class="ga-3">
              <input
                :checked="
                  mobileAppStoreSelected &&
                  mobileAppStoreSelected.id === item.raw.id
                "
                :id="`${item.raw.id}appStore`"
                type="radio"
                name="option"
                :value="item"
              />
              <div class="d-flex flex-column ga-1">
                <label
                  :for="`${item.raw.id}appStore`"
                  class="d-block"
                  style="font-size: 14px"
                  >{{ item.raw.title }}</label
                >
              </div>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-select>
    <div class="app-store">
      <label for="" class="d-block mt-3 mb-1" style="font-size: 14px; color: #1c2b33"
        >App</label
      >
      <v-autocomplete
        v-model="values"
        :items="items"
        hide-details
        density="compact"
        placeholder="Choose app"
      ></v-autocomplete>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobileAppStore: [
        {
          id: 1,
          title: "Google Play",
          value: "GOOGLE_PLAY",
        },
        {
          id: 2,
          title: "App Store",
          value: "APP_STORE",
        },
        {
          id: 3,
          title: "App store for iPad",
          value: "APP_STORE_IPAD",
        },
        {
          id: 4,
          title: "Windows store",
          value: "WINDOWS_STORE",
        },
        {
          id: 5,
          title: "Facebook Canvas",
          value: "FACEBOOK_CANVAS",
        },
        {
          id: 6,
          title: "Amazon Appstore",
          value: "AMAZON_APPSTORE",
        },
        {
          id: 7,
          title: "Games",
          value: "GAMES",
        },
        {
          id: 8,
          title: "Meta Quest App Store",
          value: "META_QUEST_APP_STORE",
        },
      ],
      mobileAppStoreSelected: {
        id: 1,
        title: "Google Play",
        value: "GOOGLE_PLAY",
      },
      items: [
        {
          id: 1,
          title: "Google Play",
          value: "GOOGLE_PLAY",
        },
        {
          id: 2,
          title: "App Store",
          value: "APP_STORE",
        },
        {
          id: 3,
          title: "App store for iPad",
          value: "APP_STORE_IPAD",
        },
        {
          id: 4,
          title: "Windows store",
          value: "WINDOWS_STORE",
        },
      ],
      values: null,
    };
  },
  methods: {
    selectedAppStore(item) {
      this.mobileAppStoreSelected = item.raw;
    },
  },
};
</script>
