<template>
  <div class="ad-group-name-card">
    <div class="ad-group-name">
      <div class="card-header">
        <label for="">Ad group name </label>
      <input
        type="text"
        v-model="adGroupName"
        @input="setGroupName($event.target.value)"
        name=""
      />
      </div>
    </div>
  </div>
</template>

<script>
import { tiktokData } from "@/store/tiktokStore/tiktokData.js";
import "@/assets/css/tiktok/index.css";
import { mapActions } from "pinia";
export default {
  data() {
    return {
      adGroupName: "Ad group 20241206054916",
    };
  },
  methods: {
    ...mapActions(tiktokData, ["setGroupName"]),
  },
};
</script>
