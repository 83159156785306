<template>
  <v-select
    label="Select"
    v-model="selected_status"
    :items="status"
    :class="['contact-v-select', statusClass]"
    variant=""
    no-details
  >
    <template v-slot:item="{ item, props }">
      <v-list-item
        @click="updateStats(item)"
        v-bind="props"
        :title="item.raw"
        :style="{
          color:
            item.raw === 'active'
              ? '#2E6B30'
              : item.raw === 'completed'
              ? '#0464bc'
              : item.raw === 'on-hold'
              ? '#c14a05'
              : item.raw === 'pending'
              ? '#666666'
              : item.raw === 'closed'
              ? '#d91313'
              : '',
          background:
            item.raw === 'active'
              ? '#E3F9EB'
              : item.raw === 'completed'
              ? '#f5fafe'
              : item.raw === 'On-hold'
              ? '#fefbf1'
              : item.raw === 'pending'
              ? '#E6E6E6'
              : item.raw === 'closed'
              ? '#fef3f2'
              : '',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '5px 0px',
        }"
      >
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import apiClient from "@/core/buildRequest";
import { useProjectsStore } from "@/store/crm/useProjectsStore";

export default {
  name: "statusCol",
  props: ["item", "id"],
  data() {
    return {
      selected_status: "",
      status: ["active", "completed", "on-hold", "pending", "closed"],
    };
  },
  mounted() {
    this.selected_status = this.item.status;
  },
  computed: {
    statusClass() {
      switch (this.selected_status) {
        case "active":
          return "contact-v-select-green";
        case "completed":
          return "contact-v-select-blue";
        case "on-hold":
          return "contact-v-select-orange";
        case "pending":
          return "contact-v-select-gray";
        case "closed":
          return "contact-v-select-red";
        default:
          return "";
      }
    },
  },
  methods: {
    async updateStats(item) {
      await apiClient
        .post(`/leads-managment/projects/status/${this.id}`, {
          status: item.value,
        })
        .then((res) => {
          const project_store = useProjectsStore();
          project_store?.updateStatus(this.id, item.value);
        });
    },
  },
};
</script>
