<script>
export default {
  name: "MetaAdsFrame",
  props: {
    adId: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "340",
    },
    height: {
      type: String,
      default: "500",
    },
  },
};
</script>

<template>
    <iframe
      :src="`https://adsmanager.facebook.com/adsmanager/manage/campaigns?act=${adId}&nav_entry_point=comet_bookmark&nav_source=comet`"
      :width="width"
      :height="height"
      style="border: none; overflow: hidden"
      scrolling="no"
      frameborder="0"
      allowfullscreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    ></iframe>
</template>
