<template>
  <v-col :cols="12" :lg="6" class="d-flex ga-5 align-center">
    <div class="d-flex ga-1 align-center input-data-text">
      <AssigneeIcon :loading="store.loading.assignees" />
      <label class="calender-input-label text-capitalize">assignee</label>
    </div>
    <div>
      <v-select
        class="task-assignee-select"
        variant="none"
        v-model="store.assignees"
        :items="store.users"
        item-title="first_name"
        item-value="id"
        multiple
        chips
        hide-details
      >
        <template v-slot:item="{ item, props }">
          <v-list-item
            v-bind="props"
            :title="item.raw.first_name + ' ' + item.raw.last_name"
          >
            <template v-slot:prepend>
              <v-checkbox-btn
                :model-value="store.assignees.includes(item.raw.id)"
                density="compact"
              />
              <span class="position-relative mx-5">
                <img
                  class="position-absolute"
                  style="
                    width: 20px;
                    height: 20px;
                    transform: translate(-50%, -50%);
                  "
                  :alt="item.raw.first_name"
                  :src="item.raw.image"
                />
              </span>
            </template>
          </v-list-item>
        </template>
      </v-select>
    </div>
  </v-col>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";
import AssigneeIcon from "./icons/AssigneeIcon.vue";

export default {
  name: "TaskAssignee",
  components: {
    AssigneeIcon,
  },
  data() {
    return {
      store: useTaskStore(),
    };
  },
  methods: {},
  mounted() {
    this.store.getUsers();
  },
};
</script>
