import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const EngagementAndInsightsStore = defineStore(
  "EngagementAndInsightsStore",
  {
    state: () => ({
      body: [],
      headers: [
        {
          title: "Post",
          name: "Post",
          align: "start",
          key: "post",
          sortable: true,
        },
        {
          title: "Publish Date",
          name: "PublishDate",
          align: "start",
          key: "publish_date",
          sortable: true,
        },
        {
          title: "Platform",
          name: "Platform",
          align: "start",
          key: "platform",
          sortable: true,
        },
        {
          title: "Reach",
          name: "Reach",
          align: "start",
          key: "reach",
          sortable: true,
        },
        {
          title: "Reactions",
          name: "Reactions",
          align: "start",
          key: "reactions",
          sortable: true,
        },
        {
          title: "Comments",
          name: "Comments",
          align: "start",
          key: "comments",
          sortable: true,
        },
        {
          title: "Shares",
          name: "Shares",
          align: "start",
          key: "shares",
          sortable: true,
        },
        {
          title: "Action",
          name: "Action",
          align: "start",
          key: "action",
          sortable: true,
        },
      ],
      items_per_page: 10,
      current_page: 1,
      total_items: 0,
      total_pages: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      engagements: [
        {
          id: 1,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "facebook",
          online: true,
        },
        {
          id: 2,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://plus.unsplash.com/premium_photo-1664541336896-b3d5f7dec9a3?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "instagram",
          online: false,
        },
        {
          id: 3,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1640951613773-54706e06851d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "tiktok",
          online: true,
        },
        {
          id: 4,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "instagram",
          online: false,
        },
        {
          id: 5,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://plus.unsplash.com/premium_photo-1683121366070-5ceb7e007a97?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "tiktok",
          online: false,
        },
        {
          id: 6,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1976&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "iinstagram",
          online: true,
        },
        {
          id: 1,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "facebook",
          online: true,
        },
        {
          id: 2,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://plus.unsplash.com/premium_photo-1664541336896-b3d5f7dec9a3?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "instagram",
          online: false,
        },
        {
          id: 3,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1640951613773-54706e06851d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "tiktok",
          online: true,
        },
        {
          id: 4,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "instagram",
          online: false,
        },
        {
          id: 5,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://plus.unsplash.com/premium_photo-1683121366070-5ceb7e007a97?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "tiktok",
          online: false,
        },
        {
          id: 6,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1976&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "iinstagram",
          online: true,
        },
      ],
      loading: true,
      title: "",
      end_date: "",
      start_date: "",
      filter_by: "",
    }),
    getters: {
      getBody: (state) => state.body,
      getPagination: (state) => ({
        currentPage: state.current_page,
        totalPages: state.total_pages,
        itemsPerPage: state.items_per_page,
        totalContacts: state.total_items,
      }),
    },
    actions: {
      async getAllData() {
        this.loading = true;
        console.log(this.current_page);

        try {
          var response = await apiClient.get(
            `/engagement-insights/page-insights/?page=${this.current_page}&per_page=${this.items_per_page}`,
            {
              params: {
                start_date: this.start_date,
                end_date: this.end_date,
                title: this.title,
                filter_by: this.filter_by,
              },
            }
          );
          let { data, pagination } = response.data.data;
          this.body = data;
          console.log(pagination);

          this.current_page = pagination.current_page;
          this.total_items = pagination.total;
          this.total_pages = pagination.last_page;
          this.hasNextPage = pagination.next_page_url !== "" ? true : false;
          this.hasPreviousPage = pagination.perv_page_url !== "" ? true : false;
          this.loading = false;
        } catch (err) {
          console.error("Error fetching contacts:", err);
        }
      },
      onPageChange() {
        return this.getAllData();
      },
      onItemsPerPageChange() {
        this.current_page = 1;
        return this.getAllData();
      },
      previousPage() {
        this.current_page -= 1;
        return this.getAllData();
      },
      nextPage() {
        this.current_page += 1;
        return this.getAllData();
      },
      FilterSearch() {
        return this.getAllData();
      },
    },
  }
);
