<template>
  <v-card elevation="0" class="post-card mb-3">
    <v-tabs v-model="tab">
      <!-- tabs  -->
      <!-- post content  -->
      <v-tab v-if="!storyContant" value="one" class="mr-5 position-relative">
        <span class="mr-3">Content</span>
        <span
          @click="customizeByPlatformType()"
          style="cursor: pointer; position: absolute; right: 6px; top: 6px"
        >
          <svg
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.14388 5.91634L0.560547 5.33301L2.89388 2.99967L0.560547 0.666341L1.14388 0.0830078L3.47721 2.41634L5.81055 0.0830078L6.39388 0.666341L4.06055 2.99967L6.39388 5.33301L5.81055 5.91634L3.47721 3.58301L1.14388 5.91634Z"
              fill="#666666"
            />
          </svg>
        </span>
      </v-tab>
      <!-- reel story content  -->
      <v-tab
        v-if="storyContant"
        value="reelStoryContent"
        class="mr-5 position-relative"
      >
        <span class="mr-3">Content</span>
        <span
          @click="customizeByPlatformType()"
          style="cursor: pointer; position: absolute; right: 6px; top: 6px"
        >
          <svg
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.14388 5.91634L0.560547 5.33301L2.89388 2.99967L0.560547 0.666341L1.14388 0.0830078L3.47721 2.41634L5.81055 0.0830078L6.39388 0.666341L4.06055 2.99967L6.39388 5.33301L5.81055 5.91634L3.47721 3.58301L1.14388 5.91634Z"
              fill="#666666"
            />
          </svg>
        </span>
      </v-tab>
      <!-- facebook post  -->
      <v-tab
        value="two"
        v-if="
          !storyContant && selectedTypes['facebook'].find((el) => el === 'post')
        "
        class="mr-2"
      >
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.8976 10C19.8976 15.5228 15.4436 20 9.94926 20C4.45498 20 0.000976562 15.5228 0.000976562 10C0.000976562 4.47715 4.45498 0 9.94926 0C15.4436 0 19.8976 4.47715 19.8976 10ZM8.45702 10C8.45702 10.8284 9.12512 11.5 9.94926 11.5C10.7734 11.5 11.4415 10.8284 11.4415 10C11.4415 9.17157 10.7734 8.5 9.94926 8.5C9.12512 8.5 8.45702 9.17157 8.45702 10Z"
              fill="#4D4D4D"
            />
            <path
              d="M13.4069 12.932L13.8193 10.0583H11.2391V8.19417C11.2391 7.40778 11.5989 6.64078 12.7546 6.64078H13.9284V4.19417C13.9284 4.19417 12.8636 4 11.846 4C9.72012 4 8.33189 5.37668 8.33189 7.86796V10.0583H5.96973V12.932H8.33189V19.8796C8.80612 19.9592 9.29128 20 9.78552 20C10.2798 20 10.7649 19.9592 11.2391 19.8796V12.932H13.4069Z"
              fill="white"
            />
          </svg>
        </span>
        Post</v-tab
      >
      <!-- instagram post -->
      <v-tab
        value="three"
        v-if="
          !storyContant &&
          selectedTypes['instagram'].find((el) => el === 'post')
        "
        class="mr-2"
      >
        <img src="@/assets/soft-ware-img/Component 63.svg" alt="" />
        Post</v-tab
      >
      <!-- tiktok post -->
      <v-tab
        value="four"
        v-if="
          !storyContant && selectedTypes['tiktok'].find((el) => el === 'post')
        "
        class="mr-2"
      >
        <img src="@/assets/soft-ware-img/tiktok-tabs-post.svg" alt="" />
        Post</v-tab
      >
      <!-- snap post -->
      <v-tab
        value="five"
        v-if="
          !storyContant && selectedTypes['snapchat'].find((el) => el === 'post')
        "
        class="mr-2"
      >
        <img src="@/assets/soft-ware-img/Component 67.svg" alt="" />
        Post
      </v-tab>

      <!-- facebook story  -->
      <v-tab
        v-if="
          storyContant && selectedTypes['facebook'].find((el) => el === 'story')
        "
        value="six"
        class="mr-2"
      >
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.8976 10C19.8976 15.5228 15.4436 20 9.94926 20C4.45498 20 0.000976562 15.5228 0.000976562 10C0.000976562 4.47715 4.45498 0 9.94926 0C15.4436 0 19.8976 4.47715 19.8976 10ZM8.45702 10C8.45702 10.8284 9.12512 11.5 9.94926 11.5C10.7734 11.5 11.4415 10.8284 11.4415 10C11.4415 9.17157 10.7734 8.5 9.94926 8.5C9.12512 8.5 8.45702 9.17157 8.45702 10Z"
              fill="#4D4D4D"
            />
            <path
              d="M13.4069 12.932L13.8193 10.0583H11.2391V8.19417C11.2391 7.40778 11.5989 6.64078 12.7546 6.64078H13.9284V4.19417C13.9284 4.19417 12.8636 4 11.846 4C9.72012 4 8.33189 5.37668 8.33189 7.86796V10.0583H5.96973V12.932H8.33189V19.8796C8.80612 19.9592 9.29128 20 9.78552 20C10.2798 20 10.7649 19.9592 11.2391 19.8796V12.932H13.4069Z"
              fill="white"
            />
          </svg>
        </span>
        Story</v-tab
      >
      <!-- facebook reel  -->
      <v-tab
        v-if="
          storyContant && selectedTypes['facebook'].find((el) => el === 'reel')
        "
        value="seven"
        class="mr-2"
      >
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.8976 10C19.8976 15.5228 15.4436 20 9.94926 20C4.45498 20 0.000976562 15.5228 0.000976562 10C0.000976562 4.47715 4.45498 0 9.94926 0C15.4436 0 19.8976 4.47715 19.8976 10ZM8.45702 10C8.45702 10.8284 9.12512 11.5 9.94926 11.5C10.7734 11.5 11.4415 10.8284 11.4415 10C11.4415 9.17157 10.7734 8.5 9.94926 8.5C9.12512 8.5 8.45702 9.17157 8.45702 10Z"
              fill="#4D4D4D"
            />
            <path
              d="M13.4069 12.932L13.8193 10.0583H11.2391V8.19417C11.2391 7.40778 11.5989 6.64078 12.7546 6.64078H13.9284V4.19417C13.9284 4.19417 12.8636 4 11.846 4C9.72012 4 8.33189 5.37668 8.33189 7.86796V10.0583H5.96973V12.932H8.33189V19.8796C8.80612 19.9592 9.29128 20 9.78552 20C10.2798 20 10.7649 19.9592 11.2391 19.8796V12.932H13.4069Z"
              fill="white"
            />
          </svg>
        </span>
        Reel</v-tab
      >
      <!-- instagram story  -->
      <v-tab
        v-if="
          storyContant &&
          selectedTypes['instagram'].find((el) => el === 'story')
        "
        value="eight"
        class="mr-2"
      >
        <img src="@/assets/soft-ware-img/Component 63.svg" alt="" />
        Story</v-tab
      >
      <!-- instagram reel -->
      <v-tab
        v-if="
          storyContant && selectedTypes['instagram'].find((el) => el === 'reel')
        "
        value="nine"
        class="mr-2"
      >
        <img src="@/assets/soft-ware-img/Component 63.svg" alt="" />
        Reel</v-tab
      >
      <!-- tiktok story  -->
      <v-tab
        v-if="
          storyContant && selectedTypes['tiktok'].find((el) => el === 'story')
        "
        value="ten"
        class="mr-2"
      >
        <img src="@/assets/soft-ware-img/tiktok-tabs-post.svg" alt="" />
        Story</v-tab
      >
      <!-- snapchat story  -->
      <v-tab
        v-if="
          storyContant && selectedTypes['snapchat'].find((el) => el === 'story')
        "
        value="eleven"
        class="mr-2"
      >
        <img src="@/assets/soft-ware-img/Component 67.svg" alt="" />
        Story
      </v-tab>

      <!-- x post  -->
      <v-tab
        v-if="!storyContant && selectedTypes['x'].find((el) => el === 'post')"
        value="twelve"
        class="mr-2"
      >
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4565_103253)">
            <rect x="0.125977" width="20" height="20" rx="10" fill="#4D4D4D" />
            <path
              d="M2.6824 3.08984L8.49179 10.7129L2.64453 16.911H3.95991L9.07753 11.4837L13.213 16.911H17.6894L11.5543 8.85919L16.9951 3.08984H15.6797L10.966 8.08846L7.15874 3.08984H2.6824ZM4.61634 4.04149H6.67399L15.7554 15.9618H13.6978L4.61634 4.04149Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_4565_103253">
              <rect x="0.125977" width="20" height="20" rx="10" fill="white" />
            </clipPath>
          </defs>
        </svg>

        Post
      </v-tab>
    </v-tabs>
    <v-card-text elevation="0" class="ma-0">
      <v-tabs-window v-model="tab" elevation="0">
        <v-tabs-window-item
          value="one"
          class="contant-social-post-default positon-relative"
        >
          <div class="border-0 card positon-relative">
            <textarea
              :disabled="!store?.getCarouselSlider?.length"
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="allPostContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="mediaFilsPost" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in mediaFilsPost"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, mediaFilsPost)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                :disabled="!store?.getCarouselSlider?.length"
                  @fileSelected="
                    (res) => handleFileSelected(res, 'allPostContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" :disabled="!store?.getCarouselSlider?.length" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button :disabled="!store?.getCarouselSlider?.length" class="btn-post" @click="all_post_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
               
               
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="all_post_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                    class="add-link-input"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                    class="add-link-input"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink('', 'post')"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link.name }}
                      </a>
                      <button
                        @click="removeLink(index, 'customize', '', 'post')"
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('all_posts')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>

          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="(event) => onEmojiClick(event, 'allPostContent')"
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item
          value="reelStoryContent"
          class="contant-social-post-default positon-relative"
        >
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="allReelStoryContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="mediaFilsPost" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in mediaFilsPost"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, mediaFilsPost)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'allReelStoryContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="all_reel_story_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="all_reel_story_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink(
                        '',
                        'story_and_reel'
                      )"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link
                      </a>
                      <button
                        @click="
                          removeLink(index, 'customize', '', 'story_and_reel')
                        "
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('all_story_reel')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>

          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="
                (event) => onEmojiClick(event, 'allReelStoryContent')
              "
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="two" class="contant-social-post-default">
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="facebookPostContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="facebookPostMedia" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in facebookPostMedia"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, facebookPostMedia)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'facebookPostContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="facebook_post_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="facebook_post_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink('facebook', 'post')"
                      :key="index"
                    >
                      <a :href="link" target="_blank" rel="noopener noreferrer">
                        link
                      </a>
                      <button
                        @click="
                          removeLink(index, 'customize', 'facebook', 'post')
                        "
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('facebook_post')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="
                (event) => onEmojiClick(event, 'facebookPostContent')
              "
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="three" class="contant-social-post-default">
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="instagramPostContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="instagramPostMedia" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in instagramPostMedia"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, instagramPostMedia)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'instagramPostContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="instagram_post_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="instagram_post_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink(
                        'instagram',
                        'post'
                      )"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link.name }}
                      </a>
                      <button
                        @click="
                          removeLink(index, 'customize', 'instagram', 'post')
                        "
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('instagram_post')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="
                (event) => onEmojiClick(event, 'instagramPostContent')
              "
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="four" class="contant-social-post-default">
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="tikTokPostContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="tikTokPostMedia" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in tikTokPostMedia"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, tikTokPostMedia)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'tikTokPostContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="tiktok_post_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="tiktok_post_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink('tiktok', 'post')"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link.name }}
                      </a>
                      <button
                        @click="
                          removeLink(index, 'customize', 'tiktok', 'post')
                        "
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('tiktok_post')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="(event) => onEmojiClick(event, 'tikTokPostContent')"
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="five" class="contant-social-post-default">
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="snapChatPostContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="snapChatPostMedia" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in snapChatPostMedia"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, snapChatPostMedia)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'snapChatPostContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="snapchat_post_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="snapchat_post_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink('snapchat', 'post')"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link.name }}
                      </a>
                      <button
                        @click="
                          removeLink(index, 'customize', 'snapchat', 'post')
                        "
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('snapchat_post')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="
                (event) => onEmojiClick(event, 'snapChatPostContent')
              "
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="six" class="contant-social-post-default">
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="facebookStoryContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="facebookStoryMedia" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in facebookStoryMedia"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, facebookStoryMedia)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'facebookStoryContant')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="facebook_story_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="facebook_story_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink(
                        'facebook',
                        'story'
                      )"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link.name }}
                      </a>
                      <button
                        @click="
                          removeLink(index, 'customize', 'facebook', 'story')
                        "
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('facebook_story')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="
                (event) => onEmojiClick(event, 'facebookStoryContent')
              "
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="seven" class="contant-social-post-default">
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="facebookReelContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="facebookReelMedia" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in facebookReelMedia"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, facebookReelMedia)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'facebookReelContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="facebook_reel_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="facebook_reel_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink('facebook', 'reel')"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link.name }}
                      </a>
                      <button
                        @click="
                          removeLink(index, 'customize', 'facebook', 'reel')
                        "
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('facebook_reel')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="
                (event) => onEmojiClick(event, 'facebookReelContent')
              "
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="eight" class="contant-social-post-default">
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="instagramStoryContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="instagramStoryMedia" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in instagramStoryMedia"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, instagramStoryMedia)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'instagramStoryContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="instagram_story_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="instagram_story_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink(
                        'instagram',
                        'story'
                      )"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link.name }}
                      </a>
                      <button
                        @click="
                          removeLink(index, 'customize', 'instagram', 'story')
                        "
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('instagram_story')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="
                (event) => onEmojiClick(event, 'instagramStoryContent')
              "
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="nine" class="contant-social-post-default">
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="instagramReelContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="instagramReelMedia" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in instagramReelMedia"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, instagramReelMedia)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'instagramReelContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="instagram_reel_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="instagram_reel_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink(
                        'instagram',
                        'reel'
                      )"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link.name }}
                      </a>
                      <button
                        @click="
                          removeLink(index, 'customize', 'instagram', 'reel')
                        "
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('instagram_reel')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="
                (event) => onEmojiClick(event, 'instagramReelContent')
              "
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="ten" class="contant-social-post-default">
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="tikTokStoryContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="tikTokStoryMedia" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in tikTokStoryMedia"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, tikTokStoryMedia)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'tiktokStoryContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="tiktok_story_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="tiktok_story_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink('tiktok', 'story')"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link.name }}
                      </a>
                      <button
                        @click="
                          removeLink(index, 'customize', 'tiktok', 'story')
                        "
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('tiktok_story')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="
                (event) => onEmojiClick(event, 'tikTokStoryContent')
              "
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="eleven" class="contant-social-post-default">
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="snapChatStoryContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="snapchatStoryMedia" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in snapchatStoryMedia"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, snapchatStoryMedia)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'snapchatStoryContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="snapchat_story_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="snapchat_story_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink(
                        'snapchat',
                        'story'
                      )"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link.name }}
                      </a>
                      <button
                        @click="
                          removeLink(index, 'customize', 'snapchat', 'story')
                        "
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('snapchat_story')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="
                (event) => onEmojiClick(event, 'snapChatStoryContent')
              "
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="twelve" class="contant-social-post-default">
          <div class="border-0 card positon-relative">
            <textarea
              placeholder="Write the content that you will use as a template on the rest of the platforms"
              name=""
              id=""
              rows="15"
              maxlength="2500"
              v-model="xPostContent"
              class=""
              ref="textarea"
            ></textarea>
            <div v-if="xPostMedia" class="image-preview-overlay">
              <div
                class="image-preview-container"
                v-for="(media, index) in xPostMedia"
                :key="index"
              >
                <img :src="media.preview" alt="Preview" />
                <button
                  class="remove-image"
                  @click="removeImage(index, xPostMedia)"
                >
                  ×
                </button>
              </div>
            </div>
            <div
              class="textarea-actions d-flex align-center justify-space-between"
            >
              <div class="btn-groups d-flex align-start ga-3">
                <ImageMenu
                  @fileSelected="
                    (res) => handleFileSelected(res, 'xPostContent')
                  "
                  @driveSelect="handleDriveSelect"
                  @editImage="handleEditImage"
                />
                <button class="btn-post" @click="toggleEmojiPicker">
                  <img
                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                    alt=""
                  />
                </button>
                <button class="btn-post" @click="x_post_dialog = true">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                      fill="#666666"
                    />
                    <path
                      d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
                <button class="btn-post">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                      stroke="#666666"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="btn-post" @click="fetchLocation">
                  <svg
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                      fill="#666666"
                    />
                  </svg>
                </button>
              </div>
              <!-- Link review section -->
              <div class="word-length">
                <span> {{ longOfText }} </span> /
                <span>2500</span>
              </div>
            </div>
            <v-dialog
              class="links-dialog"
              v-model="x_post_dialog"
              width="400px"
            >
              <v-card max-width="400" title="Add link">
                <div class="add-link-dialog d-flex flex-column w-100 pa-3">
                  <input
                    v-model="newLinkName"
                    placeholder="Enter link name"
                    type="text"
                  />
                  <input
                    v-model="newLinkHref"
                    placeholder="Enter link URL"
                    type="url"
                  />
                </div>
                <div v-if="links.length" class="all-links pa-2">
                  <h4>Links:</h4>
                  <ul>
                    <li
                      v-for="(link, index) in store.getLink('x', 'post')"
                      :key="index"
                    >
                      <a
                        :href="link.href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link.name }}
                      </a>
                      <button
                        @click="removeLink(index, 'customize', 'x', 'post')"
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </div>
                <template v-slot:actions>
                  <button @click="addCustomizationLink('x_post')">
                    Add Link
                  </button>
                </template>
              </v-card>
            </v-dialog>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
            <emoji-picker
              @emoji-click="(event) => onEmojiClick(event, 'xPostContent')"
            ></emoji-picker>
          </div>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-card>
</template>

<script>
import ImageMenu from "./ImageMenu.vue";
import "emoji-picker-element";
import { publishPost } from "@/store/publishPost/publishPost.js";
import { mapState } from "pinia";
export default {
  components: {
    ImageMenu,
  },

  data() {
    return {
      tab: null,
      showEmojiPicker: false,
      longOfText: 0,
      newLinkName: "",
      newLinkHref: "",
      links: [],
      enterPostlink: false,
      all_post_dialog: false,
      all_reel_story_dialog: false,
      facebook_post_dialog: false,
      facebook_story_dialog: false,
      facebook_reel_dialog: false,
      instagram_post_dialog: false,
      instagram_story_dialog: false,
      instagram_reel_dialog: false,
      tiktok_post_dialog: false,
      tiktok_story_dialog: false,
      snapchat_post_dialog: false,
      snapchat_story_dialog: false,
      x_post_dialog: false,

      imagePreview: null,
      cursorPosition: 0,
      allPostContent: "",
      allReelStoryContent: "",
      facebookPostContent: "",
      instagramPostContent: "",
      tikTokPostContent: "",
      snapChatPostContent: "",
      facebookStoryContent: "",
      facebookReelContent: "",
      instagramStoryContent: "",
      instagramReelContent: "",
      tikTokStoryContent: "",
      snapChatStoryContent: "",
      xPostContent: "",
      store: publishPost(),
    };
  },
  computed: {
    selectedTypes() {
      return this.store.getSelectedTypes;
    },
    ...mapState(publishPost, [
      "storyContant",
      "mediaFilsPost",
      "facebookPostMedia",
      "instagramPostMedia",
      "tikTokPostMedia",
      "snapChatPostMedia",
      "facebookStoryMedia",
      "facebookReelMedia",
      "instagramStoryMedia",
      "instagramReelMedia",
      "tikTokStoryMedia",
      "snapChatStoryMedia",
      "xPostMedia",
    ]),
  },
  methods: {
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    onEmojiClick(event, x) {
      const emoji = event.detail.unicode;
      const textarea = this.$refs.textarea;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      switch (x) {
        case "allPostContent":
          this.allPostContent =
            this.allPostContent.substring(0, start) +
            emoji +
            this.allPostContent.substring(end);
          break;
        case "allReelStoryContent":
          this.allReelStoryContent =
            this.allReelStoryContent.substring(0, start) +
            emoji +
            this.allReelStoryContent.substring(end);
          break;
        case "facebookPostContent":
          this.facebookPostContent =
            this.facebookPostContent.substring(0, start) +
            emoji +
            this.facebookPostContent.substring(end);
          break;
        case "instagramPostContent":
          this.instagramPostContent =
            this.instagramPostContent.substring(0, start) +
            emoji +
            this.instagramPostContent.substring(end);
          break;
        case "tikTokPostContent":
          this.tikTokPostContent =
            this.tikTokPostContent.substring(0, start) +
            emoji +
            this.tikTokPostContent.substring(end);
          break;
        case "snapChatPostContent":
          this.snapChatPostContent =
            this.snapChatPostContent.substring(0, start) +
            emoji +
            this.snapChatPostContent.substring(end);
          break;
        case "facebookStoryContent":
          this.facebookStoryContent =
            this.facebookStoryContent.substring(0, start) +
            emoji +
            this.facebookStoryContent.substring(end);
          break;
        case "facebookReelContent":
          this.facebookReelContent =
            this.facebookReelContent.substring(0, start) +
            emoji +
            this.facebookReelContent.substring(end);
          break;
        case "instagramStoryContent":
          this.instagramStoryContent =
            this.instagramStoryContent.substring(0, start) +
            emoji +
            this.instagramStoryContent.substring(end);
          break;
        case "instagramReelContent":
          this.instagramReelContent =
            this.instagramReelContent.substring(0, start) +
            emoji +
            this.instagramReelContent.substring(end);
          break;
        case "tikTokStoryContent":
          this.tikTokStoryContent =
            this.tikTokStoryContent.substring(0, start) +
            emoji +
            this.tikTokStoryContent.substring(end);
          break;
        case "snapChatStoryContent":
          this.snapChatStoryContent =
            this.snapChatStoryContent.substring(0, start) +
            emoji +
            this.snapChatStoryContent.substring(end);
          break;
        case "xPostContent":
          this.xPostContent =
            this.xPostContent.substring(0, start) +
            emoji +
            this.xPostContent.substring(end);
          break;
      }
      this.$nextTick(() => {
        textarea.selectionStart = textarea.selectionEnd = start + emoji.length;
        textarea.focus();
      });
      this.showEmojiPicker = false;
    },
    addCustomizationLink(type) {
      if (this.newLinkName && this.newLinkHref) {
        this.postContent += ` ${this.newLinkName}`;
        const linkHTML = `<a href="${this.newLinkHref}" target="_blank">${this.newLinkName}</a>`;
        // this.allPostContent += ` ${this.newLinkName}`;
        // this.allReelStoryContent += ` ${this.newLinkName}`;
        this.enterPostlink = false;
        this.links.push({
          name: this.newLinkName,
          href: this.newLinkHref,
        });
        switch (type) {
          case "all_posts":
            this.store.UpdatePlatformLinks(
              "customize",
              "",
              "post",
              this.newLinkHref,
              this.newLinkName
            );
            break;
          case "all_story_reel":
            this.store.UpdatePlatformLinks(
              "customize",
              "",
              "reel",
              this.newLinkHref,
              this.newLinkName
            );
            this.store.UpdatePlatformLinks(
              "customize",
              "",
              "story",
              this.newLinkHref,
              this.newLinkName
            );
            break;
          case "facebook_post":
            this.store.UpdatePlatformLinks(
              "customize",
              "facebook",
              "post",
              this.newLinkHref,
              this.newLinkName
            );
            break;
          case "facebook_story":
            this.store.UpdatePlatformLinks(
              "customize",
              "facebook",
              "story",
              this.newLinkHref,
              this.newLinkName
            );
            break;
          case "facebook_reel":
            this.store.UpdatePlatformLinks(
              "customize",
              "facebook",
              "reel",
              this.newLinkHref,
              this.newLinkName
            );
            break;
          case "instagram_post":
            this.store.UpdatePlatformLinks(
              "customize",
              "instagram",
              "post",
              this.newLinkHref,
              this.newLinkName
            );
            break;
          case "instagram_story":
            this.store.UpdatePlatformLinks(
              "customize",
              "instagram",
              "story",
              this.newLinkHref,
              this.newLinkName
            );
            break;
          case "instagram_reel":
            this.store.UpdatePlatformLinks(
              "customize",
              "instagram",
              "reel",
              this.newLinkHref,
              this.newLinkName
            );
            break;

          case "tiktok_post":
            this.store.UpdatePlatformLinks(
              "customize",
              "tiktok",
              "post",
              this.newLinkHref,
              this.newLinkName
            );
            break;
          case "tiktok_story":
            this.store.UpdatePlatformLinks(
              "customize",
              "tiktok",
              "story",
              this.newLinkHref,
              this.newLinkName
            );
            break;
          case "snapchat_story":
            this.store.UpdatePlatformLinks(
              "customize",
              "snapchat",
              "story",
              this.newLinkHref,
              this.newLinkName
            );
            break;
          case "x_post":
            this.store.UpdatePlatformLinks(
              "customize",
              "x",
              "post",
              this.newLinkHref,
              this.newLinkName
            );
            break;
          default:
            break;
        }
        this.newLinkName = "";
        this.newLinkHref = "";
      } else {
        alert("Please provide both link name and URL.");
      }
    },
    removeLink(index, platformsfor, platform, typeKey) {
      this.links.splice(index, 1);
      if (typeKey === "story_and_reel") {
        this.store.RemovePlatformLinks(platformsfor, platform, "story");
        this.store.RemovePlatformLinks(platformsfor, platform, "reel");
      } else {
        this.store.RemovePlatformLinks(platformsfor, platform, typeKey);
      }
    },

    handleFileSelected(res, typeOfSocilaMedia) {
      switch (typeOfSocilaMedia) {
        case "allPostContent":
          this.mediaFilsPost.push(res[0]);
          this.store.updatePlatformFile("customize", "", "post", res[0]);
          break;
        case "allReelStoryContent":
          this.mediaFilsPost.push(res[0]);
          this.store.updatePlatformFile("customize", "", "reel", res[0]);
          this.store.updatePlatformFile("customize", "", "story", res[0]);
          break;
        case "facebookPostContent":
          this.facebookPostMedia.push(res[0]);
          this.store.updatePlatformFile(
            "customize",
            "facebook",
            "post",
            res[0]
          );

          break;
        case "instagramPostContent":
          this.instagramPostMedia.push(res[0]);
          this.store.updatePlatformFile(
            "customize",
            "instagram",
            "post",
            res[0]
          );
          break;
        case "tikTokPostContent":
          this.tikTokPostMedia.push(res[0]);
          this.store.updatePlatformFile("customize", "tiktok", "post", res[0]);

          break;
        case "snapChatPostContent":
          this.snapChatPostMedia.push(res[0]);
          this.store.updatePlatformFile(
            "customize",
            "snapchat",
            "post",
            res[0]
          );

          break;
        case "facebookStoryContant":
          this.facebookStoryMedia.push(res[0]);
          this.store.updatePlatformFile(
            "customize",
            "facebook",
            "story",
            res[0]
          );

          break;
        case "facebookReelContent":
          this.facebookReelMedia.push(res[0]);
          this.store.updatePlatformFile(
            "customize",
            "facebook",
            "reel",
            res[0]
          );

          break;
        case "instagramStoryContent":
          this.instagramStoryMedia.push(res[0]);
          this.store.updatePlatformFile(
            "customize",
            "instagram",
            "story",
            res[0]
          );

          break;
        case "instagramReelContent":
          this.instagramReelMedia.push(res[0]);
          this.store.updatePlatformFile(
            "customize",
            "instagram",
            "reel",
            res[0]
          );

          break;
        case "tiktokStoryContent":
          this.tikTokStoryMedia.push(res[0]);
          this.store.updatePlatformFile("customize", "tiktok", "story", res[0]);

          break;
        case "snapchatStoryContent":
          this.snapchatStoryMedia.push(res[0]);
          this.store.updatePlatformFile(
            "customize",
            "snapchat",
            "story",
            res[0]
          );

          break;
        case "xPostContent":
          this.xPostMedia.push(res[0]);
          this.store.updatePlatformFile("customize", "x", "post", res[0]);

          break;
      }
    },

    removeImage(index, array) {
      array.splice(index, 1);
    },
    fetchLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            this.reverseGeocode(latitude, longitude);
          },
          (error) => {
            console.error("Error fetching location: ", error.message);
            this.allPostContent = "Unable to fetch location.";
          }
        );
      } else {
        this.city = "Geolocation is not supported by this browser.";
      }
    },
    async reverseGeocode(lat, lng) {
      const apiKey = "AIzaSyAYugMkEQTvSJa9xsKlA2ixqZD7UFCeOtU";
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.results && data.results.length > 0) {
          const addressComponents = data.results[0].address_components;
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );

          this.allPostContent = cityComponent
            ? cityComponent.long_name
            : "City not found.";
        } else {
          this.allPostContent = "Unable to fetch city.";
        }
      } catch (error) {
        console.error("Error reverse geocoding: ", error);
        this.allPostContent = "Error fetching city.";
      }
    },
    customizeByPlatformType() {
      this.$emit("customizeByPlatformType", this.customizeByPlatformType);
    },
    ...mapState(publishPost, ["UpdatePlatformContents"]),
  },
  watch: {
    allPostContent(newContent) {
      this.store.UpdatePlatformContents("customize", null, "post", newContent);
      this.longOfText = newContent.length;
    },
    allReelStoryContent(newContent) {
      this.store.UpdatePlatformContents("customize", null, "reel", newContent);
      this.store.UpdatePlatformContents("customize", null, "story", newContent);
      this.longOfText = newContent.length;
    },
    facebookPostContent(newContent) {
      this.store.UpdatePlatformContents(
        "customize",
        "facebook",
        "post",
        newContent
      );
    },
    instagramPostContent(newContent) {
      this.store.UpdatePlatformContents(
        "customize",
        "instagram",
        "post",
        newContent
      );
    },
    tikTokPostContent(newContent) {
      this.store.UpdatePlatformContents(
        "customize",
        "tiktok",
        "post",
        newContent
      );
    },
    xPostContent(newContent) {
      this.store.UpdatePlatformContents("customize", "x", "post", newContent);
    },
    snapChatPostContent(newContent) {
      this.store.UpdatePlatformContents(
        "customize",
        "snapchat",
        "post",
        newContent
      );
    },
    facebookStoryContent(newContent) {
      this.store.UpdatePlatformContents(
        "customize",
        "facebook",
        "story",
        newContent
      );
    },
    facebookReelContent(newContent) {
      this.store.UpdatePlatformContents(
        "customize",
        "facebook",
        "reel",
        newContent
      );
    },
    instagramStoryContent(newContent) {
      this.store.UpdatePlatformContents(
        "customize",
        "instagram",
        "story",
        newContent
      );
    },
    instagramReelContent(newContent) {
      this.store.UpdatePlatformContents(
        "customize",
        "instagram",
        "reel",
        newContent
      );
    },
    tikTokStoryContent(newContent) {
      this.store.UpdatePlatformContents(
        "customize",
        "tiktok",
        "story",
        newContent
      );
    },
    snapChatStoryContent(newContent) {
      this.store.UpdatePlatformContents(
        "customize",
        "snapchat",
        "story",
        newContent
      );
    },

    // "store.getPost": {
    //   handler(newSelectedTypes, oldSelectedTypes) {
    //     let getContent = (platform, type) => {
    //       let entry = this.store.getPost[platform]?.find(
    //         (el) => el.type === type
    //       );
    //       return entry ? entry.content : "";
    //     };
    //     this.facebookPostContent = getContent("facebook", "post");
    //     this.instagramPostContent = getContent("instagram", "post");
    //     this.tikTokPostContent = getContent("tiktok", "post");
    //     this.xPostContent = getContent("x", "post");
    //     this.snapChatPostContent = getContent("snapchat", "post");
    //     this.facebookStoryContent = getContent("facebook", "story");
    //     this.facebookReelContent = getContent("facebook", "reel");
    //     this.instagramStoryContent = getContent("instagram", "story");
    //     this.instagramReelContent = getContent("instagram", "reel");
    //     this.tikTokStoryContent = getContent("tiktok", "story");
    //     this.snapChatStoryContent = getContent("snapchat", "story");
    //   },
    //   deep: true,
    // },
  },
  mounted() {},
};
</script>

<style scoped>
.emoji-picker-wrapper {
  /* position: absolute; */

  left: 0;
  z-index: 1000;
  margin-bottom: 8px;
}

emoji-picker {
  --num-columns: 8;
  width: 320px;
  height: 400px;
}
.image-preview-overlay {
  position: absolute;
  bottom: 50px;
  height: 40px;
  overflow: auto;
  width: 90%;
}
.image-preview-overlay .image-preview-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.image-preview-overlay .image-preview-container img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
}
</style>
