<template>
  <v-col :cols="12" :lg="6" class="d-flex ga-5 align-center">
    <div class="d-flex ga-1 align-center input-data-text">
      <TagIcon />
      <label class="calender-input-label text-capitalize" for="tags"
        >Tags</label
      >
    </div>
    <div class="tags-input">
      <span class="d-flex align-center ga-2">
        <span v-for="(tag, i) in store.tags" :key="i" class="tag">{{
          tag
        }}</span>
      </span>
    </div>
  </v-col>
</template>

<script>
import { useEditTaskStore } from "@/store/editTaskStore";
import inputTags from "vue3-tags-input";
import TagIcon from "./icons/TagIcon.vue";

export default {
  name: "TaskTags",
  components: {
    inputTags,
    TagIcon,
  },
  data() {
    return {
      store: useEditTaskStore(),
    };
  },
  methods: {
    handleChangeTag(tags) {
      this.store.tags = tags;
    },
  },
  watch: {},
};
</script>
<style scoped>
.tag {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  color: #666;
  font-size: 0.9rem;
}
</style>
