
import ErrorPage from "@/views/ErrorPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import HomePage from "@/views/HomePage.vue";
import Home from "@/views/portfolio/Home.vue";
import Privacy from "@/views/portfolio/Privacy.vue";
import Terms from "@/views/portfolio/Terms.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import NewIframe from "@/views/NewIframe.vue";
import BoostPost from "@/views/BoostPost.vue";

const generalRoutes = [
  
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: ErrorPage,
    meta: {
      title: "Not Found",
      description: "",
    },
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
    meta: {
      title: "login",
      description: "",
      auth: "unauth",
    },
  },
  {
    path: "/home-page",
    name: "HomePage",
    component: HomePage,
    meta: {
      title: "HomePage",
      description: "",
      auth: "auth",
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy",
      description: "",
      auth: "unauth",
    },
  },
  {
    path: "/",
    name: "portfolio",
    component: Home,
    meta: {
      title: "portfolio",
      description: "",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/new-iframe",
    name: "NewIframe",
    component: NewIframe,
  },
  {
    path: "/publish-post/boots-post",
    name: "BoostPost",
    component: BoostPost,
    meta: {
      title: "Boost post",
      description: "",
      auth: "auth",
      permissions: [
        "publish-posts.create",
        "publish-posts.read",
        "publish-posts.update",
        "publish-posts.delete",
      ],
    },
  },
];

export default generalRoutes;
