import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";
import { useGeneralStore } from "@/store/General";
import snapchatIcon from "@/assets/images/icons/snapcaht.svg";
import xIcon from "@/assets/images/icons/x.svg";
import instagramIcon from "@/assets/images/icons/instagram.svg";
import facebookIcon from "@/assets/images/icons/facebook.svg";
import tiktokIcon from "@/assets/images/icons/tiktok.svg";
import phoneIcon from "@/assets/images/icons/phone.svg";
import LinkedInIcon from "@/assets/images/icons/linkedIn.svg";
export const useEditTaskStore = defineStore("useEditTaskStore", {
  state: () => ({
    title: "",
    status: { title: "To Do", color: "rgba(83, 100, 113, 1)" },
    tags: [],
    priority: "",
    description: "",
    time_estimate: "",
    from: null,
    to: null,
    assignees: [],
    add_to_social_media: 0,
    caption: "",
    type: "",
    platforms: [],
    branches: [],
    budget: "",
    budget_type: "",
    duration: "",
    users: [],
    status_list: [
      { title: "To Do", key: "todo", color: "rgba(83, 100, 113, 1)" },
      { title: "In Progress", key: "progress", color: "rgba(0, 133, 255, 1)" },
      { title: "Completed", key: "completed", color: "rgba(0, 186, 52, 1)" },
    ],
    priority_list: [
      { title: "urgent", color: "rgba(233, 44, 44, 1)" },
      { title: "high", color: "rgba(229, 160, 0, 1)" },
      { title: "normal", color: "rgba(0, 133, 255, 1)" },
      { title: "law", color: "rgba(83, 100, 113, 1)" },
      { title: "clear", color: "rgba(102, 102, 102, 1)" },
    ],
    branches_list: [],
    platforms_list: [],
    sponsor_end_date: null,
    setEndDate: false,
    attachments: [],
    comments: [],
    general_store: useGeneralStore(),
    errors: {},
    last_attachment: [],
    last_comments: [],
    Current_task_id: null,
  }),
  getters: {},
  actions: {
    getUsers() {
      try {
        apiClient
          .get("/leads-managment/all-agents")
          .then((response) => {
            this.users = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        console.error("Error fetching Drive:", err);
      }
    },
    updateStatus(status) {
      this.status = status;
    },
    validate() {
      const errors = {};

      // Validate 'title'
      if (!this.title) {
        errors.title = "Title is required.";
      } else if (typeof this.title !== "string") {
        errors.title = "Title must be a string.";
      }

      // Validate 'status'
      const validStatuses = ["todo", "progress", "completed"];
      if (!validStatuses.includes(this.status.key.toLowerCase())) {
        errors.status = "Invalid status.";
      }

      // Validate 'tags'
      if (this.tags?.some((tag) => typeof tag !== "string")) {
        errors.tags = "All tags must be strings.";
      }

      // Validate 'priority'
      const validPriorities = ["urgent", "high", "normal", "low"];
      if (!validPriorities.includes(this.priority.title.toLowerCase())) {
        errors.priority = "Invalid priority.";
      }

      // Validate 'from'
      if (!this.from) {
        errors.from = "Start date is required.";
      } else if (isNaN(Date.parse(this.from))) {
        errors.from = "Start date must be a valid date.";
      }

      // Validate 'to'
      if (this.to && isNaN(Date.parse(this.to))) {
        errors.to = "End date must be a valid date.";
      }

      // Validate 'type'
      const validTypes = ["organic", "sponsored"];
      if (!validTypes.includes(this.type.toLowerCase())) {
        errors.type = "Invalid type.";
      }

      // Validate 'budget'
      if (this.budget && isNaN(parseFloat(this.budget))) {
        errors.budget = "Budget must be a numeric value.";
      }

      // Validate 'budget_type'
      const validBudgetTypes = ["daily", "total"];
      if (
        this.budget_type &&
        !validBudgetTypes.includes(this.budget_type.toLowerCase())
      ) {
        errors.budget_type = "Invalid budget type.";
      }

      // Validate 'platforms'
      const validPlatforms = ["facebook", "snapchat", "instagram", "twitter"];
      if (
        this.platforms.some(
          (platform) => !validPlatforms.includes(platform.name.toLowerCase())
        )
      ) {
        errors.platforms = "Invalid platform(s).";
      }

      // Validate 'branches'
      // if (this.branches.length === 0) {
      //   errors.branches = "branches is required.";
      // }
      this.errors = errors;
      return Object.keys(errors).length > 0 ? errors : null;
    },
    formatDateTime(date) {
      if (!date) return null;
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      const hours = String(d.getHours()).padStart(2, "0");
      const minutes = String(d.getMinutes()).padStart(2, "0");
      const seconds = String(d.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    SendTask() {
      const validationErrors = this.validate();
      if (validationErrors) {
        this.general_store?.showSnackbar(
          "Validation errors occurred.",
          "error"
        );
        console.log("Validation Errors:", validationErrors);
        return;
      }

      try {
        const formData = new FormData();

        // Append simple fields
        formData.append("title", this.title);
        formData.append("status", this.status.key);
        formData.append("priority", this.priority.title);
        formData.append("description", this.description);
        formData.append("time_estimate", this.time_estimate);
        formData.append("from", this.formatDateTime(this.from));
        formData.append("to", this.formatDateTime(this.to));
        formData.append("add_to_social_media", this.add_to_social_media);
        formData.append("caption", this.caption);
        formData.append("type", this.type);
        formData.append("budget", this.budget);
        formData.append("budget_type", this.budget_type);
        formData.append("duration", this.duration);
        formData.append(
          "sponsor_end_date",
          this.formatDateTime(this.sponsor_end_date)
        );
        formData.append("setEndDate", this.setEndDate);
        this.tags.forEach((tag, index) => {
          formData.append(`tags[${index}]`, tag);
        });
        // Append arrays
        this.platforms.forEach((platform, index) => {
          formData.append(`platforms[${index}]`, platform.name);
        });

        this.branches.forEach((branch, index) => {
          formData.append(`branches[${index}]`, branch.name);
        });

        this.assignees.forEach((assignee, index) => {
          formData.append(`assignees[${index}]`, assignee);
        });
        this.attachments.forEach((attachment, index) => {
          console.log(attachment);
          formData.append(`attachment[${index}]`, attachment);
        });
        for (let [key, value] of formData.entries()) {
          console.log(key, value);
        }
        apiClient
          .post(`/calender/tasks/${this.Current_task_id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.general_store?.showSnackbar("Task has been sent", "success");
          })
          .catch((error) => {
            console.error("Error sending task:", error);
            this.general_store?.showSnackbar("Error Sending Data", "error");
          });
      } catch (err) {
        console.error("Error sending task:", err);
        this.general_store?.showSnackbar("Error Sending Data", "error");
      }
    },
    convertIsoDateToNormal(d) {
      const date = new Date(d);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return (formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
    },
    convertNormalDateToIso(d) {
      const [datePart, timePart] = d.split(" ");
      const [year, month, day] = datePart.split("-").map(Number);
      const [hours, minutes, seconds] = timePart.split(":").map(Number);

      const date = new Date(year, month - 1, day, hours, minutes, seconds);

      date.setHours(date.getHours() + 2);

      return date.toString();
    },
    getATask(id) {
      this.Current_task_id = id;
      apiClient
        .get(`/calender/tasks/${id}`)
        .then((res) => {
          let task = res.data.data;
          console.log(task);

          this.title = task.title;
          this.status = this.status_list.find((s) => s.key === task.status);

          this.tags = task.tags;

          this.from = new Date(task.time.start);
          this.to = new Date(task.time.end);

          this.priority = this.priority_list.find(
            (s) => s.title === task.priority
          );

          this.assignees = task.assignes.map((a) => {
            return a.id;
          });

          this.description = task.description;
          this.time_estimate = task.time_estimate;

          this.add_to_social_media = task.add_to_social_media;
          this.caption = task.social.caption;
          this.type = task.social.type;

          this.platforms = this.platforms_list.filter((p) =>
            task.social.platforms.includes(p.name)
          );
          this.branches = this.branches_list.filter((b) =>
            task.social.branches.includes(b.name)
          );
          this.budget = task.social.budget;
          this.budget_type = task.social.budget_type;
          this.duration = task.social.duration;

          this.sponsor_end_date = new Date(task.social.end_date);
          this.setEndDate = this.sponsor_end_date ? true : false;

          this.last_attachment = task.attachments;
          this.last_comments = task.comments;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBranches() {
      console.log(this.platforms.map((p) => p.key));

      apiClient
        .get("/all-platfroms-pages", {
          params: { platforms: this.platforms.map((p) => p.key) },
        })
        .then((response) => { 
          this.branches_list = response.data.data.map((b) => {
            return {
              id: b.id,
              name: b.name,
              image: b.picture,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPlatforms() {
      apiClient
        .get("/all/integrations")
        .then((response) => {
          this.platforms_list = response.data.data.map((p) => {
            return {
              id: p.id,
              name: p.platform,
              key: p.platform,
              icon: p.icon,
              color:
                p.name === "facebook"
                  ? "#1877F2"
                  : p.name === "instagram"
                  ? "#E4405F"
                  : p.name === "tiktok"
                  ? "#000000"
                  : p.name === "twitter"
                  ? "#1DA1F2"
                  : p.name === "youtube"
                  ? "#FF0000"
                  : "#757575",
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
