<template>
  <div class="d-flex flex-column gap-3">
    <v-expansion-panels>
      <v-expansion-panel bg-color="transparent">
        <v-expansion-panel-title v-slot="{ expanded }" hide-actions>
          <div class="d-flex align-center ga-2">
            <v-icon
              class="upload-span"
              style="padding: 0px; width: 32px"
              :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            ></v-icon>
            <div class="d-flex flex-column ga-1">
              <span>Custom Question</span>
              <span class="small-text"
                >Ask questions related to your business.</span
              >
            </div>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-devider></v-devider>
          <SingleQuestion
            v-for="(question, index) in store.questions"
            :key="question"
            :question="question"
            :index="index"
          />
          <v-menu>
            <template v-slot:activator="{ props }">
              <button
                v-bind="props"
                class="d-flex align-center justify-space-between upload-span"
              >
                <v-icon icon="mdi-plus" class="Caption"></v-icon>
                <span>Add Question</span>
                <v-icon icon="mdi-chevron-down" class="small-text"></v-icon>
              </button>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                :value="index"
              >
                <v-list-item-title>
                  <div
                    class="d-flex flex-column ga-1 select-cont"
                    @click="selectQuestionType(item.value)"
                  >
                    <span class="select-title">{{ item.title }}</span>
                    <span class="small-text">{{ item.description }}</span>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-devider></v-devider>
      <v-expansion-panel bg-color="transparent">
        <v-expansion-panel-title v-slot="{ expanded }" hide-actions>
          <div class="d-flex align-center ga-2">
            <v-icon
              class="upload-span"
              style="padding: 0px; width: 32px"
              :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            >
            </v-icon>
            <div class="d-flex flex-column ga-1">
              <span>Personal Information</span>
              <span class="small-text"
                >Ask for user information. This will be prefilled from their
                account.</span
              >
            </div>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="mx-4">
          <div
            v-for="(field, index) in store.fields"
            :key="field"
            class="d-flex justify-space-between align-center field-row-group"
          >
            <div class="d-flex ga-2 align-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="rgb(26, 153, 100)"
                xmlns="http://www.w3.org/2000/svg"
                class="sds-icon sds-icon-check sds-checkbox-readonly-icon styles-178t7jo"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                ></path>
              </svg>
              <span>{{ field.title }}</span>
            </div>
            <svg
              v-if="field.value !== 'first_name' && field.value !== 'last_name'"
              @click="RemoveField(index)"
              width="12"
              height="12"
              viewBox="0 0 16 16"
              fill="#444546"
              xmlns="http://www.w3.org/2000/svg"
              class="sds-icon sds-icon-cross cursor-pointer delete-icon"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
              ></path>
            </svg>
          </div>
          <v-menu>
            <template v-slot:activator="{ props }">
              <button
                v-bind="props"
                class="d-flex align-center justify-space-between upload-span"
              >
                <v-icon icon="mdi-plus" class="Caption"></v-icon>
                <span>Add Fields</span>
                <v-icon icon="mdi-chevron-down" class="small-text"></v-icon>
              </button>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in fields"
                :key="index"
                :value="index"
              >
                <v-list-item-title>
                  <div
                    class="d-flex flex-column ga-1 select-cont"
                    @click="addField(item)"
                  >
                    <span class="select-title">{{ item.title }}</span>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import SingleQuestion from "./SingleQuestion.vue";
import { useLeadFormStore } from "@/store/snapchat/LeadForm";

export default {
  name: "QuestionsForm",
  components: {
    SingleQuestion,
  },
  data() {
    return {
      items: [
        {
          title: "Multiple Choice",
          description: "Choose one answer from a list of options.",
          value: "MULTIPLE_CHOICE_SINGLE_SELECTION",
        },
        {
          title: "Multiple Selection",
          description: "Choose more than one answer from a list of options.",
          value: "MULTIPLE_CHOICE_MULTI_SELECTION",
        },
        {
          title: "Date Picker",
          description: "Choose a date to show availability.",
          value: "DATE",
        },
        {
          title: "Short Answer",
          description: "Give more info in a sentence or two.",
          value: "TEXT",
        },
      ],
      fields: [
        {
          title: "Phone Number",
          value: "PHONE_NUMBER",
        },
        {
          title: "Address",
          value: "ADDRESS",
        },
        {
          title: "Email",
          value: "EMAIL",
        },
        {
          title: "Postal Code",
          value: "POSTAL_CODE",
        },
        {
          title: "Birth Date",
          value: "BIRTH_DATE",
        },
        {
          title: "Job Title",
          value: "JOB_TITLE",
        },
        {
          title: "Company Name",
          value: "COMPANY_NAME",
        },
      ],
      store: useLeadFormStore(),
    };
  },
  methods: {
    selectQuestionType(item) {
      this.store.addQuestion(item);
    },
    addField(item) {
      this.store.fields.push(item);
    },
    RemoveField(index) {
      if (
        this.store.fields[index].value !== "first_name" &&
        this.store.fields[index].value !== "last_name"
      ) {
        this.store.fields.splice(index, 1);
      }
    },
  },
};
</script>
<style scoped>
.small-text {
  font-size: 12px;
  color: rgb(132, 134, 136);
  font-weight: 400;
  white-space: nowrap;
}

.select-title {
  font-weight: 500;
}

.select-cont:hover .select-title {
  color: #1890ff;
}
</style>
<style scoped>
input {
  border: 2px solid transparent;
  border-radius: 8px !important;
  background: #f9f9fa;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0 0 0 0 transparent;
  box-sizing: border-box;
  font-family: inherit;
  transition: box-shadow 0.15s ease-out 0.15s, border-color 0.15s,
    background 0.15s ease-out 0.15s, color 0.15s ease-out;
  width: 100%;
  border: transparent 2px solid;
  height: 40px;
}
</style>
