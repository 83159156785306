<template>
  <v-container fluid style="padding: 0; min-height: 100vh">
    <v-row no-gutters>
      <v-col cols="12">
        <GlobalNav></GlobalNav>
      </v-col>
      <v-col cols="12" class="publish-post-view">
        <div class="publish-post-contain d-flex h-100">
          <SideBar></SideBar>
          <div class="publish-post overflow-auto">
            <BreadCrumb :linksData="linksData" class="mb-5" />
            <div class="create-review-post">
              <div class="careate-new-post">
                <!-- head section  -->
                <div class="create-new-post-header mb-3">
                  <p>Create new post</p>
                  <div
                    class="filter-by-size d-flex ga-3 align-center"
                    v-if="customizeByPlatformType"
                  >
                    <p>Filter by size</p>
                    <div class="d-flex">
                      <button
                        :class="storyContant ? '' : 'active'"
                        @click="updateStoryContant"
                      >
                        <svg
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.9526 14H1.4263C0.98419 14 0.689453 13.7053 0.689453 13.2632V0.736842C0.689453 0.294737 0.98419 0 1.4263 0H13.9526C14.3947 0 14.6895 0.294737 14.6895 0.736842V13.2632C14.6895 13.7053 14.3947 14 13.9526 14ZM2.16314 12.5263H13.2158V1.47368H2.16314V12.5263Z"
                            :fill="storyContant ? '#237276' : '#E6E6E6'"
                          />
                        </svg>
                      </button>
                      <button
                        :class="storyContant ? 'active' : ''"
                        @click="updateStoryContant"
                      >
                        <svg
                          width="12"
                          height="21"
                          viewBox="0 0 12 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.689453 1.19767L0.689453 19.8023C0.689453 20.1884 1.00108 20.5 1.38713 20.5H10.6895C11.0755 20.5 11.3871 20.1884 11.3871 19.8023L11.3871 1.19767C11.3871 0.811626 11.0755 0.5 10.6895 0.5H1.38713C1.00108 0.5 0.689453 0.811626 0.689453 1.19767ZM9.99178 1.89535L9.99178 19.1047H2.0848L2.0848 1.89535H9.99178Z"
                            :fill="storyContant ? '#E6E6E6' : '#237276'"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- platforms  -->
                <div class="select-publish-type mb-4">
                  <!-- facebook  -->
                  <div class="facebook-select-item d-flex align-center ga-5">
                    <v-badge
                      :content="selectedFacebookTypes.length"
                      @click="
                        selectedFacebookTypes = facebook_options.map(
                          (item) => item.id
                        )
                      "
                    >
                      <img
                        src="@/assets/soft-ware-img/facebook-post-select.svg"
                        alt=""
                      />
                    </v-badge>
                    <treeselect
                      v-model="selectedFacebookTypes"
                      :options="facebook_options"
                      :multiple="true"
                      :clearable="false"
                      placeholder="Select options"
                    />
                  </div>
                  <!-- instagram -->
                  <div class="facebook-select-item d-flex align-center ga-5">
                    <v-badge
                      :content="selectedInstgramTypes.length"
                      @click="
                        selectedInstgramTypes = instagram_options.map(
                          (item) => item.id
                        )
                      "
                    >
                      <img
                        src="@/assets/soft-ware-img/instigram-select.svg"
                        alt=""
                      />
                    </v-badge>
                    <treeselect
                      v-model="selectedInstgramTypes"
                      :options="instagram_options"
                      :multiple="true"
                      :clearable="false"
                      placeholder="Select options"
                    />
                  </div>
                  <!-- x  -->
                  <div class="facebook-select-item d-flex align-center ga-5">
                    <v-badge
                      :content="selectedXTypes.length"
                      @click="selectedXTypes = x_options.map((item) => item.id)"
                    >
                      <img
                        src="@/assets/soft-ware-img/twitter-x-select-icon.svg"
                        alt=""
                      />
                    </v-badge>

                    <treeselect
                      v-model="selectedXTypes"
                      :options="x_options"
                      :multiple="true"
                      :clearable="false"
                      placeholder="Select options"
                    />
                  </div>
                  <!-- tiktok  -->
                  <div class="facebook-select-item d-flex align-center ga-5">
                    <v-badge
                      :content="selectedtikTokTypes.length"
                      @click="
                        selectedtikTokTypes = tiktok_options.map(
                          (item) => item.id
                        )
                      "
                    >
                      <img
                        src="@/assets/soft-ware-img/tik-tok-select-icon.svg"
                        alt=""
                      />
                    </v-badge>
                    <treeselect
                      v-model="selectedtikTokTypes"
                      :options="tiktok_options"
                      :multiple="true"
                      :clearable="false"
                      placeholder="Select options"
                    />
                  </div>
                  <!-- snapchat  -->
                  <div class="facebook-select-item d-flex align-center ga-5">
                    <v-badge
                      :content="selectedSnapchatTypes.length"
                      @click="
                        selectedSnapchatTypes = snapchat_options.map(
                          (item) => item.id
                        )
                      "
                    >
                      <img
                        style="width: 36px; height: 36px"
                        src="@/assets/soft-ware-img/snapchat-icoon-ccampaign.svg"
                        alt=""
                      />
                    </v-badge>
                    <treeselect
                      v-model="selectedSnapchatTypes"
                      :options="snapchat_options"
                      :multiple="true"
                      :clearable="false"
                      placeholder="Select options"
                    />
                  </div>
                </div>
                <!-- toggle customization  -->
                <div class="customize-platform mb-4">
                  <label class="toggle-switch">
                    <input
                      type="checkbox"
                      v-model="customizeByPlatformType"
                      id="customizeByPlatform"
                    />
                    <div class="toggle-switch-background">
                      <div class="toggle-switch-handle"></div>
                    </div>
                  </label>
                  <label for="customizeByPlatform" class="label-for-input"
                    >Customize by platform
                  </label>
                </div>
                <!-- global content  -->
                <div
                  v-if="!customizeByPlatformType"
                  class="contant-social-post-default mb-4 blur"
                >
                  <div class="card positon-relative">
                    <!-- textarea  -->
                    <textarea
                      :disabled="!store?.getCarouselSlider?.length"
                      placeholder="Write the content that you will use as a template on the rest of the platforms"
                      name=""
                      id=""
                      rows="15"
                      maxlength="2500"
                      v-model="postContent"
                      ref="textarea"
                    ></textarea>
                    <!-- images  -->
                    <div v-if="allMediaFils" class="image-preview-overlay">
                      <div
                        v-for="(media, index) in allMediaFils"
                        :key="index"
                        class="image-preview-container"
                      >
                        <img :src="media.preview" alt="Preview" />
                        <button
                          class="remove-image"
                          @click="removeImage(index, allMediaFils)"
                        >
                          ×
                        </button>
                      </div>
                    </div>
                    <!-- actions  -->
                    <div
                      class="textarea-actions d-flex align-center justify-space-between"
                    >
                      <div class="btn-groups d-flex align-start ga-3">
                        <!-- upload image  -->
                        <ImageMenu
                          :disabled="!store?.getCarouselSlider?.length"
                          @fileSelected="
                            (res) => handleFileSelected(res, 'allPostContent')
                          "
                          @driveSelect="handleDriveSelect"
                          @editImage="handleEditImage"
                        />
                        <!-- emojis  -->
                        <button
                          :disabled="!store?.getCarouselSlider?.length"
                          class="btn-post"
                          @click="toggleEmojiPicker"
                        >
                          <img
                            src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                            alt=""
                          />
                        </button>
                        <!-- link  -->
                        <button
                          :disabled="!store?.getCarouselSlider?.length"
                          class="btn-post"
                          @click="enterPostlink = true"
                        >
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.26907 11.7688C7.68157 11.1813 8.56907 10.3001 9.15657 10.8876C10.1316 11.8626 11.7128 11.8626 12.6941 10.8876L16.6691 6.9126C17.6441 5.9376 17.6566 4.3626 16.6691 3.3751C15.6816 2.3876 14.1128 2.4001 13.1316 3.3751L10.9191 5.5876C10.3316 6.1751 9.45032 5.2876 10.0378 4.70635L12.2503 2.49385C13.7128 1.03135 16.0878 1.03135 17.5566 2.49385C19.0253 3.95635 19.0191 6.33135 17.5566 7.8001L13.5816 11.7751C12.1191 13.2376 9.74407 13.2376 8.27532 11.7751L8.26907 11.7688Z"
                              :fill="links.length > 0 ? 'green' : '#666666'"
                            />
                            <path
                              d="M2.52598 17.5124C1.05723 16.0437 1.06348 13.6749 2.52598 12.2062L6.50098 8.23115C7.96348 6.76865 10.3385 6.76865 11.8072 8.23115C12.3947 8.81865 11.5072 9.6999 10.9197 9.1124C9.94473 8.1374 8.36348 8.1374 7.38223 9.1124L3.40723 13.0874C2.43223 14.0624 2.41973 15.6374 3.40723 16.6249C4.39473 17.6124 5.96348 17.5999 6.94473 16.6249L9.15723 14.4124C9.74473 13.8249 10.626 14.7124 10.0385 15.2937L7.82598 17.5062C6.36348 18.9687 3.98848 18.9687 2.51973 17.5062L2.52598 17.5124Z"
                              :fill="links.length > 0 ? 'green' : '#666666'"
                            />
                          </svg>
                        </button>
                        <!-- comment  -->
                        <!-- <button class="btn-post">
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.0381 16.6668C14.1798 16.6668 17.5381 13.6818 17.5381 10.0002C17.5381 6.3185 14.1798 3.3335 10.0381 3.3335C5.89642 3.3335 2.53809 6.3185 2.53809 10.0002C2.53809 11.3118 2.96475 12.5352 3.70059 13.5668L2.53809 16.6668L6.49225 15.876C7.60041 16.4008 8.81195 16.671 10.0381 16.6668Z"
                              stroke="#666666"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button> -->
                        <!-- location  -->
                        <!-- <button class="btn-post" @click="fetchLocation">
                          <svg
                            width="13"
                            height="18"
                            viewBox="0 0 13 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.59473 17.1114C5.59473 17.1114 5.59484 17.1116 6.03845 16.7964L6.48207 17.1116L6.03845 17.7274L5.59473 17.1114Z"
                              fill="#666666"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.03782 3.52344C4.53159 3.52344 3.31055 4.7362 3.31055 6.23222C3.31055 7.72824 4.53159 8.94095 6.03782 8.94095C7.54404 8.94095 8.76509 7.72824 8.76509 6.23222C8.76509 4.7362 7.54404 3.52344 6.03782 3.52344ZM4.40146 6.23222C4.40146 5.3346 5.13408 4.60694 6.03782 4.60694C6.94158 4.60694 7.67418 5.3346 7.67418 6.23222C7.67418 7.12982 6.94158 7.85746 6.03782 7.85746C5.13408 7.85746 4.40146 7.12982 4.40146 6.23222Z"
                              fill="#666666"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.59436 17.1115L6.03809 16.7965C6.4817 17.1117 6.48209 17.1111 6.48209 17.1111L6.48612 17.1055L6.49829 17.0886L6.54492 17.0231C6.58556 16.966 6.64485 16.8822 6.72018 16.7746C6.87083 16.5596 7.08563 16.2496 7.34325 15.8685C7.8581 15.1066 8.54543 14.0583 9.23363 12.9142C9.92069 11.7719 10.6143 10.5248 11.1379 9.36715C11.6517 8.23113 12.0381 7.10504 12.0381 6.23226C12.0381 2.94101 9.35178 0.272949 6.03809 0.272949C2.72438 0.272949 0.0380859 2.94101 0.0380859 6.23226C0.0380859 7.10504 0.424464 8.23113 0.938255 9.36715C1.46183 10.5248 2.15548 11.7719 2.84256 12.9142C3.53073 14.0583 4.21807 15.1066 4.73293 15.8685C4.99052 16.2496 5.20532 16.5596 5.35598 16.7746C5.43131 16.8822 5.49063 16.966 5.53125 17.0231L5.57789 17.0886L5.59005 17.1055L5.59436 17.1115ZM1.129 6.23226C1.129 3.53942 3.32687 1.35646 6.03809 1.35646C8.74932 1.35646 10.9472 3.53942 10.9472 6.23226C10.9472 6.85544 10.6518 7.79788 10.1428 8.92315C9.64365 10.0268 8.97367 11.2337 8.29709 12.3585C7.62165 13.4815 6.94534 14.5131 6.43747 15.2646C6.28823 15.4855 6.15367 15.6819 6.03809 15.8491C5.9225 15.6819 5.78794 15.4855 5.6387 15.2646C5.13083 14.5131 4.45453 13.4815 3.77907 12.3585C3.10251 11.2337 2.43253 10.0268 1.93337 8.92315C1.42444 7.79788 1.129 6.85544 1.129 6.23226Z"
                              fill="#666666"
                            />
                          </svg>
                        </button> -->
                      </div>
                      <!-- text long -->
                      <div class="word-length">
                        <span> {{ longOfText }} </span> / <span>2500</span>
                      </div>
                    </div>
                    <!-- link dialog  -->
                    <v-dialog
                      class="links-dialog"
                      v-model="enterPostlink"
                      width="400px"
                    >
                      <v-card max-width="400" title="Add link">
                        <div
                          class="add-link-dialog d-flex flex-column w-100 pa-3"
                        >
                          <input
                            v-model="newLinkName"
                            placeholder="Enter link name"
                            class="enter-link-name"
                            type="text"
                          />
                          <input
                            v-model="newLinkHref"
                            placeholder="Enter link URL"
                            type="url"
                          />
                        </div>
                        <div v-if="links.length" class="all-links pa-2">
                          <ul>
                            <li v-for="(link, index) in links" :key="index">
                              <a
                                :href="link.href"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {{ link.name }}
                              </a>
                              <v-btn
                                variant="text"
                                style="
                                  font-size: 10px;
                                  color: white;
                                  background: red;
                                  border-radius: 5px;
                                  padding: 1px 4px;
                                "
                                @click="removeLink(index)"
                                >Remove</v-btn
                              >
                            </li>
                          </ul>
                        </div>
                        <template v-slot:actions>
                          <button class="add-link-btn" @click="addLink">
                            Add Link
                          </button>
                        </template>
                      </v-card>
                    </v-dialog>
                    <!-- emojis menu  -->
                    <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
                      <emoji-picker @emoji-click="onEmojiClick"></emoji-picker>
                    </div>
                  </div>
                  <!-- link review  -->
                  <!-- <div id="postContentDiv" contenteditable="true"></div> -->
                </div>
                <!-- custom conent  -->
                <CustompPublish
                  @customizeByPlatformType="customizeByPlatformTypefun"
                  v-else
                />
                <!-- first comment  -->
                <div class="first-comment d-flex align-center ga-2 mb-5">
                  <div class="comment-user">M</div>
                  <div class="comment-input">
                    <input
                      :disabled="!store?.getCarouselSlider?.length"
                      v-model="store.comment"
                      ref="commentInput"
                      type="text"
                    />
                    <div class="actions d-flex align-center ga-2">
                      <span>
                        <ImageMenu
                          :disabled="!store?.getCarouselSlider?.length"
                          @fileSelected="(res) => commentImageSelected(res)"
                          @driveSelect="handleDriveSelect"
                          @editImage="handleEditImage"
                        />
                      </span>
                      <span class="position-relative">
                        <button
                          :disabled="!store?.getCarouselSlider?.length"
                          class="btn-post"
                          @click="commentToggleEmojiPicker"
                        >
                          <img
                            src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                            alt=""
                          />
                        </button>
                        <div
                          v-if="commentEmojiPicker"
                          class="emoji-picker-wrapper"
                        >
                          <emoji-picker
                            :disabled="!store?.getCarouselSlider?.length"
                            @emoji-click="onCommentEmojiClick"
                          ></emoji-picker>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- comment images review  -->
                <div class="d-flex align-center ga-2 justify-end mb-5">
                  <span
                    v-for="(img, index) in commentAllMediaFils"
                    :key="img"
                    class="position-relative"
                  >
                    <img :src="img.preview" width="60px" height="60px" alt="" />
                    <span
                      class="remove-comment-image cursor-pointer"
                      @click="removeCommentImage(index)"
                      >X</span
                    >
                  </span>
                </div>
                <!-- publish to  -->
                <div class="d-flex flex-column ga-2 mb-5">
                  <span class="publish-to">Publish to</span>
                  <v-select
                    :disabled="!store?.getCarouselSlider?.length"
                    class="publish-to-select"
                    variant="none"
                    v-model="store.pagesIds"
                    :items="store.pages"
                    item-title="name"
                    item-value="id"
                    return-object
                    multiple
                    chips
                    hide-details
                    placeholder="Select pages to publish to"
                  >
                    <template v-slot:item="{ item, props }">
                      <v-list-item
                        v-bind="props"
                        @click="setPagesId(item.raw.id)"
                        hide-details
                        :title="item.raw.name"
                      >
                        <template v-slot:prepend>
                          <v-checkbox-btn
                            :model-value="
                              store.pagesIds.some(
                                (page) => page.id === item.raw.id
                              )
                            "
                            density="compact"
                          />
                          <span class="position-relative mx-5">
                            <img
                              :src="item.raw.picture"
                              width="30px"
                              height="30px"
                              alt=""
                              class="ml-2 circle-img"
                            />
                            <img
                              class="position-absolute end-0"
                              style="
                                width: 20px;
                                height: 20px;
                                transform: translate(-50%, -50%);
                                bottom: -5px;
                              "
                              :alt="item.raw.platform"
                              :src="
                                item.raw.platform === 'instagram'
                                  ? instagramIcon
                                  : item.raw.platform === 'snapchat'
                                  ? snapchatIcon
                                  : item.raw.platform === 'x'
                                  ? xIcon
                                  : item.raw.platform === 'tiktok'
                                  ? tiktokIcon
                                  : item.raw.platform === 'linkedIn'
                                  ? LinkedInIcon
                                  : facebookIcon
                              "
                            />
                          </span>
                        </template>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>
                <!-- boost post  -->
                <div class="boost-your-post">
                  <div class="customize-platform mb-4">
                    <label class="toggle-switch">
                      <input
                        :disabled="!store?.getCarouselSlider?.length"
                        type="checkbox"
                        v-model="boostYourPost"
                        id="boostYourPost"
                      />
                      <div class="toggle-switch-background">
                        <div class="toggle-switch-handle"></div>
                      </div>
                    </label>
                    <label for="boostYourPost" class="label-for-input"
                      >Boost your post
                    </label>
                  </div>
                </div>
                <!-- boost post details  -->
                <div class="boost-post-contain" v-if="boostYourPost">
                  <div
                    class="d-flex align-center ga-2 pa-2"
                    v-for="data in Object.keys(store.boost)"
                    :key="data"
                  >
                    <div class="checkbox-wrapper-46">
                      <input
                        type="checkbox"
                        :id="store.boost[data].id"
                        class="inp-cbx"
                        @change="store.changeButtonText"
                        v-model="store.boost[data].state"
                      />
                      <label :for="store.boost[data].id" class="cbx"
                        ><span>
                          <svg viewBox="0 0 12 10" height="9px" width="10px">
                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                          </svg>
                        </span>
                      </label>
                    </div>
                    <label
                      class="d-flex align-center ga-1"
                      :for="store.boost[data].id"
                    >
                      <img :src="store.boost[data].icon" alt="" />
                      <span> {{ data }} </span>
                    </label>
                  </div>
                </div>
                <!-- errors  -->
                <div
                  class="boost-post-contain error-container border-0 my-5"
                  v-if="store.errors.length > 0"
                >
                  <v-expansion-panels v-model="panel" variant="accordion">
                    <v-expansion-panel>
                      <v-expansion-panel-title
                        style="
                          border-radius: 10px 10px 0 0;
                          background-color: rgba(255, 229, 229, 0.8);
                        "
                      >
                        <template v-slot:default>
                          <v-row no-gutters>
                            <v-col class="facebook-post-select" cols="4">
                              <div class="d-flex align-center ga-2">
                                <img
                                  src="@/assets/soft-ware-img/validate-error-icon.svg"
                                  alt=""
                                />
                                <span> {{ 1 }} error </span>
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text
                        style="
                          border: 1px solid rgba(255, 234, 234, 1);
                          border-radius: 0 0 10px 10px;
                        "
                      >
                        <ul class="error-list">
                          <li
                            v-for="(error, index) in store.errors"
                            :key="index"
                          >
                            <img
                              style="width: 20px; height: 18px"
                              src="@/assets/soft-ware-img/validate-error-icon.svg"
                              alt=""
                            />
                            <span>{{ error }}</span>
                          </li>
                          <li></li>
                        </ul>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
                <!-- form actions  -->
                <div class="create-post-actions">
                  <button class="cancel-btn">Cancel</button>

                  <div class="create-post-btn">
                    <VueDatePicker v-model="date"></VueDatePicker>
                    <v-btn
                      v-if="
                        Object.values(store.boost).some((item) => item.state)
                      "
                      color="rgba(35, 114, 118, 1)"
                      @click="goNextRoute"
                    >
                      Next
                    </v-btn>
                    <v-menu v-else transition="scale-transition">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          color="rgba(35, 114, 118, 1)"
                          v-bind="props"
                          @click="Send"
                        >
                          Publish
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="logItem(item)"
                          v-for="(item, i) in publishMenu"
                          :key="i"
                        >
                          <v-list-item-title class="d-flex align-center ga-1">
                            <span v-html="item.svgIcon"></span>
                            <span> {{ item.title }}</span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </div>
              <div class="rewivew-post">
                <ReviewPosts></ReviewPosts>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import CustompPublish from "@/components/publish_post_components/CustompPublish.vue";
import ImageMenu from "@/components/publish_post_components/ImageMenu.vue";
import "emoji-picker-element";
import { VDateInput } from "vuetify/labs/VDateInput";
import { VNumberInput } from "vuetify/labs/VNumberInput";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import ReviewPosts from "@/components/publish_post_components/ReviewPosts.vue";
import Treeselect from "vue3-treeselect";
import { mapActions, mapState } from "pinia";
import { publishPost } from "@/store/publishPost/publishPost.js";
import snapchatIcon from "@/assets/images/icons/snapcaht.svg";
import xIcon from "@/assets/images/icons/x.svg";
import instagramIcon from "@/assets/images/icons/instagram.svg";
import facebookIcon from "@/assets/images/icons/facebook.svg";
import tiktokIcon from "@/assets/images/icons/tiktok.svg";
import LinkedInIcon from "@/assets/images/icons/linkedIn.svg";
export default {
  components: {
    GlobalNav,
    SideBar,
    BreadCrumb,
    CustompPublish,
    ImageMenu,
    VNumberInput,
    VDateInput,
    VueDatePicker,
    ReviewPosts,
    Treeselect,
  },

  props: {
    label: {
      type: String,
      default: "Select an option",
    },
  },
  data() {
    return {
      snapchatIcon,
      xIcon,
      instagramIcon,
      facebookIcon,
      tiktokIcon,
      LinkedInIcon,
      date: null,
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Publish Post", link: "" },
      ],
      options: [
        {
          id: "post",
          label: "Post",
        },
        {
          id: "reel",
          label: "Reel",
        },
        {
          id: "story",
          label: "Story",
        },
      ],
      facebook_options: [
        {
          id: "post",
          label: "Post",
        },
        {
          id: "reel",
          label: "Reel",
        },
        {
          id: "story",
          label: "Story",
        },
      ],
      instagram_options: [
        {
          id: "post",
          label: "Post",
        },
        {
          id: "reel",
          label: "Reel",
        },
        {
          id: "story",
          label: "Story",
        },
      ],
      x_options: [
        {
          id: "post",
          label: "Post",
        },
      ],
      tiktok_options: [
        {
          id: "post",
          label: "Post",
        },
        {
          id: "story",
          label: "Story",
        },
      ],
      snapchat_options: [
        {
          id: "story",
          label: "Story",
        },
      ],
      selectedFacebookTypes: [],
      selectedInstgramTypes: [],
      selectedXTypes: [],
      selectedtikTokTypes: [],
      selectedSnapchatTypes: [],
      selectedItem: null,
      showEmojiPicker: false,
      postContent: "",
      longOfText: 0,
      newLinkName: "",
      newLinkHref: "",
      links: [],
      enterPostlink: false,
      imagePreview: null,
      cursorPosition: 0,
      customizeByPlatformType: false,
      boostYourPost: false,
      facebookPostCheck: false,
      xPostCheck: false,
      instagramPostCheck: false,
      tiktokPostCheck: false,
      facebookBudgetValue: "",
      xBudgetValue: "",
      instagramBudgetValue: "",
      tiktokBudgetValue: "",
      durationFacebookValue: "",
      durationXValue: "",
      durationInstagramValue: "",
      durationTikTokValue: "",
      selectedEndDate1: null,
      selectedEndDate2: null,
      selectedEndDate3: null,
      selectedEndDate4: null,
      panel: [0],
      publishMenu: [
        {
          id: 0,
          title: "Publish now",
          svgIcon: `<svg width="18" height="18" viewBox="0 0 20 20" fill="rgba(204, 204, 204, 1)" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.08798 2.95179C0.94215 2.36929 1.14965 1.75596 1.61798 1.38012C2.08632 1.00429 2.73048 0.935957 3.26715 1.20429L18.1088 8.62512C18.6297 8.88512 18.9588 9.41762 18.9588 10.0001C18.9588 10.5826 18.6297 11.1151 18.1088 11.3751L3.26715 18.796C2.73048 19.0643 2.08632 18.996 1.61798 18.6201C1.14965 18.2443 0.94215 17.631 1.08798 17.0485L2.64215 10.8335L11.6672 10.0001L2.64215 9.16679L1.08798 2.95179Z" />
                    </svg>`,
        },
        {
          id: 1,
          title: "Schedule and save",
          svgIcon: `<svg width="18" height="18" viewBox="0 0 20 20" fill="rgba(204, 204, 204, 1)" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.5 13.7503C6.91667 13.7503 6.42361 13.5489 6.02083 13.1462C5.61806 12.7434 5.41667 12.2503 5.41667 11.667C5.41667 11.0837 5.61806 10.5906 6.02083 10.1878C6.42361 9.78505 6.91667 9.58366 7.5 9.58366C8.08333 9.58366 8.57639 9.78505 8.97917 10.1878C9.38194 10.5906 9.58333 11.0837 9.58333 11.667C9.58333 12.2503 9.38194 12.7434 8.97917 13.1462C8.57639 13.5489 8.08333 13.7503 7.5 13.7503ZM4.16667 18.3337C3.70833 18.3337 3.31597 18.1705 2.98958 17.8441C2.66319 17.5177 2.5 17.1253 2.5 16.667V5.00033C2.5 4.54199 2.66319 4.14963 2.98958 3.82324C3.31597 3.49685 3.70833 3.33366 4.16667 3.33366H5V1.66699H6.66667V3.33366H13.3333V1.66699H15V3.33366H15.8333C16.2917 3.33366 16.684 3.49685 17.0104 3.82324C17.3368 4.14963 17.5 4.54199 17.5 5.00033V16.667C17.5 17.1253 17.3368 17.5177 17.0104 17.8441C16.684 18.1705 16.2917 18.3337 15.8333 18.3337H4.16667ZM4.16667 16.667H15.8333V8.33366H4.16667V16.667Z" />
                    </svg>`,
        },
      ],
      store: publishPost(),
      commentEmojiPicker: false,
      commentAllMediaFils: [],
    };
  },
  computed: {
    ...mapState(publishPost, ["storyContant", "allMediaFils"]),
  },
  methods: {
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    commentToggleEmojiPicker() {
      this.commentEmojiPicker = !this.commentEmojiPicker;
    },
    onCommentEmojiClick(event) {
      const emoji = event.detail.unicode;
      const input = this.$refs.commentInput;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      this.store.comment =
        this.store.comment.substring(0, start) +
        emoji +
        this.store.comment.substring(end);
      this.$nextTick(() => {
        input.selectionStart = input.selectionEnd = start + emoji.length;
        input.focus();
      });

      this.showEmojiPicker = false;
    },
    removeCommentImage(index) {
      this.commentAllMediaFils.splice(index, 1);
      this.store.commentImages;
    },
    onEmojiClick(event) {
      const emoji = event.detail.unicode;
      const textarea = this.$refs.textarea;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      this.postContent =
        this.postContent.substring(0, start) +
        emoji +
        this.postContent.substring(end);

      // Set cursor position after the inserted emoji
      this.$nextTick(() => {
        textarea.selectionStart = textarea.selectionEnd = start + emoji.length;
        textarea.focus();
      });

      this.showEmojiPicker = false;
    },
    addLink() {
      if (this.newLinkName && this.newLinkHref) {
        // const linkHTML = `<a href="${this.newLinkHref}" target="_blank">${this.newLinkName}</a>`;
        // const postContentDiv = document.getElementById("postContentDiv");
        // postContentDiv.innerHTML += ` ${linkHTML}`;
        this.links.push({
          name: this.newLinkName,
          href: this.newLinkHref,
        });
        let platformsfor = this.customizeByPlatformType ? "customize" : "all";
        this.UpdatePlatformLinks(platformsfor, "", "", this.newLinkHref);
        this.newLinkName = "";
        this.newLinkHref = "";
        this.enterPostlink = false;
      } else {
        alert("Please provide both link name and URL.");
      }
    },
    removeLink(index) {
      this.links.splice(index, 1);
      let platformsfor = this.customizeByPlatformType ? "customize" : "all";
      this.RemovePlatformLinks(platformsfor, "", "");
    },
    handleFileSelected(res, typeOfSocilaMedia) {
      switch (typeOfSocilaMedia) {
        case "allPostContent":
          this.allMediaFils.push(res[0]);
          break;
      }
      console.log(res[0]);
      let platformsfor = this.customizeByPlatformType ? "customize" : "all";
      this.updatePlatformFile(platformsfor, "", "", res[0]);
    },
    commentImageSelected(res) {
      res.forEach((file) => this.commentAllMediaFils.push(file));
      console.log(this.commentAllMediaFils);

      this.store.commentImages = this.commentAllMediaFils;
    },
    handleDriveSelect() {
      console.log("Drive select triggered");
    },
    handleEditImage() {
      console.log("Edit image triggered");
    },
    removeImage(index, array) {
      array.splice(index, 1);
    },
    fetchLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            this.reverseGeocode(latitude, longitude);
          },
          (error) => {
            console.error("Error fetching location: ", error.message);
            this.postContent = "Unable to fetch location.";
          }
        );
      } else {
        this.city = "Geolocation is not supported by this browser.";
      }
    },
    async reverseGeocode(lat, lng) {
      const apiKey = "AIzaSyAYugMkEQTvSJa9xsKlA2ixqZD7UFCeOtU";
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.results && data.results.length > 0) {
          const addressComponents = data.results[0].address_components;

          // Attempt to find locality or other city-related components
          const cityComponent = addressComponents.find(
            (component) =>
              component.types.includes("locality") ||
              component.types.includes("administrative_area_level_1")
          );

          if (cityComponent) {
            // Add city to postContent
            const locationText = cityComponent.long_name;

            // Assuming `this.postContent` holds the current text in your textarea
            this.postContent = `${locationText} \n ${this.postContent}`;

            // Optionally, if you want to append the location at the end of the text
            // this.postContent = `${this.postContent} (Location: ${locationText})`;
          } else {
            this.postContent =
              "City not found in locality or administrative_area_level_1.";
          }
        } else {
          this.postContent = "Unable to fetch city.";
        }
      } catch (error) {
        console.error("Error reverse geocoding: ", error);
        this.postContent = "Error fetching city.";
      }
    },
    customizeByPlatformTypefun() {
      this.customizeByPlatformType = !this.customizeByPlatformType;
    },
    setPagesId(id) {
      localStorage.setItem("pageIdBoostPost", id);
    },
    ...mapActions(publishPost, [
      "updateStoryContant",
      "UpdatePlatformTypes",
      "UpdatePlatformContents",
      "UpdatePlatformLinks",
      "updatePlatformFile",
      "SendPostToPublish",
      "RemovePlatformLinks",
    ]),
    Send() {
      this.SendPostToPublish(this.postContent);
    },
    goNextRoute() {
      console.log(this.store.boost);
      this.$router.push({ name: "BoostPost" });
    },
  },
  watch: {
    "store.boost"(val) {
      console.log(val);
    },
    selectedFacebookTypes(val) {
      let platformsfor = this.customizeByPlatformType ? "customize" : "all";
      this.UpdatePlatformTypes(platformsfor, "facebook", val);
    },
    selectedInstgramTypes(val) {
      let platformsfor = this.customizeByPlatformType ? "customize" : "all";
      this.UpdatePlatformTypes(platformsfor, "instagram", val);
    },
    selectedXTypes(val) {
      let platformsfor = this.customizeByPlatformType ? "customize" : "all";
      this.UpdatePlatformTypes(platformsfor, "x", val);
    },
    selectedtikTokTypes(val) {
      let platformsfor = this.customizeByPlatformType ? "customize" : "all";
      this.UpdatePlatformTypes(platformsfor, "tiktok", val);
    },
    selectedSnapchatTypes(val) {
      let platformsfor = this.customizeByPlatformType ? "customize" : "all";
      this.UpdatePlatformTypes(platformsfor, "snapchat", val);
    },
    postContent(val) {
      let platformsfor = this.customizeByPlatformType ? "customize" : "all";
      this.UpdatePlatformContents(platformsfor, "", "", val);
      this.longOfText = val.length;
    },
  },
  mounted() {
    this.store.getPages();
  },
};
</script>

<style scoped>
.emoji-picker-wrapper {
  left: 0;
  z-index: 1000;
  margin-bottom: 8px;
}

emoji-picker {
  --num-columns: 8;
  width: 320px;
  height: 400px;
}

.image-preview-overlay {
  position: absolute;
  bottom: 50px;
  height: 40px;
  overflow: auto;
  width: 90%;
}
.image-preview-overlay .image-preview-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.image-preview-overlay .image-preview-container img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
}
</style>
