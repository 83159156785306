import { locationData } from "@/core/country.js";
import { defineStore } from "pinia";
export const tiktokData = defineStore("tiktokData", {
  state: () => ({
    campaignName: "Reach2024",
    objectiveArr: [
      {
        id: 1,
        objecttype: "Awareness",
        checked: false,
        title: "Reach",
        description: "Show your ad to the maximum number of people.",
        imgSrc:
          "https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/reach-new42549e99.gif",
        objectiveTo: [
          "Tell more people about your product, service, or company.",
        ],
        objective_type: "REACH",
      },
      {
        objecttype: "Consideration",
        checked: false,
        title: "Traffic",
        description: "Send Snapchatters to a destination like a website or app",
        imgSrc:
          "https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/traffic-newaa6af028.gif",
        id: 2,
        objectiveTo: [
          "Drive people to a website landing page or app.",
          "Show your ads to people most likely to click on it.",
        ],
        objective_type: "TRAFFIC",
      },
      {
        checked: false,
        title: "Video Views",
        description:
          "Get Snapchatters to share their contact details with your business.",
        imgSrc:
          "https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/video-view-new4b7573cc.gif",
        id: 3,
        objectiveTo: [
          "Maximize the plays of your video ads.",
          "Drive consideration by showing your ads to users who are more actively engaged.",
        ],
        objective_type: "VIDEO_VIEWS",
      },
      {
        checked: false,
        title: "Community interaction",
        description:
          "Get Snapchatters to install and take actions in your app.",
        imgSrc:
          "https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/engagement-newbbef257f.gif",
        id: 4,
        objectiveTo: [
          "Get more people to follow your TikTok account.",
          "Get more people to visit your TikTok profile.",
          "Promote your livestream.",
        ],
        objective_type: "ENGAGEMENT",
      },
      {
        objecttype: "Conversion",
        checked: false,
        title: "App promotion",
        description: "Get Snapchatters to purchase your product or service.",
        imgSrc:
          "https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/app-install-new9eb09330.gif",
        id: 5,
        smartCampaign: true,
        CampaignType: true,
        objective_type: "APP_PROMOTION",
      },
      {
        checked: false,
        title: " Lead generation ",
        description: "Get Snapchatters to purchase your product or service.",
        imgSrc:
          "https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/lead-ads-new2199f23b.gif",
        id: 6,
        smartCampaign: true,
        objective_type: "LEAD_GENERATION",
      },
      {
        checked: false,
        title: "Website conversions",
        description: "Get Snapchatters to purchase your product or service.",
        imgSrc:
          "https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/conversions-new40325615.gif",
        id: 7,
        smartCampaign: true,
        objective_type: "WEBSITE_CONVERSIONS",
      },
      {
        checked: false,
        title: "Product sales",
        description: "Get Snapchatters to purchase your product or service.",
        imgSrc:
          "https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/products-new057fcd2c.gif",
        id: 8,
        productSource: true,
        objective_type: "PRODUCT_SALES",
      },
    ],
    objectiveInput: null,
    splitTest: false,
    campainBudgetOtpimized: false,
    setCampaignBudget: false,
    allInputsStatus: true,
    budgetSpend: [],
    adGroupName: "Ad group 20241206054916",
    text_value_post: "Your text will be shown here",
    videos_arr: [],
    media_fils: [],
    videoPreview: [],
    video_id: "",
    callToaction: "",
    locationsFromStore: locationData,
    media_type: "",
    images_preview: false,
    vide_preview: true,
    images_arr: [],
    call_to_action_text: {
      id: 29,
      value: "VIEW_VIDEO_WITH_THIS_EFFECT",
      title: "View video with this effect",
      label: "View video with this effect",
    },
    budgetSpend: "",
  }),
  actions: {
    setCampaignName(name) {
      this.campaignName = name;
      localStorage.setItem("campaign_name", this.campaignName);
    },
    setGroupName(name) {
      this.adGroupName = name;
      localStorage.setItem("adgroup_name", this.adGroupName);
    },
    setAllInputsStatus(status) {
      this.allInputsStatus = !status;
    },
    updateBudgetSpend(value) {
      this.budgetSpend = Number(value);
      localStorage.setItem("budget_spend", this.budgetSpend);
    },
    callToactionStatusStore(value) {
      this.callToaction = value;
      console.log(this.callToaction);
    },
    textValuePostStatus(value) {
      this.text_value_post = value;
      localStorage.setItem("text_value", this.text_value_post);
    },
    logLocation() {
      console.log(this.locationsFromStore);
    },
    changeMediaType(value) {
      this.media_type = value;
      console.log(this.media_type);
      localStorage.setItem("ad_formate", this.media_type);

      if (value == "SINGLE_VIDEO") {
        this.images_preview = false;
        this.vide_preview = true;
      } else {
        this.images_preview = true;
        this.vide_preview = false;
      }
    },
  },
});
