<template>
  <div class="button-destination">
    <v-select
      class="mb-4"
      v-model="store.buttonDestinationDataSelected"
      :items="store.buttonDestinationData"
      variant="solo"
      return-object
      item-value="id"
      item-title="title"
      hide-details
      label="Button destination"
    >
      <template v-slot:selection="{ item }">
        {{ item?.title || "" }}
      </template>
      <template v-slot:item="{ item }">
        <v-list-item @click="store.buttonDestinationFun(item)">
          <div class="my-form">
            <div>
              <input
                :checked="
                  store.buttonDestinationDataSelected &&
                  store.buttonDestinationDataSelected.id === item.raw.id
                "
                :id="`${item.id}_button_destination`"
                type="radio"
                name="option"
                :value="item"
              />
              <label :for="`${item.id}_button_destination`">{{
                item.title
              }}</label>
              <label :for="`${item.id}_button_destination`">{{
                item.description
              }}</label>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
import { boostPost } from "@/store/publishPost/boostPost";
export default {
  data() {
    return {
      store: boostPost(),
    };
  },
};
</script>
