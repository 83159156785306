import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const adsPerformance = defineStore("adsPerformance", {
  state: () => ({
    title_date: "Last 30 Days",
    campaignNumber: 0,
    adsReportStatus: 1,
    impressions: 0,
    amountSpent: 0,
    leads: 0,
    clicks: 0,
    linksData: [
      { name: "ads", link: "/" },
      { name: "ads_performance", link: "" },
    ],
    data: {
        labels: [
          "impressionSum",
          "Amount Spent",
          "leadsSum",
          "swipesSum",
          "campaign_sum",
        ],
        datasets: [
          {
            label: "snapchat",
            data: [40, 150, 180, 200, 90],
            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Facebook",
            data: [40, 150, 180, 200, 90],

            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "TikTok",
            data: [40, 150, 180, 200, 90],

            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Instagram",
            data: [40, 150, 180, 200, 90],

            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "X (Twitter)",
            data: [40, 150, 180, 200, 90],

            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Google",
            data: [40, 150, 180, 200, 90],
            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },

        },
      },
    
    tableData: [],
    loadingFortable: true,
  }),
  actions: {
    formatNumber(number) {
      return number
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        .replace(/\.00$/, "");
    },
    async getData() {
      this.loadingFortable = true;
      this.resetMetrics(); // Reset all metrics to initial state

      const requestData = {
        advertiser_ids: [],
        bc_id: null,
        report_type: "BASIC",
        service_type: "AUCTION",
        data_level: "AUCTION_CAMPAIGN",
        dimensions: ["campaign_id", "stat_time_day"],
        metrics: [
          "spend",
          "reach",
          "impressions",
          "clicks",
          "cost_per_result",
          "cpm",
          "cpc",
        ],
        start_date: this.start_date,
        end_date: this.end_date,
        plaform: this.selectedPlatform,
        status: this.selectedStatus,
        multi_adv_report_in_utc_time: false,
        order_type: "ASC",
        filtering: [{ field_name: "", filter_type: "", filter_value: "" }],
        matrices: ["impressions", "swipes", "native_leads", "spend"],
        adAccountId: "bc7348fc-d328-43c6-9795-b699210de9cd",
      };

      try {
        // Get TikTok advertisers in parallel with other initializations
        const tiktokResponse = await apiClient.get(
          "/campaign-ads/tiktok/advertiser"
        );
        requestData.advertiser_ids = tiktokResponse.data.data.data.list.map(
          (l) => l.advertiser_id
        );

        // Directly fetch performance data after getting necessary advertiser IDs
        const performanceResponse = await apiClient.post(
          "/all/socials/performance",
          requestData
        );

        if (!performanceResponse.data?.data) {
          console.error("Response data is missing or empty.");
          this.tableData = [];
          return;
        }

        const { snapchat, tiktok } = performanceResponse.data.data;
        this.processPlatformData(snapchat, "snapchat");
        this.processPlatformData(tiktok, "tiktok");

        this.updateChartData(snapchat, tiktok);
        this.title_date = this.getDateTitle();
      } catch (error) {
        console.error("Error:", error.response?.data || error.message);
      } finally {
        this.loadingFortable = false;
      }
    },

    // Helper methods
    resetMetrics() {
      this.clicks = 0;
      this.leads = 0;
      this.campaignNumber = 0;
      this.amountSpent = 0;
      this.impressions = 0;
      this.tableData = [];
    },

    processPlatformData(platformData, platformName) {
      platformData.performance.campaigns.forEach((item) => {
        this.tableData.push({
          platform: platformName,
          ad_account_id: item.ad_account_id,
          id: item.id,
          checked: false,
          name: item.name,
          status: item.status,
          start_time: item.start_time,
          end_time: item.end_time,
          delivery_status: item.delivery_status,
          lifetime_spend_cap_micro: item.lifetime_spend_cap_micro,
          native_leads: item?.stats?.native_leads,
          calculations: {
            costPerClick: item.calculations?.costPerClick || null,
            eCPM: item.calculations?.eCPM || null,
            eCPC: item.calculations?.eCPC || null,
          },
          stats: {
            impressions: item.stats?.impressions || 0,
            swipes: item.stats?.swipes || 0,
          },
        });
      });

      // Update metrics
      this.clicks += platformData.performance.summations.swipesSum;
      this.leads += platformData.performance.summations.leadsSum;
      this.campaignNumber += platformData.performance.summations.campaign_sum;
      this.amountSpent += platformData.performance.summations.spendSum;
      this.impressions += platformData.performance.summations.impressionSum;
    },

    updateChartData(snapchat, tiktok) {
      const datasets = this.data.datasets;
      [datasets[0].data, datasets[1].data] = [0, 1].map((i) => [
        i === 0 ? snapchat : tiktok.performance.summations.impressionSum,
        (i === 0 ? snapchat : tiktok).performance.summations.spendSum,
        (i === 0 ? snapchat : tiktok).performance.summations.leadsSum,
        (i === 0 ? snapchat : tiktok).performance.summations.swipesSum,
        (i === 0 ? snapchat : tiktok).performance.summations.campaign_sum,
      ]);
    },

    getDateTitle() {
      return this.start_date
        ? `From : ${this.start_date} To : ${this.end_date}`
        : "Last 30 Days";
    },
    ApplyFilter() {
      this.getData();
    },

    truncateName(name, length) {
      if (!name) return "";
      return name.length > length ? name.slice(0, length) + "..." : name;
    },
  },
});
