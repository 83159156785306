import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";
import { useGeneralStore } from "@/store/General";
import { useRouter } from "vue-router";
export const useTaskStore = defineStore("useTaskStore", {
  state: () => ({
    title: "",
    status: { title: "To Do", key: "todo" , color: "rgba(83, 100, 113, 1)" },
    tags: [],
    priority: "",
    description: "",
    time_estimate: "",
    from: null,
    to: null,
    assignees: [],
    add_to_social_media: 0,
    caption: "",
    type: "",
    platforms: [],
    branches: [],
    budget: "",
    budget_type: "",
    duration: "",
    users: [],
    status_list: [
      { title: "To Do", key: "todo", color: "rgba(83, 100, 113, 1)" },
      { title: "In Progress", key: "progress", color: "rgba(0, 133, 255, 1)" },
      { title: "Completed", key: "completed", color: "rgba(0, 186, 52, 1)" },
    ],
    sponsor_end_date: null,
    setEndDate: false,
    attachments: [],
    comments: [],
    general_store: useGeneralStore(),
    errors: {},
    branches_list: [],
    platforms_list: [],
    loading: {
      assignees: false,
    },
  }),
  getters: {},
  actions: {
    getUsers() {
      try {
        this.loading.assignees = true;
        apiClient
          .get("/leads-managment/all-agents")
          .then((response) => {
            this.users = response.data.data;
            this.loading.assignees = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading.assignees = false;
          });
      } catch (err) {
        console.error("Error fetching Drive:", err);
      }
    },
    updateStatus(status) {
      this.status = status;
    },
    validate() {
      const errors = {};

      // Validate 'title'
      if (!this.title) {
        errors.title = "Title is required.";
      } else if (typeof this.title !== "string") {
        errors.title = "Title must be a string.";
      }

      // Validate 'status'
      const validStatuses = ["todo", "progress", "completed"];
      if (!validStatuses.includes(this.status?.key?.toLowerCase())) {
        errors.status = "Invalid status.";
      }

      // Validate 'tags'
      if (this.tags?.some((tag) => typeof tag !== "string")) {
        errors.tags = "All tags must be strings.";
      }

      // Validate 'priority'
      const validPriorities = ["urgent", "high", "normal", "low"];
      if (!validPriorities.includes(this.priority?.key?.toLowerCase())) {
        errors.priority = "Invalid priority.";
      }

      // Validate 'from'
      if (!this.from) {
        errors.from = "Start date is required.";
      } else if (isNaN(Date.parse(this.from))) {
        errors.from = "Start date must be a valid date.";
      }

      // Validate 'to'
      if (this.to && isNaN(Date.parse(this.to))) {
        errors.to = "End date must be a valid date.";
      }

      // Validate 'type'
      const validTypes = ["organic", "sponsored"];
      if (this.add_to_social_media && !validTypes.includes(this.type?.toLowerCase())) {
        errors.type = "Invalid type.";
      }

      // Validate 'budget'
      if (this.add_to_social_media && isNaN(parseFloat(this.budget))) {
        errors.budget = "Budget must be a numeric value.";
      }

      // Validate 'budget_type'
      const validBudgetTypes = ["daily", "total"];
      if (
        this.add_to_social_media &&
        !validBudgetTypes.includes(this.budget_type?.toLowerCase())
      ) {
        errors.budget_type = "Invalid budget type.";
      }

      // Validate 'platforms'
      const validPlatforms = ["facebook", "snapchat", "instagram", "twitter"];
      if (
        this.add_to_social_media &&
        this.platforms.some(
          (platform) => !validPlatforms.includes(platform.name?.toLowerCase())
        )
      ) {
        errors.platforms = "Invalid platform(s).";
      }

      // Validate 'branches'
      // if (this.branches.length === 0) {
      //   errors.branches = "branches is required.";
      // }
      this.errors = errors;
      return Object.keys(errors).length > 0 ? errors : null;
    },
    formatDateTime(date) {
      if (!date) return null;
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      const hours = String(d.getHours()).padStart(2, "0");
      const minutes = String(d.getMinutes()).padStart(2, "0");
      const seconds = String(d.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    SendTask() {
      const validationErrors = this.validate();
      if (validationErrors) {
        this.general_store?.showSnackbar(
          "Validation errors occurred.",
          "error"
        );
        console.log("Validation Errors:", validationErrors);
        return;
      }

      try {
        const formData = new FormData();

        // Append simple fields
        formData.append("title", this.title);
        formData.append("status", this.status.key);

        formData.append("priority", this.priority.key);
        formData.append("description", this.description);
        formData.append("time_estimate", this.time_estimate);
        formData.append("from", this.formatDateTime(this.from));
        formData.append("to", this.formatDateTime(this.to));
        formData.append("add_to_social_media", this.add_to_social_media);
        formData.append("caption", this.caption);
        formData.append("type", this.type);
        formData.append("budget", this.budget);
        formData.append("budget_type", this.budget_type);
        formData.append("duration", this.duration);
        formData.append(
          "sponsor_end_date",
          this.formatDateTime(this.sponsor_end_date)
        );
        formData.append("setEndDate", this.setEndDate);
        this.tags.forEach((tag, index) => {
          formData.append(`tags[${index}]`, tag);
        });
        // Append arrays
        this.platforms.forEach((platform, index) => {
          formData.append(`platforms[${index}]`, platform.key);
        });

        this.branches.forEach((branch, index) => {
          formData.append(`branches[${index}]`, branch.name);
        });

        this.assignees.forEach((assignee, index) => {
          formData.append(`assignees[${index}]`, assignee);
        });

        // Append attachments

        this.attachments.forEach((attachment, index) => {
          formData.append(`attachment[${index}]`, attachment);
        });

        // Append comments
        this.comments.forEach((comment, index) => {
          formData.append(`comment[${index}]`, comment.content);
          comment.attachments.forEach((attachment, attIndex) => {
            formData.append(
              `comment_attachment[${index}][${attIndex}][file]`,
              attachment.file
            );
          });
          comment.links.forEach((link, linkIndex) => {
            formData.append(`links[${index}][${linkIndex}][name]`, link.name);
            formData.append(`links[${index}][${linkIndex}][href]`, link.href);
          });
        });

        // Log the form data for debugging
        // for (let [key, value] of formData.entries()) {
        //   console.log(key, value);
        // }

        // Send the form data
        apiClient
          .post("/calender/tasks", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.general_store?.showSnackbar("Task has been sent", "success");
            this.ResetData();
          })
          .catch((error) => {
            console.error("Error sending task:", error);
            this.general_store?.showSnackbar("Error Sending Data", "error");
          });
      } catch (err) {
        console.error("Error sending task:", err);
        this.general_store?.showSnackbar("Error Sending Data", "error");
      }
    },
    ResetData() {
      this.title = "";
      this.status = { title: "To Do", color: "rgba(83, 100, 113, 1)" };
      this.tags = [];
      this.priority = "";
      this.description = "";
      this.time_estimate = "";
      this.from = null;
      this.to = null;
      this.add_to_social_media = 0;
      this.caption = "";
      this.type = "";
      this.platforms = [];
      this.branches = [];
      this.budget = "";
      this.budget_type = "";
      this.duration = "";
      this.assignees = [];
      this.attachments = [];
      this.comments = [];
    },
    getBranches() {

      apiClient
        .get("/all-platfroms-pages", {
          params: { platforms: this.platforms.map((p) => p.key) },
        })
        .then((response) => {
          this.branches_list = response.data.data.map((b) => {
            return {
              id: b.id,
              name: b.name,
              image: b.picture,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPlatforms() {
      apiClient
        .get("/all/integrations")
        .then((response) => {
          this.platforms_list = response.data.data.map((p) => {
            return {
              id: p.id,
              name: p.platform,
              key: p.platform,
              icon: p.icon,
              color:
                p.name === "facebook"
                  ? "#1877F2"
                  : p.name === "instagram"
                  ? "#E4405F"
                  : p.name === "tiktok"
                  ? "#000000"
                  : p.name === "twitter"
                  ? "#1DA1F2"
                  : p.name === "youtube"
                  ? "#FF0000"
                  : "#757575",
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
