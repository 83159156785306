<template>
  <div class="card-pattern meta-conversion-card awareness-card">
    <div class="card-header">
      <label for="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Conversion</span>
      </label>
    </div>
    <div class="card-body">
      <div class="title-sub-title">
        <label for="">Conversion location</label>
        <p>
          Choose where you want to drive traffic. You'll enter more details
          about the destination later.
        </p>
      </div>
      <div
        v-for="conversion in conversion_type"
        :key="conversion.id"
        class="my-form"
      >
        <div class="ga-3">
          <input
            :checked="conversion.checked"
            :id="conversion.id"
            type="radio"
            name="conversion_type_input"
            :value="conversion.value"
            @change="setConversionType(conversion.value)"
          />
          <div class="d-flex flex-column ga-1">
            <label
              :for="conversion.id"
              class="d-block"
              style="font-size: 14px"
              >{{ conversion.title }}</label
            >
            <label :for="conversion.id" style="font-size: 12px; color: #1c2b33">
              {{ conversion.descriptions }}
            </label>
          </div>
        </div>
      </div>
      <label class="d-block roboto-regular title" for=""
        >Performance goal</label
      >
      <v-select
        v-model="performanceGoalSelected"
        :items="perfomanceGoalArray"
        class="my-2"
        density="compact"
        variant="outlined"
        return-object
        item-value="value"
        item-title="title"
        hide-details
        :disabled="disabledPerformanceGoal"
        :menu-props="{ maxWidth: '596px', minWidth: '100%' }"
      >
        <template v-slot:selection="{ item }">
          {{ item?.title || "" }}
        </template>
        <template v-slot:item="{ item, index }">
          <div v-if="index === 0">
            <p style="font-weight: bold; margin-bottom: 8px">Traffic goals</p>
          </div>
          <div v-if="index === 2">
            <p style="font-weight: bold; margin-top: 16px">Other goals</p>
          </div>
          <v-list-item @click="selectItemWinner(item)">
            <div class="my-form">
              <div class="ga-3">
                <input
                  :checked="
                    performanceGoalSelected &&
                    performanceGoalSelected.id === item.raw.id
                  "
                  :id="item.raw.id"
                  type="radio"
                  :name="`performance_goal_${item.raw.id}`"
                  :value="item.raw.value"
                />
                <div class="d-flex flex-column ga-1">
                  <label
                    :for="`${item.raw.id}+1`"
                    class="d-block"
                    style="font-size: 14px"
                    >{{ item.raw.title }}</label
                  >
                  <label
                    :for="`${item.raw.id}+1`"
                    style="font-size: 12px; color: #1c2b33"
                  >
                    {{ item.raw.descriptions }}
                  </label>
                </div>
              </div>
            </div>
          </v-list-item>
        </template>
      </v-select>
      <AppConversion v-if="item_app" />
      <MessagingApps v-else-if="item_messaging_apps" />
      <InstageramOrFacebook v-else-if="instagram_or_facebook" />
      <div class="cost-per-result my-3" v-if="item_calls">
        <label for="" class="d-block title roboto-regular"
          >Cost per result goal∙ Optional
        </label>
        <div class="input-container">
          <input
            type="number"
            placeholder="$X.XX"
            class="no-controls"
            name=""
            id=""
          />
          <span>USD</span>
        </div>
        <p class="description-item">
          Meta will aim to spend your entire budget and get the most link clicks
          using the highest-volume bid strategy. If keeping the average cost per
          result around a certain amount is important, enter a cost per result
          goal.
        </p>
      </div>
      <div class="show-options">
        <button @click="toggleOptions">
          <div class="d-flex align-center" v-if="!optionsStatus">
            <span>Show more options</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#0a7abf"
            >
              <path d="M480-384 288-576h384L480-384Z" />
            </svg>
          </div>
          <div class="d-flex align-center" v-else>
            <span>Hide options</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#0a7abf"
            >
              <path d="m288-384 192-192 192 192H288Z" />
            </svg>
          </div>
        </button>
      </div>
      <section v-if="optionsStatus">
        <WebsiteConversion />
      </section>
    </div>
  </div>
</template>

<script>
import AppConversion from "./conversion/AppConversion.vue";
import WebsiteConversion from "./conversion/WebsiteConversion.vue";
import MessagingApps from "./conversion/MessagingApps.vue";
import InstageramOrFacebook from "./conversion/InstageramOrFacebook.vue";
export default {
  components: {
    WebsiteConversion,
    AppConversion,
    MessagingApps,
    InstageramOrFacebook,
  },
  data() {
    return {
      conversion_type: [
        {
          id: 1,
          title: "Website",
          descriptions: "Send traffic to your website.",
          value: "WEBSITE",
          checked: true,
        },
        {
          id: 2,
          title: "App",
          descriptions: "Send traffic to your app.",
          value: "APP",
          checked: false,
        },
        {
          id: 3,
          title: "Messaging apps",
          descriptions: "Send traffic to Messenger, Instagram and WhatsApp.",
          value: "MESSAGING_APPS",
          checked: false,
        },
        {
          id: 4,
          title: "Instagram or Facebook",
          descriptions:
            "Send traffic to an Instagram profile, Facebook Page or both.",
          value: "INSTAGRAM_OR_FACEBOOK",
          checked: false,
        },
        {
          id: 5,
          title: "Calls",
          descriptions: "Get people to call your business.",
          value: "CALLS",
          checked: false,
        },
      ],
      selectedConversionType: "",
      perfomanceGoalArray: [],
      performanceGoalSelected: {
        id: 0,
        title: "Maximise number of landing page views ",
        descriptions:
          "We'll try to show your ads to the  people  most likely to view the website or Instant Experience linked in your ad..",
        value: "LANDING_PAGE_VIEWS",
      },
      performanceGoal: [
        {
          id: 0,
          title: "Maximise number of landing page views ",
          descriptions:
            "We'll try to show your ads to the  people  most likely to view the website or Instant Experience linked in your ad..",
          value: "LANDING_PAGE_VIEWS",
        },
        {
          id: 1,
          title: "Maximise number of link clicks",
          descriptions:
            "We'll try to show your ads to the people  most likely to click on them.",
          value: "LINK_CLICKS",
        },
        {
          id: 2,
          title: "Maximise daily unique reach",
          descriptions:
            "We'll try to show your ads to  people  up to once per day.",
          value: "REACH",
        },
        {
          id: 3,
          title: "Maximise number of conversations",
          descriptions:
            "We'll try to show your ads to  people  most likely to have a conversation with you through messaging.",
          value: "CONVERSATIONS",
        },
        {
          id: 4,
          title: "Maximise number of impressions",
          descriptions:
            "We'll try to show your ads to  people  as many times as possible.",
          value: "IMPRESSIONS",
        },
      ],
      app_conversion_performance_goal: [
        {
          id: 1,
          title: "Maximise number of link clicks",
          descriptions:
            "We'll try to show your ads to the people  most likely to click on them.",
          value: "LINK_CLICKS",
        },
        {
          id: 2,
          title: "Maximise daily unique reach",
          descriptions:
            "We'll try to show your ads to  people  up to once per day.",
          value: "REACH",
        },
      ],
      messaging_app_performance_goal: [
        {
          id: 1,
          title: "Maximise number of link clicks",
          descriptions:
            "We'll try to show your ads to the people  most likely to click on them.",
          value: "LINK_CLICKS",
        },
        {
          id: 2,
          title: "Maximise daily unique reach",
          descriptions:
            "We'll try to show your ads to  people  up to once per day.",
          value: "REACH",
        },
        {
          id: 3,
          title: "Maximise number of conversations",
          descriptions:
            "We'll try to show your ads to  people  most likely to have a conversation with you through messaging.",
          value: "CONVERSATIONS",
        },
        {
          id: 4,
          title: "Maximise number of impressions",
          descriptions:
            "We'll try to show your ads to  people  as many times as possible.",
          value: "IMPRESSIONS",
        },
      ],
      optionsStatus: false,
      item_website: true,
      item_app: false,
      item_messaging_apps: false,
      instagram_or_facebook: false,
      item_calls: true,
      disabledPerformanceGoal: false,
    };
  },

  methods: {
    setConversionType(value) {
      if (value === "WEBSITE") {
        this.item_app = false;
        this.item_website = true;
        this.item_messaging_apps = false;
        this.instagram_or_facebook = false;
        this.perfomanceGoalArray = [];
        this.perfomanceGoalArray = this.performanceGoal;
        this.disabledPerformanceGoal = false;
      } else if (value === "APP") {
        this.item_app = true;
        this.item_website = false;
        this.item_messaging_apps = false;
        this.instagram_or_facebook = false;
        this.perfomanceGoalArray = [];
        this.perfomanceGoalArray = this.app_conversion_performance_goal;
        this.disabledPerformanceGoal = false;
      } else if (value === "MESSAGING_APPS") {
        this.item_app = false;
        this.item_website = false;
        this.item_messaging_apps = true;
        this.instagram_or_facebook = false;
        this.perfomanceGoalArray = [];
        this.perfomanceGoalArray = this.messaging_app_performance_goal;
        this.disabledPerformanceGoal = false;
      } else if (value === "INSTAGRAM_OR_FACEBOOK") {
        this.item_app = false;
        this.item_website = false;
        this.item_messaging_apps = false;
        this.instagram_or_facebook = true;
        this.disabledPerformanceGoal = true;
        localStorage.setItem("meta_optimization_goal", "NONE");
      } else {
        this.item_app = false;
        this.item_website = false;
        this.item_messaging_apps = false;
        this.instagram_or_facebook = false;
        this.item_calls = false;
        this.disabledPerformanceGoal = true;
        localStorage.setItem("meta_optimization_goal", "NONE");
      }
    },
    selectItemWinner(item) {
      this.performanceGoalSelected = item.raw;
      localStorage.setItem("meta_optimization_goal", item.raw.value);
    },
    toggleOptions() {
      this.optionsStatus = !this.optionsStatus;
    },
  },
  watch: {},
  mounted() {
    this.perfomanceGoalArray = this.performanceGoal;
  },
};
</script>
