<template>
  <Layout :linksData="linksData">
    <v-row class="leads-managements w-100">
      <v-col cols="12" :lg="6" class="header-line-title"> Contacts </v-col>
      <v-col cols="12" :lg="6" class="d-flex align-center justify-end ga-2">
        <Top :type="'Contacts'" :list="contacts" />
      </v-col>
    </v-row>
    <FilterSection
      :menu="['Project', 'Event', 'Deals', 'Task']"
      :assign_to_account_menu="true"
      :selected_rows="selected_contacts"
      :parent="'contacts'"
      @update:selected_rows="selected_contacts = $event"
      :dashboard_path_name="'ContactsDashboard'"
      @update:search="updateSearch"
      :store="store"
    />
    <div class="table mt-3">
      <DataTable
        @update:selected_contacts="selected_contacts = $event"
        :contacts="contacts"
      />
    </div>
  </Layout>
</template>
<script>
import Layout from "@/components/crm/layouts/Layout.vue";
import activityLogIcon from "@/assets/images/icons/activity-log.svg";
import discussionsIcon from "@/assets/images/icons/discussions.svg";
import importIcon from "@/assets/images/icons/import.svg";
import FilterSection from "@/components/crm/assets/Filter.vue";
import Top from "@/components/crm/assets/Top.vue";
import DataTable from "@/components/crm/contacts/table.vue";
import "@/assets/css/crm/crm-moduls.css";
import { useContactsStore } from "@/store/crm/useContactsStore";

export default {
  components: {
    Layout,
    FilterSection,
    Top,
    DataTable,
  },

  data() {
    return {
      selected_contacts: [],
      activityLogIcon,
      discussionsIcon,
      importIcon,
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Contacts Management", link: "" },
      ],
      store: null,
    };
  },
  methods: {
    updateSearch(data) {
      this.store.search = data;
      this.store.getAllContacts();
    },
  },
  computed: {
    contacts() {
      return this.store?.getContacts || [];
    },
  },
  created() {
    this.store = useContactsStore();
    if (this.store?.getContacts.length === 0) {
      this.store?.getAllContacts();
    }
  },
};
</script>
