<template>
  <div class="meta-devices-and-os my-3">
    <div class="select-device">
      <label for="" class="roboto-bold mb-2 d-block title"
        >Specific mobile devices & operating systems</label
      >
      <v-select
        v-model="selected_device_type"
        :items="select_device_type"
        density="compact"
        variant="outlined"
        return-object
        item-value="id"
        item-title="title"
        hide-details
      >
        <template v-slot:selection="{ item }">
          {{ item?.title || "" }}
        </template>
        <template v-slot:item="{ item }">
          <v-list-item @click="selectDeviceType(item)">
            <div class="my-form">
              <div>
                <input
                  :checked="select && select.id === item.raw.id"
                  :id="item.raw.id"
                  type="radio"
                  name="select_device_type_option"
                  :value="item"
                  :disabled="item.raw.disabled"
                />
                <label :for="item.raw.id">{{ item.title }}</label>
              </div>
            </div>
          </v-list-item>
        </template>
      </v-select>

      <div class="conected-wifi">
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="conected_wifi"
            :id="`conected_wifi + '_' + type.model`"
          />
          <span class="checkmark"></span>
          <span class="text d-flex align-center">
            Only when connected to Wi-Fi
          </span>
        </label>
      </div>
      <div class="skippable-ads">
        <label class="title" for="">Exclude skippable ads</label>
        <h6 class="mb-3">Available for <strong>in-stream videos</strong></h6>
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="skippable_ads"
            :id="`skippable_ads + '_' + type.model`"
          />
          <span class="checkmark"></span>
          <span class="text d-flex align-center">
            Exclude skippable ads in the ad set (only for in-stream videos)
          </span>
        </label>
      </div>
    </div>

    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: "DevicesAndOs",
  data() {
    return {
      skippable_ads: false,
      conected_wifi: false,
      selected_device_type: {
        title: "All mobile devices",
        value: "All mobile devices",
        id: "all_mobile_devices",
        disabled: false,
      },
      select_device_type: [
        {
          title: "All mobile devices",
          value: "All mobile devices",
          id: "all_mobile_devices",
          disabled: false,
        },
        {
          title: "Android devices only",
          value: "Android devices only",
          id: "android_devices_only",
          disabled: false,
        },
        {
          title: "iOS devices only",
          value: "iOS devices only",
          id: "ios_devices_only",
          disabled: false,
        },
        {
          title: "Feature phones only",
          value: "Feature phones only",
          id: "feature_phones_only",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    selectDeviceType(item) {
      if (!item.raw.disabled) {
        this.selected_device_type = item.raw;
        localStorage.setItem("meta_device_type_value", item.raw.value);
      }
    },
  },
};
</script>
