<template>
  <v-navigation-drawer
    v-model="localDrawer"
    temporary
    location="right"
    width="800"
  >
    <div class="pa-4">
      <h2>{{ type }}</h2>
      <v-divider></v-divider>
      <div class="mt-4">
        <v-tabs v-model="tab" color="#237276">
          <v-tab value="activity">Activity</v-tab>
          <v-tab value="lastView">Last Viewd</v-tab>
        </v-tabs>

        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="activity"
            ><Activity :type="type"
          /></v-tabs-window-item>
          <v-tabs-window-item value="lastView">
            <LastViewed :type="type" />
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Activity from "./Activity.vue";
import LastViewed from "./LastViewed.vue";
export default {
  props: {
    drawer: Boolean,
    type: String,
  },
  components: { Activity, LastViewed },
  data() {
    return {
      tab: "activity",
    };
  },
  computed: {
    localDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("update:drawer", value);
      },
    },
  },
};
</script>
