<template>
  <div class="boost-post-card special-ad-boost">
    <div class="card-header mb-4">
      <label for="" class="title d-block">Special ad category</label>
    </div>
    <div class="card-body mb-2">
      <div class="body-left">
        <label for="category-status">
          <p>Is this ad about financial products and services?</p>
        </label>
        <a href="#">What we consider a financial products and services ad</a>
      </div>
      <div class="body-right">
        <v-switch
          id="category-status"
          v-model="store.specialAdCategoryStatus"
          color="info"
          hide-details
          inset
        ></v-switch>
      </div>
    </div>
    <div class="select-add-category" v-if="store.specialAdCategoryStatus">
      <v-select
        return-object
        :items="store.selectCategoryAds"
        density="compact"
        variant="outlined"
        multiple
        :menu-props="{ maxWidth: '596px', minWidth: '700px' }"
        item-title="title"
        item-value="value"
        v-model="store.selectedItems"
        hide-details
      >
        <template v-slot:item="{ item, props: itemProps }">
          <v-list-item class="select-category">
            <v-list-item-title
              @click="store.selectedAdCategory(item)"
              class="d-flex ga-0 align-start select-category-item"
            >
              <label
                v-bind="itemProps"
                :for="`${item.raw.id}_13`"
                class="d-flex mb-0 flex-column align-start ga-0"
              >
                <div class="d-flex mb-0 align-center ga-2">
                  <v-checkbox-btn
                    color="#237276"
                    :model-value="Array.isArray(store.selectedItems) && store.selectedItems.some((page) => page.id === item.raw.id)"
                    density="compact"
                    :id="`${item.raw.id}_13`"
                  />
                  <img :src="item.raw.svgIcon" alt="">
                  <p class="roboto-regular d-block" style="font-size: 14px">
                    {{ item.raw.title }}
                  </p>
                </div>
                <p
                  style="
                    white-space: normal;
                    word-wrap: break-word;
                    font-size: 12px;
                    margin-left: 30px;
                  "
                  class="roboto-regular d-block pl-8 w-100"
                >
                  {{ item.raw.descriptions }}
                </p>
              </label>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
    </div>
    <div class="info">
      <img :src="infoIcon" alt="" />
      <p>
        Based on your Page category, we recommend selecting Employment as the
        special ad category to help prevent an ad rejection.
      </p>
    </div>
  </div>
</template>

<script>
import { boostPost } from "@/store/publishPost/boostPost";
import infoIcon from "@/assets/images/info.svg";
export default {
  data() {
    return {
      infoIcon,
      store: boostPost(),
    };
  },
  watch: {
    selectedItems: {
      handler(newValue, oldValue) {
        this.store.selectItem = [];
        
        // Add all currently selected values
        if (Array.isArray(newValue)) {
          newValue.forEach((item) => {
            if (item.value !== undefined) {
              this.store.selectItem.push(item.value);
            }
          });
          console.log("");
          
        } else {
          console.warn("selectedItems should be an array.");
        }
      },
      deep: true
    }
  },
};
</script>
