<template>
  <div class="frequency-control">
    <p class="roboto-black d-block" style="color: #1c2b33; font-size: 14px">
      Frequency control
    </p>
    <div class="frequency-control-contant">
      <div class="my-form" v-for="item in controlType" :key="item.id">
        <div :style="{ opacity: item.disabled ? '0.5' : '1' }" class="ga-3">
          <input
            :checked="item.checked"
            :id="item.id"
            type="radio"
            name="frequencyControl"
            :value="item"
            :disabled="item.disabled"
          />
          <img
            :src="item.imageUrl"
            style="width: 32px; height: 32px; border-radius: 5px"
            alt=""
          />
          <div class="d-flex flex-column ga-1">
            <label
              :for="item.id"
              class="d-block"
              style="font-size: 14px; color: #1c2b33"
              >{{ item.title }}</label
            >
            <label :for="item.id" style="font-size: 12px; color: #1c2b33">
              {{ item.descriptions }}
            </label>
          </div>
        </div>
      </div>

      <div class="input-group-time-days">
        <v-container fluid class="pa-3 ma-0">
          <v-row no-gutters class="d-flex align-center ga-1">
            <v-col cols="3">
              <v-number-input
                :reverse="false"
                controlVariant="stacked"
                label=""
                hide-details
                density="compact"
                :hideInput="false"
                inset
                v-model="times"
                variant="outlined"
              ></v-number-input>
            </v-col>
            <v-col cols="2">
              <p>times every</p>
            </v-col>
            <v-col cols="3">
              <v-number-input
                :reverse="false"
                controlVariant="stacked"
                label=""
                v-model="days"
                hide-details
                density="compact"
                :hideInput="false"
                inset
                variant="outlined"
              ></v-number-input>
            </v-col>
            <v-col cols="3">
              <p>days</p>
            </v-col>
            <v-col cols="12">
              <p style="font-size: 12px;color: #000000d9;">
                As a maximum, we'll aim to stay under {{ times }} impressions
                every {{ days }} days.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { VNumberInput } from "vuetify/labs/VNumberInput";

import target from "@/assets/images/meta/target-2x.png";
import cap from "@/assets/images/meta/cap-2x.png";
export default {
  components: {
    VNumberInput,
  },
  data() {
    return {
      controlType: [
        {
          id: 1507,
          title: "Target",
          descriptions:
            "The average number of times that you want people to see your ads",
          checked: false,
          disabled: true,
          imageUrl: target,
        },
        {
          id: 1508,
          title: "Cap",
          descriptions:
            "The maximum number of times that you want people to see your ads",
          checked: false,
          disabled: false,
          imageUrl: cap,
        },
      ],
      times: 1,
      days: 7,
    };
  },
};
</script>
