const financeRoutes = [
  {
    path: "/finance-home",
    name: "FinanceHome",
    component: () => import("@/views/finance/Home.vue"),
    meta: {
      title: "Finance",
      description: "",
      
      permissions: [
        "finance-center.create",
        "finance-center.read",
        "finance-center.update",
        "finance-center.delete",
      ],
    },
  },
  {
    path: "/finance-invoices",
    name: "FinanceInvoices",
    component: () => import("@/views/finance/Invoices.vue"),
    meta: {
      title: "Invoices",
      description: "",
      
      permissions: [
        "finance-center.create",
        "finance-center.read",
        "finance-center.update",
        "finance-center.delete",
      ],
    },
  },
  {
    path: "/finance-statements",
    name: "FinanceStatements",
    component: () => import("@/views/finance/Statements.vue"),
    meta: {
      title: "Statements",
      description: "",
      
      permissions: [
        "finance-center.create",
        "finance-center.read",
        "finance-center.update",
        "finance-center.delete",
      ],
    },
  },
  {
    path: "/finance-payments",
    name: "FinancePayments",
    component: () => import("@/views/finance/Payments.vue"),
    meta: {
      title: "Payments",
      description: "",
      
      permissions: [
        "finance-center.create",
        "finance-center.read",
        "finance-center.update",
        "finance-center.delete",
      ],
    },
  },
  {
    path: "/finance-my-details",
    name: "FinanceMyDetails",
    component: () => import("@/views/finance/MyDetails.vue"),
    meta: {
      title: "My Details",
      description: "",
      
      permissions: [
        "finance-center.create",
        "finance-center.read",
        "finance-center.update",
        "finance-center.delete",
      ],
    },
  },
  {
    path: "/finance/invoices/details/:id",
    name: "FinanceInvoiceDetails",
    component: () => import("@/views/finance/ShowInvoice.vue"),
    meta: {
      title: "My Invoice Details",
      description: "",
      
      permissions: [
        "finance-center.create",
        "finance-center.read",
        "finance-center.update",
        "finance-center.delete",
      ],
    },
  },
];

export default financeRoutes;
