<template>
  <div>
    <label for="" class="d-block roboto-bold">When you are charged </label>
    <v-select
      v-model="chargedSelectedItem"
      :items="chargedItems"
      density="compact"
      variant="outlined"
      return-object
      item-value="id"
      item-title="title"
      hide-details
    >
      <template v-slot:selection="{ item }">
        {{ item?.title || "" }}
      </template>
      <template v-slot:item="{ item }">
        <v-list-item @click="setCahrgedItem(item)">
          <div class="my-form">
            <div class="ga-3">
              <input
                :checked="
                  chargedSelectedItem && chargedSelectedItem.id === item.raw.id
                "
                :id="`${item.raw.id}_type`"
                type="radio"
                name="chargetType"
                :value="item"
              />
              <div class="d-flex flex-column ga-1">
                <label
                  :for="`${item.raw.id}_type`"
                  class="d-block"
                  style="font-size: 14px"
                  >{{ item.title }}</label
                >
              </div>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    chargedItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chargedSelectedItem: {
        id: 0,
        title: "Impressions",
        value: "IMPRESSIONS",
      },
    };
  },
  methods: {
    setCahrgedItem(item) {
      this.chargedSelectedItem = item.raw;
      localStorage.setItem("charged_item", item.raw.value);
    },
  },
};
</script>
