<template>
  <v-list close-on-content-click="false">
    <div class="mb-4 d-flex align-center justify-space-between">
      <h4 class="mb-0">{{ $t("notifications") }}</h4>
      
      <button @click="store.markAllAsRead()">
        {{ $t("make_all_as_read") }}
      </button>
      <button
        class="d-flex align-center ga-1"
        @click="store.deleteAllNotifications"
      >
        <img :src="deleteIcon" alt="" />
        <span style="color: #ea3323; font-size: 14px">
          {{ $t("deleteAll") }}
        </span>
      </button>
    </div>
    <v-list-item
      v-for="(item, index) in store.listNotifications"
      class="mb-2"
      :key="index"
      :value="index"
      :style="{
        backgroundColor:
          item.read_at == null ? 'rgba(242, 242, 242, 1)' : '#fff',
      }"
      style="border-radius: 5px !important"
    >
      <v-list-item-title class="d-flex align-center ga-3">
        <SingleNotifiaction :item="item" />
      </v-list-item-title>
    </v-list-item>
    <router-link to="/all-notifications" class="see-all">
      {{ $t("seeAll") }}
    </router-link>
  </v-list>
</template>

<script>
import { notification } from "@/store/notifications/notification";
import defaultImage from "@/assets/images/icon-512-512.png";
import deleteIcon from "@/assets/images/deleteIcon.svg";
import checkRead from "@/assets/images/checkRead.svg";
import SingleNotifiaction from "./SingleNotifiaction.vue";
export default {
  props: ["NotificationList"],
  components: { SingleNotifiaction },
  data() {
    return {
      defaultImage,
      deleteIcon,
      checkRead,
      store: notification(),
    };
  },
  methods: {
    readNotification() {
      this.$router.push({ name: "AllNotifications" });
    },
  },
  mounted() {
    // this.store.getListNotifications();
  },
};
</script>

<style scoped>
.show-hidden-massage:hover svg path {
  fill: #fff !important;
}
.show-hidden-massage:focus svg path {
  fill: #fff !important;
}
.advanced-filter-massage .v-list {
  width: 384px !important;
  padding: 20px 20px 30px 20px !important;
  top: 14px !important;
  min-height: 55vh !important;
}
.advanced-filter-massage a.see-all {
  color: #237276;
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-align: end;
  text-decoration: none;
  position: absolute;
  bottom: 8px;
  right: 20px;
}
.advanced-filter-massage .v-list h4 {
  font-weight: 700;
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
}
.advanced-filter-massage .v-list-item {
  padding: 5px !important;
}
.v-list-item:hover {
  border-radius: 4px !important;
}
.v-list-item-title {
  padding: 6px 0 0 2px;
}
.user-img-status {
  position: relative;
}
.user-img-status img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.user-img-status .online-status {
  width: 13px;
  height: 13px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: -1px;
  left: -1px;
  border: 2px solid #fff;
}
.user-img-status .online-status span {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #31a24c;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.user-img-status img.social-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  right: -3px;
}
.user-msg {
  line-height: normal;
}
.user-msg p {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.user-msg span.msg-text {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  display: block;
}
.user-msg button img {
  width: 16px;
  height: 16px;
}
.user-msg button {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 10px;
}
.user-msg button span {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.massage-icon span {
  background-color: #edf1f1;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.massage-icon span:hover {
  background-color: #1f666a;
}
.massage-icon span:hover svg path {
  fill: #e6e6e6;
}
</style>
