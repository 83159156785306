import { defineStore } from "pinia";
export const useGeneralStore = defineStore("useGeneralStore", {
  state: () => ({
    snackbar: {
      color: "",
      show: false,
      message: "",
      timeout: 3000,
      loading: false,
      closable: true,
    },
  }),
  getters: {
    getSnakebar: (state) => state.snackbar,
    getLoading: (state) => state.loading,
  },
  actions: {
    showSnackbar(message, color) {
      this.snackbar = { message, color, show: true, timeout: 3000 };
    },
    ShowLoading(closable = true) {
      this.loading = true;
      this.closable = closable;
    },
    HideLoading() {
      this.loading = false;
    },
  },
});
