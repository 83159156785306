<template>
  <div class="placement-card ad-name">
    <div class="card-header">
      <p class="card-title">Ad name</p>
    </div>
    <div class="card-body">
      <input @input="setAdName" v-model="adName" type="text" name="" id="" />
      <button @click="setCreativeName" class="d-flex align-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="18px"
          fill="#6d6e70"
        >
          <path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" />
        </svg>
        <span> Add creative name </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adName: "",
    };
  },
  mounted() {
    this.setCurrentDateTime();
  },
  methods: {
    setAdName() {
      localStorage.setItem("ad_tiktok_name", this.adName);
    },
    setCreativeName() {
      this.setCurrentDateTime();
    },
    setCurrentDateTime() {
      const now = new Date();
      const formattedDate = now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      const formattedTime = now.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
      this.adName = `${formattedDate} ${formattedTime}`.replace(/[/,:]/g, '-');
      this.setAdName();
    }
  },
};
</script>
