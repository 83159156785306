<template>
  <v-switch
    :model-value="add_to_calendar"
    color="green"
    @update:model-value="updateAddToCalendar"
    :true-value="1"
    :false-value="0"
  ></v-switch>
</template>

<script>
import apiClient from "@/core/buildRequest";
import { useTasksStore } from "@/store/crm/useTasksStore";
import { useGeneralStore } from "@/store/General";

export default {
  name: "statusCol",
  props: ["item", "id"],
  data() {
    return {
      add_to_calendar: 0,
      task_store: null,
      general_store: null,
    };
  },
  mounted() {
    console.log("add_to_calendar",this.item.add_to_calendar);
    this.add_to_calendar = Number(this.item.add_to_calendar) ?? 0;
  },
  created() {
    this.task_store = useTasksStore();
    this.general_store = useGeneralStore();
  },
  watch: {
    "item.add_to_calendar"(newValue) {
      this.add_to_calendar = Number(newValue) ?? 0;
    },
  },
  methods: {
    async updateAddToCalendar(value) {
      this.add_to_calendar = value;
      try {
        await apiClient
          .post(`/leads-managment/tasks/add_to_calender/${this.id}`, {
            add_to_calendar: value,
          })
          .then((res) => {
            this.task_store.updateAddToCalender(this.id, value);
            this.general_store.showSnackbar(res.data.msg, res.data.key);
          });
      } catch (error) {
        console.error("Error updating add_to_calendar:", error);
      }
    },
  },
};
</script>
