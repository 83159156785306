<template>
  <v-dialog v-model="dialogModel" max-width="800" persistent>
    <v-card class="pa-4">
      <v-card-title>Add New Task</v-card-title>

      <v-card-text>
        <div class="d-flex flex-column ga-5">
          <span class="error-message" v-for="e in errors" :key="e">{{
            e
          }}</span>

          <!-- Form Fields -->
          <template v-for="field in formFields" :key="field.id">
            <div
              :class="[
                field.type === 'autocomplete' ? 'filed-autocomplete' : 'field',
                'd-flex flex-column ga-2',
              ]"
            >
              <label
                v-if="field.type !== 'switch'"
                :ref="field.ref"
                :for="field.id"
              >
                {{ field.label }}
                <span v-if="field.required" class="required">*</span>
              </label>

              <!-- Regular Input -->
              <input
                v-if="field.type === 'input'"
                :id="field.id"
                v-model="formData[field.id]"
                :type="field.inputType || 'text'"
                @input="updateValidationClass(field.ref, formData[field.id])"
              />

              <!-- Date Input -->
              <input
                v-else-if="field.type === 'date'"
                :id="field.id"
                v-model="formData[field.id]"
                type="text"
                :ref="field.id"
                @input="updateValidationClass(field.ref, formData[field.id])"
              />

              <!-- Autocomplete -->
              <v-autocomplete
                v-else-if="field.type === 'autocomplete'"
                v-model="formData[field.id]"
                :items="field.items"
                :multiple="field.multiple"
                :item-value="field.itemValue"
                :item-title="field.itemTitle"
                hide-details
                chips
                @change="updateValidationClass(field.ref, formData[field.id])"
              />

              <!-- Switch -->
              <div
                v-else-if="field.type === 'switch'"
                class="d-flex justify-end align-center ga-2 custom-switch"
              >
                <label :for="field.id">{{ field.label }}</label>
                <v-switch
                  color="green"
                  v-model="formData[field.id]"
                  hide-details
                  inset
                  :true-value="1"
                  :false-value="0"
                ></v-switch>
              </div>
            </div>
          </template>
        </div>
      </v-card-text>

      <v-card-actions class="sticky-footer-dialog">
        <v-spacer></v-spacer>
        <span class="cancel-btn cursor-pointer" @click="cancelDialog"
          >Cancel</span
        >
        <span class="choose-btn cursor-pointer" @click="saveTask">Save</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { getFieldError } from "@/utils/formValdiation.js";
import apiClient from "@/core/buildRequest";
import { useContactsStore } from "@/store/crm/useContactsStore";
import { useAccountsStore } from "@/store/crm/useAccountsStore";
import { useProjectsStore } from "@/store/crm/useProjectsStore";
import { useDealsStore } from "@/store/crm/useDealsStore";

export default {
  name: "TaskDialog",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    accounts: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      formData: {
        name: "",
        description: "",
        start_date: "",
        due_date: "",
        add_start_date_to_calendar: 1,
        add_to_calendar: 1,
        assigness: [],
        contact_id: [],
        account_id: null,
        status: null,
        project_id: null,
        deal_id: null,
        cost: "",
        weight: "",
        estimated_bouns: "",
      },
      errors: [],
      fieldErrors: {},
      flatpickrInstances: {},
      contacts_store: useContactsStore(),
      accounts_store: useAccountsStore(),
      projects_store: useProjectsStore(),
      deals_store: useDealsStore(),
    };
  },

  computed: {
    dialogModel: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },

    formFields() {
      return [
        {
          id: "name",
          label: "Task Name",
          type: "input",
          required: true,
          ref: "taskNameInput",
        },
        {
          id: "description",
          label: "Description",
          type: "input",
          required: true,
          ref: "descriptionInput",
        },
        {
          id: "start_date",
          label: "Start Date",
          type: "date",
          required: true,
          ref: "startDateInput",
        },
        {
          id: "add_start_date_to_calendar",
          label: "Add Start Date To Calendar",
          type: "switch",
        },
        {
          id: "due_date",
          label: "Due Date",
          type: "date",
          required: true,
          ref: "dueDateInput",
        },
        {
          id: "add_to_calendar",
          label: "Add Due Date To Calendar",
          type: "switch",
        },
        {
          id: "assigness",
          label: "Assignees",
          type: "autocomplete",
          items: ["a1", "a2"],
          multiple: true,
          required: true,
          ref: "assigneesInput",
        },
        {
          id: "contact_id",
          label: "Contacts",
          type: "autocomplete",
          items: this.all_contacts,
          itemTitle: "contact_number",
          itemValue: "id",
          multiple: true,
          required: true,
          ref: "contactsInput",
        },
        {
          id: "account_id",
          label: "Accounts",
          type: "autocomplete",
          items: this.all_accounts,
          itemTitle: "account_number",
          itemValue: "id",
          multiple: false,
          required: true,
          ref: "accountsInput",
        },
        {
          id: "status",
          label: "Status",
          type: "autocomplete",
          items: ["active", "completed", "pending", "on_hold", "closed"],
          required: true,
          ref: "statusInput",
        },
        {
          id: "project_id",
          label: "Project",
          type: "autocomplete",
          items: this.all_projects,
          itemTitle: "project_number",
          itemValue: "id",
          required: true,
          ref: "projectInput",
        },
        {
          id: "deal_id",
          label: "Deal",
          type: "autocomplete",
          items: this.all_deals,
          itemTitle: "deal_number",
          itemValue: "id",
          required: true,
          ref: "dealInput",
        },
        {
          id: "cost",
          label: "Cost",
          type: "input",
          inputType: "number",
          required: true,
          ref: "costInput",
        },
        {
          id: "weight",
          label: "Weight",
          type: "input",
          inputType: "number",
          required: true,
          ref: "weightInput",
        },
        {
          id: "estimated_bouns",
          label: "estimated Bonus",
          type: "input",
          inputType: "number",
          required: true,
          ref: "estimatedBonusInput",
        },
      ];
    },
    all_contacts() {
      if (this.contacts_store?.getContacts.length === 0) {
        this.contacts_store.getAllContacts();
      }
      return this.contacts_store?.getContacts || [];
    },
    all_accounts() {
      if (this.accounts_store?.getAccounts.length === 0) {
        this.accounts_store.getAllAccounts();
      }
      return this.accounts_store?.getAccounts || [];
    },
    all_projects() {
      if (this.projects_store?.getProjects.length === 0) {
        this.projects_store.getAllProjects();
      }
      return this.projects_store?.getProjects || [];
    },
    all_deals() {
      if (this.deals_store?.getDeals.length === 0) {
        this.deals_store.getAllDeals();
      }
      return this.deals_store?.getDeals || [];
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initializeFlatPickr();
        });
      } else {
        this.destroyFlatPickr();
      }
    },
    "formData.add_to_calendar"(newVal) {
      if (newVal) {
        this.formData.add_to_calendar = 1;
      } else {
        this.formData.add_to_calendar = 0;
      }
    },
    "formData.add_start_date_to_calendar"(newVal) {
      if (newVal) {
        this.formData.add_start_date_to_calendar = 1;
      } else {
        this.formData.add_start_date_to_calendar = 0;
      }
    },
    contacts: {
      handler(newVal) {
        this.formData.contact_id = newVal;
      },
      deep: true,
    },

    accounts: {
      handler(newVal) {
        this.formData.accounts = newVal;
      },
      deep: true,
    },
  },

  mounted() {
    this.getProject();
    if (this.dialog) {
      this.initializeFlatPickr();
    }
  },

  beforeUnmount() {
    this.destroyFlatPickr();
  },

  methods: {
    initializeFlatPickr() {
      this.destroyFlatPickr();

      const startDateEl = this.$refs.start_date?.[0];
      if (startDateEl) {
        this.flatpickrInstances.startDate = flatpickr(startDateEl, {
          enableTime: false,
          dateFormat: "Y-m-d",
          onChange: (selectedDates) => {
            this.formData.start_date = selectedDates[0]
              ? selectedDates[0].toISOString().split("T")[0]
              : "";
            this.updateValidationClass(
              "startDateInput",
              this.formData.start_date
            );
          },
        });
      }

      const dueDateEl = this.$refs.due_date?.[0];
      if (dueDateEl) {
        this.flatpickrInstances.dueDate = flatpickr(dueDateEl, {
          enableTime: false,
          dateFormat: "Y-m-d",
          onChange: (selectedDates) => {
            this.formData.due_date = selectedDates[0]
              ? selectedDates[0].toISOString().split("T")[0]
              : "";
            this.updateValidationClass("dueDateInput", this.formData.due_date);
          },
        });
      }
    },

    destroyFlatPickr() {
      Object.values(this.flatpickrInstances).forEach((instance) => {
        if (instance) {
          instance.destroy();
        }
      });
      this.flatpickrInstances = {};
    },

    validateForm() {
      this.errors = [];
      this.fieldErrors = {};
      let isValid = true;

      for (const field of this.formFields) {
        if (!field.required) continue;

        const value = this.formData[field.id];
        const error = getFieldError(field.label, value);

        if (error) {
          isValid = false;
          this.fieldErrors[field.id] = error;
          const el = this.$refs[field.ref]?.[0];
          if (el) {
            el.classList.add("error-validation");
          }
        }
      }

      return isValid;
    },

    updateValidationClass(ref, value) {
      const el = this.$refs[ref]?.[0];
      if (el) {
        el.classList[!value ? "add" : "remove"]("error-validation");
      }
    },

    resetForm() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = Array.isArray(this.formData[key]) ? [] : "";
      });
      this.formData.add_start_date_to_calendar = true;
      this.formData.add_to_calendar = true;
      this.errors = [];
      this.fieldErrors = {};
    },

    cancelDialog() {
      this.$emit("update:dialog", false);
    },

    async saveTask() {
      if (!this.validateForm()) return;

      try {
        await apiClient.post(
          `/leads-managment/tasks/${this.id}`,
          this.formData
        );
        this.$emit("update:dialog", false);
      } catch (error) {
        this.errors.push(error.response?.data?.msg || "An error occurred");
        console.error(error);
      }
    },
    async getProject() {
      await apiClient.get(`/leads-managment/tasks/${this.id}`).then((res) => {
        const this_task = res.data.data;
        this.formData.name = this_task.name;
        this.formData.description = this_task.description;
        this.formData.start_date = this_task.start_date;
        this.formData.due_date = this_task.due_date;
        this.formData.add_to_calendar = this_task.add_to_calendar;
        this.formData.assigness = this_task.members?.map((m) => m.id);
        this.formData.contact_id = this_task.contacts?.map((c) => c.id);
        this.formData.account_id = this_task.account?.id;
        this.formData.status = this_task.status;
        this.formData.project_id = this_task.project?.id;
        this.formData.deal_id = this_task.deal?.id;
        this.formData.cost = this_task.cost;
        this.formData.weight = this_task.weight;
        this.formData.estimated_bouns = this_task.estimated_bouns;
      });
    },
  },
};
</script>
