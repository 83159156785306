<template>
  <div class="d-flex flex-column ga-3 snapchat">
    <div class="filed mt-3">
      <span class="title">Privacy Policy URL</span>
      <div class="input-container">
        <input
          type="text"
          v-model="store.privacy_policy_url"
          placeholder="Privacy Policy URL"
          class="w-100 required_input"
          :style="[
            store.disclosureValidations.includes('form_privacy_policy_url')
              ? 'border-bottom: solid 1px red !important'
              : '',
            ,
          ]"
        />
      </div>
      <div
        v-if="store.disclosureValidations.includes('form_privacy_policy_url')"
        class="warning mt-2"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="red"
          xmlns="http://www.w3.org/2000/svg"
          class="sds-icon sds-icon-error sds-input-status styles-1yw183w"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99997 2.1355C7.6576 2.1355 7.32147 2.22721 7.02654 2.40109C6.73161 2.57497 6.48864 2.82469 6.32289 3.12426L1.91828 11.1205C1.75138 11.4118 1.6639 11.7419 1.66465 12.0776C1.6654 12.4155 1.75547 12.7473 1.92573 13.0391C2.096 13.331 2.3404 13.5727 2.63418 13.7397C2.9271 13.9062 3.25873 13.9925 3.59562 13.99H12.4043C12.7412 13.9925 13.0728 13.9062 13.3658 13.7397C13.6595 13.5727 13.9039 13.331 14.0742 13.0391C14.2445 12.7473 14.3346 12.4155 14.3353 12.0776C14.336 11.7419 14.2486 11.4118 14.0817 11.1205L9.67759 3.12522L9.67706 3.12426C9.5113 2.82469 9.26833 2.57497 8.9734 2.40109C8.67847 2.22721 8.34235 2.1355 7.99997 2.1355Z"
          ></path>
          <path
            d="M7.16671 11.5987C7.16539 11.4336 7.21314 11.2719 7.3039 11.134C7.39466 10.996 7.52435 10.8882 7.6765 10.8241C7.82865 10.76 7.99641 10.7425 8.1585 10.7739C8.32058 10.8053 8.46969 10.8842 8.5869 11.0004C8.70412 11.1167 8.78415 11.2652 8.81685 11.427C8.84955 11.5888 8.83344 11.7567 8.77056 11.9094C8.70769 12.0621 8.60089 12.1926 8.46371 12.2845C8.32653 12.3763 8.16515 12.4254 8.00005 12.4254C7.89093 12.4263 7.78271 12.4055 7.68165 12.3644C7.58058 12.3232 7.48866 12.2625 7.41119 12.1856C7.33372 12.1088 7.27223 12.0174 7.23027 11.9166C7.18831 11.8159 7.16671 11.7079 7.16671 11.5987Z"
            fill="white"
          ></path>
          <path
            d="M8.00001 4.79333C8.11321 4.7941 8.22508 4.81775 8.32891 4.86284C8.43274 4.90793 8.52638 4.97354 8.60422 5.05574C8.68205 5.13793 8.74247 5.23501 8.78185 5.34114C8.82122 5.44727 8.83874 5.56026 8.83334 5.67333L8.50001 10.04H7.49334L7.16668 5.67333C7.16222 5.56049 7.18038 5.44789 7.22009 5.34217C7.25981 5.23645 7.32027 5.13975 7.39792 5.05775C7.47557 4.97575 7.56884 4.91011 7.67225 4.8647C7.77565 4.81929 7.88709 4.79503 8.00001 4.79333Z"
            fill="white"
          ></path>
        </svg>
        <p class="small-text muted-text text-red-500">
          You must enter a valid URL format, e.g. https://yoursite.com/page
        </p>
      </div>
    </div>
    <div>
      <span>Disclosures</span>
      <span class="small-text"
        >Add a custom legal disclaimer or marketing opt-in.</span
      >
    </div>
    <div class="filed mt-3">
      <span class="title">Title</span>
      <div class="input-container">
        <input type="text" v-model="store.title" placeholder="Title" />
      </div>
    </div>
    <div class="filed mt-3">
      <span class="title">Description</span>
      <div class="input-container">
        <d-flex class="flex-column">
          <span
            class="small-text w-100 d-flex align-center justify-space-between"
            style="
              height: 40px;
              background-color: #f9f9fa;
              border-bottom: solid 0.1px rgb(225, 227, 229);
            "
          >
            <span>Add Hyperlink </span>
            <span class="small-text"
              >{{ store.description?.length }} / 2000 characters</span
            >
          </span>
          <input
            style="height: 75px"
            type="text"
            maxlength="2000"
            v-model="store.description"
            placeholder="Description"
            class="w-100 required_input"
            :style="[
              store.settingsValidations.includes('description')
                ? 'border-bottom: solid 2px red !important'
                : '',
              ,
            ]"
          />
        </d-flex>
      </div>
      <div
        v-if="store.settingsValidations.includes('description')"
        class="warning mt-2"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="red"
          xmlns="http://www.w3.org/2000/svg"
          class="sds-icon sds-icon-error sds-input-status styles-1yw183w"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99997 2.1355C7.6576 2.1355 7.32147 2.22721 7.02654 2.40109C6.73161 2.57497 6.48864 2.82469 6.32289 3.12426L1.91828 11.1205C1.75138 11.4118 1.6639 11.7419 1.66465 12.0776C1.6654 12.4155 1.75547 12.7473 1.92573 13.0391C2.096 13.331 2.3404 13.5727 2.63418 13.7397C2.9271 13.9062 3.25873 13.9925 3.59562 13.99H12.4043C12.7412 13.9925 13.0728 13.9062 13.3658 13.7397C13.6595 13.5727 13.9039 13.331 14.0742 13.0391C14.2445 12.7473 14.3346 12.4155 14.3353 12.0776C14.336 11.7419 14.2486 11.4118 14.0817 11.1205L9.67759 3.12522L9.67706 3.12426C9.5113 2.82469 9.26833 2.57497 8.9734 2.40109C8.67847 2.22721 8.34235 2.1355 7.99997 2.1355Z"
          ></path>
          <path
            d="M7.16671 11.5987C7.16539 11.4336 7.21314 11.2719 7.3039 11.134C7.39466 10.996 7.52435 10.8882 7.6765 10.8241C7.82865 10.76 7.99641 10.7425 8.1585 10.7739C8.32058 10.8053 8.46969 10.8842 8.5869 11.0004C8.70412 11.1167 8.78415 11.2652 8.81685 11.427C8.84955 11.5888 8.83344 11.7567 8.77056 11.9094C8.70769 12.0621 8.60089 12.1926 8.46371 12.2845C8.32653 12.3763 8.16515 12.4254 8.00005 12.4254C7.89093 12.4263 7.78271 12.4055 7.68165 12.3644C7.58058 12.3232 7.48866 12.2625 7.41119 12.1856C7.33372 12.1088 7.27223 12.0174 7.23027 11.9166C7.18831 11.8159 7.16671 11.7079 7.16671 11.5987Z"
            fill="white"
          ></path>
          <path
            d="M8.00001 4.79333C8.11321 4.7941 8.22508 4.81775 8.32891 4.86284C8.43274 4.90793 8.52638 4.97354 8.60422 5.05574C8.68205 5.13793 8.74247 5.23501 8.78185 5.34114C8.82122 5.44727 8.83874 5.56026 8.83334 5.67333L8.50001 10.04H7.49334L7.16668 5.67333C7.16222 5.56049 7.18038 5.44789 7.22009 5.34217C7.25981 5.23645 7.32027 5.13975 7.39792 5.05775C7.47557 4.97575 7.56884 4.91011 7.67225 4.8647C7.77565 4.81929 7.88709 4.79503 8.00001 4.79333Z"
            fill="white"
          ></path>
        </svg>
        <p class="small-text muted-text text-red-500">field is required.</p>
      </div>
    </div>
    <div
      v-for="(item, index) in store.consent"
      :key="item"
      class="single-consent"
    >
      <div class="consent-title d-flex align-center justify-space-between">
        <span>{{ "consent " + (index + 1) }}</span>
        <svg
          @click="RemoveConsent(index)"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="#444546"
          xmlns="http://www.w3.org/2000/svg"
          class="sds-icon sds-icon-trash cursor-pointer"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.19527 3.36191C6.32029 3.23688 6.48986 3.16665 6.66667 3.16665H9.33334C9.51015 3.16665 9.67972 3.23688 9.80474 3.36191C9.92977 3.48693 10 3.6565 10 3.83331H6C6 3.6565 6.07024 3.48693 6.19527 3.36191ZM4.66667 3.83331C4.66667 3.30288 4.87739 2.79417 5.25246 2.4191C5.62753 2.04403 6.13624 1.83331 6.66667 1.83331H9.33334C9.86377 1.83331 10.3725 2.04403 10.7476 2.4191C11.1226 2.79417 11.3333 3.30288 11.3333 3.83331H13.3333V5.16665H2.66667V3.83331H4.66667ZM4.66667 5.83331V12.5C4.66667 12.6768 4.73691 12.8464 4.86193 12.9714C4.98696 13.0964 5.15653 13.1666 5.33334 13.1666H10.6667C10.8435 13.1666 11.0131 13.0964 11.1381 12.9714C11.2631 12.8464 11.3333 12.6768 11.3333 12.5V5.83331H12.6667V12.5C12.6667 13.0304 12.456 13.5391 12.0809 13.9142C11.7058 14.2893 11.1971 14.5 10.6667 14.5H5.33334C4.80291 14.5 4.2942 14.2893 3.91912 13.9142C3.54405 13.5391 3.33334 13.0304 3.33334 12.5V5.83331H4.66667ZM7.33334 7.16665C7.33334 6.79846 7.03486 6.49998 6.66667 6.49998C6.29848 6.49998 6.00001 6.79846 6.00001 7.16665V11.1666C6.00001 11.5348 6.29848 11.8333 6.66667 11.8333C7.03486 11.8333 7.33334 11.5348 7.33334 11.1666V7.16665ZM9.33334 6.49998C9.70153 6.49998 10 6.79846 10 7.16665V11.1666C10 11.5348 9.70153 11.8333 9.33334 11.8333C8.96515 11.8333 8.66667 11.5348 8.66667 11.1666V7.16665C8.66667 6.79846 8.96515 6.49998 9.33334 6.49998Z"
          ></path>
        </svg>
      </div>
      <div class="consent-body">
        <div class="filed mt-3">
          <span class="title">Consent description </span>
          <div class="input-container">
            <div class="position-relative">
              <input
                maxlength="80"
                class="w-100"
                type="text"
                v-model="item.description"
                placeholder="Description"
              />
              <span
                class="position-absolute"
                style="right: 6px; top: 0px; transform: translateY(38%)"
                >{{ 80 - item.description?.length }}</span
              >
            </div>
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Required Consent </span>
          <div class="mt-3">
            <SwitchToogle
              v-model:checked="item.required"
              :notAttached="false"
              :paused="false"
              :disabled="false"
            />
          </div>
        </div>
      </div>
    </div>
    <button
      style="width: fit-content"
      :disabled="store.consent?.length >= 2"
      @click="AddConsent"
      class="d-flex align-center justify-space-between upload-span"
    >
      <v-icon icon="mdi-plus" class="Caption"></v-icon>
      <span>Add Consent</span>
      <v-icon icon="mdi-chevron-down" class="small-text"></v-icon>
    </button>
  </div>
</template>
<script>
import { useLeadFormStore } from "@/store/snapchat/LeadForm";
import SwitchToogle from "@/components/snapchat/Label&input/SwitchToogle.vue";

export default {
  name: "DisclosuresForm",
  components: { SwitchToogle },
  data() {
    return {
      store: useLeadFormStore(),
    };
  },
  methods: {
    RemoveConsent(index) {
      this.store.consent.splice(index, 1);
    },
    AddConsent() {
      if (this.store.consent?.length < 2) {
        this.store.consent.push({
          description: "",
          required: false,
        });
      }
    },
  },
};
</script>
<style scoped>
input {
  border: 2px solid transparent;
  border-radius: 8px !important;
  background: #f9f9fa;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0 0 0 0 transparent;
  box-sizing: border-box;
  font-family: inherit;
  transition: box-shadow 0.15s ease-out 0.15s, border-color 0.15s,
    background 0.15s ease-out 0.15s, color 0.15s ease-out;
  width: 100%;
  border: transparent 2px solid;
  height: 40px;
}
</style>
