<template>
  <div class="snapchat parent w-100 position-relative">
    <header class="d-flex jsutify-space-between align-center">
      <div>
        <span> Snapchat Ads1 </span>
      </div>
      <div>
        <img src="@/assets/snapchatImage/download.svg" alt="" />
      </div>
      <div style="width: 200px">
        <treeselect
          v-model="selectedOrg"
          :multiple="false"
          :options="store.organizations"
          :value-format="'object'"
          item-text="label"
          parent-link-disabled
        >
        </treeselect>
      </div>
    </header>
    <v-dialog v-model="dialog" width="800" class="choose-opjective-type">
      <v-card max-width="750" title="Choose Objective">
        <div class="create-new-camp-nav">
          <p class="create-title"><span>Create New Campaign</span></p>
          <v-container class="">
            <p class="title-select mb-3">objective</p>
            <v-row class="w-100 mb-2">
              <v-col cols="6">
                <div
                  v-for="(object, i) in saveData"
                  :key="i"
                  class="options-group radio-button"
                >
                  <input
                    name="radio-group"
                    :id="object.id"
                    class="radio-button__input"
                    :checked="object.checked"
                    type="radio"
                    @change="selectOption(i)"
                  />
                  <label
                    :for="object.id"
                    class="radio-button__label"
                    :class="{ active: object.checked }"
                  >
                    <span class="radio-button__custom"></span>
                    <span
                      class="icon-select"
                      :class="object.checked ? 'active' : ''"
                      v-html="object.svg"
                    >
                    </span>
                    {{ object.title }}
                  </label>
                </div>
              </v-col>
              <v-col cols="6" class="objective-descriptions">
                <div v-if="object1" class="objective-awareness-descriptions">
                  <div class="first-section mb-5">
                    <img
                      src="@/assets/snapchatImage/218e1a0c4b913ae4d17c6404d31ae915.svg"
                      alt=""
                    />
                    <p>Reach Snapchatters and create interest in your brand.</p>
                  </div>
                  <div class="secound-section mb-5">
                    <p class="mb-4">
                      <b>Outcomes </b>good for Awareness & Engagement objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'Reach Snapchatters who are more likely to remember your brand.'
                        "
                      >
                        Brand Awareness
                      </span>
                      <span
                        v-tooltip:top="
                          'Reach Snapchatters who are more likely to watch your videos.'
                        "
                      >
                        Video Views
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to engage with your ad, like your AR lenses.'
                        "
                      >
                        Ad Engagement
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to call or text your business'
                        "
                      >
                        Calls & Texts
                      </span>
                      <span
                        v-tooltip:top="
                          'Promote your physical locations to Snapchatters.'
                        "
                      >
                        Place Promotion
                      </span>
                    </div>
                  </div>
                  <div class="third-section mb-5">
                    <p class="mb-4">
                      <b>Ad Format </b> available for Awareness & Engagement
                      objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'A full-screen ad that shows Snapchatters a single image or video after or in between content like Stories.'
                        "
                      >
                        Single Image or Video
                      </span>
                      <span
                        v-tooltip:top="
                          'An ad that shows Snapchatters a branded tile in the Discover section that opens into a collection of 1 - 10 images or videos.'
                        "
                      >
                        Story Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'A single image or video ad with four tappable tiles to feature your products. Learn More.'
                        "
                      >
                        Collection Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'Augmented Reality (AR) Lenses and Filters are a unique way for Snapchatters to play and engage with the ads you create.'
                        "
                      >
                        AR Ads
                      </span>
                      <span
                        v-tooltip:top="
                          'A full-screen video ad that cannot be skipped for up to the first 6 seconds of play time that Snapchatters see in curated content like Shows.'
                        "
                      >
                        Commercial
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="object2" class="objective-awareness-descriptions">
                  <div class="first-section mb-5">
                    <img src="@/assets/snapchatImage/download (1).svg" alt="" />
                    <p>
                      Send Snapchatters to a destination like a website or app.
                    </p>
                  </div>
                  <div class="secound-section mb-5">
                    <p class="mb-4">
                      <b>Outcomes </b>good for Traffic objective
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to click a link to your website or app.'
                        "
                      >
                        Clicks to Website or App
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to view your fully loaded website or app.'
                        "
                      >
                        Landing Page Views
                      </span>
                    </div>
                  </div>
                  <div class="third-section mb-5">
                    <p class="mb-4">
                      <b>Ad Format </b> available for Traffic objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'A full-screen ad that shows Snapchatters a single image or video after or in between content like Stories.'
                        "
                      >
                        Single Image or Video
                      </span>
                      <span
                        v-tooltip:top="
                          'An ad that shows Snapchatters a branded tile in the Discover section that opens into a collection of 1 - 10 images or videos.'
                        "
                      >
                        Story Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'A single image or video ad with four tappable tiles to feature your products. Learn More.'
                        "
                      >
                        Collection Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'Augmented Reality (AR) Lenses and Filters are a unique way for Snapchatters to play and engage with the ads you create.'
                        "
                      >
                        AR Ads
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="object3" class="objective-awareness-descriptions">
                  <div class="first-section mb-5">
                    <img
                      src="@/assets/snapchatImage/1430d33585ba2ef694c3145c157e4dca.svg"
                      alt=""
                    />
                    <p>
                      Get Snapchatters to share their contact details with your
                      business.
                    </p>
                  </div>
                  <div class="secound-section mb-5">
                    <p class="mb-4">
                      <b>Outcomes </b>good for Leads objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to fill out a form to share their contact info with you.'
                        "
                      >
                        Leads
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to visit your website or app and share their contact info with you.'
                        "
                      >
                        Clicks to Lead Form or Website
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to take action on your website, like signing up.'
                        "
                      >
                        Website Sign Up
                      </span>
                    </div>
                  </div>
                  <div class="third-section mb-5">
                    <p class="mb-4">
                      <b>Ad Format </b> available for Leads objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'A full-screen ad that shows Snapchatters a single image or video after or in between content like Stories.'
                        "
                      >
                        Single Image or Video
                      </span>
                      <span
                        v-tooltip:top="
                          'An ad that shows Snapchatters a branded tile in the Discover section that opens into a collection of 1 - 10 images or videos.'
                        "
                      >
                        Story Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'A single image or video ad with four tappable tiles to feature your products. Learn More.'
                        "
                      >
                        Collection Ad
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="object4" class="objective-awareness-descriptions">
                  <div class="first-section mb-5">
                    <img src="@/assets/snapchatImage/download (2).svg" alt="" />
                    <p>
                      Get Snapchatters to install and take actions in your app.
                    </p>
                  </div>
                  <div class="secound-section mb-5">
                    <p class="mb-4">
                      <b>Outcomes </b>good for Promotion objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="'Get Snapchatters to install your app.'"
                      >
                        App Installs
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to install your app and take action, like making a purchase.'
                        "
                      >
                        App Installs + In-App Action
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters who use your app to take action, like making a purchase.'
                        "
                      >
                        App Re-Engagement
                      </span>
                    </div>
                  </div>
                  <div class="third-section mb-5">
                    <p class="mb-4">
                      <b>Ad Format </b> available for App Promotion objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'A full-screen ad that shows Snapchatters a single image or video after or in between content like Stories.'
                        "
                      >
                        Single Image or Video
                      </span>
                      <span
                        v-tooltip:top="
                          'An ad that shows Snapchatters a branded tile in the Discover section that opens into a collection of 1 - 10 images or videos.'
                        "
                      >
                        Story Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'A single image or video ad with four tappable tiles to feature your products. Learn More.'
                        "
                      >
                        Collection Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'Augmented Reality (AR) Lenses and Filters are a unique way for Snapchatters to play and engage with the ads you create.'
                        "
                      >
                        AR Ads
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="object5" class="objective-awareness-descriptions">
                  <div class="first-section mb-5">
                    <img src="@/assets/snapchatImage/download (3).svg" alt="" />
                    <p>Get Snapchatters to purchase your product or service.</p>
                  </div>
                  <div class="secound-section mb-5">
                    <p class="mb-4">
                      <b>Outcomes </b>good for Sales objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to buy products or services from your catalog.'
                        "
                      >
                        Catalog Sales
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to take action on your website, like adding to cart or making a purchase.'
                        "
                      >
                        Website Conversions
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters who use your app to take action, like making a purchase.'
                        "
                      >
                        App Re-Engagement
                      </span>
                    </div>
                  </div>
                  <div class="third-section mb-5">
                    <p class="mb-4">
                      <b>Ad Format </b> available for Awareness & Engagement
                      objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'A full-screen ad that shows Snapchatters a single image or video after or in between content like Stories.'
                        "
                      >
                        Single Image or Video
                      </span>
                      <span
                        v-tooltip:top="
                          'An ad that shows Snapchatters a branded tile in the Discover section that opens into a collection of 1 - 10 images or videos.'
                        "
                      >
                        Story Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'A single image or video ad with four tappable tiles to feature your products. Learn More.'
                        "
                      >
                        Collection Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'Augmented Reality (AR) Lenses and Filters are a unique way for Snapchatters to play and engage with the ads you create.'
                        "
                      >
                        AR Ads
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="dialog-footer">
              <v-col cols="12" class="d-flex justify-end">
                <v-btn
                  type="button"
                  @click="dialog = false"
                  color="#444546"
                  rounded
                  variant="outlined"
                  >Cancel</v-btn
                >
                <v-btn
                  type="button"
                  ref="toCreateCampaign"
                  class="toCreateCampaign"
                  :disabled="isButtonDisabled"
                  rounded
                  variant="outlined"
                  @click="nextAction"
                  >Next</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>
    <v-container class="create-ads-header mt-8">
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-center">
          <h4 class="title">Create Ads</h4>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <p class="sub-title">How would you like to create today?</p>
        </v-col>

        <v-col cols="12" class="mt-8">
          <v-card
            class="mx-auto pa-5 rounded-lg"
            max-width="344"
            @click="checkSnapToken"
            hover
          >
            <img
              src="@/assets/snapchatImage/advanced-select.svg"
              class="d-block ma-auto"
              alt=""
            />
            <v-card-item>
              <v-card-title class="text-center"> Advanced Create </v-card-title>
              <v-card-text class="text-center">
                Take <b>full control</b> over your ads. Create multiple ad sets
                with multiple ads for your campaign.
              </v-card-text>
            </v-card-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Treeselect from "vue3-treeselect"
import "@/assets/snapchatStyle/snapchatStyle.css";
import { useDataStore } from "@/store/snapchat/snap-chat-static-data.js";
import { useAwarenessStore } from "@/store/snapchat/awareness";
import { mapState } from "pinia";
import apiClient from "@/core/buildRequest"; // Use apiClient instead of axios 
import { useGeneralStore } from "@/store/General";
export default {
  components: { Treeselect },
  name: "SnapChat",
  data() {
    return {
      store: useAwarenessStore(),
      imageData: "",
      dialog: false,
      object1: true,
      object2: false,
      object3: false,
      object4: false,
      object5: false,
      isButtonDisabled: true,
      saveData: this.objectiveArr,
      appSelectedFromParent: false,
      selectedOrg: localStorage.getItem("selectedOrg")
        ? JSON.parse(localStorage.getItem("selectedOrg"))
        : null,
      useGeneralStore: useGeneralStore(),
    };
  },
  computed: {
    ...mapState(useDataStore, ["selectedOptions", "objectiveArr"]),
  },
  mounted() {
    this.store.getAllOrganizations();
    this.saveData = this.objectiveArr;
  },
  methods: {
    handleFile(event) {
      this.selectedFile = event.target.files[0];
    },
    test() {
      const awarenessStore = useAwarenessStore();
      awarenessStore.sendCreative(this.selectedFile);
    },
    selectOption(selectedIndex) {
      if (selectedIndex === null || selectedIndex === false) {
        // Handle deselection
        this.objectiveArr.forEach((object) => (object.checked = false));
        this.isButtonDisabled = true;
        this.object1 =
          this.object2 =
          this.object3 =
          this.object4 =
          this.object5 =
            false;
      } else {
        this.saveData = this.saveData.map((object, index) => ({
          ...object,
          checked: index === selectedIndex,
        }));
        this.isButtonDisabled = false;
        this.object1 = selectedIndex === 0;
        this.object2 = selectedIndex === 1;
        this.object3 = selectedIndex === 2;
        this.object4 = selectedIndex === 3;
        this.object5 = selectedIndex === 4;
        this.selectedIndex = selectedIndex;
      }
    },
    nextAction() {
      const store = useDataStore();
      switch (this.selectedIndex) {
        case 0:
          localStorage.setItem("selectedOption", "Awareness & Engagement");
          this.$router.push({ name: "SnapFirstStep" });
          localStorage.setItem("appSelectedFromParent", false);
          localStorage.setItem("appPromotionGoal", false);
          localStorage.setItem("dynamicAds", false);
          localStorage.setItem("leadsConversionSelected", false);
          store.objectiveArr.map((e, i) => {
            if (i === 0) {
              e.checked = true;
            } else {
              e.checked = false;
            }
          });
          break;
        case 1:
          localStorage.setItem("selectedOption", "Traffic");
          this.$router.push({ name: "TrafficFirstStep" });
          localStorage.setItem("appSelectedFromParent", true);
          localStorage.setItem("appPromotionGoal", false);
          localStorage.setItem("leadsConversionSelected", false);
          localStorage.setItem("dynamicAds", false);
          store.objectiveArr.map((e, i) => {
            if (i === 1) {
              e.checked = true;
            } else {
              e.checked = false;
            }
          });
          break;
        case 2:
          localStorage.setItem("selectedOption", "Leads");
          localStorage.setItem("appSelectedFromParent", true);
          localStorage.setItem("appPromotionGoal", false);
          localStorage.setItem("dynamicAds", false);
          this.$router.push({ name: "LeadsFirstStep" });
          localStorage.setItem("leadsConversionSelected", false);
          store.objectiveArr.map((e, i) => {
            if (i === 2) {
              e.checked = true;
            } else {
              e.checked = false;
            }
          });
          break;
        case 3:
          localStorage.setItem("selectedOption", "App Promotion");
          this.$router.push({ name: "appPromotionFirstStep" });
          localStorage.setItem("appPromotionGoal", true);
          localStorage.setItem("appSelectedFromParent", false);
          localStorage.setItem("dynamicAds", true);
          localStorage.setItem("leadsConversionSelected", false);
          store.objectiveArr.map((e, i) => {
            if (i === 3) {
              e.checked = true;
            } else {
              e.checked = false;
            }
          });
          break;
        case 4:
          localStorage.setItem("selectedOption", "Sales");
          this.$router.push({ name: "salesFirstStep" });
          localStorage.setItem("appSelectedFromParent", false);
          localStorage.setItem("appPromotionGoal", false);
          localStorage.setItem("dynamicAds", true);
          localStorage.setItem("leadsConversionSelected", true);
          store.objectiveArr.map((e, i) => {
            if (i === 4) {
              e.checked = true;
            } else {
              e.checked = false;
            }
          });
          break;
        default:
          break;
      }
    },
    changeDialogStatus() {
      this.dialog = true;
    },
    checkSnapToken() {
      if (!localStorage.getItem("account_id")) {
        this.useGeneralStore.showSnackbar("Please select an account", "error");
        return;
      }
      const snapchat_token = localStorage.getItem("snapchat_token");
      if (snapchat_token) {
        this.dialog = true;
      } else {
        apiClient
          .get("/auth/snapchat")
          .then((response) => {
            window.open(response.data.data.redirect_url, "_blank");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },

  watch: {
    selectedOrg(newVal) {
      localStorage.setItem("selectedOrg", JSON.stringify(newVal));
      localStorage.setItem("organization_id", newVal.org_id);
      localStorage.setItem("account_id", newVal.id);
      this.store.account_id = newVal.id;
      this.store.organization_id = newVal.org_id;
    },
    selectedIndex(newVal) {
      this.selectOption(newVal);
    },
    imageData(newVal) {
      this.image = newVal;
      this.test();
    },
  },
};
</script>