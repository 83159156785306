<template>
  <Layout :linksData="linksData">
    <div
      class="leads-managements-link d-flex align-center justify-space-between ga-2 mb-3"
    >
      <div
        @click="$router.push({ name: 'Leadsmanagement' })"
        class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
            fill="#1F666A"
          />
        </svg>
        <span>Leads management</span>
      </div>
      <button class="cancel-btn d-flex align-center ga-2">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10M4.66667 6.66667L8 10M8 10L11.3333 6.66667M8 10V2"
            stroke="#666666"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span>Export</span>
      </button>
    </div>
    <div class="grid-container-1-1-15">
      <NewLeads :data="new_leads_data" @filter="leadsFilter" />
      <ConfirmedLeads
        :data="confirmed_leads_data"
        @filter="ConfirmedLeadsFilter"
      />
      <ConversionRate
        :data="conversion_rate_data"
        @filter="ConversionRateFilter"
      />
    </div>
    <div class="grid-container-1-1 mt-5">
      <TopPerformingCompaign
        :data="top_performing_compaign_data"
        @filter="TopPerformingCompaignFilter"
      />
      <BookedLeads :data="booked_leads_data" />
    </div>
    <div class="grid-container-1-1-1 mt-5">
      <LeadsStatus :data="leads_status_data" />
      <ReachabilityCard :data="reachability_data" />
      <AttendanceCard :data="attendance_data" />
    </div>
    <div class="mt-4">
      <LeadsPerPlatform
        :data="platforms_data"
        @filter="LeadsPerPlatformFilter"
      />
    </div>
    <div class="mt-4">
      <AgentsAssignedToLeads :data="agents_data" />
    </div>
  </Layout>
</template>
<script>
import Layout from "@/components/crm/layouts/Layout.vue";
import NewLeads from "@/components/crm/leads/dashboard/NewLeads.vue";
import ConfirmedLeads from "@/components/crm/leads/dashboard/ConfirmedLeads.vue";
import ConversionRate from "@/components/crm/leads/dashboard/ConversionRate.vue";
import TopPerformingCompaign from "@/components/crm/leads/dashboard/TopPerformingCompaign.vue";
import BookedLeads from "@/components/crm/leads/dashboard/BookedLeads.vue";
import LeadsStatus from "@/components/crm/leads/dashboard/LeadsStatus.vue";
import ReachabilityCard from "@/components/crm/leads/dashboard/ReachabilityCard.vue";
import AttendanceCard from "@/components/crm/leads/dashboard/AttendanceCard.vue";
import LeadsPerPlatform from "@/components/crm/leads/dashboard/LeadsPerPlatform.vue";
import AgentsAssignedToLeads from "@/components/crm/leads/dashboard/AgentsAssignedToLeads.vue";
import SnapchatIcon from "@/assets/images/icons/snapcaht.svg";
import xIcon from "@/assets/images/icons/x.svg";
import InstagramIcon from "@/assets/images/icons/instagram.svg";
import FacebookIcon from "@/assets/images/icons/facebook.svg";
import TikTokIcon from "@/assets/images/icons/tiktok.svg";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale
);
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import apiClient from "@/core/buildRequest";

export default {
  components: {
    Layout,
    NewLeads,
    ConfirmedLeads,
    ConversionRate,
    TopPerformingCompaign,
    BookedLeads,
    LeadsStatus,
    ReachabilityCard,
    AttendanceCard,
    LeadsPerPlatform,
    AgentsAssignedToLeads,
  },

  data() {
    return {
      SnapchatIcon,
      xIcon,
      InstagramIcon,
      FacebookIcon,
      TikTokIcon,

      booked_leads_data: {
        value: 0,
        total: 0,
      },
      leads_status_data: [
        {
          status: "touched",
          total: 7,
          percentage: "70.00",
        },
        {
          status: "untouched",
          total: 3,
          percentage: "30.00",
        },
      ],
      reachability_data: [
        {
          status: "reached",
          total: 7,
          percentage: "70.00",
        },
        {
          status: "unreached",
          total: 3,
          percentage: "30.00",
        },
      ],
      attendance_data: [
        {
          label: "Attended",
          percentage: "70.00",
        },
        {
          label: "Not Attended",
          percentage: "30.00",
        },
      ],
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Leads Dashboard", link: "" },
      ],
      new_leads_filter: "",
      confirmed_leads_filter: "",
      platform_filter: "",
      agentsFilter: null,
      dialog: false,
      selectedAgents: [],
      new_leads_data: {
        total: 0,
        percentage: 0 + "%",
        growth_rate: "Negative",
      },
      confirmed_leads_data: {
        total: 0,
        percentage: 0 + "%",
        growth_rate: "Negative",
      },
      conversion_rate_data: {
        total: 0,
        percentage: 0 + "%",
        growth_rate: "Negative",
      },
      top_performing_compaign_data: [],
      filter: {
        leads: {
          filter_by: null,
          from: null,
          to: null,
        },
        confirmed_leads: {
          filter_by: null,
          from: null,
          to: null,
        },
        top_performing: {
          filter_by: null,
          from: null,
          to: null,
        },
        top_campaigns: {
          filter_by: null,
          from: null,
          to: null,
        },
        convertion_status: {
          filter_by: null,
          from: null,
          to: null,
        },
        status: null,
        reachablitiy: null,
      },
    };
  },
  mounted() {
    this.getData();

    ChartJS.register({
      id: "centerText",
      beforeDraw(chart) {
        if (chart.config.options.plugins?.centerText?.enabled) {
          const { width, height } = chart;
          const ctx = chart.ctx;
          ctx.save();
          const fontSize = (height / 150).toFixed(2);
          ctx.font = `${fontSize}em sans-serif`;
          ctx.textBaseline = "middle";

          const text = chart.config.options.plugins.centerText.text || "60%";
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 2;

          ctx.fillStyle =
            chart.config.options.plugins.centerText.color || "#000"; // Text color
          ctx.fillText(text, textX, textY);
          ctx.restore();
        }
      },
    });
  },
  computed: {},
  methods: {
    formatFilterFormData(filter) {
      let formattedFilter = {};

      function addParam(key, value) {
        if (value !== null && value !== undefined && value !== "") {
          formattedFilter[key] = Array.isArray(value)
            ? JSON.stringify(value)
            : value;
        }
      }
      for (const category in filter) {
        if (typeof filter[category] === "object" && filter[category] !== null) {
          formattedFilter[category] = {};
          for (const subKey in filter[category]) {
            formattedFilter[category][subKey] = filter[category][subKey];
          }
        } else {
          addParam(category, filter[category]);
        }
      }

      return formattedFilter;
    },
    handleSelection(value) {
      if (value === "custom") {
        this.openCustomDialog();
      } else {
        this.filterAgents(value);
      }
    },
    openCustomDialog() {
      this.dialog = true;
    },
    leadsFilter(from, to, filter) {
      this.filter.leads.filter_by = filter;
      this.filter.leads.from = from;
      this.filter.leads.to = to;
      this.getData();
    },
    ConfirmedLeadsFilter(from, to, filter) {
      this.filter.confirmed_leads.filter_by = filter;
      this.filter.confirmed_leads.from = from;
      this.filter.confirmed_leads.to = to;
      this.getData();
    },
    ConversionRateFilter(from, to, filter, status, reachablitiy) {
      this.filter.convertion_status.filter_by = filter;
      this.filter.convertion_status.from = from;
      this.filter.convertion_status.to = to;
      this.filter.status = status;
      this.filter.reachablitiy = reachablitiy;
      this.getData();
    },
    TopPerformingCompaignFilter(from, to, filter) {
      this.filter.top_campaigns.filter_by = filter;
      this.filter.top_campaigns.from = from;
      this.filter.top_campaigns.to = to;
      this.getData();
    },
    LeadsPerPlatformFilter(from, to, filter) {
      this.filter.top_performing.filter_by = filter;
      this.filter.top_performing.from = from;
      this.filter.top_performing.to = to;
      this.getData();
    },
    async getData() {
      let filters = this.formatFilterFormData(this.filter);
      try {
        await apiClient
          .get(`/leads-managment/leads/get/insights`, {
            params: { filter: filters },
          })
          .then((response) => {
            console.log(response.data.data);
            this.new_leads_data = {
              total: response.data.data.totalLeads.totalLeads,
              percentage: `${response.data.data.totalLeads.growth}%`,
              growth_rate:
                response.data.data.totalLeads.growth_rate || "Negative",
            };
            this.confirmed_leads_data = {
              total: response.data.data.confirmedLeads.confirmedLeads,
              percentage: `${response.data.data.confirmedLeads.growth}%`,
              growth_rate:
                response.data.data.confirmedLeads.growth_rate || "Negative",
            };
            this.conversion_rate_data = {
              total: response.data.data.convertionStatus.total,
              percentage: `${response.data.data.convertionStatus.percentage}%`,
              growth_rate:
                response.data.data.convertionStatus.growth_rate || "Negative",
            };
            this.top_performing_compaign_data =
              response.data.data.topPerformingCampaigns;
            this.booked_leads_data.value = response.data.data.bookedPercentage;
            this.booked_leads_data.total = response.data.data.bookedLeads;
            this.booked_leads_data.value = 50;
            this.booked_leads_data.total = 200;
            this.leads_status_data = response.data.data.leadsStatus;
            this.reachability_data = response.data.data.leadReachability;
            this.attendance_data[0].percentage =
              response.data.data.attendentedPercentage;
            this.attendance_data[1].percentage =
              response.data.data.notAttendedPercentage;
            this.platforms_data = response.data.data.topPerformingPlatform;
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>

<style>
.number-chart-percent .chart {
  position: relative;
  border-radius: 15px;
  background-color: rgba(240, 240, 240, 0.342);
  width: 85%;
  height: 8px;
}
.number-chart-percent .chart .background {
  background: linear-gradient(90deg, #1f666a 0%, #3f878d 42%, #4f979f 100%);
  border-radius: 15px;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
}
.grid-container-1-1-15 {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  gap: 20px;
}
@media (max-width: 768px) {
  .grid-container-1-1-15 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .grid-container-1-1-15 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.grid-container-1-1 {
  display: grid;
  grid-template-columns: 2fr 1.5fr;
  gap: 20px;
}
@media (max-width: 768px) {
  .grid-container-1-1 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .grid-container-1-1 {
    grid-template-columns: 1fr 1fr;
  }
}
.grid-container-1-1-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
@media (max-width: 768px) {
  .grid-container-1-1-1 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .grid-container-1-1-1 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.dashboard-card .v-input .v-input__details {
  display: none !important;
}
.dashboard-card.agents-select .v-input,
.dashboard-card.agents-select .v-input .v-input__control {
  width: 100% !important;
}
.dashboard-card .v-input:not(.agents-select) .v-input__control {
  width: 131px !important;
  height: 32px;
  border-radius: 10px;
}
.dashboard-card .v-input .v-input__control {
  width: 131px !important;
  height: 32px;
  /* padding: 0px 15px !important; */
}
.dashboard-card .v-input {
  border: 1px solid #e8e8e8;
  overflow: hidden;
}
.dashboard-card .v-input .v-input__control .v-field .v-field__overlay {
  background: white;
}
.dashboard-card .v-input .v-input__control .v-field,
.dashboard-card .v-input .v-input__control .v-field .v-field__field,
.dashboard-card
  .v-input
  .v-input__control
  .v-field
  .v-field__field
  .v-field__input {
  width: 131px !important;
  height: 32px;
  padding: 0px;
  min-height: 32px;
}
.dashboard-card
  .v-input
  .v-input__control
  .v-field
  .v-field__field
  .v-field__append-inner {
  padding: 0px;
}
.dashboard-card .v-input .v-input__control .v-field .v-field__outline {
  display: none;
}
.dashboard-card .v-input .v-input__control .v-field,
.dashboard-card .v-input .v-input__control .v-field .v-field__field,
.dashboard-card
  .v-input
  .v-input__control
  .v-field
  .v-field__field
  .v-field__input {
  width: 100% !important;
  flex-wrap: nowrap;
}
thead {
  background: #f2f2f2;
}
thead td {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #1a1a1a;
}
thead td,
tbody td {
  height: 40px;
  padding: 12px;
}
.red-status,
.green-status {
  height: 32px;
  padding: 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.red-status {
  background: #fef3f2;
  color: #d91313;
}
.green-status {
  background: #ecfdf3;
  color: #027a48;
}
.v-table {
  border-radius: 8px !important;
  border: solid 1px rgb(236, 236, 236) !important;
}
.touched-untouched-labels div .dots {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.choose-btn {
  width: 100px;
  height: 32px;
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  background: #237276;
  color: white;
  text-align: center;
}
.choose-btn:hover {
  background: #144244;
}
.cancel-btn {
  width: 100px;
  height: 32px;
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  background: #f2f2f2;
  color: rgb(99, 99, 99);
  text-align: center;
}
.cancel-btn:hover {
  background: #aaaaaa;
}
</style>
