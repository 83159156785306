import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";
import snapchatIcon from "@/assets/images/icons/snapcaht.svg";
import xIcon from "@/assets/images/icons/x.svg";
import instagramIcon from "@/assets/images/icons/instagram.svg";
import facebookIcon from "@/assets/images/icons/facebook.svg";
import tiktokIcon from "@/assets/images/icons/tiktok.svg";
// import LinkedInIcon from "@/assets/images/icons/linkedIn.svg";
import { useRouter } from "vue-router";
import { useGeneralStore } from "@/store/General";
export const publishPost = defineStore("publishPost", {
  state: () => ({
    useGeneralStore: useGeneralStore(),
    storyContant: false,
    mediaFilsPost: [],
    facebookPostMedia: [],
    instagramPostMedia: [],
    tikTokPostMedia: [],
    snapChatPostMedia: [],
    facebookStoryMedia: [],
    facebookReelMedia: [],
    instagramStoryMedia: [],
    instagramReelMedia: [],
    tikTokStoryMedia: [],
    snapchatStoryMedia: [],
    snapChatReelMedia: [],
    xPostMedia: [],
    xPostMedia: [],
    allMediaFils: [],
    post: {
      facebook: [],
      instagram: [],
      tiktok: [],
      snapchat: [],
      x: [],
    },
    errors: [],
    comment: "",
    commentImages: [],
    boost: {
      facebook: {
        state: false,
        id: 15,
        icon: facebookIcon,
        ad_account_id: "",
        page_id: "",
        objective: "",
        lifetime_budget: "",
        special_ad_categories: "",
        start_time: "",
        end_time: "",
        targeting: {
          geo_locations: {
            countries: [""],
            regions: [""],
            radius: "",
            distance_unit: "",
          },
        },
        genders: "",
        age_max: "",
        age_min: "",
        publisher_platforms: null,
        flexible_spec: {
          interests: {
            id: "",
            name: "",
          },
        },
      },

      instagram: {
        state: false,
        budget: "",
        duration: "",
        currency: "EG",
        icon: instagramIcon,
        id: 35,
      },
      tiktok: {
        state: false,
        budget: "",
        duration: "",
        currency: "EG",
        icon: tiktokIcon,
        id: 25,
      },
      snapchat: {
        state: false,
        budget: "",
        duration: "",
        currency: "EG",
        icon: snapchatIcon,
        id: 45,
      },
      x: {
        state: false,
        budget: "",
        duration: "",
        currency: "EG",
        icon: xIcon,
        id: 55,
      },
      loading: false,
    },
    pagesIds: [],
    pages: [],
  }),
  getters: {
    getSelectedTypes() {
      let selectedTypes = [];
      Object.keys(this.post).forEach((key) => {
        if (Array.isArray(this.post[key])) {
          selectedTypes[key] = [];
          this.post[key].forEach((entry) => {
            selectedTypes[key].push(entry.type);
          });
        }
      });
      return selectedTypes;
    },
    getPost() {
      return this.post;
    },
    getCarouselSlider() {
      return Object.entries(this.post).flatMap(([platform, features]) => {
        return features.map((item) => ({
          platform,
          type: item.type,
        }));
      });
    },
  },
  actions: {
    updateStoryContant() {
      this.storyContant = !this.storyContant;
    },
    sendPostDate() {},
    UpdatePlatformTypes(platformsfor, platform, typeKeys) {
      if (this.post[platform] && Array.isArray(this.post[platform])) {
        const existingTypes = new Map(
          this.post[platform].map((item) => [item.type, item])
        );

        const updatedEntries = typeKeys.map((type) => {
          if (existingTypes.has(type)) {
            return existingTypes.get(type);
          } else {
            if (platformsfor === "customize") {
              return { type, content: "", url: "", comment: "", file: "" };
            } else if (platformsfor === "all") {
              let firstValidEntry = null;
              for (const key in this.post) {
                if (this.post[key] && Array.isArray(this.post[key])) {
                  firstValidEntry = this.post[key].find(
                    (item) => item.content && item.url
                  );
                  if (firstValidEntry) break;
                }
              }
              if (firstValidEntry) {
                return {
                  type,
                  content: firstValidEntry.content,
                  url: firstValidEntry.url,
                };
              } else {
                return { type, content: "", url: "", comment: "", file: "" };
              }
            }
          }
        });

        this.post[platform] = updatedEntries;
      } else {
        console.error(`Invalid platform: ${platform}`);
      }
    },
    UpdatePlatformContents(
      platformsfor,
      platform = null,
      typeKey = null,
      content
    ) {
      if (platformsfor === "customize") {
        if (platform) {
          if (this.post[platform] && Array.isArray(this.post[platform])) {
            if (typeKey) {
              const entry = this.post[platform].find(
                (item) => item.type === typeKey
              );
              if (entry) {
                entry.content = content;
              } else {
                alert(
                  `No entry found with type '${typeKey}' in platform '${platform}'`
                );
              }
            } else {
              this.post[platform].forEach((entry) => {
                entry.content = content;
              });
            }
          } else {
            console.error(`Invalid platform: ${platform}`);
          }
        } else if (typeKey) {
          Object.keys(this.post).forEach((key) => {
            if (Array.isArray(this.post[key])) {
              this.post[key].forEach((entry) => {
                if (entry.type === typeKey) {
                  entry.content = content;
                }
              });
            }
          });
        }
      } else {
        Object.keys(this.post).forEach((key) => {
          if (Array.isArray(this.post[key])) {
            this.post[key].forEach((entry) => {
              entry.content = content;
            });
          }
        });
      }
    },
    UpdatePlatformLinks(
      platformsfor,
      platform = null,
      typeKey = null,
      url,
      name = null
    ) {
      if (platformsfor === "customize") {
        if (platform) {
          if (this.post[platform] && Array.isArray(this.post[platform])) {
            if (typeKey) {
              const entry = this.post[platform].find(
                (item) => item.type === typeKey
              );
              if (entry) {
                entry.url = url;
                entry.url_name = name;
              } else {
              }
            } else {
              this.post[platform].forEach((entry) => {
                entry.url = url;
                entry.url_name = name;
              });
            }
          } else {
            console.error(`Invalid platform: ${platform}`);
          }
        } else if (typeKey) {
          Object.keys(this.post).forEach((key) => {
            if (Array.isArray(this.post[key])) {
              this.post[key].forEach((entry) => {
                if (entry.type === typeKey) {
                  entry.url = url;
                  entry.url_name = name;
                }
              });
            }
          });
        }
      } else {
        Object.keys(this.post).forEach((key) => {
          if (Array.isArray(this.post[key])) {
            this.post[key].forEach((entry) => {
              entry.url = url;
              entry.url_name = name;
            });
          }
        });
      }
      console.log(this.post);
    },
    RemovePlatformLinks(platformsfor, platform = null, typeKey = null) {
      if (platformsfor === "customize") {
        if (platform) {
          if (this.post[platform] && Array.isArray(this.post[platform])) {
            if (typeKey) {
              const entry = this.post[platform].find(
                (item) => item.type === typeKey
              );
              if (entry) {
                entry.url = "";
              } else {
              }
            } else {
              this.post[platform].forEach((entry) => {
                entry.url = "";
              });
            }
          } else {
            console.error(`Invalid platform: ${platform}`);
          }
        } else if (typeKey) {
          Object.keys(this.post).forEach((key) => {
            if (Array.isArray(this.post[key])) {
              this.post[key].forEach((entry) => {
                if (entry.type === typeKey) {
                  entry.url = "";
                }
              });
            }
          });
        }
      } else {
        Object.keys(this.post).forEach((key) => {
          if (Array.isArray(this.post[key])) {
            this.post[key].forEach((entry) => {
              entry.url = "";
            });
          }
        });
      }
    },
    updatePlatformFile(platformsfor, platform = null, typeKey = null, file) {
      if (platformsfor === "customize") {
        if (platform) {
          if (this.post[platform] && Array.isArray(this.post[platform])) {
            if (typeKey) {
              const entry = this.post[platform].find(
                (item) => item.type === typeKey
              );
              if (entry) {
                entry.file = file;
              } else {
              }
            } else {
              this.post[platform].forEach((entry) => {
                entry.file = file;
              });
            }
          } else {
            console.error(`Invalid platform: ${platform}`);
          }
        } else if (typeKey) {
          Object.keys(this.post).forEach((key) => {
            if (Array.isArray(this.post[key])) {
              this.post[key].forEach((entry) => {
                if (entry.type === typeKey) {
                  entry.file = file;
                }
              });
            }
          });
        }
      } else {
        const allEmpty = Object.keys(this.post).every(
          (key) => Array.isArray(this.post[key]) && this.post[key].length === 0
        );
        if (allEmpty) {
          this.post["facebook"].push({
            type: "",
            content: "",
            url: "",
            file: file,
            comment: "",
          });
        } else {
          Object.keys(this.post).forEach((key) => {
            if (Array.isArray(this.post[key])) {
              this.post[key].forEach((entry) => {
                entry.file = file;
              });
            }
          });
        }
      }
    },
    async SendPostToPublish() {
      this.useGeneralStore.ShowLoading(false);
      this.validation();
      if (this.errors.length > 0);
      this.loading = true;
      apiClient
        .post("/publish-posts/create/post", {
          post: await this.formattedRequestToSend(),
          boost: this.createNewAudience(),
        })
        .then((response) => {
          this.useGeneralStore.HideLoading();
          this.loading = false;
        });
    },
    validation() {
      this.errors = [];
      if (this.post.facebook) {
        this.post.facebook.forEach((item, index) => {
          if (!["post", "reel", "story"].includes(item.type)) {
            this.errors.push(
              `Facebook post ${
                index + 1
              }: Type must be one of post, reel, or story.`
            );
          }
          if (
            item.type === "post" &&
            (!item.content ||
              item.content.length < 2 ||
              item.content.length > 255)
          ) {
            this.errors.push(
              `Facebook post ${
                index + 1
              }: Message must be between 2 and 255 characters.`
            );
          }
          if (
            item.type === "post" &&
            (!item.url || item.url.length < 2 || item.url.length > 255)
          ) {
            this.errors.push(
              `Facebook post ${
                index + 1
              }: Link must be between 2 and 255 characters.`
            );
          }
          if (item.type === "reel" && !item.file) {
            this.errors.push(
              `Facebook reel ${index + 1}: File must be a valid MP4 file.`
            );
          }
          if (
            item.type === "reel" &&
            (!item.content ||
              item.content.length < 2 ||
              item.content.length > 255)
          ) {
            this.errors.push(
              `Facebook reel ${
                index + 1
              }: Description must be between 2 and 255 characters.`
            );
          }
          // if (item.type && !item.page_id) {
          //   this.errors.push(`Facebook Page ID is required.`);
          // }
        });
      }

      if (this.post.instagram) {
        this.post.instagram.forEach((item, index) => {
          if (!["post", "reel", "story"].includes(item.type)) {
            this.errors.push(
              `Instagram post ${
                index + 1
              }: Type must be one of post, reel, or story.`
            );
          }
          if (
            item.type === "post" &&
            (!item.content ||
              item.content.length < 2 ||
              item.content.length > 255)
          ) {
            this.errors.push(
              `Instagram post ${
                index + 1
              }: Caption must be between 2 and 255 characters.`
            );
          }
          if (
            item.type === "post" &&
            (!item.file || item.file.length < 2 || item.file.length > 255)
          ) {
            this.errors.push(
              `Instagram post ${
                index + 1
              }: Image URL must be between 2 and 255 characters.`
            );
          }
          if (item.type === "reel") {
            // this.errors.push(
            //   `Instagram reel ${index + 1}: Video URL must be a valid MP4 file.`
            // );
          }
          if (
            item.type === "reel" &&
            (!item.content ||
              item.content.length < 2 ||
              item.content.length > 255)
          ) {
            this.errors.push(
              `Instagram reel ${
                index + 1
              }: Caption must be between 2 and 255 characters.`
            );
          }
        });
      }
      if (this.errors.length > 0) {
        console.error("Validation Errors:", this.errors);
      } else {
        console.log("Validation passed!");
      }
    },
    getLink(platform, typeKey) {
      if (platform) {
        if (typeKey === "story_and_reel") {
          let links = [];
          let entryreel = this.post[platform].find(
            (item) => item.type === "reel"
          );
          if (entry) {
            entry.url
              ? links.push({ href: entry.url, name: entry.url_name })
              : null;
          }
          let entrystory = this.post[platform].find(
            (item) => item.type === "story"
          );
          if (entrystory) {
            entry.url
              ? links.push({ href: entry.url, name: entry.url_name })
              : null;
          }
          return links;
        } else {
          if (typeKey) {
            console.log(this.post);

            const entry = this.post[platform].find(
              (item) => item.type === typeKey
            );
            if (entry) {
              return entry.url
                ? [{ href: entry.url, name: entry.url_name }]
                : [];
            }
          } else {
            this.post[platform].forEach((entry) => {
              return entry.url
                ? [{ href: entry.url, name: entry.url_name }]
                : [];
            });
          }
        }
      } else if (typeKey) {
        let links = [];
        Object.keys(this.post).forEach((key) => {
          if (Array.isArray(this.post[key])) {
            this.post[key].forEach((entry) => {
              if (entry.type === typeKey) {
                entry.url
                  ? links.push({ href: entry.url, name: entry.url_name })
                  : null;
              }
            });
          }
        });
        return links;
      }
    },
    getPages() {
      apiClient.get("/all-platfroms-pages").then((response) => {
        this.pages = response.data.data;
      });
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        apiClient
          .post("/publish-posts/upload/file", {
            file: file,
          })
          .then((response) => {
            console.log(response.data);
            resolve(response.data);
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            reject(error);
          });
      });
    },

    formattedRequestToSend() {
      let request = {};
      Object.keys(this.boost).forEach((platform) => {
        if (this.boost[platform].state) {
          this.post[platform].forEach((post) => {
            post.boost = true;
            post.duration = this.boost[platform].duration || "";
            post.date = this.boost[platform].date || null;
            post.budget = this.boost[platform].budget || "";
          });
        }
      });
      return new Promise((resolve, reject) => {
        const processFiles = () => {
          const promises = [];
          for (const platform of Object.keys(this.post)) {
            const platformPages = this.pagesIds.filter(
              (page) => page.platform === platform
            );
            request[platform] = [];
            for (const item of this.post[platform]) {
              for (const page of platformPages) {
                const baseData = {
                  type: item.type,
                  page_id: page.id,
                  comment: this.comment,
                  boost: true,
                  duration: item.duration,
                  date: item.date,
                  budget: item.budget,
                };
                if (this.commentImages[0]) {
                  promises.push(
                    this.uploadFile(this.commentImages[0]).then(
                      (commentImageUrl) => {
                        baseData.comment_images = commentImageUrl;
                      }
                    )
                  );
                }
                let platformPromise;
                switch (platform) {
                  case "facebook": {
                    if (item.file?.file) {
                      platformPromise = this.uploadFile(item.file.file).then(
                        (fileUrl) => {
                          const platformData = {
                            ...baseData,
                            message:
                              item.type === "post"
                                ? item.content || null
                                : null,
                            link: item.url || null,
                            url: item.type === "post" ? fileUrl : null,
                            description:
                              item.type === "reel" || item.type === "story"
                                ? item.content || null
                                : null,
                            file: item.type !== "post" ? fileUrl : null,
                          };
                          request[platform].push(platformData);
                        }
                      );
                      promises.push(platformPromise);
                    } else {
                      const platformData = {
                        ...baseData,
                        message:
                          item.type === "post" ? item.content || null : null,
                        link: item.url || null,
                        url: null,
                        description:
                          item.type === "reel" || item.type === "story"
                            ? item.content || null
                            : null,
                        file: null,
                      };
                      request[platform].push(platformData);
                    }
                    break;
                  }
                  case "instagram": {
                    if (item.file?.file) {
                      platformPromise = this.uploadFile(item.file.file).then(
                        (fileUrl) => {
                          const platformData = {
                            ...baseData,
                            caption: item.content || null,
                            image_url: item.type === "post" ? fileUrl : null,
                            video_url: item.type !== "post" ? fileUrl : null,
                          };
                          request[platform].push(platformData);
                        }
                      );
                      promises.push(platformPromise);
                    } else {
                      const platformData = {
                        ...baseData,
                        caption: item.content || null,
                        image_url: null,
                        video_url: null,
                      };
                      request[platform].push(platformData);
                    }
                    break;
                  }
                  case "tiktok": {
                    if (item.file?.file) {
                      platformPromise = this.uploadFile(item.file.file).then(
                        (fileUrl) => {
                          const platformData = {
                            ...baseData,
                            link: item.url || null,
                            file: fileUrl,
                          };
                          request[platform].push(platformData);
                        }
                      );
                      promises.push(platformPromise);
                    } else {
                      const platformData = {
                        ...baseData,
                        link: item.url || null,
                        file: null,
                      };
                      request[platform].push(platformData);
                    }
                    break;
                  }
                  case "x": {
                    if (item.type === "post") {
                      if (item.file?.file) {
                        platformPromise = this.uploadFile(item.file.file).then(
                          (fileUrl) => {
                            const platformData = {
                              ...baseData,
                              link: item.url || null,
                              file: fileUrl,
                            };
                            request[platform].push(platformData);
                          }
                        );
                        promises.push(platformPromise);
                      } else {
                        const platformData = {
                          ...baseData,
                          link: item.url || null,
                          file: null,
                        };
                        request[platform].push(platformData);
                      }
                    }
                    break;
                  }
                  case "snapchat": {
                    if (item.type === "story") {
                      if (item.file?.file) {
                        platformPromise = this.uploadFile(item.file.file).then(
                          (fileUrl) => {
                            const platformData = {
                              ...baseData,
                              link: item.url || null,
                              file: fileUrl,
                            };
                            request[platform].push(platformData);
                          }
                        );
                        promises.push(platformPromise);
                      } else {
                        const platformData = {
                          ...baseData,
                          link: item.url || null,
                          file: null,
                        };
                        request[platform].push(platformData);
                      }
                    }
                    break;
                  }
                }
              }
            }
          }

          Promise.all(promises)
            .then(() => {
              console.log("Formatted request:", request);
              const formData = new FormData();

              // Build FormData from the request object with uploaded file URLs
              if (request && typeof request === "object") {
                for (const platform of Object.keys(request)) {
                  if (Array.isArray(request[platform])) {
                    for (const [index, item] of request[platform].entries()) {
                      if (item && typeof item === "object") {
                        for (const [key, value] of Object.entries(item)) {
                          formData.append(
                            `${platform}[${index}][${key}]`,
                            value ?? ""
                          );
                        }
                      }
                    }
                  }
                }
              } else {
                console.error("Invalid request object:", request);
              }

              // Log the final FormData entries
              for (const pair of formData.entries()) {
                console.log(`${pair[0]}:`, pair[1]);
              }

              resolve(request);
            })
            .catch((error) => {
              console.error("Error in formattedRequestToSend:", error);
              reject(error);
            });
        };

        processFiles();
      });
    },
    goNextRoute() {
      const router = useRouter();
      console.log(this.boost);
      router.push({ name: "BoostPost" });
    },
    createNewAudience() {
      try {
        const adAccountId =
          localStorage.getItem("meta_ad_account_id_boost_post") || "";
        const pageId = localStorage.getItem("pageIdBoostPost") || "";
        const objective =
          localStorage.getItem("selected_objective_boost_post") || "";
        const endTimeString =
          localStorage.getItem("meta_end_time_boost_post") || "";
        const endTime = endTimeString ? new Date(endTimeString) : null;

        let specialAdCategories = [];
        try {
          specialAdCategories = JSON.parse(
            localStorage.getItem("meta_special_ad_categories_boost_post") ||
              "['NONE']"
          );
        } catch (error) {
          console.error("Failed to parse special_ad_categories:", error);
        }

        let genders = [];
        try {
          genders = JSON.parse(
            localStorage.getItem("boost_post_selected_gender") || "[]"
          );
        } catch (error) {
          console.error("Failed to parse genders:", error);
        }

        const ageMax = Number(localStorage.getItem("max_range_age")) || null;
        const ageMin = Number(localStorage.getItem("min_range_age")) || null;
        const budget =
          Number(localStorage.getItem("max_range_life_time")) || null;

        // 🔥 Detect if it's a Special Ad Category
        const isSpecialCategory = specialAdCategories.some((cat) =>
          ["HOUSING", "EMPLOYMENT", "CREDIT"].includes(cat.toUpperCase())
        );

        const geoLocations = isSpecialCategory
          ? { countries: ["US"] }
          : {
              countries: ["US"],
              // regions: [{ key: "1001" }],
              // cities: [{ key: "658536" }],
            };

        const audienceData = {
          facebook: {
            ad_account_id: adAccountId,
            page_id: pageId,
            objective: objective,
            special_ad_categories: ["NONE"],
            start_time: new Date().toISOString(),
            end_time: endTime,
            lifetime_budget: budget,
            targeting: {
              geo_locations: geoLocations,
              genders: genders,
              age_max: ageMax,
              age_min: ageMin,
              publisher_platforms: ["facebook"],

              // flexible_spec: [
              //   {
              //     interests: [
              //       {
              //         id: "6002925969459",
              //         name: "watching movies",
              //       },
              //     ],
              //   },
              // ],
            },
          },
        };

        // 📢 Log for Debugging
        console.log("Special Ad Categories:", specialAdCategories);
        console.log("Geo Locations:", JSON.stringify(geoLocations, null, 2));

        return audienceData;
      } catch (error) {
        console.error("Error creating audience:", error);
        throw error;
      }
    },
  },
});
