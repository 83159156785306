<template>
  <div class="field d-flex flex-column ga-2">
    <label>Leads Generation time</label>
    <input readonly :value="item?.campaignTime" type="text" />
  </div>
  <div class="field d-flex flex-column ga-2">
    <label>Marketing Campaign</label>
    <input readonly :value="item?.marketingCampaign" type="text" />
  </div>
  <div class="field d-flex flex-column ga-2">
    <label>Marketing Source</label>
    <img
      width="24px"
      v-if="item?.platform === 'Snapchat'"
      :src="snapchatIcon"
      :alt="item?.platform"
    />
    <img
      width="24px"
      v-if="item?.platform == 'x'"
      :src="xIcon"
      :alt="item?.platform"
    />
    <img
      width="24px"
      v-if="item?.platform == 'Facebook'"
      :src="facebookIcon"
      :alt="item?.platform"
    />
    <img
      width="24px"
      v-if="item?.platform == 'Instagram'"
      :src="instagramIcon"
      :alt="item?.platform"
    />
    <img
      width="24px"
      v-if="item?.platform == 'Tiktok'"
      :src="tiktokIcon"
      :alt="item?.platform"
    />
  </div>
  <div class="field d-flex flex-column ga-2">
    <label>Last Name</label>
    <input readonly :value="item?.firstName" type="text" />
  </div>
  <div class="field d-flex flex-column ga-2">
    <label>Last Name</label>
    <input readonly :value="item?.lastName" type="text" />
  </div>
  <div class="field d-flex flex-column ga-2">
    <label>Phone Number</label>
    <input readonly :value="item?.phoneNumber" type="text" />
  </div>
  <div class="field d-flex flex-column ga-2">
    <label>Status</label>
    <input readonly :value="item?.status" type="text" />
  </div>
  <div class="field d-flex flex-column ga-2">
    <label>Reachability</label>
    <input readonly :value="item?.reachability" type="text" />
  </div>
  <div class="field d-flex flex-column ga-2">
    <label>Leads Progress</label>
    <div class="d-flex ga-2">
      <span :class="[SelectedReachedLeadTypeClass]">
        {{ item?.reachedStatus }}
      </span>
      <span v-if="item?.bookedInfo.consultationDate" class="selected-green">
        {{ item?.bookedInfo.consultationDate }}
      </span>
      <span
        v-if="item?.attendanceStatus"
        :class="
          item?.attendanceStatus === 'Attended'
            ? 'selected-green'
            : 'selected-red'
        "
      >
        {{ item?.attendanceStatus }}
      </span>
    </div>
  </div>
  <div class="field d-flex flex-column ga-2">
    <label>Assigned Agent</label>
    <div class="d-flex align-center ga-2">
      <span v-for="(assignee, index) in item?.assignee" :key="index">
        <img class="circle-img" :src="assignee.img" :alt="assignee.name" />
      </span>
    </div>
  </div>
</template>
<script>
import snapchatIcon from "@/assets/images/icons/snapcaht.svg";
import xIcon from "@/assets/images/icons/x.svg";
import instagramIcon from "@/assets/images/icons/instagram.svg";
import facebookIcon from "@/assets/images/icons/facebook.svg";
import tiktokIcon from "@/assets/images/icons/tiktok.svg";
import apiClient from "@/core/buildRequest";

export default {
  props: ["sitem"],
  name: "OverviewComp",
  data() {
    return {
      item: {
        id: null,
        campaignTime: null,
        marketingCampaign: null,
        platform: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        status: null,
        reachability: null,
        assignee: [],
        reachedStatus: null,
        bookedInfo: {
          doctorName: null,
          departmentName: null,
          service: null,
          consultationDate: null,
        },
        attendanceStatus: null,
        survey: [],
        activities: [],
        updates: [],
        files: [],
        trails: {
          "1st_trail": null,
          "2nd_trail": null,
          "3rd_trail": null,
        },
        haveTrails: false,
        created_at: null,
        updated_at: null,
      },
    };
  },
  mounted() {
    this.item = this.sitem; // Initialize with prop data
    this.getItemById(); // Fetch API data on mount
  },
  computed: {
    snapchatIcon() {
      return snapchatIcon;
    },
    xIcon() {
      return xIcon;
    },
    instagramIcon() {
      return instagramIcon;
    },
    facebookIcon() {
      return facebookIcon;
    },
    tiktokIcon() {
      return tiktokIcon;
    },
    SelectedReachedLeadTypeClass() {
      let className = "";
      const reachedStatus = this.item?.reachedStatus || "";

      if (
        [
          "Booked",
          "Cross/Up Selling",
          "Transfer / Tasheel",
          "Walk In",
        ].includes(reachedStatus)
      ) {
        className = "selected-green";
      } else if (
        ["Call Back", "Outside", "Transfer / Branch", "Visited"].includes(
          reachedStatus
        )
      ) {
        className = "selected-gray";
      } else if (
        ["N/I Price", "N/I Service", "Inquiry"].includes(reachedStatus)
      ) {
        className = "selected-orange";
      } else if (
        ["Wrong Reservation", "Language Barrier", "Wrong Customer"].includes(
          reachedStatus
        )
      ) {
        className = "selected-red";
      }

      return className;
    },
  },
  methods: {
    async getItemById() {
      try {
        const response = await apiClient.get(
          `/leads-managment/get-lead/${this.sitem.id}`
        );
        if (response && response.data && response.data.data) {
          this.item = this.formatLeadData(response.data.data);
        } else {
          console.error("Unexpected API response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching lead data:", error);
      }
    },
    formatLeadData(lead) {
      return {
        id: lead.id,
        campaignTime: lead.lead_genration_time,
        marketingCampaign: lead.campaign_name,
        platform: lead.platform,
        firstName: lead.first_name,
        lastName: lead.last_name,
        phoneNumber: lead.phone,
        status: lead.status,
        reachability: lead.reachablitiy,
        leadsProgressCol: "",
        comments: "",
        assignee: lead.assignee || [], // Ensure it defaults to an array
        touchedStatus: lead.status,
        reachedStatus: lead.progress_status,
        bookedInfo: {
          doctorName: lead.booking?.doctor_name || "",
          departmentName: lead.booking?.department || "",
          service: lead.booking?.service || "",
          consultationDate: lead.booking?.appointment_date || "",
        },
        attendanceStatus: lead.booking?.status || "",
        survey: lead.survey || [],
        activities: lead.activities || [],
        updates: lead.updates || [],
        files: lead.files || [],
        trails: lead.call
          ? {
              "1st_trail": lead.call["1st_try"] || "",
              "2nd_trail": lead.call["2st_try"] || "",
              "3rd_trail": lead.call["3st_try"] || "",
            }
          : {},
        haveTrails: !!lead.call,
        created_at: lead.created_at || "",
        updated_at: lead.updated_at || "",
      };
    },
  },
  watch: {
    item: {
      handler(newVal) {
        
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.field label {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color: #6a6a6a;
}
.field input {
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  color: #666666;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
}
</style>
