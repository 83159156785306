<template>
  <div class="build-campaign">
    <div class="page-header mb-8">
      <h2 class="page-header-title">Build Your Campaign</h2>
    </div>
    <v-dialog v-model="dialog" width="800" class="choose-opjective-type">
      <v-card max-width="750" title="Choose Objective">
        <div class="create-new-camp-nav">
          <p class="create-title"><span>Create New Campaign</span></p>
          <v-container class="">
            <p class="title-select mb-3">objective</p>
            <v-row class="w-100 mb-2">
              <v-col cols="6">
                <div
                  v-for="(object, i) in objectiveArr"
                  :key="i"
                  class="options-group radio-button"
                >
                  <input
                    name="radio-group"
                    :id="object.id"
                    class="radio-button__input"
                    :checked="object.checked"
                    type="radio"
                    @change="selectOption(i)"
                  />
                  <label
                    :for="object.id"
                    class="radio-button__label"
                    :class="{ active: object.checked }"
                  >
                    <span class="radio-button__custom"></span>
                    <span class="icon-select" v-html="object.svg"> </span>
                    {{ object.title }}
                  </label>
                </div>
              </v-col>
              <v-col cols="6" class="objective-descriptions">
                <div v-if="object1" class="objective-awareness-descriptions">
                  <div class="first-section mb-5">
                    <img
                      src="@/assets/snapchatImage/218e1a0c4b913ae4d17c6404d31ae915.svg"
                      alt=""
                    />
                    <p>Reach Snapchatters and create interest in your brand.</p>
                  </div>
                  <div class="secound-section mb-5">
                    <p class="mb-4">
                      <b>Outcomes </b>good for Awareness & Engagement objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'Reach Snapchatters who are more likely to remember your brand.'
                        "
                      >
                        Brand Awareness
                      </span>
                      <span
                        v-tooltip:top="
                          'Reach Snapchatters who are more likely to watch your videos.'
                        "
                      >
                        Video Views
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to engage with your ad, like your AR lenses.'
                        "
                      >
                        Ad Engagement
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to call or text your business'
                        "
                      >
                        Calls & Texts
                      </span>
                      <span
                        v-tooltip:top="
                          'Promote your physical locations to Snapchatters.'
                        "
                      >
                        Place Promotion
                      </span>
                    </div>
                  </div>
                  <div class="third-section mb-5">
                    <p class="mb-4">
                      <b>Ad Format </b> available for Awareness & Engagement
                      objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'A full-screen ad that shows Snapchatters a single image or video after or in between content like Stories.'
                        "
                      >
                        Single Image or Video
                      </span>
                      <span
                        v-tooltip:top="
                          'An ad that shows Snapchatters a branded tile in the Discover section that opens into a collection of 1 - 10 images or videos.'
                        "
                      >
                        Story Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'A single image or video ad with four tappable tiles to feature your products. Learn More.'
                        "
                      >
                        Collection Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'Augmented Reality (AR) Lenses and Filters are a unique way for Snapchatters to play and engage with the ads you create.'
                        "
                      >
                        AR Ads
                      </span>
                      <span
                        v-tooltip:top="
                          'A full-screen video ad that cannot be skipped for up to the first 6 seconds of play time that Snapchatters see in curated content like Shows.'
                        "
                      >
                        Commercial
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="object2" class="objective-awareness-descriptions">
                  <div class="first-section mb-5">
                    <img src="@/assets/snapchatImage/download (1).svg" alt="" />
                    <p>
                      Send Snapchatters to a destination like a website or app.
                    </p>
                  </div>
                  <div class="secound-section mb-5">
                    <p class="mb-4">
                      <b>Outcomes </b>good for Traffic objective
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to click a link to your website or app.'
                        "
                      >
                        Clicks to Website or App
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to view your fully loaded website or app.'
                        "
                      >
                        Landing Page Views
                      </span>
                    </div>
                  </div>
                  <div class="third-section mb-5">
                    <p class="mb-4">
                      <b>Ad Format </b> available for Traffic objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'A full-screen ad that shows Snapchatters a single image or video after or in between content like Stories.'
                        "
                      >
                        Single Image or Video
                      </span>
                      <span
                        v-tooltip:top="
                          'An ad that shows Snapchatters a branded tile in the Discover section that opens into a collection of 1 - 10 images or videos.'
                        "
                      >
                        Story Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'A single image or video ad with four tappable tiles to feature your products. Learn More.'
                        "
                      >
                        Collection Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'Augmented Reality (AR) Lenses and Filters are a unique way for Snapchatters to play and engage with the ads you create.'
                        "
                      >
                        AR Ads
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="object3" class="objective-awareness-descriptions">
                  <div class="first-section mb-5">
                    <img
                      src="@/assets/snapchatImage/1430d33585ba2ef694c3145c157e4dca.svg"
                      alt=""
                    />
                    <p>
                      Get Snapchatters to share their contact details with your
                      business.
                    </p>
                  </div>
                  <div class="secound-section mb-5">
                    <p class="mb-4">
                      <b>Outcomes </b>good for Leads objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to fill out a form to share their contact info with you.'
                        "
                      >
                        Leads
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to visit your website or app and share their contact info with you.'
                        "
                      >
                        Clicks to Lead Form or Website
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to take action on your website, like signing up.'
                        "
                      >
                        Website Sign Up
                      </span>
                    </div>
                  </div>
                  <div class="third-section mb-5">
                    <p class="mb-4">
                      <b>Ad Format </b> available for Leads objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'A full-screen ad that shows Snapchatters a single image or video after or in between content like Stories.'
                        "
                      >
                        Single Image or Video
                      </span>
                      <span
                        v-tooltip:top="
                          'An ad that shows Snapchatters a branded tile in the Discover section that opens into a collection of 1 - 10 images or videos.'
                        "
                      >
                        Story Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'A single image or video ad with four tappable tiles to feature your products. Learn More.'
                        "
                      >
                        Collection Ad
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="object4" class="objective-awareness-descriptions">
                  <div class="first-section mb-5">
                    <img src="@/assets/snapchatImage/download (2).svg" alt="" />
                    <p>
                      Get Snapchatters to install and take actions in your app.
                    </p>
                  </div>
                  <div class="secound-section mb-5">
                    <p class="mb-4">
                      <b>Outcomes </b>good for Promotion objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="'Get Snapchatters to install your app.'"
                      >
                        App Installs
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to install your app and take action, like making a purchase.'
                        "
                      >
                        App Installs + In-App Action
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters who use your app to take action, like making a purchase.'
                        "
                      >
                        App Re-Engagement
                      </span>
                    </div>
                  </div>
                  <div class="third-section mb-5">
                    <p class="mb-4">
                      <b>Ad Format </b> available for App Promotion objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'A full-screen ad that shows Snapchatters a single image or video after or in between content like Stories.'
                        "
                      >
                        Single Image or Video
                      </span>
                      <span
                        v-tooltip:top="
                          'An ad that shows Snapchatters a branded tile in the Discover section that opens into a collection of 1 - 10 images or videos.'
                        "
                      >
                        Story Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'A single image or video ad with four tappable tiles to feature your products. Learn More.'
                        "
                      >
                        Collection Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'Augmented Reality (AR) Lenses and Filters are a unique way for Snapchatters to play and engage with the ads you create.'
                        "
                      >
                        AR Ads
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="object5" class="objective-awareness-descriptions">
                  <div class="first-section mb-5">
                    <img src="@/assets/snapchatImage/download (3).svg" alt="" />
                    <p>Get Snapchatters to purchase your product or service.</p>
                  </div>
                  <div class="secound-section mb-5">
                    <p class="mb-4">
                      <b>Outcomes </b>good for Sales objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to buy products or services from your catalog.'
                        "
                      >
                        Catalog Sales
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters to take action on your website, like adding to cart or making a purchase.'
                        "
                      >
                        Website Conversions
                      </span>
                      <span
                        v-tooltip:top="
                          'Get Snapchatters who use your app to take action, like making a purchase.'
                        "
                      >
                        App Re-Engagement
                      </span>
                    </div>
                  </div>
                  <div class="third-section mb-5">
                    <p class="mb-4">
                      <b>Ad Format </b> available for Awareness & Engagement
                      objective.
                    </p>
                    <div class="tool-tips">
                      <span
                        v-tooltip:top="
                          'A full-screen ad that shows Snapchatters a single image or video after or in between content like Stories.'
                        "
                      >
                        Single Image or Video
                      </span>
                      <span
                        v-tooltip:top="
                          'An ad that shows Snapchatters a branded tile in the Discover section that opens into a collection of 1 - 10 images or videos.'
                        "
                      >
                        Story Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'A single image or video ad with four tappable tiles to feature your products. Learn More.'
                        "
                      >
                        Collection Ad
                      </span>
                      <span
                        v-tooltip:top="
                          'Augmented Reality (AR) Lenses and Filters are a unique way for Snapchatters to play and engage with the ads you create.'
                        "
                      >
                        AR Ads
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="dialog-footer">
              <v-col cols="12" class="d-flex justify-end">
                <v-btn
                  type="button"
                  @click="dialog = false"
                  color="#444546"
                  rounded
                  variant="outlined"
                  >Cancel</v-btn
                >
                <v-btn
                  type="button"
                  ref="toCreateCampaign"
                  class="toCreateCampaign"
                  rounded
                  variant="outlined"
                  @click="nextAction"
                  >Next</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <div class="campaign-creation-container">
      <h6 class="mb-5">Campaign Setup</h6>
      <v-card max-width="100%" class="pa-5 mb-5 rounded-lg" elevation="1">
        <div class="card-header">
          <p class="mb-2">Objective</p>
          <div class="d-flex align-center justify-space-between objective-type">
            <div
              class="d-flex align-center justify-start img-header"
              style="gap: 15px"
            >
              <img :src="newData.imgSrc" alt="" />
              <div class="objective-description">
                <p class="objective-title">{{ newData.title }}</p>
                <p class="objective-desc">
                  {{ newData.description }}
                </p>
              </div>
            </div>
            <div class="change-objective">
              <button
                type="button"
                @click="changeSelectedOptions"
                class="d-flex align-center pa-0 ma-0"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#0894fa"
                  >
                    <path
                      d="M184-184v-83.77l497.23-498.77q5.15-5.48 11.07-7.47 5.93-1.99 11.99-1.99 6.06 0 11.62 1.54 5.55 1.54 11.94 7.15l38.69 37.93q5.61 6.38 7.54 12 1.92 5.63 1.92 12.25 0 6.13-2.24 12.06-2.24 5.92-7.22 11.07L267.77-184H184Zm505.15-466.46L744-704.54 704.54-744l-54.08 54.85 38.69 38.69Z"
                    />
                  </svg>
                </span>
                <span> change </span>
              </button>
            </div>
          </div>
        </div>
        <div class="card-body mt-4">
          <div class="campaign-name">
            <label for="" class="d-block">Campaign Name</label>
            <input
              type="text"
              :class="
                !isValidate.campaign_name
                  ? 'required_input custom-input'
                  : 'custom-input'
              "
              v-model="campaign_name"
              placeholder="Campaign Name"
            />
            <p class="d-flex ga-2 mt-3" v-if="!isValidate.campaign_name">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="#444546"
                xmlns="http://www.w3.org/2000/svg"
                class="blue-warning-svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
                ></path>
              </svg>
              <span class="muted-text small-text"
                >Campaign Name is required</span
              >
            </p>
          </div>

          <div
            v-if="appPromotionGoal"
            class="app-promotion-goal mt-5 mb-3 create-new-camp-nav"
          >
            <label for="" class="d-block mb-2 section-title"
              >App Promotion Goal
            </label>
            <div class="options-group radio-button mb-2">
              <input
                name="app-promotion-goal"
                id="app-promotion-goal-1"
                class="radio-button__input"
                checked
                type="radio"
              />
              <label for="app-promotion-goal-1" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <p class="">
                  <span class="d-block title-span">App Install</span>
                  <span class="d-block subtitle-span"
                    >Get Snapchatters to install your app and then engage with
                    it.</span
                  >
                </p>
              </label>
            </div>
            <div class="options-group radio-button">
              <input
                name="app-promotion-goal"
                id="app-promotion-goal-2"
                class="radio-button__input"
                type="radio"
              />
              <label for="app-promotion-goal-2" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <p class="">
                  <span class="d-block title-span">App Re-engagement</span>
                  <span class="d-block subtitle-span"
                    >Get Snapchatters who use your app to take action.</span
                  >
                </p>
              </label>
            </div>
          </div>

          <div class="status mt-5">
            <label for="" class="d-block status-title">Status</label>
            <switch-toogle
              :checked="campaign_status"
              @tooglePaused="tooglecompaginPaused"
              paused="false"
            />
          </div>
          <div v-if="dynamicAds" class="status dynamic-ads mt-5">
            <label for="" class="d-block status-title">Dynamic Ads </label>
            <switch-toogle :checked="dynamicAdsChecked" disabled="true">
            </switch-toogle>
            <p>
              Dynamic Ads requires a catalog. Go to
              <a href="https://ads.snapchat.com/notfound">Catalogs</a> to get
              started.
            </p>
          </div>
          <div class="create-split-test mt-5">
            <label for="" class="d-block status-title"
              >Create Split Test
            </label>
            <switch-toogle
              :checked="splitTest"
              @tooglePromotionOptions="showSplitTest"
            ></switch-toogle>
          </div>
          <div
            v-if="splitTest"
            class="test-variab mt-7 create-new-camp-nav app-promotion-goal custome-radio-btn"
          >
            <label for="" class="d-block section-title mb-2"
              >Test Variable</label
            >
            <div class="options-group radio-button">
              <input
                name="testVariable"
                id="Creative"
                class="radio-button__input"
                type="radio"
                v-model="test_variable"
                value="Creative"
              />
              <label for="Creative" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <p class="">
                  <span class="d-block title-span">Creative</span>
                  <span class="d-block subtitle-span"
                    >Test creative strategies.</span
                  >
                </p>
              </label>
            </div>
            <div class="options-group radio-button">
              <input
                name="testVariable"
                v-model="test_variable"
                value="Audience"
                id="Audience"
                class="radio-button__input"
                type="radio"
              />
              <label for="Audience" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <p class="">
                  <span class="d-block title-span">Audience</span>
                  <span class="d-block subtitle-span"
                    >Test audience targeting strategies.</span
                  >
                </p>
              </label>
            </div>
            <div class="options-group radio-button">
              <input
                name="testVariable"
                v-model="test_variable"
                value="Placement"
                id="Placement"
                class="radio-button__input"
                type="radio"
              />
              <label for="Placement" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <p class="">
                  <span class="d-block title-span">Placement</span>
                  <span class="d-block subtitle-span"
                    >Test placement targeting strategies.</span
                  >
                </p>
              </label>
            </div>
            <div
              v-if="selectedObjective != 'Traffic'"
              class="options-group radio-button"
            >
              <input
                name="testVariable"
                v-model="test_variable"
                value="Goal"
                id="Goal"
                class="radio-button__input"
                type="radio"
              />
              <label for="Goal" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <p class="">
                  <span class="d-block title-span">Goal</span>
                  <span class="d-block subtitle-span"
                    >Test goal strategies.</span
                  >
                </p>
              </label>
            </div>
          </div>
          <div class="start-end-object mt-5" v-if="!splitTest">
            <label for="" class="d-block start-end-title">
              Starts & Ends
            </label>
            <div class="selected-start-time">
              <input
                type="date"
                v-model="selectedStartDate"
                :min="minDate"
                :max="maxDate"
                @change="handleDateChange"
              />
              <input type="time" v-model="selectedStartTime" />
            </div>
            <v-checkbox-btn
              v-model="set_an_campaign_end_date"
              class="pt-3 mb-3 rounded-xl"
              label="Set an End Date"
            ></v-checkbox-btn>
            <div
              v-if="set_an_campaign_end_date"
              class="selected-start-time mb-12 selected-end-time"
            >
              <input
                type="date"
                v-model="selectedEndtDate"
                :min="minDate"
                :max="maxDate"
                @change="handleDateChange"
              />
              <input type="time" v-model="selectedEndTime" />
            </div>
          </div>
          <div class="more-options-parent">
            <button
              type="button"
              class="more-options mb-2"
              @click="moreOptionFun"
            >
              <p v-if="!moreOptionsBtn">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#0894fa"
                    width="13"
                    height="13"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                    />
                  </svg>
                </span>
                <span> More </span>
              </p>
              <p v-else>
                <span>
                  <svg
                    fill="#0894fa"
                    width="13"
                    height="13"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    />
                  </svg>
                </span>
                <span> Less </span>
              </p>
            </button>
            <div
              class="more-options-contenant d-none px-1 mt-4"
              ref="moreOptionsContenant"
            >
              <div class="d-flex flex-column ga-2">
                <label for=""> Daily Spend Cap </label>
                <CurrencyInput
                  v-model="daily_spend_cap"
                  :options="{ currency: 'USD' }"
                />
              </div>
              <div class="d-flex flex-column ga-2">
                <label for="">Lifetime Spend Cap</label>
                <CurrencyInput
                  v-model="lifetime_spend_cap"
                  :options="{ currency: 'USD' }"
                />
              </div>
              <!-- <input-label
                class="mb-6"
                labeltext="Daily Spend Cap"
                type="number"
                placeHolder="No Cap"
                v-model:campaignName="daily_spend_cap"
              ></input-label>
              <input-label
                labeltext="Lifetime Spend Cap"
                type="number"
                v-model:campaignName="lifetime_spend_cap"
                placeHolder="No Cap"
              ></input-label> -->
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <!-- <PromotePlaces v-if="awareness_selected" /> -->
  </div>
</template>

<script>
import CurrencyInput from "@/components/snapchat/Label&input/CurrencyInput.vue";
import InputLabel from "./Label&input/InputLabel.vue";
import SwitchToogle from "./Label&input/SwitchToogle.vue";
import { useDataStore } from "@/store/snapchat/snap-chat-static-data.js";
import { mapState } from "pinia";
// import PromotePlaces from "@/components/snapchat/PromotePlaces.vue";

export default {
  components: { InputLabel, SwitchToogle, CurrencyInput },
  name: "BuildCampaign",
  props: {},
  data() {
    const today = new Date();
    return {
      campaign_name: localStorage.getItem("campaign_name") || "",
      campaign_status: true,
      modelChecked: false,
      statusChecked: true,
      set_an_campaign_end_date: true,
      selectedStartDate: localStorage.getItem("campaign_start_date"),
      selectedStartTime: localStorage.getItem("campaign_start_time") || "",
      selectedEndtDate: localStorage.getItem("campaign_end_date") || "",
      selectedEndTime: localStorage.getItem("campaign_end_time") || "",
      minDate: today.toISOString().split("T")[0],
      maxDate: new Date(
        today.getFullYear() + 1,
        today.getMonth(),
        today.getDate()
      )
        .toISOString()
        .split("T")[0],
      moreOptions: true,
      moreOptionsBtn: false,
      promoteSinglePlace: "",
      toggleChecked: false,
      dynamicAdsChecked: false,
      splitTest: false,
      test_variable: localStorage.getItem("test_variable"),
      startEndObject: true,
      appPromotionGoal: false,
      dialog: false,
      dataObject: [],
      object1: true,
      object2: false,
      object3: false,
      object4: false,
      object5: false,
      newData: {},
      dynamicAds: false,
      daily_spend_cap: localStorage.getItem("daily_spend_cap") || "",
      lifetime_spend_cap: localStorage.getItem("lifetime_spend_cap") || "",
      isValidate: {
        campaign_name: false,
        test_creative: false,
      },
      selectedObjective: localStorage.getItem("selectedOption"),
      awareness_selected:
        localStorage.getItem("selectedOption") === "Awareness & Engagement"
          ? true
          : false,
    };
  },
  computed: {
    ...mapState(useDataStore, ["objectiveArr", "selectedOptions"]),
  },

  methods: {
    getData() {
      //filter on store where store.title == localsoreage.selectednae
      const store = useDataStore();
      if (localStorage.getItem("selectedOption")) {
        store.objectiveArr.map((e) => {
          if (e.title == localStorage.getItem("selectedOption")) {
            this.newData = e;
          }
        });
      }
    },
    selectOption(selectedIndex) {
      if (selectedIndex === null || selectedIndex === false) {
        this.dataObject.forEach((object) => (object.checked = false));
        this.object1 =
          this.object2 =
          this.object3 =
          this.object4 =
          this.object5 =
            false;
      } else {
        this.dataObject = this.dataObject.map((object, index) => ({
          ...object,
          checked: index === selectedIndex,
        }));
        this.object1 = selectedIndex === 0;
        this.object2 = selectedIndex === 1;
        this.object3 = selectedIndex === 2;
        this.object4 = selectedIndex === 3;
        this.object5 = selectedIndex === 4;
        this.selectedIndex = selectedIndex;
      }
    },
    nextAction() {
      const store = useDataStore();
      switch (this.selectedIndex) {
        case 0:
          localStorage.setItem("selectedOption", "Awareness & Engagement");
          localStorage.setItem("appSelectedFromParent", false);
          localStorage.setItem("appPromotionGoal", false);
          localStorage.setItem("dynamicAds", false);
          this.$router.push({ name: "SnapFirstStep" });
          this.appPromotionGoal = false;
          store.objectiveArr.map((e, i) => {
            if (i === 0) {
              e.checked = true;
            } else {
              e.checked = false;
            }
          });
          this.awareness_selected = true;
          break;
        case 1:
          localStorage.setItem("selectedOption", "Traffic");
          this.$router.push({ name: "TrafficFirstStep" });
          localStorage.setItem("appSelectedFromParent", true);
          localStorage.setItem("appPromotionGoal", false);
          localStorage.setItem("dynamicAds", false);
          this.appSelected = true;
          this.appPromotionGoal = false;
          store.objectiveArr.map((e, i) => {
            if (i === 1) {
              e.checked = true;
            } else {
              e.checked = false;
            }
          });
          this.awareness_selected = false;
          break;
        case 2:
          localStorage.setItem("selectedOption", "Leads");
          this.appPromotionGoal = false;
          this.$router.push({ name: "LeadsFirstStep" });
          localStorage.setItem("appSelectedFromParent", true);
          localStorage.setItem("appPromotionGoal", false);
          localStorage.setItem("dynamicAds", false);
          store.objectiveArr.map((e, i) => {
            if (i === 2) {
              e.checked = true;
            } else {
              e.checked = false;
            }
          });
          this.awareness_selected = false;
          break;
        case 3:
          localStorage.setItem("selectedOption", "App Promotion");
          this.$router.push({ name: "appPromotionFirstStep" });
          localStorage.setItem("appPromotionGoal", true);
          localStorage.setItem("appSelectedFromParent", false);
          localStorage.setItem("dynamicAds", true);
          store.objectiveArr.map((e, i) => {
            if (i === 3) {
              e.checked = true;
            } else {
              e.checked = false;
            }
          });
          this.awareness_selected = false;
          break;
        case 4:
          localStorage.setItem("selectedOption", "Sales");
          this.$router.push({ name: "salesFirstStep" });
          localStorage.setItem("appPromotionGoal", false);
          localStorage.setItem("appSelectedFromParent", false);
          localStorage.setItem("dynamicAds", true);
          store.objectiveArr.map((e, i) => {
            if (i === 4) {
              e.checked = true;
            } else {
              e.checked = false;
            }
          });
          this.awareness_selected = false;
          break;
        default:
          break;
      }
      this.$emit("change:type", localStorage.getItem("selectedOption"));
      this.getData();
      // }
    },
    changeSelectedOptions() {
      this.dialog = true;
    },

    moreOptionFun() {
      this.moreOptionsBtn = !this.moreOptionsBtn;
      if (
        this.$refs.moreOptionsContenant.classList.contains("hide-more-option")
      ) {
        this.$refs.moreOptionsContenant.classList.add("d-none");
      } else if (
        !this.$refs.moreOptionsContenant.classList.contains("hide-more-option")
      ) {
        this.$refs.moreOptionsContenant.classList.toggle("d-none");
      }
    },
    showSplitTest() {
      this.splitTest = !this.splitTest;
      this.startEndObject = !this.startEndObject;
      if (
        !this.$refs.moreOptionsContenant.classList.contains("hide-more-option")
      ) {
        this.$refs.moreOptionsContenant.classList.add("hide-more-option");
      } else {
        this.$refs.moreOptionsContenant.classList.remove("hide-more-option");
        this.$refs.moreOptionsContenant.classList.add("d-none");
      }
      localStorage.setItem("split_test", this.splitTest);
      localStorage.setItem("start_end_object", this.startEndObject);
      this.test_variable = "Creative";
    },
    tooglecompaginPaused() {
      this.campaign_status = !this.campaign_status;
      this.campaign_status
        ? (this.moreOptions = true)
        : (this.moreOptions = false);
      localStorage.setItem("campaign_status", this.campaign_status);
    },
    checkValidation() {
      if (this.campaign_name == "") {
        this.isValidate.campaign_name = false;
      } else {
        this.isValidate.campaign_name = true;
      }
      if (this.test_creative == "" && this.split_test) {
        this.isValidate.test_creative = false;
      } else {
        this.isValidate.test_creative = true;
      }
    },
    sendValidation() {
      this.checkValidation();
      return Object.values(this.isValidate).every((value) => value);
    },
  },
  watch: {
    selectedIndex(newVal) {
      this.selectOption(newVal);
    },
    campaign_name(newVal) {
      localStorage.setItem("campaign_name", newVal);
      this.checkValidation();
    },
    campaignStatus(newVal) {
      localStorage.setItem("campaign_status", newVal);
    },
    test_variable(newVal) {
      localStorage.setItem("test_variable", newVal);
    },
    set_an_campaign_end_date(newVal) {
      localStorage.setItem("set_an_campaign_end_date", newVal);
    },
    selectedStartDate(newVal) {
      localStorage.setItem("campaign_start_date", newVal);
      const datetime = `${newVal} ${localStorage.getItem(
        "campaign_start_time"
      )}`;
      localStorage.setItem("campaign_start_date_time", datetime);
    },
    selectedStartTime(newVal) {
      localStorage.setItem("campaign_start_time", newVal);
      const datetime = `${localStorage.getItem(
        "campaign_start_date"
      )} ${newVal}`;
      localStorage.setItem("campaign_start_date_time", datetime);
    },
    selectedEndtDate(newVal) {
      localStorage.setItem("campaign_end_date", newVal);
      const datetime = `${newVal} ${localStorage.getItem("campaign_end_time")}`;
      localStorage.setItem("campaign_end_date_time", datetime);
    },
    selectedEndTime(newVal) {
      localStorage.setItem("campaign_end_time", newVal);
      const datetime = `${localStorage.getItem("campaign_end_date")} ${newVal}`;
      localStorage.setItem("campaign_end_date_time", datetime);
    },
    daily_spend_cap(newVal) {
      localStorage.setItem("daily_spend_cap", newVal);
    },
    lifetime_spend_cap(newVal) {
      localStorage.setItem("lifetime_spend_cap", newVal);
    },
  },
  mounted() {
    this.checkValidation();
    this.dataObject = this.objectiveArr;
    this.getData();
    this.dynamicAds = localStorage.getItem("dynamicAds") === "true";
    this.appPromotionGoal = localStorage.getItem("appPromotionGoal") === "true";
    this.campaign_status = localStorage.getItem("campaign_status") === "true";
    this.set_an_campaign_end_date =
      localStorage.getItem("set_an_campaign_end_date") === "true";
    this.appPromotionGoal = localStorage.getItem("appPromotionGoal") === "true";
    this.splitTest = localStorage.getItem("split_test") === "true";
    this.startEndObject = localStorage.getItem("start_end_object") === "true";
    if (!localStorage.getItem("campaign_start_date")) {
      this.selectedStartDate = new Date().toISOString().split("T")[0];
    }
    if (!localStorage.getItem("campaign_start_time")) {
      this.selectedStartTime = new Date().toTimeString().split(" ")[0];
      localStorage.setItem("campaign_start_time", this.selectedStartTime);
    }
  },
};
</script>
