<template>
  <div class="payment-form d-flex flex-column ga-3 w-50 mt-5 billing-select">
    <label class="profile-box-title"> {{ $t('payment_method_details') }}  </label>
    <label for="payment-source">{{ $t('payment_source') }}</label>
    <v-autocomplete
      id="payment-source"
      v-model="paymentMethodId"
      :items="paymentSources"
      item-value="id"
      item-title="name"
      hide-details
      @change="handlePaymentMethodChange"
    >
      <template v-slot:item="{ item }">
        <div
          @click="paymentMethodId = item.raw.id"
          class="d-flex align-center pa-3 ga-3 cursor-pointer"
        >
          <input
            type="radio"
            :value="item.raw.id"
            :checked="paymentMethodId === item.raw.id"
          />
          <img
            v-if="getCardImage(item.raw.type)"
            :src="getCardImage(item.raw.type)"
            :alt="item.raw.type"
            class="payment-icon"
          />
          <span>{{ item.raw.name }}</span>
        </div>
      </template>
    </v-autocomplete>
    <label for="">  {{ $t('payment_method_nickname') }} </label>
    <Input
      v-model="payemnt_method_nickname"
      type="text"
      :placeholder="$t('payment_method_nickname')"
    />
    <label for=""> {{ $t('amount_prepaid') }} </label>
    <div class="d-flex">
      <Input
        v-model="amount"
        type="text"
        :placeholder="$t('amount_prepaid')"
        class="w-100"
        style="border-radius: 8px 0px 0px 8px"
      />
      <span class="curreny-wallet d-flex align-center justify-center">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_5334_123822)">
            <mask
              id="mask0_5334_123822"
              style="mask-type: luminance"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <path
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_5334_123822)">
              <path d="M0 0H16V16H0V0Z" fill="#496E2D" />
              <path
                d="M4.52187 9.56406C4.52187 10.1422 4.99063 10.6109 5.56563 10.6109H8.69687C8.69687 10.8413 8.7884 11.0623 8.95133 11.2252C9.11425 11.3882 9.33522 11.4797 9.56563 11.4797H10.6094C10.8398 11.4797 11.0608 11.3882 11.2237 11.2252C11.3866 11.0623 11.4781 10.8413 11.4781 10.6109V9.56406H4.52187ZM11.5656 4.52344V6.96094C11.5656 7.34219 11.2531 7.65469 10.8687 7.65469V8.69844C11.8281 8.69844 12.6094 7.91719 12.6094 6.95781V4.52344H11.5625H11.5656ZM4.0875 6.96094C4.0875 7.34219 3.775 7.65469 3.39062 7.65469V8.69844C4.35 8.69844 5.13125 7.91719 5.13125 6.95781V4.52344H4.0875V6.96094Z"
                fill="#EEEEEE"
              />
              <path
                d="M9.99922 4.52344H11.043V6.96094H9.99922V4.52344ZM8.43672 5.91406C8.43672 5.96048 8.41828 6.00499 8.38546 6.03781C8.35264 6.07063 8.30813 6.08906 8.26172 6.08906C8.21531 6.08906 8.17079 6.07063 8.13798 6.03781C8.10516 6.00499 8.08672 5.96048 8.08672 5.91406V4.52344H7.04297V5.91406C7.03732 5.95606 7.01663 5.99457 6.98473 6.02246C6.95283 6.05036 6.9119 6.06573 6.86953 6.06573C6.82716 6.06573 6.78623 6.05036 6.75433 6.02246C6.72244 5.99457 6.70174 5.95606 6.69609 5.91406V4.52344H5.65234V5.91406C5.65234 6.2373 5.78075 6.54729 6.00931 6.77585C6.23787 7.00441 6.54786 7.13281 6.87109 7.13281C7.11931 7.13282 7.36152 7.05645 7.56484 6.91406C7.76817 7.05645 8.01037 7.13282 8.25859 7.13281C8.31172 7.13281 8.36484 7.12969 8.41484 7.12344C8.37784 7.27466 8.29127 7.40914 8.16893 7.50543C8.04659 7.60171 7.89553 7.65426 7.73984 7.65469V8.69844C8.69609 8.69844 9.47734 7.91719 9.47734 6.95781V4.52344H8.43672V5.91406Z"
                fill="#EEEEEE"
              />
              <path
                d="M5.65234 7.65625H7.21484V8.7H5.65234V7.65625Z"
                fill="#EEEEEE"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_5334_123822">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>SAR</span>
      </span>
    </div>
    <label class="mt-4" for="">Add Amount Prepaid on a recurring basis?</label>

    <v-radio-group v-model="recurring_basis" color="primary">
      <v-radio
        :label="$t('yes_add_to_my_balance_now_and_on_a_recurring_basis')"
        value="yes"
      ></v-radio>
      <div
        class="d-flex align-center ga-3 pa-3"
        v-if="recurring_basis === 'yes'"
      >
        <v-autocomplete
          id="payment-source"
          v-model="recurring_type"
          :items="[
            'daily',
            'weekly',
            'monthly',
            'When My Balance Falls Below a Minimum Balance',
          ]"
          key=""
          item-value="id"
          item-title="name"
          hide-details
          width="50%"
        >
        </v-autocomplete>
        <Input v-model="recurring_date" type="date" class="w-50" />
      </div>
      <v-radio
        label="No, just add to my balance once for now"
        value="no"
      ></v-radio>
    </v-radio-group>
    <div class="d-flex mt-5 justify-end ga-3">
      <button class="cancel-btn cursor-pointer" @click="handleCancel">
        Cancel
      </button>
      <button
        class="choose-btn cursor-pointer"
        @click="handleCreatePaymentMethod"
      >
        {{$t('create_payment_method')}}
      </button>
    </div>
  </div>
</template>

<script>
import Input from "@/components/crm/assets/Input.vue";
import Visa from "@/assets/images/icons/visa.svg";
import Mastercard from "@/assets/images/icons/mastercard.svg";

export default {
  name: "WalletForm",

  components: {
    Input,
  },

  data() {
    return {
      paymentMethodId: "",
      paymentSources: [
        { id: 1, name: "Bank Transfer", type: "bank" },
        { id: 2, name: "Visa Ending with 4912", type: "visa" },
        { id: 3, name: "Mastercard ending in 2313", type: "mastercard" },
      ],
      cardImages: {
        visa: Visa,
        mastercard: Mastercard,
      },
      payemnt_method_nickname: "",
      amount: "",
      recurring_basis: "",
      recurring_type: "",
      recurring_date: "",
    };
  },

  methods: {
    getCardImage(type) {
      return this.cardImages[type] || "";
    },

    handlePaymentMethodChange() {
      this.$emit("payment-method-change", this.paymentMethodId);
    },

    handleCancel() {
      this.$emit("cancel");
    },

    handleCreatePaymentMethod() {
      this.$emit("create-payment-method");
    },
  },
};
</script>
