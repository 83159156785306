<template>
  <input ref="inputRef" type="text" />
</template>
<script>
import { useCurrencyInput } from "vue-currency-input";
export default {
  name: "CurrencyInput",
  props: {
    modelValue: Number,
    options: Object,
  },
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);
    return { inputRef };
  },
};
</script>
<style scoped>
input {
  background: #f9f9fa;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0 0 0 0 transparent;
  box-sizing: border-box;
  font-family: inherit;
  height: 100%;
  padding: 9px 12px;
  transition: box-shadow 0.15s ease-out 0.15s, border-color 0.15s,
    background 0.15s ease-out 0.15s, color 0.15s ease-out;
  width: 100%;
  border: transparent 2px solid;
  height: 38px !important;
  margin: 0px;
  height: 0px;
}
input:focus {
  border: transparent 2px solid !important;
  border-bottom: 2px solid #f7d901 !important;
  outline: none !important;
}
input:hover {
  border: 2px solid #f7d901;
}
</style>