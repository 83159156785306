<template>
  <div class="d-flex flex-column ga-3 snapchat">
    <div class="filed mt-3">
      <span class="title">Form Name</span>
      <div class="input-container">
        <input type="text" :value="data?.lead_generation_form?.name" placeholder="Form Name" />
      </div>
      <div class="warning">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="#444546"
          xmlns="http://www.w3.org/2000/svg"
          class="warning-svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
          ></path>
        </svg>
        <p class="small-text muted-text">Name is required.</p>
      </div>
    </div>
    <div class="filed mt-3">
      <span class="title">Lead Form Description</span>
      <div class="input-container">
        <textarea
          class="w-100"
          type="text"
          :value="data?.lead_generation_form?.description"
          placeholder="Lead Form Description"
          rows="5"
        ></textarea>
      </div>
    </div>
    <!-- <UploadMedia
      :old_id="data?.lead_generation_form.description"
    /> -->
  </div>
</template>
<script>
import UploadMedia from "../uploadMedia.vue";
import { useLeadFormStore } from "@/store/snapchat/LeadForm";
export default {
  name: "SettingsForm",
  props: {
    data: Object,
  },
  components: {
    UploadMedia,
  },
  data() {
    return {
      store: useLeadFormStore(),
    };
  },
};
</script>
