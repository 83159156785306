<template>
  <!-- Audiences  -->
  <div class="audiences ad-set-details-card mb-6 fs-14">
    <h6 class="page-header-title mb-3">audiences</h6>
    <v-card
      max-width="100%"
      class="pa-5 mb-5 rounded-lg"
      style="overflow: visible"
      elevation="1"
    >
      <div class="card-body">
        <span class="inputs-title">Predefined Audiences </span>

        <div class="custom-audiences">
          <div class="">
            <div class="mt-3">
              <div
                v-if="selected_predefined_audiances?.length > 0"
                class="selected-custom-audiences mb-3"
              >
                <p
                  class="d-flex align-center justify-space-between"
                  v-for="(
                    selected_custom_audience, index
                  ) in selected_predefined_audiances"
                  :key="index"
                >
                  <span class="country d-flex align-center ga-2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#444546"
                      xmlns="http://www.w3.org/2000/svg"
                      class="green-svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                      ></path>
                    </svg>
                    {{ selected_custom_audience.label }}
                  </span>
                  <span
                    @click="removeSelectedCustomAudience(index)"
                    class="cross"
                  >
                    <svg
                      style="cursor: pointer"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#33bbff"
                      xmlns="http://www.w3.org/2000/svg"
                      class="sds-icon sds-icon-cross"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                      ></path>
                    </svg>
                  </span>
                </p>
              </div>
              <treeselect
                v-model="selected_predefined_audiances"
                :multiple="true"
                :options="predefined_audiances"
                :value-format="'object'"
                searchable
              >
              </treeselect>
            </div>
          </div>
        </div>
        <div class="mb-5">
          <span
            class="mt-5 d-block"
            style="font-size: 12px; color: #000; font-weight: 500"
            >Custom Audiences</span
          >
          <div class="">
            <span
              v-for="aud in selected_custom_audience_list"
              :key="aud.id"
              class="d-flex align-center justify-space-between region-option"
            >
              <span class="d-flex align-center ga-2">
                <svg
                  v-if="aud.type === 'include'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#39CA8E"
                  xmlns="http://www.w3.org/2000/svg"
                  class="sds-icon sds-icon-check styles-im1iax"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                  ></path>
                </svg>
                <svg
                  v-if="aud.type === 'exclude'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#DC2E4B"
                  xmlns="http://www.w3.org/2000/svg"
                  class="sds-icon sds-icon-cancelled styles-im1iax"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.5 8C12.5 10.4853 10.4853 12.5 8 12.5C7.04273 12.5 6.15527 12.2011 5.42586 11.6915L11.6453 5.36098C12.183 6.10236 12.5 7.01416 12.5 8ZM4.3557 10.6405L10.5756 4.30949C9.84591 3.7993 8.95791 3.5 8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 8.98648 3.81742 9.89882 4.3557 10.6405ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                  ></path>
                </svg>
                <span>{{ aud.label }}</span>
              </span>
              <span class="d-flex align-center ga-2 actions">
                <v-btn
                  class="inc-exc-btn"
                  variant="text"
                  v-if="aud.type === 'exclude'"
                  @click="aud.type = 'include'"
                >
                  include
                </v-btn>
                <v-btn
                  class="inc-exc-btn"
                  variant="text"
                  v-if="aud.type === 'include'"
                  @click="aud.type = 'exclude'"
                >
                  exclude
                </v-btn>
                <svg
                  @click="removeAudiance(aud)"
                  class="cursor-pointer"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#33bbff"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                  ></path>
                </svg>
              </span>
            </span>
          </div>
          <div v-if="custom_audiences_list.length > 0" class="">
            <treeselect
              v-model="selected_custom_audience"
              :options="custom_audiences_list"
              :multiple="true"
              :disableBranchNodes="true"
              :appendToBody="false"
              :value-format="'object'"
              searchable
            >
              <!-- Custom After-List Content -->
              <template #after-list>
                <v-divider></v-divider>
                <button
                  class="add_audiences_btn px-3 ma-2"
                  @click="audiance_dialog = true"
                >
                  <span>Create a new audience</span>
                </button>
              </template>
            </treeselect>
          </div>
          <div v-else class="">
            <span>No Audience Available</span>
            <span>
              <button class="add_audiences_btn" @click="audiance_dialog = true">
                <span>Add Audience</span>
              </button>
            </span>
          </div>
        </div>
        <div class="advanced-settings mt-3">
          <div class="">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center justify-start ga-2">
                <label class="snap-checkbox">
                  <input v-model="advanced_audiance_settings" type="checkbox" />
                  <span class="snap-checkmark"></span>
                  <span> Expand audience automatically </span>

                  <span class="text-muted small-text"
                    >Snap expands the audience to optimize performance.</span
                  >
                </label>
              </div>
              <button
                class="add_audiences_btn"
                @click="
                  advanced_audiance_settings_toggle =
                    !advanced_audiance_settings_toggle
                "
              >
                <span v-if="advanced_audiance_settings_toggle"
                  >Hide Settings</span
                >
                <span v-else>Advanced Settings</span>
              </button>
            </div>

            <div
              v-if="advanced_audiance_settings_toggle"
              class="between-options ml-7"
            >
              <div class="d-flex align-center justify-start ga-2">
                <label class="snap-checkbox">
                  <input
                    v-model="expand_predefined_audiences"
                    type="checkbox"
                  />
                  <span class="snap-checkmark"></span>Expand Predefined
                  Audiences
                </label>
              </div>
              <div class="d-flex align-center justify-start ga-2">
                <label class="snap-checkbox">
                  <input v-model="expand_custom_audiences" type="checkbox" />
                  <span class="snap-checkmark"></span>Expand Custom Audiences
                  Stories
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="audiance_dialog" max-width="50%" persistent>
      <v-card class="pa-5 radius-10">
        <div class="">
          <span>Select a type of audience to create</span>
        </div>
        <div style="display: flex; flex-wrap: wrap; padding: 0; gap: 10px">
          <button
            @click="openCreateCustomerListAudianceDialog"
            class="d-flex align-start justify-start audiance-option-card"
            type="button"
          >
            <img
              class="image"
              src="@/assets/snapchatImage/customer-list.svg"
              alt=""
            />
            <div class="data d-flex flex-column">
              <span class="title">Customer List</span>
              <span class="desc"
                >Match your customer list with snapchatters.</span
              >
              <span class="footer">Source: .csv, .txt</span>
            </div>
          </button>
          <button
            @click="openCreateWebsiteEventAudienceDialog"
            class="d-flex align-start justify-start audiance-option-card"
            type="button"
          >
            <img
              class="image"
              src="@/assets/snapchatImage/website-events.svg"
              alt=""
            />
            <div class="data d-flex flex-column">
              <span class="title">Website Events</span>
              <span class="desc"
                >Target Snapchatters who engaged with your website</span
              >
              <span class="footer">Source: Snap Pixel</span>
            </div>
          </button>

          <button
            @click="openCreateMobileAppEventAudienceDialog"
            class="d-flex align-start justify-start audiance-option-card"
            type="button"
          >
            <img
              class="image"
              src="@/assets/snapchatImage/mobile-app-events.svg"
              alt=""
            />

            <div class="data d-flex flex-column">
              <span class="title">Mobile App Events</span>
              <span class="desc"
                >Target Snapchatters who engaged with your app</span
              >
              <span class="footer">Source: Snap App</span>
            </div>
          </button>

          <button
            @click="openCreateAdEngagementAudianceDialog"
            class="d-flex align-start justify-start audiance-option-card"
            type="button"
          >
            <img
              class="image"
              src="@/assets/snapchatImage/ad-engagement.svg"
              alt=""
            />

            <div class="data d-flex flex-column">
              <span class="title">Ad Engagement</span>
              <span class="desc"
                >Target Snapchatters who engaged with your ads</span
              >
              <span class="footer">Source: Past Campaign</span>
            </div>
          </button>

          <button
            @click="openCreateProfileEngagementAudienceDialog"
            class="d-flex align-start justify-start audiance-option-card"
            type="button"
          >
            <img
              class="image"
              src="@/assets/snapchatImage/profile-engagement.svg"
              alt=""
            />

            <div class="data d-flex flex-column">
              <span class="title">Profile Engagement</span>
              <span class="desc"
                >Target Snapchatters who engaged with your Profile</span
              >
              <span class="footer">Source: Public Profile</span>
            </div>
          </button>

          <button
            @click="openCreateLookalikeAudianceDialog"
            class="d-flex align-start justify-start audiance-option-card"
            type="button"
          >
            <img
              class="image"
              src="@/assets/snapchatImage/lookalike-audiance.svg"
              alt=""
            />

            <div class="data d-flex flex-column">
              <span class="title">Lookalike Audience</span>
              <span class="desc"
                >Target Snapchatters with similar characteristics to your
                existing customers</span
              >
              <span class="footer">Source: Existing Audience</span>
            </div>
          </button>
        </div>

        <template v-slot:actions>
          <span class="cancel-btn" @click="audiance_dialog = false"
            >Cancel</span
          >
        </template>
      </v-card>
    </v-dialog>
    <CreateNewCustomerListAudience
      ref="createCustomerListAudienceDialog"
      @created="getCustomAudiencesList"
    />
    <CreateNewWebsiteEvents ref="createWebsiteEventAudienceDialog" />
    <CreateNewMobileAppEvents ref="createMobileAppEventAudienceDialog" />
    <CreateNewProfileEngagement ref="createProfileEngagementAudienceDialog" />
    <CreateNewLookalikeAudiance ref="createLookalikeAudianceDialog" />
    <createAdEngagementAudianceDialog ref="createAdEngagementAudianceDialog" />
  </div>
</template>
<script>
import Treeselect from "vue3-treeselect";

import CreateNewCustomerListAudience from "@/components/snapchat/CreateAudiance/CreateNewCustomerListAudience.vue";
import CreateNewMobileAppEvents from "@/components/snapchat/CreateAudiance/CreateNewMobileAppEvents.vue";
import CreateNewWebsiteEvents from "@/components/snapchat/CreateAudiance/CreateNewWebsiteEvents.vue";
import CreateNewLookalikeAudiance from "@/components/snapchat/CreateAudiance/CreateNewLookalikeAudiance.vue";
import createAdEngagementAudianceDialog from "@/components/snapchat/CreateAudiance/CreateNewAdEngagementAudiance.vue";
import CreateNewProfileEngagement from "@/components/snapchat/CreateAudiance/CreateNewProfileEngagement.vue";
import apiClient from "@/core/buildRequest";
export default {
  components: {
    CreateNewCustomerListAudience,
    CreateNewWebsiteEvents,
    CreateNewLookalikeAudiance,
    createAdEngagementAudianceDialog,
    CreateNewMobileAppEvents,
    CreateNewProfileEngagement,
    Treeselect,
  },
  props: ["countries"],
  data() {
    return {
      selected_predefined_audiances: [],
      predefined_audiances: [],
      custom_audiences_list: [],
      selected_custom_audience: [],
      selected_custom_audience_list: [],
      audiance_dialog: false,
      advanced_audiance_settings: true,
      advanced_audiance_settings_toggle: true,
      expand_predefined_audiences: true,
      expand_custom_audiences: true,
    };
  },
  methods: {
    getCustomAudiencesList() {
      apiClient
        .get(
          `/campaign-ads/snapchat/get/audience-segment/${localStorage.getItem(
            "account_id"
          )}`
        )
        .then((response) => {
          this.custom_audiences_list = response.data.data;
        })
        .catch((error) => console.log(error));
    },
    removeSelectedCustomAudience(index) {
      this.selected_predefined_audiances.splice(index, 1);
    },
    openCreateCustomerListAudianceDialog() {
      this.audiance_dialog = false;
      this.$refs.createCustomerListAudienceDialog.openDialog();
    },

    openCreateWebsiteEventAudienceDialog() {
      this.audiance_dialog = false;
      this.$refs.createWebsiteEventAudienceDialog.openDialog();
    },
    openCreateMobileAppEventAudienceDialog() {
      this.audiance_dialog = false;
      this.$refs.createMobileAppEventAudienceDialog.openDialog();
    },
    openCreateProfileEngagementAudienceDialog() {
      this.audiance_dialog = false;
      this.$refs.createProfileEngagementAudienceDialog.openDialog();
    },
    openCreateLookalikeAudianceDialog() {
      this.audiance_dialog = false;
      this.$refs.createLookalikeAudianceDialog.openDialog();
    },
    openCreateAdEngagementAudianceDialog() {
      this.audiance_dialog = false;
      this.$refs.createAdEngagementAudianceDialog.openDialog();
    },
    removeAudiance(aud) {
      let i = this.selected_custom_audience.findIndex(
        (item) => item.id === aud.id
      );
      this.selected_custom_audience.splice(i, 1);
      let x = this.selected_custom_audience_list.findIndex(
        (item) => item.id === aud.id
      );
      this.selected_custom_audience_list.splice(x, 1);
    },
    async getPredfinedAudiences() {
      const codes = this.countries.map((item) => item.code);
      await apiClient
        .get(`/campaign-ads/snapchat/get/intersets`, {
          params: {
            country_code: codes,
          },
        })
        .then((response) => {
          this.predefined_audiances = response.data.data;
        })
        .catch((error) => console.log(error));
    },
  },
  computed: {},
  watch: {
    advanced_audiance_settings() {
      if (this.advanced_audiance_settings) {
        this.expand_custom_audiences = true;
        this.expand_predefined_audiences = true;
      } else {
        this.expand_custom_audiences = false;
        this.expand_predefined_audiences = false;
      }
      localStorage.setItem(
        "advanced_audiance_settings",
        JSON.stringify(this.advanced_audiance_settings)
      );
    },
    expand_predefined_audiences() {
      localStorage.setItem(
        "expand_predefined_audiences",
        JSON.stringify(this.expand_predefined_audiences)
      );
    },
    expand_custom_audiences() {
      localStorage.setItem(
        "expand_custom_audiences",
        JSON.stringify(this.expand_custom_audiences)
      );
    },
    selected_custom_audience(newVal) {
      if (newVal?.length > 0) {
        this.selected_custom_audience_list = newVal.map((item) => ({
          id: item.id,
          label: item.label,
          type: "include",
        }));
      }
      localStorage.setItem(
        "selected_custom_audience_list",
        JSON.stringify(this.selected_custom_audience_list)
      );
      localStorage.setItem("selected_custom_audience", JSON.stringify(newVal));
    },
    selected_custom_audience_list: {
      handler(newVal) {
        localStorage.setItem(
          "selected_custom_audience_list",
          JSON.stringify(newVal)
        );
      },
      deep: true,
    },
    selected_predefined_audiances(newVal) {
      localStorage.setItem(
        "selected_predefined_audiances",
        JSON.stringify(newVal)
      );
    },
    countries(c) {
      this.getPredfinedAudiences();
    },
  },
  mounted() {
    this.selected_custom_audience = localStorage.getItem(
      "selected_custom_audience"
    )
      ? JSON.parse(localStorage.getItem("selected_custom_audience"))
      : null;
    this.selected_custom_audience_list = localStorage.getItem(
      "selected_custom_audience_list"
    )
      ? JSON.parse(localStorage.getItem("selected_custom_audience_list"))
      : null;
    this.selected_predefined_audiances = localStorage.getItem(
      "selected_predefined_audiances"
    )
      ? JSON.parse(localStorage.getItem("selected_predefined_audiances"))
      : null;
    this.expand_predefined_audiences = localStorage.getItem(
      "expand_predefined_audiences"
    )
      ? JSON.parse(localStorage.getItem("expand_predefined_audiences"))
      : false;
    this.expand_custom_audiences = localStorage.getItem(
      "expand_custom_audiences"
    )
      ? JSON.parse(localStorage.getItem("expand_custom_audiences"))
      : false;
    this.advanced_audiance_settings = localStorage.getItem(
      "advanced_audiance_settings"
    )
      ? JSON.parse(localStorage.getItem("advanced_audiance_settings"))
      : false;

    this.getCustomAudiencesList();
    this.getPredfinedAudiences();
  },
};
</script>

<style scoped>
.cancel-btn {
  background-color: #fff;
  box-shadow: 0 0 0 1px #d1d3d5;
  color: #444546;
  align-items: center;
  border-radius: 100px;
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  justify-content: center;
  line-height: 1;
  min-height: 32px;
  outline: 1px;
  padding: 8px 20px;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  cursor: pointer;
}
.cancel-btn:hover {
  box-shadow: 0 0 0 2px #f7d901 !important;
}
</style>
