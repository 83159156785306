<template>
    <div class="tab-two tab-one">
        <div class="tab-header">
            <div class="search">
                <input type="text" placeholder="Search by post caption or post iD" />
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                    fill="#121415">
                    <path
                        d="M765-144 526-383q-30 22-65.79 34.5-35.79 12.5-76.18 12.5Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.03q0 40.39-12.5 76.18Q599-464 577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z" />
                </svg>
            </div>
            <div class="upload-media">
                <button class="btn_tiktok bg-black" @click.stop="drawer_upload = !drawer_upload">upload</button>
                <v-navigation-drawer class="ad-creative-drawer-traffic-upload" width="1350" v-model="drawer_upload"
                    location="right" temporary>
                    <div class="navigations-contant pa-3 position-relative">
                        <button class="btn_tiktok bg-black"
                            style="position: absolute; right:20px;display: flex;align-items: center;"
                            @click.stop="drawer_upload = !drawer_upload">
                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                                fill="#fff">
                                <path
                                    d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z" />
                            </svg>
                        </button>
                        <div class="upload-container">
                            <div class="upload-container-header">
                                <h3>Upload creatives
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="navigations-body pa-3">
                        <v-file-upload v-model="newFiles"
                            accept="video/mp4,video/quicktime,video/mpeg,video/x-msvideo,image/*" clearable multiple
                            density="default" @update:model-value="handleFileUpload"
                            :error-messages="store.errors.map(e => `${e.file}: ${e.errors.join(', ')}`)"></v-file-upload>
                        <table v-if="store.previews.length" class="table my-5">
                            <thead>
                                <tr>
                                    <th>File Name</th>
                                    <th>Placement requirement check </th>
                                    <th>Status</th>
                                    <th>Size</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(preview, index) in store.previews" :key="index">
                                    <td>
                                        <div class="d-flex align-center ga-2">
                                            <img v-if="preview.type === 'image'" :src="preview.url"
                                                style="width: 40px; height: auto;" />
                                            <video v-else-if="preview.type === 'video'" controls
                                                style="width: 40px; height: auto;">
                                                <source :src="preview.url" :type="store.media[index].type">
                                            </video>
                                            <p>{{ preview.name }}</p>
                                        </div>
                                    </td>
                                    <td class="d-flex align-center justify-center ga-3 align-center">

                                        <img class="mt-2"
                                            src="https://lf16-ttmp.byteintlstatic.com/obj/ttastatic-sg/ttam_standalone_vue/static/image/placement-tt.7e35c0fc.png"
                                            width="40px" alt="" loading="lazy">
                                        <img class="mt-2"
                                            src="https://lf16-ttmp.byteintlstatic.com/obj/ttastatic-sg/ttam_standalone_vue/static/image/global-app-bundle.7e77d90c.png"
                                            width="40px" alt="" loading="lazy">
                                        <img class="mt-2"
                                            src="https://lf16-ttmp.byteintlstatic.com/obj/ttastatic-sg/ttam_standalone_vue/static/image/placement-new-pangle.498ddcf9.png"
                                            width="40px" alt="" loading="lazy">
                                    </td>
                                    <td>
                                        Ready to upload
                                    </td>
                                    <td>
                                        <span v-if="preview.type === 'video'">Video</span>
                                        <span v-else>Image</span>
                                        • {{ formatFileSize(store.media[index].size) }}
                                    </td>
                                    <td style="position: relative;">
                                        <button @click="removeFile(index)" class="remove-btn"
                                            style="position: absolute; right: 15px; top: 10px; z-index: 1; background: rgba(255,255,255,0.8); border: none; border-radius: 50%; width: 24px; height: 24px; cursor: pointer; display: flex; align-items: center; justify-content: center;">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960"
                                                width="16" fill="#000">
                                                <path
                                                    d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="conditions-section" v-if="!store.media.length">
                            <div class="video-guidelines mb-4">
                                <h4 class="mb-2">Guidance for videos</h4>
                                <div class="d-flex">
                                    <p>Recommended format:</p> <span>.mp4, .mov, .mpeg, .avi </span>
                                </div>
                                <div class="d-flex">
                                    <p>File size:</p> <span>500 MB</span>
                                </div>
                                <div class="d-flex">
                                    <p>Aspect ratio requirements for all placements:</p> <span>Horizontal(16:9) /
                                        Square(1:1) / Vertical(9:16)</span>
                                </div>
                            </div>
                            <div class="image-guidelines">
                                <h4>Guidance for images
                                </h4>
                                <div class="d-flex">
                                    <p>Recommended format:
                                    </p>
                                    <span>.jpg, .jpeg or .png</span>
                                </div>
                                <div class="d-flex">
                                    <p>File size:</p> <span>100 MB</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-navigation-drawer>
            </div>
        </div>
        <div class="tab-content">
            <LibraryContent />
        </div>
    </div>
</template>

<script>
import { VFileUpload } from 'vuetify/labs/VFileUpload'
import { uploadMedia } from '@/store/tiktokStore/uploadMedia'
import LibraryContent from './LibraryContent.vue'
export default {
    components: {
        VFileUpload,
        LibraryContent
    },
    name: "TabTwo",
    data() {
        return {
            drawer_upload: false,
            tab: null,
            store: uploadMedia(),
            newFiles: []
        };
    },
    methods: {
        async handleFileUpload(files) {
            if (files && files.length > 0) {
                await this.store.setMedia(files);
                if (this.store.errors.length === 0) {
                    this.newFiles = []; // Clear the upload input only if no errors
                }
            }
        },
        removeFile(index) {
            this.store.removeFile(index);
        },
        formatFileSize(bytes) {
            const mb = bytes / (1024 * 1024);
            return mb >= 1 ? `${mb.toFixed(1)} MB` : `${(bytes / 1024).toFixed(1)} KB`;
        }
    },
    // beforeUnmount() {
    //     this.store.clearPreviews();
    // }
};
</script>