<template>
  <div class="card-pattern campaign-budget-card">
    <div class="card-header">
      <label for="" class="">
        <span class="roboto-black">Advantage campaign budget</span>
        <img src="@/assets/images/meta/star.svg" class="star-icon" alt="" />
      </label>
      <div class="d-flex align-center ga-2">
        <span class="roboto-light" v-if="!campaignBudgetStatus">Off</span>
        <span class="roboto-light" v-else>On</span>
        <label class="toggle-switch">
          <input
            type="checkbox"
            @change="toggleCampaignBudget"
            :value="campaignBudgetStatus"
          />
          <div class="toggle-switch-background">
            <div class="toggle-switch-handle"></div>
          </div>
        </label>
      </div>
    </div>
    <div class="card-body">
      <div class="description-campaign-budget mb-3">
        <p>
          Advantage Campaign Budget will distribute your budget across currently
          delivering ad sets to get more results depending on your performance
          goal choices and bid strategy. You can control spending on each ad set
          by using ad set spending limits.
        </p>
      </div>
      <div class="budget-section" v-if="campaignBudgetStatus">
        <p class="roboto-bold">Budget</p>
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col cols="6" class="pr-1 select-budget">
              <v-select
                v-model="select"
                :items="budget_items"
                density="compact"
                variant="outlined"
                return-object
                item-value="id"
                item-title="title"
              >
                <template v-slot:selection="{ item }">
                  {{ item?.title || "" }}
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item @click="selectItem(item)">
                    <div class="my-form">
                      <div>
                        <input
                          :checked="select && select.id === item.raw.id"
                          :id="item.id"
                          type="radio"
                          name="option"
                          :value="item"
                        />
                        <label :for="item.id">{{ item.title }}</label>
                      </div>
                    </div>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col v-if="select.value === 'DAILY_BUDGET'" cols="6" class="pl-1">
              <div class="input-budget">
                <span class="dollar-sign">$</span>
                <input
                  type="number"
                  v-model="budget_amount"
                  @input="setValueOfBudget(budget_amount)"
                  class="no-controls"
                  name=""
                  id=""
                />
                <span class="currency-type">USD</span>
              </div>
            </v-col>
            <v-col v-else cols="6" class="pl-1">
              <div class="input-budget">
                <span class="dollar-sign">$</span>
                <input
                  type="number"
                  v-model="budget_amount_max"
                  @input="setValueOfMaxBudget(budget_amount_max)"
                  class="no-controls"
                  name=""
                  id=""
                />
                <span class="currency-type">USD</span>
              </div>
            </v-col>
            <v-col cols="12"></v-col>
          </v-row>
        </v-container>
      </div>
      <div class="campaign-bid-strategy" v-if="campaignBudgetStatus">
        <p class="roboto-bold mb-1">Campaign bid strategy</p>
        <v-select
          :items="bidSterategy"
          variant="outlined"
          v-model="selectedBidStrategy"
          item-text="title"
          item-value="value"
          hide-details="true"
          density="compact"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props">
              <div>
                <div
                  style="font-size: 14px; color: #1c2b33"
                  class="item-description"
                >
                  <span>{{ item.raw.description }}</span>
                </div>
              </div>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <span>{{ item?.title }}</span>
          </template>
        </v-select>
      </div>
      <v-divider class="my-3" v-if="campaignBudgetStatus"></v-divider>
      <button
        v-if="campaignBudgetStatus"
        class="show-more-options"
        @click="toggleShowMoreOptions"
      >
        <div class="show-more" v-if="!budgetSchedulingStatus">
          <span>Show more options</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#0a7abf"
          >
            <path d="M480-397.85 311.85-566h336.3L480-397.85Z" />
          </svg>
        </div>
        <div class="show-more" v-else>
          <span>Hide options</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#0a7abf"
          >
            <path d="M311.85-394 480-562.15 648.15-394h-336.3Z" />
          </svg>
        </div>
      </button>
      <BudgetScheduling
        :selectedBidStrategy="sendSelectedBidStrategy"
        v-if="budgetSchedulingStatus"
      />
    </div>
  </div>
</template>

<script>
import BudgetScheduling from "./BudgetScheduling.vue";
export default {
  components: { BudgetScheduling },
  name: "CampaignBudget",
  data() {
    return {
      campaignBudgetStatus: false,
      select: {
        title: "Daily budget",
        id: 1,
        value: "DAILY_BUDGET",
      },
      budget_items: [
        {
          title: "Daily budget",
          id: 1,
          value: "DAILY_BUDGET",
        },
        {
          title: "lifetime budget",
          id: 2,
          value: "LIFETIME_BUDGET",
        },
      ],
      budget_amount: 20,
      budget_amount_max: 350,
      bidSterategy: [
        {
          id: 0,
          title: "Highest volume",
          description: "Get the most results for your budget.",
          value: "LOWEST_COST_WITHOUT_CAP",
        },
        {
          id: 1,
          title: "Cost per result goal",
          description:
            "Aim for a certain cost per result while maximising the volume of results.",
          value: "LOWEST_COST_WITH_BID_CAP",
        },
        {
          id: 2,
          title: "Bid cap",
          description: "Set the highest that you want to bid in any auction.",
          value: "COST_CAP",
        },
      ],
      selectedBidStrategy: {
        id: 0,
        title: "Highest volume",
        description: "Get the most results for your budget.",
        value: "LOWEST_COST_WITHOUT_CAP",
      },
      budgetSchedulingStatus: false,
      sendSelectedBidStrategy: "",
    };
  },
  mounted() {
    localStorage.setItem("meta_budget_type", this.select.value);
    localStorage.setItem("bid_strategy_type", this.selectedBidStrategy.value);
  },
  methods: {
    selectItem(item) {
      this.select = item.raw;
      localStorage.setItem("meta_budget_type", item.raw.value);
    },
    setValueOfBudget(value) {
      localStorage.setItem("value_meta_Budget", value);
      localStorage.setItem("value_meta_Budget_max", null);
    },
    setValueOfMaxBudget(value) {
      localStorage.setItem("value_meta_Budget", null);
      localStorage.setItem("value_meta_Budget_max", value);
    },
    toggleShowMoreOptions() {
      this.budgetSchedulingStatus = !this.budgetSchedulingStatus;
    },
    toggleCampaignBudget() {
      this.campaignBudgetStatus = !this.campaignBudgetStatus;
      localStorage.setItem(
        "campaign_budget_status_meta",
        this.campaignBudgetStatus
      );
    },
  },
  watch: {
    selectedBidStrategy(newValue) {
  
      if (newValue === "Bid cap") {
        this.sendSelectedBidStrategy = newValue;
      } else {
        this.sendSelectedBidStrategy = "";
      }
    },
    selectedBidStrategy(newValue) {
  
      localStorage.setItem("bid_strategy_type", newValue);
    },
  },
};
</script>
