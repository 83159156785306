<template>
  <div class="unauthorized-page">
    <h2>Whoops!</h2>
    <p>Sorry, You don't have authorization to view this page.</p>
    <img src="@/assets/images/error_page.svg" alt="" />
    <router-link to="/">Back to Home</router-link>
  </div>
</template>
<script>
export default {
  name: "AuthorizationError",
};
</script>
