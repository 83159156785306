<template>
  <div class="profile-box-container w-100">
    <div class="mb-3">
      <label for="" class="profile-box-title">{{ $t('') }}</label>
    </div>
    <div class="d-flex flex-column py-5">
      <div class="profile-box-second-text mb-3">
         {{ $t('first_name') }}  <span class="text-red-accent-4">*</span>
      </div>
      <Input v-model="first_name" type="text" :placeholder="$t('first_name')" />
      <span
        class="text-red-accent-4 text-caption small-text mt-2"
        v-if="errors.first_name"
        >{{ errors.first_name }}</span
      >
    </div>
    <div class="d-flex flex-column py-5">
      <div class="profile-box-second-text mb-3">
        {{ $t('last_name') }} <span class="text-red-accent-4">*</span>
      </div>
      <Input v-model="last_name" type="text" placeholder="last name" />
      <span
        class="text-red-accent-4 text-caption small-text mt-2"
        v-if="errors.last_name"
        >{{ errors.last_name }}</span
      >
    </div>
    <div class="d-flex flex-column py-5">
      <div class="profile-box-second-text mb-3">
        {{ $t('date_of_birth') }} <span class="text-red-accent-4">*</span>
      </div>
      <Input v-model="date_of_birth" type="date" placeholder="date of birth" />
      <span
        class="text-red-accent-4 text-caption small-text mt-2"
        v-if="errors.date_of_birth"
        >{{ errors.date_of_birth }}</span
      >
    </div>
  </div>
</template>
<script>
import Input from "@/components/crm/assets/Input.vue";

export default {
  name: "BasicInfo",
  components: { Input },
  props: ["errors", "profile"],

  data() {
    return {
      first_name: "",
      last_name: "",
      date_of_birth: "",
    };
  },
  beforeCreate() {},

  watch: {
    profile() {
      this.first_name = this.profile?.first_name;
      this.last_name = this.profile?.last_name;
      this.date_of_birth = this.profile?.date_of_birth;
    },
    errors(val) {
      console.log(val);
    },
    first_name() {
      this.$emit("change:firstName", this.first_name);
    },
    last_name() {
      this.$emit("change:lastName", this.last_name);
    },
    date_of_birth() {
      this.$emit("change:dateOfBirth", this.date_of_birth);
    },
  },
};
</script>
