<template>
  <div>
    <input
      hidden
      type="file"
      ref="fileInput"
      @change="handleFileUpload"
      :accept="FileType"
      class="hidden"
      :multiple="multiple"
    />

    <button
      v-if="url === ''"
      class="upload-span d-flex align-center ga-2"
      @click="triggerFileInput"
    >
      <img
        v-if="this.loading"
        :src="loadingBlackSvg"
        width="16px"
        height="16px"
        alt="Loading..."
      />
      Upload
    </button>

    <v-dialog v-model="showCropper" max-width="600px">
      <v-card>
        <v-card-text>
          <Cropper
            :src="previewUrl"
            @change="change"
            :stencil-props="{
              aspectRatio: computedAspectRatio,
              minWidth: minCropWidth,
              minHeight: minCropHeight,
              width: cropWidth,
              height: cropHeight,
            }"
            :resize="{
              enabled: true,
              minWidth: minCropWidth,
              minHeight: minCropHeight,
              maxWidth: maxCropWidth,
              maxHeight: maxCropHeight,
            }"
            :default-size="{
              width: cropWidth,
              height: cropHeight,
            }"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button @click="cancelCrop" class="cancel-btn">Cancel</button>
          <button @click="cropImage" class="crop-btn">
            <img
              v-if="this.loading"
              :src="loadingSvg"
              width="16px"
              height="16px"
              alt="Loading..."
            />
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              class="sds-icon sds-icon-scissor"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.23239 11.9217C5.01016 12.1535 4.75076 12.1879 4.61528 12.1879C4.48062 12.1879 4.22122 12.1535 3.999 11.9217C3.65946 11.5689 3.65946 10.9938 3.999 10.641C4.22122 10.4092 4.48062 10.3748 4.61528 10.3748C4.75076 10.3748 5.01016 10.4092 5.23239 10.641C5.57275 10.9938 5.57275 11.5689 5.23239 11.9217ZM5.31665 5.35904C5.09443 5.59082 4.83503 5.62515 4.69954 5.62515C4.56489 5.62515 4.30548 5.59082 4.08326 5.35904C3.7429 5.00622 3.7429 4.43108 4.08326 4.07826C4.30466 3.84734 4.56489 3.81301 4.69954 3.81301C4.83503 3.81301 5.09443 3.84734 5.31665 4.07826C5.65619 4.43108 5.65619 5.00622 5.31665 5.35904ZM13.8711 12.957L9.06309 7.96867L13.686 3.2267C13.9504 2.95715 13.7901 2.48759 13.4217 2.45325C12.7476 2.39059 11.9132 2.35968 11.7174 2.56399L7.80657 6.66557L7.1655 6.00114C7.12832 5.96166 7.08041 5.94277 7.03828 5.91187C7.51743 4.8972 7.36129 3.64132 6.54922 2.79662C6.03868 2.26611 5.36952 2 4.69954 2C4.03039 2 3.36041 2.26611 2.84987 2.79662C1.82796 3.85764 1.82796 5.5788 2.84987 6.64153C3.36041 7.17204 4.03039 7.4373 4.69954 7.4373C5.09443 7.4373 5.48518 7.33343 5.84784 7.14801C5.87758 7.19265 5.89493 7.24243 5.93293 7.28192L6.57896 7.95322L5.8495 8.71894C5.8115 8.75757 5.79332 8.80735 5.76441 8.85199C5.40174 8.66657 5.01099 8.5627 4.61528 8.5627C3.94612 8.5627 3.27697 8.82796 2.76643 9.35932C1.74452 10.4212 1.74452 12.1424 2.76643 13.2034C3.27697 13.7339 3.94612 14 4.61528 14C5.28526 14 5.95524 13.7339 6.46578 13.2034C7.27785 12.3595 7.43316 11.1028 6.95401 10.0881C6.99615 10.0572 7.04406 10.0392 7.08206 9.99971L7.81978 9.24258L12.0991 13.6892C12.3973 14.0858 13.159 13.9073 13.71 13.7056C14.0132 13.5948 14.0983 13.1931 13.8711 12.957Z"
              ></path>
            </svg>
            Crop Image
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Preview Uploaded Image -->
    <div v-if="url" class="d-flex align-center ga-2 mt-3">
      <img
        v-if="uploaded_type === 'image'"
        class="preview-uploaded-image"
        :src="url"
        alt="Uploaded Image"
      />
      <video
        v-else-if="uploaded_type === 'video'"
        class="preview-uploaded-image"
        :src="url"
        controls
      ></video>
      <span></span>
      <button class="remove-uploaded-image-btn">
        <svg
          @click="deleteMedia"
          class="cursor-pointer"
          width="16px"
          height="16px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>delete</title>
          <g
            id="Icon/SDS/Trash"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <path
              d="M11,14 L12,14 L12,5 L11,5 L11,14 Z M7.5,14 L8.5,14 L8.5,5 L7.5,5 L7.5,14 Z M4,14 L5,14 L5,5 L4,5 L4,14 Z M14,3 C14.553,3 15,3.448 15,4 C15,4.552 14.553,5 14,5 L14,14 C14,15.104 13.104,16 12,16 L4,16 C2.896,16 2,15.104 2,14 L2,5 C1.448,5 1,4.552 1,4 C1,3.448 1.448,3 2,3 L14,3 Z M3,2 C2.448,2 2,1.552 2,1 C2,0.448 2.448,0 3,0 L13,0 C13.553,0 14,0.448 14,1 C14,1.552 13.553,2 13,2 L3,2 Z"
              id="🎨-Fill-Color"
              fill="#16191C"
            ></path>
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { useAwarenessStore } from "@/store/snapchat/awareness";
import { useGeneralStore } from "@/store/General";
import loadingSvg from "@/assets/loading.svg";
import loadingBlackSvg from "@/assets/loadingBlack.svg";
export default {
  components: {
    Cropper,
  },

  props: {
    type: { type: String, default: "image" },
    multiple: { type: Boolean, default: false },
    old_id: { type: String },
    aspectRatio: { type: Number },
    minAspectRatio: { type: Number },
    maxAspectRatio: { type: Number },
    cropWidth: { type: Number },
    cropHeight: { type: Number },
    minCropWidth: { type: Number },
    minCropHeight: { type: Number },
    maxCropWidth: { type: Number },
    maxCropHeight: { type: Number },
    size: { type: Number },
    extension: { type: String },
  },
  data() {
    return {
      store: useAwarenessStore(),
      selectedFile: null,
      url: "",
      id: "",
      uploaded_type: "",
      showCropper: false,
      previewUrl: "",
      croppedFile: null,
      YWidth: 0,
      YHeight: 0,
      useGeneralStore: useGeneralStore(),
      loading: false,
      loadingSvg,
      loadingBlackSvg,
    };
  },
  computed: {
    computedAspectRatio() {
      return (
        this.aspectRatio || (this.minAspectRatio + this.maxAspectRatio) / 2
      );
    },
    FileType() {
      return this.type === "image"
        ? "image/*"
        : this.type === "video"
        ? "video/*"
        : "image/*, video/*";
    },
  },
  watch: {
    old_id() {
      this.getOldImage();
    },
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.uploaded_type = file.type.startsWith("image") ? "image" : "video";
      if (this.uploaded_type === "image") {
        this.selectedFile = file;
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (!this.validateImage(img)) return;
          this.previewUrl = img.src;
          this.showCropper = true;
        };
      } else {
        const fileSizeMB = file.size / (1024 * 1024);
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.preload = "metadata";

        video.onloadedmetadata = () => {
          if (!this.validateVideo(video, fileSizeMB)) return;
          this.uploadFile(file);
        };
      }
      event.target.value = "";
    },
    change({ coordinates, canvas }) {
      if (!canvas) return;

      const originalExtension = this.selectedFile.name.split(".").pop().toLowerCase();

      const mimeTypeMap = {
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
        webp: "image/webp",
      };

      const mimeType = mimeTypeMap[originalExtension] || "image/png";

      const outputExtension = originalExtension;

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const croppedFile = new File(
              [blob],
              `cropped-image.${outputExtension}`,
              {
                type: mimeType,
              }
            );
            this.croppedFile = croppedFile;
          }
        },
        mimeType,
        1.0
      );
    },
    cropImage() {
      if (this.croppedFile) {
        this.uploadFile(this.croppedFile);
      }
    },
    async uploadFile(file) {
      this.loading = true;
      try {
        await this.store.CreateUploadContainer("container", this.uploaded_type);
        const data = await this.store.UploadMedia(file);
        if (data.request_status === "ERROR") {
          this.loading = false;
          this.showCropper = false;
          this.useGeneralStore.showSnackbar(data.display_message, "error");
          return;
        }

        this.$emit("uploaded:id", data.result.id);
        this.$emit(
          this.uploaded_type === "image" ? "uploaded:url" : "uploaded:video",
          data.result.download_link
        );
        this.id = data.result.id;
        this.url = data.result.download_link;
        this.loading = false;
        this.showCropper = false;
      } catch (error) {
        this.loading = false;
        this.showCropper = false;
        this.useGeneralStore.showSnackbar("Something went wrong", "error");
      }
    },
    deleteMedia() {
      this.url = "";
      this.id = "";
      this.$emit("uploaded:delete");
    },
    cancelCrop() {
      this.showCropper = false;
      this.previewUrl = "";
      this.selectedFile = null;
    },
    async getOldImage() {
      if (this.old_id) {
        const data = await this.store.GetMedia(this.old_id);
        this.$emit("uploaded:id", data.id);
        this.$emit(
          data.type === "IMAGE" ? "uploaded:url" : "uploaded:video",
          data.download_link
        );
        this.uploaded_type = data.type === "IMAGE" ? "image" : "video";
        this.id = data.id;
        this.url = data.download_link;
      }
    },
    validateImage(img) {
      this.YWidth = img.width;
      this.YHeight = img.height;

      if (
        this.YWidth < this.minCropWidth ||
        this.YHeight < this.minCropHeight
      ) {
        this.useGeneralStore.showSnackbar(
          `File dimensions must be at least ${this.minCropWidth}x${this.minCropHeight} pixels.`,
          "error"
        );
        return false;
      }
      if (
        this.maxCropWidth &&
        this.maxCropHeight &&
        (this.YWidth > this.maxCropWidth || this.YHeight > this.maxCropHeight)
      ) {
        this.useGeneralStore.showSnackbar(
          `File dimensions must be at most ${this.maxCropWidth}x${this.maxCropHeight} pixels.`,
          "error"
        );
        return false;
      }
      const fileSize = this.selectedFile.size / (1024 * 1024);
      if (this.size && fileSize > this.size) {
        this.useGeneralStore.showSnackbar(
          `File size must be at most ${this.size} MB.`,
          "error"
        );
        return false;
      }
      if (this.extension) {
        if (!this.selectedFile.name.endsWith(this.extension)) {
          this.useGeneralStore.showSnackbar(
            `File must be ${this.extension} type.`,
            "error"
          );
          return false;
        }
      }

      return true;
    },
    validateVideo(vid, fileSizeMB) {
      const videoWidth = vid.videoWidth;
      const videoHeight = vid.videoHeight;
      if (fileSizeMB > 1024) {
        this.useGeneralStore.showSnackbar(
          `File size must be at most 1 GB.`,
          "error"
        );
        return false;
      }
      if (
        videoWidth !== this.minCropWidth ||
        videoHeight !== this.minCropHeight
      ) {
        this.useGeneralStore.showSnackbar(
          `Video dimensions must be at least ${this.minCropWidth}x${this.minCropHeight} pixels.`,
          "error"
        );
        return false;
      }
      if (this.aspectRatio && videoWidth / videoHeight !== this.aspectRatio) {
        this.useGeneralStore.showSnackbar(
          `Video dimensions must be 9 : 16 `,
          "error"
        );
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.getOldImage();
  },
};
</script>

<style scoped>
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.preview-uploaded-image {
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
}
.crop-btn {
  gap: 5px;
  background-color: #131414;
  box-shadow: 0 0 0 1px #131414;
  color: #fff;
  align-items: center;
  border: none;
  border-radius: 100px;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  justify-content: center;
  line-height: 1;
  min-height: 32px;
  outline: 1px;
  padding: 8px 20px;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
.crop-btn:hover {
  box-shadow: 0 0 0 2px #f7d901;
  background-color: rgb(21, 22, 22);
}
</style>
