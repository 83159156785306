<template>
  <div class="table-wrapper">
    <div class="table-container">
      <v-data-table
        v-model="selectedItems"
        :headers="visibleHeaders"
        :items="contacts"
        item-value="id"
        :show-select="!removeSelect"
        hide-default-header
        :items-per-page="store.items_per_page"
        :hide-default-footer="true"
        class="data-table-no-border"
      >
        <!-- Custom Headers -->
        <template v-slot:thead>
          <thead>
            <tr>
              <th v-if="!removeSelect">
                <v-checkbox
                  v-model="isAllSelected"
                  @change="toggleSelectAll"
                  :indeterminate="isIndeterminate"
                  hide-details
                ></v-checkbox>
              </th>
              <th v-for="header in visibleHeaders" :key="header.key">
                <div
                  v-if="header.menu"
                  class="d-flex justify-space-between align-center"
                >
                  <span>{{ header.title }}</span>
                  <div class="options">
                    <div class="menu align-center">
                      <svg
                        @click="hideColumn(header.key)"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3348_28197)">
                          <path
                            d="M0.666748 8.00008C0.666748 8.00008 3.33341 2.66675 8.00008 2.66675C12.6667 2.66675 15.3334 8.00008 15.3334 8.00008C15.3334 8.00008 12.6667 13.3334 8.00008 13.3334C3.33341 13.3334 0.666748 8.00008 0.666748 8.00008Z"
                            stroke="#666666"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.00008 10.0001C9.10465 10.0001 10.0001 9.10465 10.0001 8.00008C10.0001 6.89551 9.10465 6.00008 8.00008 6.00008C6.89551 6.00008 6.00008 6.89551 6.00008 8.00008C6.00008 9.10465 6.89551 10.0001 8.00008 10.0001Z"
                            stroke="#666666"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3348_28197">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ header.title }}
                </div>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item.contact="{ item }">
          <div class="d-flex align-center ga-2">
            <span
              class="cursor-pointer"
              @click="
                $router.push({ name: 'ShowContact', params: { id: item.id } })
              "
            >
              {{ item.contact_number }}
            </span>
          </div>
        </template>

        <template v-slot:item.city="{ item }">
          {{ item.city?.name }}
        </template>

        <template v-slot:item.country="{ item }">
          {{ item.country?.name }}
        </template>

        <template v-slot:item.job_title="{ item }">
          {{ item.job_title?.name }}
        </template>

        <template v-slot:item.potentiality="{ item }">
          <Potentiality :item="item" :id="item.id" />
        </template>

        <template v-slot:item.actions="{ item }">
          <Actions :item="item" />
        </template>
      </v-data-table>
    </div>
  </div>
  <Pagination v-if="store?.total_contacts > 5" :store="store" />
</template>

<script>
import { format } from "date-fns";
import { useContactsStore } from "@/store/crm/useContactsStore";
import Potentiality from "@/components/crm/contacts/table/Potentiality.vue";
import Actions from "@/components/crm/contacts/table/Actions.vue";
import Pagination from "@/components/crm/assets/Pagination.vue";
export default {
  name: "CustomDataTable",
  props: {
    removeSelect: {
      type: Boolean,
      default: false,
    },
    contacts : {
      type: Array,
      default: () => [],
    }
  },
  components: {
    Potentiality,
    Actions,
    Pagination,
  },

  data() {
    return {
      selectedItems: [],
      store: null,
    };
  },
  watch: {
    selectedItems() {
      this.$emit("update:selected_contacts", this.selectedItems);
    },
  },
  computed: {

    visibleHeaders() {
      return this.store?.getHeaders || [];
    },
    isAllSelected() {
      return (
        this.selectedItems.length === this.contacts.length &&
        this.contacts.length > 0
      );
    },
    isIndeterminate() {
      return (
        this.selectedItems.length > 0 &&
        this.selectedItems.length < this.contacts.length
      );
    },
  },

  methods: {
    formatDate(date) {
      return format(new Date(date), "MMM dd, yyyy HH:mm");
    },
    renameColumn(key) {
      const header = this.store.headers.find((h) => h.key === key);
      if (header) {
        header.title = "new title";
      }
    },
    hideColumn(key) {
      const header = this.store.headers.find((h) => h.key === key);
      if (header) {
        header.show = false;
      }
    },
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.contacts.map((item) => item.id);
      }
    },
  },
  mounted() {
    this.store.getAllContacts();
  },
  created() {
    this.store = useContactsStore();
  },
};
</script>

<style>
.table-container .v-table {
  margin: 0px !important;
}

.table-container .v-table .v-data-table-footer {
  justify-content: space-between !important;
}

.table-container .v-table .v-table__wrapper table thead {
  background: #edf1f1;
  color: #535862;
}

.table-container .v-table .v-table__wrapper table tbody tr:hover {
  background: #adadad27;
}

.table-container .v-table .v-table__wrapper table tbody tr td {
  padding: 16px 24px;
  white-space: nowrap;
}

.table-container thead tr th:hover {
  background-color: #adadad27 !important;
}

.table-container thead tr th div .options .menu {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.table-container thead tr th div .options .menu {
  display: none;
}
.table-container thead tr th:hover > div .options .menu {
  display: flex;
}

.data-table-no-border
  .v-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td,
.data-table-no-border
  .v-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th {
  border: none !important;
}
.data-table-no-border > .v-table__wrapper > table > tbody > tr > th,
.data-table-no-border > .v-table__wrapper > table > thead > tr > th,
.data-table-no-border > .v-table__wrapper > table > tfoot > tr > th {
  border: none !important;
}
</style>

<style scoped>
.table-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
}

.table-container {
  width: 100%;
  position: relative;
  overflow-x: auto;
}

.v-data-table {
  width: 100%;
  margin: 1rem 0;
}

:deep(.v-data-table__wrapper) {
  overflow-x: auto;
  width: 100%;
}

:deep(.v-data-table > .v-data-table__wrapper > table) {
  width: 100%;
  white-space: nowrap;
}

:deep(.v-data-table th) {
  white-space: nowrap;
  min-width: 120px;
  padding: 16px 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.phone-link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #666666;
  text-decoration: none;
}

.phone-link:hover {
  text-decoration: underline;
}

:deep(.v-data-table) {
  text-transform: capitalize;
}

:deep(.v-data-table__checkbox) {
  width: 48px;
  padding-left: 16px !important;
}

.menu-options-list .v-list-item .v-list-item__content .v-list-item-title {
  width: 150px;
  height: 36px;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  margin: 6px 0px;
}

.menu-options-list .v-list-item .v-list-item__content .v-list-item-title:hover {
  background: #c0c0c0;
  color: white;
}
</style>
