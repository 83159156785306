<template>
  <div
    class="card-pattern meta-conversion-card engagement-conversion awareness-card"
  >
    <div class="card-header mb-3">
      <label for="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Conversion</span>
      </label>
    </div>
    <div class="card-body">
      <div class="title-sub-title mb-2">
        <label for="" class="roboto-bold d-block mb-2 title"
          >Conversion location</label
        >
        <p class="roboto-regular d-block sub-title">
          Choose where you want to drive engagement.
        </p>
      </div>
      <div class="select-conversion-location mb-3">
        <v-select
          v-model="conversionLocationSelected"
          :items="conversionLocationArray"
          density="compact"
          variant="outlined"
          return-object
          item-value="value"
          item-title="title"
          hide-details
          :menu-props="{ maxWidth: '796px', minWidth: '100%' }"
        >
          <template v-slot:selection="{ item }">
            {{ item?.title || "" }}
          </template>
          <template v-slot:item="{ item, index }">
            <v-list-item @click="driveEngagement(item)">
              <div class="my-form">
                <div class="ga-3">
                  <input
                    :checked="
                      conversionLocationSelected &&
                      conversionLocationSelected.id === item.raw.id
                    "
                    :id="`${item.raw.id}_conversion_location1`"
                    type="radio"
                    name="option"
                    :value="item"
                  />
                  <div class="d-flex flex-column ga-1">
                    <label
                      :for="`${item.raw.id}_conversion_location1`"
                      class="d-block"
                      style="font-size: 14px"
                      >{{ item.raw.title }}</label
                    >
                    <label
                      :for="`${item.raw.id}_conversion_location1`"
                      style="font-size: 12px; color: #1c2b33"
                    >
                      {{ item.raw.descriptions }}
                    </label>
                  </div>
                </div>
              </div>
            </v-list-item>
          </template>
        </v-select>
      </div>
      <MessagingApps v-if="message_app" />
      <EngagementType v-if="on_your_ad" />
      <div class="performance-goal mt-2 mb-5">
        <label for="" style="font-size: 14px; color: #1c2b33"
          >Performance goal</label
        >
        <v-select
          v-model="performanceGoalSelected"
          :style="{
            cursor: disabledConversionLocation ? 'not-allowed' : 'pointer',
          }"
          :items="performanceGoal"
          density="compact"
          variant="outlined"
          return-object
          item-value="value"
          item-title="title"
          hide-details
          :disabled="disabledConversionLocation"
          :menu-props="{ maxWidth: '596px', minWidth: '100%' }"
        >
          <template v-slot:selection="{ item }">
            {{ item?.title || "" }}
          </template>
          <template v-slot:item="{ item, index }">
            <div v-if="index === 0">
              <p style="font-weight: bold; margin-bottom: 8px">
                Engagement goals
              </p>
            </div>
            <div v-if="index === 3">
              <p style="font-weight: bold; margin-top: 16px">Other goals</p>
            </div>
            <v-list-item @click="selectItemWinner(item)">
              <div class="my-form">
                <div class="ga-3">
                  <input
                    :checked="
                      performanceGoalSelected &&
                      performanceGoalSelected.id === item.raw.id
                    "
                    :id="`${item.raw.id}_conversion_app_goal`"
                    type="radio"
                    name="option"
                    :value="item"
                  />
                  <div class="d-flex flex-column ga-1">
                    <label
                      :for="`${item.raw.id}_conversion_app_goal`"
                      class="d-block"
                      style="font-size: 14px"
                      >{{ item.raw.title }}</label
                    >
                    <label
                      :for="`${item.raw.id}_conversion_app_goal`"
                      style="font-size: 12px; color: #1c2b33"
                    >
                      {{ item.raw.descriptions }}
                    </label>
                  </div>
                </div>
              </div>
            </v-list-item>
          </template>
        </v-select>
        <p
          v-if="disabledConversionLocation"
          style="font-size: 12px; color: #0000009d"
        >
          We'll try to deliver your ads to try to get you the most possible
          calls and report the number of times that the call button in the call
          confirmation dialogue is clicked.
        </p>
      </div>
      <SelectFacebookPage v-if="disabledConversionLocation" />
      <BidSterategy v-if="disabledConversionLocation" />
      <DataSet v-if="website_contant" />
      <ConversionEvent v-if="website_contant" />
      <MobileApp v-if="app_contant" />
      <div v-if="!disabledConversionLocation" class="cost-per-result my-3">
        <label for="" class="d-block title roboto-regular"
          >Cost per result goal∙ Optional
        </label>
        <div class="input-container">
          <input
            type="number"
            placeholder="$X.XX"
            class="no-controls"
            name=""
            id=""
          />
          <span>USD</span>
        </div>
        <p class="description-item">
          Meta will aim to spend your entire budget and get the most link clicks
          using the highest-volume bid strategy. If keeping the average cost per
          result around a certain amount is important, enter a cost per result
          goal.
        </p>
      </div>
      <FrequencyControl v-if="on_your_ad" />
      <v-divider class="my-4"></v-divider>
      <div class="card-footer">
        <button @click="showMore = !showMore" class="mb-2">
          <div class="d-flex align-center" v-if="!showMore">
            <span class="d-flex">Show more options</span>
            <span class="d-flex" v-html="arrowDown"></span>
          </div>
          <div class="d-flex align-center" v-else>
            <span class="d-flex">Hide options</span>
            <span class="d-flex" v-html="arrowTop"></span>
          </div>
        </button>
        <div class="card-footer-contant">
          <div class="show-more-options-container" v-if="showMore">
            <section class="mb-4">
              <WhenCharged v-if="on_your_ad" :chargedItems="chargedItems_1" />
            </section>
            <section>
              <WhenCharged
                v-if="facebook_page_contant"
                :chargedItems="chargedItems_2"
              />
            </section>
            <section class="mb-4" v-if="website_contant">
              <AttributionSetting />
            </section>
            <section class="delivery-type-container">
              <DeliveryType />
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessagingApps from "./conversion/MessagingApps.vue";
import EngagementType from "./conversion/EngagementType.vue";
import FrequencyControl from "@/components/meta/secound-page-componet/awareness/FrequencyControl.vue";
import SelectFacebookPage from "./conversion/SelectFacebookPage.vue";
import ConversionEvent from "./conversion/ConversionEvent.vue";
import { conversionDate } from "@/store/meta/conversionDate/conversions";
import { mapState, mapActions } from "pinia";
import BidSterategy from "./conversion/BidSterategy.vue";
import DataSet from "./conversion/DataSet.vue";
import MobileApp from "./conversion/MobileApp.vue";
import WhenCharged from "./conversion/WhenCharged.vue";
import DeliveryType from "./conversion/DeliveryType.vue";
import AttributionSetting from "./conversion/AttributionSetting.vue";
export default {
  components: {
    MessagingApps,
    EngagementType,
    FrequencyControl,
    SelectFacebookPage,
    BidSterategy,
    DataSet,
    ConversionEvent,
    MobileApp,
    WhenCharged,
    DeliveryType,
    AttributionSetting,
  },
  data() {
    return {
      store: conversionDate(),
      arrowDown: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#0a78be"><path d="M480-384 288-576h384L480-384Z"/></svg>`,
      arrowTop: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#0a78be"><path d="m288-384 192-192 192 192H288Z"/></svg>`,
      showMore: false,
      chargedItems_1: [
        {
          id: 0,
          title: "Impressions",
          value: "IMPRESSIONS",
        },
        {
          id: 1,
          title: "ThruPlay",
          value: "THRUPLAY",
        },
      ],
      chargedItems_2: [
        {
          id: 0,
          title: "Impressions",
          value: "IMPRESSIONS",
        },
        {
          id: 1,
          title: "Page like",
          value: "PAGE_LIKES",
        },
      ],
    };
  },
  computed: {
    ...mapState(conversionDate, [
      "conversionLocationArray",
      "conversionLocationSelected",
      "performanceGoalSelected",
      "performanceGoal",
      "messageAppGoal",
      "onYourAdGoal",
      "on_your_ad",
      "message_app",
      "disabledConversionLocation",
      "website_contant",
      "app_contant",
      "facebook_page_contant",
    ]),
  },
  methods: {
    ...mapActions(conversionDate, [
      "defaultPerformanceGoal",
      "driveEngagement",
      "selectItemWinner",
    ]),
  },
  mounted() {
    this.defaultPerformanceGoal();
  },
};
</script>
