<template>
  <Layout :linksData="linksData">
    <v-row class="leads-managements w-100">
      <v-col cols="12" :lg="6" class="header-line-title"> Accounts </v-col>
      <v-col cols="12" :lg="6" class="d-flex align-center justify-end ga-2">
        <Top :type="'Accounts'" :list="accounts" />
      </v-col>
    </v-row>
    <FilterSection
      :selected_rows="selected_accounts"
      :menu="['Project', 'Event', 'Deals', 'Task']"
      :assign_to_account_menu="false"
      @update:selected_rows="selected_accounts = $event"
      :parent="'accounts'"
      :dashboard_path_name="'AccountsDashboard'"
      @update:search="updateSearch"
      :store="store"
    />
    <div class="table mt-3">
      <DataTable
        @update:selected_accounts="selected_accounts = $event"
        :accounts="accounts"
      />
    </div>
  </Layout>
</template>
<script>
import Layout from "@/components/crm/layouts/Layout.vue";
import activityLogIcon from "@/assets/images/icons/activity-log.svg";
import discussionsIcon from "@/assets/images/icons/discussions.svg";
import importIcon from "@/assets/images/icons/import.svg";
import FilterSection from "@/components/crm/assets/Filter.vue";
import Top from "@/components/crm/assets/Top.vue";
import DataTable from "@/components/crm/accounts/table.vue";
import "@/assets/css/crm/crm-moduls.css";
import { useAccountsStore } from "@/store/crm/useAccountsStore";

export default {
  components: {
    Layout,
    FilterSection,
    Top,
    DataTable,
  },

  data() {
    return {
      selected_accounts: [],
      activityLogIcon,
      discussionsIcon,
      importIcon,
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Accounts Management", link: "" },
      ],
      store: null,
    };
  },
  methods: {
    updateSearch(data) {
      this.store.search = data;
      this.store.getAllAccounts();
    },
  },
  computed: {
    accounts() {
      return this.store?.getAccounts;
    },
  },
  created() {
    this.store = useAccountsStore();
    if (this.store?.getAccounts.length === 0) {
      this.store?.getAllAccounts();
    }
  },
};
</script>
