<script>
import "emoji-picker-element";
import Image from "@/components/calender/CommentImage.vue";

export default {
  name: "CommentInput",
  components: { Image },

  data() {
    return {
      postContent: "",
      postHTML: "",
      showEmojiPicker: false,
      enterPostlink: false,
      newLinkName: "",
      newLinkHref: "",
      links: [],
      images: [],
      allMediaFils: [],
    };
  },

  methods: {
    boldSelection() {
      const textarea = this.$refs.textarea;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const selectedText = this.postContent.substring(start, end);

      if (selectedText) {
        const boldText = `**${selectedText}**`;
        this.postContent =
          this.postContent.substring(0, start) +
          boldText +
          this.postContent.substring(end);

        // Log HTML representation
        this.postHTML = this.postContent.replace(
          /\*\*(.*?)\*\*/g,
          "<strong>$1</strong>"
        );

        this.$nextTick(() => {
          textarea.selectionStart = start;
          textarea.selectionEnd = start + boldText.length;
          textarea.focus();
        });
      }
    },

    italicSelection() {
      const textarea = this.$refs.textarea;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const selectedText = this.postContent.substring(start, end);

      if (selectedText) {
        const italicText = `_${selectedText}_`;
        this.postContent =
          this.postContent.substring(0, start) +
          italicText +
          this.postContent.substring(end);

        // Log HTML representation
        this.postHTML = this.postContent.replace(/_(.*?)_/g, "<em>$1</em>");

        this.$nextTick(() => {
          textarea.selectionStart = start;
          textarea.selectionEnd = start + italicText.length;
          textarea.focus();
        });
      }
    },

    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },

    onEmojiClick(event) {
      const emoji = event.detail.unicode;
      const textarea = this.$refs.textarea;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      this.postContent =
        this.postContent.substring(0, start) +
        emoji +
        this.postContent.substring(end);

      this.$nextTick(() => {
        textarea.selectionStart = textarea.selectionEnd = start + emoji.length;
        textarea.focus();
      });

      this.showEmojiPicker = false;
    },

    addLink() {
      if (this.newLinkName && this.newLinkHref) {
        this.links.push({
          name: this.newLinkName,
          href: this.newLinkHref,
        });
        this.newLinkName = "";
        this.newLinkHref = "";
        this.enterPostlink = false;
      }
    },

    removeLink(index) {
      this.links.splice(index, 1);
    },

    removeImage(index, array) {
      array.splice(index, 1);
    },

    handleFileSelected(res, typeOfSocilaMedia) {
      switch (typeOfSocilaMedia) {
        case "allPostContent":
          this.allMediaFils.push(res[0]);
          break;
      }
    },
    SendComment() {
      if (
        this.postContent.trim() === "" &&
        this.allMediaFils.length === 0 &&
        this.links.length === 0
      )
        return;
      this.$emit("comment-added", {
        comment: this.postContent
          .replace(/_(.*?)_/g, "<em>$1</em>")
          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>"),
        attachments: this.allMediaFils,
        links: this.links,
      });
      this.postContent = "";
      this.allMediaFils = [];
      this.links = [];
    },
  },

  watch: {
    postContent: {
      handler(newValue) {
        // Log HTML representation whenever content changes
        const htmlContent = newValue
          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
          .replace(/_(.*?)_/g, "<em>$1</em>");
        console.log("HTML Content:", htmlContent);
      },
      deep: true,
    },
  },
};
</script>

<template>
  <div class="new-comment comment-input">
    <div class="card position-relative">
      <textarea
        placeholder="Write your comment..."
        rows="2"
        maxlength="2500"
        v-model="postContent"
        ref="textarea"
        class="comment-textarea"
        id="myTextarea"
      ></textarea>
      <!-- Image Preview -->
      <div v-if="images.length" class="image-preview-overlay">
        <div
          v-for="(image, index) in images"
          :key="index"
          class="image-preview-container"
        >
          <img :src="image.preview" alt="Preview" />
          <button class="remove-image" @click="removeImage(index)">×</button>
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="textarea-actions">
        <div class="btn-groups">
          <button
            class="btn-post d-flex align-center"
            @click="toggleEmojiPicker"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8284 11.8284C11.457 12.1999 11.0161 12.4945 10.5307 12.6955C10.0454 12.8966 9.52529 13 9 13C8.47471 13 7.95456 12.8966 7.46926 12.6955C6.98395 12.4945 6.54299 12.1999 6.17156 11.8284M17 9C17 10.0506 16.7931 11.0909 16.391 12.0615C15.989 13.0321 15.3997 13.914 14.6569 14.6569C13.914 15.3997 13.0321 15.989 12.0615 16.391C11.0909 16.7931 10.0506 17 9 17C7.94943 17 6.90914 16.7931 5.93853 16.391C4.96793 15.989 4.08601 15.3997 3.34315 14.6569C2.60028 13.914 2.011 13.0321 1.60896 12.0615C1.20693 11.0909 1 10.0506 1 9C1 6.87827 1.84285 4.84344 3.34315 3.34315C4.84344 1.84285 6.87827 1 9 1C11.1217 1 13.1566 1.84285 14.6569 3.34315C16.1571 4.84344 17 6.87827 17 9ZM7 7C7 7.368 6.85067 7.66667 6.66667 7.66667C6.48267 7.66667 6.33333 7.368 6.33333 7C6.33333 6.632 6.48267 6.33333 6.66667 6.33333C6.85067 6.33333 7 6.632 7 7ZM6.66667 7H6.67378V7.01333H6.66667V7ZM11.6667 7C11.6667 7.368 11.5173 7.66667 11.3333 7.66667C11.1493 7.66667 11 7.368 11 7C11 6.632 11.1493 6.33333 11.3333 6.33333C11.5173 6.33333 11.6667 6.632 11.6667 7ZM11.3333 7H11.3404V7.01333H11.3333V7Z"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button @click="boldSelection()" class="btn-post d-flex align-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 3.33301H11.6667C12.5507 3.33301 13.3986 3.6842 14.0237 4.30932C14.6488 4.93444 15 5.78229 15 6.66634C15 7.5504 14.6488 8.39824 14.0237 9.02336C13.3986 9.64849 12.5507 9.99967 11.6667 9.99967H5V3.33301Z"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 10H12.5C13.3841 10 14.2319 10.3512 14.857 10.9763C15.4821 11.6014 15.8333 12.4493 15.8333 13.3333C15.8333 14.2174 15.4821 15.0652 14.857 15.6904C14.2319 16.3155 13.3841 16.6667 12.5 16.6667H5V10Z"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            @click="italicSelection()"
            class="btn-post d-flex align-center"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.834 3.33301H8.33398"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.666 16.667H4.16602"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5 3.33301L7.5 16.6663"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <div class="img-button d-flex align-center justify-center">
            <Image
              @fileSelected="(res) => handleFileSelected(res, 'allPostContent')"
              @driveSelect="handleDriveSelect"
              @editImage="handleEditImage"
            />
          </div>
          <button
            class="btn-post d-flex align-center"
            @click="enterPostlink = true"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49935 14.1663H5.83268C4.72761 14.1663 3.66781 13.7274 2.8864 12.946C2.105 12.1646 1.66602 11.1047 1.66602 9.99967C1.66602 8.89461 2.105 7.8348 2.8864 7.0534C3.66781 6.27199 4.72761 5.83301 5.83268 5.83301H7.49935"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5 5.83301H14.1667C15.2717 5.83301 16.3315 6.27199 17.1129 7.0534C17.8943 7.8348 18.3333 8.89461 18.3333 9.99967C18.3333 11.1047 17.8943 12.1646 17.1129 12.946C16.3315 13.7274 15.2717 14.1663 14.1667 14.1663H12.5"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.66602 10H13.3327"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div class="send-button">
          <button
            :disabled="
              this.postContent.trim() === '' &&
              this.allMediaFils.length === 0 &&
              this.links.length === 0
            "
            @click="SendComment"
            class=""
          >
            Send
          </button>
        </div>
      </div>
      <div v-if="allMediaFils" class="image-preview-overlay">
        <div
          v-for="(media, index) in allMediaFils"
          :key="index"
          class="image-preview-container"
        >
          <img :src="media.preview" alt="Preview" />
          <button
            class="remove-image"
            @click="removeImage(index, allMediaFils)"
          >
            ×
          </button>
        </div>
      </div>
      <!-- Link Dialog -->
      <div v-if="enterPostlink" class="link-dialog">
        <div class="link-dialog-content">
          <h3>Add Link</h3>
          <input
            v-model="newLinkName"
            placeholder="Enter link name"
            type="text"
            class="link-input"
          />
          <input
            v-model="newLinkHref"
            placeholder="Enter link URL"
            type="url"
            class="link-input"
          />
          <div class="link-actions">
            <button @click="addLink" class="btn-primary">Add</button>
            <button @click="enterPostlink = false" class="btn-secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>

      <!-- Links List -->
      <div v-if="links.length" class="links-list">
        <div v-for="(link, index) in links" :key="index" class="link-item">
          <a :href="link.href" target="_blank" rel="noopener noreferrer">
            {{ link.name }}
          </a>
          <button @click="removeLink(index)" class="remove-link">×</button>
        </div>
      </div>

      <!-- Emoji Picker -->
      <div v-if="showEmojiPicker" class="emoji-picker-wrapper">
        <emoji-picker @emoji-click="onEmojiClick"></emoji-picker>
      </div>
    </div>
    <span
      v-if="postContent || allMediaFils.length || links.length"
      class="small-text"
      >You Should Send a Comment</span
    >
  </div>
</template>

<style scoped>
.comment-input {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.create-comments .new-comment .card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #1f666a;
  padding: 12px 11px;
}

.comment-textarea {
  width: 100%;
  min-height: 30px;
  padding: 0.75rem;
  border-radius: 6px;
  resize: none;
  outline: none;
  transition: border-color 0.2s;
  border-bottom: 1px solid #0000001a;
}

.textarea-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.75rem;
}

.btn-groups {
  display: flex;
  gap: 0.5rem;
}

.btn-post {
  padding: 0.5rem;
  border: none;
  background: transparent;
  color: #666;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.btn-post:hover {
  background-color: #f3f4f6;
}

.emoji-picker-wrapper {
  /* position: absolute; */
  bottom: 100%;
  left: 0;
  margin-bottom: 0.5rem;
  z-index: 1000;
}

emoji-picker {
  --num-columns: 8;
  width: 320px;
  height: 400px;
}

.link-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.link-dialog-content {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
}

.link-input {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
}

.link-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.btn-primary {
  background: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-secondary {
  background: #e5e7eb;
  color: #374151;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.links-list {
  margin-top: 1rem;
}

.link-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background: #f3f4f6;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.remove-link {
  background: none;
  border: none;
  color: #1a1717;
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0 0.5rem;
}

.image-preview-overlay {
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.image-preview-container {
  position: relative;
}

.image-preview-container img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ef4444;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
}
.img-button button {
  background-color: unset;
}
.img-button:hover {
  background-color: #f3f4f6;
}
.send-button button {
  background-color: #237276;
  color: white;
  width: 51px;
  height: 32px;
  border-radius: 8px;
}
.send-button button:hover {
  background-color: #1a5457;
}
</style>
