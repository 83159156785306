<template>
  <v-col :cols="12">
    <div class="d-flex align-center ga-2">
      <v-switch
        v-model="store.add_to_social_media"
        hide-details
        inset
        :true-value="1"
        :false-value="0"
        color="rgba(35, 114, 118, 1)"
      ></v-switch>
      <div class="bold-text mb-2">Set post to social media</div>
    </div>
  </v-col>
  <v-col v-if="store.add_to_social_media" :cols="12" :lg="9">
    <div class="bold-text mb-2">
      Set your post to <span class="text-red-accent-4">*</span>
    </div>
    <v-radio-group v-model="store.type" row>
      <v-radio
        color="rgba(35, 114, 118, 1)"
        label="Organic"
        value="organic"
      ></v-radio>
      <v-radio
        v-if="store.type !== 'sponsored'"
        color="rgba(35, 114, 118, 1)"
        label="Sponsored"
        value="sponsored"
      ></v-radio>
    </v-radio-group>

    <div v-if="store.type === 'sponsored'" class="sponsored-details">
      <v-radio-group v-model="store.type">
        <v-radio
          color="rgba(35, 114, 118, 1)"
          label="Sponsored"
          value="sponsored"
        ></v-radio>
      </v-radio-group>

      <div class="body p-3">
        <div class="d-lg-flex d-md-block justify-space-between mb-2">
          <div class="d-flex ga-2 align-center">
            <div class="bold-text muted-text">Budget</div>
            <v-radio-group class="row-radio-group" v-model="store.budget_type">
              <v-radio
                label="Daily"
                color="rgba(35, 114, 118, 1)"
                value="daily"
              ></v-radio>
              <v-radio
                label="Total"
                color="rgba(35, 114, 118, 1)"
                value="total"
              ></v-radio>
            </v-radio-group>
          </div>
          <div class="d-flex ga-2 align-center">
            <div class="bold-text muted-text">Budget</div>
            <input
              v-model="store.budget"
              type="number"
              class="calender-input w-100"
              placeholder="USD"
            />
          </div>
        </div>

        <div class="d-lg-flex d-md-block justify-space-between">
          <div class="d-flex ga-2 align-center">
            <div class="bold-text muted-text">Duration</div>
            <input
              v-model="store.duration"
              type="number"
              class="calender-input w-100"
              placeholder="Days"
            />
          </div>
          <v-switch
            v-model="store.setEndDate"
            hide-details
            inset
            color="rgba(35, 114, 118, 1)"
          ></v-switch>
          <div class="d-flex ga-2 align-center task-calendar-date-input">
            <div class="bold-text muted-text">End Date</div>
            <v-date-input
              :disabled="!store.setEndDate"
              v-model="store.sponsor_end_date"
              prepend-icon=""
              variant="solo"
              hide-details
              keyboard-date-time-24h
              :model-type="'iso'"
              :model-format="'yyyy-MM-dd HH:mm:ss'"
            ></v-date-input>
          </div>
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";
import "flatpickr/dist/flatpickr.css";
import { VDateInput } from "vuetify/labs/VDateInput";

export default {
  name: "Tasktype",
  components: {
    VDateInput,
  },
  data() {
    return {
      store: useTaskStore(),
    };
  },
};
</script>
