<template>
  <div class="boost-post-card">
    <div class="card-header mb-4">
      <label class="title d-block"> {{ $t("goal") }} </label>
      <p class="sub-title">{{ $t("what_results") }}</p>
    </div>
    <div class="card-body">
      <label class="title mb-1 d-block">
        {{ $t("automatic_more_messages") }}
      </label>
      <div class="get-more-message d-flex align-start justify-space-between">
        <p>{{ $t("automatic_desc") }}</p>
        <button @click="dialog = true">{{ $t("change") }}</button>
        <v-dialog transition="dialog-top-transition" v-model="dialog" width="819px">
          <v-card max-width="819px">
            <v-card-title class="pa-4">
              <p>
                {{ $t("goal") }}
                <span style="cursor: pointer" @click="dialog = false" v-html="iconSvg"></span>
              </p>
            </v-card-title>

            <v-card-text class="py-2 px-5">
              <h1 class="mb-4">{{ $t("what_results") }}</h1>
              <div v-for="(item, i) in store.metaGoalDate" :key="i" class="d-flex align-center mb-3 ga-2 goals-select">
                <img style="width: 60px; height: 60px; border-radius: 50%" :src="item.icon" alt="" />
                <div class="d-flex align-start w-100 justify-space-between goal-item">
                  <label :for="`${item.id}_goal`" class="d-block" @click="sendObjectiveToLocal(item.value)">
                    <p class="mb-1 title">{{ item.title }}</p>
                    <p class="sub-title">{{ item.description }}</p>
                    <p class="good-for">
                      {{ $t("good_for") }} <span>{{ item.goodFor }}</span>
                    </p>
                  </label>
                  <div class="my-form">
                    <div>
                      <input :checked="item.checked" :id="`${item.id}_goal`" type="radio" name="gooalType" value="item" />
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>

            <template v-slot:actions>
              <v-btn class="ms-auto" bg-color="primary" text="Save" @click="dialog = false">
                {{ $t("save") }}
              </v-btn>
            </template>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>


<script>
import { boostPost } from "@/store/publishPost/boostPost.js";
export default {
  data() {
    return {
      dialog: false,
      iconSvg: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="rgba(102, 102, 102, 1)"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>`,
      store: boostPost(),
    };
  },
  methods:{
    sendObjectiveToLocal(value){
      localStorage.setItem("selected_objective_boost_post", value);
    }
  }
};
</script>

<style scoped>
.v-card-title p {
  font-size: 24px;
  font-weight: 700;
  color: rgba(13, 13, 13, 1);
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid rgba(236, 236, 236, 1);
  text-align: center;
  position: relative;
  padding: 0 0 15px 0;
}
.v-card-title p span {
  position: absolute;
  right: 0;
  bottom: 8px;
}
.v-card-text h1 {
  font-size: 18px;
  font-weight: 600;
  color: rgba(34, 34, 34, 1);
}
.goals-select .goal-item label {
  line-height: normal;
}
.goals-select .goal-item label .title {
  font-size: 18px;
  font-weight: 600;
  color: rgba(13, 13, 13, 1);
}
.goals-select .goal-item label .sub-title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(77, 77, 77, 1);
  margin-bottom: 6px;
}
.goals-select .goal-item .good-for {
  font-size: 14px;
  color: #4d4d4d;
}
.goals-select .goal-item .good-for span{
  background-color:  #4D4D4D;
  font-size: 14px;
  color: #fff;
  border-radius: 8px;
  padding: 4px 8px;
  margin-left: 8px;
}
</style>
