<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="store.dialog"
    width="819px"
  >
    <v-card max-width="819px">
      <v-card-title class="pa-4">
        <p class="">
          Connect WhatsApp
          <span
            style="cursor: pointer"
            @click="store.dialog = false"
            v-html="iconSvg"
          ></span>
        </p>
      </v-card-title>

      <v-card-text class="py-2 px-5">
        <h1 class="mb-4">
          To create an ad where people can message you directly from your post,
          connect your WhatsApp number.
        </h1>
        <div class="d-flex align-center ga-2">
          <v-select
            class="w-25"
            v-model="store.countryCodeNumberSelected"
            :items="store.countryCodeNumber"
            variant="outlined"
            return-object
            item-value="id"
            item-title="title"
            hide-details
          >
            <template v-slot:selection="{ item }">
              <p style="font-weight: 600; color: #000; font-size: 16px">
                {{ item?.title || "" }}
              </p>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item @click="store.selectWhatsAppNumber(item)">
                <div class="my-form">
                  <div>
                    <input
                      :checked="
                        store.countryCodeNumberSelected &&
                        store.countryCodeNumberSelected.id === item.raw.id
                      "
                      :id="`${item.id}_number`"
                      type="radio"
                      name="selctedNumber"
                      :value="item"
                    />
                    <label :for="`${item.id}_number`">{{ item.title }}</label>
                  </div>
                </div>
              </v-list-item>
            </template>
          </v-select>
          <v-text-field
            class="w-75"
            variant="outline"
            hide-details="auto"
            label="whatsApp phone number"
          ></v-text-field>
        </div>
      </v-card-text>

      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          elevated=""
          bg-color="primary"
          text="Send code"
          @click="store.dialog = false"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { boostPost } from "@/store/publishPost/boostPost";
export default {
  data() {
    return {
      store: boostPost(),
    };
  },
};
</script>

<style scoped>
.v-card-text .v-input {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: #e6e6e6;
}
.v-card-title p {
  font-size: 24px;
  font-weight: 700;
  color: rgba(13, 13, 13, 1);
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid rgba(236, 236, 236, 1);
  text-align: center;
  position: relative;
  padding: 0 0 15px 0;
}
.v-card-title p span {
  position: absolute;
  right: 0;
  bottom: 8px;
}
.v-card-text h1 {
  font-size: 14px;
  font-weight: 600;
  color: #4d4d4d;
}
</style>
