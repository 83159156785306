<template>
  <div class="single-comment d-flex flex-column justify-space-between ga-3">
    <div class="header w-100 d-flex align-center justify-space-between">
      <div class="user-data d-flex align-center ga-2">
        <img
          class="circle-img"
          :src="comment.user.avatar"
          :alt="comment.user.name"
        />
        
        <span class="name">{{ comment.user.name }}</span>
      </div>
      <div class="time">{{ comment.time }}</div>
    </div>
    <div class="body" v-html="comment.content"></div>
    <div class="body">
      <v-row>
        <v-col
          v-for="img in comment.attachments"
          :key="img"
          class="d-flex child-flex"
          cols="4"
        >
          <v-img
            @click="showImage(img.preview)"
            :lazy-src="img.preview"
            :src="img.preview"
            aspect-ratio="1"
            class="bg-grey-lighten-2 rounded cursor-pointer"
            cover
          >
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular
                  color="grey-lighten-5"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
      <div class="mt-3" v-for="link in comment.links" :key="link">
        <a :href="link.href"> {{ link.name }}</a>
      </div>
    </div>
    <div class="footer d-flex align-center justify-space-between">
      <div class="reactions">
        <svg
          width="100%"
          height="16"
          viewBox="0 0 100% 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 9.27601C16 8.74341 15.7572 8.23301 15.3821 7.90014C15.6469 7.54508 15.7572 7.10125 15.7131 6.65742C15.6248 5.72538 14.7862 4.97087 13.8152 4.97087H10.9462L11.1228 3.59501C11.1669 3.17337 11.1669 2.81831 11.0786 2.46325C10.7476 1.0208 9.46759 0 7.98897 0C7.65793 0 7.3269 0.133148 7.08414 0.377254C6.84138 0.621359 6.70897 0.932039 6.70897 1.26491V3.0846C6.70897 3.8835 6.48828 4.638 6.06897 5.32594L5.29655 6.56865C5.18621 6.74619 5.03172 6.87933 4.85517 6.9681C4.70069 6.47989 4.25931 6.12483 3.72966 6.12483H1.19172C0.529655 6.12483 0 6.65742 0 7.32316V14.8017C0 15.4674 0.529655 16 1.19172 16H3.77379C4.43586 16 4.96552 15.4674 4.96552 14.8017V14.6241C5.53931 15.1345 6.28966 15.4452 7.10621 15.4452H12.7117C13.2193 15.4452 13.7269 15.2233 14.08 14.8682C14.4331 14.5132 14.6097 14.0472 14.5876 13.5589C14.5876 13.3814 14.5655 13.2261 14.4993 13.0485C15.0952 12.7379 15.4924 12.1165 15.4924 11.4064C15.4924 11.1623 15.4483 10.9182 15.36 10.6963C15.7572 10.319 16 9.8086 16 9.27601Z"
            fill="#999999"
          />
        </svg>
      </div>
      <div class="reply">Reply</div>
    </div>
  </div>
  <v-overlay v-model="dialog" location="center" />
  <v-dialog v-model="dialog" width="auto">
    <v-card max-width="auto">
      <template v-slot:text>
        <img width="100%" :src="image_preview" alt="" />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TaskComment",
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image_preview: "",
      dialog: false,
    };
  },
  methods: {
    showImage(url) {
      this.image_preview = url;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.single-comment {
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.name {
  font-weight: 500;
}

.time {
  color: #666;
  font-size: 0.9rem;
}

.body {
  color: #333;
  line-height: 1.5;
}

.reply {
  color: #237276;
  cursor: pointer;
  font-weight: 500;
}

.circle-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
</style>
