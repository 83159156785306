<template>
  <div class="single-question">
    <MultipleChoice
      v-if="question.type === 'MULTIPLE_CHOICE_SINGLE_SELECTION'"
      :question="question"
      :index="index"
    />
    <MultipleSelection
      v-else-if="question.type === 'MULTIPLE_CHOICE_MULTI_SELECTION'"
      :question="question"
      :index="index"
    />
    <Datepicker
      v-else-if="question.type === 'DATE'"
      :question="question"
      :index="index"
    />
    <ShortAnswer
      v-else-if="question.type === 'TEXT'"
      :question="question"
      :index="index"
    />
  </div>
</template>
<script>
import { useLeadFormStore } from "@/store/snapchat/LeadForm";
import MultipleChoice from "./questions/MultipleChoice.vue";
import MultipleSelection from "./questions/MultipleSelection.vue";
import Datepicker from "./questions/DatePicker.vue";
import ShortAnswer from "./questions/ShortAnswer.vue";
export default {
  components: {
    MultipleChoice,
    MultipleSelection,
    Datepicker,
    ShortAnswer,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      store: useLeadFormStore(),
    };
  },
};
</script>
<style scoped>
input {
  border: 2px solid transparent;
  border-radius: 8px !important;
  background: #f9f9fa;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0 0 0 0 transparent;
  box-sizing: border-box;
  font-family: inherit;
  transition: box-shadow 0.15s ease-out 0.15s, border-color 0.15s,
    background 0.15s ease-out 0.15s, color 0.15s ease-out;
  width: 100%;
  border: transparent 2px solid;
  height: 40px;
}
</style>