import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const DriveStore = defineStore("DriveStore", {
  state: () => ({
    Drive: [],
    headers: [
      {
        title: "Total",
        name: "Total",
        align: "start",
        key: "total",
        sortable: true,
      },
      {
        title: "Uploaded By",
        name: "Uploaded By",
        align: "start",
        key: "created_by",
        sortable: true,
      },
      {
        title: "For Task",
        name: "For Task",
        align: "start",
        key: "for_task",
      },
      {
        title: "Date",
        name: "Date",
        align: "start",
        key: "createdTime",
      },
      {
        title: "Assignees",
        name: "Assignees",
        align: "start",
        key: "assignees",
      },
      {
        title: "Options",
        name: "Options",
        align: "center",
        key: "options",
      },
    ],
    items_per_page: 5,
    current_page: 1,
    total_drive: 0,
    total_pages: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    users: [
      {
        name: "Sandra Adams",
        group: "Group 1",
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Ali Connors",
        group: "Group 1",
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      },
      {
        name: "Trevor Hansen",
        group: "Group 1",
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Tucker Smith",
        group: "Group 1",
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      },
      {
        name: "Britta Holt",
        group: "Group 2",
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Jane Smith ",
        group: "Group 2",
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      },
      {
        name: "John Smith",
        group: "Group 2",
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Sandra Williams",
        group: "Group 2",
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      },
    ],
    filter: "all",
    assignees: [
      {
        name: "Mohamed Ahmed",
        img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Mohamed Ahmed",
        img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Mohamed Ahmed",
        img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Mohamed Ahmed",
        img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Mohamed Ahmed",
        img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Mohamed Ahmed",
        img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Mohamed Ahmed",
        img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Mohamed Ahmed",
        img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Mohamed Ahmed",
        img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
      {
        name: "Mohamed Ahmed",
        img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      },
    ],
    search_text: "",
    loading: true,
  }),
  getters: {
    getDrive: (state) => state.Drive,
    getHeaders: (state) => state.headers,

    getFolders: (state) => {
      return state.Drive.length > 0
        ? state.Drive.filter((item) => item.file_type === "folder")
        : [];
    },
    getImages: (state) => {
      return state.Drive.length > 0
        ? state.Drive.filter((item) => item.mimeType?.includes("image/"))
        : [];
    },
    getVideos: (state) => {
      return state.Drive.length > 0
        ? state.Drive.filter((item) => item.mimeType?.includes("video/"))
        : [];
    },
    getPagination: (state) => ({
      currentPage: state.current_page,
      totalPages: state.total_pages,
      itemsPerPage: state.items_per_page,
      totalDrive: state.total_drive,
    }),
    isLoading: (state) => state.loading,
    getUsers: (state) => state.users,
  },
  actions: {
    async getAllDrive() {
      this.loading = true;
      try {
        apiClient
          .get(
            `/my-drive/list-files?page=${this.current_page}&per_page=${this.items_per_page}`,
            {
              params: {
                filter_by: this.filter === "all" ? null : this.filter,
                search: this.search_text,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            this.Drive = response.data.data.data;
            this.current_page = response.data.data.pagination.current_page;
            this.items_per_page = response.data.data.pagination.per_page;
            this.total_drive = response.data.data.pagination.total_items;
            this.total_pages = response.data.data.pagination.total_pages;
            this.hasNextPage =
              response.data.data.pagination.next_page_url !== "" ? true : false;
            this.hasPreviousPage =
              response.data.data.pagination.prev_page_url !== "" ? true : false;
          })
          .catch((error) => {
            this.loading = false;
          });
      } catch (err) {
        console.error("Error fetching Drive:", err);
      }
    },
    async getAllUsers() {
      try {
        apiClient
          .get("/leads-managment/all-agents")
          .then((response) => {
            this.users = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        console.error("Error fetching Drive:", err);
      }
    },
    updateItemName(id, name) {
      let item = this.Drive.find((item) => item.file_id === id);

      if (item) {
        item.file_name = name;
      }
    },

    deleteItem(id) {
      this.Drive = this.Drive.filter((item) => item.file_id !== id);
    },
    onPageChange() {
      return this.getAllDrive();
    },
    onItemsPerPageChange() {
      this.current_page = 1;
      return this.getAllDrive();
    },
    previousPage() {
      this.current_page -= 1;
      return this.getAllDrive();
    },
    nextPage() {
      this.current_page += 1;
      return this.getAllDrive();
    },
  },
});
