<template>
  <div class="conversion-event">
    <label
      for=""
      class="d-block mb-1 roboto-bold"
      style="font-size: 14px; color: #1c2b33"
      >Conversion event</label
    >
    <v-select
      v-model="conversionEventSelected"
      :items="conversionEventArr"
      density="compact"
      variant="outlined"
      return-object
      item-value="value"
      item-title="title"
      hide-details
      placeholder="Select an event"
    >
      <template v-slot:selection="{ item }">
        {{ item?.title || "" }}
      </template>
      <template v-slot:item="{ item, index }">
        <div v-if="index === 0">
          <p style="font-weight: bold; margin-bottom: 8px">Inactive datasets</p>
        </div>

        <v-list-item @click="!item.raw.disabled && selectedConversionEvent(item)">
          <div
            class="my-form"
            :style="{ opacity: item.raw.disabled ? 0.5 : 1 }"
          >
            <div class="ga-3">
              <input
                :disabled="item.raw.disabled"
                :checked="
                  conversionEventSelected &&
                  conversionEventSelected.id === item.raw.id
                "
                :id="`${item.raw.id}event`"
                type="radio"
                name="event"
                :value="item"
              />
              <div class="d-flex flex-column ga-1">
                <label
                  :for="`${item.raw.id}event`"
                  class="d-block"
                  style="font-size: 14px"
                  >{{ item.raw.title }}</label
                >
              </div>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      conversionEventSelected: null,
      conversionEventArr: [
        {
          id: 0,
          title: "Add to wishlist",
          disabled: false,
        },
        {
          id: 1,
          title: "Contact",
          disabled: false,
        },
        {
          id: 2,
          title: "Customise product",
          disabled: false,
        },
        {
          id: 3,
          title: "Donate",
          disabled: false,
        },
        {
          id: 4,
          title: "Find location",
          disabled: false,
        },
        {
          id: 5,
          title: "Schedule",
          disabled: false,
        },
        {
          id: 6,
          title: "Search",
          disabled: false,
        },
        {
          id: 7,
          title: "Start trial",
          disabled: false,
        },
        {
          id: 8,
          title: "Submit application",
          disabled: false,
        },
        {
          id: 9,
          title: "Subscribe",
          disabled: false,
        },
        {
          id: 10,
          title: "View content",
          disabled: false,
        },

        {
          id: 12,
          title: "Add payment info",
          disabled: true,
        },
        {
          id: 13,
          title: "Add to cart",
          disabled: true,
        },
        {
          id: 14,
          title: "Complete registration",
          disabled: true,
        },
        {
          id: 15,
          title: "Initiate checkout",
          disabled: true,
        },
        {
          id: 16,
          title: "Lead",
          disabled: true,
        },
        {
          id: 17,
          title: "Purchase",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    selectedConversionEvent(item) {
      this.conversionEventSelected = item.raw;
    },
  },
};
</script>
