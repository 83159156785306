<template>
  <router-view></router-view>
</template>

<script>
import "@/assets/snapchatStyle/snapchatStyle.css";
import { useAwarenessStore } from "@/store/snapchat/awareness";
export default {
  data() {
    return {
      snapStore: useAwarenessStore(),
    };
  },
  components: {},
  mounted() {
    this.getIds();
    this.$router.push({ name: "SnapFirstStep" });
  },
  methods: {
    uploadedFile(file) {
      this.snapStore.uploadFile(file);
    },
    async getIds() {
      // await this.snapStore.getAllOrganizations();
      await this.snapStore.getUserProfileId();
      // await this.snapStore.getAllAccounts();
    },
    goTonextStep() {
      this.$router.push({ name: "SnapSecoundStep" });
    },
  },
};
</script>
