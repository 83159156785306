import SnapChat from "@/views/campains/snapchat/SnapChat.vue";
// Awareness Route
import SnapHomePage from "@/views/campains/snapchat/campaign/HomePage.vue";
import FirstStep from "@/views/campains/snapchat/campaign/steps/FirstStep.vue";
import SecoundStep from "@/views/campains/snapchat/campaign/steps/SecoundStep.vue";
import ThirdStep from "@/views/campains/snapchat/campaign/steps/ThirdStep.vue";
import getSnapChatToken from "@/views/campains/snapchat/getSnapChatToken.vue";
const snapchatRoutes = [
  {
    path: "/snapchat",
    name: "SnapChat",
    component: SnapChat,
    meta: {
      title: "Snapchat",
      description: "",
      auth: "auth",
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
  {
    path: "/snapchat/awareness-engagement",
    name: "awareness-engagement",
    component: SnapHomePage,
    meta: {
      title: "Snapchat Ads Manager",
      description: "",
    },
    children: [
      {
        path: "/snapchat/awareness-engagement/create-campaign",
        component: FirstStep,
        name: "SnapFirstStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
      {
        path: "/snapchat/awareness-engagement/ad-set-details",

        component: SecoundStep,
        name: "SnapSecoundStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
      {
        path: "/snapchat/awareness-engagement/third-step",
        component: ThirdStep,

        name: "SnapThirdStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
    ],
  },
  {
    path: "/snapchat/traffic",
    name: "traffic",
    component: SnapHomePage,
    meta: {
      title: "Snapchat Ads Manager",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
    children: [
      {
        path: "/snapchat/traffic/create-campaign",
        component: FirstStep,
        name: "TrafficFirstStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
      {
        path: "/snapchat/traffic/secound-step",
        component: SecoundStep,
        name: "TrafficSecoundStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
      {
        path: "/snapchat/traffic/third-step",
        component: ThirdStep,
        name: "TrafficThirdStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
    ],
  },
  {
    path: "/snapchat/leads",
    name: "Leads",
    component: SnapHomePage,
    meta: {
      title: "Snapchat Ads Manager",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
    children: [
      {
        path: "/snapchat/leads/create-campaign",
        component: FirstStep,
        name: "LeadsFirstStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
      {
        path: "/snapchat/leads/secound-step",
        component: SecoundStep,
        name: "LeadsSecoundStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
      {
        path: "/snapchat/leads/third-step",
        component: ThirdStep,
        name: "LeadsThirdStep",
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
    ],
  },
  {
    path: "/snapchat/app-promotion",
    name: "AppPromotion",
    component: SnapHomePage,
    meta: {
      title: "Snapchat Ads Manager",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
    children: [
      {
        path: "/snapchat/app-promotion/create-campaign",
        name: "appPromotionFirstStep",
        component: FirstStep,
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
      {
        path: "/snapchat/app-promotion/secound-step",
        name: "appPromotionSecoundStep",
        component: SecoundStep,
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
      {
        path: "/snapchat/app-promotion/third-step",
        name: "appPromotionThirdStep",
        component: ThirdStep,
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
    ],
  },
  {
    path: "/snapchat/sales",
    name: "Sales",
    component: SnapHomePage,
    meta: {
      title: "Snapchat Ads Manager",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
    children: [
      {
        path: "/snapchat/sales/create-campaign",
        name: "salesFirstStep",
        component: FirstStep,
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
      {
        path: "/snapchat/sales/secound-step",
        name: "salesSecoundStep",
        component: SecoundStep,
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
      {
        path: "/snapchat/sales/third-step",
        name: "salesThirdStep",
        component: ThirdStep,
        meta: {
          title: "Snapchat Ads Manager",
          description: "",
          
          permissions: [
            "campaign-ads.create",
            "campaign-ads.read",
            "campaign-ads.update",
            "campaign-ads.delete",
          ],
        },
      },
    ],
  },
  {
    path: "/snapchat/token",
    name: "SnapChatToken",
    component: getSnapChatToken,
    meta: {
      title: "SnapChatToken",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
];

export default snapchatRoutes;
