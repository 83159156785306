<template>
  <div class="message-app-selected">
    <h5>Apps</h5>
    <p>Where do you want people to message you?</p>
    <div class="message-app-selected-body mb-2">
      <div class="messanger-select mb-1">
        <div>
          <label for="instagram-checkbox" class="d-flex align-center ga-5">
            <img :src="messenger" alt="" />
            <p>
              <span>Messenger</span>
            </p>
          </label>
        </div>
        <v-checkbox
          v-model="inestagramChecked"
          id="instagram-checkbox"
          color="#237276"
        ></v-checkbox>
      </div>
      <div class="messanger-select">
        <div>
          <label for="messenger-checkbox" class="d-flex align-center ga-5">
            <img :src="instagream" alt="" />
            <p class="d-flex align-start flex-column">
              <span>Instagram</span>
              <span>Alrrifjj_alxdinik</span>
            </p>
          </label>
        </div>
        <v-checkbox
          v-model="messengerChecked"
          id="messenger-checkbox"
          color="#237276"
        ></v-checkbox>
      </div>
      <div class="messanger-select">
        <div>
          <label for="whatsApp-checkbox" class="d-flex align-center ga-5">
            <img :src="whatsApp" alt="" />
            <p class="d-flex align-start flex-column">
              <span>WhatsApp</span>
              <span>+966 59 726 8500</span>
            </p>
          </label>
        </div>
        <v-checkbox
          v-model="whatAppChecked"
          id="whatsApp-checkbox"
          color="#237276"
        ></v-checkbox>
      </div>
    </div>
    <div class="add-new-number">
      <v-select
        class="w-50"
        v-model="store.numberOfWhatsAppSelected"
        :items="store.numberOfWhatsApp"
        variant="outlined"
        return-object
        item-value="id"
        item-title="title"
        hide-details
      >
        <template v-slot:selection="{ item }">
          <p style="font-weight: 600; color: #000; font-size: 16px">
            {{ item?.title || "" }}
          </p>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item @click="store.selectWhatsAppNumber(item)">
            <div class="my-form">
              <div>
                <input
                  :checked="
                    store.numberOfWhatsAppSelected &&
                    store.numberOfWhatsAppSelected.id === item.raw.id
                  "
                  :id="`${item.id}_number`"
                  type="radio"
                  name="selctedNumber"
                  :value="item"
                />
                <label :for="`${item.id}_number`">{{ item.title }}</label>
              </div>
            </div>
          </v-list-item>
        </template>
      </v-select>
      <button @click="store.dialog = true">Add new number</button>
    </div>
    <AddNewWhatsAppNumber />
    <div class="connect-multiple mt-3 mb-1">
      <div class="header">
        <div class="left-head">
          <img :src="infoIcon" alt="Info Icon" />
          <p>Connect multiple WhatsApp numbers to your Page</p>
        </div>
        <img :src="closeIcon" alt="" />
      </div>
      <div class="body">
        <p>
          You can then select the number that you want to use for your ad. You
          can connect a maximum of 50 WhatsApp numbers per Facebook Page.
        </p>
        <button @click="store.dialog = true">Connect another number</button>
      </div>
    </div>
  </div>
</template>

<script>
import messenger from "@/assets/images/Logo.svg";
import instagream from "@/assets/images/instagream.svg";
import whatsApp from "@/assets/images/whatsApp.svg";
import infoIcon from "@/assets/images/info.svg";
import { boostPost } from "@/store/publishPost/boostPost";
import AddNewWhatsAppNumber from "./AddNewWhatsAppNumber.vue";
import closeIcon from "@/assets/images/close-icon.svg";
export default {
  components: { AddNewWhatsAppNumber },
  data() {
    return {
      messenger,
      instagream,
      whatsApp,
      infoIcon,
      closeIcon,
      messengerChecked: false,
      inestagramChecked: false,
      whatAppChecked: false,
      store: boostPost(),
    };
  },
};
</script>

<style scoped>
.add-new-number .v-input {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: #e6e6e6;
}
.add-new-number .v-field__input {
  padding-bottom: 5px !important   ;
}
</style>
