<template>
  <div class="profile-box-container w-100">
    <div class="mb-3">
      <label for="" class="profile-box-title">{{ $t('general_preference') }}</label>
    </div>
    <div class="d-flex ga-3 flex-column billing-select">
      <label for="Country"> {{ $t('language') }} </label>
      <v-autocomplete
        v-model="language"
        :items="languages"
        item-value="id"
        item-title="name"
        hide-details
        label=""
      >
      </v-autocomplete>
      <span
        class="text-red-accent-4 text-caption small-text mt-2"
        v-if="errors.lang"
        >{{ errors.lang }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "General",
  props: ["errors", "profile"],
  data() {
    return {
      language: "",
      languages: [
        { id: "en", name: "English" },
        { id: "ar", name: "Arabic" },
      ],
    };
  },
  beforeCreate() {},
  methods: {},
  watch: {
    profile(val) {
      this.language =
        this.languages?.find((p) => p.name === val?.lang)?.id ?? "";
    },
    errors(val) {
      console.log(val);
    },
    language(val) {
      this.$emit("change:language", val);
    },
  },
};
</script>
