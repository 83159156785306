<template>
  <div class="d-flex align-center ga-2">
    <svg
      v-if="!selected_confirmation"
      @click="confirmation_dialog = true"
      class="cursor-pointer hoverd-plus-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9902 22.5C11.525 22.5 11.067 22.4698 10.6182 22.4112L10.5535 22.907C9.58346 22.7805 8.65312 22.5276 7.77957 22.1653L7.97109 21.7035C7.11774 21.3496 6.32095 20.8863 5.59827 20.3309L5.29361 20.7274C4.53158 20.1418 3.84843 19.4587 3.26284 18.6966L3.6593 18.392C3.10395 17.6693 2.64065 16.8725 2.28678 16.0191L1.82492 16.2107C1.46267 15.3371 1.20975 14.4068 1.08321 13.4367L1.57901 13.372C1.52046 12.9232 1.49023 12.4653 1.49023 12C1.49023 11.5347 1.52046 11.0768 1.57901 10.628L1.08321 10.5633C1.20975 9.59323 1.46267 8.66289 1.82492 7.78933L2.28678 7.98086C2.64065 7.12751 3.10395 6.33072 3.6593 5.60804L3.26284 5.30337C3.84843 4.54134 4.53158 3.85819 5.29361 3.27261L5.59827 3.66907C6.32095 3.11371 7.11774 2.65042 7.9711 2.29655L7.77957 1.83469C8.65312 1.47244 9.58347 1.21951 10.5535 1.09297L10.6182 1.58877C11.067 1.53023 11.525 1.5 11.9902 1.5C12.4555 1.5 12.9134 1.53023 13.3623 1.58877L13.4269 1.09298C14.397 1.21951 15.3273 1.47244 16.2009 1.83469L16.0094 2.29655C16.8627 2.65042 17.6595 3.11371 18.3822 3.66907L18.6869 3.27261C19.4489 3.8582 20.132 4.54135 20.7176 5.30338L20.3212 5.60804C20.8765 6.33072 21.3398 7.12751 21.6937 7.98086L22.1555 7.78933C22.5178 8.66289 22.7707 9.59323 22.8973 10.5633L22.4015 10.628C22.46 11.0768 22.4902 11.5347 22.4902 12C22.4902 12.4653 22.46 12.9232 22.4015 13.372L22.8973 13.4367C22.7707 14.4068 22.5178 15.3371 22.1555 16.2107L21.6937 16.0191C21.3398 16.8725 20.8765 17.6693 20.3212 18.392L20.7176 18.6966C20.132 19.4587 19.4489 20.1418 18.6869 20.7274L18.3822 20.3309C17.6595 20.8863 16.8627 21.3496 16.0094 21.7035L16.2009 22.1653C15.3273 22.5276 14.397 22.7805 13.4269 22.907L13.3623 22.4112C12.9134 22.4698 12.4555 22.5 11.9902 22.5Z"
        stroke="#B3B3B3"
        stroke-dasharray="3 3"
      />
      <path
        d="M17.0497 10.9999H12.9997V6.93994C12.9997 6.67472 12.8943 6.42037 12.7068 6.23283C12.5193 6.0453 12.2649 5.93994 11.9997 5.93994C11.7345 5.93994 11.4801 6.0453 11.2926 6.23283C11.105 6.42037 10.9997 6.67472 10.9997 6.93994V10.9999H6.92969C6.66447 10.9999 6.41012 11.1053 6.22258 11.2928C6.03504 11.4804 5.92969 11.7347 5.92969 11.9999C5.92969 12.2652 6.03504 12.5195 6.22258 12.707C6.41012 12.8946 6.66447 12.9999 6.92969 12.9999H10.9997V17.0599C10.9997 17.3252 11.105 17.5795 11.2926 17.767C11.4801 17.9546 11.7345 18.0599 11.9997 18.0599C12.2649 18.0599 12.5193 17.9546 12.7068 17.767C12.8943 17.5795 12.9997 17.3252 12.9997 17.0599V12.9999H17.0597C17.3249 12.9999 17.5793 12.8946 17.7668 12.707C17.9543 12.5195 18.0597 12.2652 18.0597 11.9999C18.0597 11.7347 17.9543 11.4804 17.7668 11.2928C17.5793 11.1053 17.3249 10.9999 17.0597 10.9999H17.0497Z"
        fill="#666666"
      />
    </svg>
    <span
      @click="confirmation_dialog = true"
      v-else
      class="d-flex align-center ga-2 cursor-pointer"
    >
      <span
        :class="[
          selected_confirmation == 'attended'
            ? 'attend-selected'
            : 'not-attend-selected',
        ]"
      >
        {{ selected_confirmation }}
      </span>
    </span>
    <span
      v-if="selected_confirmation === 'attended'"
      :class="[
        'cursor-pointer d-flex align-center',
        answers?.length !== questions?.length ? 'alert-red' : 'alert-green',
      ]"
    >
      <img @click="servey_dialog = true" :src="surveyIcon" alt="Edit" />
    </span>
  </div>
  <!-- Dialog -->
  <v-dialog v-model="confirmation_dialog" persistent max-width="500px">
    <v-card class="pa-4">
      <v-card-title
        class="d-flex justify-space-between align-center dialog-title"
      >
        <span class="title">Confirmation</span>
        <span class="cursor-pointer" @click="confirmation_dialog = false">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 1L1 11M1 1L11 11"
              stroke="#666666"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </v-card-title>
      <v-card-text>
        <v-col col="3" class="d-flex flex-column ga-3 confirmation-type">
          <div
            @click="selected_confirmation = 'attended'"
            :class="[
              'cursor-pointer green',
              selected_confirmation === 'attended' ? 'selected' : '',
            ]"
          >
            attended
          </div>
          <div
            @click="selected_confirmation = 'not_attended'"
            :class="[
              'cursor-pointer red',
              selected_confirmation === 'not_attended' ? 'selected' : '',
            ]"
          >
            Not attended
          </div>
        </v-col>
        <div class="d-flex justify-end ga-3">
          <span @click="ChooseConfirmation" class="Choose-btn cursor-pointer"
            >Choose</span
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="servey_dialog" persistent max-width="500px">
    <v-card class="pa-4">
      <v-card-title
        class="d-flex justify-space-between align-center dialog-title"
      >
        <span class="title">Servey</span>
        <span class="cursor-pointer" @click="servey_dialog = false">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 1L1 11M1 1L11 11"
              stroke="#666666"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column ga-3 mb-4">
          <div
            v-for="(question, i) in questions"
            :key="i"
            class="field d-flex flex-column ga-2"
          >
            <label :for="'q' + i">{{ question }}</label>
            <input v-model="answers[i]" type="text" :id="'q' + i" />
          </div>
        </div>
        <div class="d-flex justify-end ga-3">
          <span @click="CancelServeyDialog" class="cancel-btn cursor-pointer"
            >Cancel</span
          >
          <span @click="SendSurvey" class="Choose-btn cursor-pointer"
            >Save</span
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dashedAddIcon from "@/assets/images/icons/dashed-add.svg";
import surveyIcon from "@/assets/images/icons/servey.svg";
import apiClient from "@/core/buildRequest";

export default {
  props: ["selected_reached_lead_type", "consultation_date", "item"],
  name: "ConfirmationType",
  data() {
    return {
      surveyIcon,
      dashedAddIcon,
      confirmation_dialog: false,
      selected_confirmation: this.item.attendanceStatus,
      servey_dialog: false,
      questions: [],
      questions_ids: [],
      answers: [],
    };
  },
  computed: {},
  mounted() {
    this.selected_confirmation = this.item.attendanceStatus;
  },
  watch: {
    servey_dialog(newValue) {
      if (newValue) {
        this.getSurvey();
      }
    },
    'item.attendanceStatus'(newValue) {
      this.selected_confirmation = newValue;
    }
  },
  methods: {
    CancelServeyDialog() {
      this.servey_dialog = false;
      this.answers = [];
    },
    async ChooseConfirmation() {
      if (this.selected_confirmation !== this.item.attendanceStatus) {
        try {
          await apiClient.post(
            `/leads-managment/update/booking-status/${this.item.id}`,
            {
              attendece_status: this.selected_confirmation,
            }
          );
        } catch (error) {
        }
      }
      this.confirmation_dialog = false;
    },
    async getSurvey() {
      try {
        await apiClient
          .get(`/leads-managment/surveys/`, {
            params: { lead_id: this.item.id },
          })
          .then((response) => {
            this.questions_ids = response.data.data?.map(
              (question) => question.id
            );
            this.questions = response.data.data?.map(
              (question) => question.question
            );
            this.answers = response.data.data?.map(
              (question) => question.survey_answer?.answer
            );
          });
      } catch (error) {
      }
    },
    async SendSurvey() {
      try {
        const payload = {
          lead_id: this.item.id,
        };

        this.answers.forEach((answer, index) => {
          payload[`answer[${index}]`] = answer || null;
        });

        this.questions_ids.forEach((id, index) => {
          payload[`survey_id[${index}]`] = id;
        });

        await apiClient.post(`/leads-managment/surveys/add/answer`, payload);
      } catch (error) {
        
      }
      this.servey_dialog = false;
    },
  },
};
</script>

<style scoped>
.confirmation-type div {
  height: 32px;
  padding: 2px 12px;
  border-radius: 16px;
  white-space: nowrap;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  border: solid 2px transparent;
}

.confirmation-type .green {
  background-color: #ecfdf3;
  color: #027a48;
}
.confirmation-type .green.selected {
  border-color: #034b2d;
}
.confirmation-type .red {
  background-color: #fef3f2;
  color: #d91313;
}
.confirmation-type .red.selected {
  border-color: #8d0b0b;
}
.Choose-btn {
  width: 100px;
  height: 32px;
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  background: #237276;
  color: white;
  text-align: center;
}
.Choose-btn:hover {
  background: #144244;
}
.attend-selected {
  height: 32px;
  padding: 2px 12px;
  border-radius: 16px;
  white-space: nowrap;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  border: solid 2px transparent;
  background-color: #ecfdf3;
  color: #027a48;
}
.not-attend-selected {
  height: 32px;
  padding: 2px 12px;
  border-radius: 16px;
  white-space: nowrap;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  border: solid 2px transparent;
  background-color: #fef3f2;
  color: #d91313;
}
.cancel-btn {
  width: 100px;
  height: 32px;
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  background: #f2f2f2;
  color: rgb(99, 99, 99);
  text-align: center;
}
.cancel-btn:hover {
  background: #aaaaaa;
}
input {
  height: 45px;
  padding: 16px 20px 16px 20px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  border: 0.5px solid rgba(197, 197, 197, 0.5);
}
input:focus,
input:focus-visible {
  border: unset;
  box-shadow: 0px 0px 2px 0px rgba(35, 114, 118, 0.5);
  outline: none;
}
.alert-red,
.alert-green {
  position: relative;
}
.alert-red::before,
.alert-green::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate(50%, -50%);
  border: solid 2px white;
}
.alert-red::before {
  background: #d91313;
}
.alert-green::before {
  background: #027a48;
}
</style>
