<template>
  <div class="optimize-location-card traffic-placement">
    <div class="card-body">
      <div class="radio-buttons-container">
        <div class="radio-button">
          <input
            type="radio"
            id="PLACEMENT_TYPE_AUTOMATIC"
            value="PLACEMENT_TYPE_AUTOMATIC"
            v-model="placement_type"
            class="radio-button__input"
            name="placement_type"
            @change="placementType"
          />
          <label for="PLACEMENT_TYPE_AUTOMATIC" class="radio-button__label">
            <span class="radio-button__custom"></span>
            <div>
              <span class="ma-0">Automatic placement</span>
              <p>
                Automatic placement is recommended because it can improve ad
                performance across TikTok
              </p>
            </div>
          </label>
        </div>

        <div class="radio-button mt-4">
          <input
            type="radio"
            id="PLACEMENT_TYPE_NORMAL"
            value="PLACEMENT_TYPE_NORMAL"
            v-model="placement_type"
            class="radio-button__input"
            name="placement_type"
            @change="placementType"
          />
          <label for="PLACEMENT_TYPE_NORMAL" class="radio-button__label">
            <span class="radio-button__custom"></span>
            <div>
              <span class="ma-0">Select placement</span>
              <p>Manually choose your targeting placement.</p>
            </div>
          </label>
        </div>
      </div>
      <div
        class="selected-placement-containe"
        v-if="placement_type == 'PLACEMENT_TYPE_NORMAL'"
      >
        <ul>
          <li v-for="(placement, index) in placementSelectedData" :key="index">
            <div class="in-feed-input d-flex ga-4 align-center">
              <div class="d-flex align-center ga-2">
                <input
                  :id="placement.id"
                  @change="placementValueStatus(placement.id)"
                  :v-model="placement.vModel"
                  type="checkbox"
                  class="ui-checkbox"
                  :checked="placement.checked"
                />
                <label class="feed-label" :for="placement.id">
                  <img :src="placement.img" alt="" />
                  <div class="d-flex flex-column">
                    <span class="d-block">{{ placement.text }}</span>
                    <div
                      v-if="placement.include_search"
                      class="checked-label smart-campaign mb-1 d-flex align-center ga-1"
                    >
                      <label class="toggle-switch">
                        <input
                          type="checkbox"
                          v-model="includeSearch"
                          id="includeSearch"
                        />
                        <div class="toggle-switch-background">
                          <div class="toggle-switch-handle"></div>
                          <svg
                            class="closemark"
                            xmlns="http://www.w3.org/2000/svg"
                            height="15px"
                            viewBox="0 -960 960 960"
                            width="15px"
                            fill=""
                          >
                            <path
                              d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
                            />
                          </svg>
                          <svg
                            class="checkmark"
                            xmlns="http://www.w3.org/2000/svg"
                            height="15px"
                            viewBox="0 -960 960 960"
                            width="15px"
                            fill=""
                          >
                            <path
                              d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
                            />
                          </svg>
                        </div>
                      </label>
                      <label
                        for="includeSearch"
                        style="font-size: 12px; color: #6d6e70"
                        class="label-for-input text-label"
                      >
                        {{ placement.description }}
                      </label>
                    </div>
                    <span v-else class="d-block description">{{
                      placement.description
                    }}</span>
                  </div>
                </label>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      placement_type: "PLACEMENT_TYPE_AUTOMATIC",
      placementSelectedData: [
        {
          id: 1,
          img: `https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/placement-tt7e35c0fc.png`,
          text: "TikTok",
          include_search: true,
          description: "Include search results",
          vModel: "PLACEMENT_TIKTOK",
          checked: true,
        },
        {
          id: 2,
          img: `https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/global-app-bundle7e77d90c.png`,
          text: "Global app bundle",
          include_search: false,
          description: "CapCut/Fizzo",
          vModel: "PLACEMENT_GLOBAL_APP_BUNDLE",
          checked: false,
        },
        {
          id: 3,
          img: `https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/placement-new-pangle498ddcf9.png`,
          text: "Pangle",
          include_search: false,
          description:
            "Premium global publisher network, available in some regions.",
          vModel: "PLACEMENT_PANGLE",
          checked: false,
        },
      ],
      includeSearch: true,
    };
  },
  mounted() {
    const checkedPlacements = this.placementSelectedData
      .filter((placement) => placement.checked)
      .map((placement) => placement.vModel);
    localStorage.setItem("placements", JSON.stringify(checkedPlacements));
    localStorage.setItem("placement_type", this.placement_type);
  },
  methods: {
    placementValueStatus(id) {
      let vModelValues = JSON.parse(localStorage.getItem("placements")) || [];
      const placement = this.placementSelectedData.find((p) => p.id === id);
      if (placement) {
        const index = vModelValues.indexOf(placement.vModel);
        if (index === -1) {
          vModelValues.push(placement.vModel);
        } else {
          vModelValues.splice(index, 1);
        }
        localStorage.setItem("placements", JSON.stringify(vModelValues));
      }
    },
    placementType() {
      localStorage.setItem("placement_type", this.placement_type);
    },
  },
};
</script>
