<template>
  <!-- Template remains exactly the same -->
  <v-container class="pa-0 ma-0">
    <v-row no-gutters class="meta-container-list position-relative h-100">
      <v-col cols="6">
        <div class="assets-customizaion mb-2">
          <p class="roboto-bold">Asset customisation</p>
          <p class="roboto-regular">
            16/16 placements that support asset customisation
          </p>
        </div>
        <p class="roboto-bold">Placements</p>
        <div class="parent-list">
          <div class="list" v-for="list in listOfArray" :key="list.id">
            <div class="list-header mb-2 d-flex align-start ga-1">
              <div class="left-header" @click="toggleBody(list)">
                <svg
                  v-if="!list.bodyStatus"
                  xmlns="http://www.w3.org/2000/svg"
                  height="20px"
                  viewBox="0 -960 960 960"
                  width="20px"
                  fill="#5f6368"
                >
                  <path d="M384-288v-384l192 192-192 192Z" />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  height="20px"
                  viewBox="0 -960 960 960"
                  width="20px"
                  fill="#5f6368"
                >
                  <path d="M480-384 288-576h384L480-384Z" />
                </svg>
              </div>
              <div class="right-header ga-2 d-flex align-start">
                <div class="text-header">
                  <p>{{ list.headerTitle }}</p>
                  <span>{{ list.headerDesc }} </span>
                </div>
                <label class="checkbox">
                  <input
                    :checked="areAllBodyInputsChecked(list)"
                    @change="toggleAllBodyInputs(list, $event.target.checked)"
                    type="checkbox"
                  />
                  <span class="checkmark"></span>
                  <span class="text"> </span>
                </label>
              </div>
            </div>
            <div class="list-body pl-5" v-if="list.bodyStatus">
              <ul>
                <li
                  v-for="child in list.listOfSelectedChild"
                  :key="child.id"
                  class="d-flex mb-2 align-center justify-space-between"
                >
                  <span class="d-block">{{ child.title }}</span>
                  <label class="checkbox mb-0">
                    <input
                      type="checkbox"
                      :disabled="child.disabled"
                      :checked="child.checked"
                      @change="handleSingleCheckboxChange(list, child, $event.target.checked)"
                    />
                    <span
                      class="checkmark"
                      :style="{ opacity: child.disabled ? 0.5 : 1 }"
                    ></span>
                    <span class="text"> </span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="6"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      feed: false,
      selectedValues: [], 
      checkedPlacements: [], 
      facebookFeed: false,
      listOfArray: [
        {
          id: 0,
          headerTitle: "Feeds",
          headerDesc: "Get high visibility for your business with ads in feeds",
          value: "feed",
          bodyStatus: false,
          listOfSelectedChild: [
            {
              id: 0,
              title: "Facebook Feed",
              checked: false,
              value: "feed",
              disabled: false,
            },
            {
              id: 1,
              title: "Instagram feed",
              value: "",
              checked: true,
              disabled: false,
            },
            {
              id: 2,
              title: "Facebook Marketplace",
              checked: true,
              value: "",
              disabled: false,
            },
            {
              id: 3,
              title: "Facebook video feeds",
              value: "",
              checked: true,
              disabled: false,
            },
            {
              id: 4,
              title: "Facebook right column",
              checked: false,
              value: "right_column",
              disabled: false,
            },
            {
              id: 5,
              title: "Instagram Explore",
              value: "",
              checked: true,
              disabled: false,
            },
            {
              id: 6,
              title: "Instagram Explore home",
              value: "",
              checked: true,
              disabled: false,
            },
            {
              id: 7,
              title: "Messenger inbox",
              value: "",
              checked: false,
              disabled: true,
            },
            {
              id: 8,
              title: "Facebook Business Explore",
              value: "",
              checked: false,
              disabled: true,
            },
          ],
        },
        {
          id: 1,
          headerTitle: "Stories and Reels",
          headerDesc:
            "Tell a rich, visual story with immersive, full-screen vertical ads",
          value: "story",
          bodyStatus: false,
          listOfSelectedChild: [
            {
              id: 0,
              title: "Instagram Stories",
              value: "",
              checked: true,
              disabled: false,
            },
            {
              id: 1,
              title: "Facebook Stories",
              value: "",
              checked: false,
              disabled: false,
            },
            {
              id: 2,
              title: "Messenger Stories",
              value: "",
              checked: true,
              disabled: false,
            },
            {
              id: 3,
              title: "Instagram Reels",
              value: "",
              checked: true,
              disabled: false,
            },
            {
              id: 4,
              title: "Facebook Reels",
              value: "",
              checked: false,
              disabled: false,
            },
          ],
        },
        {
          id: 2,
          headerTitle: "In-stream ads for videos and reels",
          headerDesc:
            "Reach people before, during or after they watch a video or reel",
          value: "",
          bodyStatus: false,
          listOfSelectedChild: [
            {
              id: 0,
              title: "Facebook in-stream videos",
              value: "",
              checked: true,
              disabled: false,
            },
            {
              id: 1,
              title: "Ads on Facebook Reels",
              value: "",
              checked: false,
              disabled: false,
            },
          ],
        },
        {
          id: 3,
          headerTitle: "Search results",
          headerDesc: "Get visibility for your business as people search",
          value: "search",
          bodyStatus: false,
          listOfSelectedChild: [
            {
              id: 0,
              title: "Facebook search results",
              value: "",
              checked: true,
              disabled: false,
            },
            {
              id: 1,
              title: "Instagram search results",
              value: "",
              checked: false,
              disabled: false,
            },
          ],
        },
        {
          id: 4,
          headerTitle: "Messages",
          headerDesc:
            "Send offers or updates to people who are already connected to your business",
          value: "",
          bodyStatus: false,
          listOfSelectedChild: [
            {
              id: 0,
              title: "Messenger sponsored messages",
              value: "",
              checked: false,
              disabled: true,
            },
          ],
        },
        {
          id: 5,
          headerTitle: "Apps and sites",
          headerDesc:
            "Expand your reach with ads in external apps and websites",
          value: "",
          bodyStatus: false,
          listOfSelectedChild: [
            {
              id: 0,
              title: "Audience Network native, banner and interstitial",
              value: "",
              checked: true,
              disabled: false,
            },
            {
              id: 1,
              title: "Audience Network rewarded videos",
              value: "",
              checked: false,
              disabled: true,
            },
          ],
        },
      ],
    };
  },
  methods: {
    toggleBody(list) {
      list.bodyStatus = !list.bodyStatus;
    },
    areAllBodyInputsChecked(list) {
      return list.listOfSelectedChild
        .filter((child) => !child.disabled)
        .every((child) => child.checked);
    },
    updateStorage() {
      this.selectedValues = [];
      this.checkedPlacements = [];

      this.listOfArray.forEach((category) => {
        category.listOfSelectedChild.forEach((child) => {
          if (child.checked && !child.disabled) {
            // Only add to checkedPlacements if value is not an empty string
            if (child.value !== '') {
              this.checkedPlacements.push(child.value);
            }
            // Add to selectedValues if there's a value
            if (child.value) {
              this.selectedValues.push(child.value);
            }
          }
        });
      });

      try {
        localStorage.setItem(
          "selected_placements",
          JSON.stringify(this.selectedValues)
        );
        localStorage.setItem(
          "checked_placements",
          JSON.stringify(this.checkedPlacements)
        );
      } catch (error) {
        console.error('Error saving to localStorage:', error);
      }
      
      this.$emit("update:selected-placements", this.selectedValues);
      this.$emit("update:checked-placements", this.checkedPlacements);
    },
    handleSingleCheckboxChange(list, child, checked) {
      child.checked = checked;
      this.updateStorage();
    },
    toggleAllBodyInputs(list, isChecked) {
      list.listOfSelectedChild.forEach((child) => {
        if (!child.disabled) {
          child.checked = isChecked;
        }
      });
      this.updateStorage();
    },
    safeJSONParse(data, fallback = []) {
      if (!data) return fallback;
      try {
        return JSON.parse(data);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return fallback;
      }
    }
  },
  mounted() {
    const savedValues = localStorage.getItem("selected_placements");
    const savedPlacements = localStorage.getItem("checked_placements");
    this.checkedPlacements = this.safeJSONParse(savedPlacements, []);
    this.selectedValues = this.safeJSONParse(savedValues, []);

    this.listOfArray.forEach((list) => {
      list.listOfSelectedChild.forEach((child) => {
        if (!child.disabled) {
          if (child.value && this.checkedPlacements.includes(child.value)) {
            child.checked = true;
          }
        }
      });
    });

    this.updateStorage();
  },
};
</script>