<template>
  <v-col :cols="12" :lg="9">
    <div class="mt-4 branch-select">
      <div class="bold-text mb-2">Branch</div>
      <v-select
        v-model="store.branches"
        :items="store?.branches_list"
        label="Select Branch"
        item-title="name"
        item-value="id"
        return-object
        multiple
        readonly
      >
        <template v-slot:selection="{ item }">
          <div class="d-flex align-center">
            <v-avatar size="24" class="mr-2">
              <v-img :src="item.raw.image" :alt="item.raw.name"></v-img>
            </v-avatar>
            {{ item.raw.name }}
          </div>
        </template>
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :title="null">
            <template v-slot:prepend>
              <div class="d-flex align-center">
                <v-avatar size="32" class="mr-2">
                  <v-img :src="item.raw.image" :alt="item.raw.name"></v-img>
                </v-avatar>
                {{ item.raw.name }}
              </div>
            </template>
          </v-list-item>
        </template>
      </v-select>
    </div>
  </v-col>
</template>

<script>
import { useEditTaskStore } from "@/store/editTaskStore";

export default {
  name: "TaskBranch",
  data() {
    return {
      store: useEditTaskStore(),
    };
  },
};
</script>
