<template>
  <div>
    <v-select
      :items="['touched', 'untouched']"
      v-model="status"
      label=""
      variant="solo"
      class="custom-v-select"
      hide-details
    ></v-select>
  </div>
</template>
<script>
import apiClient from "@/core/buildRequest";

export default {
  props: ["item"],
  name: "ProgressCol",
  components: {},
  data() {
    return {
      status: this.item.status,
    };
  },
  methods: {
    async updateStatus() {
      await apiClient
        .post(`/leads-managment/leads/update-status/${this.item.id}`, {
          status: this.status,
        })
    },
  },
  watch: {
    status() {
      this.updateStatus();
    },
  },
};
</script>

<style scoped>
.custom-v-select {
  width: 150px;
  height: 32px;
  border-radius: 4px;
  border: unset;
  background: transparent;
  color: gray;
  box-shadow: unset;
  text-decoration: capitalize;
}
.custom-v-select * {
  height: 32px;
  box-shadow: unset;
  border: unset;
}
.custom-v-select .v-field__input {
  padding: 0px 15px;
}
.custom-v-select .v-input__details {
  display: none !important;
}
</style>
