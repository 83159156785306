<template>
  <div class="pa-4 text-center">
    <v-dialog v-model="dialog" max-width="70vw">
 
      <template v-slot:default="{ isActive }">
        <div
          class="bg-white rounded-lg d-flex flex-column"
          style="height: 80vh"
        >
          <div class="pa-5">Design Your Form</div>

          <v-row no-gutters>
            <!-- Sidebar (Steps) -->
            <v-col cols="3">
              <div class="w-100 h-100 d-flex flex-column gap-3 lead-form-steps">
                <div
                  :class="['form-step', active === 'settings' ? 'active' : '']"
                  @click="active = 'settings'"
                >
                  <span class="dot"></span>
                  <span>Form Settings</span>
                </div>
                <div
                  :class="['form-step', active === 'questions' ? 'active' : '']"
                  @click="active = 'questions'"
                >
                  <span class="dot"></span>
                  <span>Questions</span>
                </div>
                <div
                  :class="[
                    'form-step',
                    active === 'disclosures' ? 'active' : '',
                  ]"
                  @click="active = 'disclosures'"
                >
                  <span class="dot"></span>
                  <span>Disclosures</span>
                </div>
                <div
                  :class="['form-step', active === 'ending' ? 'active' : '']"
                  @click="active = 'ending'"
                >
                  <span class="dot"></span>
                  <span>Ending</span>
                </div>
              </div>
            </v-col>

            <!-- Main Content -->
            <v-col cols="9">
              <v-row class="mx-3 h-100 position-relative" no-gutters>
                <v-col cols="8" class="h-100">
                  <div
                    class="lead-form-contents w-100"
                    style="max-height: 60vh; overflow-y: auto"
                  >
                    <div v-show="active === 'settings'">
                      <SettingsForm :data="form_data" />
                    </div>
                    <div v-show="active === 'questions'">
                      <QuestionsForm :data="form_data" />
                    </div>
                    <div v-show="active === 'disclosures'">
                      <DisclosuresForm :data="form_data" />
                    </div>
                    <div v-show="active === 'ending'">
                      <EndingForm :data="form_data" />
                    </div>
                  </div>
                </v-col>

                <v-col cols="4" class="h-100">
                  <div class="border w-100 h-100"></div>
                </v-col>
                <div
                  class="position-absolute bottom-0 w-100 d-flex align-center justify-space-between px-8 py-3 bg-white"
                >
                  <button
                    v-if="active !== 'settings'"
                    class="upload-span"
                    @click="getBack"
                  >
                    Back
                  </button>
                  <div v-else></div>
                  <button class="upload-span" @click="getNext">
                    {{ active !== "ending" ? "Next" : "" }}
                  </button>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import SettingsForm from "./SettingsForm.vue";
import QuestionsForm from "./QuestionsForm.vue";
import DisclosuresForm from "./DisclosuresForm.vue";
import EndingForm from "./EndingForm.vue";
import apiClient from "@/core/buildRequest";

export default {
  components: {
    SettingsForm,
    QuestionsForm,
    DisclosuresForm,
    EndingForm,
  },
  props: {
    local_dialog: Boolean,
    id: String,
  },
  data() {
    return {
      dialog: false,
      active: "settings",
      form_data: {},
    };
  },
  watch: {
    local_dialog(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("update:local_dialog", val);
    },
    id(val) {
      this.getData();
    },
  },
  methods: {
    getBack() {
      switch (this.active) {
        case "settings":
          break;
        case "questions":
          this.active = "settings";
          break;
        case "disclosures":
          this.active = "questions";
          break;
        case "ending":
          this.active = "disclosures";
          break;
      }
    },
    getNext() {
      switch (this.active) {
        case "settings":
          this.active = "questions";
          break;
        case "questions":
          this.active = "disclosures";
          break;
        case "disclosures":
          this.active = "ending";
          break;
        case "ending":
          break;
      }
    },
    getData() {
      if (!this.id) return;
      apiClient
        .get(
          `/campaign-ads/snapchat/lead-generation-form/account=${localStorage.getItem(
            "account_id"
          )}/form=${this.id}`
        )
        .then((res) => {
          this.form_data = res.data.data; 
        })
        .catch((err) => console.error(err));
    },
  },
  mounted() {
    // this.getData();
  },
};
</script>
