<template>
  <div class="tab-one">
    <div class="tab-header">
      <div class="search">
        <input type="text" placeholder="Search by post caption or post iD" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#121415"
        >
          <path
            d="M765-144 526-383q-30 22-65.79 34.5-35.79 12.5-76.18 12.5Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.03q0 40.39-12.5 76.18Q599-464 577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z"
          />
        </svg>
      </div>
      <div class="add-post">
        <button @click="dialog = true">Add post</button>
        <v-dialog v-model="dialog" class="post-dialog" width="800">
          <v-card class="pa-5">
            <div class="dialog-header mb-5">
              <div class="d-flex justify-space-between align-center mb-3">
                <p class="title">Authorize TikTok posts</p>
                <button @click="dialog = false">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#121415"><path d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"/></svg>
                </button>
              </div>
              <p class="subtitle">Enter the TikTok post code that was shared with you.</p>
            </div>
            <div class="dialog-body">
              <input type="text" placeholder="Enter post code" />
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="dialog = false">Cancel</v-btn>
              <v-btn @click="dialog = false">Continue</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabOne",

  data() {
    return {
      dialog: false,
    };
  },
};
</script>
