<template>
  <div class="card setting-card">
    <div class="card-header">
      <h3 class="card-title">Settings</h3>
    </div>
    <div class="card-body">
      <div class="campaign-name mb-8">
        <label for="">Campaign name</label>
        <input
          type="text"
          v-model="store.campaignName"
          
          name=""
          id="campaignName"
        />
      </div>
      <section v-if="allInputsStatus">
        <div class="split-test mb-8">
          <div
            class="checked-label smart-campaign mb-1 d-flex align-center ga-1"
          >
            <label class="toggle-switch">
              <input
                @change="setSplitTestAction"
                type="checkbox"
                v-model="splitTest"
                id="split-test"
                :disabled="disapledSplitTest"
              />

              <div
                class="toggle-switch-background"
                :style="{
                  cursor: disapledSplitTest ? 'no-drop' : 'pointer',
                  opacity: disapledSplitTest ? 0.5 : 1,
                }"
              >
                <div class="toggle-switch-handle"></div>
                <svg
                  class="closemark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
                  />
                </svg>
                <svg
                  class="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
                  />
                </svg>
              </div>
            </label>
            <label
              :style="{
                cursor: disapledSplitTest ? 'no-drop' : 'pointer',
                opacity: disapledSplitTest ? 0.5 : 1,
              }"
              for="split-test"
              class="label-for-input"
            >
              Create split test
            </label>
          </div>
          <p class="description">
            Split tests allow you to test two different versions of your ads to
            determine which one performs best.
          </p>
        </div>
        <div class="split-test-contant mb-8" v-if="splitTest">
          <p class="title">
            We'll show your split test to 2 separate audiences to give you
            data-driven results.
          </p>
          <div class="split-test-audience mb-2">
            <div>
              <svg
                width="20"
                height="20"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376l0 103.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"
                />
              </svg>
            </div>
            <span v-for="(n, i) in 15" :key="i" class="chart-text"></span>
            <div class="ml-2">
              <svg
                width="20"
                height="20"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  d="M288 0L160 0 128 0C110.3 0 96 14.3 96 32s14.3 32 32 32l0 132.8c0 11.8-3.3 23.5-9.5 33.5L10.3 406.2C3.6 417.2 0 429.7 0 442.6C0 480.9 31.1 512 69.4 512l309.2 0c38.3 0 69.4-31.1 69.4-69.4c0-12.8-3.6-25.4-10.3-36.4L329.5 230.4c-6.2-10.1-9.5-21.7-9.5-33.5L320 64c17.7 0 32-14.3 32-32s-14.3-32-32-32L288 0zM192 196.8L192 64l64 0 0 132.8c0 23.7 6.6 46.9 19 67.1L309.5 320l-171 0L173 263.9c12.4-20.2 19-43.4 19-67.1z"
                />
              </svg>
            </div>
            <span v-for="(n, i) in 15" :key="i" class="chart-text"></span>
            <div class="ml-2">
              <svg
                width="20"
                height="20"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M32 32c17.7 0 32 14.3 32 32l0 336c0 8.8 7.2 16 16 16l400 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L80 480c-44.2 0-80-35.8-80-80L0 64C0 46.3 14.3 32 32 32zM160 224c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32zm128-64l0 160c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-160c0-17.7 14.3-32 32-32s32 14.3 32 32zm64 32c17.7 0 32 14.3 32 32l0 96c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-96c0-17.7 14.3-32 32-32zM480 96l0 224c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-224c0-17.7 14.3-32 32-32s32 14.3 32 32z"
                />
              </svg>
            </div>
          </div>
          <div class="split-test-text">
            <p>1. Complete the ad group and ad settings for your control</p>
            <p>2. Select a variable and create your test ad group</p>
            <p>3. View results for insights into your strategy</p>
          </div>
        </div>
        <div class="split-test mb-6">
          <div
            class="checked-label smart-campaign mb-1 d-flex align-center ga-1"
          >
            <label class="toggle-switch">
              <input
                type="checkbox"
                v-model="campaignBudgetOptimization"
                id="campaignBudgetOptimization"
                :disabled="disapledCampaignBudgetOptimization"
                ref="campaignBudgetStatus"
                @change="campaignBudgetAction"
              />
              <div
                class="toggle-switch-background"
                :style="{
                  cursor: disapledCampaignBudgetOptimization
                    ? 'no-drop'
                    : 'pointer',
                  opacity: disapledCampaignBudgetOptimization ? 0.5 : 1,
                }"
              >
                <div class="toggle-switch-handle"></div>
                <svg
                  class="closemark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
                  />
                </svg>
                <svg
                  class="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
                  />
                </svg>
              </div>
            </label>
            <label
              for="campaignBudgetOptimization"
              class="label-for-input"
              :style="{
                cursor: disapledCampaignBudgetOptimization
                  ? 'no-drop'
                  : 'pointer',
                opacity: disapledCampaignBudgetOptimization ? 0.5 : 1,
              }"
            >
              Campaign budget optimization
            </label>
          </div>
          <p class="description">
            Automatically optimize your budget allocation. You'll have some on
            your bid strategy and optimization goal.
          </p>
        </div>
        <div v-if="campaignBudgetOptimization" class="budget mb-3">
          <label for="budget" class="mb-1 d-block">Budget</label>
          <div class="container-for-input">
            <select name="" id="" @change="updateBudgetMode">
              <option value="BUDGET_MODE_DAY">Daily</option>
              <option value="BUDGET_MODE_TOTAL">Lifetime</option>
            </select>
            <div
              style="
                width: 60%;
                position: relative;
                display: flex;
                align-items: center;
              "
            >
              <input
                type="number"
                v-model="store.budgetSpend"
                name=""
                id=""
                placeholder="At least 500.00"
                class="no-controls"
              />

              <span
                style="
                  position: absolute;
                  right: 10px;
                  color: #6d6e70;
                  font-size: 12px;
                "
                >EGP</span
              >
            </div>
          </div>
        </div>
        <div class="split-test mb-3" v-if="setCampaignBudgetContainer">
          <div
            class="checked-label smart-campaign mb-3 d-flex align-center ga-1"
          >
            <label class="toggle-switch">
              <input
                type="checkbox"
                v-model="setCampaignBudget"
                id="campaignBudgetOptimization"
              />
              <div class="toggle-switch-background">
                <div class="toggle-switch-handle"></div>
                <svg
                  class="closemark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
                  />
                </svg>
                <svg
                  class="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
                  />
                </svg>
              </div>
            </label>
            <label for="campaignBudgetOptimization" class="label-for-input">
              Set campaign budget
            </label>
          </div>
          <div class="budget" v-if="setCampaignBudget">
            <div class="container-for-input">
              <select name="" id="">
                <option value="Daily">Daily</option>
                <option value="Lifetime">Lifetime</option>
              </select>
              <div
                style="
                  width: 60%;
                  position: relative;
                  display: flex;
                  align-items: center;
                "
              >
                <input
                  type="text"
                  v-model="setCampaignBudgetInput"
                  name=""
                  id=""
                  placeholder="At least 500.00"
                />
                <span
                  style="
                    position: absolute;
                    right: 10px;
                    color: #6d6e70;
                    font-size: 12px;
                  "
                  >EGP</span
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { tiktokData } from "@/store/tiktokStore/tiktokData.js";
import { mapActions, mapState } from "pinia";

export default {
  data() {
    return {
      splitTest: false,
      campaignBudgetOptimization: false,
      setCampaignBudget: false,
      setCampaignBudgetInput: "",
      disapledCampaignBudgetOptimization: false,
      disapledSplitTest: false,
      setCampaignBudgetContainer: true,
      budget_mode: "BUDGET_MODE_DAY",
      store: tiktokData(),
    };
  },
  computed: {
    ...mapState(tiktokData, [
      "campaignName",
      "splitTest",
      "campainBudgetOtpimized",
      "setCampaignBudget",
      "allInputsStatus",
     
    ]),
  },
  mounted() {
    if (localStorage.getItem("split_test") === "true") {
      this.splitTest = localStorage.getItem("split_test");
      this.disapledCampaignBudgetOptimization =
        localStorage.getItem("split_test");
    } else {
      this.splitTest = localStorage.setItem("split_test", this.splitTest);
    }
    if (localStorage.getItem("campaignBudgetOptimization") === "true") {
      this.campaignBudgetOptimization = localStorage.getItem(
        "campaignBudgetOptimization"
      );
      this.disapledSplitTest = localStorage.getItem(
        "campaignBudgetOptimization"
      );
      this.setCampaignBudgetContainer = !localStorage.getItem(
        "campaignBudgetOptimization"
      );
    } else {
      this.campaignBudgetOptimization = localStorage.setItem(
        "campaignBudgetOptimization",
        this.campaignBudgetOptimization
      );
    }
    if (localStorage.getItem("smart_campaign") === "true") {
      this.allInputsStatus = localStorage.getItem("smart_campaign");
    }
    const storedModes = localStorage.getItem("budget_mode");
    if (storedModes) {
      this.budget_mode = storedModes;
    } else {
      this.budget_mode = "BUDGET_MODE_DAY";
      localStorage.setItem("budget_mode", this.budget_mode);
    }
    if(localStorage.getItem("budget_spend")) {
      this.budgetSpend = Number(localStorage.getItem("budget_spend"));
    }else{
      localStorage.setItem("budget_spend", this.budgetSpend);
    }
  },

  methods: {
    ...mapActions(tiktokData, ["setCampaignName", "updateBudgetSpend"]),
    setSplitTestAction() {
      localStorage.setItem("split_test", this.splitTest);
      this.disapledCampaignBudgetOptimization = this.splitTest;
    },
    campaignBudgetAction() {
      localStorage.setItem(
        "campaignBudgetOptimization",
        this.campaignBudgetOptimization
      );
      this.disapledSplitTest = this.campaignBudgetOptimization;
      this.setCampaignBudgetContainer = !this.setCampaignBudgetContainer;
    },
    updateBudgetMode(event) {
      const selectedValue = event.target.value;
      this.budget_mode = selectedValue;
      localStorage.setItem("budget_mode", this.budget_mode);
    },
  },
};
</script>
