<template>
  <div class="profile-box-container w-100">
    <div class="d-flex justify-space-between align-items-center w-100 mb-3">
      <label for="" class="profile-box-title"> {{ $t("addresses") }} </label>
      <span class="profile-box-link" @click="UpdateAdderss">{{
        $t("update")
      }}</span>
    </div>
    <div class="d-flex align-items-center justify-space-between py-5">
      <div class="profile-box-second-text"> {{ $t('shipping_address') }}  </div>
      <div class="profile-box-second-text">
        {{ profile?.shipping_address?.address }}
      </div>
    </div>
    <v-divider></v-divider>
    <div class="d-flex align-items-center justify-space-between py-5">
      <div class="profile-box-second-text">{{ $t('billing_address') }}</div>
      <div class="profile-box-second-text">
        {{ profile?.billing_address?.address }}
      </div>
    </div>
  </div>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <div class="dialog-card">
        <v-tabs v-model="tab" align-tabs="start" color="cyan-accent-4">
          <v-tab :value="1">{{ $t("billing_address") }}</v-tab>
          <v-tab :value="2"> {{ $t('shipping_address') }}</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item :value="1">
            <BillingAddress :profile="profile" @close="dialog = false" />
          </v-tabs-window-item>
          <v-tabs-window-item :value="2">
            <ShippingAddress :profile="profile" @close="dialog = false" />
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import BillingAddress from "@/components/myAccount/index/BillingAddress.vue";
import ShippingAddress from "@/components/myAccount/index/ShippingAddress.vue";
export default {
  name: "Addresses",
  components: { BillingAddress, ShippingAddress },
  props: ["profile"],
  data() {
    return {
      dialog: false,
      tab: null,
    };
  },
  computed: {},
  watch: {
    profile(val) {
      console.log(val);
    },
  },
  methods: {
    UpdateAdderss() {
      this.dialog = true;
    },
  },
};
</script>
