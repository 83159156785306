<template>
  <div class="delivery ad-set-details-card mb-6 fs-14">
    <h6 class="page-header-title mb-3">Delivery</h6>

    <div
      class="bg-white d-flex flex-column ga-5"
      style="
        padding: 19px;
        border-radius: 15px;
        box-shadow: 0px 2px 1px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
          0px 1px 1px 0px
            var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
          0px 1px 3px 0px
            var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12)) !important;
      "
    >
      <span>Frequency Cap</span>
      <div>
        <label class="snap-checkbox">
          <input v-model="user_frequency_cap" type="checkbox" />
          <span class="snap-checkmark"></span>DoubleVerify
        </label>
      </div>
      <v-row v-if="user_frequency_cap">
        <v-col cols="6">
          <div class="position-relative">
            <input
              min="1"
              class="no-spin w-100 h-100"
              type="number"
              v-model="maxi_impression_per"
            />
            <span class="position-absolute" style="right: 10px; top: 10px">
              maximum impressions <strong>per</strong>
            </span>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="position-relative">
            <input
              min="1"
              class="no-spin w-100 h-100"
              type="number"
              v-model="maxi_impression_days"
            />
            <span class="position-absolute" style="right: 10px; top: 10px">
              days
            </span>
          </div>
        </v-col>
      </v-row>
      <span>Third Party Tagging</span>
      <div>
        <label class="snap-checkbox">
          <input v-model="third_party_tragging" type="checkbox" />
          <span class="snap-checkmark"></span>DoubleVerify
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      third_party_tragging: true,
      user_frequency_cap: false,
      maxi_impression_per: 1,
      maxi_impression_days: 7,
    };
  },
  methods: {},
  watch: {
    third_party_tragging(val) {
      localStorage.setItem("third_party_tragging", val);
    },
    user_frequency_cap(val) {
      localStorage.setItem("user_frequency_cap", val);
    },
    maxi_impression_per(val) {
      localStorage.setItem("maxi_impression_per", val);
    },
    maxi_impression_days(val) {
      localStorage.setItem("maxi_impression_days", val);
    },
  },
  mounted() {
    this.third_party_tragging = localStorage.getItem("third_party_tragging") === "true";
    this.user_frequency_cap =
      localStorage.getItem("user_frequency_cap") === "true";
    this.maxi_impression_per = localStorage.getItem("maxi_impression_per");
    this.maxi_impression_days = localStorage.getItem("maxi_impression_days");
  },
};
</script>
