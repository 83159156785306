import axios from "axios";
import config from "@/../config";
import { useGeneralStore } from "@/store/General";

const token = localStorage.getItem("user_token");

const apiClient = axios.create({
  baseURL: config.api_base_url_stg,

  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    token: "Bearer " + token,
  },
  params: {
    time: new Date().getTime(),
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Lang = localStorage.getItem("lang") || "en";
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      let g = useGeneralStore();
      g.showSnackbar(`${error.response.data.msg}`, "error");
    } else {
      let g = useGeneralStore();
      g.showSnackbar(
        "An unexpected error occurred. Please try again later.",
        "error"
      );
    }
    return Promise.reject(error);
  }
);

export default apiClient;
