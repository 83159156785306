
<template>
  <div class="estimate-results mb-3">
    <div class="estimate-results-head mb-3">
      <p>Estimated daily results</p>
    </div>
    <div class="estimate-results-body flex-column align-start">
      <div class="total-budget">
        <p class="title">Total budget</p>
        <p class="details">$6.00 USD</p>
      </div>
      <div class="total-budget">
        <p class="title">Estimated VAT</p>
        <p class="details">$0.30 USD</p>
      </div>
      <div class="total-budget">
        <p class="title">Total amount</p>
        <p class="details">$6.30 USD</p>
      </div>
    </div>
  </div>
</template>
