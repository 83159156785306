<template>
  <v-expansion-panels>
    <v-expansion-panel bg-color="transparent">
      <v-expansion-panel-title v-slot="{ expanded }" hide-actions>
        <div class="d-flex align-center justify-space-between ga-2 w-100">
          <div class="d-flex align-center ga-2">
            <v-icon
              class="upload-span"
              style="padding: 0px; width: 32px"
              :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            ></v-icon>
            <div class="d-flex flex-column ga-1">
              <span>Q{{ index + 1 }}</span>
            </div>
          </div>
        </div>
      </v-expansion-panel-title>

      <v-expansion-panel-text>
        <div class="pa-5 snapchat d-flex flex-column ga-3">
          <div class="filed mt-3">
            <span class="title">Question</span>
            <div class="input-container">
              <input
                type="text"
                v-model="question.custom_form_field_properties.description"
                placeholder="Question"
              />
            </div>
          </div>
          <div>Answers</div>
          <div
            v-for="option in question.custom_form_field_properties
              .multiple_choice_options"
            :key="option"
            class="filed mt-3 d-flex align-center ga-2"
          >
            <div class="input-container w-100">
              <input
                type="text"
                v-model="option.choice_description"
                placeholder="Question"
              />
            </div>
            <div class="cursor-pointer"></div>
          </div>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    addOption() {
      this.question.options.push({ text: "" });
    },
  },
};
</script>
<style scoped>
.sds-icon-trash:hover {
  fill: #0894fa;
}
</style>
