<template>
  <div class="input-container">

    <div v-if="icon" class="icon">
      <img class="input-icon" :src="icon" alt="icon" />
    </div>
    <input
      :type="inputType"
      ref="input"
      :placeholder="placeholder"
      :value="modelValue"
      :class="type === 'number' ? 'no-controls' : ''"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div v-if="type === 'password'">
      <button @click="togglePassword">
        <img
          v-if="showPassword"
          alt="show password"
          src="@/assets/images/Eye.svg"
        />
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#5f6368"
        >
          <path
            d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-142.6 0-259.8-78.5Q103-349 48-480q55-131 172.2-209.5Q337.4-768 480-768q142.6 0 259.8 78.5Q857-611 912-480q-55 131-172.2 209.5Q622.6-192 480-192Zm0-288Zm0 216q112 0 207-58t146-158q-51-100-146-158t-207-58q-112 0-207 58T127-480q51 100 146 158t207 58Z"
          />
        </svg>
      </button>
    </div>

  </div>
</template>

<script>
export default {
  name: "InputComponent",
  props: {
    type: {
      type: String,
      default: "text",
    },
    icon: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      inputType: this.type, // Store the type in data to toggle it
      showPassword: true,
    };
  },
  methods: {
    togglePassword() {
      this.inputType = this.inputType === "password" ? "text" : "password";
      this.showPassword = !this.showPassword;
    },

  },
};
</script>

<style scoped>
.input-container {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 36px; /* Fixed height */
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #cccccc;
}
.input-container .icon {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-icon {
  width: 18px;
  height: 18px;
}
input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  color: #999999;
  width: calc(100% - 18px);
}
input:focus {
  border: none;
  outline: none;
}
button {
  outline: none;
  border: none;
  padding: 0 10px;
}

</style>
