<template>
  <div class="comments-container">
    <div @click="toggleComments" class="toggle-comments">
      <span v-if="showComments" class="d-flex align-center ga-1">
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 5L5 1L1 5"
            stroke="#666666"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Hide
      </span>
      <span v-else class="d-flex align-center ga-1">
        <svg
          style="transform: rotate(180deg)"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 5L5 1L1 5"
            stroke="#666666"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Show
      </span>
    </div>
    <div class="comments" v-if="showComments">
      <TaskComment
        v-for="(comment, index) in store.last_comments"
        :key="index"
        :comment="{
          user: { avatar: comment?.image, name: comment?.user_name },
          attachments: comment.attachments.map((a) => ({
            preview: a.file_path,
          })),
          created_at: comment.created_at,
          content: comment.comment,
          links: comment.links,
          id: comment.id,
        }"
      />
      <TaskComment
        v-for="(comment, index) in store.comments"
        :key="index"
        :comment="comment"
      />
    </div>
    <div class="create-comments">
      <TaskNewComment @comment-added="handleNewComment" />
    </div>
  </div>
</template>

<script>
import TaskComment from "./TaskComment.vue";
import TaskNewComment from "./TaskNewComment.vue";
import { useEditTaskStore } from "@/store/editTaskStore";
import apiClient from "@/core/buildRequest";
import { useGeneralStore } from "@/store/General";

export default {
  name: "TaskComments",
  components: {
    TaskComment,
    TaskNewComment,
  },
  data() {
    return {
      showComments: true,
      comments: [],
      store: useEditTaskStore(),
      generalStore: useGeneralStore(),
    };
  },
  watch: {
    "store.comments"(newValue) {
      console.log(this.store.comments);
    },
    "store.last_comments"(newValue) {
      console.log(this.store.last_comments);
    },
  },
  methods: {
    toggleComments() {
      this.showComments = !this.showComments;
    },
    handleNewComment(comment) {
      const formData = new FormData();
      console.log(comment.comment);

      formData.append(`comment`, comment.comment);
      comment.attachments.forEach((attachment, attIndex) => {
        formData.append(`attachment[${attIndex}]`, attachment.file);
      });
      comment.links.forEach((link, linkIndex) => {
        formData.append(`links[${linkIndex}][name]`, link.name);
        formData.append(`links[${linkIndex}][href]`, link.href);
      });
      apiClient
        .post(`/calender/tasks/${this.$route.params.id}/comment`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.store.last_comments.push({
            user: {
              name: "Current User",
              avatar: "https://ui-avatars.com/api/?length=1?name=User",
            },
            time: "Just now",
            comment: response.data.data.comment,
            attachments: response.data.data.attachments,
            links: response.data.data.links,
            id: response.data.data.id,
          });
          this.general_store?.showSnackbar("Comment has been sent", "success");
        })
        .catch((error) => {
          console.error("Error sending task:", error);
          this.general_store?.showSnackbar("Error Sending Data", "error");
        });
    },
  },
};
</script>

<style scoped>
.toggle-comments {
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
}

.comments {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  max-height: 85vh;
  overflow-y: auto;
}
</style>
