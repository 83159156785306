import CalenderIndex from "@/views/calender/CalenderIndex.vue";
import CreateTask from "@/views/calender/CreateTask.vue";
import ShowTask from "@/views/calender/ShowTask.vue";
import EditTask from "@/views/calender/EditTask.vue";

const CalenderRoutes = [
  {
    path: "/calender",
    name: "calender",
    component: CalenderIndex,
    meta: {
      title: "Calender",
      description: "View the calendar with all tasks and events.",
      
      permissions: [
        "calender.create",
        "calender.read",
        "calender.update",
        "calender.delete",
      ],
    },
  },
  {
    path: "/calender/task/edit/:id",
    name: "CalenderTaskEdit",
    component: EditTask,
    meta: {
      title: "Calender",
      description: "View the calendar with all tasks and events.",
      
      permissions: [
        "calender.create",
        "calender.read",
        "calender.update",
        "calender.delete",
      ],
    },
  },
  {
    path: "/calender/task/show/:id",
    name: "CalenderTaskShow",
    component: ShowTask,
    meta: {
      title: "Calender",
      description: "View the calendar with all tasks and events.",
      
      permissions: [
        "calender.create",
        "calender.read",
        "calender.update",
        "calender.delete",
      ],
    },
  },
  {
    path: "/create-task",
    name: "AddTask",
    component: CreateTask,
    meta: {
      title: "Add Task",
      description: "Create a new task or event in the calendar.",
      
      permissions: [
        "calender.create",
        "calender.read",
        "calender.update",
        "calender.delete",
      ],
    },
  },
];

export default CalenderRoutes;
