<template>
  <div class="boost-post-card placement-type-card">
    <div class="card-header mb-4">
      <label for="" class="title d-block">Placements</label>
    </div>
    <div class="card-body">
      <div class="recomended-placement mb-2">
        <div class="left-body">
          <p class="title">Recommended</p>
          <p class="sub-title">Advantage+ Placements</p>
        </div>
        <div class="right-body">
          <v-switch
            id="category-status"
            v-model="store.placementRecomended"
            color="info"
            hide-details
            inset
          ></v-switch>
        </div>
      </div>
      <div class="recomended-placement-description mb-3">
        <p class="title">
          Let’s maximise your budget across Facebook, Messenger, Instagram and
          meta Audience Network to help show your ad to more people
        </p>
      </div>
      <div class="manual-placement" v-if="!store.placementRecomended">
        <p class="title">Manual placement</p>
        <p class="sub-title">
          Choose where you want to show your ad. The more placements you select,
          the more opportunities you 'll have to reach your target audience.
        </p>
        <div class="mt-3">
          <div
            class="d-flex align-center justify-space-between"
            v-for="item in store.manualPlacement"
            :key="item.id"
          >
            <label
              class="d-flex align-center ga-5"
              :for="`${item.id}_placement`"
            >
              <img
                :style="{ opacity: item.disabled ? 0.5 : 1 }"
                width="30px; height: 30px"
                :src="item.icon"
                :alt="item.title"
              />
              <p
                style="font-size: 16px; color: #0d0d0d;font-weight: 600;"
                :style="{ opacity: item.disabled ? 0.5 : 1 }"
              >
                {{ item.title }}
              </p>
            </label>

            <v-checkbox
              v-model="item.checked"
              :disabled="item.disabled"
              :id="`${item.id}_placement`"
              color="#237276"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { boostPost } from "@/store/publishPost/boostPost";
export default {
  data() {
    return {
      store: boostPost(),
    };
  },
};
</script>
