<template>
  <v-table>
    <thead>
      <tr>
        <th class="text-left">Module</th>
        <th class="text-left">Show</th>
        <th class="text-left">Create</th>
        <th class="text-left">Edit</th>
        <th class="text-left">Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(module, i) in modules" :key="module">
        <td>
          <span class="d-flex align-center ga-2">
            <img
              :src="module.image"
              width="32"
              height="32"
              :alt="module.name"
            />
            {{ i }}
          </span>
        </td>
        <td>
          <v-checkbox color="#237276" readonly :model-value="true"></v-checkbox>
        </td>
        <td>
          <v-checkbox :model-value="true" color="#237276" readonly></v-checkbox>
        </td>
        <td>
          <v-checkbox :model-value="true" color="#237276" readonly></v-checkbox>
        </td>
        <td>
          <v-checkbox :model-value="true" color="#237276" readonly></v-checkbox>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script>
export default {
  name: "RolePermission",
  props: {
    role_permissions: {
      type: Array,
      required: true,
      default: () => [],
    },
    showOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modules() {
      return this.role_permissions || [];
    },
  },
  methods: {
    hasPermission(module, type) {
      const permission = module.prefix.find((p) => p.prefix === type);
      return permission ? true : false;
    },
  },
};
</script>
