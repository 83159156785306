import apiClient from "@/core/buildRequest";
import { defineStore } from "pinia";
import { useGeneralStore } from "../General";
export const useLeadFormStore = defineStore("useLeadFormStore", {
  state: () => ({
    form_name: "",
    form_description: "",
    header_image: "",
    questions: [],
    fields: [
      {
        title: "First Name",
        value: "first_name",
      },
      {
        title: "Last Name",
        value: "last_name",
      },
    ],
    privacy_policy_url: "",
    title: "",
    description: "",
    consent: [
      {
        description: "",
        required: false,
      },
    ],
    call_to_action: {
      button_text: "",
      website_url: "",
    },
    lead_form_id: "",
    lead_forms: [],
    settingsValidations: [],
    disclosureValidations: [],
    useGeneralStore: useGeneralStore(),
    getting_lead_forms: false,
  }),
  getters: {},
  actions: {
    async createForm() {
      try {
        let form_fields = [];
        form_fields = this.fields.map((f) => {
          return {
            type: f.value.toUpperCase(),
          };
        });
        let custom_questions = this.questions?.map((q) => {
          if (q.type === "MULTIPLE_CHOICE_SINGLE_SELECTION") {
            return {
              type: "CUSTOM",
              custom_form_field_properties: {
                type: q.type.toUpperCase(),
                description: q.question,
                multiple_choice_options: q.options.map((o) => {
                  return {
                    choice_description: o.text,
                    option_preferred_status: o.type.toUpperCase(),
                  };
                }),
              },
            };
          }
          if (q.type === "MULTIPLE_CHOICE_MULTI_SELECTION") {
            return {
              type: "CUSTOM",
              custom_form_field_properties: {
                type: q.type.toUpperCase(),
                description: q.question,
                multiple_choice_options: q.options.map((o) => {
                  return {
                    choice_description: o.text,
                  };
                }),
              },
            };
          }
          if (q.type === "DATE" || q.type === "TEXT") {
            return {
              type: "CUSTOM",
              custom_form_field_properties: {
                type: q.type.toUpperCase(),
                description: q.question,
              },
            };
          }
        });
        form_fields.push(...custom_questions);
        let lead_generation_forms = [
          {
            privacy_policy_url: this.privacy_policy_url,
            name: this.form_name,
            banner_media_id: this.header_image,
            description: this.form_description,
            form_fields: form_fields,
            legal_disclosures: {
              title: this.title,
              description: this.description,
              consent_form_fields: this.consent.map((c) => {
                return {
                  consent_description: c.description,
                  required: c.required,
                };
              }),
            },
            end_page_properties: {
              call_to_action: this.call_to_action.button_text.toUpperCase(),
              url: this.call_to_action.website_url,
            },
          },
        ];
        let status = false;
        await apiClient
          .post(
            `/campaign-ads/snapchat/create/lead-generation/${localStorage.getItem(
              "account_id"
            )}`,
            {
              lead_generation_forms: lead_generation_forms,
            }
          )
          .then((res) => {
            this.getLeadForms();
            let id = "";
            id =
              res?.data.data?.lead_generation_forms[0]?.lead_generation_form
                ?.id;
            if (id !== "") {
              localStorage.setItem("lead_form_id", id);
              this.useGeneralStore.showSnackbar(
                "Lead Form Created Successfully",
                "success"
              );
              status = true;
            }
          })
          .catch((error) => {
            this.createValidation = [];
            if (error.response?.data?.msg) {
              this.createValidation.push(error.response?.data?.msg);
            }
            if (error.response?.data?.error?.lead_generation_forms) {
              this.createValidation.push(
                error.response?.data?.error?.lead_generation_forms.map(
                  (f) => f.sub_request_error_reason
                )
              );
            }
            this.useGeneralStore.showSnackbar(
              "Lead Form Creation Failed",
              "error"
            );
          });
        return status;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    validateSettingStep() {
      this.settingsValidations = [];
      if (!this.form_name || this.form_name === "")
        this.settingsValidations.push("form_name");
      if (!this.form_description || this.form_description === "")
        this.settingsValidations.push("form_description");
    },
    validateDisclosureStep() {
      this.disclosureValidations = [];
      if (!this.privacy_policy_url || this.privacy_policy_url === "")
        this.disclosureValidations.push("form_privacy_policy_url");
      if (!this.description || this.description === "")
        this.disclosureValidations.push("description");
    },
    validation() {},
    removeOptoin(index, i) {
      if (this.questions[index].options.length > 2) {
        this.questions[index].options.splice(i, 1);
      }
    },
    RemoveQuestion(index) {
      this.questions.splice(index, 1);
    },
    addQuestion(type) {
      switch (type) {
        case "MULTIPLE_CHOICE_SINGLE_SELECTION":
          this.questions.push({
            type: "MULTIPLE_CHOICE_SINGLE_SELECTION",
            question: "",
            qualifying_question: false,
            options: [
              {
                text: "",
                type: "",
              },
              {
                text: "",
                type: "",
              },
            ],
          });
          break;
        case "MULTIPLE_CHOICE_MULTI_SELECTION":
          this.questions.push({
            type: "MULTIPLE_CHOICE_MULTI_SELECTION",
            question: "",
            options: [
              {
                text: "",
              },
            ],
          });
          break;
        case "DATE":
          this.questions.push({
            type: "DATE",
            question: "",
          });
          break;
        case "TEXT":
          this.questions.push({
            type: "TEXT",
            question: "",
          });
          break;
      }
    },
    async getLeadForms() {
      this.getting_lead_forms = true;
      await apiClient
        .get(
          `/campaign-ads/snapchat/lead-generation/${localStorage.getItem(
            "account_id"
          )}`
        )
        .then((res) => {
          this.getting_lead_forms = false;
          this.lead_forms = res.data.data;
        })
        .catch((error) => {
          this.getting_lead_forms = false;
          useGeneralStore.showSnackbar("Failed to get lead forms", "error");
        });
    },
    getSingleLeadForm(id) {
      apiClient
        .get(
          `/campaign-ads/snapchat/lead-generation-form/account=${localStorage.getItem(
            "account_id"
          )}/form=${id}`
        )
        .then((res) => {
          let form_data = res.data.data.lead_generation_form;
          console.log(form_data);
          this.form_name = form_data?.name;
          this.form_description = form_data?.description;
          this.header_image = form_data?.banner_media_id;
          this.questions = form_data?.form_fields
            .filter((f) => f.type === "CUSTOM")
            .map((f) => {
              let fdata = f.custom_form_field_properties;
              if (fdata.type === "MULTIPLE_CHOICE_SINGLE_SELECTION") {
                return {
                  type: "MULTIPLE_CHOICE_SINGLE_SELECTION",
                  question: fdata.description,
                  qualifying_question: fdata.multiple_choice_options.filter(
                    (o) => o.option_preferred_status === "PREFERRED" || o.option_preferred_status === "NOT_PREFERRED" ).length > 0,
                  options: fdata.multiple_choice_options.map((o) => {
                    return {
                      text: o.choice_description,
                      type: o.option_preferred_status,
                    };
                  }),
                };
              }
              if (fdata.type === "MULTIPLE_CHOICE_MULTI_SELECTION") {
                return {
                  type: "MULTIPLE_CHOICE_MULTI_SELECTION",
                  question: fdata.description,
                  options: fdata.multiple_choice_options.map((o) => {
                    return {
                      text: o.choice_description,
                    };
                  }),
                };
              }
              if (fdata.type === "DATE" || fdata.type === "TEXT") {
                return {
                  type: fdata.type,
                  question: fdata.description,
                };
              }
            });
            console.log(this.questions);

          this.fields = form_data?.form_fields
            .filter((f) => f.type !== "CUSTOM")
            .map((f) => {
              return {
                title: f.type,
                value: f.type,
              };
            });
          this.privacy_policy_url = form_data?.privacy_policy_url;
          this.title = form_data?.legal_disclosures.title;
          this.description = form_data?.legal_disclosures.description;
          this.consent = form_data?.legal_disclosures.consent_form_fields.map(
            (c) => {
              return {
                description: c.consent_description,
                required: c.required,
              };
            }
          );
          this.call_to_action = {
            button_text: form_data?.end_page_properties.call_to_action,
            website_url: form_data?.end_page_properties.url,
          };
        })
        .catch((err) => console.error(err));
    },
    resetForm() {
      this.questions = [];
      this.header_image = null;
      this.form_name = null;
      this.form_description = null;
      this.privacy_policy_url = null;
      this.title = null;
      this.description = null;
      this.consent = [];
    },
  },
});
