<template>
  <v-expansion-panels>
    <v-expansion-panel bg-color="transparent" elevation="0" hide-accordion>
      <v-expansion-panel-title v-slot="{ expanded }" hide-actions>
        <div class="d-flex align-center justify-space-between ga-2 w-100">
          <div class="d-flex align-center ga-2">
            <v-icon
              class="upload-span"
              style="padding: 0px; width: 32px"
              :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            ></v-icon>
            <div class="d-flex flex-column ga-1">
              <span>Q{{ index + 1 }}</span>
            </div>
          </div>
        </div>
      </v-expansion-panel-title>

      <v-expansion-panel-text>
        <div class="pa-5 snapchat d-flex flex-column ga-3">
          <div class="d-flex align-center ga-2 mx-5">
            <input
              :value="
                question.custom_form_field_properties.multiple_choice_options.some(
                  (option) =>
                    option.type === 'PREFERRED' ||
                    option.type === 'NOT_PREFERRED'
                )
              "
              type="checkbox"
              :id="question.id"
            />
            <div class="d-flex flex-column ga-1">
              <span>Qualifying Question</span>
              <span class="small-text"
                >Ask Snapchatters a question to see if they're a preferred match
                for your business.</span
              >
            </div>
          </div>
          <div class="filed mt-3">
            <span class="title">Question</span>
            <div class="input-container">
              <input
                type="text"
                v-model="question.custom_form_field_properties.description"
              />
            </div>
          </div>
          <div>Answers</div>
          <div
            v-for="option in question.custom_form_field_properties
              .multiple_choice_options"
            :key="option"
            class="filed mt-3 d-flex align-center ga-2"
          >
            <div class="input-container w-100">
              <input type="text" :value="option.choice_description" />
            </div>
            <div
              v-if="
                question.custom_form_field_properties.multiple_choice_options.some(
                  (option) =>
                    option.type === 'PREFERRED' ||
                    option.type === 'NOT_PREFERRED'
                )
              "
              type="checkbox"
              :id="question.id"
              class="input-container w-100"
            >
              <v-select
                :value="option?.option_preferred_status"
                :items="[
                  { text: 'Preferred', value: 'preferred' },
                  { text: 'Not Preferred', value: 'not_preferred' },
                ]"
                item-title="text"
                item-value="value"
                label=""
                variant="solo"
                class="v-select-style"
              ></v-select>
            </div>
            <div class="cursor-pointer"></div>
          </div>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { useLeadFormStore } from "@/store/snapchat/LeadForm";

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      store: useLeadFormStore(),
    };
  },
  methods: {
    addOption() {
      this.question.options.push({ text: "", type: "" });
    },
    methods: {
      updateOption(questionIndex, optionIndex) {
        const selectedValue =
          this.store.questions[questionIndex].options[optionIndex].type;
        if (selectedValue === "preferred") {
          this.store.questions[questionIndex].options.forEach((option) => {
            option.type = "not_preferred";
          });
          this.store.questions[questionIndex].options[optionIndex].type =
            "preferred";
        }
      },
    },
  },
};
</script>
<style scoped>
.sds-icon-trash:hover {
  fill: #0894fa;
}
</style>
