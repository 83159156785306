<template>
  <div class="taps-platform">
    <v-tabs v-model="tab" rounded="lg" density="compact">
      <v-tab v-for="data in activePlatforms" :key="data" :value="data">
        <img :src="store.boost[data].icon" alt="" /> {{ data }}
      </v-tab>
    </v-tabs>
    <div v-if="store.loading" class="loading">
      <div id="loader">
        <svg class="circle-outer" viewBox="0 0 86 86">
          <circle class="back" cx="43" cy="43" r="40"></circle>
          <circle class="front" cx="43" cy="43" r="40"></circle>
          <circle class="new" cx="43" cy="43" r="40"></circle>
        </svg>
        <svg class="circle-inner" viewBox="0 0 34 34">
          <circle class="back" cx="17" cy="17" r="14"></circle>
          <circle class="front" cx="17" cy="17" r="14"></circle>
        </svg>
        <div class="text" data-text="Loading.."></div>
      </div>
    </div>
    <v-card-text class="px-0">
      <v-tabs-window v-model="tab">
        <v-tabs-window-item
          v-for="data in activePlatforms"
          :key="data"
          :value="data"
        >
          <MetaGoals v-if="data === 'facebook'"></MetaGoals>
          <AdvantageCreative v-if="data === 'facebook'" />
          <CalltoActions v-if="data === 'facebook'" />
          <MessageTemplate v-if="data === 'facebook'" />
          <SpecialAdcategory v-if="data === 'facebook'" />
          <AudienceComponent v-if="data === 'facebook'" />
          <DurationsCom v-if="data === 'facebook'" />
          <PlacementType v-if="data === 'facebook'" />
          <MetaPixel v-if="data === 'facebook'" />
          <div class="send-request mt-3">

            <button @click="store.SendPostToPublish()">
               save
            </button>
          </div>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </div>
</template>

<script>
import { publishPost } from "@/store/publishPost/publishPost.js";
import {boostPost} from "@/store/publishPost/boostPost.js";
import MetaGoals from "./MetaGoals.vue";
import AdvantageCreative from "./AdvantageCreative.vue";
import CalltoActions from "./CalltoActions.vue";
import MessageTemplate from "./MessageTemplate.vue";
import SpecialAdcategory from "./SpecialAdcategory.vue";
import AudienceComponent from "./AudienceComponent.vue";
import DurationsCom from "./DurationsCom.vue";
import PlacementType from "./PlacementType.vue";
import MetaPixel from "./MetaPixel.vue";
export default {
  components: {
    MetaGoals,
    AdvantageCreative,
    CalltoActions,
    MessageTemplate,
    SpecialAdcategory,
    AudienceComponent,
    DurationsCom,
    PlacementType,
    MetaPixel,
  },
  data() {
    return {
      tab: null,
      store: publishPost(),
      sendRequest: boostPost(),
    };
  },
  computed: {
    activePlatforms() {
      return Object.keys(this.store.boost).filter(
        (key) => this.store.boost[key].state
      );
    },
  },
};
</script>

<style scoped>
.send-request{
  display: flex;
  align-items: center;
  justify-content: flex-end;  
}
.send-request button {
  background-color: rgba(0, 115, 221, 1);
  color: #fff;
  border: none;
  padding: 9px 30px;
  display: block;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400; 
}

</style>
