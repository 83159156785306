<template>
  <div class="pa-4 dashboard-card" elevation="2">
    <div class="d-flex justify-space-between">
      <div class="d-flex flex-column ga-2">
        <span class="text-subtitle-1 font-weight-bold">Leads Per Platform</span>
        <span v-if="date_range.length" class="d-flex align-center date-range"
          >{{ store?.formatDateFromInput(date_range[0]) }} -
          {{
            store?.formatDateFromInput(date_range[date_range.length - 1])
          }}</span
        >
      </div>
      <div class="d-flex align-center ga-2">
        <span class="vuetify-date-input-icon cursor-pointer">
          <v-date-input
            hide-details
            variant="plain"
            prepend-icon=""
            prepend-inner-icon="$calendar"
            density="compact"
            multiple="range"
            clearable
            format="YYYY-MM-DD HH:mm:ss"
            v-model="date_range"
          >
            <template v-slot:text-field="{ value }">
              <v-text-field
                :model-value="formatDate(value)"
                readonly
                variant="plain"
              ></v-text-field>
            </template>
          </v-date-input>
        </span>

        <v-select
          v-model="filter"
          :items="filter_days"
          item-title="title"
          item-value="value"
        ></v-select>
      </div>
    </div>
    <div class="">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="d-flex justify-space-between align-center py-3"
      >
        <div class="logo-name d-flex align-center ga-3">
          <img
            width="24px"
            :src="
              item.platform === 'Snapchat'
                ? SnapchatIcon
                : item.platform === 'Instagram'
                ? InstagramIcon
                : item.platform === 'Facebook'
                ? FacebookIcon
                : item.platform === 'TikTok'
                ? TikTokIcon
                : item.platform === 'LinkedIn'
                ? LinkedIn
                : xIcon
            "
            :alt="item.platform"
          />
          <span>{{ item.platform }}</span>
        </div>

        <div
          class="number-chart-percent d-flex align-center justify-end ga-2 w-50"
        >
          <div class="number">{{ item.total }}</div>
          <div class="chart">
            <span
              class="background"
              :style="{ width: item.percentage + '%' }"
            ></span>
          </div>
          <div class="percent">{{ item.percentage }} %</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SnapchatIcon from "@/assets/images/icons/snapcaht.svg";
import xIcon from "@/assets/images/icons/x.svg";
import InstagramIcon from "@/assets/images/icons/instagram.svg";
import FacebookIcon from "@/assets/images/icons/facebook.svg";
import TikTokIcon from "@/assets/images/icons/tiktok.svg";
import LinkedIn from "@/assets/images/icons/linkedIn.svg";
import phoneIcon from "@/assets/images/icons/phone.svg";
import { VDateInput } from "vuetify/labs/VDateInput";
import { useLeadsStore } from "@/store/crm/useLeadsStore";
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  components: { VDateInput },

  data() {
    return {
      SnapchatIcon,
      xIcon,
      InstagramIcon,
      FacebookIcon,
      TikTokIcon,
      LinkedIn,
      phoneIcon,
      filter: "",
      filter_days: [
        { title: "Last Day", value: "last_day" },
        { title: "Last Week", value: "last_week" },
        { title: "Last Month", value: "last_month" },
        { title: "Last Year", value: "last_year" },
      ],
      store: useLeadsStore(),
      date_range: [],
    };
  },
  methods: {
    emitData() {
      this.$emit(
        "filter",
        this.store?.formatDateFromInput(this.date_range[0]),
        this.store?.formatDateFromInput(
          this.date_range[this.date_range.length - 1]
        ),
        this.filter
      );
    },
  },
  watch: {
    filter() {
      this.emitData();
    },
    date_range() {
      this.emitData();
    },
  },
};
</script>
