import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";
import { useGeneralStore } from "@/store/General";

export const useAwarenessStore = defineStore("awareness", {
  state: () => ({
    adsquads: [],
    ads: [],
    organization_id: "",
    public_profile_id: "",
    account_id: "",
    img: "",
    creative: [],
    campaigns: [],
    validationData: [],
    organizations: [],
    discover_tile: {},
    story_ad_creatives: [],
    story_ad_story: [],
    created: false,
  }),

  actions: {
    async sendCreative() {
      try {
        const response = await this.getAllOrganizations();
        if (response.data.key === "success") {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (error) {
        console.error("Error during creative creation:", error);
        throw error;
      }
    },

    async getAllOrganizations() {
      try {
        const response = await apiClient.get(
          "/campaign-ads/snapchat/organizations-adaccounts"
        );
        this.organizations = response.data.data.organizations;
        this.organizations.forEach((org) => {
          org.disabled = true;
        });
      } catch (error) {
        console.error("Error fetching organization data:", error);
        throw error;
      }
    },

    async getUserProfileId() {
      if (!localStorage.getItem("organization_id")) return;
      try {
        const response = await apiClient.get(
          `/campaign-ads/snapchat/user/${localStorage.getItem(
            "organization_id"
          )}`
        );
        this.public_profile_id =
          response.data.data.public_profiles[0].public_profile.id;
      } catch (error) {
        console.error("Error fetching user profile:", error);
        throw error;
      }
    },

    async getAllAccounts() {
      try {
        const response = await apiClient.get(
          `/campaign-ads/snapchat/get/all/ad-accounts/${this.organization_id}`
        );
        this.account_id = response.data.data.id;
        localStorage.setItem("account_id", this.account_id);
      } catch (error) {
        console.error("Error fetching accounts:", error);
        throw error;
      }
    },

    async CreateUploadContainer(name, type) {
      try {
        const payload = {
          media: [
            {
              name: name,
              type: type.toUpperCase(),
              ad_account_id: localStorage.getItem("account_id"),
            },
          ],
        };

        const response = await fetch(
          `https://adsapi.snapchat.com/v1/adaccounts/${localStorage.getItem(
            "account_id"
          )}/media`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("snapchat_token")}`,
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          console.error("🚨 Snapchat API Error:", data);
          throw new Error(
            `Error creating media container: ${
              data.display_message || "Unknown error"
            }`
          );
        }
        localStorage.setItem("media_container_id", data.media[0]?.media.id);
        return data.media[0]?.media.id;
      } catch (error) {
        console.error("❌ Error creating media container:", error.message);
        throw error;
      }
    },
    async UploadMedia(file) {
      if (!file) return;
      const mediaId = localStorage.getItem("media_container_id");
      const token = localStorage.getItem("snapchat_token");

      const formData = new FormData();
      formData.append("file", file);
      formData.append("filename", file.name);

      try {
        const response = await fetch(
          `https://adsapi.snapchat.com/v1/media/${mediaId}/upload`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("❌ Upload Error:", error);
        this.uploadStatus = "Upload Failed!";
      }
    },
    async GetMedia(mediaId) {
      try {
        const response = await fetch(
          `https://adsapi.snapchat.com/v1/media/${mediaId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("snapchat_token")}`,
            },
          }
        );
        const data = await response.json();
        if (!response.ok) {
          // throw new Error(data.request_status || "failed");
          return false;
        }
        return data.media.find((m) => m.media.id === mediaId).media;
      } catch (error) {
        console.error("Error fetching media:", error);
        // throw error;
      }
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }

      return new Blob([uintArray]);
    },

    createFileFromBlob(blob, name, type, size) {
      return new File([blob], name, { type, size });
    },

    getFileFromStorage(key) {
      const storedData = JSON.parse(localStorage.getItem(key));
      if (!storedData) return null;

      const { name, type, size, content } = storedData;
      const blob = this.dataURItoBlob(content);
      return this.createFileFromBlob(blob, name, type, size);
    },

    getFromLocalStorage(key, defaultValue = "") {
      return localStorage.getItem(key) || defaultValue;
    },

    async getFileFromIndexedDB(fileId) {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open("FileStorage", 1);

        request.onerror = () => reject(new Error("Failed to open IndexedDB"));

        request.onsuccess = (event) => {
          const db = event.target.result;
          const transaction = db.transaction(["files"], "readonly");
          const store = transaction.objectStore("files");
          const getRequest = store.get(fileId);

          getRequest.onsuccess = () => {
            const fileData = getRequest.result;
            if (fileData) {
              const { name, type, size, content } = fileData;
              const blob = this.dataURItoBlob(content);
              resolve(this.createFileFromBlob(blob, name, type, size));
            } else {
              resolve(null);
            }
          };

          getRequest.onerror = () =>
            reject(new Error("Failed to retrieve file from IndexedDB"));
        };
      });
    },
    removeNullKeys(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, value]) => value !== null)
          .map(([key, value]) =>
            value && typeof value === "object"
              ? [key, this.removeNullKeys(value)]
              : [key, value]
          )
      );
    },
    async validateSingleImageOrVideoCreative() {
      let type = "";
      if (this.getCampaignType() === "LEADS") {
        type = "LEAD_GENERATION";
      } else if (localStorage.getItem("selected_ad_type") === "None") {
        type = "SNAP_AD";
      } else {
        type = localStorage.getItem("selected_ad_type");
      }

      this.creative = {
        name: localStorage.getItem("ad_name") || "",
        type: type,
        brand_name: localStorage.getItem("brand_name") || "",
        headline: localStorage.getItem("headline") || "",
        shareable: localStorage.getItem("shareable") === "true" ? true : false,
        offer_disclaimer_id:
          localStorage.getItem("offer_details_ad_disclaimer") || "",
        profile_properties: {
          profile_id: this.public_profile_id || "",
        },

        favorite_display_mode:
          localStorage.getItem("selected_ad_type") !== "None"
            ? localStorage.getItem("ad_favoriting") === "true"
              ? "HIDE"
              : "SHOW"
            : null,

        cta_color_display_mode:
          localStorage.getItem("selected_ad_type") !== "None"
            ? localStorage.getItem("standard_button_color") === "true"
              ? "DEFAULT_COLOR"
              : "AUTO_COLOR_DETECTION"
            : null,

        call_to_action:
          localStorage.getItem("selected_ad_type") !== "None"
            ? localStorage.getItem("call_to_action")
            : null,

        top_snap_media_id:
          localStorage.getItem("single_image_or_video_tosnap_file") || "",

        top_snap_crop_position:
          localStorage.getItem("top_snap_crop_position") || "MIDDLE",
        ad_account_id: localStorage.getItem("account_id"),

        // third_party_paid_impression_tracking_urls: localStorage.getItem('impression_trackers') ? JSON.parse(localStorage.getItem('impression_trackers')) : [],
        // third_party_on_swipe_tracking_urls: localStorage.getItem('click_trackers') ? JSON.parse(localStorage.getItem('click_trackers')) : [],

        // website
        web_view_properties:
          localStorage.getItem("selected_ad_type") === "WEB_VIEW"
            ? {
                url: localStorage.getItem("website_url") || "",
                block_preload:
                  localStorage.getItem("smart_prefetching") === "true"
                    ? true
                    : false,
              }
            : null,

        // app install
        app_install_properties:
          localStorage.getItem("selected_ad_type") === "APP_INSTALL"
            ? {
                app_name: localStorage.getItem("app_name") || "",
                ios_app_id: localStorage.getItem("ios_app_id") || "",
                android_app_url: localStorage.getItem("android_app_url") || "",
                icon_media_id:
                  localStorage.getItem("image_app_icon_file") || "",
                product_page_id:
                  localStorage.getItem("custom_ios_product_page_id") || null,
              }
            : null,

        // deep link

        deep_link_properties:
          localStorage.getItem("selected_ad_type") === "DEEP_LINK"
            ? {
                deep_link_uri: localStorage.getItem("deep_link_uri") || null,
                app_name: localStorage.getItem("app_name") || null,
                ios_app_id: localStorage.getItem("ios_app_id") || null,
                android_app_url:
                  localStorage.getItem("android_app_url") || null,
                fallback_type:
                  localStorage.getItem("fallback_type") === "app_install"
                    ? "APP_INSTALL"
                    : "WEB_SITE",
                web_view_fallback_url:
                  localStorage.getItem("fallback_type") === "web_site"
                    ? localStorage.getItem("fallback_website_url")
                    : null,
                product_page_id:
                  localStorage.getItem("fallback_type") === "app_install"
                    ? localStorage.getItem("custom_ios_product_page_id")
                    : null,
                icon_media_id:
                  localStorage.getItem("image_app_icon_file") || null,
              }
            : null,
        lead_generation_form_id:
          this.getCampaignType() === "LEADS"
            ? localStorage.getItem("lead_form_id")
            : null,
      };

      let CreativeValidations = [];

      if (!this.creative.name) {
        CreativeValidations.push({
          message: "Ad Name is required",
          key: "Name",
          id: "name",
        });
      }
      if (!this.creative.type) {
        CreativeValidations.push({
          message: "Ad Type is required",
          key: "Type",
          id: "type",
        });
      }
      if (!this.creative.brand_name) {
        CreativeValidations.push({
          message: "Brand Name is required",
          key: "brand_name",
          id: "brand_name",
        });
      }
      if (!this.creative.headline) {
        CreativeValidations.push({
          message: "Headline is required",
          key: "headline",
          id: "headline",
        });
      }
      if (!this.creative.top_snap_media_id) {
        CreativeValidations.push({
          message: "Top Snap Media (file) is required",
          key: "top_snap_media_id",
          id: "top_snap_media_id",
        });
      }
      if (
        !this.creative.call_to_action &&
        localStorage.getItem("selected_ad_type") !== "None"
      ) {
        CreativeValidations.push({
          message: "Call to Action is required",
          key: "call_to_action",
          id: "call_to_action",
        });
      }
      if (!this.creative.profile_properties.profile_id) {
        CreativeValidations.push({
          message: "Profile ID is required",
          key: "profile_properties.profile_id",
          id: "profile_properties_profile_id",
        });
      }
      switch (this.creative.creative_type) {
        case "APP_INSTALL":
          if (!this.creative.app_install_properties) break;

          if (!this.creative.app_install_properties.app_name) {
            CreativeValidations.push({
              message: `The App Name field is required when type is ${this.creative.creative_type}`,
              key: "app_name",
              id: "app_name",
            });
          }
          if (!this.creative.app_install_properties.ios_app_id) {
            CreativeValidations.push({
              message: `The iOS App ID is required when type is ${this.creative.creative_type}`,
              key: "ios_app_id",
              id: "ios_app_id",
            });
          }
          if (!this.creative.app_install_properties.android_app_url) {
            CreativeValidations.push({
              message: `The Android App URL is required when type is ${this.creative.creative_type}`,
              key: "android_app_url",
              id: "android_app_url",
            });
          }
          if (!this.creative.app_install_properties.icon_media_id) {
            CreativeValidations.push({
              message: `The Icon Media ID is required when type is ${this.creative.creative_type}`,
              key: "icon_media_id",
              id: "icon_media_id",
            });
          }
          if (!this.creative.app_install_properties.product_page_id) {
            CreativeValidations.push({
              message: `The Product Page ID is required when type is ${this.creative.creative_type}`,
              key: "product_page_id",
              id: "product_page_id",
            });
          }
          break;
        case "WEB_VIEW":
          if (!this.creative.web_view_properties) break;

          if (!this.creative.web_view_properties.url) {
            CreativeValidations.push({
              message: `The Website URL is required when type is ${this.creative.creative_type}`,
              key: "website_url",
              id: "website_url",
            });
          }
          break;
        case "DEEP_LINK":
          if (!this.creative.deep_link_properties) break;

          if (!this.creative.deep_link_properties.deep_link_uri) {
            CreativeValidations.push({
              message: `The Deep Link URI is required when type is ${this.creative.creative_type}`,
              key: "deep_link_uri",
              id: "deep_link_uri",
            });
          }
          if (!this.creative.deep_link_properties.app_name) {
            CreativeValidations.push({
              message: `The App Name is required when type is ${this.creative.creative_type}`,
              key: "app_name",
              id: "app_name",
            });
          }
          if (!this.creative.deep_link_properties.ios_app_id) {
            CreativeValidations.push({
              message: `The iOS App ID is required when type is ${this.creative.creative_type}`,
              key: "ios_app_id",
              id: "ios_app_id",
            });
          }
          if (!this.creative.deep_link_properties.android_app_url) {
            CreativeValidations.push({
              message: `The Android App URL is required when type is ${this.creative.creative_type}`,
              key: "android_app_url",
              id: "android_app_url",
            });
          }
          if (!this.creative.deep_link_properties.icon_media_id) {
            CreativeValidations.push({
              message: `The Icon Media ID is required when type is ${this.creative.creative_type}`,
              key: "icon_media_id",
              id: "icon_media_id",
            });
          }
          if (!this.creative.deep_link_properties.product_page_id) {
            CreativeValidations.push({
              message: `The Product Page ID is required when type is ${this.creative.creative_type}`,
              key: "product_page_id",
              id: "product_page_id",
            });
          }
          if (
            this.creative.deep_link_properties.fallback_type === "WEB" &&
            !this.creative.deep_link_properties.web_view_fallback_url
          ) {
            CreativeValidations.push({
              message: `The Fallback Website URL is required when fallback type is WEB for ${this.creative.creative_type}`,
              key: "fallback_website_url",
              id: "fallback_website_url",
            });
          }
          break;
        default:
          break;
      }
      if (
        this.getCampaignType === "LEADS" &&
        !this.creative.lead_generation_form
      ) {
        CreativeValidations.push({
          message: "form is required",
          key: "lead_generation_form",
          id: "lead_generation_form",
        });
      }
      if (CreativeValidations.length > 0) {
        this.validationData.push({
          type: "creative",
          error: "validation",
          data: CreativeValidations,
          path: "SnapThirdStep",
        });
      }
      this.creative = this.removeNullKeys(this.creative);
      // apiClient
      //   .post("/campaign-ads/snapchat/create/creative", {
      //     creatives: [this.creative],
      //   })
      //   .then((response) => {
      //     console.log(response);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    async validateStoryAdCreative() {
      await this.ValidateDiscoverTile();
      await this.ValidateStoryAdCreatives();
      await this.ValidateStoryAdStory();
    },
    async ValidateDiscoverTile() {
      this.discover_tile = {
        ad_account_id: localStorage.getItem("account_id"),
        name: "Story Ad Preview",
        type: "PREVIEW",
        preview_properties: {
          preview_media_id: localStorage.getItem("background_image_file"),
          logo_media_id: localStorage.getItem("logo_image_file"),
          preview_headline: localStorage.getItem("discover_tile_headline"),
        },
        profile_properties: {
          profile_id: this.public_profile_id,
        },
      };
    },
    async ValidateStoryAdCreatives() {
      const storiesSnaps = JSON.parse(localStorage.getItem("story_ad"));
      let ads = [];
      storiesSnaps.forEach((storySnap, index) => {
        switch (storySnap.selected_ad_type) {
          case "APP_INSTALL":
            ads.push({
              name: `Story Ad ${index + 1}`,
              call_to_action: storySnap.call_to_action,
              type: "APP_INSTALL",
              ad_account_id: localStorage.getItem("account_id"),
              brand_name: localStorage.getItem("brand_name"),
              offer_disclaimer_id:
                localStorage.getItem("offer_details_ad_disclaimer") || "",
              favorite_display_mode:
                storySnap.ad_favoriting === "true" ? "HIDE" : "SHOW",
              headline: localStorage.getItem("headline"),
              top_snap_media_id: storySnap.media_id,
              shareable: localStorage.getItem("shareable") === "true",
              profile_properties: {
                profile_id: this.public_profile_id || "",
              },
              app_install_properties: {
                app_name: storySnap.app_name,
                ios_app_id: storySnap.ios_app_id,
                android_app_url: storySnap.android_app_url,
                icon_media_id: storySnap.image_app_icon_id,
                product_page_id: storySnap.custom_ios_product_page_id,
              },
            });
            break;
          case "DEEP_LINK":
            ads.push({
              name: `Story Ad ${index + 1}`,
              call_to_action: storySnap.call_to_action,
              type: "DEEP_LINK",
              ad_account_id: localStorage.getItem("account_id"),
              brand_name: localStorage.getItem("brand_name"),
              offer_disclaimer_id:
                localStorage.getItem("offer_details_ad_disclaimer") || "",
              favorite_display_mode:
                storySnap.ad_favoriting === "true" ? "HIDE" : "SHOW",
              headline: localStorage.getItem("headline"),
              top_snap_media_id: storySnap.media_id,
              shareable: localStorage.getItem("shareable") === "true",
              profile_properties: {
                profile_id: this.public_profile_id || "",
              },

              // deep link
              deep_link_properties: {
                deep_link_uri: storySnap.deep_link_uri,
                app_name: storySnap.app_name,
                ios_app_id: storySnap.ios_app_id,
                android_app_url: storySnap.android_app_url,
                fallback_type:
                  storySnap.fallback_type === "app_install"
                    ? "APP_INSTALL"
                    : "WEB_SITE",

                web_view_fallback_url:
                  storySnap.fallback_type !== "app_install"
                    ? storySnap.fallback_website_url
                    : null,
                product_page_id:
                  storySnap.fallback_type === "app_install"
                    ? storySnap.custom_ios_product_page_id
                    : null,
                icon_media_id: storySnap.image_app_icon_id,
              },
            });
            break;
          case "WEB_VIEW":
            ads.push({
              name: `Story Ad ${index + 1}`,
              type: "WEB_VIEW",
              ad_account_id: localStorage.getItem("account_id"),
              headline: localStorage.getItem("headline"),
              brand_name: localStorage.getItem("brand_name"),
              top_snap_media_id: storySnap.media_id,
              profile_properties: {
                profile_id: this.public_profile_id || "",
              },
              call_to_action: storySnap.call_to_action,
              web_view_properties: {
                url: storySnap.website_url,
                block_preload:
                  localStorage.getItem("smart_prefetching") === "true"
                    ? true
                    : false,
              },
            });
            break;
          default:
            ads.push({
              name: `Story Ad ${index + 1}`,
              type: "SNAP_AD",
              ad_account_id: localStorage.getItem("account_id"),
              brand_name: localStorage.getItem("brand_name"),
              offer_disclaimer_id:
                localStorage.getItem("offer_details_ad_disclaimer") || "",
              headline: localStorage.getItem("headline") || "",
              top_snap_media_id: storySnap.media_id,
              profile_properties: {
                profile_id: this.public_profile_id || "",
              },
            });
            break;
        }
      });
      this.story_ad_creatives = ads;
    },
    async ValidateStoryAdStory() {
      let story = [];
      story.push({
        name: localStorage.getItem("ad_name"),
        type: "COMPOSITE",
        ad_account_id: localStorage.getItem("account_id"),
        brand_name: localStorage.getItem("brand_name"),
        top_snap_media_id: localStorage.getItem("story_media_id"),
        headline: localStorage.getItem("headline") || "",
        profile_properties: {
          profile_id: this.public_profile_id || "",
        },
        shareable: localStorage.getItem("shareable") === "true",
      });
      this.story_ad_story = story;
    },
    async validateCollectionAdCreative() {},
    async validateCreative() {
      this.validationData = [];
      try {
        switch (localStorage.getItem("creative_type")) {
          case "Single Image or Video":
            this.validateSingleImageOrVideoCreative();
            break;
          case "Story Ad":
            await this.validateStoryAdCreative();
            break;
          case "Collection Ad":
            this.validateCollectionAdCreative();
            break;
          default:
            break;
        }
      } catch (error) {
        console.error("Error in validateCreative:", error);
        throw error;
      }
    },

    async validateCampaigns() {
      try {
        this.campaigns = [
          {
            name: localStorage.getItem("campaign_name"),
            ad_account_id: localStorage.getItem("account_id"),
            type: "marketing",
            budget:
              localStorage.getItem("selected_budget") === "daily_budget"
                ? parseFloat(
                    localStorage
                      .getItem("daily_budget_micro")
                      ?.replace("$", "")
                      ?.replace(",", ".")
                      ?.trim()
                  )
                : parseFloat(
                    localStorage
                      .getItem("lifetime_spend_cap_micro")
                      ?.replace("$", "")
                      ?.replace(",", ".")
                      ?.trim()
                  ),
            platform: "snapchat",
            status:
              localStorage.getItem("status") === "true" ? "ACTIVE" : "PAUSED",
            start_time: localStorage.getItem("campaign_start_date_time")
              ? this.convertToISO(
                  localStorage.getItem("campaign_start_date_time")
                )
              : null,
            end_time: localStorage.getItem("campaign_end_date_time")
              ? this.convertToISO(
                  localStorage.getItem("campaign_end_date_time")
                )
              : null,
            lifetime_spend_cap_micro:
              localStorage.getItem("selected_budget") === "lifetime_budget"
                ? parseFloat(
                    localStorage
                      .getItem("lifetime_spend_cap_micro")
                      ?.replace("$", "")
                      ?.replace(",", ".")
                      ?.trim()
                  ) * 1000000
                : null,
            buy_model: "AUCTION",
            measurement_spec: {
              ios_app_id: "1234",
              android_app_url: "com.snapchat.android",
            },
            daily_budget_micro:
              localStorage.getItem("selected_budget") === "daily_budget"
                ? parseFloat(
                    localStorage
                      .getItem("daily_budget_micro")
                      ?.replace("€", "")
                      ?.replace(",", ".")
                      ?.trim()
                  ) * 1000000
                : null,
            objective: this.getCampaignType(),
            // regulations: {
            //   restricted_delivery_signals: false,
            // },
            promotion_type:
              localStorage.getItem("promote-places") === "true"
                ? "PROMOTE_PLACES"
                : null,
            conversion_location:
              this.getCampaignType === "LEADS"
                ? localStorage.getItem("direct_traffic_location")
                : null,
          },
        ];

        const objectives = [
          "BRAND_AWARENESS",
          "APP_CONVERSION",
          "APP_INSTALL",
          "CATALOG_SALES",
          "ENGAGEMENT",
          "LEAD_GENERATION",
          "VIDEO_VIEW",
          "WEB_CONVERSION",
          "PROMOTE_STORIES",
          "PROMOTE_PLACES",
        ];

        const campaignValidations = [];

        this.campaigns.forEach((campaign, index) => {
          if (!campaign.name) {
            campaignValidations.push({
              message: "Campaign name is required.",
              key: `campaigns name`,
              id: `campaigns[${index}].name`,
            });
          }

          if (!campaign.ad_account_id) {
            campaignValidations.push({
              message: "Ad account ID is required.",
              key: `campaigns[${index}].ad_account_id`,
            });
          }

          if (
            !campaign.status ||
            !["ACTIVE", "PAUSED"].includes(campaign.status)
          ) {
            campaignValidations.push({
              message:
                "Status is required and must be either 'ACTIVE' or 'PAUSED'.",
              key: `campaigns status`,
              id: `campaigns[${index}].status`,
            });
          }

          if (!campaign.start_time) {
            campaignValidations.push({
              message: "Start time is required and must be a valid date.",
              key: `campaigns start time`,
              id: `campaigns[${index}].start_time`,
            });
          }
          // if (!campaign.end_time) {
          //   campaignValidations.push({
          //     message: "End time is required and must be a valid date.",
          //     key: `campaigns End time`,
          //     id: `campaigns[${index}].end_time`,
          //   });
          // }
          if (campaign.objective && Array.isArray(campaign.objective)) {
            const validProperties = [
              "AWARENESS_AND_ENGAGEMENT",
              "SALES",
              "TRAFFIC",
              "APP_PROMOTION",
              "LEADS",
            ];
            campaign.objective.forEach((property) => {
              if (!validProperties.includes(property)) {
                campaignValidations.push({
                  message: `Invalid objective: ${property}.`,
                  key: `campaigns objective`,
                  id: `campaigns[${index}].objective`,
                });
              }
            });
          }
        });

        if (campaignValidations.length > 0) {
          this.validationData.push({
            type: "campaigns",
            error: "validation",
            data: campaignValidations,
            path: "SnapFirstStep",
          });
        }
      } catch (error) {
        console.error("Error in validateCampaigns:", error);
        throw error;
      }
    },
    getGeos() {
      let geos = [];

      const regions = JSON.parse(
        localStorage.getItem("selectedRegions") || "[]"
      );
      const countries = JSON.parse(
        localStorage.getItem("selectedCountries") || "[]"
      );
      countries.forEach((country) => {
        let inc_re = regions.filter(
          (region) =>
            region.country_code === country.code && region.type === "include"
        );
        if (inc_re?.length > 0) {
          inc_re.forEach((region) => {
            geos.push({
              country_code: country.code,
              region_id: [region.id],
              operation: "INCLUDE",
            });
          });
        } else {
          geos.push({
            country_code: country.code,
            operation: "INCLUDE",
          });
        }
        let ex_re = regions.filter(
          (region) =>
            region.country_code === country.code && region.type === "exclude"
        );
        if (ex_re?.length > 0) {
          ex_re.forEach((region) => {
            geos.push({
              country_code: country.code,
              region_id: [region.id],
              operation: "EXCLUDE",
            });
          });
        }
      });
      if (localStorage.getItem("hideMap") !== "true") {
        let map = JSON.parse(
          localStorage.getItem("selectedMapLocatoins") || "[]"
        );
        if (map?.length > 0) {
          geos.push({
            circles: map.map((location) => {
              return {
                latitude: location.latitude,
                longitude: location.longitude,
                radius: location.radius,
              };
            }),
          });
        }
      }
      return geos;
    },
    convertToISO(dateString) {
      // Create a new Date object from input
      const date = new Date(dateString.replace(" ", "T"));

      // Convert to ISO format (UTC) and remove milliseconds
      return date.toISOString().split(".")[0] + "Z";
    },
    getCarriersIds() {
      let ids = [];
      if (localStorage.getItem("selected_carriers")) {
        ids = JSON.parse(localStorage.getItem("selected_carriers")).map(
          (carrier) => carrier.id
        );
      }
      return ids;
    },
    getInterestsAudiences() {
      let auds = [];
      let s = JSON.parse(localStorage.getItem("selected_predefined_audiances"));
      if (s?.length > 0) {
        auds.push({
          category_id: s.map((aud) => aud.id),
        });
      }
      return auds;
    },
    getSegmentsAudiences() {
      let segments = [];
      let s = JSON.parse(localStorage.getItem("selected_custom_audience_list"));
      if (s?.length > 0) {
        let included = s.filter((segment) => segment.type === "include");
        if (included?.length > 0) {
          segments.push({
            segment_id: included.map((segment) => segment.id),
            operation: "INCLUDE",
          });
        }
        let excluded = s.filter((segment) => segment.type === "exclude");
        if (excluded?.length > 0) {
          segments.push({
            segment_id: excluded.map((segment) => segment.id),
            operation: "EXCLUDE",
          });
        }
      }
      return segments;
    },
    async validateAdsquads() {
      let bid_strategy = "";
      switch (localStorage.getItem("bid_selected_view")) {
        case "MAX_BID":
          bid_strategy = "LOWEST_COST_WITH_MAX_BID";
          break;
        case "AUTO_BID":
          bid_strategy = "AUTO_BID";
          break;
        case "TARGET_COST":
          bid_strategy = "TARGET_COST";
          break;
        default:
          bid_strategy = "AUTO_BID";
      }
      try {
        this.adsquads = [
          {
            name: localStorage.getItem("ad_set_details"),
            type: "SNAP_ADS",
            optimization_goal: localStorage.getItem("selected_goal"),
            bid_strategy: bid_strategy,
            bid_micro:
              localStorage.getItem("bid_selected_view") === "MAX_BID" ||
              localStorage.getItem("bid_selected_view") === "MIN_BID"
                ? parseFloat(localStorage.getItem("goal_bid_budget")) * 1000000
                : null,
            billing_event: "IMPRESSION",
            start_time:
              localStorage.getItem("selected_budget") === "daily_budget"
                ? localStorage.getItem("daily_budget_start_date_time")
                : localStorage.getItem("budget_lifetime_start_date_time"),
            end_time:
              localStorage.getItem("selected_budget") === "daily_budget"
                ? localStorage.getItem("daily_budget_end_date_time")
                : localStorage.getItem("lifetime_budget_end_date_time"),
            lifetime_budget_micro:
              localStorage.getItem("selected_budget") === "lifetime_budget"
                ? parseFloat(
                    localStorage
                      .getItem("lifetime_spend_cap_micro")
                      ?.replace("$", "")
                      ?.replace(",", ".")
                      ?.trim()
                  ) * 1000000
                : null,
            daily_budget_micro:
              localStorage.getItem("selected_budget") === "daily_budget"
                ? parseFloat(
                    localStorage
                      .getItem("daily_budget_micro")
                      ?.replace("$", "")
                      ?.replace(",", ".")
                      ?.trim()
                  ) * 1000000
                : null,
            ad_scheduling_config:
              localStorage.getItem("ad_scheduling_time") ===
              "ad-scheduling-at-scheduling-time"
                ? JSON.parse(localStorage.getItem("selected_schedule_times"))
                : null,
            status:
              localStorage.getItem("ad_set_status") === "true"
                ? "ACTIVE"
                : "PAUSED",
            story_ad_creative_type:
              localStorage.getItem("creative_type") === "Story Ad"
                ? this.creative?.type
                : null,

            cap_and_exclusion_config:
              this.getCampaignType === "BRAND_AWARENESS"
                ? {
                    frequency_cap_config: [
                      {
                        frequency_cap_count: 4,
                        time_interval: 48,
                        frequency_cap_interval: "HOURS",
                        frequency_cap_type: "IMPRESSIONS",
                      },
                    ],
                  }
                : null,
            placement_v2: {
              config: this.getPlacementConfig(),
              platforms:
                this.getPlacementConfig() === "CUSTOM" ? ["SNAPCHAT"] : null,
              snapchat_positions:
                this.getPlacementConfig() === "CUSTOM"
                  ? this.getSnapchatPositions()
                  : null,
              inclusion:
                this.getPlacementConfig() === "CUSTOM"
                  ? this.getinclusionConfig()
                  : null,
              exclusion:
                this.getPlacementConfig() === "CUSTOM"
                  ? this.getexclusionConfig()
                  : null,
            },
            brand_safety_config:
              localStorage.getItem("brand_safety_filter") === "full_inventory"
                ? "FULL_INVENTORY"
                : "LIMITED_INVENTORY",
            child_ad_type: "SNAP_AD",
            // conversion_window: "SWIPE_28DAY_VIEW_1DAY",
            targeting: {
              devices: this.getDevices(),
              demographics: [
                {
                  gender:
                    localStorage.getItem("demographics_gender") !== "OTHER"
                      ? localStorage.getItem("demographics_gender")
                      : null,
                  min_age: parseInt(
                    Number(
                      localStorage.getItem(
                        "age_restrictions_required_start_age"
                      )
                    )
                  ),
                  max_age: parseInt(
                    Number(
                      localStorage.getItem(
                        "age_restrictions_required_start_end_age"
                      )
                    )
                  ),
                  languages: JSON.parse(
                    localStorage.getItem("selected_language")
                  )?.map((lang) => lang.id),
                },
              ],
              geos: this.getGeos(),
              regulated_content:
                localStorage.getItem("age_restrictions_required") === "true",
              segments: this.getSegmentsAudiences(),
              interests: this.getInterestsAudiences(),
              enable_targeting_expansion:
                localStorage.getItem("expand_predefined_audiences") ===
                  "true" ||
                localStorage.getItem("expand_custom_audiences") === "true",
              auto_expansion_options: {
                interest_expansion_option: {
                  enabled:
                    localStorage.getItem("expand_predefined_audiences") ===
                    "true",
                },
                custom_audience_expansion_options: {
                  enabled:
                    localStorage.getItem("expand_custom_audiences") === "true",
                },
              },
            },
            ad_squad_status:
              localStorage.getItem("ad_set_status") === "true"
                ? "ACTIVE"
                : "PAUSED",
            measurement_provider_names: [
              localStorage.getItem("third_party_tragging") === "true"
                ? "DOUBLEVERIFY"
                : null,
            ],
          },
        ];

        let validationErrors = [];

        this.adsquads.forEach((ad, index) => {
          if (!ad.name) {
            validationErrors.push({
              message: `Name is required for adsquad at index ${index}`,
              key: `ads name`,
              id: `adsquads[${index}].name`,
            });
          }

          if (!ad.type) {
            validationErrors.push({
              message: `Type is required for adsquad at index ${index}`,
              key: `ads type`,
              id: `adsquads[${index}].type`,
            });
          }
          if (bid_strategy !== "AUTO_BID" && !ad.bid_micro) {
            validationErrors.push({
              message: `Bid Budget is required for adsquad at index ${index}`,
              key: `Bid Budget`,
              id: `adsquads[${index}].bid_micro`,
            });
          }
          if (!ad.targeting.demographics[0].min_age) {
            validationErrors.push({
              message: `Min age is required for adsquad at index ${index}`,
              key: `min age`,
              id: `adsquads[${index}].min_age`,
            });
          }
          if (!ad.targeting.demographics[0].max_age) {
            validationErrors.push({
              message: `Max age is required for adsquad at index ${index}`,
              key: `max age`,
              id: `adsquads[${index}].max_age`,
            });
          }
          if (
            ![
              "IMPRESSIONS",
              "APP_INSTALLS",
              "SWIPES",
              "PIXEL_PURCHASE",
              "STORY_OPENS",
              "LEAD_FORM_SUBMISSIONS",
              "APP_ADD_TO_CART",
              "APP_SIGNUP",
              "APP_PURCHASE",
              "USES",
              "PIXEL_SIGNUP",
              "APP_PURCHASE",
              "APP_REENGAGE_OPEN",
              "VIDEO_VIEWS",
              "VIDEO_VIEWS_15_SEC",
              "LANDING_PAGE_VIEW",
              "APP_REENGAGE_OPEN",
              "APP_REENGAGE_PURCHASE",
              ,
            ].includes(ad.optimization_goal)
          ) {
            validationErrors.push({
              message: `Invalid optimization goal for adsquad`,
              key: `ads optimization goal`,
              id: `adsquads[${index}].optimization_goal`,
            });
          }

          if (!ad.billing_event) {
            validationErrors.push({
              message: `Billing event is required for adsquad at index ${index}`,
              key: `ads billing event`,
              id: `adsquads[${index}].billing_event`,
            });
          }

          if (!ad.start_time) {
            validationErrors.push({
              message: `Start time is required for adsquad at index ${index}`,
              key: `ads start time`,
              id: `adsquads[${index}].start_time`,
            });
          }

          if (!ad.end_time) {
            validationErrors.push({
              message: `End time is required for adsquad at index ${index}`,
              key: `ads end time`,
              id: `adsquads[${index}].end_time`,
            });
          }

          if (ad.lifetime_budget_micro && ad.daily_budget_micro) {
            validationErrors.push({
              message: `Cannot set both daily and lifetime budgets for adsquad at index ${index}`,
              key: `ads budget conflict`,
              id: `adsquads[${index}].budget_conflict`,
            });
          }
          if (localStorage.getItem("selected_budget") === "daily_budget") {
            if (!ad.daily_budget_micro) {
              validationErrors.push({
                message: `Daily budget is required for adsquad at index ${index}`,
                key: `ads daily budget`,
                id: `adsquads[${index}].daily_budget_micro`,
              });
            }
          } else {
            if (!ad.lifetime_budget_micro) {
              validationErrors.push({
                message: `Lifetime budget is required for adsquad at index ${index}`,
                key: `ads lifetime budget`,
                id: `adsquads[${index}].lifetime_budget_micro`,
              });
            }
          }
          if (ad.bid_micro === null && ad.optimization_goal === "IMPRESSIONS") {
            validationErrors.push({
              message: `Bid micro is required when optimization goal is IMPRESSIONS for adsquad at index ${index}`,
              key: `ads bid micro`,
              id: `adsquads[${index}].bid_micro`,
            });
          }
          // if (
          //   !JSON.parse(localStorage.getItem("selectedCountries")).length ===
          //     0 ||
          //   !JSON.parse(localStorage.getItem("selectedRegions")).length === 0
          // ) {
          //   validationErrors.push({
          //     message: `At least one country code is required`,
          //     key: `ads targeting geos`,
          //     id: `adsquads[${index}].targeting_geos`,
          //   });
          // }
        });

        if (validationErrors.length > 0) {
          this.validationData.push({
            type: "adsquad",
            error: "validation",
            data: validationErrors,
            path: "SnapSecoundStep",
          });
        }
      } catch (error) {
        console.error("Error in validateAdsquads:", error);
        throw error;
      }
    },

    async create() {
      try {
        await this.validateCreative();
        await this.validateCampaigns();
        await this.validateAdsquads();
        const data = {};
        if (this.validationData.length > 0) {
          window.scrollTo({ top: 0, behavior: "smooth" });
          data.error = "validation";
          data.data = this.validationData;
          return data;
        }
        let creative = {};
        switch (localStorage.getItem("creative_type")) {
          case "Single Image or Video":
            const formData = new FormData();
            this.appendFormData(formData, { creatives: [this.creative] });
            const response = await apiClient.post(
              "/campaign-ads/snapchat/create/creative",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response.data.key !== "success") {
              this.validationData = [
                {
                  type: response.data.key,
                  error: "validation",
                  data: [
                    {
                      message: response.data.message,
                      key: response.data.key,
                      id: response.data.key,
                    },
                  ],
                  path: "SnapThirdStep",
                },
              ];
              if (this.validationData.length > 0) {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }
              useGeneralStore().showSnackbar("Something went wrong", "error");
              break;
            }
            creative = response.data.data.creatives[0].creative;
            console.log("Creative created successfully:", response);
            break;
          case "Story Ad":
            const res = await apiClient.post(
              "/campaign-ads/snapchat/create-story-ad-creative",
              {
                "preview[creatives]": [this.discover_tile],
                media: this.story_ad_creatives.map((creative) => {
                  return {
                    creatives: [creative],
                  };
                }),
                "story[creatives]": this.story_ad_story,
              }
            );
            if (res.data.key !== "success") {
              this.validationData = [
                {
                  type: res.data.key,
                  error: "validation",
                  data: [
                    {
                      message: res.data.message,
                      key: res.data.key,
                      id: res.data.key,
                    },
                  ],
                  path: "SnapThirdStep",
                },
              ];
              if (this.validationData.length > 0) {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }
              useGeneralStore().showSnackbar("Something went wrong", "error");
            }
            creative = res.data.data[0].creative;
            break;
          case "Collection Ad":
            alert("sooooooooooon");
            break;
          default:
            break;
        }
        console.log(creative);
        if (creative) {
          const r = await this.createcampaign(creative);
          return r;
        }
      } catch (error) {
        console.error("Error creating creative:", error);
        throw error;
      }
    },

    async createcampaign(creative = null) {
      try {
        const campaigns = this.campaigns;
        const adsquads = this.adsquads;
        let adsType = "";
        switch (creative?.type) {
          case "APP_INSTALL":
            adsType = "APP_INSTALL";
            break;
          case "SNAP_AD":
            adsType = "SNAP_AD";

            break;
          case "WEB_VIEW":
            adsType = "REMOTE_WEBPAGE";

            break;
          case "DEEP_LINK":
            adsType = "DEEP_LINK";

            break;
          case "COMPOSITE":
            adsType = "STORY";

            break;
          case "AD_TO_LENS":
            adsType = "AD_TO_LENS";

            break;
          case "AD_TO_CALL":
            adsType = "AD_TO_CALL";

            break;
          case "AD_TO_MESSAGE":
            adsType = "AD_TO_MESSAGE";

            break;
          case "LENS":
            adsType = "LENS";

            break;
          case "LENS_WEB_VIEW":
            adsType = "LENS_REMOTE_WEBPAGE";

            break;
          case "LENS_APP_INSTALL":
            adsType = "LENS_APP_INSTALL";

            break;
          case "LENS_DEEP_LINK":
            adsType = "LENS_DEEP_LINK";

            break;
          case "COLLECTION":
            adsType = "COLLECTION";

            break;
          case "LEAD_GENERATION":
            adsType = "LEAD_GENERATION";

            break;
          default:
            break;
        }
        const ads = [
          {
            creative_id: creative?.id,
            name: creative?.name,
            type: adsType,
            status:
              localStorage.getItem("delivery_status") === "true"
                ? "ACTIVE"
                : "PAUSED",
          },
        ];

        const formData = new FormData();
        this.appendFormData(formData, { campaigns });
        this.appendFormData(formData, { adsquads });
        this.appendFormData(formData, { ads });
        for (const [key, value] of formData.entries()) {
          console.log(`${key}: ${value}`);
        }
        const response = await apiClient.post(
          "/campaign-ads/snapchat/create/campaign",
          formData
        );
        console.log("Campaign response:", response);

        if (response.data.key === "success") {
          useGeneralStore().showSnackbar(
            "Campaign & Creative created successfully",
            "success"
          );
          this.created = true;
          return true;
        } else {
          console.log(response.data.message);
          this.validationData = [
            {
              type: response.data.key,
              error: "validation",
              data: [
                {
                  message: response.data.message,
                  key: response.data.key,
                  id: response.data.key,
                },
              ],
              path: "SnapThirdStep",
            },
          ];
          if (this.validationData.length > 0) {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
          useGeneralStore().showSnackbar("Something went wrong", "error");
          return false;
        }
      } catch (error) {
        console.error("Error creating campaign:", error);
        throw error;
      }
    },

    Resetfiles(dbName) {
      const request = indexedDB.open(dbName);

      request.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction(db.objectStoreNames, "readwrite");

        transaction.oncomplete = function () {
          console.log("All object stores cleared successfully.");
        };

        transaction.onerror = function (event) {
          console.error("Transaction error:", event.target.error);
        };

        for (const storeName of db.objectStoreNames) {
          const objectStore = transaction.objectStore(storeName);
          objectStore.clear().onsuccess = function () {
            console.log(`Cleared object store: ${storeName}`);
          };
        }
      };

      request.onerror = function (event) {
        console.error("Error opening database:", event.target.error);
      };
    },
    getCampaignType() {
      switch (localStorage.getItem("selectedOption")) {
        case "Awareness & Engagement":
          return "AWARENESS_AND_ENGAGEMENT";
        case "Traffic":
          return "TRAFFIC";
        case "Leads":
          return "LEADS";
        case "App Promotion":
          return "APP_PROMOTION";
        case "Sales":
          return "SALES";
        default:
          return "UNKNOWN";
      }
    },

    getCampaignObjective() {
      switch (localStorage.getItem("selectedOption")) {
        case "Awareness & Engagement":
          return "AWARENESS_AND_ENGAGEMENT";
        case "Traffic":
          return "TRAFFIC";
        case "Leads":
          return "LEADS";
        case "App Promotion":
          return "APP_PROMOTION";
        case "Sales":
          return "SALES";
        default:
          return "UNKNOWN";
      }
    },

    getPlacementConfig() {
      return localStorage.getItem("placement_type") === "edit_placement"
        ? "CUSTOM"
        : "AUTOMATIC";
    },

    getSnapchatPositions() {
      const positions = [];

      if (localStorage.getItem("between_content_user_stories") === "true")
        positions.push("INTERSTITIAL_USER");
      if (
        localStorage.getItem(
          "between_content_publisher_and_creator_stories"
        ) === "true"
      )
        positions.push("INTERSTITIAL_CONTENT");

      if (localStorage.getItem("between_content_spotlight") === "true")
        positions.push("INTERSTITIAL_SPOTLIGHT");
      if (localStorage.getItem("within_content_publisher_stories") === "true")
        positions.push("INSTREAM");
      if (localStorage.getItem("within_content_creator_stories") === "true")
        positions.push("PUBLIC_STORIES_INSTREAM");

      if (localStorage.getItem("edit_placement_type") === "camera") {
        return ["CAMERA"];
      }
      if (localStorage.getItem("edit_placement_type") === "in_stream") {
        return ["INSTREAM"];
      }
      if (localStorage.getItem("discover_feed") === "true")
        positions.push("FEED");

      return positions;
    },
    getinclusionConfig() {
      let positions = { content_types: [], creator_content_types: [] };

      if (
        localStorage.getItem("within_content") === "true" &&
        localStorage.getItem("within_content_publisher_stories") === "true"
      ) {
        positions.content_types.push(
          ...JSON.parse(
            localStorage.getItem("publisher_stories_selected_categories") ||
              "[]"
          )
            .filter((cat) => cat.type === "include")
            .map((cat) => cat.value.toUpperCase())
        );
      }

      if (
        localStorage.getItem("within_content") === "true" &&
        localStorage.getItem("within_content_creator_stories") === "true"
      ) {
        positions.creator_content_types.push(
          ...JSON.parse(
            localStorage.getItem("creator_stories_selected_categories") || "[]"
          )
            .filter((cat) => cat.type === "include")
            .map((cat) => cat.value.toUpperCase())
        );
      }

      return positions;
    },

    getexclusionConfig() {
      let positions = { content_types: [], creator_content_types: [] };

      if (
        localStorage.getItem("within_content") === "true" &&
        localStorage.getItem("within_content_publisher_stories") === "true"
      ) {
        positions.content_types.push(
          ...JSON.parse(
            localStorage.getItem("publisher_stories_selected_categories") ||
              "[]"
          )
            .filter((cat) => cat.type === "exclude")
            .map((cat) => cat.value.toUpperCase())
        );
      }

      if (
        localStorage.getItem("within_content") === "true" &&
        localStorage.getItem("within_content_creator_stories") === "true"
      ) {
        positions.creator_content_types.push(
          ...JSON.parse(
            localStorage.getItem("creator_stories_selected_categories") || "[]"
          )
            .filter((cat) => cat.type === "exclude")
            .map((cat) => cat.value.toUpperCase())
        );
      }

      return positions;
    },
    getDevices() {
      let os_type = [];
      if (localStorage.getItem("iOS_devices") === "true") {
        os_type.push({
          os_type: "iOS",
          os_version_min: localStorage.getItem("min_ios_version"),
          os_version_max: localStorage.getItem("max_ios_version"),
        });
      }
      if (localStorage.getItem("android_devices") === "true") {
        os_type.push({
          os_type: "ANDROID",
          os_version_min: localStorage.getItem("min_android_version"),
          os_version_max: localStorage.getItem("max_android_version"),
        });
      }
      if (localStorage.getItem("web_devices") === "true") {
        os_type.push({
          os_type: "WEB",
        });
      }
      if (this.getCarriersIds().length > 0) {
        os_type.push({
          carrier_id: this.getCarriersIds(),
        });
      }
      if (localStorage.getItem("selected_connection_type") === "all") {
        os_type.push({
          connection_type: "WIFI",
        });
        os_type.push({
          connection_type: "CELL",
        });
      } else {
        os_type.push({
          connection_type: localStorage
            .getItem("selected_connection_type")
            .toUpperCase(),
        });
      }
      if (localStorage.getItem("selected_devices_makers")) {
        JSON.parse(localStorage.getItem("selected_devices_makers")).forEach(
          (maker) => {
            console.log(maker);

            if (maker?.children) {
              maker.children.forEach((child) => {
                os_type.push({
                  marketing_name: child.id,
                });
              });
            } else {
              os_type.push({
                marketing_name: maker.id,
              });
            }
          }
        );
      }
      return os_type;
    },

    getAdSchedulingConfig() {
      return {
        tuesday: {
          hour_of_day: [1, 2],
        },
      };
    },

    appendFormData(formData, data, parentKey = "") {
      if (data && typeof data === "object" && !(data instanceof File)) {
        Object.entries(data).forEach(([key, value]) => {
          const newKey = parentKey ? `${parentKey}[${key}]` : key;
          this.appendFormData(formData, value, newKey);
        });
      } else if (data !== undefined && data !== null) {
        formData.append(parentKey, data);
      }
    },
  },
});
