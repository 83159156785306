import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const conversionDate = defineStore("conversionDate", {
  state: () => ({
    conversionLocationArray: [
      {
        id: 1,
        title: "Messaging apps",
        descriptions:
          "Get people to engage with your brand on Messenger, WhatsApp or Instagram.",
        value: "messaging_apps",
      },
      {
        id: 2,
        title: "On your ad",
        descriptions:
          "Get people to watch a video or interact with your post or event.",
        value: "on_your_ad",
      },
      {
        id: 3,
        title: "Calls",
        descriptions: "Get people to call your business.",
        value: "calls",
      },
      {
        id: 4,
        title: "Website",
        descriptions: "Get people to engage with your website.",
        value: "website",
      },
      {
        id: 5,
        title: "App",
        descriptions: "Get people to engage with your app.",
        value: "app",
      },
      {
        id: 6,
        title: "Facebook page",
        descriptions: "Get people to engage with your Facebook Page.",
        value: "facebook_page",
      },
    ],

    conversionLocationSelected: {
      id: 1,
      title: "Messaging apps",
      descriptions:
        "Get people to engage with your brand on Messenger, WhatsApp or Instagram.",
    },
    performanceGoalSelected: {
      id: 3,
      title: "Maximise number of conversations",
      descriptions:
        "We'll try to show your ads to  people  most likely to have a conversation with you through messaging.",
      value: "CONVERSATIONS",
    },
    performanceGoal: [],
    messageAppGoal: [
      {
        id: 3,
        title: "Maximise number of conversations",
        descriptions:
          "We'll try to show your ads to  people  most likely to have a conversation with you through messaging.",
        value: "CONVERSATIONS",
      },
      {
        id: 1,
        title: "Maximise number of link clicks",
        descriptions:
          "We'll try to show your ads to the people  most likely to click on them.",
        value: "LINK_CLICKS",
      },
    ],
    onYourAdGoal: [
      {
        id: 3,
        title: "Maximise ThruPlay views",
        descriptions:
          "We'll try to show your video ads to people who will watch the entire video when it’s shorter than 15 seconds. For longer videos, we’ll try to show it to people who are likely to watch at least 15 seconds.",
        value: "THRUPLAY",
      },
      {
        id: 4,
        title: "Maximise 2-second continuous video plays",
        descriptions:
          "We'll try to show your video ads to people  who are likely to watch 2 continuous seconds or more. Most 2-second continuous video plays will have at least 50% of the video pixels on screen.",
        value: "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS",
      },
    ],
    callsGoal: [
      {
        id: 15,
        title: "Maximise number of calls",
        descriptions: "",
        value: "QUALITY_CALL",
      },
    ],
    message_app: true,
    on_your_ad: false,
    website_contant: false,
    disabledConversionLocation: false,
    app_contant: false,
    facebook_page_contant: false,
    facebookPages: [],
    facebookSelectedId: {},
    webisteGoal: [
      {
        id: 3,
        title: "Maximise number of conversations",
        descriptions:
          "We'll try to show your ads to  people  most likely to have a conversation with you through messaging.",
        value: "CONVERSATIONS",
      },
      {
        id: 16,
        title: "Maximise number of landing page views",
        descriptions:
          "We'll try to show your ads to the people  most likely to view the website or Instant Experience linked in your ad. ",
        value: "LANDING_PAGE_VIEWS",
      },
      {
        id: 1,
        title: "Maximise number of link clicks",
        descriptions:
          "We'll try to show your ads to the people  most likely to click on them.",
        value: "LINK_CLICKS",
      },
      {
        id: 17,
        title: "Maximise daily unique reach",
        descriptions:
          "We'll try to show your ads to people up to once per day.",
        value: "REACH",
      },
      {
        id: 18,
        title: "Maximise number of impressions",
        descriptions:
          "We'll try to show your ads to people as many times as possible.",
        value: "IMPRESSIONS",
      },
    ],
    appPerformanceGoal: [
      {
        id: 19,
        title: "Maximise number of app events",
        descriptions:
          " We'll try to show your ads to the people most likely to take a specific action in your app at least once.",
        value: "DERIVED_EVENTS",
      },
      {
        id: 1,
        title: "Maximise number of link clicks",
        descriptions:
          "We'll try to show your ads to the people  most likely to click on them.",
        value: "LINK_CLICKS",
      },
      {
        id: 17,
        title: "Maximise daily unique reach",
        descriptions:
          "We'll try to show your ads to people up to once per day.",
        value: "REACH",
      },
    ],
  }),

  actions: {
    defaultPerformanceGoal() {
      this.performanceGoal = this.messageAppGoal;
    },

    driveEngagement(item) {
      this.conversionLocationSelected = item.raw;
      if (item.raw.value === "messaging_apps") {
        this.performanceGoal = [];
        this.performanceGoal = this.messageAppGoal;
        this.message_app = true;
        this.on_your_ad = false;
        this.disabledConversionLocation = false;
        this.website_contant = false;
        this.facebook_page_contant = false;
        this.app_contant = false;
      } else if (item.raw.value === "on_your_ad") {
        this.message_app = false;
        this.on_your_ad = true;
        this.disabledConversionLocation = false;
        this.website_contant = false;
        this.app_contant = false;
        this.facebook_page_contant = false;
        this.performanceGoal = [];
        this.performanceGoal = this.onYourAdGoal;
      } else if (item.raw.value === "calls") {
        this.message_app = false;
        this.on_your_ad = false;
        this.website_contant = false;
        this.disabledConversionLocation = true;
        this.app_contant = false;
        this.facebook_page_contant = false;
        this.performanceGoal = [];
        this.performanceGoal = this.callsGoal;
        this.performanceGoalSelected = this.callsGoal[0];
        localStorage.setItem("meta_optimization_goal", "QUALITY_CALL");
      } else if (item.raw.value === "website") {
        this.message_app = false;
        this.on_your_ad = false;
        this.disabledConversionLocation = false;
        this.website_contant = true;
        this.app_contant = false;
        this.facebook_page_contant = false;
        this.performanceGoal = [];
        this.performanceGoal = this.webisteGoal;
      } else if (item.raw.value === "app") {
        this.message_app = false;
        this.on_your_ad = false;
        this.disabledConversionLocation = false;
        this.website_contant = false;
        this.app_contant = true;
        this.facebook_page_contant = false;
        this.performanceGoal = [];
        this.performanceGoal = this.appPerformanceGoal;
        this.performanceGoalSelected = this.appPerformanceGoal[0];
      } else {
        this.message_app = false;
        this.on_your_ad = false;
        this.website_contant = false;
        this.disabledConversionLocation = true;
        this.app_contant = false;
        this.facebook_page_contant = true;
        this.performanceGoal = [];
        this.performanceGoal = this.callsGoal;
        this.performanceGoalSelected = this.callsGoal[0];
      }
    },
    selectItemWinner(item) {
      this.performanceGoalSelected = item.raw;
      localStorage.setItem("meta_optimization_goal", item.raw.value);
    },
    async getAllPages() {
      await apiClient.get("/publish-posts/all/pages").then((response) => {
        let data = response.data.data.data;
        this.facebookPages = data.map((item) => ({
          id: item.id,
          name: item.name,
          imageUrl: item.picture.data.url,
          category: item.category,
          width: item.picture.data.width,
          height: item.picture.data.height,
        }));
        this.facebookSelectedId = this.facebookPages[0];
      });
    },
    selectedfacebookPage(item) {
      this.facebookSelectedId = item.raw;
      localStorage.setItem("facebook_page_id", item.raw.id);
      localStorage.setItem("facebook_page_data", JSON.stringify(item));
    },
  },
});
