import { createRouter, createWebHistory } from "vue-router";
import TakeTime from "@/views/calender/TakeTime";
import AuthorizationError from "@/views/AuthorizationError.vue";
import AdsPerformance from "@/views/AdsPerformancePage.vue";
import InsightsPage from "@/views/InsightsPage.vue";
import PublishPost from "@/views/PublishPost.vue";
import Notifications from "@/views/NotificationsView.vue";
import authMiddleware from "../middleware/auth";
import snapchatRoutes from "@/router/snapchat.js";
import generalRoutes from "@/router/general.js";
import modulesRoutes from "@/router/modules.js";
import tiktokRoutes from "./tiktok";
import CalenderRoutes from "@/router/calender.js";
import integrationsRoutes from "@/router/integrations.js";
import crmRoutes from "@/router/crm.js";
import metaRoutes from "./meta";
import EngagementAndInsightsRoutes from "./EngagementAndInsights";
import DriveRoutes from "./Drive";
import MyAccountRoutes from "./MyAccount";
import MyTeamRoutes from "./myTeam";
import financeRoutes from "./finance";

const routes = [
  {
    path: "/authorization/error",
    name: "AuthorizationError",
    component: AuthorizationError,
    meta: {
      title: "Authorization Error",
      auth: "auth",
    },
  },
  {
    path: "/publish-post",
    name: "PublishPost",
    component: PublishPost,
    meta: {
      title: "Publish Post",
      description: "",
      auth: "auth",
      permissions: [
        "publish-posts.create",
        "publish-posts.read",
        "publish-posts.update",
        "publish-posts.delete",
      ],
    },
  },
  {
    path: "/ads-performance",
    name: "AdsPerformance",
    component: AdsPerformance,
    meta: {
      title: "Ads Performance",
      description: "",
      auth: "auth",
      permissions: [
        "ads-performance.create",
        "ads-performance.read",
        "ads-performance.update",
        "ads-performance.delete",
      ],
    },
  },
  {
    path: "/calender/bookDate",
    name: "TakeTime",
    component: TakeTime,
    meta: {
      title: "Book Date",
      auth: "auth",
      permissions: [
        "calender.create",
        "calender.read",
        "calender.update",
        "calender.delete",
      ],
    },
  },
  {
    path: "/ads-performance/:ad_account_id/:platform/:id",
    name: "InsightsPage",
    component: InsightsPage,
    meta: {
      title: "Insights",
      auth: "auth",
      permissions: [
        "ads-performance.create",
        "ads-performance.read",
        "ads-performance.update",
        "ads-performance.delete",
      ],
    },
  },
  {
    path: "/all-notifications",
    name: "AllNotifications",
    component: Notifications,
    meta: {
      title: "Notifications",
      description: "",
      auth: "auth",
      permissions: [
        "notification.create",
        "notification.read",
        "notification.update",
        "notification.delete",
      ],
    },
  },
  ...snapchatRoutes,
  ...generalRoutes,
  ...tiktokRoutes,
  ...modulesRoutes,
  ...metaRoutes,
  ...CalenderRoutes,
  ...integrationsRoutes,
  ...crmRoutes,
  ...EngagementAndInsightsRoutes,
  ...DriveRoutes,
  ...MyAccountRoutes,
  ...MyTeamRoutes,
  ...financeRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(authMiddleware);

export default router;
