<template>
  <div class="d-flex flex-column ga-3 snapchat">
    <div class="filed mt-3">
      <span class="title">Privacy Policy URL</span>
      <div class="input-container">
        <input
          type="text"
          :value="data?.lead_generation_form?.privacy_policy_url"
          placeholder="Privacy Policy URL"
        />
      </div>
    </div>
    <div>
      <span>Disclosures</span>
      <span class="small-text"
        >Add a custom legal disclaimer or marketing opt-in.</span
      >
    </div>
    <div class="filed mt-3">
      <span class="title">Title</span>
      <div class="input-container">
        <input
          type="text"
          :value="data?.lead_generation_form?.legal_disclosures.title"
          placeholder="Title"
        />
      </div>
    </div>
    <div class="filed mt-3">
      <span class="title">Description</span>
      <div class="input-container">
        <d-flex class="flex-column">
          <span
            class="small-text w-100 d-flex align-center justify-space-between"
            style="
              height: 40px;
              background-color: #f9f9fa;
              border-bottom: solid 0.1px rgb(225, 227, 229);
            "
          >
            <span>Add Hyperlink </span>
            <span class="small-text"
              >{{
                data?.lead_generation_form?.legal_disclosures?.description.length
              }}
              / 2000 characters</span
            >
          </span>
          <input
            style="height: 75px"
            class="w-100"
            type="text"
            maxlength="2000"
            :value="data?.lead_generation_form?.legal_disclosures?.description"
            placeholder="Description"
          />
        </d-flex>
      </div>
    </div>
    <div
      v-for="(item, index) in data?.lead_generation_form?.legal_disclosures
        ?.consent_form_fields"
      :key="item"
      class="single-consent"
    >
      <div class="consent-title d-flex align-center justify-space-between">
        <span>{{ "consent " + (index + 1) }}</span>
      </div>
      <div class="consent-body">
        <div class="filed mt-3">
          <span class="title">Consent description </span>
          <div class="input-container">
            <div class="position-relative">
              <input
                maxlength="80"
                class="w-100"
                type="text"
                :value="item.consent_description"
                placeholder="Description"
              />
              <span
                class="position-absolute"
                style="right: 6px; top: 0px; transform: translateY(38%)"
                >{{ 80 - item.consent_description.length }}</span
              >
            </div>
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Required Consent </span>
          <div class="mt-3">
            <SwitchToogle
              v-model:checked="item.required"
              :notAttached="false"
              :paused="false"
              :disabled="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useLeadFormStore } from "@/store/snapchat/LeadForm";
import SwitchToogle from "@/components/snapchat/Label&input/SwitchToogle.vue";

export default {
  name: "DisclosuresForm",
  props: {
    data: Object,
  },
  components: { SwitchToogle },
  data() {
    return {
      store: useLeadFormStore(),
    };
  },
  methods: {
    RemoveConsent(index) {
      this.store.consent.splice(index, 1);
    },
    AddConsent() {
      if (store.consent.length < 2) {
        this.store.consent.push({
          description: "",
          required: false,
        });
      }
    },
  },
};
</script>
