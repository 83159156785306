<template>
  <div class="pa-4 dashboard-card" elevation="2">
    <div class="d-flex justify-space-between">
      <div class="d-flex flex-column ga-2">
        <span class="text-subtitle-1 font-weight-bold"
          >Top Performing Compaign</span
        >
        <span v-if="date_range.length" class="d-flex align-center date-range"
          >{{ store?.formatDateFromInput(date_range[0]) }} -
          {{
            store?.formatDateFromInput(date_range[date_range.length - 1])
          }}</span
        >
      </div>
      <div class="d-flex align-center ga-2">
        <span class="vuetify-date-input-icon cursor-pointer">
          <v-date-input
            hide-details
            variant="plain"
            prepend-icon=""
            prepend-inner-icon="$calendar"
            density="compact"
            multiple="range"
            clearable
            format="YYYY-MM-DD HH:mm:ss"
            v-model="date_range"
          >
            <template v-slot:text-field="{ value }">
              <v-text-field
                :model-value="formatDate(value)"
                readonly
                variant="plain"
              ></v-text-field>
            </template>
          </v-date-input>
        </span>

        <v-select
          v-model="filter_by"
          :items="filter_days"
          item-title="title"
          item-value="value"
        ></v-select>
      </div>
    </div>
    <div class="p-3 mt-4">
      <v-table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Campaign name</th>
            <th>Leads Generated</th>
            <th>Change</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="item.name">
            <td>{{ index + 1 }}</td>
            <td>{{ item.campaign_name }}</td>
            <td>{{ item.total }}</td>
            <td>
              <span :class="item.percentage > 0 ? 'green-status' : 'red-status'"
                >{{ item.percentage > 0 ? "+" : "-"
                }}{{ item.percentage }}</span
              >
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { VDateInput } from "vuetify/labs/VDateInput";
import { useLeadsStore } from "@/store/crm/useLeadsStore";
export default {
  props: {
    data: {
      type: Array,
    },
  },
  components: { VDateInput },

  data() {
    return {
      filter_by: "",
      filter_days: [
        { title: "Last Day", value: "last_day" },
        { title: "Last Week", value: "last_week" },
        { title: "Last Month", value: "last_month" },
        { title: "Last Year", value: "last_year" },
      ],
      store: useLeadsStore(),
      date_range: [],
    };
  },
  methods: {
    emitData() {
      this.$emit(
        "filter",
        this.store?.formatDateFromInput(this.date_range[0]),
        this.store?.formatDateFromInput(
          this.date_range[this.date_range.length - 1]
        ),
        this.filter_by
      );
    },
  },
  watch: {
    filter_by() {
     
      this.emitData();
    },
    date_range() {
      this.emitData();
    },
  },
};
</script>
