<template>
  <v-row v-if="store.loading" class="folders">
    <v-col cols="6" :lg="3" v-for="i in 4" :key="i">
      <v-skeleton-loader
        class="folder-box new-folder-box"
        style="padding: 0px; overflow: hidden"
        elevation="0"
        width="100%"
        type="image"
        rounded
      ></v-skeleton-loader>
    </v-col>
  </v-row>
  <div v-else>
    <LatestFolder @open:NewFolder="$emit('open:NewFolder', true)" />
    <LatestImages v-if="store?.getImages.length" @edit:file="editFile" />
    <LatestVideos v-if="store?.getVideos.length" @edit:file="editFile" />
  </div>
</template>

<script>
import { DriveStore } from "@/store/drive/DriveStore";
import LatestFolder from "@/components/drive/LatestFolder.vue";
import LatestImages from "@/components/drive/LatestImages.vue";
import LatestVideos from "@/components/drive/LatestVideos.vue";
export default {
  name: "CustomBoxView",
  props: {},
  components: { LatestFolder, LatestImages, LatestVideos },

  data() {
    return {
      store: null,
    };
  },
  watch: {},
  computed: {},

  methods: {
    formatDate(date) {
      return format(new Date(date), "MMM dd, yyyy HH:mm");
    },
    editFile(v) {
      this.$emit("edit:file", v);
    },
  },

  created() {
    this.store = DriveStore();
  },
  mounted() {},
};
</script>
