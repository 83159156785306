import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";
export const contentExclusions = defineStore("contentExclusions", {
  state: () => ({
    categoryExclusions: [],
    verticalSensitivity: [],
    adAccountId: localStorage.getItem("advertiser_id"),
    obj: localStorage.getItem("selected_objective"),
  }),
  actions: {
    getCategoryExclusions() {
      apiClient
        .get(
          `/campaign-ads/tiktok/get/category/${this.adAccountId}/obj=${this.obj}`
        )
        .then((response) => {
          console.log(response);
          if (
            !response.data ||
            !response.data.data ||
            !response.data.data.options
          ) {
            console.error("Invalid response format", response);
            return;
          }
          const {
            excluded_category_list = [],
            vertical_sensitivity_list = [],
          } = response.data.data.options;
          this.categoryExclusions =
            excluded_category_list.length > 0 ? excluded_category_list : [];
          this.verticalSensitivity =
            vertical_sensitivity_list.length > 0
              ? vertical_sensitivity_list
              : [];
        })
        .catch((error) => {
          console.error("Error fetching category exclusions:", error);
          this.categoryExclusions = [];
          this.verticalSensitivity = [];
        });
    },
  },
});
