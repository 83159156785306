<template>
  <v-expansion-panels>
    <v-expansion-panel bg-color="transparent" elevation="0" hide-accordion>
      <v-expansion-panel-title v-slot="{ expanded }" hide-actions>
        <div class="d-flex align-center justify-space-between ga-2 w-100">
          <div class="d-flex align-center ga-2">
            <v-icon
              class="upload-span"
              style="padding: 0px; width: 32px"
              :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            ></v-icon>
            <div class="d-flex flex-column ga-1">
              <span>Q{{ index + 1 }}</span>
            </div>
          </div>
          <svg
            @click="store.RemoveQuestion(index)"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#444546"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-trash"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.19527 3.36191C6.32029 3.23688 6.48986 3.16665 6.66667 3.16665H9.33334C9.51015 3.16665 9.67972 3.23688 9.80474 3.36191C9.92977 3.48693 10 3.6565 10 3.83331H6C6 3.6565 6.07024 3.48693 6.19527 3.36191ZM4.66667 3.83331C4.66667 3.30288 4.87739 2.79417 5.25246 2.4191C5.62753 2.04403 6.13624 1.83331 6.66667 1.83331H9.33334C9.86377 1.83331 10.3725 2.04403 10.7476 2.4191C11.1226 2.79417 11.3333 3.30288 11.3333 3.83331H13.3333V5.16665H2.66667V3.83331H4.66667ZM4.66667 5.83331V12.5C4.66667 12.6768 4.73691 12.8464 4.86193 12.9714C4.98696 13.0964 5.15653 13.1666 5.33334 13.1666H10.6667C10.8435 13.1666 11.0131 13.0964 11.1381 12.9714C11.2631 12.8464 11.3333 12.6768 11.3333 12.5V5.83331H12.6667V12.5C12.6667 13.0304 12.456 13.5391 12.0809 13.9142C11.7058 14.2893 11.1971 14.5 10.6667 14.5H5.33334C4.80291 14.5 4.2942 14.2893 3.91912 13.9142C3.54405 13.5391 3.33334 13.0304 3.33334 12.5V5.83331H4.66667ZM7.33334 7.16665C7.33334 6.79846 7.03486 6.49998 6.66667 6.49998C6.29848 6.49998 6.00001 6.79846 6.00001 7.16665V11.1666C6.00001 11.5348 6.29848 11.8333 6.66667 11.8333C7.03486 11.8333 7.33334 11.5348 7.33334 11.1666V7.16665ZM9.33334 6.49998C9.70153 6.49998 10 6.79846 10 7.16665V11.1666C10 11.5348 9.70153 11.8333 9.33334 11.8333C8.96515 11.8333 8.66667 11.5348 8.66667 11.1666V7.16665C8.66667 6.79846 8.96515 6.49998 9.33334 6.49998Z"
            ></path>
          </svg>
        </div>
      </v-expansion-panel-title>

      <v-expansion-panel-text>
        <div class="pa-5 snapchat d-flex flex-column ga-3">
          <div class="d-flex align-center ga-2 mx-5">
            <input
              v-model="question.qualifying_question"
              type="checkbox"
              :id="question.id"
            />
            <div class="d-flex flex-column ga-1">
              <span>Qualifying Question</span>
              <span class="small-text"
                >Ask Snapchatters a question to see if they're a preferred match
                for your business.</span
              >
            </div>
          </div>
          <div class="filed mt-3">
            <span class="title">Question</span>
            <div class="input-container">
              <input
                type="text"
                v-model="store.questions[index].question"
                placeholder="Question"
              />
            </div>
          </div>
          <div>Answers</div>
          <div
            v-for="(option, i) in question.options"
            :key="option"
            class="filed mt-3 d-flex align-center ga-2"
          >
            <div class="input-container w-100">
              <input type="text" v-model="option.text" placeholder="Question" />
            </div>
            <div
              v-if="question.qualifying_question"
              class="input-container w-100"
            >
              <v-select
                @update:modelValue="updateOption(index, i)"
                v-model="option.type"
                :items="[
                  { text: 'Preferred', value: 'preferred' },
                  { text: 'Not Preferred', value: 'not_preferred' },
                ]"
                item-title="text"
                item-value="value"
                label=""
                variant="solo"
                class="v-select-style"
              ></v-select>
            </div>
            <div @click="store.removeOptoin(index, i)" class="cursor-pointer">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="#444546"
                xmlns="http://www.w3.org/2000/svg"
                class="sds-icon sds-icon-trash"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.19527 3.36191C6.32029 3.23688 6.48986 3.16665 6.66667 3.16665H9.33334C9.51015 3.16665 9.67972 3.23688 9.80474 3.36191C9.92977 3.48693 10 3.6565 10 3.83331H6C6 3.6565 6.07024 3.48693 6.19527 3.36191ZM4.66667 3.83331C4.66667 3.30288 4.87739 2.79417 5.25246 2.4191C5.62753 2.04403 6.13624 1.83331 6.66667 1.83331H9.33334C9.86377 1.83331 10.3725 2.04403 10.7476 2.4191C11.1226 2.79417 11.3333 3.30288 11.3333 3.83331H13.3333V5.16665H2.66667V3.83331H4.66667ZM4.66667 5.83331V12.5C4.66667 12.6768 4.73691 12.8464 4.86193 12.9714C4.98696 13.0964 5.15653 13.1666 5.33334 13.1666H10.6667C10.8435 13.1666 11.0131 13.0964 11.1381 12.9714C11.2631 12.8464 11.3333 12.6768 11.3333 12.5V5.83331H12.6667V12.5C12.6667 13.0304 12.456 13.5391 12.0809 13.9142C11.7058 14.2893 11.1971 14.5 10.6667 14.5H5.33334C4.80291 14.5 4.2942 14.2893 3.91912 13.9142C3.54405 13.5391 3.33334 13.0304 3.33334 12.5V5.83331H4.66667ZM7.33334 7.16665C7.33334 6.79846 7.03486 6.49998 6.66667 6.49998C6.29848 6.49998 6.00001 6.79846 6.00001 7.16665V11.1666C6.00001 11.5348 6.29848 11.8333 6.66667 11.8333C7.03486 11.8333 7.33334 11.5348 7.33334 11.1666V7.16665ZM9.33334 6.49998C9.70153 6.49998 10 6.79846 10 7.16665V11.1666C10 11.5348 9.70153 11.8333 9.33334 11.8333C8.96515 11.8333 8.66667 11.5348 8.66667 11.1666V7.16665C8.66667 6.79846 8.96515 6.49998 9.33334 6.49998Z"
                ></path>
              </svg>
            </div>
          </div>
          <button
            @click="addOption"
            class="w-100 d-flex align-center justify-center ga-2 upload-span"
          >
            <v-icon icon="mdi-plus"></v-icon> <span>Add Answer</span>
          </button>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { useLeadFormStore } from "@/store/snapchat/LeadForm";

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      store: useLeadFormStore(),
    };
  },
  methods: {
    addOption() {
      this.question.options.push({ text: "", type: "" });
    },

    updateOption(questionIndex, optionIndex) {
      const selectedValue =
        this.store.questions[questionIndex].options[optionIndex].type;
      if (selectedValue === "preferred") {
        this.store.questions[questionIndex].options.forEach((option) => {
          option.type = "not_preferred";
        });
        this.store.questions[questionIndex].options[optionIndex].type =
          "preferred";
      }
    },
  },
};
</script>
<style scoped>
.sds-icon-trash:hover {
  fill: #0894fa;
}
</style>
