<template>
  <v-container fluid style="margin-top: 0%; padding: 0% 0% 0 0; min-height: calc(100vh - 58px)">
    <v-row style="height: 100%; margin: 0; padding: 0; position: relative">
      <v-col cols="12" class="pa-0">
        <GlobalNav />
      </v-col>
      <v-col cols="12" style="height: 100%" class="pa-0 d-flex align-start ga-6">
        <SideBar />
        <div class="ad-performance-contant">
          <BreadCrumb :linksData="store.linksData" class="mb-5" />
          <div class="result-last-days">
            <h4 class="mb-3">
              {{ store.title_date }}
            </h4>
            <v-container fluid class="py-0 px-1">
              <v-row class="w-100 pa-0 ma-0">
                <v-col cols="11" xl="12" md="12" sm="12" class="results-card ga-8 pa-0">
                  <v-card class="card rounded-xl px-7 py-12">
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img src="@/assets/soft-ware-img/ads-performance-campaign.svg" alt="" />
                      </div>
                      <div class="result-contain">
                        <p class="number">
                          {{ store.formatNumber(store.campaignNumber) }}
                        </p>
                        <p class="title">{{ $t("compaigns") }}</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img src="@/assets/soft-ware-img/amount-icon-dollar.svg" alt="" />
                      </div>
                      <div class="result-contain">
                        <p class="number">{{ store.formatNumber(store.amountSpent) }}$</p>
                        <p class="title">{{ $t("amount_spent") }}</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img src="@/assets/soft-ware-img/impressions-icon.svg" alt="" />
                      </div>
                      <div class="result-contain">
                        <p class="number">{{ store.formatNumber(store.impressions) }}$</p>
                        <p class="title">{{ $t("impressions") }}</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img src="@/assets/soft-ware-img/leads-ad-performance-icon.svg" alt="" />
                      </div>
                      <div class="result-contain">
                        <p class="number">
                          {{ store.formatNumber(store.leads) }}
                        </p>
                        <p class="title">{{ $t("leads") }}</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img src="@/assets/soft-ware-img/click-icon.svg" alt="" />
                      </div>
                      <div class="result-contain">
                        <p class="number">{{ store.formatNumber(store.clicks) }}</p>
                        <p class="title">{{ $t("click") }}</p>
                      </div>
                    </div>
                  </v-card>
                  <div class="actions-on-performance">
                    <button @click="dialog = true" class="add-campaign-btn d-flex align-center ga-1">
                      <span class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                          fill="#fff">
                          <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                        </svg>
                      </span>
                      <span>{{ $t("add_compaign") }}</span>
                    </button>
                    <v-dialog v-model="dialog" width="auto" class="add-campaign-modal">
                      <v-card min-width="600" class="dialog-card pa-4 rounded-xl">
                        <div class="card-title d-flex justify-space-between align-center">
                          <p>{{ $t("select_social_media_Platform") }}</p>
                          <img @click="dialog = false" src="@/assets/soft-ware-img/X circle.svg" alt="" />
                        </div>
                        <v-card-text>
                          <div>
                            <router-link to="/create-new-camp" style="border: 1px solid #1094f4">
                              <span>
                                <img src="@/assets/soft-ware-img/facebook-icon-adcampaign.svg" alt="" />
                              </span>
                              <span> {{ $t("facebook") }} </span>
                            </router-link>
                            <router-link to="/create-new-camp" style="border: 0.6px solid #b731a0">
                              <span>
                                <img src="@/assets/soft-ware-img/instgrapm.svg" alt="" />
                              </span>

                              <span> {{ $t("instagram") }} </span>
                            </router-link>
                            <router-link to="/create-new-camp" style="border: 1px solid #fffa37">
                              <span>
                                <img src="@/assets/soft-ware-img/snapchat-icoon-ccampaign.svg" alt="" />
                              </span>
                              <span> {{ $t("snapchat") }} </span>
                            </router-link>
                          </div>
                          <div>
                            <router-link to="/create-new-camp" style="border: 1px solid #1275b1">
                              <span>
                                <img src="@/assets/soft-ware-img/linkedin-icon.svg" alt="" />
                              </span>
                              <span> {{ $t("linked_in") }} </span>
                            </router-link>

                            <router-link to="/create-new-camp" style="border: 1px solid #000000">
                              <span>
                                <img src="@/assets/soft-ware-img/tikTopkcampaign.svg" alt="" />
                              </span>
                              <span> {{ $t("tik_tok") }} </span>
                            </router-link>

                            <router-link to="/create-new-camp" style="border: 1px solid #000000">
                              <span>
                                <img src="@/assets/soft-ware-img/x-twitter-icons.svg" alt="" />
                              </span>
                              <span> {{ $t("x") }} </span>
                            </router-link>
                          </div>
                        </v-card-text>
                        <div class="card-footer">
                          <span>* {{ $t("you_can_choose_multiple_platform") }}
                          </span>

                          <button>{{ $t("add_compaign") }}</button>
                        </div>
                      </v-card>
                    </v-dialog>
                    <button @click="adsReportStatusFunc" class="ads-report-btn d-flex align-center ga-2">
                      <span class="d-flex">
                        <img src="@/assets/soft-ware-img/ads-report-icon.svg" alt="" />
                      </span>
                      <span>{{ $t('ads_report') }}</span>
                    </button>
                    <button @click="exportTableToExcel" class="export-btn d-flex align-center ga-2" >
                      <span class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                          fill="rgba(35, 114, 118, 1)">
                          <path
                            d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" />
                        </svg>
                      </span>
                      <span>{{ $t("export_file") }}</span>
                    </button>
                  </div>
                </v-col>
                <v-col cols="8" xl="11" md="12" sm="12"
                  class="d-flex my-5 align-center justify-space-between flex-wrap">
                  <div v-if="hideFilter" class="filter-contain d-flex align-center ga-5">
                    <div class="select-date">
                      <label for="">{{ $t("date") }}</label>

                      <v-date-input prepend-icon="" prepend-inner-icon="$calendar" v-model="selectedDate"
                        multiple="range" variant="solo" elevation="0"></v-date-input>
                    </div>
                    <div class="select-status">
                      <label for="" class="d-block mb-2">{{
                        $t("status")
                      }}</label>
                      <v-select v-model="selectedStatus" :items="statusItems" item-text="text" item-value="value"
                        :label="$t('select')" persistent-hint return-object single-line variant="solo"
                        elevation="0"></v-select>
                    </div>
                    <div class="select-status" style="display: flex; align-items: end; gap: 22px">
                      <div class="select-status">
                        <label for="" class="d-block mb-2">
                          {{ $t("platform") }}
                        </label>
                        <v-select v-model="selectedPlatform" :items="platformItems" item-title="platform"
                          item-value="platform" label="Select Platform" persistent-hint return-object single-line
                          variant="solo" elevation="0"></v-select>
                      </div>
                      <div class="actions-on-performance">
                        <button @click="ApplyFilter" class="add-campaign-btn d-flex align-center ga-1">
                          <span class="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                              fill="#fff">
                              <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                            </svg>
                          </span>
                          <span> {{ $t("apply") }} </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="hide-filter">
                    <button @click="toggleFilter" class="d-flex align-center ga-2">
                      <span><img src="@/assets/soft-ware-img/filter-icon-performance.svg" alt="" /></span>
                      <span>
                        <span v-if="hideFilter">{{ $t("reset_&_hide") }}</span>
                        <span v-else>{{ $t("show") }}</span>
                        {{ $t("filters") }}</span>
                    </button>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="table-contain">
                    <table class="w-100" id="data-table" v-if="store.adsReportStatus">
                      <thead>
                        <tr>
                          <th style="border-radius: 8px 0 0 0" class="d-flex name-contain align-center ga-2">
                            <div class="checkbox-wrapper-46">
                              <input @change="toggleAllCheckboxes" type="checkbox" id="check-all-name" class="inp-cbx"
                                v-model="checkAll" />
                              <label for="check-all-name" class="cbx"><span>
                                  <svg viewBox="0 0 12 10" height="9px" width="10px">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                  </svg></span><span> {{ $t("name") }} </span>
                              </label>
                            </div>
                          </th>
                          <th>{{ $t("status") }}</th>
                          <th>{{ $t("delivery_status") }}</th>
                          <th>{{ $t("amount_spent") }}</th>
                          <th>{{ $t("result") }}</th>
                          <th>Cost per Result</th>
                          <th>
                            <span class="d-block">{{
                              $t("paid_immpressions")
                            }}</span>
                          </th>
                          <th>{{ $t("paid_eCPM") }}</th>
                          <th>{{ $t("click") }}</th>
                          <th>{{ $t("CPC") }}</th>
                          <th style="border-radius: 0 8px 0 0">
                            {{ $t("action") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="store.loadingFortable">
                        <tr v-for="i in 10" :key="i">
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr v-for="row in store.tableData" :key="row.id">
                          <td>
                            <div class="d-flex align-center ga-2">
                              <div class="checkbox-wrapper-46">
                                <input :checked="row.checked" type="checkbox" :id="row.id" class="inp-cbx" />
                                <label :for="row.id" class="cbx"><span>
                                    <svg viewBox="0 0 12 10" height="9px" width="10px">
                                      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                  </span>
                                  <span><img style="width: 20px"
                                      src="@/assets/soft-ware-img/snapchat-icoon-ccampaign.svg" alt="" /></span>
                                  <span v-tooltip:top="row.name">{{
                                    store.truncateName(row.name, 15)
                                  }}</span>
                                </label>
                              </div>
                            </div>
                          </td>
                          <td class="switch-status">
                            <input class="switch" type="checkbox" @change="
                              changeStatus(
                                row.status,
                                row.id,
                                row.name,
                                row.start_time,
                                row.end_time
                              )
                              " :checked="row.status === 'PAUSED' ? false : true" />
                          </td>
                          <td>
                            <div class="deliviery-status d-flex align-center ga-2">
                              <span :class="row.delivery_status &&
                                  row.delivery_status.length > 0
                                  ? 'deleviry-status'
                                  : 'status'
                                ">
                              </span>
                              <span class="d-flex" v-tooltip:top="row.delivery_status.length === 0
                                  ? $t('Not Delivering Compain is Scheduled')
                                  : $t('delivering')
                                ">
                                {{
                                  row.delivery_status.length === 0
                                    ? store.truncateName(
                                      $t(
                                        "Not Delivering Compain is Scheduled"
                                      ),
                                      10
                                    )
                                    : $t("delivering")
                                }}
                              </span>
                            </div>
                          </td>
                          <td>
                            <span>{{
                              formatLifetimeSpend(
                                row.lifetime_spend_cap_micro
                              )
                            }}
                              $</span>
                          </td>
                          <td>
                            <span>
                              {{ row.stats.native_leads }} <br />
                              {{ $t("leads") }}</span>
                          </td>
                          <td>
                            <span>{{
                              (
                                Math.round(
                                  row.calculations.costPerClick * 100
                                ) / 100
                              ).toFixed(2)
                            }}$ <br />
                              {{ $t("cost_per_lead") }}</span>
                          </td>
                          <td>
                            <span>
                              {{
                                new Intl.NumberFormat("en-US").format(
                                  row.stats.impressions / 100
                                )
                              }}
                            </span>
                          </td>
                          <td>
                            <span>
                              <span>$
                                {{
                                  new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(row.calculations.eCPM)
                                }}</span>
                            </span>
                          </td>
                          <td>
                            <span>{{ row.stats.swipes }}</span>
                          </td>
                          <td>
                            <span>{{
                              new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(row.calculations.eCPC)
                            }}$</span>
                          </td>
                          <td>
                            <div class="table-action">
                              <button @click="
                                showAnalisyes(
                                  row.id,
                                  row.ad_account_id,
                                  row.platform
                                )
                                ">
                                <img src="@/assets/soft-ware-img/see-analisyes.svg" alt="" />
                              </button>
                              <!-- <button>
                                <img src="@/assets/soft-ware-img/Edit.svg" alt="" />
                              </button> -->
                              <button @click="copyRowData(row)">
                                <img src="@/assets/soft-ware-img/copy-table-action.svg" alt="" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody class="no-data-found position-relative"
                        v-if="store.tableData.length === 0 && !store.loadingFortable">
                        <tr>
                          <td colspan="11">
                            <img class="no-data-img" src="@/assets/soft-ware-img/no-data.jpg" alt="" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <VerticalBarChart v-else :chartData="store.data" :chartOptions="store.options" />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import { VDateInput } from "vuetify/labs/VDateInput";
import apiClient from "@/core/buildRequest.js";
import VerticalBarChart from "@/components/VerticalBarChart.vue";
import "@/assets/css/style.css";
import * as XLSX from "xlsx";
import { adsPerformance } from "@/store/AdsPerformance/ads_performance";
import { useGeneralStore } from "@/store/General";
import { toast } from "vue3-toastify";

export default {
  components: { GlobalNav, SideBar, BreadCrumb, VDateInput, VerticalBarChart },
  data() {
    return {
      store: adsPerformance(),
      selectedDate: [],
      start_date: null,
      end_date: null,
      hideFilter: true,
      ex11: true,
      ex12: true,
      selectedStatus: null,
      selectedPlatform: null,
      checkAll: false,
      dialog: false,
      campaign_id: [],
      campaign_leads: [],
      startTimeCampign: null,
      endTimeCampaign: null,
      campaignStatus: null,
      campaignId: null,
      campaignName: null,
      generalStore: useGeneralStore(),
      statusItems: [this.$t("PAUSED"), this.$t("ACTIVE"), this.$t("Completed")],
      platformItems: [
        {
          platform: this.$t("snapchat"),
        },
        {
          platform: this.$t('meta'),
        },
        {
          platform: this.$t('instagram'),
        },
        {
          platform: this.$t('x'),
        },
        {
          platform: this.$t('tik_tok'),
        },
      ],
    };
  },
  methods: {
    toggleFilter() {
      this.hideFilter = !this.hideFilter;
      this.selectedDate = [];
      this.selectedStatus = null;
      this.selectedPlatform = null;
      this.store.getData();
    },
    formatLifetimeSpend(value) {
      if (!value || isNaN(value)) return 0;
      return Math.ceil(value / 1_000_000);
    },
    exportTableToExcel() {
      const table = document.getElementById("data-table");
      const workbook = XLSX.utils.table_to_book(table);
      const fileName = "table-data.xlsx";
      XLSX.writeFile(workbook, fileName);
    },
    toggleAllCheckboxes() { },
    adsReportStatusFunc() {
      this.store.adsReportStatus = !this.store.adsReportStatus;
    },
    async changeStatus(
      status,
      id,
      campaignName,
      startTimeCampign,
      endTimeCampaign
    ) {
      const requestData = {
        status: String(status) === "PAUSED" ? "ACTIVE" : "PAUSED",
        campaign_id: id,
        name: campaignName,
        start_time: startTimeCampign,
        end_time: endTimeCampaign,
      };
      await apiClient
        .post(`/snapchat/update/campaign/${id}`, requestData)
        .then(() => {
          toast(`Status Updated Successfully`, {
            type: "success",
          });
        });
    },
    getFirstAndLastDate(dates) {
      if (!dates || !Array.isArray(dates) || dates.length === 0) {
        throw new Error("Invalid dates array");
      }
      const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));
      const firstDate = new Date(sortedDates[0]);
      const lastDate = new Date(sortedDates[sortedDates.length - 1]);
      const formatDate = (date) => date.toISOString().split("T")[0];
      return {
        firstDate: formatDate(firstDate),
        lastDate: formatDate(lastDate),
      };
    },
    showAnalisyes(id, ad_account_id, platform) {
        this.$router.push(`/ads-performance/${ad_account_id}/${platform}/${id}`);
      },
    copyRowData(row) {
      const rowData = {
        campaign_name: row.name,
        platform: row.platform,
        Amount_Spent: this.formatLifetimeSpend(row.lifetime_spend_cap_micro),
        status: row.status,
        cost_per_result: Math.round(row.calculations.costPerClick * 100) / 100,
        Paid_Impressions: row.stats.impressions / 100,
        Clicks: row.stats.swipes,
      };
      
      const textToCopy = Object.entries(rowData)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
      
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          this.generalStore.showSnackbar('Row data copied to clipboard', 'success');
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
          this.generalStore.showSnackbar('Failed to copy data', 'error');
        });
    },
  },
  watch: {
    checkAll(newValue) {
      this.filteredTableData.forEach((row) => {
        row.checked = newValue;
      });
    },
    selectedDate() {
      if (this.selectedDate.length > 0) {
        let dates = this.getFirstAndLastDate(this.selectedDate);
        this.start_date = dates.firstDate;
        this.end_date = dates.lastDate;
      } else {
        this.start_date = null;
        this.end_date = null;
      }
    },
  },
  async mounted() {
    this.store.getData();
  },
  computed: {
    filteredTableData() {
      return this.store.tableData.filter((row) => {
        const isStatusMatch =
          !this.selectedStatus || row.status === this.selectedStatus;
        const isDateInRange =
          !this.selectedDate.length ||
          (new Date(row.start_time) >= new Date(this.selectedDate[0]) &&
            new Date(row.end_time) <= new Date(this.selectedDate[1]));
        const isPlatformMatch =
          !this.selectedPlatform ||
          row.platform === this.selectedPlatform.platform;
        return isStatusMatch && isDateInRange && isPlatformMatch;
      });
    },
  },
};
</script>