import CreateCampaign from "@/views/CreatCampain.vue";
import AdsPerformance from "@/views/AdsPerformancePage.vue";
import TakeTime from "@/views/calender/TakeTime";

const modulesRoutes = [
  {
    path: "/create-new-camp",
    name: "CreateNewCamp",
    component: CreateCampaign,
    meta: {
      title: "Create New Camp",
      description: "",
      auth: "auth",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
  {
    path: "/ads-performance",
    name: "AdsPerformance",
    component: AdsPerformance,
    meta: {
      title: "Ads Performance",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
  {
    path: "/calender/bookDate",
    name: "TakeTime",
    component: TakeTime,
    meta: {
      title: "Book Date",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
];
export default modulesRoutes;
