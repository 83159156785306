<template>
  <v-app>
    <v-container
      fluid
      style="margin-top: 0%; padding: 0% 0% 0 0; min-height: calc(100vh - 58px)"
    >
      <v-row style="height: 100%; margin: 0; padding: 0; position: relative">
        <v-col cols="12" class="pa-0">
          <GlobalNav />
        </v-col>
        <v-col
          cols="12"
          style="height: 100%"
          class="pa-0 d-flex align-start ga-6"
        >
          <SideBar />
          <div class="w-100 pa-3 is-light-mode">
            <BreadCrumb :linksData="linksData" class="mb-3" />
            <div class="leads-managements-link d-flex align-center ga-2 my-3">
              <div
                @click="$router.push({ name: 'MyTeam' })"
                class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
                    fill="#1F666A"
                  />
                </svg>
                <span>Roles Permissions</span>
              </div>
            </div>
            <div>
              <v-tabs v-model="tab" center-active color="#1F666A">
                <v-tab
                  v-for="role in roles"
                  :key="role.id"
                  :value="getRoleValue(role.name)"
                >
                  {{ role.name }}
                </v-tab>
              </v-tabs>
              <div class="tap-body pa-5">
                <v-tabs-window v-model="tab">
                  <v-tabs-window-item
                    v-for="role in roles"
                    :key="role.id"
                    :value="getRoleValue(role.name)"
                  >
                    <role-permission
                      :role_permissions="role.permissions"
                      :show-only="true"
                    />
                  </v-tabs-window-item>
                </v-tabs-window>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import "@/assets/css/team/style.css";
import apiClient from "@/core/buildRequest";
import RolePermission from "@/components/team/AllRoles/RolePermission.vue";

export default {
  name: "RolesPermissionsView",
  components: {
    GlobalNav,
    SideBar,
    BreadCrumb,
    RolePermission,
  },
  data() {
    return {
      linksData: [
        { name: "Home", link: "/" },
        { name: "My Team", link: "" },
      ],
      tab: "one",
      roles: [],
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    getRoleValue(name) {
      return name.replace(/\s+/g, "_").toLowerCase();
    },
    getRoles() {
      apiClient
        .get(`/my-team/list-roles`)
        .then((response) => {
          this.roles = response.data.data;
          this.tab = this.getRoleValue(this.roles[0].name);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
