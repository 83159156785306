<template>
  <div class="boost-post-card message-template">
    <div class="card-header mb-4">
      <label for="" class="title d-block"> {{ $t('messaging') }} </label>
      <p class="sub-title">Choose where you want people to message you.</p>
    </div>
    <div class="card-body">
      <label for="" class="title"> Message template </label>
      <p class="sub-title">
        How do you want to welcome people who tap on your ad? Select a template.
      </p>
      <div class="welcome-message mt-3">
        <div class="d-flex align-center ga-8 mb-4">
          <v-select
            v-model="store.defaultMassageSelected"
            :items="store.defaultMassage"
            variant="outlined"
            return-object
            item-value="id"
            item-title="title"
            hide-details
          >
            <template v-slot:selection="{ item }">
              <p style="font-weight: 600; color: #000; font-size: 16px">
                {{ item?.title || "" }}
              </p>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item @click="store.selectItem(item)">
                <div class="my-form">
                  <div>
                    <input
                      :checked="
                        store.defaultMassageSelected &&
                        store.defaultMassageSelected.id === item.raw.id
                      "
                      :id="`${item.id}_call_action_type`"
                      type="radio"
                      name="option"
                      :value="item"
                    />
                    <label :for="`${item.id}_call_action_type`">{{
                      item.title
                    }}</label>
                  </div>
                </div>
              </v-list-item>
            </template>
          </v-select>
          <button><span v-html="iconSvg"></span> <span>Creat new</span></button>
        </div>
        <MassageShape />
        <AppsSelected />
      </div>
    </div>
  </div>
</template>

<script>
import { boostPost } from "@/store/publishPost/boostPost";
import MassageShape from "./MassageShape.vue";
import AppsSelected from "./AppsSelected.vue";
export default {
  components: { MassageShape, AppsSelected },
  data() {
    return {
      store: boostPost(),
      iconSvg: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0003 4.16699V15.8337M4.16699 10.0003H15.8337" stroke="#1E1E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`,
    };
  },
};
</script>

<style scoped>
.v-input {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: #e6e6e6;
}
</style>
