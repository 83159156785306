<template>
    <div class="demographics audience">
        <v-expansion-panels class="my-4">
          <v-expansion-panel title="Audience">
            <v-expansion-panel-text>
              <div class="language mb-10">
                <label for="">Include</label>
                <div>
                  <treeselect
                    :options="languages"
                    :value="value"
                    :multiple="multiple"
                  >
                  </treeselect>
                </div>
              </div>
              <div class="language mb-10">
                <label for="">Exclude</label>
                <div>
                  <treeselect
                    :options="languages"
                    :value="value"
                    :multiple="multiple"
                  >
                  </treeselect>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
</template>

<script>
import Treeselect from "vue3-treeselect";
export default {
    components: {
        Treeselect
    },
    name: "AudienceTargeting",
    data() {
        return {
            languages: [1, 2, 3].map((i) => ({
        id: i,
        label: `Label ${i}`,
        customLabel: `Custom Label ${i}`,
      })),
         
        }
    }
}
</script>