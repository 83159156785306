<template>
  <!-- Goal & Bid -->
  <div class="goal-and-bid ad-set-details-card mb-6">
    <h6 class="page-header-title mb-3">Goal & Bid</h6>
    <v-card
      max-width="100%"
      class="pa-5 mb-5 rounded-lg"
      style="overflow: visible"
      elevation="1"
    >
      <div class="card-header">
        <div class="w-100 d-flex flex-column ga-3">
          <label for="">Performance Goal </label>
          <Goals :selected="selectedGoal" @selectedGoal="getSelectedGoal" :direct_traffic_location="direct_traffic_location" />
          <label for="">Bid</label>
          <Bid :value="bidSelectedView" @selectedBid="getselectedBid" />
          <div
            v-if="
              this.bidSelectedView === 'MAX_BID' ||
              this.bidSelectedView === 'TARGET_COST'
            "
          >
            <div
              class="max-bid-amount mt-3 d-flex align-center justify-space-between"
            >
              <input
                @input="toggleValidate"
                min="0"
                class="no-spin"
                type="number"
                name=""
                id=""
                v-model="goalBidBudget"
              />
              <span class="bid-per">
                {{
                  selectedOption === "Awareness & Engagement"
                    ? "Bid is per 15 Second Video View"
                    : ""
                }}
              </span>
              <span class="bid-per">
                {{ selectedOption === "Traffic" ? "Per Click" : "" }}
                {{
                  selectedOption === "Leads"
                    ? "Bid is per Lead Form Submission"
                    : ""
                }}
              </span>
            </div>
            <div class="description-max-bid">
              <span
                v-if="validateMaxBid"
                class="validate d-flex align-center ga-2"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#444546"
                  xmlns="http://www.w3.org/2000/svg"
                  class="sds-icon sds-icon-incomplete sds-input-status styles-1hnbkxj blue-warning-svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
                  ></path>
                </svg>
                Maximum bid requires bid value
              </span>
              <p class="mt-6">
                <span>Suggested bid $0.20 - $0.57 </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import TreeSelectCustom from "@/components/snapchat/tree-select-componant/TreeSelect.vue";
import Goals from "./Goals.vue";
import Bid from "./Bid.vue";
export default {
  components: { TreeSelectCustom, Goals, Bid },
  props: ["direct_traffic_location"],
  data() {
    return {
      selectedGoal: localStorage.getItem("selected_goal"),
      bidSelectedView: localStorage.getItem("bid_selected_view") || null,
      bidContant: false,
      paiedType: [],
      goalBidBudget: localStorage.getItem("goal_bid_budget") || null,
      validateMaxBid: true,
      selectedOption: localStorage.getItem("selectedOption"),
    };
  },
  methods: {
    getSelectedGoal(selectedGoal) {
      this.selectedGoal = selectedGoal;
      localStorage.setItem("selected_goal", this.selectedGoal);
    },
    getselectedBid(selectedBid) {
      this.bidSelectedView = selectedBid;
      localStorage.setItem("bid_selected_view", this.bidSelectedView);
    },
    togglebidContant() {
      this.bidContant = !this.bidContant;
    },
  },
  computed: {
    togglevalidate() {
      return this.goalBidBudget;
    },
  },
  watch: {
    bidSelectedView() {
      localStorage.setItem("bid_selected_view", this.bidSelectedView);
    },
    goalBidBudget(value) {
      if (value !== 0) {
        this.validateMaxBid = false;
      } else if (value === "") {
        this.validateMaxBid = true;
      } else {
        this.validateMaxBid = true;
      }
      localStorage.setItem("goal_bid_budget", value);
    },
  },
  mounted() {
    localStorage.getItem("selectedOption") === "Traffic"
      ? (this.paiedType = [
          {
            id: 1,
            title: "Auto-Bid",
            autoPaied: "Recommended",
            description:
              "Makes best effort to spend your budget. Try this strategy if you want to learn what cost per action you can achieve on Snap.",
          },
          {
            id: 2,
            title: "Target Cost",
            description:
              "Achieves the most volume while aiming to keep your average cost per action below your target cost.",
          },
          {
            id: 3,
            title: "Max Bid",
            description: "Bids conservatively at or below your desired bid.",
          },
        ])
      : (this.paiedType = [
          {
            id: 1,
            title: "Auto-Bid",
            autoPaied: "Recommended",
            description:
              "Makes best effort to spend your budget. Try this strategy if you want to learn what cost per action you can achieve on Snap.",
          },
          {
            id: 2,
            title: "Max Bid",
            description: "Bids conservatively at or below your desired bid.",
          },
        ]);
    if (localStorage.getItem("selected_goal")) {
      this.selectedGoal = localStorage.getItem("selected_goal");
    }
  },
};
</script>
