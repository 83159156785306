<template>
  <div class="data-set mb-3">
    <label for="" class="d-block mb-1 roboto-bold" style="font-size: 14px; color: #1c2b33"
      >Dataset</label
    >
    <v-select
      v-model="dataSetSelected"
      :items="dataSet"
      density="compact"
      variant="outlined"
      return-object
      item-value="value"
      item-title="title"
      hide-details
    >
      <template v-slot:selection="{ item }">
        {{ item?.title || "" }}
      </template>
      <template v-slot:item="{ item, index }">
        <div v-if="index === 0">
          <p style="font-weight: bold; margin-bottom: 8px">Inactive datasets</p>
        </div>

        <v-list-item @click="selectedDataSet(item)">
          <div class="my-form">
            <div class="ga-3">
              <input
                :checked="dataSetSelected && dataSetSelected.id === item.raw.id"
                :id="`${item.raw.id}dataSet`"
                type="radio"
                name="option"
                :value="item"
              />
              <div class="d-flex flex-column ga-1">
                <label
                  :for="`${item.raw.id}dataSet`"
                  class="d-block"
                  style="font-size: 14px"
                  >{{ item.raw.title }}</label
                >
                <label
                  :for="`${item.raw.id}dataSet`"
                  style="font-size: 12px; color: #1c2b33"
                >
                  Dataset iD {{ item.raw.id }}
                </label>
              </div>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataSetSelected: {
        title: "Hajar Adel's Pixel",
        id: "8465805173506319",
      },
      dataSet: [
        {
          title: "Hajar Adel's Pixel",
          id: "8465805173506319",
        },
      ],
    };
  },
  methods:{
    selectedDataSet(item) {
      this.dataSetSelected = item.raw;
      
    },
  }
};
</script>
