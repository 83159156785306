<template>
  <v-container fluid style="padding: 0; min-height: 100vh">
    <v-row no-gutters>
      <v-col cols="12">
        <GlobalNav></GlobalNav>
      </v-col>
      <v-col cols="12">
        <div class="d-flex h-100">
          <SideBar></SideBar>
          <div class="pa-5 w-100">
            <BreadCrumb :linksData="linksData" class="mb-5" />
            <div
              class="leads-managements-link d-flex align-center ga-2 my-3 py-3"
            >
              <div
                class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
              >
                <span>{{ $t("engagement_and_insights") }}</span>
              </div>
            </div>
            <div class="mt-5 mb-5"><Filter /></div>
            <div><Table :body="store?.body" /></div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import Filter from "@/components/EngagementInsights/Filter.vue";
import Table from "@/components/EngagementInsights/Table.vue";
import { EngagementAndInsightsStore } from "@/store/EngagementAndInsights/EngagementAndInsights";

export default {
  name: "EngagementAndInsights",
  components: { BreadCrumb, GlobalNav, SideBar, Filter, Table },
  data() {
    return {
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Engagement and Insights", link: "" },
      ],
      store: EngagementAndInsightsStore(),
    };
  },
  mounted() {
    this.store?.getAllData();
  },
};
</script>
