<template>
  <v-dialog v-model="dialogModel" max-width="800" persistent>
    <v-card class="pa-4">
      <v-card-title>Edit A Project</v-card-title>

      <v-card-text>
        <div class="d-flex flex-column ga-5">
          <span class="error-message" v-for="e in errors" :key="e">{{
            e
          }}</span>

          <!-- Form Fields -->
          <template v-for="field in formFields" :key="field.id">
            <div
              :class="[
                field.type === 'autocomplete' ? 'filed-autocomplete' : 'field',
                'd-flex flex-column ga-2',
              ]"
            >
              <label
                v-if="field.type !== 'switch'"
                :ref="field.ref"
                :for="field.id"
              >
                {{ field.label }}
                <span v-if="field.required" class="required">*</span>
              </label>

              <!-- Regular Input -->
              <input
                v-if="field.type === 'input'"
                :id="field.id"
                v-model="formData[field.id]"
                :type="field.inputType || 'text'"
                @input="updateValidationClass(field.ref, formData[field.id])"
              />

              <!-- Date/Time Input -->
              <input
                v-else-if="field.type === 'datetime'"
                :id="field.id"
                v-model="formData[field.id]"
                type="text"
                @input="updateValidationClass(field.ref, formData[field.id])"
                :ref="field.id"
              />

              <!-- Regular Autocomplete -->
              <v-autocomplete
                v-else-if="field.type === 'autocomplete'"
                v-model="formData[field.id]"
                :items="field.items"
                :multiple="field.multiple"
                :item-value="field.itemValue"
                :item-title="field.itemTitle"
                hide-details
                chips
                @change="updateValidationClass(field.ref, formData[field.id])"
              />
              <div
                v-else-if="field.type === 'switch'"
                class="d-flex justify-end align-center ga-2 custom-switch"
              >
                <label for="add_to_calender">Add To Calendar</label>
                <v-switch
                  color="green"
                  v-model="formData[field.id]"
                  hide-details
                  inset
                  :true-value="1"
                  :false-value="0"
                ></v-switch>
              </div>
            </div>
          </template>
        </div>
      </v-card-text>

      <v-card-actions class="sticky-footer-dialog">
        <v-spacer></v-spacer>
        <span class="cancel-btn cursor-pointer" @click="cancelDialog"
          >Cancel</span
        >
        <span class="choose-btn cursor-pointer" @click="saveProject">Save</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { getFieldError } from "@/utils/formValdiation.js";
import apiClient from "@/core/buildRequest";
import { useContactsStore } from "@/store/crm/useContactsStore";
import { useAccountsStore } from "@/store/crm/useAccountsStore";
import { useProjectsStore } from "@/store/crm/useProjectsStore";

export default {
  name: "ProjectDialog",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        name: "",
        description: "",
        type: "",
        status: "",
        added_date: "",
        add_added_date_to_calender: 1,
        due_date: "",
        add_to_calendar: 1,
        assigness: [],
        contact_id: [],
        location: "",
        value: "",
        currency: "",
        probability: "",
        challenges: "",
        feedback: "",
      },
      errors: [],
      fieldErrors: {},
      flatpickrInstances: {},
      contacts_store: useContactsStore(),
      accounts_store: useAccountsStore(),
      this_project: null,
    };
  },

  computed: {
    dialogModel: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },

    formFields() {
      return [
        {
          id: "name",
          label: "Project Name",
          type: "input",
          required: true,
          ref: "projectNameInput",
        },
        {
          id: "description",
          label: "Description",
          type: "input",
          required: true,
          ref: "descriptionInput",
        },
        {
          id: "type",
          label: "Project Type",
          type: "input",
          required: true,
          ref: "projectTypeInput",
        },
        {
          id: "status",
          label: "Status",
          type: "autocomplete",
          items: ["active", "completed", "pending", "on_hold", "closed"],
          required: true,
          ref: "statusInput",
        },
        {
          id: "added_date",
          label: "Added Date",
          type: "datetime",
          required: true,
          ref: "addedDateInput",
        },
        {
          id: "due_date",
          label: "Due Date",
          type: "datetime",
          required: true,
          ref: "dueDateInput",
        },
        {
          id: "add_to_calendar",
          label: "Add to Calender",
          type: "switch",
          required: false,
          ref: "addDueDateToCalenderInput",
        },
        {
          id: "assignee_id",
          label: "Assignees",
          type: "autocomplete",
          items: [1, 2],
          multiple: true,
          required: true,
          ref: "assigneesInput",
        },
        {
          id: "contact_id",
          label: "Contacts",
          type: "autocomplete",
          items: this.all_contacts,
          multiple: true,
          required: true,
          ref: "contactsInput",
          itemTitle: "contact_number",
          itemValue: "id",
        },
        {
          id: "account_id",
          label: "Account",
          type: "autocomplete",
          required: true,
          ref: "accountInput",
          items: this.all_accounts,
          itemTitle: "account_number",
          itemValue: "id",
        },
        {
          id: "location",
          label: "Location",
          type: "input",
          required: true,
          ref: "locationInput",
        },
        {
          id: "value",
          label: "Value",
          type: "input",
          required: true,
          ref: "valueInput",
        },
        {
          id: "currency",
          label: "Currency",
          type: "input",
          required: true,
          ref: "currencyInput",
        },
        {
          id: "probability",
          label: "Probability",
          type: "input",
          inputType: "number",
          required: true,
          ref: "probabilityInput",
        },
        {
          id: "challenges",
          label: "Challenge",
          type: "input",
          required: true,
          ref: "challengesInput",
        },
        {
          id: "feedback",
          label: "Feedback",
          type: "input",
          required: true,
          ref: "feedbackInput",
        },
      ];
    },
    all_contacts() {
      if (this.contacts_store?.getContacts.length == 0) {
        this.contacts_store.getAllContacts();
      }
      return this.contacts_store?.getContacts || [];
    },
    all_accounts() {
      if (this.accounts_store?.getAccounts.length == 0) {
        this.accounts_store.getAllAccounts();
      }
      return this.accounts_store?.getAccounts || [];
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initializeFlatPickr();
        });
      } else {
        this.destroyFlatPickr();
      }
    },

    contacts: {
      handler(newVal) {
        this.formData.contact_id = newVal;
      },
      deep: true,
    },
    ...Object.fromEntries(
      [
        "name",
        "description",
        "type",
        "status",
        "added_date",
        "due_date",
        "assignee_id",
        "contact_id",
        "location",
        "value",
        "currency",
        "probability",
        "challenges",
        "feedback",
      ].map((field) => [
        `formData.${field}`,
        function (value) {
          const el = this.$refs[`${field}Input`]?.[0];
          if (el) {
            el.classList[!value ? "add" : "remove"]("error-validation");
          }
        },
      ])
    ),
  },

  mounted() {
    this.getProject();
    if (this.dialog) {
      this.initializeFlatPickr();
    }
  },

  beforeUnmount() {
    this.destroyFlatPickr();
  },

  methods: {
    async getProject() {
      await apiClient
        .get(`/leads-managment/projects/${this.id}`)
        .then((res) => {
          const this_project = res.data.data;
          this.formData.name = this_project.name;
          this.formData.description = this_project.description;
          this.formData.type = this_project.type;
          this.formData.status = this_project.status;
          this.formData.added_date = this_project.added_date;
          this.formData.add_added_date_to_calender =
            this_project.add_to_calendar;
          this.formData.due_date = this_project.due_date;
          this.formData.add_to_calendar = this_project.add_to_calendar;
          this.formData.assignee_id = this_project.members?.map(
            (member) => member.id
          );
          this.formData.contact_id = this_project.contacts?.map(
            (contact) => contact.id
          );
          this.formData.location = this_project.location;
          this.formData.value = this_project.value;
          this.formData.currency = this_project.currency;
          this.formData.probability = this_project.probability;
          this.formData.challenges = this_project.challenges;
          this.formData.feedback = this_project.feedback;
          this.formData.account_id = this_project.account?.id;
        });
    },
    initializeFlatPickr() {
      this.destroyFlatPickr();

      // Initialize Added Time picker
      const addedTimeEl = this.$refs.added_date?.[0];
      if (addedTimeEl) {
        this.flatpickrInstances.addedTime = flatpickr(addedTimeEl, {
          enableTime: true,
          dateFormat: "Y-m-d H:i",
          time_24hr: true,
          onChange: (selectedDates) => {
            this.formData.added_date = selectedDates[0]
              ? selectedDates[0].toISOString()
              : "";
            this.updateValidationClass("addedTimeInput", "added_date");
          },
        });
      }

      // Initialize Due Date picker
      const dueDateEl = this.$refs.due_date?.[0];
      if (dueDateEl) {
        this.flatpickrInstances.dueDate = flatpickr(dueDateEl, {
          enableTime: true,
          dateFormat: "Y-m-d H:i",
          time_24hr: true,
          onChange: (selectedDates) => {
            this.formData.due_date = selectedDates[0]
              ? selectedDates[0].toISOString().split("T")[0]
              : "";
            this.updateValidationClass("dueDateInput", "due_date");
          },
        });
      }
    },

    destroyFlatPickr() {
      Object.values(this.flatpickrInstances).forEach((instance) => {
        if (instance) {
          instance.destroy();
        }
      });
      this.flatpickrInstances = {};
    },

    validateForm() {
      this.errors = [];
      this.fieldErrors = {};
      let isValid = true;

      for (const field of this.formFields) {
        if (!field.required) continue;

        const value = this.formData[field.id];
        const error = getFieldError(field.label, value);

        if (error) {
          isValid = false;
          this.fieldErrors[field.id] = error;
          let el = this.$refs[field.ref]?.[0];
          if (el) {
            el.classList.add("error-validation");
          }
        }
      }

      return isValid;
    },

    updateValidationClass(ref, value) {
      const el = this.$refs[ref]?.[0];
      if (el) {
        el.classList[!value ? "add" : "remove"]("error-validation");
      }
    },

    resetForm() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = Array.isArray(this.formData[key]) ? [] : "";
      });
      this.errors = [];
      this.fieldErrors = {};
    },

    cancelDialog() {
      this.$emit("update:dialog", false);
    },

    async saveProject() {
      try {
        await apiClient
          .post(`/leads-managment/projects/${this.id}`, this.formData)
          .then((response) => {
            const project_store = useProjectsStore();
            project_store?.updateProject(response.data.data);
            this.$emit("update:dialog", false);
          });
      } catch (error) {
        this.errors.push(error.response?.data?.msg || "An error occurred");
        console.error(error);
      }
    },
  },
  created() {
    if (this.contacts.length > 0) {
      this.formData.contact_id = this.contacts;
    }
  },
};
</script>
