<template>
  <div class="container">
    <!-- Search Section -->
    <div class="location-search">
      <!-- Search Input -->
      <div class="search-container">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search locations..."
          class="search-input"
          @input="handleSearch"
        />
      </div>
      <!-- Search Results -->
      <div v-if="showResults" class="search-results">
        <div v-if="filteredLocations.length" class="results-container">
          <div
            v-for="(location, index) in filteredLocations"
            :key="location.id"
            class="result-item"
            :class="{
              active: selectedIndex === index,
              selected: isLocationSelected(location),
            }"
            @click="handleLocationToggled(location)"
            @mouseover="selectedIndex = index"
          >
            <div class="location-info">
              <input
                type="checkbox"
                :checked="isLocationSelected(location)"
                @click.stop
                class="location-checkbox"
              />
              <span class="location-name">{{ location.name }}</span>
            </div>
            <span class="location-type">{{ location.type }}</span>
          </div>
        </div>
        <div v-else class="no-results">No locations found</div>
      </div>
    </div>
    <!-- Selected Locations Section -->
    <div class="selected-locations" v-if="selectedLocations.length">
      <div class="locations-list">
        <div
          v-for="location in selectedLocations"
          :key="location.id"
          class="location-item"
        >
          <div class="location-details">
            <span class="location-name">{{ location.name }}</span>
            <span class="location-type">{{ location.type }}</span>
          </div>
          <button
            class="remove-button"
            @click="handleRemoveLocation(location)"
            title="Remove location"
          >
            ×
          </button>
        </div>
      </div>
    </div>
    <!-- <div v-else class="no-selections">No locations selected</div> -->
  </div>
</template>

<script>
import { locationData } from "@/core/country";
export default {
  name: "LocationSearchView",

  data() {
    return {
      locations: {
        DMA: locationData.DMA,
        cities: locationData.cities,
        countries: locationData.countries,
        provinces: locationData.provinces,
      },
      selectedLocations: [],
      searchQuery: "",
      showResults: false,
      selectedIndex: -1,
      debounceTimeout: null,
    };
  },
  computed: {
    filteredLocations() {
      if (!this.searchQuery) return [];
      const results = [];
      const query = this.searchQuery.toLowerCase();
      const maxResults = 10;
      const addLocations = (locationList, type) => {
        for (const location of locationList) {
          if (results.length >= maxResults) break;
          if (location.name.toLowerCase().includes(query)) {
            results.push({
              ...location,
              type,
            });
          }
        }
      };
      addLocations(this.locations.DMA, "DMA");
      addLocations(this.locations.cities, "City");
      addLocations(this.locations.countries, "Country");
      addLocations(this.locations.provinces, "Province");
      return results;
    },
  },
  methods: {
    handleSearch() {
      clearTimeout(this.debounceTimeout);
      localStorage.setItem("countryId", this.searchQuery.length);
      this.debounceTimeout = setTimeout(() => {
        this.showResults = true;
        this.selectedIndex = -1;
      }, 300);
    },

    handleLocationToggled(location) {
      const index = this.selectedLocations.findIndex(
        (item) => item.id === location.id
      );
      if (index === -1) {
        this.selectedLocations.push(location);
      } else {
        this.selectedLocations.splice(index, 1);
      }
      const selectedCountryIds = this.selectedLocations.map((loc) => loc.id);
      localStorage.setItem(
        "selectedCountryIds",
        JSON.stringify(selectedCountryIds)
      );
    },
    handleRemoveLocation(location) {
      const index = this.selectedLocations.findIndex(
        (item) => item.id === location.id
      );
      if (index !== -1) {
        this.selectedLocations.splice(index, 1);
      }
      // Update localStorage after removal
      const selectedCountryIds = this.selectedLocations.map((loc) => loc.id);
      localStorage.setItem(
        "selectedCountryIds",
        JSON.stringify(selectedCountryIds)
      );
    },
    isLocationSelected(location) {
      return this.selectedLocations.some((item) => item.id === location.id);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showResults = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    const storedCountryIds =
      JSON.parse(localStorage.getItem("selectedCountryIds")) || [];
    this.selectedLocations = storedCountryIds
      .map((id) => {
        // Find the location object in the available locations
        return (
          this.locations.DMA.find((loc) => loc.id === id) ||
          this.locations.cities.find((loc) => loc.id === id) ||
          this.locations.countries.find((loc) => loc.id === id) ||
          this.locations.provinces.find((loc) => loc.id === id)
        );
      })
      .filter(Boolean);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    clearTimeout(this.debounceTimeout);
  },
};
</script>
<style scoped>
.container {
  max-width: 800px;
}

/* Search Styles */
.location-search {
  width: 100%;
  position: relative;
}

.search-container {
  width: 100%;
  position: relative;
}

.search-input {
  width: 60%;
  padding: 0 12px;
  font-size: 14px;
  line-height: 32px;
  border: 1px solid #f2f3f3;
  border-radius: 4px;
  outline: none;
  background-color: #f2f3f3;
  transition: border-color 0.2s;
}

.search-input:focus {
  border-color: #009995;
  box-shadow: 0 0 0 2px #c3e9e7;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
  width: 70%;
}

.results-container {
  padding: 8px 0;
}

.result-item {
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-item:hover,
.result-item.active {
  background-color: #f5f5f5;
}

.result-item.selected {
  background-color: #e8f5e9;
}

.location-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.location-checkbox {
  cursor: pointer;
}

.no-results {
  padding: 16px;
  text-align: center;
  color: #666;
}

/* Selected Locations Styles */
.selected-locations {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f1f2f0;
  width: 60%;
}

h3 {
  margin: 0 0 15px;
  color: #2c3e50;
  font-size: 1.2em;
}

.locations-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.location-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.location-details {
  display: flex;
  gap: 10px;
  align-items: center;
}

.location-name {
  font-weight: 500;
  font-size: 14px;
  color: #121415;
}

.location-type {
  color: #666;
  font-size: 0.9em;
  padding: 2px 6px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 12px;
}

.remove-button {
  background: none;
  border: none;
  color: #ff5252;
  font-size: 20px;
  cursor: pointer;
  padding: 0 6px;
  line-height: 1;
  border-radius: 4px;
}

.remove-button:hover {
  background-color: #ffebee;
}

.no-selections {
  text-align: center;
  color: #666;
  font-style: italic;
}
</style>
