<template>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    location="top"
    :close-on-content-click="true"
  >
    {{ snackbar.message }}
  </v-snackbar>

  <v-dialog v-model="localDialog" width="auto">
    <v-card>
      <v-card-text class="scrollable-dialog">
        <div class="new-form d-flex flex-column justify-space-between">
          <div class="title mb-5">Create Folder</div>
          <div class="">
            <div class="d-flex ga-5 flex-column">
              <label for="folderName" class="label-name"
                >Folder Name <span style="color: red">*</span>
              </label>
              <Input
                v-model="folderName"
                type="text"
                :icon="SearchIcon"
                placeholder="Folder Name"
              />
            </div>
          </div>
          <div class="button-container d-flex justify-end mt-5">
            <v-btn
              color="white"
              variant="text"
              @click="createFolder"
              class="upload-btn"
            >
              Create Folder
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Input from "@/components/crm/assets/Input.vue";
import apiClient from "@/core/buildRequest";
import { DriveStore } from "@/store/drive/DriveStore.js";

export default {
  name: "NewFolder",
  props: ["dialog"],
  components: { Input },
  data() {
    return {
      localDialog: this.dialog,
      folderName: "",
      snackbar: {
        color: "",
        show: false,
        message: "",
        timeout: 3000,
      },
      store: DriveStore(),
    };
  },
  watch: {
    dialog(val) {
      this.localDialog = val;
    },
    localDialog(val) {
      this.$emit("update:dialog", val);
    },
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    createFolder() {
      if (this.folderName === "") {
        this.showSnackbar("Folder name is required", "error");
        return;
      }
      apiClient
        .post("/my-drive/create-folder", { name: this.folderName })
        .then((response) => {
          this.store?.getAllDrive();
          this.showSnackbar(
            `Folder "${this.folderName}" created successfully`,
            "success"
          );
          this.folderName = "";
          this.closeDialog();
        })
       
    },

    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
  },
};
</script>
