<template>
  <div class="locations-card ad-set-details-card mb-6">
    <!-- Header section -->
    <div
      class="locations-parent d-flex mb-4 align-center ga-12 justify-space-between"
    >
      <div class="location-header">
        <h6 class="page-header-title mb-1">Locations</h6>
        <p>
          As of July 25th, 2024, location targeting will include people who we
          believe are living, working, or have recently been in the area. This
          change only affects new ad sets. <a href="">Learn More.</a>
        </p>
      </div>
      <button @click="changeDetactedLocationType" class="locations-button">
        <span v-if="hideMap">Target Locations on Map</span>
        <span v-else>Target Predefined Locations</span>
      </button>
    </div>

    <!-- Main card content -->
    <div
      max-width="100%"
      class="pa-5 mb-5 rounded-lg card-content bg-white"
      style="overflow: visible"
      elevation="1"
    >
      <!-- Card header -->
      <div class="card-header mb-4 d-flex align-center justify-space-between">
        <p>Your audience is located in:</p>
        <div class="d-flex align-center ga-5">
          <!-- Reset button -->
          <button
            type="button"
            @click="emptySelectedCountry"
            class="action-button"
          >
            <span>
              <svg
                width="15"
                height="15"
                viewBox="0 0 16 16"
                fill="#33bbff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.72491 3.77886C6.45718 3.77886 5.24137 4.28247 4.34494 5.17889C3.44852 6.07531 2.94491 7.29113 2.94491 8.55886C2.94491 9.82509 3.44733 11.0395 4.34175 11.9356C5.23873 12.807 6.44377 13.2886 7.69436 13.2755C8.94646 13.2623 10.1425 12.7543 11.0212 11.8622L11.0249 11.8585L11.0286 11.8549C11.9184 10.9793 12.4321 9.79158 12.4607 8.54357L13.7937 8.57415C13.7572 10.1671 13.1021 11.6833 11.9674 12.8017C10.8418 13.9423 9.31094 14.5919 7.70838 14.6087C6.10408 14.6256 4.55828 14.007 3.40856 12.888L3.40852 12.888L3.40213 12.8816C2.25566 11.7352 1.61158 10.1802 1.61158 8.55886C1.61158 6.9375 2.25566 5.38255 3.40213 4.23608C4.54861 3.08961 6.10356 2.44553 7.72491 2.44553C9.26819 2.44553 10.7513 3.02907 11.8793 4.07396L11.9832 3.06022L13.3096 3.19626L12.99 6.31284L9.87781 6.61549L9.74875 5.28842L11.0844 5.15853C10.19 4.27484 8.98302 3.77886 7.72491 3.77886Z"
                ></path>
              </svg>
            </span>
            <span class="btn-text">Reset</span>
          </button>

          <!-- Upload button -->
          <button type="button" @click="dialog = true" class="action-button">
            <span>
              <svg
                width="15"
                height="15"
                viewBox="0 0 16 16"
                fill="#33bbff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667L2 9.33333L3.33333 9.33333L3.33333 12.6667L12.6667 12.6667L12.6667 9.33333L14 9.33333L14 12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14L3.33333 14C2.97971 14 2.64057 13.8595 2.39052 13.6095Z"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.37337 4.27611L5.93141 5.71807L4.9886 4.77526L8.04001 1.72385L11.0914 4.77526L10.1486 5.71807L8.7067 4.27617L8.7067 11.3333L7.37337 11.3333L7.37337 4.27611Z"
                ></path>
              </svg>
            </span>
            <span class="btn-text">Upload Locations in Bulk</span>
          </button>
        </div>
      </div>

      <!-- Selected regions display -->
      <div class="card-body">
        <div class="country-select">
          <v-expansion-panels>
            <v-expansion-panel
              class="snap-expansion"
              v-for="country in selectedCountries"
              :key="country.id"
              :expand-icon="'x'"
            >
              <!-- :disabled="
                selectedRegions.filter(
                  (region) => region.country_code === country.code
                ).length === 0
              " -->
              <v-expansion-panel-title disable-icon-rotate="true">
                <template v-slot:default="{ expanded }">
                  <div class="d-flex justify-space-between align-center w-100">
                    <div class="d-flex align-center ga-2">
                      <v-icon
                        :icon="expanded ? 'mdi-menu-up' : 'mdi-menu-down'"
                      >
                      </v-icon>
                      <span>{{ country.label }}</span>
                    </div>
                    <span @click="removeCountry(country)">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="#33bbff"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text
                v-if="
                  selectedRegions.filter(
                    (region) => region.country_code === country.code
                  ).length > 0
                "
              >
                <v-expansion-panels>
                  <v-expansion-panel class="snap-expansion">
                    <v-expansion-panel-title disable-icon-rotate="true">
                      <template v-slot:default>
                        <span>Regions</span>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <span
                        v-for="reg in selectedRegions.filter(
                          (region) => region.country_code === country.code
                        )"
                        :key="reg.id"
                        class="d-flex align-center justify-space-between region-option"
                      >
                        <span class="d-flex align-center ga-2">
                          <svg
                            v-if="reg.type === 'include'"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="#39CA8E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="sds-icon sds-icon-check styles-im1iax"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                            ></path>
                          </svg>
                          <svg
                            v-if="reg.type === 'exclude'"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="#DC2E4B"
                            xmlns="http://www.w3.org/2000/svg"
                            class="sds-icon sds-icon-cancelled styles-im1iax"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.5 8C12.5 10.4853 10.4853 12.5 8 12.5C7.04273 12.5 6.15527 12.2011 5.42586 11.6915L11.6453 5.36098C12.183 6.10236 12.5 7.01416 12.5 8ZM4.3557 10.6405L10.5756 4.30949C9.84591 3.7993 8.95791 3.5 8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 8.98648 3.81742 9.89882 4.3557 10.6405ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                            ></path>
                          </svg>
                          <span>{{ reg.label }}</span>
                        </span>
                        <span class="d-flex align-center ga-2 actions">
                          <v-btn
                            class="inc-exc-btn"
                            variant="text"
                            v-if="reg.type === 'exclude'"
                            @click="reg.type = 'include'"
                          >
                            include
                          </v-btn>
                          <v-btn
                            class="inc-exc-btn"
                            variant="text"
                            v-if="reg.type === 'include'"
                            @click="reg.type = 'exclude'"
                          >
                            exclude
                          </v-btn>
                          <svg
                            @click="removeRegion(reg)"
                            class="cursor-pointer"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="#33bbff"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                            ></path>
                          </svg>
                        </span>
                      </span>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panels>
              <v-expansion-panel
                class="snap-expansion"
                v-for="(location, index) in selectedMapLocations"
                :key="location.latitude"
              >
                <v-expansion-panel-title :disable-icon-rotate="true">
                  <template v-slot:default="{ expanded }">
                    <div
                      class="d-flex justify-space-between align-center w-100"
                    >
                      <div class="d-flex align-center ga-2">
                        <v-icon
                          :icon="expanded ? 'mdi-menu-up' : 'mdi-menu-down'"
                        ></v-icon>
                        <span>{{ location.country }}</span>
                      </div>
                      <span @click="removeCountryFromMap(index)">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="#33bbff"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Libero harum earum laudantium tempora ipsum odio quibusdam
                  necessitatibus ex accusamus consectetur voluptate quo,
                  aperiam, iure officiis! Odit rerum laudantium dolorem omnis.
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panels>
        </div>

        <!-- Country selection menu -->
        <div class="d-flex justify-end">
          <v-menu
            v-model="continentsMenu"
            :close-on-content-click="false"
            location="end"
          >
            <template v-slot:activator="{ props }">
              <span color="indigo" v-bind="props" class="blue-link"
                >Add Countries</span
              >
            </template>
            <v-card>
              <div>
                <v-row style="min-width: 500px; height: 280px" noGutters>
                  <!-- Continent Selection -->
                  <v-col cols="6">
                    <div
                      v-for="(continent, index) in continentsList"
                      :key="index"
                      class="d-flex align-center justify-space-between ga-3 option"
                      @click="ShowContinentCountries(continent)"
                    >
                      <div class="d-flex align-center ga-1">
                        <input
                          class="continent-checkbox"
                          :value="continent.id"
                          type="checkbox"
                          :id="'continent_' + index"
                          v-model="selectedContinents"
                          @change="toggleContinentSelection(continent)"
                        />
                        <label
                          :for="'continent_' + index"
                          class="continent-span"
                        >
                          {{ getContinentLabel(continent.label) }}
                        </label>
                      </div>
                      <div>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="#444546"
                          xmlns="http://www.w3.org/2000/svg"
                          class="cursor-pointer"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.99052 7.99996L5.22192 11.7686L6.16473 12.7114L10.8761 7.99996L6.16473 3.28856L5.22192 4.23136L8.99052 7.99996Z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </v-col>

                  <v-divider
                    :vertical="$vuetify.display.mdAndUp"
                    class="my-md-3"
                  ></v-divider>
                  <v-col cols="6" style="height: 100%; overflow-y: scroll">
                    <div
                      v-for="(
                        country, childIndex
                      ) in selected_continent_countries"
                      :key="childIndex"
                      class="d-flex align-center justify-space-between ga-3 option"
                    >
                      <div class="d-flex align-center ga-2">
                        <input
                          class="country-checkbox"
                          :value="country"
                          type="checkbox"
                          :id="'country_' + country.id"
                          v-model="selectedCountries"
                          @change="syncContinentSelection(country)"
                        />
                        <label
                          :for="'country_' + country.id"
                          class="continent-span"
                        >
                          {{ country.label }}
                        </label>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-menu>
        </div>
        <div v-if="hideMap" class="">
          <treeselect
            v-model="selectedRegions"
            :multiple="true"
            :options="processedCountriesList"
            :value-format="'object'"
            placeholder="Select regions..."
            @input="handleRegionSelection"
          >
          </treeselect>
        </div>
        <div class="" v-else>
          <Map @map-selected="RegionSelectionFromMap" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/snapchatStyle/snapchatStyle.css";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import apiClient from "@/core/buildRequest";
import Map from "@/components/snapchat/GoogleMap.vue";

export default {
  name: "LocationsCard",
  components: {
    Treeselect,
    Map,
  },
  data() {
    return {
      hideMap: localStorage.getItem("hideMap")
        ? localStorage.getItem("hideMap") === "true"
        : true,
      dialog: false,
      continentsList: [],
      continentsMenu: false,
      locationText: "",
      selectedContinents: JSON.parse(
        localStorage.getItem("selectedContinents") || "[]"
      ),
      selectedCountries: JSON.parse(
        localStorage.getItem("selectedCountries") || "[]"
      ),
      selectedRegions: JSON.parse(
        localStorage.getItem("selectedRegions") || "[]"
      ),
      publisherStoriesSelectedCategoriesTypes: "include",
      publisherStoriesCategoriesTypes: ["include", "exclude"],
      regions: ["regions"],
      regionsModel: "regions",
      selected_continent_countries: [],
      processedCountriesList: [], // Move from computed to data
      selectedMapLocations: [],
    };
  },
  methods: {
    getContinentLabel(l) {
      switch (l) {
        case "af":
          return "Africa";
        case "as":
          return "Asia";
        case "eu":
          return "Europe";
        case "na":
          return "North America";
        case "oc":
          return "Oceania";
        case "sa":
          return "South America";
        case "an":
          return "Antarctica";
        default:
          return "Unknown"; // Added a default case for safety
      }
    },
    async updateProcessedCountriesList() {
      try {
        let processed = [];
        for (const country of this.selectedCountries) {
          const response = await apiClient.get(
            `/campaign-ads/snapchat/countries/${country.code}`
          );
          processed.push({
            id: country.id,
            code: country.code,
            label: country.label,
            children: response.data.data[0].children[0].children.map(
              (child) => ({
                id: child.id,
                code: child.code,
                label: child.label,
                country_code: country.code,
                type: "include",
              })
            ),
          });
        }
        this.processedCountriesList = processed;
      } catch (error) {
        console.error("Error processing countries list:", error);
      }
    },
    ShowContinentCountries(continent) {
      this.selected_continent_countries = continent.children;
    },
    toggleContinentSelection(continent) {
      this.ShowContinentCountries(continent);
      const isSelected = this.selectedContinents.includes(continent.id);
      if (isSelected) {
        continent.children.forEach((country) => {
          if (!this.selectedCountries.some((c) => c.id === country.id)) {
            this.selectedCountries.push({ ...country });
          }
        });
      } else {
        this.selectedCountries = this.selectedCountries.filter(
          (country) => !continent.children.some((c) => c.id === country.id)
        );
        this.selectedRegions = this.selectedRegions.filter(
          (region) =>
            !continent.children.some((c) => region.id.startsWith(c.id))
        );
      }
      this.updateProcessedCountriesList();
    },
    syncContinentSelection(country) {
      const continentCountries = country.children.map((child) => child.id);
      const allSelected = continentCountries.every((countryId) =>
        this.selectedCountries.some((c) => c.id === countryId)
      );
      const continent = this.continentsList.find((c) =>
        c.children.some((child) => child.id === country.id)
      );
      if (allSelected && !this.selectedContinents.includes(continent.id)) {
        this.selectedContinents.push(continent.id);
      } else if (!allSelected) {
        this.selectedContinents = this.selectedContinents.filter(
          (id) => id !== continent.id
        );
      }
      this.updateProcessedCountriesList();
    },
    handleRegionSelection(value) {
      this.selectedRegions = value;
    },
    removeCountry(country) {
      this.selectedCountries = this.selectedCountries.filter(
        (r) => r.id !== country.id
      );
      this.updateProcessedCountriesList(); // Update when a country is removed
    },
    removeRegion(region) {
      this.selectedRegions = this.selectedRegions.filter(
        (r) => r.id !== region.id
      );
    },
    emptySelectedCountry() {
      this.selectedCountries = [];
      this.selectedContinents = [];
      this.selectedRegions = [];
      this.processedCountriesList = []; // Clear processed list
    },
    changeDetactedLocationType() {
      this.hideMap = !this.hideMap;
    },
    async getAllCountries() {
      try {
        const response = await apiClient.get(
          "/campaign-ads/snapchat/country-code"
        );
        this.continentsList = response.data.data;
      } catch (error) {
        console.error("Error fetching countries data:", error);
      }
    },
    RegionSelectionFromMap(selectedRegions) {
      this.selectedMapLocations = selectedRegions;
    },
  },
  watch: {
    hideMap() {
      localStorage.setItem("hideMap", this.hideMap);
    },
    selectedContinents: {
      handler(newValue) {
        localStorage.setItem("selectedContinents", JSON.stringify(newValue));
      },
      deep: true,
    },
    selectedCountries: {
      handler(newValue) {
        localStorage.setItem("selectedCountries", JSON.stringify(newValue));
        this.updateProcessedCountriesList();
        this.$emit("country:selected", newValue);
      },
      deep: true,
    },
    selectedRegions: {
      handler(newValue) {
        localStorage.setItem("selectedRegions", JSON.stringify(newValue));
      },
      deep: true,
    },
  },
  mounted() {
    this.getAllCountries();
    this.updateProcessedCountriesList();
    this.selectedMapLocations =
      JSON.parse(localStorage.getItem("selectedMapLocatoins")) || [];
  },
};
</script>
<style scoped>
.locations-card {
  margin-bottom: 2rem;
}

.selected-region {
  background-color: #f3f4f6;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-button {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #33bbff;
  transition: color 0.2s;
}

.action-button:hover {
  color: #0894fa;
}

.continent-checkbox {
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
}

.continent-span {
  white-space: nowrap;
  margin-left: 0.5rem;
}

.option {
  height: 40px;
  color: #848688;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 10px;
  cursor: pointer;
}

.option:hover {
  background-color: #f9f9fa;
}

.blue-link {
  cursor: pointer;
  color: #33bbff;
  text-decoration: none;
}

.blue-link:hover {
  color: #0894fa;
}

/* Treeselect customization */
:deep(.vue3-treeselect) {
  width: 100%;
}

:deep(.vue3-treeselect__control) {
  border-color: #e2e8f0;
  border-radius: 0.375rem;
}

:deep(.vue3-treeselect__placeholder) {
  color: #a0aec0;
}

:deep(.vue3-treeselect__option) {
  padding: 0.5rem 1rem;
}

:deep(.vue3-treeselect__option--highlight) {
  background-color: #f7fafc;
}

.cross {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.cross:hover {
  opacity: 1;
}

.locations-button {
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.locations-button:hover {
  background-color: #f7fafc;
}
</style>
