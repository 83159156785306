<template>
  <div class="message-shape mb-4">
    <div class="message-shape-header mb-3">
      <div>
        <p class="title">Greeting</p>
        <p class="sub-title">
          Hi, Shaimaa! Please let us know how we can help you.
        </p>
      </div>
      <div class="d-flex align-center ga-1">
        <span>
          <img :src="editIcon" alt="Edit" />
        </span>
        <span>
          <img :src="duplicate" alt="Duplicate" />
        </span>
        <span>
          <img :src="deleteIcon" alt="Delete" />
        </span>
      </div>
    </div>
    <div class="message-shape-body">
      <h4>Questions</h4>
      <p>1. Is anyone available to chat?</p>
      <p>2. I'm interested. Can you tell me more?</p>
      <p>3. Can I learn more about your business?</p>
    </div>
  </div>
</template>

<script>
import editIcon from "@/assets/images/edite_w.svg";
import duplicate from "@/assets/images/g2626.svg";
import deleteIcon from "@/assets/images/delete_s.svg";
export default {
  data() {
    return {
      editIcon,
      duplicate,
      deleteIcon,
    };
  },
};
</script>
