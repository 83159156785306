<template>
  <div>
    <ReachedStatus :item="item" @select-reached-info="selectReachedInfo" /> 
  </div>
</template>
<script>
import ReachedStatus from "@/components/crm/leads/options/ReachedStatus.vue";

export default {
  props: ["item"],
  name: "ProgressCol",
  components: { ReachedStatus },
  data() {
    return {
      reached_type: this.item.leadsProgress,
      reached_info: {
        doctor_name: this.item.bookedInfo?.doctor_name,
        department_name: this.item.booking?.department,
        consultation_date: this.item.booking?.appointment_date,
        service: this.item.booking?.service,
      },
    };
  },
  methods: {
    async selectReachedInfo(
      doctor_name,
      department_name,
      consultation_date,
      service
    ) {
      this.reached_info.doctor_name = doctor_name;
      this.reached_info.department_name = department_name;
      this.reached_info.consultation_date = consultation_date;
      this.reached_info.service = service;
    },
  },
  mounted() {
    
  },
};
</script>
