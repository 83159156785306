<template>
  <v-dialog v-model="dialogModel" max-width="800" persistent>
    <v-card class="pa-4">
      <v-card-title>Edit An Event</v-card-title>

      <v-card-text>
        <div class="d-flex flex-column ga-5">
          <span class="error-message" v-for="e in errors" :key="e">{{
            e
          }}</span>

          <!-- Form Fields -->
          <template v-for="field in formFields" :key="field.id">
            <div
              :class="[
                field.type === 'autocomplete' ? 'filed-autocomplete' : 'field',
                'd-flex flex-column ga-2',
              ]"
            >
              <label
                v-if="field.type !== 'switch'"
                :ref="field.ref"
                :for="field.id"
              >
                {{ field.label }}
                <span v-if="field.required" class="required">*</span>
              </label>

              <!-- Regular Input -->
              <input
                v-if="field.type === 'input'"
                :id="field.id"
                v-model="formData[field.id]"
                :type="field.inputType || 'text'"
                @input="updateValidationClass(field.ref, formData[field.id])"
              />

              <!-- Date Input -->
              <input
                v-else-if="field.type === 'date'"
                :id="field.id"
                v-model="formData[field.id]"
                type="text"
                :ref="field.id"
                @input="updateValidationClass(field.ref, formData[field.id])"
              />

              <!-- Autocomplete -->
              <v-autocomplete
                v-else-if="field.type === 'autocomplete'"
                v-model="formData[field.id]"
                :items="field.items"
                :multiple="field.multiple"
                :item-value="field.itemValue"
                :item-title="field.itemTitle"
                hide-details
                @change="updateValidationClass(field.ref, formData[field.id])"
              />

              <!-- Switch -->
              <div
                v-else-if="field.type === 'switch'"
                class="d-flex justify-end align-center ga-2 custom-switch"
              >
                <label :for="field.id">{{ field.label }}</label>
                <v-switch
                  color="green"
                  v-model="formData[field.id]"
                  hide-details
                  inset
                  :true-value="1"
                  :false-value="0"
                ></v-switch>
              </div>
            </div>
          </template>
        </div>
      </v-card-text>

      <v-card-actions class="sticky-footer-dialog">
        <v-spacer></v-spacer>
        <span class="cancel-btn cursor-pointer" @click="cancelDialog"
          >Cancel</span
        >
        <span class="choose-btn cursor-pointer" @click="saveEvent">Save</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { getFieldError } from "@/utils/formValdiation.js";
import apiClient from "@/core/buildRequest";
import { useContactsStore } from "@/store/crm/useContactsStore";
import { useAccountsStore } from "@/store/crm/useAccountsStore";
import { useProjectsStore } from "@/store/crm/useProjectsStore";
import { useDealsStore } from "@/store/crm/useDealsStore";
import { useTasksStore } from "@/store/crm/useTasksStore";
import { useEventsStore } from "@/store/crm/useEventsStore";
export default {
  name: "EventDialog",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    accounts: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        name: "",
        objectives: "",
        date: "",
        add_to_calendar: 1,
        assigness: [],
        status: "",
        type: "",
        contact_id: [],
        account_id: [],
        project_id: "",
        deal_id: "",
        task_id: "",
      },
      errors: [],
      fieldErrors: {},
      flatpickrInstances: {},
      contacts_store: useContactsStore(),
      accounts_store: useAccountsStore(),
      projects_store: useProjectsStore(),
      deals_store: useDealsStore(),
      tasks_store: useTasksStore(),
      events_store: useEventsStore(),
    };
  },

  computed: {
    dialogModel: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },

    formFields() {
      return [
        {
          id: "name",
          label: "Event Name",
          type: "input",
          required: true,
          ref: "eventNameInput",
        },
        {
          id: "objectives",
          label: "Objectives",
          type: "input",
          required: true,
          ref: "objectivesInput",
        },
        {
          id: "date",
          label: "Event Date",
          type: "date",
          required: true,
          ref: "eventDateInput",
        },
        {
          id: "add_to_calendar",
          label: "Add To Calendar",
          type: "switch",
          required: false,
        },
        {
          id: "assigness",
          label: "Assignees",
          type: "autocomplete",
          items: [1, 2],
          multiple: true,
          required: true,
          ref: "assigneesInput",
        },
        {
          id: "status",
          label: "Status",
          type: "autocomplete",
          items: ["active", "pending", "on_hold", "completed", "closed"],
          required: true,
          ref: "statusInput",
        },
        {
          id: "type",
          label: "Event Type",
          type: "autocomplete",
          items: ["phone_call", "f2f_meeting", "virtual_meeting"],
          required: true,
          ref: "eventTypeInput",
        },
        {
          id: "contact_id",
          label: "Contacts",
          type: "autocomplete",
          items: this.all_contacts,
          multiple: true,
          required: true,
          ref: "contactsInput",
          itemTitle: "contact_number",
          itemValue: "id",
        },
        {
          id: "account_id",
          label: "Account",
          type: "autocomplete",
          items: this.all_accounts,
          multiple: false,
          required: true,
          ref: "accountsInput",
          itemTitle: "account_number",
          itemValue: "id",
        },
        {
          id: "project_id",
          label: "Project",
          type: "autocomplete",
          items: this.all_projects,
          required: true,
          ref: "projectInput",
          itemTitle: "project_number",
          itemValue: "id",
        },
        {
          id: "deal_id",
          label: "Deal",
          type: "autocomplete",
          items: this.all_deals,
          required: true,
          ref: "dealInput",
          itemTitle: "deal_number",
          itemValue: "id",
        },
        {
          id: "task_id",
          label: "Task",
          type: "autocomplete",
          items: this.all_tasks,
          required: true,
          ref: "taskInput",
          itemTitle: "task_number",
          itemValue: "id",
        },
      ];
    },
    all_contacts() {
      if (this.contacts_store?.getContacts.length == 0) {
        this.contacts_store?.getAllContacts();
      }
      return this.contacts_store?.getContacts || [];
    },
    all_accounts() {
      if (this.accounts_store?.getAccounts.length == 0) {
        this.accounts_store?.getAllAccounts();
      }
      return this.accounts_store?.getAccounts || [];
    },
    all_projects() {
      if (this.projects_store?.getProjects.length == 0) {
        this.projects_store?.getAllProjects();
      }
      return this.projects_store?.getProjects || [];
    },
    all_deals() {
      if (this.deals_store?.getDeals.length == 0) {
        this.deals_store?.getAllDeals();
      }
      return this.deals_store?.getDeals || [];
    },
    all_tasks() {
      if (this.tasks_store?.getTasks.length == 0) {
        this.tasks_store?.getAllTasks();
      }
      return this.tasks_store?.getTasks || [];
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initializeFlatPickr();
        });
      } else {
        this.destroyFlatPickr();
      }
    },

    contacts: {
      handler(newVal) {
        this.formData.contact_id = newVal;
      },
      deep: true,
    },

    accounts: {
      handler(newVal) {
        this.formData.accounts = newVal;
      },
      deep: true,
    },
  },

  mounted() {
    this.getEvent();
    if (this.dialog) {
      this.initializeFlatPickr();
    }
  },

  beforeUnmount() {
    this.destroyFlatPickr();
  },

  methods: {
    async getEvent() {
      await apiClient.get(`/leads-managment/events/${this.id}`).then((res) => {
        const this_event = res.data.data;
        this.formData.name = this_event.name;
        this.formData.objectives = this_event.objectives;
        this.formData.date = this_event.date;
        this.formData.add_to_calendar = this_event.add_to_calendar ?? 0;
        this.formData.assigness = this_event.members.map((item) => {
          return item.id;
        });
        this.formData.status = this_event.status;
        this.formData.type = this_event.type;
        this.formData.contact_id = this_event.contacts.map((item) => {
          return item.id;
        });
        this.formData.account_id = this_event.account[0].id;
        this.formData.project_id = this_event.project.id;
        this.formData.deal_id = this_event.deal.id;
        this.formData.task_id = this_event.task.id;
      });
    },
    initializeFlatPickr() {
      this.destroyFlatPickr();

      const eventDateEl = this.$refs.date?.[0];
      if (eventDateEl) {
        this.flatpickrInstances.eventDate = flatpickr(eventDateEl, {
          enableTime: false,
          dateFormat: "Y-m-d",
          onChange: (selectedDates) => {
            this.formData.date = selectedDates[0]
              ? selectedDates[0].toISOString().split("T")[0]
              : "";
            this.updateValidationClass("eventDateInput", this.formData.date);
          },
        });
      }
    },

    destroyFlatPickr() {
      Object.values(this.flatpickrInstances).forEach((instance) => {
        if (instance) {
          instance.destroy();
        }
      });
      this.flatpickrInstances = {};
    },

    validateForm() {
      this.errors = [];
      this.fieldErrors = {};
      let isValid = true;

      for (const field of this.formFields) {
        if (!field.required) continue;

        const value = this.formData[field.id];
        const error = getFieldError(field.label, value);

        if (error) {
          isValid = false;
          this.fieldErrors[field.id] = error;
          let el = this.$refs[field.ref]?.[0];
          if (el) {
            el.classList.add("error-validation");
          }
        }
      }

      return isValid;
    },

    updateValidationClass(ref, value) {
      const el = this.$refs[ref]?.[0];
      if (el) {
        el.classList[!value ? "add" : "remove"]("error-validation");
      }
    },

    resetForm() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = Array.isArray(this.formData[key]) ? [] : "";
      });
      this.formData.add_to_calendar = 1;
      this.errors = [];
      this.fieldErrors = {};
    },

    cancelDialog() { 
      this.$emit("update:dialog", false);
    },

    async saveEvent() {
      if (!this.validateForm()) return;
      try {
        await apiClient
          .post(`/leads-managment/events/${this.id}`, this.formData)
          .then((res) => {
            const event_store = useEventsStore();
            event_store?.updateEvent(res.data.data);
            this.$emit("update:dialog", false);
          });
      } catch (error) {
        this.errors.push(error.response?.data?.msg || "An error occurred");
        console.error(error);
      }
    },
  },

  created() {
    if (this.contacts.length > 0) {
      this.formData.contacts = this.contacts;
    }
    if (this.accounts.length > 0) {
      this.formData.accounts = this.accounts;
    }
  },
};
</script>
