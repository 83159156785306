<template>
  <div class="d-flex flex-column gap-3">
    <v-expansion-panels>
      <v-expansion-panel bg-color="transparent">
        <v-expansion-panel-title v-slot="{ expanded }" hide-actions>
          <div class="d-flex align-center ga-2">
            <v-icon
              class="upload-span"
              style="padding: 0px; width: 32px"
              :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            ></v-icon>
            <div class="d-flex flex-column ga-1">
              <span>Custom Question</span>
              <span class="small-text"
                >Ask questions related to your business.</span
              >
            </div>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-devider></v-devider>
          <SingleQuestion
            v-for="(
              question, index
            ) in data?.lead_generation_form.form_fields.filter(
              (question) => question.type === 'CUSTOM'
            )"
            :key="question"
            :question="question"
            :index="index"
          />
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-devider></v-devider>
      <v-expansion-panel bg-color="transparent">
        <v-expansion-panel-title v-slot="{ expanded }" hide-actions>
          <div class="d-flex align-center ga-2">
            <v-icon
              class="upload-span"
              style="padding: 0px; width: 32px"
              :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            >
            </v-icon>
            <div class="d-flex flex-column ga-1">
              <span>Personal Information</span>
              <span class="small-text"
                >Ask for user information. This will be prefilled from their
                account.</span
              >
            </div>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="mx-4">
          <div
            v-for="field in data?.lead_generation_form.form_fields.filter(
              (question) => question.type !== 'CUSTOM'
            )"
            :key="field"
            class="d-flex justify-space-between align-center field-row-group"
          >
            <div class="d-flex ga-2 align-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="rgb(26, 153, 100)"
                xmlns="http://www.w3.org/2000/svg"
                class="sds-icon sds-icon-check sds-checkbox-readonly-icon styles-178t7jo"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                ></path>
              </svg>
              <span>{{ field.type }}</span>
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import SingleQuestion from "./SingleQuestion.vue"; 
import { useLeadFormStore } from "@/store/snapchat/LeadForm";

export default {
  name: "QuestionsForm",
  components: {
    SingleQuestion,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      items: [
        {
          title: "Multiple Choice",
          description: "Choose one answer from a list of options.",
          value: "MULTIPLE_CHOICE_SINGLE_SELECTION",
        },
        {
          title: "Multiple Selection",
          description: "Choose more than one answer from a list of options.",
          value: "MULTIPLE_CHOICE_MULTI_SELECTION",
        },
        {
          title: "Date Picker",
          description: "Choose a date to show availability.",
          value: "DATE",
        },
        {
          title: "Short Answer",
          description: "Give more info in a sentence or two.",
          value: "TEXT",
        },
      ],
      fields: [
        {
          title: "Phone Number",
          value: "PHONE_NUMBER",
        },
        {
          title: "Address",
          value: "ADDRESS",
        },
        {
          title: "Email",
          value: "EMAIL",
        },
        {
          title: "Postal Code",
          value: "POSTAL_CODE",
        },
        {
          title: "Birth Date",
          value: "BIRTH_DATE",
        },
        {
          title: "Job Title",
          value: "JOB_TITLE",
        },
        {
          title: "Company Name",
          value: "COMPANY_NAME",
        },
      ],
      store: useLeadFormStore(),
    };
  },
  methods: {
    selectQuestionType(item) {
      this.store.addQuestion(item);
    },
    addField(item) {
      this.store.fields.push(item);
    },
    RemoveField(index) {
      if (
        this.store.fields[index].value !== "first_name" &&
        this.store.fields[index].value !== "last_name"
      ) {
        this.store.fields.splice(index, 1);
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.small-text {
  font-size: 12px;
  color: rgb(132, 134, 136);
  font-weight: 400;
  white-space: nowrap;
}

.select-title {
  font-weight: 500;
}

.select-cont:hover .select-title {
  color: #1890ff;
}
</style>
