<template>
  <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
    <template v-slot:activator="{ props }">
      <button
        variant="text"
        class="w-100 text-start goal-select"
        v-bind="props"
      >
        {{ selectBidTitle }}
      </button>
    </template>
    <v-card min-width="300">
      <div
        v-for="option in filteredBids"
        :key="option.value"
        class="d-flex flex-column ga-3 pa-5"
      >
        <div class="d-flex align-center ga-2 option">
          <div class="option-check">
            <input
              @change="handleOptionSelect"
              name="tree-select"
              :id="'radio' + option.value"
              class="radio-button-snap"
              type="radio"
              :value="option.value"
              v-model="selectedBid"
            />
          </div>
          <label
            :for="'radio' + option.value"
            class="option-text d-flex flex-column ga-2"
          >
            <span class="title">{{ option.title }}</span>
            <span class="desc">{{ option.description }}</span>
          </label>
        </div>
      </div>
      <!-- <v-expansion-panels v-model="expandedPanel">
        <v-expansion-panel
          v-for="(goal, index) in filteredBids"
          :key="goal.title"
          :value="index"
        >
          <v-expansion-panel-title>
            {{ goal.title }}
          </v-expansion-panel-title>
          <v-expansion-panel-text> </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels> -->
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: "GoalsMenu",
  props: ["value"],
  data() {
    return {
      selectedBid: localStorage.getItem("bid_selected_view"),
      menu: false,
      bids: [
        {
          types: ["all"],
          title: "Auto-Bid",
          value: "AUTO_BID",
          description:
            "Makes best effort to spend your budget. Try this strategy if you want to learn what cost per action you can achieve on Snap.",
        },
        {
          types: ["all"],
          title: "Max Bid",
          value: "MAX_BID",
          description: "Bids conservatively at or below your desired bid.",
        },
        {
          types: ["Traffic", "Leads", "App Promotion", "Sales"],
          title: "Target Cost",
          value: "TARGET_COST",
          description:
            "Achieves the most volume while aiming to keep your average cost per action below your target cost.",
        },
      ],
      type: localStorage.getItem("selectedOption"),
    };
  },
  watch: {
    selectedBid(v) {
      this.menu = false;
      this.$emit("selectedBid", v);
    },
  },
  methods: {
    handleOptionSelect() {
      this.$nextTick(() => {
        this.expandedPanel = null;
      });
    },
  },
  computed: {
    filteredBids() {
      return this.bids.filter(
        (b) => b.types.includes(this.type) || b.types.includes("all")
      );
    },
    selectBidTitle() {
      const bid = this.bids.find((b) => b.value === this.selectedBid);
      return bid ? bid.title : null;
    },
  },
  mounted() {
    this.selectedBid = this.value;
  },
};
</script>

<style scoped>
.goal-select {
  align-items: center;
  background: #f9f9fa;
  border-color: transparent;
  border-radius: 8px;
  line-height: 20px;
  min-height: 40px;
  padding-left: 11px;
  padding-right: 11px;
}
.goal-select:hover {
  border: 1px solid #f7d901;
}

.option .option-text .title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 17px;
  grid-area: label;
  cursor: pointer;
  color: rgb(0, 0, 0);
}
.option .option-text .desc {
  color: rgb(84, 85, 87);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #000000;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

input[type="radio"]:checked {
  background-color: #000000;
}

input[type="radio"]:checked::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
