import { defineStore } from "pinia";
import metaGoalIcon from "@/assets/images/2_New1.png";
import Social from "@/assets/images/Socialissues,electionsorpolitics.svg";
import housing from "@/assets/images/housing.svg";
import employment from "@/assets/images/employment.svg";
import financial from "@/assets/images/Financialproductsorservices.svg";
import faceBookIcon from "@/assets/images/icons/facebook.svg";
import instagramIcon from "@/assets/images/icons/instagram.svg";
import messangerIcon from "@/assets/images/meta/messenger_5968771.svg";
import apiClient from "@/core/buildRequest";
export const boostPost = defineStore("boostPost", {
  state: () => ({
    manualPlacement: [
      {
        id: 1,
        title: "Facebook",
        icon: faceBookIcon,
        checked: true,
        disabled: true,
      },
      {
        id: 2,
        title: "Instagram",
        icon: instagramIcon,
        checked: false,
        disabled: false,
      },
      {
        id: 3,
        title: "Messenger",
        icon: messangerIcon,
        checked: false,
        disabled: false,
      },
    ],
    metaGoalDate: [
      {
        id: 1,
        title: "Automatic",
        description:
          "Let Facebook select the most relevant goal based on the button label and other settings that you choose.",
        icon: metaGoalIcon,
        goodFor: "Engagement",
        checked: false,
        value: "OUTCOME_AWARENESS",
      },
      {
        id: 2,
        title: "Get more messages",
        description:
          "Show your ad to people who are likely to send you a message on Facebook, WhatsApp",
        goodFor: "Sales",
        icon: metaGoalIcon,
        value: "OUTCOME_SALES",
        checked: false,
      },
      {
        id: 3,
        title: "Get more engagement",
        description:
          "Show your ad to people who are likely to react, comment and share.",
        goodFor: "Engagement",
        icon: metaGoalIcon,
        checked: false,
        value: "OUTCOME_ENGAGEMENT",
      },
      {
        id: 4,
        title: "Grow customer base",
        description:
          "Use a form to collect contact information from potential customers.",
        goodFor: "Sales",
        value: "OUTCOME_SALES",
        icon: metaGoalIcon,
        checked: false,
      },
      {
        id: 5,
        title: "Get more website visitors ",
        description:
          "Show your ad to people who are likely to click on URL in it. ",
        goodFor: "Traffic",
        icon: metaGoalIcon,
        checked: false,
        value: "OUTCOME_TRAFFIC",
      },
    ],
    selected_call_action_type: {
      id: 1,
      title: "No button",
      value: "no_button",
      checked: false,
    },
    select_call_action_type: [
      {
        id: 1,
        title: "No button",
        value: "no_button",
        checked: false,
      },
      {
        id: 2,
        title: "Book now",
        value: "book_now",
        checked: false,
      },
      {
        id: 3,
        title: "Learn more",
        value: "learn_more",
        checked: false,
      },
      {
        id: 4,
        title: "shop now",
        value: "shop_now",
        checked: false,
      },
      {
        id: 5,
        title: "Sign up",
        value: "sign_up",
        checked: false,
      },
      {
        id: 6,
        title: "Send message",
        value: "send_message",
        checked: false,
      },
      {
        id: 7,
        title: "Call now",
        value: "call_now",
        checked: false,
      },
    ],
    imageCropeContant: false,
    book_now_contant: false,
    learn_more_contant: false,
    buttonDestinationDataSelected: {
      id: 1,
      title: "website",
      description:
        "Direct people to a website where they can make a purchase, or learn more about your business",
    },
    buttonDestinationData: [
      {
        id: 1,
        title: "website",
        description:
          "Direct people to a website where they can make a purchase, or learn more about your business",
      },
      {
        id: 2,
        title: "Messanger",
        description:
          "Use automated chat to collect information from people interested in your business and follow up with them in Messenger",
      },
      {
        id: 3,
        title: "Contact form",
        description:
          "Use a form to collect customer info so you can contact them about your business.",
      },
    ],
    defaultMassage: [
      {
        id: 1,
        title: "Default welcome message template",
      },
    ],
    defaultMassageSelected: {
      id: 1,
      title: "Default welcome message template",
    },
    numberOfWhatsApp: [
      {
        id: 0,
        title: "+966 59 726 8500",
      },
    ],
    numberOfWhatsAppSelected: {
      id: 0,
      title: "+966 59 726 8500",
    },
    dialog: false,
    countryCodeNumber: [
      {
        id: 1,
        title: "SA+ 966",
      },
    ],
    countryCodeNumberSelected: {
      id: 1,
      title: "SA+ 966",
    },
    specialAdCategoryStatus: false,
    selectCategoryAds: [
      {
        id: 1,
        title: "Financial products and services (formerly Credit)",
        value: "FINANCIAL_PRODUCTS_SERVICES",
        descriptions: `Ads for credit cards, long-term financing, current and savings accounts,
          investment services, 
          insurance services or other related financial opportunities.`,
        checked: false,
        svgIcon: financial,
      },
      {
        id: 2,
        title: "Employment",
        value: "EMPLOYMENT",
        descriptions: `Ads for job offers, internships, professional certification programmes or other related opportunities.`,
        checked: false,
        svgIcon: employment,
      },
      {
        id: 3,
        title: "Housing",
        value: "HOUSING",
        descriptions: `Ads for property listings, home insurance, mortgages or other related opportunities.`,
        checked: false,
        svgIcon: housing,
      },
      {
        id: 4,
        title: "Social issues, elections or politics",
        value: "ISSUES_ELECTIONS_POLITICS",
        descriptions: `Ads for property listings, home insurance, mortgages or other related opportunities.`,
        checked: false,
        svgIcon: Social,
      },
    ],
    selectedItems: [],
    audienceType: [
      {
        id: 0,
        title: "Advantage+ audience",
        description:
          "Let our ad technology find your audience and adjust over time to reach more people who are likely to respond to your ad.",
        checked: false,
      },
      {
        id: 1,
        title: "People you choose through targeting",
        description:
          "Let our ad technology find your audience and adjust over time to reach more people who are likely to respond to your ad. Learn more.",
        checked: false,
      },
    ],
    advantageAudience: [
      {
        id: 0,
        loacation: "Saudi Arabia",
        age: "65+",
        status: "On",
      },
    ],
    throwTargeting: [
      {
        id: 0,
        loacation: "Egypt:Nasr City (+10 mi) Cairo  Governorate",
        age: "18-65+",
        optimiseLocation: "Off",
        advancedAudience: "Off",
        advantagedetailedTagrting: "On",
      },
    ],
    days: 0,
    endDate: null,
    placementRecomended: true,
    metaPixel: false,
    CreateAudience: false,
    audienceName: "",
    advantagrAudience: false,
    genderType: [
      {
        id: 0,
        title: "All",
        checked: false,
        value: [1, 2],
      },
      {
        id: 1,
        title: "Male",
        checked: false,
        value: [1],
      },
      {
        id: 2,
        title: "Female",
        checked: false,
        value: [2],
      },
    ],
    selectedGender: null,
    adAccountItems: [],
  }),
  actions: {
    selectItem(item) {
      this.selected_call_action_type = item.raw;
      localStorage.setItem("meta_call_action_type", item.raw.value);
      if (item.raw.value === "no_button") {
        this.book_now_contant = false;
      } else if (item.raw.value === "book_now") {
        this.book_now_contant = true;
      } else if (item.raw.value === "learn_more") {
        this.learn_more_contant = true;
        this.book_now_contant = true;
      } else if (item.raw.value === "shop_now") {
        this.book_now_contant = true;
        this.learn_more_contant = false;
      } else if (item.raw.value === "sign_up") {
        this.learn_more_contant = true;
        this.book_now_contant = true;
      } else if (item.raw.value === "call_now") {
        this.learn_more_contant = true;
      } else if (item.raw.value === "send_message") {
        this.learn_more_contant = false;
        this.book_now_contant = false;
        this.imageCropeContant = false;
      }
    },
    toggleImage() {
      this.imageCropeContant = !this.imageCropeContant;
    },
    buttonDestinationFun(item) {
      this.buttonDestinationDataSelected = item.raw;
    },
    selectWhatsAppNumber(item) {
      this.numberOfWhatsAppSelected = item.raw;
    },
    // selectedAdCategory(item) {
    //   this.selectedItems = item.raw;
    //   localStorage.setItem(
    //     "meta_special_ad_categories_boost_post",
    //     JSON.parse(item.raw.value)
    //   );
    // },
    selectedAdCategory(item) {
      this.selectedItems = item.raw;
      const selectedValue = Array.isArray(item.raw.value)
        ? item.raw.value
        : [item.raw.value];

      localStorage.setItem(
        "meta_special_ad_categories_boost_post",
        JSON.stringify(selectedValue) // Store as a valid JSON array
      );
    },
    formatDateFromInput(date) {
      if (!date) return "";
      const d = new Date(date);
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(d.getDate()).padStart(2, "0")}`;
    },
    setEndDate(time) {
      try {
        this.endDate = this.formatDateFromInput(time);
        localStorage.setItem("meta_end_time_boost_post", this.endDate);

        console.log("End date set successfully:", this.endDate);
      } catch (error) {
        console.error("Failed to set end date:", error);
      }
    },
    setSelectedGender(genderValue) {
      this.selectedGender = genderValue;
      localStorage.setItem(
        "boost_post_selected_gender",
        JSON.stringify(genderValue)
      );
    },
    getAdAcountId() {
      apiClient
        .get("/campaign-ads/facebook/ad-account")
        .then((response) => {
          console.log(response.data.data);
          this.adAccountItems = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setAdAccountStorage(item) {
      localStorage.setItem("meta_ad_account_id_boost_post", item.account_id);
    },
  },
});
