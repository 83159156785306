<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2000 2000"
    style="enable-background: new 0 0 2000 2000"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <circle style="fill: #1f0b39" cx="1000" cy="1000" r="909.589" />
          <g class="assets11" style="display: none">
            <g>
              <g id="planet" transform-origin="50% 50%">
                <g>
                  <g>
                    <g>
                      <path
                        style="fill: #e64728"
                        d="M1260.871,293.031c-4.247,36.96,22.273,70.364,59.232,74.612
										c36.961,4.246,70.365-22.273,74.612-59.233c4.247-36.96-22.273-70.363-59.233-74.611S1265.119,256.072,1260.871,293.031z"
                      />
                      <path
                        style="fill: #f3c2d9"
                        d="M1273.283,266.626c-0.25-1.426-0.557-2.836-0.903-4.223
										c13.693-19.764,37.546-31.539,63.103-28.603c36.96,4.247,63.48,37.65,59.233,74.611
										c-0.661,5.747-2.027,11.241-4.001,16.409c-5.185,0.637-9.873,0.654-12.351-0.705c-7.567-4.144-25.816-13.19-34.894-11.107
										c-9.078,2.083-14.551-2.542-21.363-9.801c-6.811-7.259-12.661-10.328-29.468-13.088
										C1275.834,287.36,1275.628,280.057,1273.283,266.626z"
                      />
                      <path
                        style="fill: #fadce7"
                        d="M1305.903,246.287c13.397-7.456,47.554-7.453,48.981,19.04
										c1.428,26.493-43.765,43.656-62.194,31.106C1274.261,283.882,1281.491,259.875,1305.903,246.287z"
                      />
                      <path
                        style="fill: #fdeff5"
                        d="M1309.097,252.079c5.393-2.183,10.197,3.327,7.985,7.328
										c-1.327,2.4-7.918,6.469-14.571,7.922C1291.786,269.669,1297.868,256.625,1309.097,252.079z"
                      />
                    </g>
                  </g>
                  <path
                    style="fill: #29b5c5"
                    d="M1276.953,293.204c-3.634-1.989-10.686-3.358-14.526-8.786
								c2.647-10.665,7.79-20.22,14.711-28.102c0.928,5.526,3.063,15.003,7.409,20.959c6.433,8.815,21.43-2.712,29.202,8.735
								c7.774,11.449,19.313,16.888,21.305,12.755c1.993-4.132,16.384-2.289,31.999,8.096c10.356,6.889,17.531,8.361,26.407,8.876
								c-2.133,9.377-6.18,17.928-11.677,25.276c-6.198-1.544-12.573-3.649-17.022-5.691c-10.681-4.901-20.624-10.894-23.152-5.904
								c-2.531,4.993-30.029-16.1-40.015-21.16c-9.982-5.061-1.598,4.08-9.164-0.065
								C1284.864,304.05,1284.518,297.349,1276.953,293.204z"
                  />
                  <path
                    style="fill: #e1e3e9"
                    d="M1292.691,296.434c-8.385-5.71-11.456-13.791-10.017-22.184
								c0.576,1.073,1.193,2.096,1.874,3.026c6.433,8.815,21.43-2.712,29.202,8.735c3.603,5.306,8.012,9.317,11.908,11.687
								C1313.619,301.728,1300.656,301.858,1292.691,296.434z"
                  />
                  <path
                    style="fill: #978cc3"
                    d="M1387.734,269.976c1.903,3.7,3.47,7.588,4.666,11.621
								c-14.213-2.332-30.522-7.664-38.635-16.825c-6.418-7.244-23.403-14.246-23.403-14.246s13.876,1.978,24.127,8.402
								C1361.312,263.208,1375.078,267.973,1387.734,269.976z"
                  />
                  <path
                    style="fill: #c4bbdd"
                    d="M1330.36,250.527c0,0,13.101,1.869,23.248,7.868c0.695,2.077,1.14,4.38,1.277,6.932
								c0.011,0.217,0.012,0.431,0.019,0.646c-0.395-0.394-0.778-0.793-1.14-1.201
								C1347.346,257.528,1330.36,250.527,1330.36,250.527z"
                  />
                  <path
                    style="fill: #978cc3"
                    d="M1280.873,349.054c-3.869-3.764-7.278-7.977-10.147-12.547
								c5.752,2.977,13.563,5.643,24.429,7.727c19.087,3.661,36.336,14.375,36.336,14.375s-23.827-4.856-36.724-7.058
								C1289.587,350.665,1285.244,350.165,1280.873,349.054z"
                  />
                  <path
                    style="fill: #978cc3"
                    d="M1336.514,311.72c12.68,6.853,31.982,9.479,55.847,8.206
								c-1.259,4.253-2.916,8.314-4.93,12.142c-20.589-0.474-46.814-9.478-55.523-15.677c-9.873-7.026-35.583-15.582-35.583-15.582
								S1317.067,301.212,1336.514,311.72z"
                  />
                  <path
                    style="fill: #978cc3"
                    d="M1380.752,304.37c0,0-8.742-5.878-22.659-5.782c-13.917,0.095-49.624-2.552-62.43-15.617
								c-6.953-7.091-17.897-12.354-26.45-15.684c2.088-3.572,4.479-6.961,7.156-10.123c9.762,2.92,15.656,8.302,22.999,17.215
								c9.302,11.286,32.482,16.477,52.358,18.959C1371.817,295.847,1380.752,304.37,1380.752,304.37z"
                  />
                  <path
                    style="fill: #c4bbdd"
                    d="M1282.795,273.588c0.733-3.677,2.329-7.405,4.715-11.009c4.117,2.959,7.761,6.829,11.857,11.8
								c7.339,8.904,23.313,14.012,39.49,16.991c-2.903,1.892-6.053,3.531-9.326,4.881c-13.275-2.183-26.933-6.201-33.869-13.28
								C1292.125,279.366,1287.557,276.235,1282.795,273.588z"
                  />
                  <path
                    style="fill: #f8fafd"
                    d="M1358.556,307.951c4.539,0.159,14.851,6.912,7.552,5.933
								C1358.808,312.905,1349.641,307.638,1358.556,307.951z"
                  />
                  <path
                    style="fill: #fadce7"
                    d="M1363.636,276.175c4.265,1.567,6.393,4.397-0.907,3.418
								C1355.431,278.616,1355.144,273.05,1363.636,276.175z"
                  />
                  <path
                    style="fill: #f7bc95"
                    d="M1294.438,335.512c5.583,3.938,7.572,8.882-2.649,4.614
								C1281.564,335.857,1283.318,327.659,1294.438,335.512z"
                  />
                  <path
                    style="fill: #f7bc95"
                    d="M1358.487,335.4c4.813,2.543,6.89,6.244-1.621,3.917
								C1348.353,336.991,1348.9,330.335,1358.487,335.4z"
                  />
                  <path
                    style="fill: #f7bc95"
                    d="M1326.419,360.678c4.813,2.542,6.89,6.243-1.621,3.917
								C1316.286,362.268,1316.832,355.614,1326.419,360.678z"
                  />
                  <path
                    style="fill: #f7bc95"
                    d="M1277.126,333.691c2.523,1.332,3.614,3.274-0.851,2.054
								C1271.81,334.525,1272.096,331.033,1277.126,333.691z"
                  />
                  <path
                    style="fill: #f7bc95"
                    d="M1301.234,312.774c2.524,1.333,3.614,3.275-0.85,2.055
								C1295.918,313.609,1296.204,310.118,1301.234,312.774z"
                  />
                  <path
                    style="fill: #f7bc95"
                    d="M1310.254,358.717c2.526,1.331,3.615,3.274-0.85,2.055
								C1304.938,359.552,1305.226,356.06,1310.254,358.717z"
                  />
                  <path
                    style="fill: #f7bc95"
                    d="M1269.448,319.391c2.526,1.332,3.613,3.275-0.849,2.054
								C1264.134,320.226,1264.421,316.733,1269.448,319.391z"
                  />
                  <path
                    style="fill: #fadce7"
                    d="M1388.208,308.819c3.153,1.16,4.728,3.254-0.669,2.53
								C1382.142,310.626,1381.929,306.509,1388.208,308.819z"
                  />
                  <path
                    style="fill: #fadce7"
                    d="M1285.163,255.375c3.152,1.159,4.727,3.252-0.671,2.529
								C1279.096,257.18,1278.885,253.064,1285.163,255.375z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M1329.551,277.269c7.152,2.63,10.725,7.378-1.521,5.736
								C1315.786,281.366,1315.303,272.03,1329.551,277.269z"
                  />
                  <path
                    style="fill: #f8fafd"
                    d="M1269.97,282.882c4.538,0.16,14.85,6.911,7.55,5.933
								C1270.219,287.836,1261.054,282.568,1269.97,282.882z"
                  />
                  <path
                    style="fill: #f8fafd"
                    d="M1334.361,321.629c2.822,0.494,8.675,5.601,4.202,4.359
								C1334.089,324.745,1328.821,320.66,1334.361,321.629z"
                  />
                  <path
                    style="fill: #f8fafd"
                    d="M1335.804,304.79c2.82,0.492,8.675,5.601,4.203,4.358
								C1335.533,307.905,1330.263,303.822,1335.804,304.79z"
                  />
                </g>
                <g>
                  <path
                    style="fill: #ffffff"
                    d="M1422.194,355.049c-2.697,5.015-11.475,6.942-24.713,5.421
								c-22.227-2.554-54.61-14.359-86.622-31.582c-26.207-14.097-49.302-30.179-65.035-45.281
								c-15.095-14.49-21.712-26.593-18.157-33.203c2.699-5.017,11.477-6.941,24.716-5.42c8.457,0.972,18.39,3.286,29.208,6.726
								c-0.643,0.606-1.279,1.221-1.898,1.85c-17.627-4.02-30.43-3.175-34.004,3.473c-6.905,12.836,23.19,42.444,67.222,66.13
								c44.031,23.686,85.323,32.482,92.227,19.646c3.191-5.931-1.521-15.443-11.854-26.295c0.282-1.181,0.531-2.377,0.752-3.583
								c3.591,2.994,6.938,5.975,10.002,8.915C1419.131,336.338,1425.75,348.439,1422.194,355.049z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M1209.377,244.509c6.862-12.755,37.491-10.556,76.672,3.348
								c-0.676,0.532-1.337,1.084-1.992,1.641c-11.627-3.785-22.307-6.324-31.347-7.363c-14.695-1.689-24.225,0.701-27.565,6.909
								c-4.274,7.944,2.366,20.952,18.694,36.629c15.914,15.278,39.233,31.521,65.66,45.738
								c32.317,17.382,65.083,29.311,87.653,31.904c14.695,1.688,24.225-0.701,27.566-6.911c4.273-7.944-2.368-20.951-18.695-36.627
								c-3.483-3.344-7.321-6.733-11.469-10.138c0.055-0.409,0.113-0.817,0.161-1.23c0.054-0.471,0.1-0.939,0.144-1.408
								c31.494,24.293,49.008,47.656,42.334,60.058c-9.865,18.341-68.861,5.774-131.771-28.068
								C1242.512,305.15,1199.511,262.848,1209.377,244.509z"
                  />
                </g>
              </g>
              <g>
                <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="-1485.7522"
                  y1="2130.7859"
                  x2="-1430.4642"
                  y2="2277.8733"
                  gradientTransform="matrix(0.9085 0.4179 -0.4179 0.9085 2510.8445 -592.7221)"
                >
                  <stop offset="0" style="stop-color: #2f3887" />
                  <stop offset="0.2715" style="stop-color: #745977" />
                  <stop offset="0.7634" style="stop-color: #f6ab20" />
                  <stop offset="1" style="stop-color: #fed600" />
                </linearGradient>
                <path
                  style="fill: url(#SVGID_1_)"
                  d="M286.713,844.196c-4.345-32.613-18.376-113.083-18.376-113.083l-0.226-0.013
						c0,0-23.185,78.323-31.249,110.222c-7.904,31.268,12.191,40.228,22.333,41.11c0.002,0.004,0.002,0.007,0.002,0.01
						c0.098,0.006,0.202,0.01,0.3,0.014c0.099,0.006,0.202,0.015,0.301,0.02c0.001-0.002,0.001-0.007,0.001-0.009
						C269.978,882.758,290.969,876.166,286.713,844.196z"
                />

                <linearGradient
                  id="SVGID_00000100348514637329972700000007699984827397514632_"
                  gradientUnits="userSpaceOnUse"
                  x1="-1517.9974"
                  y1="2250.7407"
                  x2="-1462.7087"
                  y2="2397.8301"
                  gradientTransform="matrix(0.9085 0.4179 -0.4179 0.9085 2510.8445 -592.7221)"
                >
                  <stop offset="0" style="stop-color: #2f3887" />
                  <stop offset="0.2715" style="stop-color: #745977" />
                  <stop offset="0.7634" style="stop-color: #f6ab20" />
                  <stop offset="1" style="stop-color: #fed600" />
                </linearGradient>
                <path
                  style="
                    fill: url(#SVGID_00000100348514637329972700000007699984827397514632_);
                  "
                  d="M207.287,939.703
						c-4.345-32.612-18.374-113.086-18.374-113.086l-0.225-0.013c0,0-23.185,78.327-31.25,110.225
						c-7.901,31.268,12.191,40.228,22.335,41.109c-0.002,0.003-0.001,0.007-0.001,0.01c0.098,0.004,0.202,0.01,0.3,0.012
						c0.103,0.009,0.203,0.016,0.302,0.022c0.001-0.003,0.002-0.006,0.003-0.01C190.555,978.263,211.546,971.671,207.287,939.703z"
                />
              </g>
              <g>
                <linearGradient
                  id="SVGID_00000011722609160033638500000012169729504211854721_"
                  gradientUnits="userSpaceOnUse"
                  x1="14859.2695"
                  y1="-6490.1611"
                  x2="14725.0762"
                  y2="-6657.5259"
                  gradientTransform="matrix(0.7853 0.619 -0.619 0.7853 -13951.0684 -2874.3027)"
                >
                  <stop offset="0.1141" style="stop-color: #fdc716" />
                  <stop offset="0.2291" style="stop-color: #ca9c4f" />
                  <stop offset="0.4218" style="stop-color: #86696e" />
                  <stop offset="0.58" style="stop-color: #5a4a79" />
                  <stop offset="0.6957" style="stop-color: #3f377d" />
                  <stop offset="0.7567" style="stop-color: #33307e" />
                </linearGradient>
                <path
                  style="
                    fill: url(#SVGID_00000011722609160033638500000012169729504211854721_);
                  "
                  d="M1753.992,1087.871
						c22.762,26.096,33.974,160.479,18.238,207.954c-7.562,22.816-16.22,43.376-43.744,39.35
						c-18.093-2.646-39.528-13.969-36.541-45.832c1.195-12.737,10.461-35.957,14.866-55.112
						c11.304-49.155,4.035-145.173,4.035-145.173s16.959,17.174,17.082,50.576c10.983-21.589,1.857-78.938,1.857-78.938
						s22.758,38.097,24.697,82.59C1759.866,1126.922,1763.139,1107.101,1753.992,1087.871z"
                />
                <path
                  style="fill: #ed7526"
                  d="M1761.673,1218.049c11.277,25.397,19.536,135.637-37.112,115.977
						c-14.18-4.921-28.091-8.162-28.806-24.074c-0.516-11.537,4.25-31.545,11.522-51.047c13.754-36.876,10.137-68.179,10.137-68.179
						s14.512,41.266,11.494,69.598c11.33-17.277,11.887-54.804,11.887-54.804s13.557,30.619,13.561,67.205
						C1759.702,1263.938,1767.63,1235.209,1761.673,1218.049z"
                />
                <path
                  style="fill: #e74b27"
                  d="M1718.953,1253.051c0,0-23.774,38.012-17.943,50.756c5.829,12.744,56.977,16.597,56.977,16.597
						s10.769-34.521,7.761-51.254c-3.553,12.811-17.176,15.145-17.176,15.145s0.971-35.225-4.735-50.515
						c-1.961,27.823-17.858,39.804-17.858,39.804L1718.953,1253.051z"
                />
                <g>
                  <path
                    style="fill: #76523c"
                    d="M1753.176,1343.777c3.286-4.462-1.739-9.57,1.868-13.362
							c3.604-3.788,10.228-12.009,6.619-22.536c-3.609-10.526-12.922-13.334-13.467-19.268c-0.549-5.932-7.761-10.315-15.417-9.835
							c-7.654,0.481-13.561-8.688-21.369-1.749c-7.81,6.936-4.113,14.136-11.276,21.132c-7.16,6.996-4.673,25.067,4.067,29.393
							c8.741,4.324,9.342,12.028,11.397,15.746c2.053,3.717,14.153,6.975,18.087,4.391
							C1737.614,1345.106,1748.709,1349.841,1753.176,1343.777z"
                  />
                  <path
                    style="fill: #88614b"
                    d="M1758.917,1308.597c-2.401-4.853-4.787-8.764-7.725,0.239
							c-2.937,9.002-8.908,21.339-2.751,21.211C1754.599,1329.918,1764.214,1319.308,1758.917,1308.597z"
                  />
                  <path
                    style="fill: #5c3c2d"
                    d="M1734.949,1313.701c-3.385-9.283-10.432-16.992-16.894-9.272
							c-6.465,7.721,6.264,12.925,10.81,15.298C1733.413,1322.099,1738.682,1323.938,1734.949,1313.701z"
                  />
                  <path
                    style="fill: #5c3c2d"
                    d="M1736.097,1341.681c0.706-4.034-10.971-8.656-16.62-5.741c-5.65,2.915,1.164,9.317,6.649,9.767
							C1731.612,1346.157,1735.316,1346.14,1736.097,1341.681z"
                  />
                  <path
                    style="fill: #5c3c2d"
                    d="M1751.653,1340.126c-0.825-3.352-7.1-1.222-10.995,1.298c-3.894,2.522,0.602,4.593,4.125,4.237
							C1748.307,1345.304,1752.674,1344.277,1751.653,1340.126z"
                  />
                  <path
                    style="fill: #5c3c2d"
                    d="M1743.037,1330.972c-1.435-1.604-23.979-16.221-25.418-9.114
							c-1.439,7.107,15.588,15.034,19.825,15.605C1741.684,1338.034,1750.109,1338.876,1743.037,1330.972z"
                  />
                  <path
                    style="fill: #88614b"
                    d="M1711.187,1316.781c-0.037-9.049-2.913-22.585-7.821-16.242
							c-4.908,6.341-0.592,23.586,3.561,24.203C1711.081,1325.358,1711.187,1316.781,1711.187,1316.781z"
                  />
                  <path
                    style="fill: #5c3c2d"
                    d="M1734.588,1280.447c4.693-1.295,9.653,6.193,4.443,7.891
							C1733.82,1290.035,1720.691,1284.282,1734.588,1280.447z"
                  />
                  <path
                    style="fill: #5c3c2d"
                    d="M1748.948,1294.703c5.016,4.558,2.802,17.698-3.781,12.715
							C1738.583,1302.436,1734.605,1281.663,1748.948,1294.703z"
                  />
                  <path
                    style="fill: #5c3c2d"
                    d="M1721.938,1280.545c-1.847-2.377-7.547-4.919-11.822,4.035
							c-4.275,8.954,3.769,22.345,8.603,16.478C1723.553,1295.192,1726.499,1286.405,1721.938,1280.545z"
                  />
                  <path
                    style="fill: #88614b"
                    d="M1734.941,1293.936c-1.859-2.847-6.757-6.036-7.965-1.711s1.275,6.673,5.653,11.99
							c4.38,5.319,7.83,16.343,9.206,11.469C1743.209,1310.812,1740.875,1303.019,1734.941,1293.936z"
                  />
                  <path
                    style="fill: #88614b"
                    d="M1716.662,1325.474c-2.115-4.4-3.84-3.298-4.892,0.175c-1.051,3.475,3.152,13.33,5.254,10.672
							C1719.125,1333.664,1719.357,1331.081,1716.662,1325.474z"
                  />
                  <path
                    style="fill: #88614b"
                    d="M1742.762,1325.147c-0.936-2.963-6.501-5.561-5.438-2.145
							C1738.385,1326.418,1744.752,1331.439,1742.762,1325.147z"
                  />
                  <path
                    style="fill: #88614b"
                    d="M1751.869,1335.288c-0.094-3.378-4.608-4.888-4.589-1.986
							C1747.302,1336.203,1751.979,1339.215,1751.869,1335.288z"
                  />
                </g>
              </g>
            </g>
            <g>
              <path
                style="opacity: 0.2; fill: #82bce6"
                d="M821.773,1788.954c-39.621,26.994-93.502,16.501-120.346-23.435
						c-26.844-39.935-16.487-94.192,23.133-121.186c39.621-26.994,93.502-16.503,120.346,23.433
						C871.751,1707.702,861.394,1761.96,821.773,1788.954z"
              />
              <g>
                <g>
                  <path
                    style="fill: #74b8e6"
                    d="M812.839,1775.663c-32.338,22.032-76.316,13.469-98.227-19.127
								c-15.062-22.407-15.774-50.344-4.238-72.773c5.242-10.194,13.014-19.255,23.12-26.141
								c32.339-22.032,76.318-13.469,98.228,19.126c12.485,18.574,15.111,40.944,9.077,60.83
								C836.243,1752.59,826.751,1766.184,812.839,1775.663z"
                  />
                  <path
                    style="fill: #3288c9"
                    d="M789.494,1785.989c3.335-5.43-24.699-19.529-40.315-25.226
								c-19.249-7.021-13.222-19.671,9.456-19.256c22.68,0.413-0.71-20.873-22.012-41.754c-21.301-20.88,26.937-25.674,40.29-25.621
								c13.357,0.056,3.284-7.725-9.698-13.528c-10.827-4.84,14.351-10.004,38.131-7.373c-22.215-11.43-49.751-10.666-71.853,4.392
								c-32.338,22.032-40.792,66.319-18.882,98.914C731.566,1781.759,761.732,1792.586,789.494,1785.989z"
                  />
                  <path
                    style="fill: #a2d7f4"
                    d="M834.47,1710.593c0.432-16.207-16.168-48.249-41.522-36.648
								c-25.354,11.599-19.371,62.374,1.265,73.533C814.849,1758.635,833.683,1740.13,834.47,1710.593z"
                  />
                  <path
                    style="fill: #d6ecfa"
                    d="M829.372,1702.136c-0.341-4.499-5.847-12.891-12.531-8.946
								c-6.687,3.938-3.59,17.822,2.437,20.316C825.305,1715.999,829.993,1710.335,829.372,1702.136z"
                  />
                  <path
                    style="fill: #cbe6f8"
                    d="M766.901,1645.67c10.958,8.112,22.424,13.055,31.057,14.736
								c11.842,2.305,23.309,10.36,23.309,10.36s-17.147-6.496-26.75-5.574c-13.301,1.278-30.658-7.554-42.937-16.429
								C756.603,1647.146,761.744,1646.122,766.901,1645.67z M800.537,1782.373c-8,0.37-18.724-2.296-35.014-12.606
								c-16.413-10.391-36.276-14.34-36.276-14.34s20.684,12.759,31.588,19.974c7.696,5.092,12.687,9.291,22.096,11.819
								C788.904,1786.393,794.822,1784.79,800.537,1782.373z M702.437,1716.18c15.218,14.169,41.377,26.007,52.18,27.455
								c11.976,1.603,36.544,12.934,36.544,12.934s-15.408-13.912-36.733-19.518c-14.249-3.747-33.116-18.787-50.106-36.801
								C703.106,1705.461,702.471,1710.809,702.437,1716.18z M735.6,1683.166c0,0,10.372,1.675,20.46,11.278
								c10.087,9.604,37.939,32.124,56.158,31.307c9.889-0.446,21.447,3.186,29.949,6.602c0.904-4.051,1.462-8.179,1.657-12.332
								c-9.108-4.544-17.063-4.631-28.474-3.121c-14.454,1.916-34.891-10.152-51.078-21.949
								C747.91,1683.029,735.6,1683.166,735.6,1683.166z"
                  />
                  <path
                    style="fill: #a2d7f4"
                    d="M789.495,1785.988c-0.073,0.019-0.146,0.032-0.219,0.05c-0.726,0.169-1.456,0.328-2.185,0.475
								c-0.256,0.052-0.512,0.095-0.769,0.144c-0.563,0.105-1.125,0.209-1.689,0.301c-0.295,0.049-0.592,0.092-0.887,0.137
								c-0.282,0.041-0.564,0.077-0.843,0.117c-9.391-2.53-14.381-6.726-22.067-11.811c-10.904-7.215-31.588-19.974-31.588-19.974
								s19.863,3.949,36.276,14.34c8.228,5.207,15.034,8.461,20.791,10.363C789.045,1782.482,790.381,1784.542,789.495,1785.988z
								 M765.112,1657.195c1.553-1.668,6.766-3.116,13.783-3.944c-3.959-2.107-8.009-4.631-11.993-7.581
								c-0.502,0.044-1.003,0.098-1.504,0.152c-0.146,0.016-0.293,0.029-0.44,0.045c-0.416,0.048-0.831,0.107-1.248,0.163
								c-0.289,0.038-0.578,0.075-0.868,0.118c-0.41,0.06-0.821,0.127-1.232,0.193c-0.29,0.051-0.582,0.099-0.871,0.151
								c-0.413,0.074-0.827,0.15-1.24,0.229c-0.28,0.057-0.562,0.115-0.843,0.174c-0.421,0.089-0.842,0.177-1.261,0.274
								c-0.245,0.055-0.486,0.117-0.73,0.175c-1.037,0.249-2.07,0.518-3.1,0.816c-0.033,0.009-0.067,0.018-0.099,0.03
								c-0.444,0.126-0.884,0.266-1.326,0.403c-0.184,0.06-0.371,0.117-0.556,0.175
								C755.647,1651.704,760.266,1654.632,765.112,1657.195z M758.635,1741.507c2.86,0.052,4.984-0.244,6.475-0.836
								c-3.341-1.384-6.919-2.631-10.681-3.621c-14.249-3.747-33.116-18.787-50.106-36.801c0,0.003-0.001,0.005-0.002,0.007
								c-0.146,0.629-0.282,1.26-0.414,1.892c-0.009,0.044-0.019,0.087-0.027,0.131c-0.125,0.616-0.242,1.232-0.35,1.851
								c-0.006,0.027-0.009,0.055-0.015,0.085c-0.461,2.623-0.775,5.276-0.94,7.94c0.001,0.003,0,0.003,0.001,0.004
								c-0.044,0.654-0.074,1.31-0.097,1.965c0,0.03-0.001,0.055-0.002,0.081c-0.023,0.658-0.036,1.316-0.04,1.975
								c12.923,12.034,33.734,22.38,46.332,26.114C751.576,1741.731,754.875,1741.437,758.635,1741.507z"
                  />
                  <path
                    style="fill: #e1f2fc"
                    d="M776.333,1710.825c11.662,8.102,25.375,15.397,35.885,14.925
								c6.126-0.277,12.894,1.016,19.178,2.848c1.24-3.635,2.14-7.631,2.64-11.917c-5.624-1.049-11.462-0.737-18.686,0.218
								c-11.025,1.462-25.532-5.217-38.979-13.703C776.197,1705.712,776.185,1708.27,776.333,1710.825z"
                  />
                  <path
                    style="fill: #cbe6f8"
                    d="M841.775,1699.33c-6.369-6.005-18.082-16.041-32.295-23.585
								c8.328,0.217,19.98,5.753,27.229,9.67C838.904,1689.928,840.591,1694.591,841.775,1699.33z M729.251,1660.749
								c-4.692,3.732-8.799,7.962-12.297,12.569c18.841-1.536,39.232,6.538,55.903,16.394c21.1,12.476,39.798,1.565,39.798,1.565
								s-30.06-1.489-45.184-14.983C755.195,1665.343,753.977,1662.535,729.251,1660.749z M731.564,1767.882
								c7.841,5.857,13.589,11.482,17.395,15.67c4.88,1.79,9.937,3.036,15.075,3.717
								C755.104,1781.201,739.463,1770.927,731.564,1767.882z M839.311,1741.957c-15.644,0.12-24.512,0.197-40.932-6.171
								c-18.834-7.305-23.668-2.79-23.668-2.79s16.02,3.119,28.895,13.266c9.997,7.879,16.196,12.821,24.401,15.453
								C832.866,1755.718,836.655,1749.033,839.311,1741.957z M759.814,1731.551c-27.859-15.076-33.038-33.346-50.932-44.713
								c-1.195,2.619-2.224,5.309-3.091,8.045C722.198,1707.699,745.138,1725.773,759.814,1731.551z"
                  />
                  <path
                    style="fill: #e1f2fc"
                    d="M827.157,1687.05c-5.188-3.875-11.169-7.85-17.677-11.304
								c2.953,0.077,6.323,0.824,9.764,1.927C822.305,1680.282,824.96,1683.525,827.157,1687.05z M778.258,1692.438
								c18.789,7.943,34.398-1.161,34.398-1.161s-16.404-0.814-31.073-6.678C780.198,1686.999,779.092,1689.637,778.258,1692.438z
								 M824.186,1741.758c-8.002-0.486-15.383-1.929-25.807-5.971c-8.073-3.132-13.567-4.088-17.218-4.161
								c0.562,1.286,1.169,2.531,1.819,3.721c5.772,1.994,13.657,5.425,20.625,10.916c1.967,1.55,3.785,2.984,5.504,4.313
								C814.84,1749.802,820.02,1746.684,824.186,1741.758z"
                  />
                  <g>
                    <path
                      style="fill: #2b56a0"
                      d="M731.564,1767.882c7.832,5.851,13.577,11.47,17.383,15.656
									c0.315,0.115,0.631,0.234,0.948,0.344c0.262,0.092,0.523,0.186,0.785,0.275c0.699,0.235,1.398,0.461,2.103,0.676
									c0.256,0.077,0.511,0.146,0.768,0.221c0.501,0.146,1.007,0.289,1.512,0.425c0.289,0.078,0.579,0.154,0.867,0.227
									c0.539,0.136,1.083,0.267,1.623,0.39c0.222,0.052,0.44,0.106,0.661,0.154c0.73,0.16,1.463,0.306,2.199,0.443
									c0.253,0.047,0.51,0.089,0.765,0.133c0.531,0.094,1.063,0.182,1.593,0.26c0.277,0.041,0.552,0.081,0.829,0.123
									c0.139,0.019,0.278,0.032,0.416,0.052C755.084,1781.187,739.459,1770.925,731.564,1767.882z"
                    />
                    <path
                      style="fill: #a2d7f4"
                      d="M747.694,1677.898c5.851-1.588,12.387-2.58,18.061-3.143
									c-10.732-9.681-12.982-12.305-36.503-14.006c-0.581,0.464-1.154,0.934-1.716,1.411c-0.024,0.02-0.049,0.04-0.072,0.062
									c-0.506,0.43-1.006,0.869-1.498,1.313c-0.1,0.087-0.197,0.176-0.296,0.266c-0.432,0.393-0.858,0.791-1.281,1.193
									c-0.09,0.088-0.184,0.176-0.274,0.264c-1.521,1.472-2.968,2.999-4.336,4.58c-0.072,0.084-0.146,0.17-0.217,0.255
									c-0.386,0.452-0.766,0.903-1.138,1.362c-0.071,0.088-0.142,0.177-0.214,0.262c-0.427,0.528-0.845,1.06-1.251,1.598
									c-0.002,0.001-0.002,0.001-0.004,0.003C727.027,1672.496,737.542,1674.421,747.694,1677.898z"
                    />
                    <path
                      style="fill: #a2d7f4"
                      d="M759.814,1731.551c-27.859-15.076-33.038-33.346-50.932-44.713
									c-0.274,0.605-0.539,1.213-0.797,1.826c-0.072,0.168-0.141,0.338-0.209,0.509c-0.186,0.449-0.367,0.9-0.544,1.352
									c-0.074,0.195-0.149,0.387-0.221,0.581c-0.175,0.462-0.346,0.925-0.512,1.392c-0.057,0.161-0.116,0.322-0.171,0.486
									c-0.219,0.628-0.431,1.261-0.634,1.893c-0.001,0.005-0.001,0.005-0.002,0.007
									C722.198,1707.699,745.138,1725.773,759.814,1731.551z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        style="fill: #2b56a0"
                        d="M819.952,1770.126c-7.742,0.436-18.952,1.829-39.407-4.626
										c12.237,9,25.495,10.801,30.794,11.156c0.503-0.323,1.003-0.655,1.5-0.993
										C815.358,1773.947,817.728,1772.097,819.952,1770.126z"
                      />
                      <path
                        style="fill: #2b56a0"
                        d="M714.414,1756.23c7.033,3.332,15.337,6.708,19.537,6.379
										c-6.745-2.249-19.897-13.728-26.547-19.796C709.227,1747.433,711.555,1751.93,714.414,1756.23z"
                      />
                    </g>
                  </g>
                  <path
                    style="fill: #ffffff"
                    d="M759.498,1757.04c-2.976-2.075-6.613-0.378-3.396,1.703
								C759.317,1760.825,763.483,1759.817,759.498,1757.04z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M790.327,1774.684c-2.977-2.076-6.612-0.379-3.398,1.702
								C790.146,1778.468,794.311,1777.458,790.327,1774.684z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M740.69,1691.226c-2.008-1.399-4.461-0.254-2.289,1.15
								C740.568,1693.781,743.381,1693.1,740.69,1691.226z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M780.139,1739.407c-2.008-1.399-4.46-0.257-2.289,1.149
								C780.018,1741.959,782.828,1741.28,780.139,1739.407z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M748.509,1697.692c-1.305-0.909-2.898-0.168-1.49,0.747
								C748.429,1699.35,750.254,1698.909,748.509,1697.692z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M747.71,1692.111c-1.304-0.909-2.896-0.165-1.488,0.748
								C747.631,1693.769,749.454,1693.327,747.71,1692.111z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M748.342,1770.806c-1.304-0.909-2.896-0.164-1.487,0.747
								C748.263,1772.464,750.087,1772.022,748.342,1770.806z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M810.183,1755.929c-1.303-0.908-2.898-0.166-1.488,0.747
								C810.105,1757.589,811.929,1757.146,810.183,1755.929z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M805.084,1756.149c-1.304-0.909-2.897-0.165-1.488,0.747
								C805.004,1757.807,806.831,1757.366,805.084,1756.149z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M809.057,1698.115c-1.303-0.908-2.896-0.166-1.488,0.747
								C808.98,1699.773,810.805,1699.331,809.057,1698.115z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M809.228,1712.068c-2.922-1.373-5.858,0.666-2.729,2.008
								C809.634,1715.418,813.139,1713.906,809.228,1712.068z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M822.062,1729.36c-2.919-1.371-5.858,0.666-2.729,2.008
								C822.467,1732.71,825.974,1731.198,822.062,1729.36z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M783.561,1698.478c-2.201-1.033-4.416,0.504-2.056,1.516
								C783.868,1701.005,786.511,1699.863,783.561,1698.478z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M832.194,1711.741c-1.451-0.68-2.911,0.331-1.357,0.999
								C832.396,1713.406,834.138,1712.655,832.194,1711.741z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M812.988,1735.406c-1.451-0.682-2.913,0.332-1.357,0.998
								C813.189,1737.072,814.931,1736.318,812.988,1735.406z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M814.933,1728.993c-1.451-0.681-2.91,0.334-1.356,0.999
								C815.136,1730.66,816.878,1729.906,814.933,1728.993z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M813.306,1684.58c-1.45-0.682-2.912,0.33-1.356,0.999
								C813.507,1686.244,815.251,1685.493,813.306,1684.58z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M770.804,1712.316c-1.451-0.682-2.911,0.33-1.356,0.998
								C771.006,1713.981,772.749,1713.229,770.804,1712.316z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M783.382,1770.057c-1.45-0.682-2.913,0.33-1.356,1
								C783.583,1771.721,785.326,1770.969,783.382,1770.057z"
                  />
                </g>
                <path
                  style="fill: #ffffff"
                  d="M884.593,1759.344c2.384-1.694,4.211-3.795,5.279-6.073c0.284-0.598,0.552-1.319,0.713-1.909
							l0.069-0.229c0.016-0.056,0.039-0.146,0.054-0.218h0.002l0.164-0.887c0.043-0.225,0.086-0.446,0.12-0.722
							c0.124-1.549,0.073-2.801-0.158-3.913c-0.396-2.219-1.275-4.432-2.693-6.772c-1.197-1.947-2.565-3.743-4.028-5.304
							c-0.317-0.367-0.664-0.735-1.119-1.187l-1.086-1.098c-0.013-0.013-0.025-0.025-0.036-0.034
							c-0.262-0.24-0.518-0.481-0.774-0.721c-0.476-0.448-0.969-0.911-1.466-1.33c-1.487-1.301-3.011-2.538-4.516-3.665
							c-1.129-0.87-2.273-1.668-3.381-2.441c-0.348-0.242-0.691-0.48-1.056-0.738l-2.201-1.444
							c-0.665-0.435-1.353-0.887-2.027-1.288l-0.874-0.537c-1.072-0.656-2.085-1.277-3.097-1.846l-0.378-0.219
							c-1.147-0.662-2.231-1.29-3.285-1.849c-2.067-1.152-3.974-2.125-5.656-2.98l-1.127-0.565c-1.654-0.807-3.075-1.5-4.261-2.061
							l-3.951-1.824c-0.172-0.08-0.354-0.102-0.528-0.083c0.095,0.733,0.179,1.466,0.252,2.201l3.297,1.647
							c1.155,0.592,2.547,1.326,4.168,2.182l0.433,0.227c0.458,0.254,0.934,0.515,1.427,0.785c1.419,0.781,3.026,1.662,4.736,2.665
							c1.007,0.565,2.04,1.195,3.134,1.861l0.436,0.265c0.963,0.57,1.933,1.198,2.962,1.863l0.891,0.573
							c0.644,0.405,1.276,0.841,1.991,1.334l2.057,1.418c0.347,0.257,0.699,0.513,1.052,0.773c1.042,0.762,2.121,1.552,3.194,2.419
							c1.441,1.135,2.836,2.322,4.281,3.643c0.448,0.397,0.879,0.822,1.335,1.27c0.25,0.245,0.5,0.493,0.755,0.735l1.025,1.086
							c0.404,0.419,0.703,0.752,0.994,1.104c1.36,1.531,2.579,3.195,3.584,4.885c1.167,1.987,1.877,3.826,2.177,5.658
							c0.157,0.805,0.181,1.707,0.074,2.92c-0.021,0.138-0.05,0.277-0.079,0.415c-0.023,0.106-0.044,0.212-0.062,0.307l-0.092,0.466
							c0.006-0.037,0.014-0.052,0.026-0.08c-0.004,0.014-0.023,0.071-0.028,0.083l-0.014,0.078c0.004-0.033,0.009-0.055,0.013-0.074
							l-0.07,0.222c-0.146,0.502-0.299,0.889-0.538,1.389c-0.835,1.716-2.284,3.318-4.199,4.635
							c-1.69,1.184-3.728,2.204-6.223,3.113c-2.1,0.76-4.436,1.405-7.137,1.968c-4.578,0.928-9.668,1.498-15.559,1.746
							c-1.377,0.057-2.741,0.096-4.055,0.11l-1.003,0.016l-7.276-0.098c-0.251-0.005-0.505-0.008-0.756-0.01
							c-0.441-0.005-0.882-0.007-1.318-0.032l-7.268-0.42c-1.075-0.061-2.185-0.121-3.287-0.246l-8.582-0.832l-8.705-1.146
							c-1.106-0.13-2.237-0.315-3.329-0.493l-5.372-0.869c-0.522-0.09-1.043-0.17-1.567-0.252c-0.922-0.147-1.875-0.295-2.807-0.484
							l-5.956-1.147c-2.368-0.453-4.818-0.924-7.213-1.484c-1.036-0.237-2.074-0.465-3.111-0.693
							c-1.865-0.413-3.792-0.838-5.666-1.285l-8.763-2.205c-0.285-0.074-0.573-0.145-0.86-0.215c-0.44-0.11-0.88-0.217-1.31-0.337
							l-6.546-1.788l-4.332-1.262c-1.29-0.372-2.806-0.813-4.298-1.287l-4.295-1.331c-0.922-0.291-1.842-0.596-2.758-0.899
							c-0.502-0.167-1-0.331-1.505-0.497c-2.117-0.677-4.247-1.425-6.306-2.146c-0.71-0.248-1.419-0.498-2.114-0.739
							c-4.903-1.779-10.687-3.917-16.438-6.316c-1.959-0.786-3.914-1.64-5.805-2.468c-0.716-0.314-1.429-0.625-2.122-0.923
							l-5.248-2.421l-2.474-1.197c-4.715-2.264-9.485-4.823-14.591-7.82c-2.237-1.293-4.414-2.708-6.517-4.076l-3.485-2.428
							c-0.31-0.209-0.626-0.451-0.964-0.708c-0.191-0.147-0.38-0.293-0.555-0.422l-0.441-0.332c-0.38-0.286-0.736-0.554-1.094-0.852
							c-1.891-1.483-3.777-3.146-5.604-4.952c-1.806-1.782-3.349-3.615-4.585-5.442l-0.028-0.042
							c-1.378-2.051-2.308-4.038-2.763-5.893c-0.471-2.047-0.367-4.074,0.305-5.86c0.632-1.662,1.823-3.245,3.456-4.584
							c1.632-1.384,3.555-2.382,5.03-3.089c4.014-1.836,8.118-2.795,10.848-3.355c3.163-0.629,6.455-1.096,10.065-1.421
							c2.775-0.253,5.61-0.42,8.644-0.508l7.596-0.065c0.312-0.003,0.616-0.006,0.902-0.001l7.527,0.227
							c0.402-0.587,0.812-1.171,1.234-1.748c-0.163-0.143-0.368-0.24-0.6-0.253l-8.082-0.502c-0.313-0.015-0.635-0.022-0.961-0.028
							l-2.08-0.046c-1.652-0.04-3.523-0.082-5.563-0.096c-3.108,0.017-5.997,0.121-8.832,0.311
							c-3.714,0.249-7.112,0.648-10.397,1.222c-3.141,0.569-7.301,1.458-11.622,3.313c-1.014,0.46-2.003,0.922-2.949,1.49
							c-1.078,0.62-2.018,1.264-2.867,1.96c-2.125,1.709-3.653,3.708-4.543,5.949l-0.053,0.132
							c-0.047,0.114-0.091,0.229-0.139,0.369l-0.266,0.856c-0.009,0.032-0.02,0.062-0.024,0.096l-0.281,1.411
							c-0.033,0.23-0.05,0.461-0.064,0.693c-0.007,0.099-0.013,0.196-0.02,0.292c-0.128,1.414,0.083,2.813,0.311,4.033
							c0.538,2.338,1.588,4.682,3.212,7.166l0.203,0.306c1.302,1.938,2.9,3.876,4.753,5.761c1.935,1.962,3.855,3.709,5.85,5.318
							c0.386,0.329,0.774,0.63,1.151,0.922l0.437,0.341c0.182,0.139,0.359,0.277,0.537,0.418c0.337,0.264,0.685,0.539,1.066,0.806
							l3.287,2.37l0.207,0.139c2.156,1.449,4.384,2.942,6.686,4.318c4.577,2.8,9.566,5.532,14.819,8.117l1.938,0.969l5.886,2.766
							c0.704,0.311,1.413,0.627,2.121,0.943c1.922,0.857,3.91,1.745,5.896,2.557c4.727,2.022,9.848,4.021,16.615,6.479
							c0.703,0.247,1.406,0.493,2.109,0.743c2.094,0.739,4.257,1.502,6.41,2.199c0.507,0.167,1.014,0.335,1.521,0.505
							c0.927,0.308,1.849,0.616,2.784,0.915l4.317,1.351c1.514,0.483,3.061,0.939,4.37,1.321l4.381,1.286l6.555,1.806
							c0.527,0.15,1.039,0.276,1.555,0.403l9.523,2.374c1.92,0.451,3.874,0.879,5.762,1.291c1.038,0.227,2.076,0.453,3.103,0.681
							c2.448,0.569,4.943,1.041,7.352,1.496l5.964,1.132c0.97,0.195,1.955,0.348,2.905,0.494c0.518,0.082,1.036,0.16,1.561,0.249
							l4.417,0.703c0.37,0.059,0.737,0.116,1.107,0.177c1.089,0.176,2.216,0.357,3.327,0.478l8.799,1.091l8.678,0.777
							c1.152,0.122,2.315,0.179,3.439,0.233l7.363,0.367c0.475,0.025,0.945,0.025,1.416,0.027c0.244,0.001,0.488,0,0.74,0.003
							l7.373,0.045l1.039-0.029c1.345-0.035,2.747-0.095,4.167-0.175c6.095-0.343,11.378-1.013,16.154-2.052
							c2.874-0.644,5.371-1.371,7.632-2.224C880.365,1761.931,882.662,1760.736,884.593,1759.344z"
                />
              </g>
            </g>
            <g>
              <defs>
                <circle
                  id="SVGID_00000031201324429355823190000008864424503108926891_"
                  cx="1000"
                  cy="1000"
                  r="909.589"
                />
              </defs>
              <clipPath
                id="SVGID_00000057134746010976415170000007964834293088945543_"
              >
                <use
                  xlink:href="#SVGID_00000031201324429355823190000008864424503108926891_"
                  style="overflow: visible"
                />
              </clipPath>
              <g
                style="
                  clip-path: url(#SVGID_00000057134746010976415170000007964834293088945543_);
                "
              >
                <path
                  style="opacity: 0.2; fill: #cae6eb"
                  d="M1610.642,1536.565c94.13,1.377,191.364,19.776,288.445,54.087l2.685,5.818
							l-997.738,460.516l-2.716-5.884c62.889-163.559,161.244-296.687,288.109-386.547
							C1312.714,1577.34,1454.433,1534.281,1610.642,1536.565z"
                />
                <path
                  style="fill: #069eae"
                  d="M1841.827,1624.138l-877.828,405.17c49.419-137.659,143.446-260.463,276.766-344.507
							C1427.642,1567.108,1649.921,1551.876,1841.827,1624.138z"
                />
                <path
                  style="fill: #29b5c5"
                  d="M1417.728,1620.035c81.39-27.316,325.679-59.003,340.97,42.473l-219.882,101.489
							c-99.338,14.242-221.797,38.497-344.796,111.362c-80.571,47.733-131.406,93.939-156.964,120.23l-15.034,6.939
							C1043.573,1941.284,1140.752,1712.992,1417.728,1620.035z"
                />
                <path
                  style="fill: #151729"
                  d="M1637.456,1661.331c-0.621,6.412,10.941,12.778,25.829,14.217
							c14.889,1.441,27.461-2.587,28.081-9c0.62-6.414-10.943-12.778-25.832-14.222
							C1650.649,1650.885,1638.076,1654.916,1637.456,1661.331z"
                />
                <path
                  style="fill: #aedbe3"
                  d="M1617.528,1693.706c18.174,1.76,33.936,7.093,43.469,13.896l-66.441,30.667
							c-21.182-5.357-35.728-15.68-34.72-26.063C1561.113,1699.027,1586.94,1690.746,1617.528,1693.706z"
                />
                <path
                  style="fill: #151729"
                  d="M1589.68,1620.633c-0.264,3.599,6.293,7.013,14.645,7.627
							c8.353,0.613,15.341-1.804,15.604-5.403c0.264-3.597-6.291-7.012-14.645-7.628
							C1596.931,1614.615,1589.945,1617.034,1589.68,1620.633z"
                />
                <g>
                  <path
                    style="fill: #069eae"
                    d="M1368.066,1625.849c-23.251,7.073-41.696,16.658-54.439,2.037
								c-12.744-14.619-31.939-37.239-52.247-28.746c-20.311,8.493-29.623,25.184-49.439,35.015
								c-19.815,9.832-43.276,30.858-35.232,43.904c8.045,13.045,14.597,28.344,2.343,43.616
								c-12.255,15.272-23.429,24.618-28.869,32.179c44.17,12.221,68.803,18.602,143.716-28.604
								C1368.811,1678.042,1368.066,1625.849,1368.066,1625.849z"
                  />
                  <path
                    style="fill: #aedbe3"
                    d="M1183.247,1690.782c-1.692-4.412-4.042-8.676-6.538-12.722
								c-8.044-13.046,15.417-34.072,35.232-43.904c19.816-9.832,29.128-26.523,49.439-35.015
								c14.087-5.892,27.639,3.191,38.873,14.187c11.152,11.654-21.632,42.054-46.512,57.731
								C1228.216,1687.142,1192.459,1704.092,1183.247,1690.782z"
                  />
                  <path
                    style="fill: #151729"
                    d="M1256.484,1609.159c7.629-7.36,25.255-3.491,12.308,13.458
								c-12.948,16.949-51.884,47.215-72.899,49.879c-21.014,2.665-8.505-11.707,7.25-22.824
								C1218.898,1638.557,1231.987,1632.789,1256.484,1609.159z"
                  />
                </g>
                <g>
                  <path
                    style="fill: #069eae"
                    d="M1778.153,1625.303c-14.643-7.235-34.489-20.713-34.002-33.741
								c0.487-13.028,1.498-32.94-12.359-38.129c-13.856-5.188-26.101-1.299-40.33-5.603c-14.231-4.306-35.401-4.805-37.464,5.288
								c-2.065,10.091-5.896,20.602-18.929,22.442c-13.032,1.837-20.527,5.222-26.671,6.422
								c16.007,26.313,27.46,49.784,85.882,61.142C1752.7,1654.481,1778.153,1625.303,1778.153,1625.303z"
                  />
                  <path
                    style="fill: #aedbe3"
                    d="M1651.343,1562.357c1.204-2.94,2.015-6.11,2.655-9.24c2.064-10.093,23.233-9.594,37.464-5.288
								c14.23,4.304,26.474,0.415,40.33,5.603c9.613,3.6,12.069,14.286,12.51,24.841c0.099,10.84-36.219,14.202-55.621,10.429
								C1668.778,1584.833,1649.728,1573.117,1651.343,1562.357z"
                  />
                  <path
                    style="fill: #151729"
                    d="M1724.782,1556.085c7.126-0.092,13.978,9.915-0.164,12.258
								c-14.144,2.34-47.144-0.755-58.664-9.122c-11.52-8.366,1.223-9.649,14.055-7.841
								C1692.842,1553.186,1701.909,1556.385,1724.782,1556.085z"
                  />
                </g>
                <g>
                  <path
                    style="fill: #069eae"
                    d="M1561.778,1613.066c1.946,17.305,10.015,26.85,23.836,30.946
								c13.821,4.1,4.604,32.667-73.852,50.569c-72.576,16.56-120.495-3.559-110.995-16.922
								c15.177-21.357,4.968-10.066,18.355-43.688C1478.463,1622.861,1561.778,1613.066,1561.778,1613.066z"
                  />
                  <path
                    style="fill: #aedbe3"
                    d="M1419.225,1641.762c3.414,16.951,38.089,24.278,77.459,16.348
								c39.363-7.92,68.513-28.088,65.094-45.044c-3.411-16.954-38.088-24.274-77.455-16.354
								C1444.958,1604.635,1415.81,1624.805,1419.225,1641.762z"
                  />
                  <path
                    style="fill: #151729"
                    d="M1438.409,1632.838c1.906,9.472,26.335,12.547,54.568,6.862
								c28.229-5.68,49.573-17.966,47.66-27.439c-1.904-9.473-26.335-12.543-54.566-6.864
								C1457.84,1611.077,1436.5,1623.364,1438.409,1632.838z"
                  />
                </g>
                <g>
                  <path
                    style="fill: #069eae"
                    d="M1155.7,1792.867c12.826,13.656,17.042,18.911,31.589,13.538
								c14.549-5.374,39.569,28.688-16.488,94.657c-51.855,61.022-116.456,54.889-116.882,37.258
								c-0.683-28.179-2.686-23.142-7.651-35.535C1090.233,1854.981,1155.7,1792.867,1155.7,1792.867z"
                  />
                  <path
                    style="fill: #aedbe3"
                    d="M1048.893,1907.768c17.745,15.878,55.486,2.667,84.294-29.53
								c28.808-32.182,37.783-71.156,20.029-87.033c-17.745-15.884-55.481-2.664-84.296,29.52
								C1040.111,1852.911,1031.143,1891.88,1048.893,1907.768z"
                  />
                  <path
                    style="fill: #151729"
                    d="M1058.557,1886.723c9.915,8.872,34.698-2.641,55.359-25.73
								c20.66-23.081,29.375-48.987,19.453-57.86c-9.913-8.875-34.697,2.646-55.359,25.727
								C1057.349,1851.94,1048.638,1877.85,1058.557,1886.723z"
                  />
                </g>
                <g>
                  <path
                    style="fill: #aedbe3"
                    d="M1262.635,1891.471c-0.285-0.574-0.565-1.15-0.831-1.737
								c-20.792-45.885,23.029-110.443,97.871-144.189c74.515-33.594,151.671-24.004,172.851,21.355L1262.635,1891.471z"
                  />
                  <path
                    style="fill: #151729"
                    d="M1294.169,1859.28c-11.617-25.635,22.481-66.04,76.148-90.241
								c53.673-24.201,106.594-23.051,118.2,2.582c2.035,4.485,2.666,9.425,2.037,14.65l-184.924,85.353
								C1300.4,1868.473,1296.471,1864.36,1294.169,1859.28z"
                  />
                </g>
                <path
                  style="fill: #151729"
                  d="M1019.149,1993.549c5.357,2.714,14.808-5.169,21.108-17.609
							c6.302-12.441,7.066-24.729,1.708-27.441c-5.357-2.715-14.808,5.17-21.112,17.608
							C1014.551,1978.549,1013.788,1990.832,1019.149,1993.549z"
                />
                <path
                  style="fill: #151729"
                  d="M1342.759,1695.848c2.485,5.483,14.817,5.253,27.548-0.517
							c12.73-5.769,21.041-14.893,18.554-20.375c-2.483-5.485-14.816-5.253-27.55,0.513
							C1348.58,1681.24,1340.274,1690.359,1342.759,1695.848z"
                />
                <path
                  style="fill: #151729"
                  d="M1291.293,1745.663c4.501,6.282,19.975,2.905,34.562-7.548
							c14.585-10.455,22.765-24.024,18.258-30.306c-4.499-6.285-19.972-2.904-34.561,7.549
							C1294.965,1725.811,1286.791,1739.377,1291.293,1745.663z"
                />
                <path
                  style="fill: #151729"
                  d="M1202.198,1791.32c2.522,2.79,9.817,0.308,16.296-5.547c6.477-5.854,9.688-12.864,7.163-15.652
							c-2.519-2.792-9.815-0.308-16.295,5.545C1202.884,1781.521,1199.676,1788.527,1202.198,1791.32z"
                />
                <path
                  style="fill: #aedbe3"
                  d="M1329.572,1652.476c-33.257,21.475,22.205,10.683,42.203-2.083
							C1423.728,1617.226,1362.828,1631.001,1329.572,1652.476z"
                />
                <path
                  style="fill: #aedbe3"
                  d="M1592.501,1588.912c-26.442,2.622,9.982,13.187,25.837,11.699
							C1659.53,1596.744,1618.946,1586.29,1592.501,1588.912z"
                />
                <path
                  style="fill: #aedbe3"
                  d="M1197.613,1752.603c-11.037,12.207-47.523,27.31-29.228,6.824
							C1186.678,1738.938,1226.285,1720.889,1197.613,1752.603z"
                />
              </g>
            </g>
          </g>

          <g>
            <g>
              <path
                class="assets"
                style="fill: #6ec6ef"
                d="M1219.407,895.353c-1.225,3.561-5.11,5.459-8.678,4.232c-3.556-1.223-5.447-5.107-4.223-8.668
						c1.224-3.558,5.102-5.453,8.658-4.23C1218.732,887.914,1220.63,891.795,1219.407,895.353z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1550.092,1476.98c-1.225,3.563-5.111,5.453-8.676,4.227c-3.556-1.223-5.449-5.101-4.224-8.663
						c1.224-3.56,5.105-5.461,8.661-4.238C1549.417,1469.532,1551.316,1473.421,1550.092,1476.98z"
              />
              <path
                class="assets"
                style="fill: #6ec6ef"
                d="M991.893,1634.576c-1.224,3.559-5.108,5.453-8.676,4.227c-3.556-1.223-5.45-5.104-4.227-8.663
						c1.225-3.561,5.107-5.46,8.663-4.237C991.22,1627.129,993.117,1631.015,991.893,1634.576z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M692.715,739.394c-0.841,2.447-3.504,3.749-5.952,2.907c-2.453-0.844-3.754-3.509-2.913-5.955
						c0.842-2.45,3.507-3.751,5.96-2.907C692.258,734.28,693.558,736.944,692.715,739.394z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M397.943,369.298c-0.843,2.45-3.507,3.751-5.959,2.908c-2.448-0.842-3.749-3.506-2.906-5.957
						c0.842-2.45,3.507-3.75,5.954-2.909C397.484,364.184,398.785,366.848,397.943,369.298z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M486.866,556.01c-0.842,2.448-3.506,3.748-5.957,2.905c-2.448-0.842-3.747-3.505-2.905-5.953
						c0.842-2.45,3.505-3.751,5.952-2.909C486.408,550.897,487.709,553.561,486.866,556.01z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M605.642,743.786c-0.842,2.45-3.505,3.751-5.958,2.907c-2.448-0.842-3.747-3.505-2.904-5.955
						c0.841-2.447,3.504-3.749,5.952-2.907C605.184,738.674,606.483,741.339,605.642,743.786z"
              />
              <path
                class="assets"
                style="fill: #ffffff"
                d="M199.046,754.272c-1.264,3.675-5.26,5.627-8.934,4.363c-3.669-1.261-5.619-5.258-4.355-8.933
						c1.262-3.671,5.258-5.622,8.926-4.361C198.357,746.605,200.308,750.6,199.046,754.272z"
              />
              <path
                class="assets"
                style="fill: #ffffff"
                d="M277.49,566.041c-1.264,3.676-5.26,5.627-8.932,4.365c-3.668-1.261-5.619-5.258-4.355-8.934
						c1.262-3.671,5.258-5.622,8.926-4.36C276.802,558.375,278.753,562.37,277.49,566.041z"
              />
              <path
                class="assets"
                style="fill: #f26522"
                d="M1042.013,1434.775c-2.123,6.174-8.837,9.451-15.012,7.327
						c-6.167-2.121-9.446-8.833-7.323-15.007c2.12-6.166,8.834-9.443,15.001-7.322
						C1040.854,1421.895,1044.133,1428.608,1042.013,1434.775z"
              />
              <path
                class="assets"
                style="fill: #f26522"
                d="M1036.434,156.312c-2.123,6.174-8.837,9.451-15.012,7.327
						c-6.167-2.121-9.446-8.834-7.323-15.008c2.12-6.166,8.834-9.443,15.001-7.322
						C1035.276,143.433,1038.555,150.146,1036.434,156.312z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M633.462,1042.321c-1.263,3.673-5.259,5.624-8.931,4.361c-3.67-1.262-5.621-5.258-4.358-8.931
						c1.263-3.673,5.259-5.624,8.929-4.362C632.774,1034.652,634.725,1038.647,633.462,1042.321z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1501.506,887.104c-1.263,3.673-5.259,5.625-8.931,4.362c-3.668-1.262-5.621-5.259-4.358-8.932
						c1.263-3.674,5.261-5.624,8.929-4.362C1500.818,879.434,1502.769,883.43,1501.506,887.104z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1146.583,1268.496c-1.263,3.673-5.26,5.623-8.933,4.36c-3.668-1.261-5.619-5.257-4.356-8.93
						c1.264-3.675,5.259-5.624,8.927-4.363C1145.894,1260.827,1147.847,1264.821,1146.583,1268.496z"
              />
              <path
                class="assets"
                style="fill: #fee673"
                d="M1168.813,1626.032c-1.264,3.675-5.259,5.626-8.932,4.363c-3.671-1.262-5.621-5.258-4.358-8.933
						c1.263-3.672,5.259-5.624,8.929-4.362C1168.126,1618.363,1170.076,1622.359,1168.813,1626.032z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M399.89,420.231c-1.265,3.678-5.262,5.629-8.935,4.366c-3.668-1.262-5.621-5.258-4.356-8.936
						c1.261-3.668,5.259-5.619,8.928-4.358C399.198,412.566,401.151,416.563,399.89,420.231z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M659.005,478.744c-1.263,3.673-5.257,5.625-8.933,4.361c-3.668-1.261-5.617-5.257-4.354-8.93
						c1.263-3.674,5.257-5.625,8.925-4.364C658.319,471.075,660.268,475.07,659.005,478.744z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M847.631,436.71c-1.265,3.679-5.261,5.629-8.935,4.366c-3.668-1.262-5.622-5.257-4.356-8.936
						c1.262-3.668,5.259-5.618,8.928-4.357C846.942,429.046,848.893,433.041,847.631,436.71z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M646.36,243.303c-1.263,3.673-5.259,5.624-8.933,4.361c-3.669-1.261-5.619-5.257-4.356-8.93
						c1.263-3.674,5.259-5.625,8.927-4.364C645.673,235.633,647.624,239.629,646.36,243.303z"
              />
              <path
                class="assets"
                style="fill: #6ec6ef"
                d="M1326.495,477.18c-1.263,3.673-5.259,5.624-8.931,4.361c-3.669-1.262-5.621-5.258-4.358-8.931
						c1.264-3.674,5.261-5.624,8.929-4.363C1325.807,469.51,1327.758,473.506,1326.495,477.18z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1344.56,709.977c-1.263,3.674-5.261,5.624-8.933,4.361c-3.669-1.262-5.62-5.257-4.356-8.931
						c1.263-3.674,5.259-5.625,8.927-4.363C1343.87,702.307,1345.823,706.303,1344.56,709.977z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1824.694,924.405c-1.264,3.674-5.259,5.625-8.933,4.362c-3.668-1.262-5.621-5.258-4.358-8.932
						c1.263-3.673,5.26-5.623,8.929-4.362C1824.006,916.737,1825.957,920.732,1824.694,924.405z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1621.798,769.809c-1.265,3.679-5.263,5.629-8.935,4.366c-3.669-1.261-5.621-5.258-4.356-8.937
						c1.261-3.668,5.259-5.619,8.927-4.357C1621.106,762.144,1623.059,766.141,1621.798,769.809z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M544.069,308.482c-0.842,2.448-3.506,3.748-5.952,2.907c-2.448-0.842-3.749-3.506-2.907-5.953
						c0.842-2.451,3.507-3.75,5.954-2.909C543.61,303.368,544.911,306.032,544.069,308.482z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M610.298,338.569c-0.842,2.448-3.506,3.748-5.952,2.907c-2.453-0.844-3.755-3.507-2.913-5.955
						c0.842-2.449,3.507-3.75,5.96-2.906C609.839,333.455,611.14,336.12,610.298,338.569z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M974.653,384.879c-0.842,2.448-3.506,3.748-5.954,2.906c-2.451-0.843-3.752-3.507-2.911-5.954
						c0.843-2.45,3.507-3.75,5.958-2.907C974.194,379.765,975.495,382.429,974.653,384.879z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M751.939,642.335c-0.842,2.448-3.506,3.748-5.954,2.906c-2.451-0.843-3.753-3.506-2.911-5.955
						c0.842-2.45,3.507-3.75,5.958-2.907C751.48,637.221,752.782,639.885,751.939,642.335z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M405.787,949.806c-0.841,2.446-3.506,3.746-5.953,2.905c-2.451-0.843-3.753-3.506-2.911-5.953
						c0.842-2.449,3.506-3.749,5.958-2.906C405.327,944.694,406.629,947.357,405.787,949.806z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M548.136,885.252c-0.844,2.453-3.508,3.753-5.954,2.912c-2.453-0.844-3.754-3.507-2.911-5.96
						c0.84-2.442,3.504-3.743,5.957-2.899C547.674,880.145,548.975,882.81,548.136,885.252z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M865.36,886.229c-0.843,2.453-3.51,3.753-5.956,2.912c-2.453-0.844-3.754-3.508-2.911-5.96
						c0.84-2.442,3.504-3.742,5.957-2.899C864.896,881.122,866.199,883.786,865.36,886.229z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1091.122,1156.926c-0.843,2.451-3.506,3.753-5.953,2.911c-2.451-0.843-3.754-3.508-2.911-5.96
						c0.84-2.442,3.506-3.742,5.957-2.899C1090.662,1151.82,1091.962,1154.484,1091.122,1156.926z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1209.713,1194.766c-0.841,2.446-3.506,3.746-5.959,2.903c-2.446-0.841-3.747-3.505-2.906-5.951
						c0.842-2.448,3.506-3.75,5.952-2.909C1209.254,1189.653,1210.555,1192.318,1209.713,1194.766z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1275.212,1287.288c-0.844,2.453-3.508,3.753-5.953,2.912c-2.453-0.844-3.755-3.507-2.911-5.96
						c0.84-2.442,3.504-3.743,5.958-2.899C1274.75,1282.181,1276.051,1284.846,1275.212,1287.288z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1428.513,1312.985c-0.842,2.448-3.506,3.747-5.957,2.904c-2.448-0.842-3.75-3.503-2.908-5.952
						c0.842-2.449,3.506-3.748,5.953-2.906C1428.053,1307.874,1429.355,1310.537,1428.513,1312.985z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M918.338,803.655c-0.841,2.447-3.508,3.747-5.951,2.906c-2.453-0.844-3.756-3.508-2.915-5.955
						c0.842-2.449,3.509-3.75,5.962-2.907C917.877,798.539,919.18,801.205,918.338,803.655z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1238.349,1084.708c-0.842,2.449-3.512,3.748-5.96,2.907c-2.445-0.841-3.752-3.507-2.91-5.957
						c0.842-2.448,3.512-3.746,5.957-2.905C1237.884,1079.594,1239.191,1082.26,1238.349,1084.708z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M568.612,956.249c-0.84,2.443-3.506,3.749-5.954,2.907c-2.453-0.844-3.751-3.512-2.911-5.955
						c0.843-2.451,3.508-3.759,5.961-2.915C568.156,951.127,569.455,953.797,568.612,956.249z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1402.263,435.418c-0.841,2.445-3.506,3.747-5.953,2.905c-2.447-0.842-3.748-3.507-2.908-5.952
						c0.843-2.451,3.507-3.752,5.955-2.91C1401.804,430.303,1403.106,432.967,1402.263,435.418z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1253.001,169.378c-0.841,2.445-3.505,3.746-5.951,2.905c-2.447-0.842-3.747-3.505-2.906-5.951
						c0.843-2.452,3.506-3.753,5.953-2.912C1252.543,164.261,1253.844,166.926,1253.001,169.378z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M480.153,391.995c-0.493,1.433-2.052,2.197-3.489,1.703c-1.436-0.494-2.202-2.057-1.709-3.49
						c0.495-1.438,2.06-2.2,3.496-1.706C479.887,388.995,480.647,390.557,480.153,391.995z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M561.353,269.288c-0.493,1.433-2.06,2.194-3.494,1.701c-1.434-0.493-2.196-2.055-1.704-3.488
						c0.495-1.439,2.056-2.202,3.491-1.709C561.08,266.285,561.848,267.849,561.353,269.288z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M736.817,391.417c-0.493,1.433-2.054,2.196-3.489,1.703c-1.436-0.494-2.202-2.057-1.709-3.49
						c0.495-1.438,2.06-2.2,3.496-1.706C736.549,388.417,737.311,389.979,736.817,391.417z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M713.944,494.732c-0.493,1.433-2.053,2.197-3.489,1.703c-1.434-0.493-2.2-2.057-1.707-3.49
						c0.495-1.438,2.06-2.2,3.494-1.706C713.678,491.733,714.438,493.294,713.944,494.732z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M670.663,569.558c-0.493,1.432-2.053,2.197-3.489,1.703c-1.436-0.494-2.2-2.057-1.707-3.49
						c0.495-1.438,2.058-2.201,3.494-1.707C670.397,566.558,671.158,568.12,670.663,569.558z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M542.412,412.324c-0.493,1.433-2.05,2.197-3.488,1.702c-1.434-0.493-2.2-2.056-1.708-3.489
						c0.495-1.439,2.06-2.2,3.494-1.706C542.148,409.325,542.907,410.885,542.412,412.324z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M417.557,446.426c-0.493,1.433-2.053,2.197-3.489,1.703c-1.436-0.494-2.2-2.056-1.707-3.49
						c0.495-1.438,2.058-2.2,3.494-1.706C417.291,443.426,418.052,444.988,417.557,446.426z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1255.966,390.161c-0.493,1.433-2.056,2.195-3.494,1.701c-1.434-0.493-2.199-2.055-1.706-3.488
						c0.495-1.439,2.058-2.2,3.492-1.707C1255.697,387.16,1256.461,388.722,1255.966,390.161z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1431.429,512.289c-0.493,1.433-2.056,2.195-3.487,1.703c-1.44-0.495-2.2-2.056-1.707-3.49
						c0.495-1.438,2.054-2.202,3.494-1.707C1431.159,509.288,1431.923,510.851,1431.429,512.289z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M823.027,795.599c-0.493,1.434-2.052,2.196-3.494,1.7c-1.43-0.492-2.197-2.054-1.704-3.488
						c0.495-1.438,2.06-2.2,3.49-1.708C822.762,792.599,823.522,794.161,823.027,795.599z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M904.227,672.892c-0.493,1.434-2.058,2.195-3.492,1.702c-1.438-0.494-2.204-2.057-1.711-3.491
						c0.495-1.439,2.06-2.199,3.498-1.705C903.956,669.891,904.722,671.454,904.227,672.892z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1079.689,795.021c-0.493,1.433-2.056,2.195-3.486,1.703c-1.44-0.495-2.2-2.056-1.708-3.489
						c0.495-1.439,2.054-2.202,3.494-1.707C1079.42,792.02,1080.184,793.582,1079.689,795.021z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M885.287,815.928c-0.493,1.434-2.056,2.195-3.487,1.704c-1.442-0.496-2.202-2.056-1.709-3.49
						c0.495-1.438,2.054-2.201,3.496-1.705C885.017,812.928,885.781,814.49,885.287,815.928z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1148.202,1659.089c-0.492,1.432-2.059,2.196-3.489,1.704c-1.44-0.495-2.201-2.059-1.709-3.492
						c0.495-1.44,2.056-2.199,3.496-1.704C1147.93,1656.089,1148.697,1657.649,1148.202,1659.089z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M953.797,1679.996c-0.492,1.431-2.057,2.197-3.489,1.704c-1.44-0.495-2.199-2.06-1.707-3.491
						c0.495-1.441,2.054-2.2,3.494-1.705C953.527,1676.997,954.293,1678.555,953.797,1679.996z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M447.261,1414.767c-0.493,1.435-2.058,2.194-3.492,1.701c-1.436-0.494-2.203-2.055-1.71-3.49
						c0.494-1.435,2.059-2.195,3.495-1.702C446.988,1411.77,447.755,1413.332,447.261,1414.767z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M622.727,1536.899c-0.492,1.431-2.059,2.196-3.491,1.704c-1.44-0.495-2.197-2.059-1.705-3.49
						c0.495-1.44,2.052-2.201,3.492-1.706C622.455,1533.899,623.222,1535.459,622.727,1536.899z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1697.554,654.161c-0.494,1.436-2.058,2.195-3.494,1.701c-1.434-0.493-2.199-2.053-1.705-3.489
						c0.494-1.436,2.056-2.195,3.49-1.701C1697.28,651.165,1698.047,652.725,1697.554,654.161z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1678.612,797.2c-0.492,1.43-2.056,2.195-3.489,1.703c-1.44-0.495-2.199-2.059-1.707-3.49
						c0.495-1.441,2.054-2.202,3.494-1.706C1678.342,794.199,1679.107,795.759,1678.612,797.2z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M659.823,1215.215c-0.493,1.434-2.052,2.195-3.492,1.7c-1.432-0.492-2.199-2.053-1.706-3.488
						c0.494-1.437,2.06-2.201,3.493-1.708C659.558,1212.215,660.317,1213.778,659.823,1215.215z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1447.971,964.922c-0.494,1.436-2.056,2.196-3.491,1.703c-1.436-0.494-2.203-2.055-1.709-3.491
						c0.494-1.435,2.058-2.195,3.494-1.701C1447.7,961.926,1448.465,963.487,1447.971,964.922z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1029.303,1281.827c-0.491,1.429-2.058,2.193-3.488,1.702c-1.442-0.496-2.201-2.06-1.71-3.489
						c0.496-1.442,2.054-2.203,3.496-1.707C1029.032,1278.825,1029.799,1280.385,1029.303,1281.827z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1109.002,379.612c-0.841,2.447-3.506,3.748-5.959,2.905c-2.446-0.841-3.746-3.506-2.905-5.953
						c0.842-2.45,3.507-3.75,5.953-2.909C1108.544,374.498,1109.845,377.162,1109.002,379.612z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M668.204,299.906c-0.842,2.448-3.506,3.749-5.96,2.905c-2.448-0.842-3.747-3.506-2.905-5.953
						c0.842-2.449,3.505-3.751,5.952-2.909C667.745,294.792,669.046,297.456,668.204,299.906z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1291.8,1640.828c-0.842,2.448-3.506,3.748-5.952,2.907c-2.448-0.842-3.749-3.506-2.907-5.953
						c0.843-2.45,3.507-3.75,5.954-2.909C1291.342,1635.714,1292.643,1638.378,1291.8,1640.828z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1358.029,1670.915c-0.842,2.448-3.506,3.748-5.952,2.907c-2.453-0.844-3.755-3.507-2.913-5.955
						c0.842-2.449,3.507-3.75,5.96-2.906C1357.571,1665.801,1358.872,1668.465,1358.029,1670.915z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1412.144,1748.048c-0.842,2.448-3.506,3.748-5.953,2.906c-2.453-0.844-3.753-3.507-2.911-5.955
						c0.842-2.45,3.505-3.751,5.959-2.908C1411.686,1742.934,1412.986,1745.599,1412.144,1748.048z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1309.084,1601.634c-0.493,1.433-2.06,2.194-3.494,1.701c-1.434-0.493-2.196-2.055-1.703-3.488
						c0.495-1.439,2.056-2.202,3.491-1.709C1308.811,1598.631,1309.579,1600.195,1309.084,1601.634z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1484.548,1723.763c-0.493,1.433-2.054,2.196-3.489,1.703c-1.436-0.494-2.202-2.057-1.709-3.49
						c0.495-1.438,2.06-2.2,3.496-1.706C1484.281,1720.763,1485.042,1722.325,1484.548,1723.763z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1290.144,1744.67c-0.493,1.433-2.051,2.197-3.489,1.702c-1.434-0.493-2.2-2.056-1.707-3.489
						c0.495-1.439,2.06-2.2,3.494-1.706C1289.88,1741.671,1290.638,1743.231,1290.144,1744.67z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1415.936,1632.251c-0.842,2.448-3.506,3.749-5.96,2.905c-2.448-0.842-3.747-3.505-2.905-5.953
						c0.842-2.449,3.505-3.75,5.952-2.909C1415.477,1627.138,1416.778,1629.802,1415.936,1632.251z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M363.243,663.809c-0.84,2.442-3.505,3.75-5.958,2.906c-2.448-0.842-3.745-3.511-2.905-5.953
						c0.841-2.447,3.505-3.753,5.953-2.911C362.786,658.694,364.084,661.362,363.243,663.809z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M726.369,1151.805c-0.841,2.446-3.506,3.747-5.953,2.906c-2.451-0.843-3.752-3.507-2.911-5.954
						c0.844-2.453,3.508-3.755,5.96-2.912C725.912,1146.686,727.212,1149.352,726.369,1151.805z"
              />
              <path
                class="assets"
                style="fill: #ffffff"
                d="M940.587,1371.259c-0.84,2.444-3.504,3.749-5.952,2.907c-2.453-0.844-3.751-3.511-2.911-5.955
						c0.841-2.445,3.505-3.752,5.959-2.908C940.13,1366.144,941.428,1368.814,940.587,1371.259z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M230.578,1245.064c-0.842,2.448-3.504,3.748-5.957,2.905c-2.448-0.842-3.749-3.504-2.907-5.953
						c0.843-2.451,3.507-3.751,5.955-2.91C230.121,1239.95,231.421,1242.613,230.578,1245.064z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1495.87,1582.516c-0.842,2.449-3.512,3.754-5.96,2.912c-2.442-0.84-3.746-3.512-2.904-5.961
						c0.841-2.447,3.513-3.749,5.955-2.91C1495.407,1577.399,1496.711,1580.069,1495.87,1582.516z"
              />
              <path
                class="assets"
                style="fill: #ffffff"
                d="M1786.097,777.396c-0.841,2.445-3.503,3.746-5.956,2.902c-2.448-0.842-3.747-3.505-2.906-5.95
						c0.843-2.453,3.506-3.754,5.953-2.912C1785.641,772.279,1786.94,774.943,1786.097,777.396z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1555.042,725.152c-0.842,2.448-3.512,3.746-5.959,2.904c-2.448-0.842-3.752-3.507-2.911-5.955
						c0.843-2.451,3.511-3.752,5.959-2.91C1554.579,720.034,1555.885,722.701,1555.042,725.152z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M285.662,967.042c-0.84,2.442-3.506,3.748-5.954,2.907c-2.448-0.842-3.745-3.511-2.905-5.953
						c0.841-2.446,3.506-3.753,5.953-2.911C285.204,961.925,286.504,964.595,285.662,967.042z"
              />
              <path
                class="assets"
                style="fill: #fee673"
                d="M1064.827,1295.025c-0.838,2.438-3.506,3.748-5.957,2.905c-2.448-0.842-3.746-3.515-2.907-5.953
						c0.843-2.452,3.511-3.762,5.958-2.92C1064.372,1289.899,1065.67,1292.572,1064.827,1295.025z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M736.849,1035.113c-0.84,2.442-3.508,3.753-5.955,2.912c-2.446-0.841-3.743-3.516-2.903-5.958
						c0.841-2.447,3.509-3.758,5.955-2.917C736.393,1029.991,737.69,1032.667,736.849,1035.113z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1501.633,1214.871c-0.839,2.44-3.505,3.752-5.958,2.908c-2.448-0.842-3.745-3.517-2.906-5.957
						c0.842-2.448,3.51-3.76,5.958-2.918C1501.18,1209.748,1502.475,1212.422,1501.633,1214.871z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1720.73,1019.331c-0.84,2.444-3.505,3.745-5.954,2.902c-2.451-0.843-3.753-3.507-2.912-5.951
						c0.844-2.453,3.508-3.753,5.959-2.91C1720.272,1014.214,1721.573,1016.878,1720.73,1019.331z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1769.907,1198.611c-0.838,2.437-3.504,3.742-5.952,2.901c-2.451-0.843-3.753-3.513-2.915-5.95
						c0.843-2.452,3.513-3.763,5.965-2.92C1769.453,1193.484,1770.75,1196.159,1769.907,1198.611z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1135.21,739.027c-0.838,2.437-3.506,3.742-5.954,2.901c-2.451-0.843-3.75-3.513-2.912-5.95
						c0.843-2.452,3.511-3.762,5.962-2.919C1134.754,733.901,1136.053,736.576,1135.21,739.027z"
              />
              <path
                class="assets"
                style="fill: #e64728"
                d="M1118.943,1054.539c-0.952,2.769-3.973,4.245-6.742,3.293c-2.769-0.952-4.244-3.974-3.291-6.743
						c0.952-2.768,3.972-4.243,6.742-3.291C1118.42,1048.75,1119.895,1051.771,1118.943,1054.539z"
              />
              <path
                class="assets"
                style="fill: #29b5c5"
                d="M411.424,1509c-3.32,9.656-13.846,14.796-23.502,11.475
						c-9.648-3.318-14.796-13.847-11.476-23.503c3.321-9.658,13.855-14.792,23.504-11.475
						C409.606,1488.818,414.745,1499.342,411.424,1509z"
              />
              <path
                class="assets"
                style="fill: #29b5c5"
                d="M1061.252,215.464c-3.32,9.656-13.846,14.796-23.502,11.475
						c-9.648-3.318-14.796-13.847-11.476-23.503c3.321-9.658,13.856-14.792,23.504-11.475
						C1059.434,195.282,1064.574,205.807,1061.252,215.464z"
              />
              <path
                class="assets"
                style="fill: #29b5c5"
                d="M326.096,1463.503c-3.32,9.656-13.846,14.796-23.502,11.475
						c-9.648-3.318-14.796-13.847-11.476-23.503c3.321-9.658,13.856-14.793,23.504-11.475
						C324.278,1443.321,329.417,1453.845,326.096,1463.503z"
              />
              <path
                class="assets"
                style="fill: #29b5c5"
                d="M1367.851,1728.426c-3.32,9.656-13.846,14.796-23.502,11.475
						c-9.648-3.318-14.796-13.847-11.476-23.503c3.321-9.658,13.856-14.792,23.504-11.475
						C1366.033,1708.244,1371.172,1718.769,1367.851,1728.426z"
              />
              <path
                class="assets"
                style="fill: #e64728"
                d="M1612.185,450.014c-2.53,7.358-10.549,11.273-17.907,8.742
						c-7.35-2.527-11.271-10.548-8.741-17.906c2.53-7.357,10.555-11.27,17.905-8.743
						C1610.8,434.638,1614.715,442.657,1612.185,450.014z"
              />
              <path
                class="assets"
                style="fill: #53bed2"
                d="M1589.811,923.323c-2.53,7.356-10.549,11.272-17.905,8.743
						c-7.354-2.529-11.275-10.551-8.745-17.907c2.53-7.357,10.555-11.271,17.909-8.742
						C1588.425,907.946,1592.341,915.966,1589.811,923.323z"
              />
              <path
                class="assets"
                style="fill: #e64728"
                d="M1202.401,1759.633c-0.952,2.769-3.975,4.243-6.744,3.291c-2.769-0.952-4.242-3.972-3.29-6.741
						c0.952-2.769,3.971-4.244,6.74-3.292S1203.353,1756.865,1202.401,1759.633z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M1846.984,1030.001c-2.178,6.335-9.073,9.7-15.408,7.521c-6.329-2.176-9.701-9.071-7.522-15.407
						c2.176-6.328,9.077-9.697,15.406-7.521C1845.794,1016.774,1849.16,1023.673,1846.984,1030.001z"
              />
              <path
                class="assets"
                style="fill: #c5e2f0"
                d="M813.878,1864.213c-0.839,2.441-3.506,3.747-5.957,2.904c-2.448-0.842-3.747-3.512-2.907-5.953
						c0.842-2.447,3.508-3.753,5.956-2.912C813.42,1859.096,814.72,1861.766,813.878,1864.213z"
              />
            </g>
            <g>
              <path
                class="assets"
                style="fill: #ffffff"
                d="M1586.579,1354.863c-3.93-0.455-7.489-3.604-7.829-7.823
						c-0.268-3.278-2.456-17.726-2.456-17.726s-1.877,14.487-2.462,17.726c-0.869,4.802-3.728,7.253-7.824,7.819
						c-3.26,0.448-17.723,2.468-17.723,2.465c0,0,14.464,2.036,17.723,2.468c5.203,0.687,7.311,4.013,7.814,7.817
						c0.44,3.259,2.472,17.725,2.472,17.725s2.134-14.453,2.471-17.725c0.445-4.387,3.651-7.363,7.814-7.823
						c3.269-0.363,17.723-2.463,17.723-2.463C1604.303,1357.326,1589.843,1355.24,1586.579,1354.863z"
              />
              <path
                class="assets"
                style="fill: #ffffff"
                d="M926.585,267.034c-4.845-0.561-9.233-4.443-9.652-9.645c-0.329-4.041-3.027-21.851-3.027-21.851
						s-2.313,17.86-3.033,21.851c-1.072,5.92-4.597,8.942-9.647,9.641c-4.019,0.55-21.85,3.042-21.85,3.036
						c0,0,17.831,2.511,21.85,3.043c6.414,0.846,9.012,4.947,9.632,9.637c0.545,4.018,3.048,21.852,3.048,21.852
						s2.631-17.819,3.048-21.852c0.548-5.408,4.5-9.077,9.632-9.645c4.029-0.448,21.849-3.038,21.849-3.038
						C948.434,270.071,930.609,267.499,926.585,267.034z"
              />
              <path
                class="assets"
                style="fill: #ffffff"
                d="M1758.445,688.911c-4.845-0.561-9.233-4.443-9.652-9.645
						c-0.329-4.041-3.027-21.851-3.027-21.851s-2.313,17.86-3.033,21.851c-1.072,5.92-4.597,8.942-9.647,9.641
						c-4.019,0.55-21.85,3.042-21.85,3.036c0,0,17.831,2.511,21.85,3.043c6.414,0.846,9.012,4.947,9.632,9.637
						c0.545,4.018,3.047,21.852,3.047,21.852s2.631-17.819,3.047-21.852c0.548-5.408,4.5-9.077,9.632-9.645
						c4.029-0.448,21.849-3.038,21.849-3.038C1780.294,691.948,1762.469,689.376,1758.445,688.911z"
              />
              <path
                class="assets"
                style="fill: #ffffff"
                d="M1530.743,1155.698c-7.018-0.812-13.374-6.436-13.982-13.971
						c-0.477-5.854-4.385-31.652-4.385-31.652s-3.351,25.871-4.393,31.652c-1.553,8.576-6.659,12.952-13.974,13.965
						c-5.822,0.797-31.651,4.406-31.651,4.398c0,0,25.828,3.637,31.651,4.408c9.291,1.226,13.054,7.166,13.953,13.96
						c0.789,5.82,4.414,31.654,4.414,31.654s3.812-25.811,4.415-31.654c0.794-7.833,6.519-13.148,13.953-13.97
						c5.837-0.649,31.649-4.401,31.649-4.401C1562.392,1160.098,1536.572,1156.371,1530.743,1155.698z"
              />
              <path
                class="assets"
                style="fill: #ffffff"
                d="M235.558,1115.386c-5.389-0.62-10.268-4.937-10.734-10.728
						c-0.365-4.491-3.367-24.297-3.367-24.297s-2.573,19.864-3.372,24.297c-1.194,6.587-5.113,9.948-10.73,10.723
						c-4.47,0.616-24.297,3.384-24.297,3.379c0,0,19.827,2.794,24.297,3.382c7.133,0.939,10.022,5.499,10.712,10.718
						c0.604,4.466,3.389,24.298,3.389,24.298s2.926-19.81,3.388-24.298c0.61-6.013,5.007-10.096,10.713-10.723
						c4.48-0.498,24.298-3.383,24.298-3.383C259.855,1118.765,240.034,1115.9,235.558,1115.386z"
              />
              <path
                class="assets"
                style="fill: #ffffff"
                d="M471.436,1624.63c-4.181-0.488-7.973-3.84-8.336-8.336c-0.281-3.49-2.617-18.875-2.617-18.875
						s-1.998,15.427-2.62,18.875c-0.926,5.118-3.97,7.725-8.333,8.328c-3.469,0.476-18.874,2.63-18.874,2.627
						c0-0.005,15.401,2.167,18.874,2.625c5.544,0.734,7.781,4.274,8.325,8.328c0.466,3.469,2.628,18.875,2.628,18.875
						s2.274-15.39,2.628-18.875c0.479-4.672,3.895-7.841,8.324-8.337c3.482-0.383,18.878-2.621,18.878-2.616
						C490.314,1627.251,474.915,1625.029,471.436,1624.63z"
              />
              <path
                class="assets"
                style="fill: #ffffff"
                d="M667.68,1437.402c-8.535-0.984-16.274-7.83-17.009-17.009
						c-0.575-7.12-5.339-38.507-5.339-38.507s-4.081,31.481-5.353,38.507c-1.878,10.451-8.096,15.769-16.994,17.002
						c-7.08,0.961-38.522,5.363-38.522,5.363c0-0.017,31.433,4.413,38.522,5.346c11.315,1.498,15.881,8.732,16.986,16.999
						c0.946,7.082,5.361,38.509,5.361,38.509s4.642-31.397,5.363-38.509c0.978-9.534,7.942-16.002,16.986-17.021
						c7.103-0.775,38.524-5.341,38.524-5.324C706.204,1442.758,674.784,1438.228,667.68,1437.402z"
              />
              <path
                class="assets"
                style="fill: #ffffff"
                d="M530.688,357.615c-6.994-0.81-13.349-6.429-13.945-13.957
						c-0.481-5.845-4.388-31.595-4.388-31.595s-3.345,25.831-4.391,31.595c-1.538,8.573-6.635,12.935-13.945,13.951
						c-5.805,0.79-31.595,4.395-31.595,4.395c0-0.011,25.779,3.621,31.595,4.384c9.286,1.229,13.034,7.163,13.945,13.95
						c0.776,5.806,4.391,31.597,4.391,31.597s3.806-25.762,4.399-31.597c0.799-7.82,6.527-13.13,13.935-13.961
						c5.828-0.634,31.605-4.385,31.605-4.373C562.293,362.004,536.516,358.288,530.688,357.615z"
              />
            </g>

            <g>
              <defs>
                <circle
                  id="SVGID_00000031201324429355823190000008864424503108926891_"
                  cx="1000"
                  cy="1000"
                  r="909.589"
                />
              </defs>
              <clipPath
                id="SVGID_00000057134746010976415170000007964834293088945543_"
              >
                <use
                  xlink:href="#SVGID_00000031201324429355823190000008864424503108926891_"
                  style="overflow: visible"
                />
              </clipPath>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <path
            style="fill: #f57f45"
            d="M1553.588,630.026c-2.963-5.224-1.131-11.86,4.092-14.823l95.411-54.127
				c5.224-2.963,11.86-1.131,14.823,4.092s1.131,11.86-4.092,14.823l-95.411,54.127
				C1563.188,637.082,1556.552,635.25,1553.588,630.026z"
          />

          <ellipse
            transform="matrix(0.9871 -0.1603 0.1603 0.9871 -70.8957 271.9521)"
            style="fill: #db1f26"
            cx="1649.968"
            cy="575.35"
            rx="27.836"
            ry="27.836"
          />
        </g>
        <g>
          <path
            style="fill: #ffc20e"
            d="M662.805,1135.368c-43.217-72.049-63.623-128.563-195.723-51.538
				c-38.295,22.338-99.538,81.615-113.619,115.335c22.852-13.347,55.6-36.118,109.549-49.961
				c-28.648,15.818-68.197,35.203-118.388,94.351c-62.861,73.981-68.946,111.431-68.946,111.431s35.267,13.99,131.02-2.011
				c76.52-12.735,113.45-36.737,141.726-53.212c-39.561,39.205-75.908,55.632-99.089,68.399
				c36.169,5.214,118.47-16.942,157.291-38.352C740.517,1255.939,702.477,1209.429,662.805,1135.368z"
            class="fire-loading"
          />
          <path
            style="fill: #f37121"
            d="M662.805,1135.368c-34.185-60.259-89.793-58.387-130.41-13.275
				c-40.617,45.112-80.817,133.104-80.817,133.104s96.154,10.64,155.714-1.08C666.853,1242.399,696.99,1195.628,662.805,1135.368z"
            class="fire-loading"
          />
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  style="fill: #db1f26"
                  d="M1005.322,755.041c0,0-122.87-155.934-153.756-183.476
							c-30.887-27.542-95.423-34.636-166.337-4.881c-68.345,28.676-123.148,56.421-167.041,75.295
							c-16.418,7.249-9.317,38.862,4.535,36.237c25.004-4.352,184.118-45.713,258.13,104.915
							C890.308,769.433,1005.322,755.041,1005.322,755.041z"
                />
              </g>
              <path
                style="fill: #ed5d31"
                d="M512.594,665.091c43.894-18.874,98.696-46.619,167.041-75.295
						c70.914-29.754,135.45-22.66,166.337,4.882c23.948,21.354,103.189,119.889,137.586,163.086
						c13.771-1.723,21.764-2.724,21.764-2.724s-122.87-155.934-153.756-183.476c-30.887-27.542-95.423-34.636-166.337-4.881
						c-68.345,28.676-123.147,56.421-167.041,75.295c-9.176,4.052-11.003,15.712-8.414,24.754
						C510.624,666.099,511.563,665.547,512.594,665.091z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  style="fill: #db1f26"
                  d="M1174.571,1053.381c0,0,70.809,185.469,78.603,226.111s-19.227,99.677-81.15,145.281
							c-59.679,43.952-111.61,76.757-150.331,104.75c-14.646,10.373-38.138-11.942-28.778-22.484
							c16.564-19.23,133.701-134.586,42.38-275.395C1103.209,1144.72,1174.571,1053.381,1174.571,1053.381z"
                />
              </g>
              <path
                style="fill: #ed5d31"
                d="M998.983,1522.466c38.721-27.993,90.652-60.799,150.332-104.75
						c61.922-45.604,88.943-104.638,81.149-145.281c-6.043-31.512-49.966-150.083-69.397-201.77
						c8.545-10.937,13.504-17.284,13.504-17.284s70.809,185.468,78.603,226.111c7.794,40.642-19.227,99.677-81.15,145.281
						c-59.679,43.952-111.61,76.757-150.331,104.75c-8.185,5.797-19.132,1.383-25.565-5.478
						C997.108,1523.64,998.063,1523.118,998.983,1522.466z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            style="fill: #961b1e"
            d="M715.489,1105.188c-39.835-70.219-59.659-126.19-73.166-167.719
				c-12.278,7.017-26.937,16.336-32.518,21.548c-32.73,30.568-47.412,51.28,30.64,188.866
				c77.942,137.392,112.813,132.257,146.394,123.198c8.077-2.179,24.932-10.639,38.853-18.605
				C794.969,1220.377,755.304,1175.372,715.489,1105.188z"
          />
          <path
            style="fill: #db1f26"
            d="M865.519,1020.369c-33.414-58.9-83.781-166.582-72.303-257.863
				c-57.685,18.189-111.767,40.369-157.256,66.174c-26.49,95.952,30.572,219.204,67.211,283.788
				c36.639,64.585,113.157,176.8,209.109,203.29c45.488-25.806,92.274-60.848,137.482-101.03
				C965.522,1177.748,898.933,1079.268,865.519,1020.369z"
          />
          <path
            style="fill: #f57f45"
            d="M1569.826,620.815c-57.043-100.551-583.438-28.074-831.29,112.533
				c-29.547,107.022,34.099,244.494,74.966,316.531c40.866,72.036,126.212,197.198,233.235,226.745
				C1294.589,1136.016,1626.869,721.366,1569.826,620.815z"
          />
          <path
            style="fill: #db1f26"
            d="M1394.922,720.038c19.711,34.745,51.846,82.533,95.499,121.837
				c65.904-97.763,101.278-182.504,79.405-221.061c-21.873-38.556-112.759-51.669-230.488-45.258
				C1350.679,633.192,1375.211,685.293,1394.922,720.038z"
          />
          <g>
            <path
              style="fill: #db1f26"
              d="M769.983,788.45c-10.337,5.864-13.963,18.998-8.099,29.335
					c5.864,10.337,18.998,13.963,29.335,8.099c10.337-5.864,13.963-18.998,8.099-29.335
					C793.454,786.212,780.32,782.586,769.983,788.45z"
            />

            <ellipse
              transform="matrix(0.7963 -0.6049 0.6049 0.7963 -408.5104 694.1099)"
              style="fill: #db1f26"
              cx="826.451"
              cy="953.665"
              rx="21.519"
              ry="21.519"
            />

            <ellipse
              transform="matrix(0.3825 -0.924 0.924 0.3825 -490.6289 1671.1632)"
              style="fill: #db1f26"
              cx="1004.955"
              cy="1202.642"
              rx="21.519"
              ry="21.519"
            />

            <ellipse
              transform="matrix(0.9996 -0.0271 0.0271 0.9996 -29.1873 24.8904)"
              style="fill: #db1f26"
              cx="902.73"
              cy="1088.126"
              rx="21.519"
              ry="21.519"
            />

            <ellipse
              transform="matrix(0.4974 -0.8675 0.8675 0.4974 165.0801 1534.1453)"
              style="fill: #f57f45"
              cx="1406.485"
              cy="624.611"
              rx="21.519"
              ry="21.519"
            />

            <ellipse
              transform="matrix(0.9533 -0.302 0.302 0.9533 -160.014 483.2832)"
              style="fill: #f57f45"
              cx="1482.765"
              cy="759.072"
              rx="21.519"
              ry="21.519"
            />
          </g>
          <g>
            <path
              style="fill: #db1f26"
              d="M1444.437,905.758c-49.733-55.794-86.276-113.407-108.361-152.337s-52.789-99.855-75.164-171.169
					c-2.057,0.234-4.118,0.471-6.184,0.715c22.287,71.409,52.923,132.543,75.119,171.855L812.593,1048.26
					c0.3,0.532,0.61,1.094,0.908,1.619c0.626,1.104,1.295,2.257,1.942,3.385l517.25-293.436
					c22.209,38.763,58.669,95.745,107.957,150.872C1441.919,909.051,1443.181,907.404,1444.437,905.758z"
            />
          </g>
        </g>
        <g>
          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -287.1406 1047.3961)"
            style="fill: #db1f26"
            cx="1120.749"
            cy="870.307"
            rx="225.875"
            ry="225.875"
          />
          <circle cx="1120.244" cy="870.648" r="174.774" />
          <path
            d="M1266.006,920.83c17.241-88.759-40.736-174.688-129.494-191.929c-85.278-16.565-167.908,36.322-189.551,119.215
				c0.47-3.592,1.015-7.19,1.716-10.794c18.406-94.754,110.14-156.647,204.894-138.241
				c94.754,18.406,156.647,110.139,138.241,204.894c-11.937,61.451-54.73,109.047-109.172,129.916
				C1224.47,1011.433,1256.247,971.071,1266.006,920.83z"
          />
          <g>
            <path
              style="fill: #a7dde6"
              d="M1108.984,793.771l72.334,136.689c2.181,4.121,7.337,5.709,11.458,3.528l0,0
					c4.121-2.181,5.709-7.337,3.528-11.458l-72.334-136.689c-2.181-4.121-7.337-5.709-11.458-3.528l0,0
					C1108.391,784.494,1106.803,789.65,1108.984,793.771z"
            />
            <path
              style="fill: #a7dde6"
              d="M1050.91,784.136l105,197.862c2.181,4.121,7.337,5.709,11.458,3.528l0,0
					c4.121-2.181,5.709-7.337,3.528-11.458l-105-197.862c-2.181-4.121-7.337-5.709-11.458-3.528l0,0
					C1050.317,774.859,1048.729,780.015,1050.91,784.136z"
            />
          </g>
          <g>
            <path
              d="M1068.596,1066.324c-1.455,7.492,3.438,14.745,10.93,16.2c7.492,1.455,14.745-3.438,16.2-10.93
					c1.455-7.492-3.438-14.745-10.93-16.2C1077.305,1053.939,1070.052,1058.833,1068.596,1066.324z"
            />
            <circle cx="1158.708" cy="674.888" r="13.819" />
            <path
              d="M926.034,820.086c-7.492-1.455-14.745,3.438-16.2,10.93c-1.455,7.492,3.438,14.745,10.93,16.2
					c7.492,1.455,14.745-3.438,16.2-10.93C938.419,828.794,933.526,821.541,926.034,820.086z"
            />
            <path
              d="M1320.105,896.632c-7.492-1.455-14.745,3.438-16.2,10.93c-1.455,7.492,3.438,14.745,10.93,16.2
					c7.492,1.455,14.745-3.438,16.2-10.93C1332.49,905.34,1327.597,898.087,1320.105,896.632z"
            />

            <ellipse
              transform="matrix(0.8571 -0.5151 0.5151 0.8571 -370.6621 632.0871)"
              cx="954.046"
              cy="984.186"
              rx="13.819"
              ry="13.819"
            />
            <path
              d="M1279.094,748.207c-6.327,4.268-7.995,12.857-3.726,19.184c4.268,6.326,12.857,7.995,19.184,3.726
					c6.326-4.268,7.995-12.857,3.726-19.184C1294.01,745.607,1285.421,743.938,1279.094,748.207z"
            />

            <ellipse
              transform="matrix(0.5142 -0.8577 0.8577 0.5142 -115.3584 1207.4025)"
              cx="1008.173"
              cy="705.536"
              rx="13.819"
              ry="13.819"
            />
            <path
              d="M1244.152,1030.583c-4.269-6.327-12.858-7.995-19.184-3.726c-6.327,4.268-7.995,12.857-3.726,19.184
					c4.268,6.327,12.857,7.995,19.184,3.726C1246.752,1045.499,1248.42,1036.91,1244.152,1030.583z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style="fill: #ffc20e"
                d="M1006.043,1510.828c-17.792-29.662-26.193-52.928-80.577-21.218
						c-15.766,9.196-40.979,33.6-46.776,47.482c9.408-5.495,22.89-14.87,45.1-20.569c-11.794,6.512-28.076,14.493-48.739,38.844
						c-25.879,30.457-28.385,45.875-28.385,45.875s14.519,5.76,53.94-0.828c31.503-5.243,46.706-15.124,58.347-21.907
						c-16.287,16.14-31.251,22.903-40.794,28.159c14.89,2.146,48.773-6.975,64.755-15.789
						C1038.036,1560.466,1022.376,1541.318,1006.043,1510.828z"
                class="fire-loading"
              />
              <path
                style="fill: #f37121"
                d="M1006.043,1510.828c-14.074-24.808-36.967-24.037-53.689-5.465
						c-16.722,18.572-33.271,54.798-33.271,54.798s39.586,4.38,64.106-0.445C1007.71,1554.891,1020.117,1535.636,1006.043,1510.828z
						"
                class="fire-loading"
              />
            </g>
            <path
              style="fill: #961b1e"
              d="M1027.733,1498.403c-16.4-28.908-24.561-51.951-30.122-69.048
					c-5.055,2.889-11.09,6.725-13.387,8.871c-13.475,12.584-19.519,21.112,12.614,77.754c32.088,56.563,46.444,54.449,60.269,50.72
					c3.325-0.897,10.264-4.38,15.995-7.66C1060.454,1545.825,1044.124,1527.297,1027.733,1498.403z"
            />
            <path
              style="fill: #db1f26"
              d="M1089.499,1463.484c-13.756-24.248-34.492-68.58-29.766-106.16
					c-23.749,7.488-46.014,16.62-64.741,27.243c-10.906,39.503,12.586,90.244,27.67,116.833s46.585,72.787,86.088,83.693
					c18.727-10.624,37.988-25.05,56.6-41.593C1130.669,1528.275,1103.255,1487.732,1089.499,1463.484z"
            />
            <path
              style="fill: #f57f45"
              d="M1379.456,1298.991c-23.484-41.396-240.196-11.558-342.235,46.329
					c-12.164,44.06,14.038,100.656,30.863,130.313c16.824,29.657,51.96,81.185,96.021,93.349
					C1266.143,1511.095,1402.939,1340.387,1379.456,1298.991z"
            />
            <path
              style="fill: #db1f26"
              d="M1307.449,1339.84c8.115,14.304,21.345,33.978,39.316,50.159
					c27.132-40.248,41.695-75.135,32.69-91.009c-9.005-15.873-46.422-21.272-94.89-18.632
					C1289.235,1304.087,1299.334,1325.536,1307.449,1339.84z"
            />
          </g>
          <g>
            <g>
              <path
                style="fill: #ffc20e"
                d="M526.206,665.002c-17.792-29.662-26.193-52.928-80.577-21.218
						c-15.765,9.196-40.979,33.6-46.776,47.483c9.408-5.495,22.89-14.87,45.1-20.569c-11.794,6.512-28.076,14.493-48.739,38.843
						c-25.879,30.457-28.384,45.875-28.384,45.875s14.519,5.76,53.94-0.828c31.503-5.243,46.706-15.124,58.347-21.907
						c-16.287,16.14-31.251,22.903-40.794,28.159c14.89,2.147,48.773-6.975,64.755-15.789
						C558.199,714.64,542.538,695.492,526.206,665.002z"
                class="fire-loading"
              />
              <path
                style="fill: #f37121"
                d="M526.206,665.002c-14.074-24.808-36.967-24.037-53.689-5.465
						c-16.722,18.572-33.271,54.798-33.271,54.798s39.586,4.38,64.106-0.444C527.872,709.066,540.279,689.81,526.206,665.002z"
                class="fire-loading"
              />
            </g>
            <path
              style="fill: #961b1e"
              d="M547.895,652.577c-16.4-28.908-24.561-51.951-30.122-69.048
					c-5.055,2.889-11.09,6.725-13.387,8.871c-13.475,12.584-19.519,21.111,12.614,77.754c32.088,56.563,46.444,54.449,60.269,50.72
					c3.325-0.897,10.264-4.38,15.996-7.659C580.616,699.999,564.287,681.471,547.895,652.577z"
            />
            <path
              style="fill: #db1f26"
              d="M609.661,617.658c-13.756-24.248-34.492-68.58-29.766-106.16
					c-23.749,7.488-46.014,16.619-64.741,27.243c-10.906,39.503,12.586,90.244,27.67,116.833
					c15.084,26.589,46.586,72.787,86.088,83.693c18.727-10.624,37.988-25.05,56.6-41.593
					C650.832,682.449,623.417,641.906,609.661,617.658z"
            />
            <path
              style="fill: #f57f45"
              d="M899.618,453.165c-23.484-41.396-240.196-11.558-342.235,46.329
					c-12.164,44.06,14.038,100.656,30.863,130.313c16.824,29.657,51.961,81.185,96.021,93.349
					C786.305,665.269,923.102,494.561,899.618,453.165z"
            />
            <path
              style="fill: #db1f26"
              d="M827.611,494.014c8.115,14.304,21.345,33.978,39.316,50.159
					c27.132-40.248,41.695-75.135,32.69-91.009c-9.005-15.873-46.422-21.272-94.89-18.632
					C809.397,458.261,819.497,479.71,827.611,494.014z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style="fill: #ffffff"
                d="M1020.395,898.379c0,0-82.698,34.775-107.866,84.034c-20.168-4.945-44.483-31.597-44.483-31.597
						s45.881-93.63,152.877-133.394C1047.712,835.365,1020.395,898.379,1020.395,898.379z"
              />
              <path
                style="fill: #00b7bb"
                d="M947.819,858.13c4.143,8.771,2.315,20.694-5.464,29.952
						c-9.671,11.51-24.992,14.686-34.939,7.516C918.414,883.4,931.831,870.444,947.819,858.13z"
              />
              <path
                style="fill: #ffffff"
                d="M915.734,1015.074c-2.47,7.206-9.541,8.832-13.405,5.768
						c-5.833-4.626-2.496-13.577-4.792-17.193c-4.645,7.727-9.927,19.908-14.935,26.25c-8.357,10.585-14.062,11.042-17.623,8.633
						c-4.734-3.204-2.701-9.496,10.441-30.351c-1.268,1.624-13.411,17.484-14.495,18.834c-6.109,7.568-12.158,7.928-15.75,4.113
						c-7.375-7.835,15.465-32.085,15.832-32.735c-4.797,2.486-18.393,24.481-27.509,18.944c-3.967-2.41-4.778-9.377,1.574-16.051
						c5.342-5.613,13.208-15.127,14.294-16.27c-2.464-0.392-10.303,13.407-17.066,11.022c-2.394-0.845-3.621-3.805-2.94-6.325
						c4.706-17.404,32.501-37.945,38.684-38.897c6.953-1.071,30.046,6.969,44.954,27.919
						C912.118,976.756,923.691,991.858,915.734,1015.074z"
              />
              <path
                style="fill: #00b7bb"
                d="M913.897,980.828c2.091,3.222,16.609-11.63,14.448-20.021
						c-2.758-10.71-38.695-34.878-49.566-30.416c-6.552,2.689-13.377,16.564-10.733,20.424
						C883.308,956.076,909.435,973.953,913.897,980.828z"
              />
            </g>
            <g>
              <path
                style="fill: #ffffff"
                d="M1213.112,825.348c0,0,88.652-13.749,124.983-55.461c18.375,9.675,35.524,41.414,35.524,41.414
						s-56.624,72.994-144.486,85.66C1207.477,873.073,1213.112,825.348,1213.112,825.348z"
              />
              <path
                style="fill: #00b7bb"
                d="M1349.958,836.084c-11.227,10.278-26.185,22.282-44.308,33.028
						c-3.381-9.17-0.405-20.973,8.324-29.482C1324.931,828.95,1340.843,827.462,1349.958,836.084z"
              />
              <path
                style="fill: #ffffff"
                d="M1342.882,737.419c4.139-6.396,11.394-6.263,14.401-2.356
						c4.542,5.899-0.861,13.777,0.493,17.842c6.375-6.374,14.446-16.917,20.839-21.861c10.669-8.25,16.315-7.315,19.187-4.116
						c3.819,4.253,0.325,9.867-17.469,26.926c1.623-1.269,17.24-13.723,18.619-14.771c7.758-5.866,13.714-4.754,16.277-0.183
						c5.262,9.386-22.764,27.394-23.277,27.936c5.256-1.252,23.766-19.308,31.273-11.731c3.267,3.297,2.369,10.254-5.408,15.194
						c-6.541,4.155-16.474,11.485-17.803,12.331c2.296,0.976,13.239-10.519,19.224-6.569c2.119,1.398,2.594,4.568,1.323,6.848
						c-8.774,15.75-40.711,28.961-46.941,28.391c-7.006-0.642-27.47-14.026-36.871-37.959
						C1337.126,775.474,1329.548,758.023,1342.882,737.419z"
              />
              <path
                style="fill: #00b7bb"
                d="M1336.385,771.093c-1.25-3.632-18.928,7.269-18.86,15.934
						c0.087,11.059,29.115,43.199,40.742,41.497c7.008-1.026,16.985-12.839,15.353-17.223
						C1360.082,802.506,1339.052,778.843,1336.385,771.093z"
              />
            </g>
            <g>
              <g>
                <path
                  style="fill: #ffffff"
                  d="M994.244,942.733c-3.242,15.127-5.667,28.673-7.397,40.826
							c24.81,29.348,59.41,50.758,100.071,58.656c61.297,11.907,121.321-9.799,161.056-52.257
							c-2.926-14.925-5.931-30.549-8.263-44.693c-3.57-41.285-11.175-83.467-25.065-122.737
							c-52.153-9.055-191.383-18.821-191.383-18.821S1005.935,864.638,994.244,942.733z"
                />
                <path
                  style="fill: #00b7bb"
                  d="M1229.756,932.354c-1.397,37.158-46.065,65.598-99.755,63.592
							c-53.707-2.034-96.087-33.766-94.767-70.873c1.404-37.134,46.086-65.587,99.791-63.55
							C1188.749,863.537,1231.154,895.245,1229.756,932.354z"
                />
                <path
                  style="fill: #ffffff"
                  d="M1099.694,909.361c-0.333,8.494-7.825,15.113-16.767,14.768
							c-8.959-0.329-15.965-7.5-15.64-16.015c0.314-8.512,7.822-15.133,16.77-14.777
							C1093.013,893.671,1099.996,900.818,1099.694,909.361z"
                />
                <path
                  style="fill: #ffffff"
                  d="M1200.732,913.151c-0.292,8.493-7.448,15.143-15.922,14.815
							c-8.447-0.313-15.037-7.481-14.748-15.997c0.34-8.497,7.441-15.135,15.916-14.836
							C1194.466,897.477,1201.051,904.636,1200.732,913.151z"
                />
                <path
                  style="fill: #ffffff"
                  d="M1129.446,894.101c-0.261,7.7-6.737,13.73-14.403,13.43
							c-7.666-0.292-13.634-6.763-13.331-14.454c0.278-7.733,6.722-13.745,14.384-13.439
							C1123.747,879.914,1129.753,886.386,1129.446,894.101z"
                />
                <path
                  style="fill: #ffffff"
                  d="M1166.988,895.526c-0.309,7.674-6.759,13.693-14.421,13.405
							c-7.667-0.27-13.647-6.761-13.356-14.46c0.284-7.717,6.756-13.712,14.402-13.421
							C1161.308,881.32,1167.295,887.801,1166.988,895.526z"
                />
                <path
                  d="M1160.374,938.134c-0.14,3.943-3.45,7.003-7.404,6.883l-41.538-1.565c-3.937-0.142-6.984-3.472-6.854-7.389
							l0.13-2.081c0.164-3.901,3.461-6.997,7.38-6.868l41.566,1.559c3.923,0.149,7.017,3.453,6.865,7.383L1160.374,938.134z"
                />
                <path
                  d="M1159.393,965.157c-0.14,3.943-3.45,7.003-7.404,6.883l-41.538-1.565c-3.937-0.142-6.984-3.472-6.854-7.389
							l0.13-2.081c0.164-3.901,3.461-6.997,7.38-6.868l41.566,1.559c3.923,0.149,7.017,3.453,6.865,7.383L1159.393,965.157z"
                />
                <path
                  style="fill: #838484"
                  d="M1235.724,910.51l2.758,22.04c0,0,22.169,16.052,3.491,16.97
							c-20.659,1.015-38.093-20.218-45.907-40.641c-5.163-10.521-27.366-7.199-17.729,12.759
							c6.331,13.111,26.347,47.083,59.214,50.392c28.712,2.891,43.833-15.097,32.584-35.193
							C1259.693,918.248,1235.724,910.51,1235.724,910.51z"
                />
                <path
                  style="fill: #838484"
                  d="M1001.585,902.015l-4.348,21.782c0,0-22.988,12.71-4.712,16.672
							c16.303,3.531,67.074-21.969,83.521-38.254c10.788-9.05,26.098,4.03,11.824,18.657c-18.952,16.057-64.817,41.98-93.667,42.01
							c-28.857,0.047-41.523-17.874-28.847-37.102C977.119,907.995,1001.585,902.015,1001.585,902.015z"
                />
                <path
                  style="fill: #00b7bb"
                  d="M1227.714,818.723c-0.815,21.151-47.848,36.548-105.019,34.434
							c-57.151-2.146-102.865-21.049-102.061-42.197c0.791-21.206,47.803-36.62,104.95-34.522
							C1182.779,778.618,1228.481,797.547,1227.714,818.723z"
                />
              </g>
            </g>
            <g>
              <path
                style="fill: #ffffff"
                d="M1283.554,632.453c5.781,34.799,32.111,59.446,58.821,55.004
						c26.708-4.418,43.639-36.282,37.831-71.075c-5.773-34.849-32.117-59.497-58.815-55.057
						C1294.721,565.789,1277.745,597.616,1283.554,632.453z"
              />
              <path
                style="fill: #00b7bb"
                d="M1269.677,636.06c6.894,41.584,32.054,72.09,56.101,68.096
						c24.045-4.015,37.974-40.99,31.065-82.613c-6.942-41.591-32.048-72.101-56.135-68.068
						C1276.654,557.449,1262.754,594.454,1269.677,636.06z"
              />
              <path
                style="fill: #ffffff"
                d="M1178.478,833.574c113.25-18.84,175.344-113.964,158.45-215.772
						c-16.949-101.812-122.479-169.064-235.749-150.224c-113.245,18.808-191.317,116.618-174.439,218.425
						C943.714,787.81,1065.217,852.411,1178.478,833.574z"
              />
              <path
                style="fill: #00b7bb"
                d="M1119.328,446.004c39.144-4.874,58.917,2.829,58.13,22.779
						c-5.902,34.758-12.82,39.136-19.87,43.819c-2.555,1.698-12.315,5.361-25.824,7.625c-13.509,2.263-23.93,1.981-26.899,1.209
						c-8.191-2.13-17.873-7.91-34.783-38.846C1062.836,463.986,1087.108,450.016,1119.328,446.004z"
              />
              <path
                style="fill: #00b7bb"
                d="M982.985,636.134c1.193-45.005,27.532-74.393,65.237-87.31
						c23.847-8.17,47.03-14.76,80.598-20.419l0-0.001c0.108-0.018,0.213-0.035,0.32-0.053s0.212-0.036,0.32-0.054l0,0.001
						c33.579-5.592,57.644-6.918,82.852-6.968c39.856-0.079,74.336,19.119,90.134,61.277
						c16.479,43.977,20.014,86.752,14.449,123.105c-10.919,71.327-95.713,98.882-139.878,106.408l0,0.003
						c-0.105,0.018-0.215,0.034-0.32,0.052s-0.215,0.037-0.32,0.055l0-0.003c-44.206,7.281-133.351,8.871-166.923-55.002
						C992.342,724.672,981.74,683.08,982.985,636.134z"
              />
              <path
                d="M996.415,638.831c1.099-41.448,25.356-68.514,60.081-80.41c21.962-7.524,43.313-13.594,74.228-18.805l0-0.001
						c0.099-0.017,0.196-0.032,0.295-0.049c0.099-0.016,0.196-0.033,0.295-0.05l0,0.001c30.925-5.15,53.088-6.371,76.303-6.417
						c36.706-0.073,68.461,17.608,83.01,56.434c15.177,40.501,18.432,79.896,13.307,113.376
						c-10.056,65.69-88.149,91.067-128.823,97.998l0,0.003c-0.097,0.016-0.198,0.032-0.295,0.048
						c-0.097,0.016-0.198,0.034-0.295,0.051l0-0.003c-40.712,6.706-122.812,8.17-153.731-50.655
						C1005.033,720.371,995.269,682.067,996.415,638.831z"
              />
              <path
                style="fill: #8c8c8c"
                d="M1011.769,671.368c-0.616-7.981-0.835-16.188-0.612-24.596
						c1.034-38.996,23.856-64.46,56.526-75.652c6.094-2.088,12.144-4.053,18.385-5.916
						C1047.047,587.132,1019.558,622.689,1011.769,671.368z"
              />
              <path
                style="fill: #2a2a2a"
                d="M1151.589,705.963c60.361,10.108,108.519-13.01,121.959-55.912
						c13.415-42.906-0.972-91.521-61.34-101.637c-60.352-10.122-132.173,18.642-145.62,61.541
						C1053.184,652.865,1091.221,695.847,1151.589,705.963z"
              />
              <path
                style="fill: #838484"
                d="M1207.728,611.676c18.089,4.722,37.352,0.22,43.031-10.055
						c5.672-10.277-4.392-22.435-22.483-27.159c-18.086-4.725-37.348-0.221-43.03,10.052
						C1179.578,594.792,1189.637,606.952,1207.728,611.676z"
              />
              <path
                style="fill: #ffffff"
                d="M1150.397,591.813c8.74,0.895,17.363-2.577,19.264-7.756c1.897-5.18-3.649-10.105-12.39-11.001
						c-8.739-0.897-17.362,2.576-19.264,7.755C1136.112,585.991,1141.656,590.917,1150.397,591.813z"
              />
              <path
                style="fill: #838484"
                d="M1258.74,752.107c5.684-1.795,10.12-6.279,9.909-10.016c-0.213-3.736-4.992-5.311-10.677-3.516
						c-5.683,1.794-10.119,6.278-9.909,10.015C1248.277,752.327,1253.056,753.902,1258.74,752.107z"
              />
              <path
                style="fill: #838484"
                d="M1028.96,699.805c-0.302-3.898-2.508-7.398-4.926-7.819c-2.419-0.419-4.134,2.4-3.833,6.299
						c0.301,3.897,2.507,7.397,4.926,7.818C1027.546,706.522,1029.262,703.703,1028.96,699.805z"
              />
              <path
                style="fill: #ffffff"
                d="M1083.627,779.465c10.089,2.826,20.756-0.603,23.829-7.659
						c3.069-7.057-2.62-15.069-12.71-17.896c-10.087-2.828-20.754,0.602-23.828,7.658
						C1067.85,768.625,1073.537,776.638,1083.627,779.465z"
              />
              <path
                style="fill: #00b7bb"
                d="M971.759,681.061c7.174,43.155,7.898,82.975-17.071,87.113
						c-24.974,4.172-51.04-27.477-58.217-70.659c-7.166-43.203,7.269-81.546,32.221-85.718
						C953.664,607.62,964.601,637.881,971.759,681.061z"
              />
              <path
                style="fill: #ffffff"
                d="M947.491,685.505c6.19,37.232,2.99,72.226-18.552,75.796
						c-21.546,3.599-61.387-5.264-70.16-57.621c-9.136-54.526,26.205-73.693,47.732-77.293
						C928.056,622.785,941.316,648.252,947.491,685.505z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "LoadingSvg",
};
</script>
<style scoped>
@keyframes FireAnimationPulse {
  0% {
    transform: scaleY(0.99);
    opacity: 0.8;
  }
  50% {
    transform: scaleY(1);
    opacity: 1;
  }
  100% {
    transform: scaleY(0.99);
    opacity: 0.8;
  }
}

@keyframes FireAnimation {
  0% {
    fill: #ffc20e;
  }
  50% {
    fill: #ff8c00;
  }
  100% {
    fill: #ffc20e;
  }
}

@keyframes AssetsAnimation {
  0% {
    transform: translateY(-50%);
    /* opacity: 0; */
  }
  25% {
    transform: translateY(-2%);
    opacity: 1;
  }
  75% {
    transform: translateY(2%);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    /* opacity: 0; */
  }
}

.fire-loading {
  animation: FireAnimationPulse 0.3s infinite ease-in-out,
    FireAnimation 2s infinite alternate;
}
 
@keyframes rain {
  0% {
    opacity: 0;
    transform: translateY(-100vh) ;
  }
  50% {
    opacity: 1;
    transform: translateY(0) ;
  }
  100% {
    opacity: 0;
    transform: translateY(100vh) ;
  }
}

.assets {
  animation: rain 2s linear infinite;
  animation-delay: calc(var(--delay, 0) * 1s);
}

.assets:nth-child(3n) {
  --delay: 0;
}
.assets:nth-child(3n + 1) {
  --delay: 2;
}
.assets:nth-child(3n + 2) {
  --delay: 4;
}
.assets:nth-child(5n) {
  --delay: 1;
}
.assets:nth-child(5n + 1) {
  --delay: 3;
}
.assets:nth-child(7n) {
  --delay: 5;
}
.assets:nth-child(7n + 1) {
  --delay: 6;
}
</style>
