<template>
  <!-- Placements -->
  <div class="placements ad-set-details-card mb-6 fs-14">
    <h6 class="page-header-title mb-3">Placements</h6>
    <v-card
      max-width="100%"
      class="pa-5 mb-5 rounded-lg"
      style="overflow: visible"
      elevation="1"
    >
      <div class="card-body">
        <div class="placements tree-select">
          <!-- automatic_placement  -->
          <div class="radio-button">
            <input
              v-model="placement_type"
              value="automatic_placement"
              name="placement_type"
              id="automatic_placement"
              class="radio-button__input"
              type="radio"
            />
            <label for="automatic_placement" class="radio-button__label">
              <span class="radio-button__custom"></span>
              <span class="d-block">Automatic Placement (Recommended)</span>
              <span class="d-block small-text">
                Run your ads across all of Snapchat. This gives more flexibility
                to improve the results of your campaign and maximize your reach.
              </span>
            </label>
          </div>
          <!-- edit_placement  -->
          <div class="radio-button">
            <input
              v-model="placement_type"
              value="edit_placement"
              name="placement_type"
              id="edit_placement"
              class="radio-button__input"
              type="radio"
            />
            <label for="edit_placement" class="radio-button__label">
              <span class="radio-button__custom"></span>
              <span class="d-block">Edit Placement</span>
            </label>
          </div>
        </div>
        <!-- edit_placement_options  -->
        <div
          v-if="placement_type == 'edit_placement'"
          class="edit-placement-selections"
        >
          <div class="edit-placement-selections-tree tree-select ml-7">
            <!-- content  -->
            <div class="radio-button">
              <input
                v-model="edit_placement_type"
                value="content"
                name="edit_placement_content"
                id="edit_placement_content"
                class="radio-button__input"
                type="radio"
              />
              <label for="edit_placement_content" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">content</span>
              </label>
              <!-- content options  -->
              <div v-if="edit_placement_type == 'content'" class="fs-14 ml-7">
                <!-- between content  -->
                <div class="">
                  <div class="d-flex align-center justify-start ga-2 mb-3">
                    <label class="snap-checkbox">
                      <input v-model="between_content" type="checkbox" />
                      <span class="snap-checkmark"></span>Between content
                      <span class="text-muted small-text"
                        >(Ads watched between stories and professional
                        content)</span
                      >
                    </label>
                  </div>
                  <!-- between options  -->
                  <div class="between-options ml-7 d-flex flex-column ga-3">
                    <div class="d-flex align-center justify-start ga-2">
                      <label class="snap-checkbox">
                        <input
                          v-model="between_content_user_stories"
                          type="checkbox"
                        />
                        <span class="snap-checkmark"></span>User Stories
                      </label>
                    </div>
                    <div class="d-flex align-center justify-start ga-2">
                      <label class="snap-checkbox">
                        <input
                          v-model="
                            between_content_publisher_and_creator_stories
                          "
                          type="checkbox"
                        />
                        <span class="snap-checkmark"></span>Publisher & Creator
                        Stories
                      </label>
                    </div>
                    <div class="d-flex align-center justify-start ga-2">
                      <label class="snap-checkbox">
                        <input
                          v-model="between_content_spotlight"
                          type="checkbox"
                        />
                        <span class="snap-checkmark"></span>Spotlight
                      </label>
                    </div>
                  </div>
                </div>
                <!-- within content -->
                <div class="">
                  <div class="d-flex align-center justify-start ga-2 mt-3">
                    <label class="snap-checkbox">
                      <input v-model="within_content" type="checkbox" />
                      <span class="snap-checkmark"></span>Within content
                      <span class="text-muted small-text"
                        >(Ads watched within professional, creator & curated
                        content)</span
                      >
                    </label>
                  </div>
                  <!-- within options  -->
                  <div class="within-options ml-7">
                    <!-- within publisher stories  -->
                    <div class="publisher-stories">
                      <div class="d-flex align-center justify-start ga-2 mt-5">
                        <label class="snap-checkbox">
                          <input
                            v-model="within_content_publisher_stories"
                            type="checkbox"
                          />
                          <span class="snap-checkmark"></span>Publisher Stories
                        </label>
                      </div>
                      <div class="ml-7 mt-3">
                        <div class="fs-14 mb-3">Categories</div>
                        <div class="d-1-3 ga-2">
                          <v-select
                            variant="solo"
                            class="snap-select-style"
                            hide-details
                            v-model="
                              publisher_stories_selected_categories_types
                            "
                            :items="publisherStoriesCategoriesTypes"
                          ></v-select>
                          <v-select
                            variant="solo"
                            class="snap-select-style"
                            hide-details
                            v-model="
                              publisher_stories_selected_categories_single
                            "
                            :items="publisherStoriesCategories"
                            item-value="value"
                            item-title="title"
                          ></v-select>
                        </div>
                        <div class="d-flex flex-column ga-2 my-3">
                          <div
                            v-for="(
                              category, index
                            ) in publisher_stories_selected_categories"
                            :key="category.value"
                            class="d-flex align-center ga-2 my-1"
                          >
                            <svg
                              v-if="category.type === 'include'"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="#39CA8E"
                              xmlns="http://www.w3.org/2000/svg"
                              class="sds-icon sds-icon-check styles-im1iax"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                              ></path>
                            </svg>
                            <svg
                              v-if="category.type === 'exclude'"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="#DC2E4B"
                              xmlns="http://www.w3.org/2000/svg"
                              class="sds-icon sds-icon-cancelled styles-im1iax"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.5 8C12.5 10.4853 10.4853 12.5 8 12.5C7.04273 12.5 6.15527 12.2011 5.42586 11.6915L11.6453 5.36098C12.183 6.10236 12.5 7.01416 12.5 8ZM4.3557 10.6405L10.5756 4.30949C9.84591 3.7993 8.95791 3.5 8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 8.98648 3.81742 9.89882 4.3557 10.6405ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                              ></path>
                            </svg>
                            <span>{{ category.value }}</span>
                            <svg
                              @click="removePublisherStoreisCategory(index)"
                              class="cursor-pointer"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="#33bbff"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- within creator stories  -->
                    <div class="creator-stories mt-5">
                      <div class="d-flex align-center justify-start ga-2">
                        <label class="snap-checkbox">
                          <input
                            v-model="within_content_creator_stories"
                            type="checkbox"
                          />
                          <span class="snap-checkmark"></span>Creator Stories
                        </label>
                      </div>
                      <div class="ml-7 mt-3">
                        <div class="fs-14 mb-3">Categories</div>
                        <div class="d-1-3 ga-2">
                          <v-select
                            variant="solo"
                            class="snap-select-style"
                            hide-details
                            v-model="creator_stories_selected_categories_types"
                            :items="creatorStoriesCategoriesTypes"
                          ></v-select>
                          <v-select
                            variant="solo"
                            class="snap-select-style"
                            hide-details
                            v-model="creator_stories_selected_categories_single"
                            :items="creatorStoriesCategories"
                          ></v-select>
                        </div>
                      </div>
                      <div class="d-flex flex-column ga-2 my-3">
                        <div
                          v-for="(
                            category, index
                          ) in creator_stories_selected_categories"
                          :key="category.value"
                          class="d-flex align-center ga-2 my-1"
                        >
                          <svg
                            v-if="category.type === 'include'"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="#39CA8E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="sds-icon sds-icon-check styles-im1iax"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                            ></path>
                          </svg>
                          <svg
                            v-if="category.type === 'exclude'"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="#DC2E4B"
                            xmlns="http://www.w3.org/2000/svg"
                            class="sds-icon sds-icon-cancelled styles-im1iax"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.5 8C12.5 10.4853 10.4853 12.5 8 12.5C7.04273 12.5 6.15527 12.2011 5.42586 11.6915L11.6453 5.36098C12.183 6.10236 12.5 7.01416 12.5 8ZM4.3557 10.6405L10.5756 4.30949C9.84591 3.7993 8.95791 3.5 8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 8.98648 3.81742 9.89882 4.3557 10.6405ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                            ></path>
                          </svg>
                          <span>{{ category.value }}</span>
                          <svg
                            @click="removeCreatorStoreisCategory(index)"
                            class="cursor-pointer"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="#33bbff"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- within content -->
                <div class="mt-5" v-if=" selected_objective !== 'Traffic'">
                  <div class="d-flex align-center justify-start ga-2">
                    <label class="snap-checkbox">
                      <input v-model="discover_feed" type="checkbox" />
                      <span class="snap-checkmark"></span>Discover Feed
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- in stream  -->
            <div
              v-if="
                (conversion_location === 'WEB' &&
                  selected_objective === 'Sales') ||
                selected_objective === 'App Promotion' ||
                selected_objective === 'Awareness & Engagement'
              "
              class="radio-button"
            >
              <input
                v-model="edit_placement_type"
                value="in_stream"
                name="edit_placement_in_stream"
                id="edit_placement_in_stream"
                class="radio-button__input"
                type="radio"
              />
              <label for="edit_placement_in_stream" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">In Stream</span>
              </label>
              <div
                v-if="edit_placement_type === 'in_stream'"
                class="radio-button ml-7"
              >
                <input
                  v-model="in_stream_all_show"
                  value="in_stream_all_show"
                  name="in_stream_all_show"
                  id="in_stream_all_show"
                  class="radio-button__input"
                  type="radio"
                />
                <label for="in_stream_all_show" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">All Show</span>
                </label>
                <div class="d-flex align-center justify-start ga-2 ml-7">
                  <label class="snap-checkbox">
                    <input v-model="exclude_news_show" type="checkbox" />
                    <span class="snap-checkmark"></span>Exclude News Shows
                  </label>
                </div>
              </div>
            </div>
            <!-- camera  -->
            <div
              v-if="
                conversion_location === 'WEB' &&
                (selected_objective === 'Sales' ||
                  selected_objective === 'Traffic' ||
                  selected_objective === 'App Promotion' ||
                  selected_objective === 'Awareness & Engagement')
              "
              class="radio-button"
            >
              <input
                v-model="edit_placement_type"
                value="camera"
                name="edit_placement_camera"
                id="edit_placement_camera"
                class="radio-button__input"
                type="radio"
              />
              <label for="edit_placement_camera" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">Camera</span>
              </label>
            </div>
            <div v-if="edit_placement_type == 'camera'" class="ml-7">
              <div class="d-flex align-center justify-start ga-2">
                <label class="snap-checkbox">
                  <input v-model="camera_ar_lens" type="checkbox" />
                  <span class="snap-checkmark"></span>AR lens
                </label>
              </div>
            </div>
            <div v-if="edit_placement_type == 'camera'" class="ml-7">
              <div class="d-flex align-center justify-start ga-2">
                <label class="snap-checkbox">
                  <input v-model="camera_ar_filters" type="checkbox" />
                  <span class="snap-checkmark"></span>AR Filters
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more-options-parent">
        <button type="button" class="more-options mb-2" @click="moreOptionFun">
          <p v-if="!moreOptionsBtn">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#0894fa"
                width="13"
                height="13"
                viewBox="0 0 512 512"
              >
                <path
                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                />
              </svg>
            </span>
            <span> More </span>
          </p>
          <p v-else>
            <span>
              <svg
                fill="#0894fa"
                width="13"
                height="13"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                />
              </svg>
            </span>
            <span> Less </span>
          </p>
        </button>
        <div
          v-show="moreOptions"
          class="more-options-contenant d-none px-1 mt-4"
          ref="moreOptionsContenant"
        >
          <span class="fs-14">Brand Safety Filter</span>
          <div class="select-with-caption mt-3">
            <div
              :class="
                'option ' +
                brand_safety_filter_active_fully +
                ' tree-select mb-3'
              "
            >
              <div class="radio-button">
                <input
                  v-model="brand_safety_filter"
                  value="full_inventory"
                  name="full_inventory"
                  id="full_inventory"
                  class="radio-button__input"
                  type="radio"
                />
                <label for="full_inventory" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">Full Inventory</span>
                  <span class="d-block small-text">
                    Show ads near any Snapchat content to maximize reach.
                    Content still adheres to Snap's Community Guidelines and
                    content moderation rules.
                  </span>
                </label>
              </div>
            </div>
            <div
              :class="
                'option ' + brand_safety_filter_active_limited + ' tree-select'
              "
            >
              <div class="radio-button">
                <input
                  :disabled="edit_placement_type == 'camera'"
                  v-model="brand_safety_filter"
                  value="limited_inventory"
                  name="limited_inventory"
                  id="limited_inventory"
                  class="radio-button__input"
                  type="radio"
                />
                <label for="limited_inventory" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">Limited Inventory</span>
                  <span class="d-block small-text">
                    Show ads near content that has passed additional moderation
                    filters. This may decrease reach and increase costs. Only
                    available for Creator Stories right now.
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      placement_type:
        localStorage.getItem("placement_type") || "automatic_placement",
      edit_placement_type:
        localStorage.getItem("edit_placement_type") || "content",
      between_content: true,
      between_content_user_stories: true,
      between_content_publisher_and_creator_stories: true,
      between_content_spotlight: true,
      within_content: true,
      within_content_publisher_stories: true,
      publisher_stories_selected_categories_types: localStorage.getItem(
        "publisher_stories_selected_categories_types"
      ),
      publisherStoriesCategoriesTypes: ["include", "exclude"],
      publisher_stories_selected_categories_single: "", 
      publisher_stories_selected_categories:
        JSON.parse(
          localStorage.getItem("publisher_stories_selected_categories")
        ) || [],
      publisherStoriesCategories: [
        {
          title: "News",
          value: "NEWS",
        },
        {
          title: "Entertainment",
          value: "ENTERTAINMENT",
        },
        {
          title: "Science & Technology",
          value: "SCIENCE_TECHNOLOGY",
        },
        {
          title: "Beauty & Fashion",
          value: "BEAUTY_FASHION",
        },
        {
          title: "Mens Lifestyle",
          value: "MENS_LIFESTYLE",
        },
        {
          title: "Womens Lifestyle",
          value: "WOMENS_LIFESTYLE",
        },
        {
          title: "Gaming",
          value: "GAMING",
        },
        {
          title: "General Lifestyle",
          value: "GENERAL_LIFESTYLE",
        },
        {
          title: "Food",
          value: "FOOD",
        },
        {
          title: "Sports",
          value: "SPORTS",
        },
        {
          title: "Young & Bold",
          value: "YOUNG_BOLD",
        },
      ],
      within_content_creator_stories: true,
      creator_stories_selected_categories_types: localStorage.getItem(
        "creator_stories_selected_categories_types"
      ),
      creatorStoriesCategoriesTypes: ["include", "exclude"],
      creator_stories_selected_categories_single: "",
      creator_stories_selected_categories:
        JSON.parse(
          localStorage.getItem("creator_stories_selected_categories")
        ) || [],
      creatorStoriesCategories: [
        "Lifestyle",
        "Sports",
        "Music",
        "Others",
        "Comedy",
        "Entertainment",
        "Family",
        "News and current events",
        "Technology",
        "Fashion",
        "Travel",
      ],
      moreOptionsBtn: false,
      brand_safety_filter_active_fully: "active",
      brand_safety_filter:
        localStorage.getItem("brand_safety_filter") || "full_inventory",
      brand_safety_filter_active_limited: "",
      moreOptions: true,
      camera_ar_lens: true,
      camera_ar_filters: true,
      discover_feed: true,
      conversion_location: localStorage.getItem("direct_traffic_location"),
      selected_objective: localStorage.getItem("selectedOption"),
      in_stream_all_show: localStorage.getItem("in_stream_all_show") === "true",
      exclude_news_show: localStorage.getItem("exclude_news_show") === "true",
    };
  },
  methods: {
    moreOptionFun() {
      this.moreOptionsBtn = !this.moreOptionsBtn;
      if (
        this.$refs.moreOptionsContenant.classList.contains("hide-more-option")
      ) {
        this.$refs.moreOptionsContenant.classList.add("d-none");
      } else if (
        !this.$refs.moreOptionsContenant.classList.contains("hide-more-option")
      ) {
        this.$refs.moreOptionsContenant.classList.toggle("d-none");
      }
    },
    getBooleanFromLocalStorage(key, defaultValue = false) {
      const value = localStorage.getItem(key);
      return value !== null ? (value === "true" ? true : false) : defaultValue;
    },
    removePublisherStoreisCategory(index) {
      this.publisher_stories_selected_categories.splice(index, 1);
      localStorage.setItem(
        "publisher_stories_selected_categories",
        JSON.stringify(this.publisher_stories_selected_categories)
      );
    },
    removeCreatorStoreisCategory(index) {
      this.creator_stories_selected_categories.splice(index, 1);
      localStorage.setItem(
        "creator_stories_selected_categories",
        JSON.stringify(this.creator_stories_selected_categories)
      );
    },
  },
  watch: {
    publisher_stories_selected_categories_single(val) {
      this.publisher_stories_selected_categories.push({
        type: this.publisher_stories_selected_categories_types,
        value: val,
      });
      localStorage.setItem(
        "publisher_stories_selected_categories",
        JSON.stringify(this.publisher_stories_selected_categories)
      );
    },
    creator_stories_selected_categories_single(val) {
      this.creator_stories_selected_categories.push({
        type: this.creator_stories_selected_categories_types,
        value: val,
      });
      localStorage.setItem(
        "creator_stories_selected_categories",
        JSON.stringify(this.creator_stories_selected_categories)
      );
    },
    between_content(val) {
      localStorage.setItem("between_content", val);
      this.between_content_user_stories = val;
      this.between_content_publisher_and_creator_stories = val;
      this.between_content_spotlight = val;
    },
    within_content(val) {
      localStorage.setItem("within_content", val);
      this.within_content_publisher_stories = val;
      this.within_content_creator_stories = val;
    },
    exclude_news_show(val) {
      localStorage.setItem("exclude_news_show", val);
    },
    in_stream_all_show(val) {
      localStorage.setItem("in_stream_all_show", val);
    },
    placement_type(value) {
      localStorage.setItem("placement_type", value);
    },
    edit_placement_type(val) {
      localStorage.setItem("edit_placement_type", val);
    },
    between_content_user_stories(val) {
      localStorage.setItem("between_content_user_stories", val);
    },
    between_content_publisher_and_creator_stories(val) {
      localStorage.setItem(
        "between_content_publisher_and_creator_stories",
        val
      );
    },
    between_content_spotlight(val) {
      localStorage.setItem("between_content_spotlight", val);
    },

    within_content_publisher_stories(val) {
      localStorage.setItem("within_content_publisher_stories", val);
    },
    publisher_stories_selected_categories_types(val) {
      localStorage.setItem("publisher_stories_selected_categories_types", val);
    },
    publisher_stories_selected_categories(val) {
      localStorage.setItem(
        "publisher_stories_selected_categories",
        JSON.stringify(val)
      );
    },
    within_content_creator_stories(val) {
      localStorage.setItem("within_content_creator_stories", val);
    },
    creator_stories_selected_categories_types(val) {
      localStorage.setItem("creator_stories_selected_categories_types", val);
    },
    creator_stories_selected_categories(val) {
      localStorage.setItem(
        "creator_stories_selected_categories",
        JSON.stringify(val)
      );
    },
    brand_safety_filter(val) {
      if (this.brand_safety_filter == "full_inventory") {
        this.brand_safety_filter_active_fully = "active";
        this.brand_safety_filter_active_limited = "";
      } else {
        this.brand_safety_filter_active_fully = "";
        this.brand_safety_filter_active_limited = "active";
      }
      localStorage.setItem("brand_safety_filter", val);
    },
    camera_ar_lens(val) {
      localStorage.setItem("camera_ar_lens", val);
    },
    camera_ar_filters(val) {
      localStorage.setItem("camera_ar_filters", val);
    },
    discover_feed(val) {
      localStorage.setItem("discover_feed", val);
    },
  },
  mounted() {
    if (localStorage.getItem("between_content_user_stories")) {
      this.between_content_user_stories =
        localStorage.getItem("between_content_user_stories") === "true";
    } else {
      this.between_content_user_stories = true;
      localStorage.setItem(
        "between_content_user_stories",
        this.between_content_user_stories
      );
    }
    if (localStorage.getItem("between_content_publisher_and_creator_stories")) {
      this.between_content_publisher_and_creator_stories =
        localStorage.getItem(
          "between_content_publisher_and_creator_stories"
        ) === "true";
    } else {
      this.between_content_publisher_and_creator_stories = true;
      localStorage.setItem(
        "between_content_publisher_and_creator_stories",
        this.between_content_publisher_and_creator_stories
      );
    }

    if (localStorage.getItem("between_content_spotlight")) {
      this.between_content_spotlight =
        localStorage.getItem("between_content_spotlight") === "true";
    } else {
      this.between_content_spotlight = true;
      localStorage.setItem(
        "between_content_spotlight",
        this.between_content_spotlight
      );
    }

    if (localStorage.getItem("within_content")) {
      this.within_content = localStorage.getItem("within_content") === "true";
    } else {
      this.within_content = true;
      localStorage.setItem("within_content", this.within_content);
    }

    if (localStorage.getItem("within_content_publisher_stories")) {
      this.within_content_publisher_stories =
        localStorage.getItem("within_content_publisher_stories") === "true";
    } else {
      this.within_content_publisher_stories = true;
      localStorage.setItem(
        "within_content_publisher_stories",
        this.within_content_publisher_stories
      );
    }

    if (localStorage.getItem("within_content_creator_stories")) {
      this.within_content_creator_stories =
        localStorage.getItem("within_content_creator_stories") === "true";
    } else {
      this.within_content_creator_stories = true;
      localStorage.setItem(
        "within_content_creator_stories",
        this.within_content_creator_stories
      );
    }

    if (localStorage.getItem("camera_ar_lens")) {
      this.camera_ar_lens = localStorage.getItem("camera_ar_lens") === "true";
    } else {
      this.camera_ar_lens = true;
      localStorage.setItem("camera_ar_lens", this.camera_ar_lens);
    }

    if (localStorage.getItem("camera_ar_filters")) {
      this.camera_ar_filters =
        localStorage.getItem("camera_ar_filters") === "true";
    } else {
      this.camera_ar_filters = true;
      localStorage.setItem("camera_ar_filters", this.camera_ar_filters);
    }

    if (localStorage.getItem("discover_feed")) {
      this.discover_feed = localStorage.getItem("discover_feed") === "true";
    } else {
      this.discover_feed = true;
      localStorage.setItem("discover_feed", this.discover_feed);
    }
    if (localStorage.getItem("between_content")) {
      this.between_content = localStorage.getItem("between_content") === "true";
    } else {
      this.between_content = true;
      localStorage.setItem("between_content", this.between_content);
    }
  },
};
</script>
