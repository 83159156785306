<template>
  <v-container fluid class="pa-0 ma-0 boost-post">
    <v-row no-gutters class="h-100">
      <v-col cols="12">
        <GlobalNav></GlobalNav>
      </v-col>
      <v-col cols="12" class="d-flex h-100">
        <SideBar />
        <v-container class="boost-post-contain">
          <v-row no-gutters>
            <v-col cols="12">
              <BreadCrumb :linksData="linksData" class="mb-5" />
            </v-col>
            <v-col cols="6" md="6" sm="6" class="boost-header-title mb-2">
              <div class="header-title d-flex align-center ga-1">
                <button
                  v-html="svgIcon"
                  class="d-flex"
                  @click="backPublishPost"
                ></button>
                <p>{{ $t("boost_post") }}</p>
              </div>
            </v-col>
            <v-col cols="3" md="3" sm="6"></v-col>
            <v-col cols="3" md="3" sm="6" class="d-flex justify-end" >
              <v-menu open-on-hover>
                <template v-slot:activator="{ props }">
                  <v-btn elevation="0" exact="true" flat="true" color="info" v-bind="props">
                    {{ $t('ad_account') }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="store.setAdAccountStorage(item)"
                    v-for="(item, index) in store.adAccountItems"
                    :key="index"
                    :value="index"
                  >
                    <v-list-item-title>
                      <p>{{ item.account_id }}</p>
                      <p>{{ item.business?.name ?? item.name }} </p>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
         
          </v-row>
          <v-row no-gutters class="boost-post-data">
            <v-col cols="8" class="">
              <TapsPlatform />
            </v-col>
            <v-col cols="4" class="pa-2">
              <ReviewPosts />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SideBar from "@/components/layout/SideBar.vue";
import GlobalNav from "@/components/layout/GlobalNav.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import TapsPlatform from "@/components/boost-post/TapsPlatform.vue";
import ReviewPosts from "@/components/publish_post_components/ReviewPosts.vue";
import { boostPost } from "@/store/publishPost/boostPost";
export default {
  components: { GlobalNav, SideBar, BreadCrumb, TapsPlatform, ReviewPosts },
  data() {
    return {
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Publish Post", link: "" },
      ],
      svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="rgba(31, 102, 106, 1)"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/></svg>`,
      store: boostPost(),
    };
  },
  methods: {
    backPublishPost() {
      this.$router.push({ name: "PublishPost" });
    },
  },
  mounted() {
    this.store.getAdAcountId();
  },
};
</script>
