<template>
  <div class="pa-4 dashboard-card" elevation="2">
    <div class=" ">
      <span class="text-subtitle-1 font-weight-bold">Booked Leads</span>
   
    </div>
    <div class="d-flex justify-center align-center p-3">
      <div style="width: 25%" class="d-flex flex-column align-center">
        <div class="w-100" style="width: 160px; height: 160px">
          <Doughnut
            ref="doughnutChart"
            :data="chartData"
            :options="chartOptions"
          />
        </div>
        <div class="text-caption text-bold text-subtitle-1">
          Number of booked leads
        </div>
        <div class="text-bold text-h4 mt-4">{{ data.total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";

export default {
  components: {
    Doughnut,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      new_leads_filter: "",
      filter_days: [
        { title: "Last Day", value: "last_day" },
        { title: "Last Week", value: "last_week" },
        { title: "Last Month", value: "last_month" },
        { title: "Last Year", value: "last_year" },
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        rotation: -120,
        cutout: "75%",
        plugins: {
          legend: {
            position: "top",
          },
          centerText: {
            enabled: true,
            text: this.data.value,
            color: "#1A1A1A",
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                let label = context.label || "";
                if (label) {
                  label += ": ";
                }
                if (context.raw !== undefined) {
                  label += `${context.raw} %`;
                }
                return label;
              },
            },
          },
        },
        elements: {
          arc: {
            borderRadius: 10,
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      return {
        datasets: [
          {
            label: "Booked Leads",
            data: [this.data.value, 100 - this.data.value],
            backgroundColor: ["#1F666A", "#F4F5F9"],
            hoverBackgroundColor: ["#1F666A", "#F4F5F9"],
          },
        ],
      };
    },
  },
  watch: {
    data: {
      deep: true,
      handler(newValue) {
        const newChartData = {
          datasets: [
            {
              label: "Booked Leads",
              data: [newValue.value, 100 - newValue.value],
              backgroundColor: ["#1F666A", "#F4F5F9"],
              hoverBackgroundColor: ["#1F666A", "#F4F5F9"],
            },
          ],
        };

        this.chartOptions.plugins.centerText.text = newValue.value;

        if (this.$refs.doughnutChart) {
          this.$refs.doughnutChart.data = newChartData;
        }
      },
    },
  },
};
</script>
