<template>
  <v-col :cols="12" :lg="6" class="d-flex ga-5 align-center">
    <div class="d-flex ga-1 align-center input-data-text">
      <StatusIcon />
      <label class="calender-input-label text-capitalize" for="status"
        >status</label
      >
    </div>

    <div
      :style="{ backgroundColor: store.status.color }"
      class="status-input d-flex align-center justify-space-between"
    >
      <span @click="nextStatus" class="clicked-status">
        {{ store.status.title }}
      </span>
      <StatusMenu />
    </div>
  </v-col>
</template>

<script>
import { useEditTaskStore } from "@/store/editTaskStore";
import StatusIcon from "./icons/StatusIcon.vue";
import StatusMenu from "./StatusMenu.vue";

export default {
  name: "TaskStatus",
  components: {
    StatusIcon,
    StatusMenu,
  },
  data() {
    return {
      store: useEditTaskStore(),
      statusList: [
        { title: "To Do", color: "rgba(83, 100, 113, 1)" },
        { title: "In Progress", color: "rgba(0, 133, 255, 1)" },
        { title: "Completed", color: "rgba(0, 186, 52, 1)" },
      ],
    };
  },
 
  methods: {
    nextStatus() {
      const currentIndex = this.store.status_list.findIndex(
        (item) => item.title === this.store.status.title
      );
      const nextStatus =
        currentIndex < this.store.status_list.length - 1
          ? this.store.status_list[currentIndex + 1]
          : this.store.status_list[0];
      this.store.updateStatus(nextStatus);
    },
  },
};
</script>
