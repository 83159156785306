<template>
    <div>
        <div class="ad-creative-title">
            <button class="btn_tiktok" @click.stop="drawer = !drawer">
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                    fill="#121415">
                    <path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" />
                </svg>
                <span>Ad single videos or images</span>
            </button>

            <v-navigation-drawer class="ad-creative-drawer-traffic" width="1200" v-model="drawer" location="right"
                temporary>
                <div class="navigations-header">
                    <div class="d-flex align-center justify-space-between">
                        <h4 class="mb-4">Add creatives</h4>
                        <button class="btn_tiktok bg-black" @click.stop="drawer = !drawer">
                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                                fill="#fff">
                                <path
                                    d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z" />
                            </svg>
                        </button>
                    </div>
                    <v-tabs v-model="tab" class="">
                        <v-tab value="one">Tiktok posts</v-tab>
                        <v-tab value="two">Your library</v-tab>
                    </v-tabs>
                </div>
                <div class="navigations-contant pa-3">
                    <v-tabs-window v-model="tab">
                        <v-tabs-window-item value="one">
                            <TabOne />
                        </v-tabs-window-item>

                        <v-tabs-window-item value="two">
                            <TabTwo />
                        </v-tabs-window-item>

                    </v-tabs-window>
                </div>
            </v-navigation-drawer>

            <button class="btn_tiktok">
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                    fill="#121415">
                    <path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" />
                </svg>
                <span>Create new</span>
            </button>


        </div>
    </div>
</template>


<script>
import TabOne from './tabs/TabOne.vue';
import TabTwo from './tabs/TabTwo.vue';
export default {
    name: 'AdCreative',
    components: {
        TabOne,
        TabTwo
    },
    data() {
        return {
            drawer: null,
            tab: null,
        }
    }
}
</script>