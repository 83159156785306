<template>
  <v-table hover class="mt-5 no-border main-table" elevation="0">
    <thead>
      <tr>
        <th class="text-left">User</th>
        <th class="text-left">Last Viewed</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="last in lasts" :key="last.id">
        <td>
          <span class="d-flex align-center ga-2">
            <img :src="last.image" :alt="last.name" class="circle-img" />
            <span>{{ last.name }}</span>
          </span>
        </td>
        <td>{{ last.last_active }}</td>
      </tr>
    </tbody>
  </v-table>
  <Pagination @PaginationChange="PaginationChanged" :pagination="pagination" />
</template>
<script>
import apiClient from "@/core/buildRequest";
import Pagination from "./Pagination.vue";
export default {
  name: "LastViewedTable",
  props: {
    type: {
      type: String,
    },
  },
  components: { Pagination },
  data() {
    return {
      lasts: [],
      pagination: {
        total_items: 0,
        count_items: 0,
        total_pages: 1,
        current_page: 1,
        next_page_url: "",
        perv_page_url: "",
      },
      current_page: 1,
      current_items_per_page: 10,
    };
  },
  methods: {
    getLasts() {
      apiClient
        .get(`/leads-managment/last-seen`, {
          params: {
            page: this.current_page,
            per_page: this.current_items_per_page,
          },
        })
        .then((response) => {
          this.lasts = response.data.data?.data;
          this.current_page = response.data.data?.pagination.current_page;
          this.current_items_per_page = response.data.data?.pagination.per_page;
          this.pagination.current_page =
            response.data.data?.pagination.current_page;
          this.pagination.count_items = response.data.data?.pagination.per_page;
          this.pagination.next_page =
            response.data.data?.pagination.next_page_url;
          this.pagination.prev_page =
            response.data.data?.pagination.perv_page_url;
          this.pagination.total_pages =
            response.data.data?.pagination.total_pages;
          this.pagination.total_items =
            response.data.data?.pagination.total_items;
        })
        .catch((error) => {
          console.error("Error fetching lead data:", error);
        });
    },
    PaginationChanged({ page, items_per_page }) {

      this.current_page = page;
      this.current_items_per_page = items_per_page;
      this.getLasts();
    },
  },
  mounted() {
    this.getLasts();
  },
};
</script>
<style>
.no-border {
  border: none !important;
}
.no-border .v-table__wrapper table tbody tr:not(:last-child) td {
  border-bottom: none !important;
}

.main-table .v-table__wrapper table thead {
  background: rgb(246, 252, 251);
}
.main-table .v-table__wrapper table thead tr th {
  border-bottom: none !important;
}
</style>
