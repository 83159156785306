<template>
    <div class="demographics devices interests-behaviors">
        <v-expansion-panels class="my-4">
            <v-expansion-panel title="Device">
                <v-expansion-panel-text>
                    <div class="age mt-5 mb-10">
                        <label for="" class="operating-label">Operating system </label>
                        <div class="age-form my-3">
                            <div class="mydict">
                                <div>
                                    <label>
                                        <input type="radio" value="All" name="operatingSystem" v-model="operatingSystem"
                                            @change="operateAllChecked('All')" />
                                        <span>All</span>
                                    </label>
                                    <label>
                                        <input v-model="operatingSystem" type="radio" value="ANDROID"
                                            name="operatingSystem" @change="operateAllChecked('ANDROID')" />
                                        <span>Androied</span>
                                    </label>
                                    <label>
                                        <input type="radio" value="IOS" name="operatingSystem" v-model="operatingSystem"
                                            @change="operateAllChecked('IOS')" />
                                        <span>iOS</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="os-versions mb-8">
                        <label class="d-block operating-label" for="">OS versions
                        </label>
                        <v-select :items="all_os_versions" return-object item-title="name" v-model="selectedOsVersion"
                            variant="outlined" density="compact" @update:modelValue="updateOsVersion"></v-select>
                    </div>
                    <div class="device-model os-versions mb-8">
                        <label class="d-block operating-label" for="">Device model
                        </label>
                        <v-select :items="os_versions" item-title="label" v-model="device_model_seleceted"
                            variant="outlined" density="compact" return-object
                            @update:modelValue="updateOsVersion"></v-select>
                    </div>
                    <div class="age mb-8 connection-type">
                        <div class="age-form my-3">
                            <label for="age" class="mb-1 age-label d-block">
                                Connection type
                            </label>
                            <div class="mydict">
                                <div>
                                    <label>
                                        <input type="checkbox" value="All" :checked="conectionTypeSelected"
                                            @change="handleAllCheckedConnection" />
                                        <span>All</span>
                                    </label>
                                    <label>
                                        <input type="checkbox" value="WIFI" v-model="selectedConectionType"
                                            @change="selecteConectionType" />
                                        <span>Wi-Fi</span>
                                    </label>
                                    <label>
                                        <input type="checkbox" value="2G" v-model="selectedConectionType"
                                            @change="selecteConectionType" />
                                        <span>2G</span>
                                    </label>
                                    <label>
                                        <input type="checkbox" value="3G" v-model="selectedConectionType"
                                            @change="selecteConectionType" />
                                        <span>3G</span>
                                    </label>
                                    <label>
                                        <input type="checkbox" value="4G" v-model="selectedConectionType"
                                            @change="selecteConectionType" />
                                        <span>4G</span>
                                    </label>
                                    <label>
                                        <input type="checkbox" value="5G" v-model="selectedConectionType"
                                            @change="selecteConectionType" />
                                        <span>5G</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="os-versions carriers mb-8">
                        <label for="" class="d-block operating-label">Carriers</label>
                        <treeselect :multiple="true" :options="carriers" clearable="false" placeholder="All"
                            v-model="carrier_selected.label" />
                    </div>
                    <div class="internet-service os-versions carriers mb-8">
                        <label class="d-block operating-label" for="">Internet service provider
                        </label>
                        <treeselect :multiple="true" :options="internetServiceProvider" placeholder=""
                            v-model="internet_service_provider_selected" />
                    </div>
                    <div class="device-price">
                        <label class="d-block device-price-label" for="">Device price</label>
                        <div class="radio-buttons-container">
                            <div class="radio-button">
                                <input @change="updatePrice()" name="radio-group" id="radio2"
                                    class="radio-button__input" type="radio" value="any" v-model="price" />
                                <label for="radio2" class="radio-button__label">
                                    <span class="radio-button__custom"></span>
                                    Any price
                                </label>
                            </div>
                            <div class="radio-button">
                                <input name="radio-group" id="radio1" @change="updatePrice()"
                                    class="radio-button__input" type="radio" value="specific" v-model="price" />
                                <label for="radio1" class="radio-button__label">
                                    <span class="radio-button__custom"></span>
                                    Specific range
                                </label>
                            </div>
                        </div>
                        <div class="price-range" v-if="price === 'specific'">
                            <div class="d-1-0-1 ga-2 w-75 d-flex">
                                <v-select class="v-select-style" v-model="age_restrictions_required_start_age"
                                    :items="spacefic_price_range_start" variant="solo"
                                    @change="updatePriceRange"></v-select>

                                <span class="d-flex align-center justify-center">-</span>
                                <v-select @change="updatePriceRange" variant="solo" class="v-select-style"
                                    v-model="age_restrictions_required_start_end_age"
                                    :items="spacefic_price_range_end"></v-select>
                                <span class="d-flex align-center justify-center">USD</span>
                            </div>
                            <div class="price-range-slider"></div>
                        </div>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>


<script>
import apiClient from "@/core/buildRequest";
export default {
    name: "DevicesTargeting",
    data() {
        return {
            operatingSystem: "All",
            advertise_id: "",
            all_os_versions: [],
            selectedOsVersion: { id: "NONE", name: "All" },
            device_model_seleceted: [
                {
                    id: "all",
                    label: "All",
                },
            ],
            os_versions: [
                {
                    id: "all",
                    label: "All",
                },
                {
                    id: "Android 14.0 and above",
                    label: "Android 14.0 and above",
                },
                {
                    id: "Android 13.0 and above",
                    label: "Android 13.0 and above",
                },
                {
                    id: "Android 12.0 and above",
                    label: "Android 12.0 and above",
                },
            ],
            selectedConectionType: ["All"],
            carriers: [
                {
                    id: "eg_orange",
                    label: "EG :Orange",
                },
                {
                    id: "eg_vodafone",
                    label: "EG :Vodafone",
                },
                {
                    id: "eg_etisalat",
                    label: "EG :Etisalat",
                },
            ],
            carrier_selected: [
                {
                    id: "eg_orange",
                    label: "EG :Orange",
                },
            ],
            internetServiceProvider: [
                {
                    id: "eg_etisalat_misr",
                    label: "EG :Etisalat Misr",
                },
                {
                    id: "eg_telecom_egypt",
                    label: "EG :Telecom Egypt",
                },
                {
                    id: "eg_vodafone_egypt",
                    label: "EG :Vodafone Egypt",
                },
                {
                    id: "eg_other",
                    label: "EG :other ",
                },
            ],
            internet_service_provider_selected: [],
            price: [],
            age_restrictions_required_start_age: "",
            spacefic_price_range_start: [
                "0",
                "50",
                "100",
                "150",
                "200",
                "250",
                "300",
                "350",
                "400",
                "450",
                "500",
                "550",
                "600",
                "650",
                "700",
                "750",
                "800",
                "850",
                "900",
                "950",
                "1000",
            ],
            age_restrictions_required_start_end_age: "",
            spacefic_price_range_end: [
                "50",
                "100",
                "150",
                "200",
                "250",
                "300",
                "350",
                "400",
                "450",
                "500",
                "550",
                "600",
                "650",
                "700",
                "750",
                "800",
                "850",
                "900",
                "950",
                "1000",
                "1000+",
            ],
            android_os_versions: [],
            ios_os_versions: [],
            multiple: true,
        }
    },
    mounted() {
        this.advertise_id = localStorage.getItem("advertiser_id");
        if (localStorage.getItem("operatingSelect")) {
            this.operatingSystem = localStorage.getItem("operatingSelect");
        }
        if (localStorage.getItem("price")) {
            this.price = localStorage.getItem("price");
        }


        localStorage.setItem(
            "connectionType",
            JSON.stringify(this.selectedConectionType)
        );


        Promise.all([
            apiClient.get(
                `/campaign-ads/tiktok/get/os/versions/${this.advertise_id}/ANDROID`
            ),
            apiClient.get(
                `/campaign-ads/tiktok/get/os/versions/${this.advertise_id}/IOS`
            ),
        ])
            .then(([androidResponse, iosResponse]) => {
                // Process responses
                this.android_os_versions =
                    androidResponse?.data?.data?.data?.os_versions || [];
                this.ios_os_versions = iosResponse?.data?.data?.data?.os_versions || [];
                // Combine all OS versions
                this.all_os_versions = this.android_os_versions.concat(
                    this.ios_os_versions
                );
                this.all_os_versions.unshift({
                    id: "NONE",
                    name: "All",
                });
            })
            .catch((error) => {
                console.error("API Error:", error);
            });
        localStorage.setItem(
            "connectionType",
            JSON.stringify(this.selectedConectionType)
        );
    },
    methods: {
        operateAllChecked(type) {
            localStorage.setItem("operatingSelect", this.operatingSystem);
            let baseArray = [];
            if (type === "All") {
                baseArray = this.android_os_versions.concat(this.ios_os_versions);
            } else if (type === "ANDROID") {
                baseArray = [...this.android_os_versions];
            } else if (type === "IOS") {
                baseArray = [...this.ios_os_versions];
            }

            // Add the "All" option at the beginning
            this.all_os_versions = [
                { id: "NONE", name: "All" },
                ...baseArray,
            ];
        },
        updateOsVersion() {
            localStorage.setItem("osVersion", this.selectedOsVersion.id);
        },
        handleAllCheckedConnection(event) {
            if (event.target.checked) {
                // If "All" is checked, clear other selections and add "All"
                this.selectedConectionType = ["All"];
                localStorage.setItem(
                    "connectionType",
                    JSON.stringify(this.selectedConectionType)
                );
            } else {
                // If "All" is unchecked, clear the array
                this.selectedConectionType = [];
                localStorage.setItem(
                    "connectionType",
                    JSON.stringify(this.selectedConectionType)
                );
            }
        },
        selecteConectionType() {
            if (this.selectedConectionType) {
                this.selectedConectionType = this.selectedConectionType.filter(
                    (val) => val !== "All"
                );
            }
            localStorage.setItem(
                "connectionType",
                JSON.stringify(this.selectedConectionType)
            );
        },
        generateRange(min, max) {
            return Array.from({ length: max - min + 1 }, (_, i) => i + min);
        },
        updatePrice() {
            localStorage.setItem("price", this.price);
        },
        updatePriceRange() {
            const startAge = Number(this.age_restrictions_required_start_age);
            const endAge = Number(this.age_restrictions_required_start_end_age);

            // Ensure ranges are valid
            if (startAge > endAge) {
                this.age_restrictions_required_start_end_age = startAge;
            }

            // Update available options for both selectors
            this.spacefic_price_range_start = this.generateRange(18, endAge);
            this.spacefic_price_range_end = this.generateRange(startAge, 100);
        },
    },
    computed: {
        conectionTypeSelected() {
            return this.selectedConectionType.includes("All");
        },
    },
    watch: {
        age_restrictions_required_start_age(newValue) {
            if (
                Number(newValue) > Number(this.age_restrictions_required_start_end_age)
            ) {
                this.age_restrictions_required_start_end_age = newValue;
            }
            if (this.age_restrictions_required) {
                this.show_age_restrictions_required_validate = Number(newValue) < 18;
            }
            localStorage.setItem("Specific_price_range", newValue);
        },
        age_restrictions_required_start_end_age(newValue) {
            if (Number(newValue) < Number(this.age_restrictions_required_start_age)) {
                this.age_restrictions_required_start_age = newValue;
            }
        },
    },
}
</script>