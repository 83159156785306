<template>
  <div class="profile-box-container w-100">
    <div class="mb-3">
      <label for="" class="profile-box-title">{{ $t('password') }}</label>
    </div>
    <div
      class="d-flex flex-lg-row flex-sm-column flex-md-column align-items-center justify-space-between py-5"
    >
      <div class="d-flex align-center ga-1">
        <svg
          v-for="n in 6"
          :key="n"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="4" cy="4" r="4" fill="#999999" />
        </svg>
      </div>
      <div class="profile-box-second-text">{{ $t('last_update_was') }} 2 days ago</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Password",
  components: {},
  data() {
    return {};
  },
  beforeCreate() {},
  methods: {},
};
</script>
