<template>
  <div class="pa-4">
    <v-row no-gutters>
      <v-col cols="12">
        <FileListComponent
          v-if="showUploadFile"
          :files="files"
          @remove-file="handleRemoveFile"
          @HideUploadedFiles="showUploadFile = false"
          :itemId="item.id"
        />
        <div class="d-flex justify-end align-center mb-3">
          <span
            v-if="!showUploadFile"
            @click="showUploadFile = true"
            style="width: auto"
            class="cursor-pointer upload-file choose-btn"
          >
            Uploaded Files
          </span>
        </div>
        <v-file-upload
          v-if="!showUploadFile"
          :density="density"
          v-model="newFiles"
          multiple
        ></v-file-upload>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import FileUploadComponent from "@/components/crm/leads/comments/FileUploadComponent.vue";
import FileListComponent from "@/components/crm/leads/comments/FileListComponent.vue";
import { VFileUpload } from "vuetify/labs/VFileUpload";
import apiClient from "@/core/buildRequest";

export default {
  name: "FileManagerParent",
  components: {
    FileListComponent,
    VFileUpload,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      files: [],
      showUploadFile: false,
      newFiles: [],
    };
  },
  mounted() {
    if (this.item && this.item.files) {
      this.files = [...this.item.files];
    }

    if (this.files.length === 0) {
      this.showUploadFile = true;
    } else {
      this.showUploadFile = false;
    }
  },
  watch: {
    newFiles(files) {
      files.forEach((file) => {
        try {
          let formData = new FormData();
          formData.append("file", file);
          apiClient
            .post(`/leads-managment/upload-file/${this.item.id}`, formData)
            .then((image) => {
              console.log(image);
              this.files.push(image);
            });
        } catch (error) {
          console.log(error);
        }
      });
    },
  },
  methods: {
    handleFilesAdded(newFiles) {
      this.files.push(...newFiles);
    },
    handleRemoveFile(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>
