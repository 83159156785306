<template>
  <button @click="exportToExcel" class="exports d-flex align-center ga-2">
    <svg 
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10M4.66667 6.66667L8 10M8 10L11.3333 6.66667M8 10V2"
        stroke="#666666"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span>Export</span>
  </button>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn variant="text" v-bind="props">
        <svg
          width="16"
          height="4"
          viewBox="0 0 16 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 0.5C2.82843 0.5 3.5 1.17157 3.5 2C3.5 2.82843 2.82843 3.5 2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5Z"
            fill="#666666"
          />
          <path
            d="M8 0.5C8.82843 0.5 9.5 1.17157 9.5 2C9.5 2.82843 8.82843 3.5 8 3.5C7.17157 3.5 6.5 2.82843 6.5 2C6.5 1.17157 7.17157 0.5 8 0.5Z"
            fill="#666666"
          />
          <path
            d="M14 0.5C14.8284 0.5 15.5 1.17157 15.5 2C15.5 2.82843 14.8284 3.5 14 3.5C13.1716 3.5 12.5 2.82843 12.5 2C12.5 1.17157 13.1716 0.5 14 0.5Z"
            fill="#666666"
          />
        </svg>
      </v-btn>
    </template>
    <v-list class="leads-v-list">
      <div @click="toggleActivityDrawer" class="d-flex item">
        <span>Activity Log</span>
      </div>
      <div class="d-flex item">
        <span>Discussions</span>
      </div>
    </v-list>
  </v-menu>
  <activity-drawer
    :drawer="activityDrawer"
    @update:drawer="activityDrawer = $event"
    :type="type"
  />
</template>

<script>
import * as XLSX from "xlsx";
import activityLog from "./Activites/ActivityLog.vue";
export default {
  name: "TopHead",
  props: ["type", "list"],
  components: { "activity-drawer": activityLog },
  data() {
    return {
      activityDrawer: false,
    };
  },
  methods: {
    exportToExcel() {
      const worksheet = XLSX.utils.json_to_sheet(this.list);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, `${this.type}`);
      XLSX.writeFile(workbook, `${this.type}.xlsx`);
    },
    toggleActivityDrawer() {
      this.activityDrawer = !this.activityDrawer;
    },
  },
};
</script>
<style scoped>
.exports {
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.exports:hover {
  background-color: rgb(211, 211, 211);
}
</style>
