<template>
  <div class="engagements-drawer">
    <v-navigation-drawer
      :width="600"
      location="right"
      v-model="inDrawer"
      temporary
    >
      <div class="pa-4">
        <v-list-item class="mb-5">
          <div class="d-flex ga-5 align-center">
            <svg
              @click="inDrawer = false"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33366 15.8327L4.16699 14.666L8.83366 9.99935L4.16699 5.33268L5.33366 4.16602L10.0003 8.83268L14.667 4.16602L15.8337 5.33268L11.167 9.99935L15.8337 14.666L14.667 15.8327L10.0003 11.166L5.33366 15.8327Z"
                fill="#666666"
              />
            </svg>
            <span>Engagements</span>
          </div>
        </v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" class="advanced-filter-massage mt-5" nav>
          <v-list-item
            v-for="(item, index) in store.engagements"
            :key="index"
            :value="index"
          >
            <v-list-item-title
              class="d-flex align-center justify-space-between ga-2"
            >
              <div class="d-flex align-center ga-2">
                <div class="user-img-status">
                  <img :src="item.user_img" alt="" />
                  <img
                    v-if="item.massageFrom === 'facebook'"
                    class="social-icon"
                    src="@/assets/soft-ware-img/Component 30.svg"
                    alt=""
                  />
                  <img
                    v-else-if="item.massageFrom === 'instagram'"
                    class="social-icon"
                    src="@/assets/soft-ware-img/instgrapm.svg"
                    alt=""
                  />
                  <img
                    v-else-if="item.massageFrom === 'tiktok'"
                    class="social-icon"
                    src="@/assets/soft-ware-img/tikTopkcampaign.svg"
                    alt=""
                  />
                </div>
                <div class="user-msg">
                  <p>{{ item.user_name }}</p>
                  <span class="msg-text">{{ item.user_msg }}</span>
                  <button @click="reply()">
                    <img src="@/assets/soft-ware-img/replay-msg.svg" alt="" />
                    <span> Reply </span>
                  </button>
                </div>
              </div>
              <div class="">
                <img :src="item.user_img" width="67px"  alt="" />
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { EngagementAndInsightsStore } from "@/store/EngagementAndInsights/EngagementAndInsights";
export default {
  name: "EngagementsDrawer",
  props: ["drawer"],
  data() {
    return {
      inDrawer: this.drawer,
      store: EngagementAndInsightsStore(),
    };
  },
  watch: {
    drawer() {
      this.inDrawer = this.drawer;
      this.$emit("update:drawer", this.inDrawer);
    },
  },
  methods: {
 
  },
};
</script>
<style>
.engagements-drawer .v-navigation-drawer {
  z-index: 1500 !important;
}
</style>
<style scoped>
.advanced-filter-massage .v-list {
  width: 384px !important;
  padding: 20px !important;
  top: 14px !important;
  height: 66vh !important;
}
.advanced-filter-massage a.see-all {
  color: #237276;
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-align: end;
  text-decoration: none;
}
.advanced-filter-massage .v-list h4 {
  font-weight: 700;
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
}
.advanced-filter-massage .v-list-item {
  padding: 5px !important;
}
.v-list-item:hover {
  border-radius: 4px !important;
}
.v-list-item-title {
  padding: 6px 0 0 2px;
}
.user-img-status {
  position: relative;
}
.user-img-status img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.user-img-status .online-status {
  width: 13px;
  height: 13px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: -1px;
  left: -1px;
  border: 2px solid #fff;
}
.user-img-status .online-status span {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #31a24c;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.user-img-status img.social-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  right: -3px;
}
.user-msg {
  line-height: normal;
}
.user-msg p {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.user-msg span.msg-text {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  display: block;
}
.user-msg button img {
  width: 16px;
  height: 16px;
}
.user-msg button {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 10px;
}
.user-msg button span {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
</style>
