<template>
  <div class="single-question">
    <MultipleChoice
      v-if="question.custom_form_field_properties.type === 'MULTIPLE_CHOICE_SINGLE_SELECTION'"
      :question="question"
      :index="index"
    />
    <MultipleSelection
      v-else-if="question.custom_form_field_properties.type === 'MULTIPLE_CHOICE_MULTI_SELECTION'"
      :question="question"
      :index="index"
    />
    <Datepicker
      v-else-if="question.custom_form_field_properties.type === 'DATE'"
      :question="question"
      :index="index"
    />
    <ShortAnswer
      v-else-if="question.custom_form_field_properties.type === 'TEXT'"
      :question="question"
      :index="index"
    />
  </div>
</template>
<script>
import { useLeadFormStore } from "@/store/snapchat/LeadForm";
import MultipleChoice from "./questions/MultipleChoice.vue";
import MultipleSelection from "./questions/MultipleSelection.vue";
import Datepicker from "./questions/DatePicker.vue";
import ShortAnswer from "./questions/ShortAnswer.vue";
export default {
  components: {
    MultipleChoice,
    MultipleSelection,
    Datepicker,
    ShortAnswer,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      store: useLeadFormStore(),
    };
  },
};
</script>
