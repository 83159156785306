<template>
  <!-- Budget & Schedule  -->
  <div class="budget-and-schedule ad-set-details-card mb-6">
    <h6 class="page-header-title mb-3">Budget & Schedule</h6>
    <v-card
      max-width="100%"
      class="pa-5 mb-5 rounded-lg budget-and-schedule-card"
      elevation="1"
    >
      <div class="card-header">
        <span class="inputs-title">Budget</span>
        <div class="d-2 ga-3">
          <CurrencyInput
            v-if="SelectedBudget == 'lifetime_budget'"
            v-model="lifetime_spend_cap_micro"
            :options="{ currency: 'USD' }"
          />
          <CurrencyInput
            v-else
            v-model="daily_budget_micro"
            :options="{ currency: 'USD' }"
          />

          <v-select
            variant="solo"
            class="v-select-style"
            v-model="SelectedBudget"
            :items="budget"
            item-title="title"
            item-value="value"
          ></v-select>
        </div>
        <!-- daily_budget  -->
        <div
          v-if="SelectedBudget == 'daily_budget'"
          class="lifetime-baget-container"
        >
          <span class="inputs-title mt-5">Starts & Ends</span>
          <div class="d-3-1 ga-3 mt-2">
            <input
              v-model="daily_budget_start_date"
              class="custom-input"
              type="date"
            />
            <input
              v-model="daily_budget_start_time"
              class="custom-input"
              type="time"
            />
          </div>
          <div class="d-flex justify-end pacific-time mt-1">Pacific time</div>
          <div class="optoinal-daily-end-time-checkbox">
            <label class="snap-checkbox">
              <input
                v-model="set_daily_budget_end_date_and_time"
                type="checkbox"
              />
              <span class="snap-checkmark"></span>Set an End Date (Optional)
            </label>
          </div>
          <div
            v-if="set_daily_budget_end_date_and_time"
            class="optoinal-daily-end-time"
          >
            <div class="d-3-1 ga-3 mt-2">
              <input
                v-model="daily_budget_end_date"
                class="custom-input"
                type="date"
              />
              <input
                v-model="daily_budget_end_time"
                :disabled="!daily_budget_end_date"
                class="custom-input daily_budget_end_time"
                type="time"
              />
            </div>
            <div class="d-flex justify-end pacific-time mt-1 w-100">
              Pacific time
            </div>
            <div
              v-if="daily_budget_end_date_warning"
              class="three-days-end-time-warning"
            >
              <div class="header">
                <div class="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="#444546"
                    xmlns="http://www.w3.org/2000/svg"
                    class="warning-icon-svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.99994 8C1.99994 4.68629 4.68623 2 7.99994 2C11.3136 2 13.9999 4.68629 13.9999 8C13.9999 11.3137 11.3136 14 7.99994 14C4.68623 14 1.99994 11.3137 1.99994 8Z"
                    ></path>
                    <path
                      d="M7.16653 10.755C7.16521 10.5899 7.21296 10.4281 7.30372 10.2902C7.39448 10.1523 7.52416 10.0444 7.67631 9.98034C7.82847 9.91625 7.99623 9.89879 8.15831 9.93019C8.3204 9.96158 8.46951 10.0404 8.58672 10.1567C8.70393 10.273 8.78397 10.4214 8.81666 10.5833C8.84936 10.7451 8.83325 10.913 8.77038 11.0657C8.70751 11.2183 8.60071 11.3489 8.46352 11.4407C8.32634 11.5326 8.16496 11.5816 7.99986 11.5816C7.89074 11.5825 7.78253 11.5618 7.68146 11.5206C7.5804 11.4795 7.48848 11.4187 7.41101 11.3419C7.33354 11.265 7.27205 11.1736 7.23009 11.0729C7.18813 10.9721 7.16653 10.8641 7.16653 10.755Z"
                      fill="white"
                    ></path>
                    <path
                      d="M8.00001 3.79333C8.11321 3.7941 8.22508 3.81775 8.32891 3.86284C8.43274 3.90793 8.52638 3.97354 8.60422 4.05574C8.68205 4.13793 8.74247 4.23501 8.78185 4.34114C8.82122 4.44727 8.83874 4.56026 8.83334 4.67333L8.50001 9.04H7.49334L7.16668 4.67333C7.16222 4.56049 7.18038 4.44789 7.22009 4.34217C7.25981 4.23645 7.32027 4.13975 7.39792 4.05775C7.47557 3.97575 7.56884 3.91011 7.67225 3.8647C7.77565 3.81929 7.88709 3.79503 8.00001 3.79333Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <div class="text">
                  Increase Ad Set Duration for Better Results
                </div>
              </div>
              <div class="body">
                After you create a new ad set, our system will start the
                learning phase to best deliver your ads. For optimal
                performance, we recommend that your ad set runs for at least 7
                days.
              </div>
            </div>
          </div>
        </div>
        <!-- lifetime_budget  -->
        <div
          v-if="SelectedBudget == 'lifetime_budget'"
          class="lifetime-baget-container"
        >
          <span class="inputs-title mt-5">Starts & Ends</span>
          <div class="d-3-1 ga-3 mt-2">
            <input
              v-model="budget_start_date"
              class="custom-input"
              type="date"
            />
            <input
              v-model="budget_lifetime_start_time"
              class="custom-input"
              type="time"
            />
          </div>
          <div class="d-flex justify-end pacific-time mt-1">Pacific time</div>
          <div class="d-3-1 ga-3 mt-2">
            <input v-model="budget_end_date" class="custom-input" type="date" />
            <input
              v-model="lifetime_budget_end_time"
              class="custom-input"
              type="time"
            />
          </div>
          <div class="d-flex justify-end pacific-time mt-1">Pacific time</div>
          <div
            v-if="budget_end_date_warning"
            class="three-days-end-time-warning"
          >
            <div class="header">
              <div class="icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#444546"
                  xmlns="http://www.w3.org/2000/svg"
                  class="warning-icon-svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.99994 8C1.99994 4.68629 4.68623 2 7.99994 2C11.3136 2 13.9999 4.68629 13.9999 8C13.9999 11.3137 11.3136 14 7.99994 14C4.68623 14 1.99994 11.3137 1.99994 8Z"
                  ></path>
                  <path
                    d="M7.16653 10.755C7.16521 10.5899 7.21296 10.4281 7.30372 10.2902C7.39448 10.1523 7.52416 10.0444 7.67631 9.98034C7.82847 9.91625 7.99623 9.89879 8.15831 9.93019C8.3204 9.96158 8.46951 10.0404 8.58672 10.1567C8.70393 10.273 8.78397 10.4214 8.81666 10.5833C8.84936 10.7451 8.83325 10.913 8.77038 11.0657C8.70751 11.2183 8.60071 11.3489 8.46352 11.4407C8.32634 11.5326 8.16496 11.5816 7.99986 11.5816C7.89074 11.5825 7.78253 11.5618 7.68146 11.5206C7.5804 11.4795 7.48848 11.4187 7.41101 11.3419C7.33354 11.265 7.27205 11.1736 7.23009 11.0729C7.18813 10.9721 7.16653 10.8641 7.16653 10.755Z"
                    fill="white"
                  ></path>
                  <path
                    d="M8.00001 3.79333C8.11321 3.7941 8.22508 3.81775 8.32891 3.86284C8.43274 3.90793 8.52638 3.97354 8.60422 4.05574C8.68205 4.13793 8.74247 4.23501 8.78185 4.34114C8.82122 4.44727 8.83874 4.56026 8.83334 4.67333L8.50001 9.04H7.49334L7.16668 4.67333C7.16222 4.56049 7.18038 4.44789 7.22009 4.34217C7.25981 4.23645 7.32027 4.13975 7.39792 4.05775C7.47557 3.97575 7.56884 3.91011 7.67225 3.8647C7.77565 3.81929 7.88709 3.79503 8.00001 3.79333Z"
                    fill="white"
                  ></path>
                </svg>
              </div>
              <div class="text">
                Increase Ad Set Duration for Better Results
              </div>
            </div>
            <div class="body">
              After you create a new ad set, our system will start the learning
              phase to best deliver your ads. For optimal performance, we
              recommend that your ad set runs for at least 7 days.
            </div>
          </div>
          <!-- Ad Scheduling  -->
          <div
            v-if="selected_capmaign_type !== 'Awareness & Engagement'"
            class="ad-scheduling tree-select"
          >
            <div class="radio-button">
              <input
                v-model="ad_scheduling_time"
                value="ad-scheduling-all-the-time"
                name="ad-scheduling"
                id="ad-scheduling-all-the-time"
                class="radio-button__input"
                type="radio"
              />
              <label
                for="ad-scheduling-all-the-time"
                class="radio-button__label"
              >
                <span class="radio-button__custom"></span>
                <span class="d-block">Run Ads All The Time</span>
              </label>
            </div>
            <div class="radio-button">
              <input
                v-model="ad_scheduling_time"
                value="ad-scheduling-at-scheduling-time"
                name="ad-scheduling"
                id="ad-scheduling-at-scheduling-time"
                class="radio-button__input"
                type="radio"
              />
              <label
                for="ad-scheduling-at-scheduling-time"
                class="radio-button__label"
              >
                <span class="radio-button__custom"></span>
                <span class="d-block"
                  >Run ads on a schedule
                  <span class="text-muted small-text"
                    >Ads are scheduled based on the time zone of the person
                    seeing it.</span
                  >
                </span>
              </label>
            </div>
            <div
              class=""
              v-if="ad_scheduling_time == 'ad-scheduling-at-scheduling-time'"
            >
              <div class="">
                <ScheduleCalender
                  :last_selected="selected_schedule_times"
                  @selectedTimes="getSelectedScheduleTimes"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import CurrencyInput from "@/components/snapchat/Label&input/CurrencyInput.vue";
import ScheduleCalender from "@/components/snapchat/ScheduleCalender.vue";

export default {
  components: { CurrencyInput, ScheduleCalender },
  data() {
    return {
      selected_capmaign_type: localStorage.getItem("selectedOption"),
      lifetime_spend_cap_micro:
        localStorage.getItem("lifetime_spend_cap_micro") || "",
      daily_budget_micro: localStorage.getItem("daily_budget_micro") || "",
      SelectedBudget: "",
      daily_budget_start_date: "",
      daily_budget_start_time: "",
      set_daily_budget_end_date_and_time: false,
      daily_budget_end_date: "",
      daily_budget_end_date_warning: false,
      daily_budget_end_time: "",
      budget_start_date: "",
      budget_lifetime_start_time: "",
      budget_end_date: "",
      budget_end_date_warning: false,
      lifetime_budget_end_time:
        localStorage.getItem("lifetime_budget_end_time") ||
        localStorage.getItem("campaign_end_time") ||
        "",
      ad_scheduling_time:
        localStorage.getItem("ad_scheduling_time") ||
        "ad-scheduling-all-the-time",
      selected_schedule_times: localStorage.getItem("selected_schedule_times")
        ? JSON.parse(localStorage.getItem("selected_schedule_times"))
        : {},
      budget: [
        { title: "Daily Budget", value: "daily_budget" },
        { title: "Lifetime Budget", value: "lifetime_budget" },
      ],
    };
  },

  methods: {
    // getLifetimeBudgetValue(value) {
    //   localStorage.setItem("lifetime_spend_cap_micro", value);
    // },
    // getDailyBudgetValue(value) {
    //   localStorage.setItem("daily_budget_micro", value);
    // },
    getSelectedScheduleTimes(selected_schedule_times) {
      this.selected_schedule_times = selected_schedule_times;
      localStorage.setItem(
        "selected_schedule_times",
        JSON.stringify(this.selected_schedule_times)
      );
    },
    initLocalStorage(primaryKey, fallbackKey) {
      let value =
        localStorage.getItem(primaryKey) ||
        localStorage.getItem(fallbackKey) ||
        "";
      if (!localStorage.getItem(primaryKey)) {
        localStorage.setItem(primaryKey, value);
      }
      this[primaryKey] = value;
    },
  },
  computed: {},
  watch: {
    lifetime_spend_cap_micro(value) {
      localStorage.setItem("lifetime_spend_cap_micro", value);
    },
    daily_budget_micro(value) {
      localStorage.setItem("daily_budget_micro", value);
    },
    SelectedBudget() {
      localStorage.setItem("selected_budget", this.SelectedBudget);
    },
    daily_budget_start_date(value) {
      localStorage.setItem("daily_budget_start_date", value);
      const datetime = `${value} ${localStorage.getItem(
        "daily_budget_start_time"
      )}`;
      localStorage.setItem("daily_budget_start_date_time", datetime);
    },
    daily_budget_start_time(value) {
      localStorage.setItem("daily_budget_start_time", value);
      const datetime = `${localStorage.getItem(
        "daily_budget_start_date"
      )} ${value}`;
      localStorage.setItem("daily_budget_start_date_time", datetime);
    },
    set_daily_budget_end_date_and_time(value) {
      localStorage.setItem("set_daily_budget_end_date_and_time", value);
    },
    daily_budget_end_date(newDate) {
      if (newDate) {
        const currentDate = new Date();
        const endDate = new Date(newDate);
        const diffTime = endDate - currentDate;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);

        this.daily_budget_end_date_warning = diffDays <= 1;
      } else {
        this.daily_budget_end_date_warning = false;
      }

      localStorage.setItem("daily_budget_end_date", newDate);
      const datetime = `${newDate} ${localStorage.getItem(
        "daily_budget_end_time"
      )}`;
      localStorage.setItem("daily_budget_end_date_time", datetime);
    },
    daily_budget_end_time(newTime) {
      localStorage.setItem("daily_budget_end_time", newTime);
      const datetime = `${localStorage.getItem(
        "daily_budget_end_date"
      )} ${newTime}`;
      localStorage.setItem("daily_budget_end_date_time", datetime);
    },
    budget_start_date(value) {
      localStorage.setItem("budget_start_date", value);
      const datetime = `${value} ${localStorage.getItem(
        "budget_lifetime_start_time"
      )}`;
      localStorage.setItem("budget_lifetime_start_date_time", datetime);
    },
    budget_lifetime_start_time(value) {
      localStorage.setItem("budget_lifetime_start_time", value);
      const datetime = `${localStorage.getItem("budget_start_date")} ${value} `;
      localStorage.setItem("budget_lifetime_start_date_time", datetime);
    },
    budget_end_date(newDate) {
      if (newDate) {
        const currentDate = new Date();
        const endDate = new Date(newDate);
        const diffTime = endDate - currentDate;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        this.budget_end_date_warning = diffDays <= 1;
      } else {
        this.budget_end_date_warning = false;
      }
      localStorage.setItem("budget_end_date", newDate);
      const datetime = `${newDate} ${localStorage.getItem(
        "lifetime_budget_end_time"
      )}`;
      localStorage.setItem("lifetime_budget_end_date_time", datetime);
    },
    lifetime_budget_end_time(value) {
      localStorage.setItem("lifetime_budget_end_time", value);
      const datetime = ` ${localStorage.getItem("budget_end_date")} ${value}`;
      localStorage.setItem("lifetime_budget_end_date_time", datetime);
    },
    ad_scheduling_time(value) {
      localStorage.setItem("ad_scheduling_time", value);
    },
  },
  mounted() {
    this.set_daily_budget_end_date_and_time =
      localStorage.getItem("set_daily_budget_end_date_and_time") === "true";
    if (localStorage.getItem("selected_budget")) {
      this.SelectedBudget =
        localStorage.getItem("selected_budget") || "lifetime_budget";
    } else {
      this.SelectedBudget = "lifetime_budget";
      localStorage.setItem("selected_budget", this.SelectedBudget);
    }
    this.initLocalStorage("daily_budget_start_date", "campaign_start_date");
    this.initLocalStorage("daily_budget_start_time", "campaign_start_time");
    this.initLocalStorage("daily_budget_end_date", "campaign_end_date");
    this.initLocalStorage("daily_budget_end_time", "campaign_end_time");
    this.initLocalStorage("budget_start_date", "campaign_start_date");
    this.initLocalStorage("budget_lifetime_start_time", "campaign_start_time");
    this.initLocalStorage("budget_end_date", "campaign_end_date");
  },
};
</script>
