<template>
  <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
    <template v-slot:activator="{ props }">
      <button class="no-overflow" variant="text" v-bind="props" color="#237276">
        <div class="d-flex ga-2 align-center">
          <img class="circle-img" :src="user.image" alt="" />
          <div class="d-flex flex-column">
            <p class="user-name-dropdown">{{ user.name }}</p>
            <span class="user-email-dropdown">{{ user.email }}</span>
          </div>
        </div>
      </button>
    </template>

    <v-card min-width="300">
      <v-list>
        <v-list-item
          :prepend-avatar="user.image"
          :subtitle="user.email"
          :title="user.name"
        >
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item>
          <span class="logout-text" @click="loginForm?.logout">{{
            $t("Logout")
          }}</span>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import { loginForm } from "@/store/logIn";

export default {
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    loginForm: loginForm(),
  }),
  computed: {
    user() {
      let c = JSON.parse(localStorage.getItem("user"));
      return c["data"];
    },
  },
  mounted() {},
};
</script>
<style scoped>
.logout-text {
  color: red;
}
.user-name-dropdown {
  text-align: start;
  line-height: 22.4px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
}
.user-email-dropdown {
  text-align: start;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  color: rgba(102, 102, 102, 1);
}
</style>
