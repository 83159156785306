<template>
  <Layout :linksData="linksData">
    <v-row class="leads-managements w-100">
      <v-col cols="12" :lg="6" class="header-line-title"> Deals </v-col>
      <v-col cols="12" :lg="6" class="d-flex align-center justify-end ga-2">
        <Top :type="'Deals'" :list="deals" />
      </v-col>
    </v-row>
    <FilterSection
      :selected_rows="selected_deals"
      @update:selected_rows="selected_deals = $event"
      :menu="['Event', 'Task']"
      :assign_to_account_menu="false"
      :parent="'deals'"
      :dashboard_path_name="'DealsDashboard'"
      @update:search="updateSearch"
      :store="store"
    />
    <div class="table mt-3">
      <DataTable
        @update:selected_deals="selected_deals = $event" :deals="deals"
      />
    </div>
  </Layout>
</template>
<script>
import Layout from "@/components/crm/layouts/Layout.vue";
import activityLogIcon from "@/assets/images/icons/activity-log.svg";
import discussionsIcon from "@/assets/images/icons/discussions.svg";
import importIcon from "@/assets/images/icons/import.svg";
import FilterSection from "@/components/crm/assets/Filter.vue";
import Top from "@/components/crm/assets/Top.vue";
import DataTable from "@/components/crm/deals/table.vue";
import "@/assets/css/crm/crm-moduls.css";
import { useDealsStore } from "@/store/crm/useDealsStore";

export default {
  components: {
    Layout,
    FilterSection,
    Top,
    DataTable,
  },

  data() {
    return {
      selected_deals: [],
      activityLogIcon,
      discussionsIcon,
      importIcon,
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Deals Management", link: "" },
      ],
      store: null,
    };
  },
  methods: {
    updateSearch(data) {
      this.store.search = data;
      this.store.getAllDeals();
    },
  },
  computed: {
    deals() {
      return this.store?.getDeals || [];
    },
  },
  created() {
    this.store = useDealsStore();
    if (this.store?.getDeals.length === 0) {
        this.store?.getAllDeals();
      }
  },
};
</script>
