<template>
  <v-select label="Select" v-model="selected_status" :items="status" :class="['contact-v-select', statusClass]"
    @update:modelValue="updateStats(item.id)" variant="" no-details>
    <template v-slot:item="{ item, props }">
      <v-list-item v-bind="props" :title="item.raw.title" :style="{
        color:
          item.raw.title === 'active'
            ? '#2E6B30'
            : item.raw.title === 'completed'
              ? '#0464bc'
              : item.raw.title === 'on-hold'
                ? '#c14a05'
                : item.raw.title === 'pending'
                  ? '#666666'
                  : item.raw.title === 'closed'
                    ? '#d91313'
                    : '',
        background:
          item.raw.title === 'active'
            ? '#E3F9EB'
            : item.raw.title === 'completed'
              ? '#f5fafe'
              : item.raw.title === 'On-hold'
                ? '#fefbf1'
                : item.raw.title === 'pending'
                  ? '#E6E6E6'
                  : item.raw.title === 'closed'
                    ? '#fef3f2'
                    : '',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px 0px',
      }">
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import apiClient from "@/core/buildRequest";
import { useEventsStore } from "@/store/crm/useEventsStore";
import { useGeneralStore } from "@/store/General";
export default {
  name: "statusCol",
  props: ["item", "id"],
  data() {
    return {
      selected_status: "",
      status: [
        { 
         title: "active", 
          id: 0 
        }, 
        { 
          title: "completed",
           id: 1   
        }, 
        { 
          title: "on-hold", 
          id: 2 
        }, 
        { 
          title: "pending", 
          id: 3 
        },
        { 
          title: "closed", 
          id: 4 
        }
      ],
      store: useGeneralStore(),
    };
  },
  mounted() {
    this.selected_status = this.item.status;
  },
  computed: {
    statusClass() {
      switch (this.selected_status) {
        case "active":
          return "contact-v-select-green";
        case "completed":
          return "contact-v-select-blue";
        case "on-hold":
          return "contact-v-select-orange";
        case "pending":
          return "contact-v-select-gray";
        case "closed":
          return "contact-v-select-red";
        default:
          return "";
      }
    },
  },
  methods: {
    async updateStats(id) {
      await apiClient
        .post(`/leads-managment/events/status/${id}`, {
          status: this.selected_status,
        })
        .then((res) => {
          const deal_store = useEventsStore();
          deal_store?.updateStatus(this.id, this.selected_status);
          this.store.showSnackbar(res.data.msg, res.data.key);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
