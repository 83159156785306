<template>
  <v-table hover class="mt-5 no-border main-table" elevation="0">
    <thead>
      <tr>
        <th class="text-left">Date & Time</th>
        <th class="text-left">User</th>
        <th class="text-left">Edit Column</th>
        <th class="text-left">Old Value</th>
        <th class="text-left">New Value</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="activity in activities" :key="activity.id">
        <td>
          <span class="d-flex ga-2 align-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2664_65341)">
                <path
                  d="M7.9987 4.00016V8.00016L10.6654 9.3335M14.6654 8.00016C14.6654 11.6821 11.6806 14.6668 7.9987 14.6668C4.3168 14.6668 1.33203 11.6821 1.33203 8.00016C1.33203 4.31826 4.3168 1.3335 7.9987 1.3335C11.6806 1.3335 14.6654 4.31826 14.6654 8.00016Z"
                  stroke="#666666"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2664_65341">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {{ activity.created_at }}
          </span>
        </td>
        <td>
          <img
            :src="activity?.user?.image"
            :alt="activity?.user?.name"
            class="circle-img"
          />
        </td>
        <td>{{ activity.edited_column }}</td>
        <td>{{ activity.old_value }}</td>
        <td>{{ activity.new_value }}</td>
      </tr>
    </tbody>
  </v-table>
  <Pagination @PaginationChange="PaginationChanged" :pagination="pagination" />
</template>
<script>
import apiClient from "@/core/buildRequest";
import Pagination from "./Pagination.vue";
export default {
  name: "LastViewedTable",
  props: {
    type: {
      type: String,
    },
  },
  components: { Pagination },
  data() {
    return {
      activities: [],
      pagination: {
        total_items: 0,
        count_items: 0,
        total_pages: 1,
        current_page: 1,
        next_page_url: "",
        perv_page_url: "",
      },
      current_page: 1,
      current_items_per_page: 10,
    };
  },
  methods: {
    getActivites() {
      apiClient
        .get(`/leads-managment/activity-log/Lead`, {
          params: {
            page: this.current_page,
            per_page: this.current_items_per_page,
          },
        })
        .then((response) => {
          this.activities = response.data.data;
          this.current_page = response.data.data?.pagination?.current_page;
          this.current_items_per_page =
            response.data.data?.pagination?.per_page;

          this.pagination.current_page =
            response.data.data?.pagination.current_page;
          this.pagination.count_items =
            response.data.data?.pagination?.per_page;
          this.pagination.next_page =
            response.data.data?.pagination?.next_page_url;
          this.pagination.prev_page =
            response.data.data?.pagination?.perv_page_url;
          this.pagination.total_pages =
            response.data.data?.pagination?.total_pages;
          this.pagination.total_items =
            response.data.data?.pagination?.total_items;
        })
        .catch((error) => {
          console.error("Error fetching lead data:", error);
        });
    },
    PaginationChanged({ page, items_per_page }) {
      this.current_page = page;
      this.current_items_per_page = items_per_page;
      this.getActivites();
    },
  },
  mounted() {
    this.getActivites();
  },
};
</script>
<style>
.no-border {
  border: none !important;
}
.no-border .v-table__wrapper table tbody tr:not(:last-child) td {
  border-bottom: none !important;
}

.main-table .v-table__wrapper table thead {
  background: rgb(246, 252, 251);
}
.main-table .v-table__wrapper table thead tr th {
  border-bottom: none !important;
}
</style>
