<template>
  <div class="card-pattern special-ad-category">
    <div class="card-header mb-4">
      <label for="" class="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Special ad categories </span>
      </label>
      <p class="px-1 special-ad-paragraph roboto-regular">
        Declare if your ads are related to financial products and services,
        employment, housing, social issues, elections or politics. Requirements
        differ by country.
      </p>
    </div>
    <div class="card-body px-1">
      <h4 class="roboto-bold">Benefits of declaring special ad categories</h4>
      <p class="roboto-regular mb-4 text-description">
        Accurately declaring your ad categories helps you run ads which are
        compliant with our advertising standards and helps prevent potential ad
        rejections.
      </p>
      <div class="categorise-ads mb-4">
        <button @click="dialog = true">Categorise your ads</button>
      </div>
      <v-dialog
        v-model="dialog"
        class="categorise-dialog"
        width="1200px"
        height="100vh"
      >
        <v-card max-width="1200">
          <v-card-text class="pa-0">
            <v-container fluid class="pa-0 ma-0 h-100">
              <v-row class="ma-0 pa-0 h-100">
                <v-col
                  cols="2"
                  style="
                    background: linear-gradient(
                      90deg,
                      rgba(250, 240, 240, 1) 0%,
                      rgba(233, 245, 242, 1) 100%
                    );
                  "
                >
                  <div class="dialog-side-bar">
                    <h4>Categorise your ads</h4>
                    <v-tabs v-model="tab" color="primary" direction="vertical">
                      <v-tab
                        v-for="item in side_bar_title"
                        prepend-icon="mdi-circle"
                        :value="item.value"
                        :key="item.id"
                      >
                        {{ truncateText(item.title, 10) }}
                      </v-tab>
                    </v-tabs>
                  </div>
                </v-col>
                <v-col cols="10 select-container position-relative">
                  <v-tabs-window v-model="tab">
                    <v-tabs-window-item
                      v-for="item in side_bar_title"
                      :value="item.value"
                    >
                      <v-card flat class="pa-0">
                        <v-card-text class="pa-0 ma-0">
                          <v-container class="pa-0 ma-0">
                            <v-row class="ma-0 quastions-parent">
                              <v-col cols="7">
                                <div class="quastions">
                                  <img
                                    src="@/assets/images/meta/image (2).svg"
                                    alt=""
                                  />
                                  <p class="roboto-bold">
                                    Is any part of your ads about financial
                                    products and services?
                                  </p>
                                </div>
                              </v-col>
                              <v-col
                                cols="7"
                                class="d-flex align-center justify-space-between px-0"
                              >
                                <div class="d-flex align-center ga-2">
                                  <img
                                    src="@/assets/images/meta/devices_14654084.svg"
                                    alt=""
                                  />
                                  <p>Ad landing page</p>
                                </div>
                                <div class="d-flex align-center ga-2">
                                  <img
                                    src="@/assets/images/meta/photos_14014867.svg"
                                    alt=""
                                  />
                                  <p>Ad images and videos</p>
                                </div>
                                <div class="d-flex align-center ga-2">
                                  <img
                                    src="@/assets/images/meta/text_16116401.svg"
                                    alt=""
                                  />
                                  <p>Ad headlines and text</p>
                                </div>
                              </v-col>
                              <v-col
                                cols="6"
                                class="px-1 answer-parent"
                                v-for="item in item.item_selects"
                                :key="item.id"
                              >
                                <form class="my-form">
                                  <div>
                                    <input
                                      :id="`radio-1+${item.id}`"
                                      :v-model="item.model"
                                      :value="item.value"
                                      type="radio"
                                      :name="item.name"
                                      @change="
                                        setSelectedValueAnswer(item.value, item)
                                      "
                                    />
                                    <label
                                      :for="`radio-1+${item.id}`"
                                      class="radio-button-label"
                                    >
                                      <span style="color: #1c2b33">
                                        {{ item.title }}
                                      </span>
                                      <ul>
                                        <li v-for="item in item.descriptions">
                                          {{ item }}
                                        </li>
                                      </ul>
                                    </label>
                                  </div>
                                </form>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-tabs-window-item>
                  </v-tabs-window>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text="Cancel"
                      variant="text"
                      @click="dialog = false"
                    ></v-btn>

                    <v-btn
                      color="surface-variant"
                      text="Next"
                      variant="flat"
                      @click="dialog = false"
                    ></v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <div class="categorise-select">
        <h4 class="roboto-bold mb-0">Categories</h4>
        <span class="roboto-regular d-block text-description"
          >Select the categories that best describe what this campaign will
          advertise.</span
        >
        <v-select
          return-object
          :items="selectCategoryAds"
          density="compact"
          variant="outlined"
          multiple
          :menu-props="{ maxWidth: '596px', minWidth: '100%' }"
          item-title="title"
          item-value="value"
          v-model="selectedItems"
          hide-details
        >
          <template v-slot:item="{ item, props: itemProps }">
            <v-list-item class="select-category">
              <v-list-item-title
                @click="showSelectedCountries()"
                class="d-flex  ga-0 align-start select-category-item"
              >
                <label
                  v-bind="itemProps"
                  :for="item.raw.id"
                  class="d-flex mb-0 flex-column align-start ga-0"
                >
                  <div class="d-flex mb-0 align-center ga-2">
                   
                    <v-checkbox-btn
                      :model-value="
                        selectedItems.some((page) => page.id === item.raw.id)
                      "
                      density="compact"
                    />
                    <p v-html="item.raw.svgIcon"></p>
                    <p class="roboto-regular d-block" style="font-size: 14px">
                      {{ item.raw.title }}
                    </p>
                  </div>
                  <p
                    style="
                      white-space: normal;
                      word-wrap: break-word;
                      font-size: 12px;
                      margin-left: 30px;
                    "
                    class="roboto-regular d-block pl-8 w-100"
                  >
                    {{ item.raw.descriptions }}
                  </p>
                </label>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </div>
      <SelectedCountry v-if="selectedCountry" />
    </div>
    
  </div>
</template>

<script>
import { meta } from "@/store/meta/meta.js";
import { mapActions, mapState } from "pinia";
import SelectedCountry from "./special-category/SelectedCountry.vue";
export default {
  components: { SelectedCountry },
  data() {
    return {
      selectedItems: [],
      selectItem: [],
      dialog: false,
      tab: "option-1",
      selectedValue: null,
      selectedCountry: false,
    };
  },
  methods: {
    selectItem(item) {
      this.selectedItems = item;
    },
    showSelectedCountries(){
      this.selectedCountry = true
    },

    ...mapActions(meta, ["truncateText", "setSelectedValueAnswer"]),
  },

  computed: {
    ...mapState(meta, ["selectCategoryAds", "side_bar_title"]),
  },
  watch: {
    selectedItems: {
      handler(newValue, oldValue) {
        // Clear the selectItem array
        this.selectItem = [];
        
        // Add all currently selected values
        if (Array.isArray(newValue)) {
          newValue.forEach((item) => {
            if (item.value !== undefined) {
              this.selectItem.push(item.value);
            }
          });
          
          // Update localStorage with the current selection
          localStorage.setItem(
            "selected_ad_categories_meta",
            JSON.stringify(this.selectItem)
          );
        } else {
          console.warn("selectedItems should be an array.");
        }
      },
      deep: true
    }
  },
};
</script>

<style scoped>
.v-select__content {
  contain: content;
}
</style>
