<template>
    <div class="bid-steratgy">
        <label for="" class="d-block roboto-bold">Bid strategy</label>
        <p class="roboto-regular title">Highest volume</p>
        <p class="roboto-regular description">
          Meta will aim to spend your entire budget and get the most results
          using the highest-volume bid strategy. This is the only bid strategy
          available for this campaign.
        </p>
      </div>
</template>