<template>
  <div class="country-select">
    <h4 class="roboto-bold mb-0">Country</h4>
    <span class="roboto-regular d-block text-description"
      >Select where you want to run this campaign. If there are additional
      requirements to run your ads in those locations, your advertising options
      will be adjusted.
    </span>
    <v-select
      return-object
      :items="listOfCountry"
      density="compact"
      variant="outlined"
      multiple
      hide-details
      item-title="name"
      item-value="key"
      :item-props="{}"
      v-model="selectedCountry"
      v-bind:item-props="{ maxWidth: '596px', minWidth: '100%' }"
    >
      <!-- Prepend Item: Countries -->
      <template v-slot:prepend-item>
        <div>
          <div
            style="cursor: pointer"
            @click="showCountries"
            class="select-country d-flex justify-space-between"
          >
            <p>Countries</p>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#5f6368"
              >
                <path d="M480-384 288-576h384L480-384Z" />
              </svg>
            </span>
          </div>
        </div>
      </template>
      <template v-slot:append-item>
        <div :style="{ maxWidth: '596px', minWidth: '100%' }">
          <div
            @click="showRegions"
            class="select-country d-flex justify-space-between"
          >
            <p>Regions</p>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#5f6368"
              >
                <path d="M480-384 288-576h384L480-384Z" />
              </svg>
            </span>
          </div>
          <!-- Region Description (Correct Placement) -->
          <div v-if="listOfRegions">
            <div
              v-for="item in allRegions"
              :key="item.id"
              class="d-flex align-start ga-2 mb-2"
            >
              <div>
                <v-checkbox-btn
                  :model-value="selectedRegions.includes(item.id)"
                  density="compact"
                />
              </div>
              <div @click="selectedRegionsFun(item)">
                <label :for="item.id">
                  <p style="font-size: 14px; color: #1c2b33">
                    {{ item.title }}
                  </p>
                  <p style="font-size: 12px; color: #1c2b33">
                    {{ item.descriptions }}
                  </p>
                </label>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Select Items -->
      <template v-slot:item="{ item, props: itemProps }">
        <label
          @click="selectItemCountry(item)"
          v-if="listOfCountries"
          v-bind="itemProps"
          :for="`${item.raw.key}`"
          class="d-flex mb-2 flex-column align-start ga-0"
        >
          <div class="d-flex mb-0 align-center ga-2">
            <v-checkbox-btn
              :model-value="
                selectedCountry.some((page) => page.key === item.raw.key)
              "
              density="compact"
            />
            <p class="roboto-regular d-block" style="font-size: 14px">
              {{ item.raw.name }}
            </p>
          </div>
        </label>
      </template>
    </v-select>
  </div>
</template>

<script>
import apiClient from "@/core/buildRequest";
export default {
  data() {
    return {
      listOfCountry: [],
      selectedCountry: JSON.parse(localStorage.getItem("meta_country_select"))
        ? JSON.parse(localStorage.getItem("meta_country_select"))
        : [] || [],
      listOfCountries: false,
      allRegions: [
        {
          id: "EEA",
          title: "European Economic Area (EEA)",
          descriptions:
            "Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Iceland, Ireland, Italy, Latvia, Liechtenstein, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, United Kingdom",
          checked: false,
        },
        {
          id: "NAFTA",
          title: "North American Free Trade Agreement",
          descriptions: "Canada, Mexico, United States",
          checked: false,
        },
        {
          id: "ASEAN",
          title: "ASEAN Free Trade Area",
          descriptions:
            "Brunei, Cambodia, Indonesia, Laos, Malaysia, Myanmar (Burma), Philippines, Singapore, Thailand, Vietnam",
          checked: false,
        },
        {
          id: "mercosur",
          title: "Mercosur",
          descriptions: "Argentina, Brazil, Paraguay, Uruguay, Venezuela",
          checked: false,
        },
        {
          id: "GCC",
          title: "Gulf Cooperation Council",
          descriptions:
            "Bahrain, Kuwait, Oman, Qatar, Saudi Arabia, United Arab Emirates",
          checked: false,
        },
        {
          id: "APEC",
          title: "Asia-Pacific Economic Cooperation",
          descriptions:
            "Australia, Brunei, Canada, Chile, China, Hong Kong, Indonesia, Japan, Malaysia, Mexico, New Zealand, Papua New Guinea, Peru, Philippines, Russia, Singapore, South Korea, Thailand, United States, Vietnam",
          checked: false,
        },
        {
          id: "CISFTA",
          title: "Commonwealth of Independent States Free Trade Area",
          descriptions:
            "Armenia, Azerbaijan, Belarus, Georgia, Kazakhstan, Kyrgyzstan, Moldova, Russia, Tajikistan, Ukraine, Uzbekistan",
          checked: false,
        },
      ],
      selectedRegions: [],
      listOfRegions: false,
    };
  },
  methods: {
    async getAllCountry() {
      await apiClient
        .get("/campaign-ads/facebook/all/countries")
        .then((response) => {
          this.listOfCountry = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    showCountries() {
      this.listOfCountries = !this.listOfCountries;
    },

    showRegions() {
      this.listOfRegions = !this.listOfRegions;
    },

   

    selectItemCountry(item) {
      this.selectedCountry = item.raw;
      localStorage.setItem("meta_country_select", item.raw.country_code);
    },
    selectedRegionsFun(item) {
    const index = this.selectedRegions.indexOf(item.id);

    if (index === -1) {
      this.selectedRegions.push(item.id);
    } else {
      this.selectedRegions.splice(index, 1);
    }
  }
  },
  mounted() {
    this.getAllCountry();
  },
  watch: {
    selectedCountry: {
      handler(newVal) {
        localStorage.setItem(
          "meta_country_select",
          JSON.stringify(newVal.map((c) => c.country_code))
        );
      },
      deep: true,
    },
  },
};
</script>
