<template>
  <v-container fluid style="padding: 0; min-height: 100vh">
    <v-row no-gutters>
      <v-col cols="12">
        <GlobalNav></GlobalNav>
      </v-col>
      <v-col cols="12">
        <div class="d-flex h-100">
          <SideBar></SideBar>
          <div class="pa-5 w-100">
            <BreadCrumb :linksData="linksData" class="mb-5" />
            <div class="leads-managements-link d-flex align-center ga-2 my-3">
              <div
                @click="$router.push({ name: 'DriveCenter' })"
                class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
                    fill="#1F666A"
                  />
                </svg>
                <span>All Images</span>
              </div>
            </div>
            <div class="mt-5 mb-5">
              <v-row class="files">
                <v-col
                  cols="6"
                  :lg="2"
                  :md="4"
                  v-for="(file, index) in store.getImages"
                  :key="index"
                >
                  <v-sheet class="ma-2 pa-4 file-box d-flex flex-column ga-4">
                    <ImageBox :file="file" @edit:file="toggleEditFunction" />
                  </v-sheet>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
  <EditFile :dialog="edit_file_dialog" :fileId="edit_file_id" />
</template>

<script>
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import Filter from "@/components/drive/Filter.vue";
import ImageBox from "@/components/drive/boxs/ImageBox.vue";
import EditFile from "@/components/drive/forms/EditFile.vue";

import { DriveStore } from "@/store/drive/DriveStore";
export default {
  name: "AllFolderFiles",
  props: {},
  components: { BreadCrumb, GlobalNav, SideBar, Filter, ImageBox, EditFile },

  data() {
    return {
      store: null,
      edit_file_dialog: false,
      edit_file_id: null,
    };
  },
  watch: {},
  computed: {},

  methods: {
    formatDate(date) {
      return format(new Date(date), "MMM dd, yyyy HH:mm");
    },
 
    toggleEditFunction(file) {
      this.edit_file_id = file;
      this.edit_file_dialog = !this.edit_file_dialog;
    },
  },

  created() {
    this.store = DriveStore();
  },
  mounted() {},
};
</script>
