import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const sendRequest = defineStore("sendRequest", {
  state: () => ({
    ad_account_id: localStorage.getItem("meta_ad_account_id"),
  }),
  actions: {
    getAdSet() {},
    async createCampaign() {
      const FormData = require("form-data");

      // First Object
      const dataPart1 = new FormData();
      dataPart1.append("name", localStorage.getItem("meta_campaign_name"));
      dataPart1.append(
        "objective",
        localStorage.getItem("meta_selected_objective")
      );
      dataPart1.append("status", "ACTIVE");

      let special_ad_categories = JSON.parse(
        localStorage.getItem("selected_ad_categories_meta")
      );

      if (Array.isArray(special_ad_categories)) {
        special_ad_categories.forEach((e, index) => {
          dataPart1.append(`special_ad_categories[${index}]`, e);
        });
      } else {
        console.error("The stored value is not an array");
      }

      //  dataPart1.append("special_ad_categories[0]", "NONE");

      dataPart1.append("optimization_goal", "");

      dataPart1.append(
        "daily_budget",
        Number(localStorage.getItem("value_meta_Budget"))
      );

      // if (localStorage.getItem("value_meta_Budget") === "null") {
      //   dataPart1.append("daily_budget", null);
      // } else {
      //   dataPart1.append(
      //     "daily_budget",
      //     Number(localStorage.getItem("value_meta_Budget"))
      //   );
      // }

      dataPart1.append(
        "billing_event",
        localStorage.getItem("charged_item") || "IMPRESSIONS"
      );

      // const countriesArray = JSON.parse(
      //   localStorage.getItem("metaSelectedCountryCodes")
      // );
      // console.log(countriesArray);

      // if (Array.isArray(countriesArray)) {
      //   countriesArray.forEach((e, index) => {
      //     dataPart1.append(`targeting[geo_locations][countries][${index}]`, e);
      //   });
      // } else {
      //   console.log("Not valid");
      // }

      dataPart1.append("targeting[geo_locations][countries][0]", "US");

      dataPart1.append("targeting[interests][0][id]", "6003139266461");
      dataPart1.append("targeting[interests][0][name]", "Movies");

      // dataPart1.append("promoted_object", "");

      // dataPart1.append('bid_strategy', 'LOWEST_COST_WITH_BID_CAP')

      dataPart1.append(
        "bid_strategy",
        [localStorage.getItem("bid_strategy_type")] || [
          "LOWEST_COST_WITHOUT_CAP",
        ]
      );

      dataPart1.append(
        "buying_type",
        localStorage.getItem("meta_buying_type") || "AUCTION"
      );

      // dataPart1.append('buying_type','AUCTION')

      dataPart1.append(
        "dynamic_creative_optimization_mode",
        localStorage.getItem("dynamic_creative_status") === "false"
          ? "OFF"
          : "ON" || "OFF"
      );

      // dataPart1.append('dynamic_creative_optimization_mode','OFF')

      let endDate = localStorage.getItem("meta_end_date");
      if (endDate) {
        let formattedEndDate = new Date(endDate).toISOString();
        dataPart1.append("stop_time", formattedEndDate);
      } else {
        dataPart1.append("stop_time", "");
      }

      let startData = localStorage.getItem("meta_start_date");
      if (startData) {
        let formattedStartDate = new Date(startData).toISOString();
        dataPart1.append("start_time", formattedStartDate);
      } else {
        dataPart1.append("start_time", null);
      }

      let fromLS = JSON.parse(localStorage.getItem("budgetSchedulingPanels"));
      if (fromLS && Array.isArray(fromLS)) {
        fromLS.forEach((e, index) => {
          const roundTo15Minutes = (dateTime) => {
            let date = new Date(dateTime);
            let minutes = date.getUTCMinutes();
            let remainder = minutes % 15;

            if (remainder !== 0) {
              date.setUTCMinutes(minutes + (15 - remainder));
              date.setUTCSeconds(0, 0);
            }
            return date.toISOString();
          };

          const startISO = roundTo15Minutes(e.startDateTime);
          const endISO = roundTo15Minutes(e.endDateTime);

          dataPart1.append(
            `budget_schedule_specs[${index}][time_start]`,
            startISO
          );
          dataPart1.append(`budget_schedule_specs[${index}][time_end]`, endISO);
          dataPart1.append(
            `budget_schedule_specs[${index}][budget_value]`,
            e.budgetValue
          );
          dataPart1.append(
            `budget_schedule_specs[${index}][budget_value_type]`,
            e.daily_budget_type_selected.value
          );
          dataPart1.append(
            `budget_schedule_specs[${index}][recurrence_type]`,
            "ONE_TIME"
          );
        });
      } else {
        console.error(
          "Invalid or empty data in localStorage for budgetSchedulingPanels"
        );
      }

      // dataPart1.append("budget_schedule_specs[0][time_start]", "2025-01-27");

      // dataPart1.append("budget_schedule_specs[0][time_end]", "2025-01-31");

      // dataPart1.append("budget_schedule_specs[0][budget_value]", 15);

      // dataPart1.append(
      //   "budget_schedule_specs[0][budget_value_type]",
      //   "ABSOLUTE"
      // );

      //dataPart1.append("budget_schedule_specs[0][recurrence_type]", "ONE_TIME");

      // dataPart1.append("campaign_optimization_type", "NONE");
      // dataPart1.append(
      //   "is_skadnetwork_attribution",
      //   localStorage.getItem("is_skadnetwork_attribution") === "true"
      //     ? "true"
      //     : "false" || "false"
      // );
      // dataPart1.append("is_using_l3_schedule", "false");
      // dataPart1.append("topline_id", "");
      // dataPart1.append(
      //   "spend_cap",
      //   Number(localStorage.getItem("setLimitValue")) || 100
      // );

      dataPart1.append("spend_cap", 10000);

      // dataPart1.append("lifetime_budget", 350);

      // if (localStorage.getItem("value_meta_Budget_max") === "null") {
      //    dataPart1.append("lifetime", 0);
      // } else {
      //   dataPart1.append(
      //     "lifetime_budget",
      //     Number(localStorage.getItem("value_meta_Budget_max") ||0 )
      //   );
      // }

      // dataPart1.append("special_ad_category_country[0]", "EG");
      dataPart1.append("source_campaign_id", "");

      // Second Object
      const dataPart2 = new FormData();
      // dataPart2.append("adgroup_name", localStorage.getItem("metaAdSetName"));

      dataPart2.append("adgroup_name", "test adgroup name");

      // if (localStorage.getItem("value_meta_Budget") === "null") {
      //   dataPart2.append("adgroup_daily_budget", 0);
      // } else {
      //   dataPart2.append(
      //     "adgroup_daily_budget",
      //     Number(localStorage.getItem("value_meta_Budget"))
      //   );
      // }

      // if (localStorage.getItem("value_meta_Budget_max") === "null") {
      //   dataPart2.append("adgroup_bid_amount", 1);
      // } else {
      //   dataPart2.append(
      //     "adgroup_bid_amount",
      //     Number(localStorage.getItem("value_meta_Budget_max"))
      //   );
      // }

      dataPart2.append(
        "adgroup_billing_event",
        localStorage.getItem("charged_item") || "IMPRESSIONS"
      );

      dataPart2.append(
        "adgroup_optimization_goal",
        localStorage.getItem("meta_optimization_goal")
        
      );

      // dataPart2.append("campaign_id", "120212114758460358");

      dataPart2.append(
        "adgroup_promoted_object[page_id]",
        localStorage.getItem("facebook_page_id")
      );

      let checked_placements = JSON.parse(
        localStorage.getItem("checked_placements")
      );

      if (Array.isArray(checked_placements)) {
        checked_placements.forEach((e, index) => {
          dataPart2.append(
            `adgroup_targeting[facebook_positions][${index}]`,
            e
          );
        });
      } else {
        dataPart2.append("adgroup_targeting[facebook_positions][0]", "feed");
      }

      // if (Array.isArray(countriesArray)) {
      //   countriesArray.forEach((e, index) => {
      //     dataPart2.append(
      //       `adgroup_targeting[geo_locations][countries][${index}]`,
      //       e
      //     );
      //   });
      // } else {
      //   console.log("invalid");
      // }

      dataPart2.append("adgroup_targeting[geo_locations][countries][0]", "US");

      // dataPart2.append(
      //   "adgroup_targeting[geo_locations][regions][0][key]",
      //   "3847"
      // );

      // dataPart2.append(
      //   "adgroup_targeting[geo_locations][cities][0][key]",
      //   Number(777934)
      // );

      //wait for valid value
      // dataPart2.append(
      //   "adgroup_targeting[geo_locations][cities][0][radius]",
      //   15
      // );

      // dataPart2.append(
      //   "adgroup_targeting[geo_locations][cities][0][distance_unit]",
      //   "mile"
      // );

      let genders = localStorage.getItem("meta_gender");
      if (genders === "all_gender") {
        [1, 2].forEach((value, index) => {
          dataPart2.append(
            `adgroup_targeting[genders][${index}]`,
            Number(value)
          );
        });
      } else if (genders === "Men") {
        [2].forEach((value, index) => {
          dataPart2.append(
            `adgroup_targeting[genders][${index}]`,
            Number(value)
          );
        });
      } else {
        [1].forEach((value, index) => {
          dataPart2.append(
            `adgroup_targeting[genders][${index}]`,
            Number(value)
          );
        });
      }

      // dataPart2.append("adgroup_targeting[age_max]", 55);

      dataPart2.append(
        "adgroup_targeting[age_max]",
        Number(localStorage.getItem("set_max_age_value"))
      );

      // dataPart2.append("adgroup_targeting[age_min]", 18);

      dataPart2.append(
        "adgroup_targeting[age_min]",
        Number(localStorage.getItem("set_min_age_value"))
      );

      // dataPart2.append("adgroup_targeting[publisher_platforms][0]", "facebook");
      // dataPart2.append(
      //   "adgroup_targeting[publisher_platforms][1]",
      //   "audience_network"
      // );

      let platFormsPublishers = JSON.parse(
        localStorage.getItem("meta_platform_type")
      );

      if (Array.isArray(platFormsPublishers)) {
        platFormsPublishers.forEach((e, index) => {
          dataPart2.append(
            `adgroup_targeting[publisher_platforms][${index}]`,
            e
          );
        });
      } else {
        dataPart2.append("adgroup_targeting[publisher_platforms][0]", "all");
      }

      // dataPart2.append("adgroup_targeting[device_platforms][0]", "mobile");

      let device_platforms = localStorage.getItem("meta_devices_type");

      if (Array.isArray(device_platforms)) {
        device_platforms.forEach((e, index) => {
          dataPart2.append(`adgroup_targeting[device_platforms][${index}]`, e);
        });
      } else {
        dataPart2.append("adgroup_targeting[device_platforms][0]", "desktop");
        dataPart2.append("adgroup_targeting[device_platforms][1]", "mobile");
      }

      dataPart2.append(
        "adgroup_targeting[flexible_spec][0][interests][0][id]",
        " 6002925969459"
      );
      dataPart2.append(
        "adgroup_targeting[flexible_spec][0][interests][0][name]",
        "watching movies"
      );
      dataPart2.append(
        "adgroup_targeting[flexible_spec][0][interests][1][id]",
        "6002839660079"
      );
      dataPart2.append(
        "adgroup_targeting[flexible_spec][0][interests][1][name]",
        " Cosmetics (personal care)"
      );
      dataPart2.append("adgroup_status", "ACTIVE");
      // dataPart2.append("adgroup_dsa_beneficiary", "brandifyuae");

      if (fromLS && Array.isArray(fromLS)) {
        fromLS.forEach((e, index) => {
          dataPart2.append(
            `adgroup_budget_schedule_specs[${index}][time_start]`,
            e.startDateTime
          );
          dataPart2.append(
            `adgroup_budget_schedule_specs[${index}][time_end]`,
            e.endDateTime
          );
          dataPart2.append(
            `adgroup_budget_schedule_specs[${index}][budget_value]`,
            e.budgetValue
          );
          dataPart2.append(
            `adgroup_budget_schedule_specs[${index}][budget_value_type]`,
            e.daily_budget_type_selected.value
          );
          dataPart1.append(
            `budget_schedule_specs[${index}][recurrence_type]`,
            "ONE_TIME"
          );
        });
      } else {
        console.error(
          "Invalid or empty data in localStorage for budgetSchedulingPanels"
        );
      }

      // dataPart2.append("adgroup_start_time", " 2025-01-27");
      dataPart2.append(
        "adgroup_start_time",
        localStorage.getItem("meta_start_date") ||
          new Date().toISOString().split("T")[0]
      );
      // dataPart2.append("adgroup_end_time", "  2025-01-31");
      dataPart2.append(
        "adgroup_end_time",
        localStorage.getItem("meta_end_date") || ""
      );

      // dataPart2.append("adgroup_tune_for_category", "EMPLOYMENT");

      const selectedAdCategoriesMeta = localStorage.getItem(
        "selected_ad_categories_meta"
      );
      const firstCategory = selectedAdCategoriesMeta
        ? JSON.parse(selectedAdCategoriesMeta)[0]
        : "EMPLOYMENT";
      dataPart2.append("adgroup_tune_for_category", firstCategory);

      dataPart2.append("adgroup_source_adset_id", "");
      dataPart2.append("adgroup_rf_prediction_id", "");

      // can't find input for this
      dataPart2.append("adgroup_multi_optimization_goal_weight", "BALANCED");

      // dataPart2.append(
      //   "adgroup_min_budget_spend_percentage",
      //   Number(localStorage.getItem("meta_daily_minimum"))
      // );
      dataPart2.append(
        "adgroup_max_budget_spend_percentage",
        localStorage.getItem("meta_daily_maximum")
      );
      dataPart2.append(
        "adgroup_lifetime_spend_cap",
        Number(localStorage.getItem("setLimitValue"))
      );

      // dataPart2.append(
      //   "adgroup_lifetime_min_spend_target",
      //   localStorage.getItem("meta_daily_minimum")
      // );

      // can't find input for this
      // dataPart2.append("adgroup_lifetime_imps", "1000000");

      // dataPart2.append("adgroup_lifetime_budget", 350);

      // if (localStorage.getItem("value_meta_Budget_max") === "null") {
      //   dataPart2.append("adgroup_lifetime_budget", 0);
      // } else {
      //   dataPart2.append("adgroup_lifetime_budget", 0);
      // }

      dataPart2.append("adgroup_is_dynamic_creative", "false");
      dataPart2.append("adgroup_existing_customer_budget_percentage", "50");
      dataPart2.append("adgroup_destination_type", "WEBSITE");
      // dataPart2.append("adgroup_daily_spend_cap", "5000");
      // dataPart2.append(
      //   "adgroup_daily_min_spend_target",
      //   Number(localStorage.getItem("meta_daily_minimum"))
      // );
      // dataPart2.append("adgroup_daily_imps", "10000");
      dataPart2.append("adgroup_adset_schedule[0][start_minute]", "480");
      dataPart2.append("adgroup_adset_schedule[0][end_minute]", "1020");
      dataPart2.append("adgroup_adset_schedule[0][days][0]", "1");
      dataPart2.append("adgroup_adset_schedule[0][timezone_type]", "USER");

      const dataPart3 = new FormData();

      dataPart3.append("ad_name", "first_ad_Ad");
      dataPart3.append("ad_creative[creative_id]", "583809467866143");
      dataPart3.append("ad_status", "ACTIVE");
      try {
        const combinedData = new FormData();
        for (const [key, value] of dataPart1.entries()) {
          combinedData.append(`${key}`, value);
        }

        for (const [key, value] of dataPart2.entries()) {
          combinedData.append(`${key}`, value);
        }

        for (const [key, value] of dataPart3.entries()) {
          combinedData.append(`${key}`, value);
        }

        for (const [key, value] of combinedData.entries()) {
          console.log(`${key}: ${value}`);
        }

        const response = await apiClient.post(
          `/campaign-ads/facebook/create/campaign/${this.ad_account_id}`,
          combinedData
        );

        console.log("Campaign creation response:", response);
        return response;
      } catch (error) {
        console.error("Campaign creation failed:", error);
        throw error;
      }
    },
  },
});
