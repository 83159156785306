<template>
  <v-select label="Select" v-model="selected_type" :items="status" :class="['contact-v-select', statusClass]"
    @update:modelValue="updateType(item.id)" variant="" no-details>
    <template v-slot:item="{ item, props }">
      <v-list-item v-bind="props" :title="item.raw.title" :style="{
        color:
          item.raw.title === 'phone_call'
            ? '#0464BC'
            : item.raw.title === 'virtual_meeting'
              ? '#2E6B30'
              : item.raw.title === 'f2f_meeting'
                ? '#D91313'
                : '',
        background:
          item.raw.title === 'phone_call'
            ? '#F5FAFE'
            : item.raw.title === 'virtual_meeting'
              ? '#E3F9EB'
              : item.raw.title === 'f2f_meeting'
                ? '#FEF3F2'
                : '',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px 0px',
      }">
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import apiClient from "@/core/buildRequest";
import { useEventsStore } from "@/store/crm/useEventsStore";
import { useGeneralStore } from "@/store/General";
export default {
  name: "EventType",
  props: ["item"],
  data() {
    return {
      store: useGeneralStore(),
      selected_type: "",
      status: [{ title: "phone_call", id: 0 }, { title: "virtual_meeting", id: 1 }, { title: "f2f_meeting", id: 2 }],
    };
  },
  mounted() {
    this.selected_type = this.item.type;
  },
  computed: {
    statusClass() {
      switch (this.selected_type) {
        case "virtual_meeting":
          return "contact-v-select-green";
        case "phone_call":
          return "contact-v-select-blue";
        case "f2f_meeting":
          return "contact-v-select-red";
        default:
          return ""; // Return empty if no class is applicable
      }
    },
  },
  methods: {
    async updateType(id) {
      
      await apiClient
        .post(`/leads-managment/events/type/${id}`, {
          type: this.selected_type,
        })
        .then((res) => {
          const deal_store = useEventsStore();
          deal_store?.updateType(this.id, this.selected_type);
          this.store.showSnackbar(res.data.msg, res.data.key);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
