<template>
  <v-col :cols="12" :lg="9">
    <div class="bold-text mb-2">Attachments</div>
    <v-row>
      <v-col
        v-for="img in store?.last_attachment"
        :key="img"
        class="d-flex child-flex"
        cols="4"
      >
        <v-img
          @click="showImage(img.file_path)"
          :lazy-src="img.file_path"
          :src="img.file_path"
          aspect-ratio="1"
          class="bg-grey-lighten-2 rounded cursor-pointer"
          cover
        >
          <template v-slot:placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular
                color="grey-lighten-5"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-col>
  <v-overlay v-model="dialog" location="center" />
  <v-dialog v-model="dialog" width="auto">
    <v-card max-width="auto">
      <template v-slot:text>
        <img width="100%" :src="image_preview" alt="" />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { useEditTaskStore } from "@/store/editTaskStore";
import { VFileUpload } from "vuetify/labs/VFileUpload";

export default {
  name: "TaskAttachments",
  components: {
    VFileUpload,
  },
  data() {
    return {
      store: useEditTaskStore(),
      image_preview: "",
      dialog: false,
    };
  },
  watch: {},
  methods: {
    showImage(url) {
      this.image_preview = url;
      this.dialog = true;
    },
  },
};
</script>
