<template>
  <div class="payment-form d-flex flex-column ga-3 w-50 mt-5 billing-select">
    <label for="" class="profile-box-title">
      {{ $t("payment_method_details") }}
    </label>
    <label for="">{{ $t("payment_method_nickname") }}</label>
    <Input
      v-model="payemnt_method_nickname"
      type="text"
      :placeholder="$t('payment_method_nickname')"
    />
    <label for="">{{ $t("cardholder_name") }}</label>
    <Input
      v-model="cardholder_name"
      type="text"
      :placeholder="$t('cardholder_name')"
    />
    <div class="d-flex align-center justify-space-between ga-2">
      <div class="d-flex flex-column ga-3 w-100">
        <label for="">{{ $t("card_number") }}</label>
        <Input
          v-model="card_number"
          type="text"
          :placeholder="$t('card_number')"
        />
      </div>
      <div class="d-flex flex-column ga-3 w-100">
        <label for="">{{ $t("expiration") }}</label>
        <Input
          v-model="expiration"
          type="month"
          :placeholder="$t('expiration')"
        />
      </div>
      <div class="d-flex flex-column ga-3 w-100">
        <label for="">{{ $t("cvv") }}</label>
        <Input v-model="cvv" type="number" :placeholder="$t('cvv')" />
      </div>
    </div>
    <label for="Country">{{ $t("country") }}</label>
    <v-autocomplete
      v-model="country_id"
      :items="countries"
      item-value="id"
      item-title="name"
      hide-details
      label=""
    >
    </v-autocomplete>
    <label for="">{{ $t("zip_code") }}</label>
    <Input v-model="zip_code" type="text" :placeholder="$t('zip_code')" />
    <div class="d-flex mt-5 justify-end ga-3 mt-5">
      <button class="cancel-btn cursor-pointer">{{ $t("cancel") }}</button>
      <button class="choose-btn cursor-pointer" style="width: auto">
        {{ $t("create_payment_method") }}
      </button>
    </div>
  </div>
</template>
<script>
import Input from "@/components/crm/assets/Input.vue";

export default {
  name: "CreditCardForm",
  components: { Input },
  data() {
    return {
      payemnt_method_nickname: "",
      cardholder_name: "",
      card_number: "",
      expiration: "",
      country_id: "",
      countries: [
        { id: 1, name: "United States" },
        { id: 2, name: "Brazil" },
        { id: 3, name: "Germany" },
        { id: 4, name: "France" },
        { id: 5, name: "United Kingdom" },
      ],
      cvv: "",
      zip_code: "",
    };
  },
};
</script>
