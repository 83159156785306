<template>
  <v-container
    fluid=""
    style="margin-top: 0%; padding: 0% 0% 0 0; min-height: calc(100vh - 58px)"
  >
    <v-row
      style="
        height: 100%;
        margin: 0;
        padding: 0px 0px 0 0px;
        position: relative;
      "
    >
      <v-col cols="12" class="pa-0">
        <GlobalNav />
      </v-col>
      <v-col
        cols="12"
        style="height: 100%"
        class="pa-0 d-flex align-start ga-6"
      >
        <SideBar />
        <div class="insights-contant mt-7 w-100">
          <BreadCrumb :linksData="linksData" class="mb-5" />
          <v-row>
            <v-col cols="9" sm="6" md="9">
              <v-card
                style="
                  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.2);
                  height: 100%;
                "
                class="insights-card px-5 pt-5 pb-14 rounded-lg"
              >
                <div class="charts-title position-absolute">
                  <p>{{ $t('paid_impressions_by') }}</p>
                  <h4>{{ $t('age_&_gender') }}</h4>
                </div>
                <BarChart :dataBar="barChartData" :firstTime="firstTimeChart" />
              </v-card>
            </v-col>
            <v-col cols="3" sm="6" md="3">
              <v-card
                class="total-impersions mb-3 d-flex align-center justify-space-between"
              >
                <div class="imperions-data">
                  <div class="mb-4 imperions-data-sub">
                    <p>{{ totalPaiedImprsions.toLocaleString("en-US") }}</p>
                    <span> {{ $t('total_paid_impressions') }} </span>
                  </div>
                  <div class="piad-ecpm">
                    <p>
                      <span>$9.70</span>
                      <span> {{ $t('Paid_eCPM') }} </span>
                    </p>
                  </div>
                </div>
                <div class="imperions-icon">
                  <div class="shadow-lg">
                    <img
                      src="@/assets/soft-ware-img/impressions-icon.svg"
                      alt=""
                    />
                  </div>
                </div>
              </v-card>
              <v-card class="male-female-statistics mb-3">
                <div
                  class="male-statistics mb-2 d-flex align-center justify-space-between px-2 py-3"
                >
                  <div :style="{ width: malePercentage }" class="overlay"></div>
                  <div class="male-number">
                    <p>{{ malePercentage }}</p>
                    <p> {{ $t('male') }} </p>
                  </div>
                  <div class="male-impressions">
                    <p>
                      {{
                        (
                          total_male_impressions + total_female_impressions
                        ).toLocaleString("en-US")
                      }}
                      {{ $t('paid_immpressions') }}
                    </p>
                  </div>
                </div>
                <div
                  class="male-statistics d-flex align-center justify-space-between px-2 py-3"
                >
                  <div
                    :style="{ width: femalePercentage }"
                    class="overlay"
                  ></div>
                  <div class="male-number">
                    <p>{{ femalePercentage }}</p>
                    <p>{{ $t('female') }}</p>
                  </div>
                  <div class="male-impressions">
                    <p>
                      {{
                        (
                          total_male_impressions + total_female_impressions
                        ).toLocaleString("en-US")
                      }}
                      {{ $t('paid_immpressions') }}
                    </p>
                  </div>
                </div>
              </v-card>
              <v-card class="male-female-statistics">
                <div
                  class="male-statistics mb-2 d-flex align-center justify-space-between px-2 py-3"
                >
                  <div :style="{ width: iosPercentage }" class="overlay"></div>
                  <div class="male-number">
                    <p>{{ iosPercentage }}</p>
                    <p>Ios</p>
                  </div>
                  <div class="male-impressions">
                    <p>
                      {{
                        (
                          total_ios_impressions + total_andriod_impressions
                        ).toLocaleString("en-US")
                      }}
                      {{ $t('Paid impressions') }}
                    </p>
                  </div>
                </div>
                <div
                  class="male-statistics d-flex align-center justify-space-between px-2 py-3"
                >
                  <div
                    :style="{ width: andriodPercentage }"
                    class="overlay"
                  ></div>
                  <div class="male-number">
                    <p>{{ andriodPercentage }}</p>
                    <p>{{ $t('android') }}</p>
                  </div>
                  <div class="male-impressions">
                    <p>
                      {{
                        (
                          total_ios_impressions + total_andriod_impressions
                        ).toLocaleString("en-US")
                      }}
                      {{ $t('paid_immpressions') }}
                    </p>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="9" sm="6" md="9">
              <div class="card top-inserts">
                <div class="card-header">
                  <h2>{{ $t('top_interests') }}</h2>
                  <v-select
                    :items="itemsForSelect"
                    density="compact"
                    variant="solo"
                  ></v-select>
                </div>
                <div class="card-body py-5">
                  <div
                    v-for="summary in topThreeInterestSummary"
                    :key="summary.id"
                    class="shoppers d-flex align-center justify-space-between mb-8"
                  >
                    <p>{{ summary.interest_category_name }}</p>
                    <div class="progress-number d-flex align-center ga-2">
                      <span style="font-size: 14px; font-weight: 400">{{
                        summary.impressions.toLocaleString("en-US")
                      }}</span>
                      <div class="progress-bar">
                        <span :style="{ width: summary.percentage }"></span>
                      </div>
                      <span style="font-size: 14px; font-weight: 600">{{
                        summary.percentage
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="3" sm="6" md="3">
              <div class="card top-inserts top-location">
                <div class="card-header">
                  <h2>{{ $t('top_locations') }}</h2>
                </div>
                <div
                  class="card-body d-flex align-center justify-space-between pt-5"
                >
                  <div>
                    <p>{{ countryName }}</p>
                    <p>97,254</p>
                  </div>
                  <div class="chart-container">
                    <Doughnut
                      style="width: 150px; height: 150px"
                      :data="chartData"
                      :options="chartOptions"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import BarChart from "@/components/BarChart.vue";
import { Doughnut } from "vue-chartjs";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import apiClient from "@/core/buildRequest";

ChartJS.register(ArcElement, Tooltip, Legend);
export default {
  components: {
    GlobalNav,
    SideBar,
    BreadCrumb,
    BarChart,
    Doughnut,
  },
  data() {
    return {
      linksData: [
        { name: "ads", link: "/home-page" },
        { name: "ads_performance", link: "" },
      ],
      totalPaiedImprsions: 0,
      malePercentage: 0,
      femalePercentage: 0,
      iosPercentage: 0,
      andriodPercentage: 0,
      total_male_impressions: 0,
      total_female_impressions: 0,
      total_ios_impressions: 0,
      total_andriod_impressions: 0,
      itemsForSelect: [],
      interestSummary: [],
      countryName: "",
      barChartData: {
        labels: [],
        datasets: [
          {
            label: "Female",
            backgroundColor: "rgba(237, 237, 237, 1)",
            borderColor: "#4fc3f7",
            data: [],
            borderRadius: 5,
            fill: true,
          },
          {
            label: "Male",
            backgroundColor: "rgba(53, 140, 145,0.7)",
            borderColor: "#f87979",
            borderRadius: 5,
            data: [],
            fill: true,
          },
        ],
      },
      firstTimeChart: true,
    };
  },
  computed: {
    userId() {
      return this.$route.params.id;
    },
    chartData() {
      return {
        labels: ["Impressions"],
        datasets: [
          {
            backgroundColor: ["rgba(35, 114, 118, 1)"],
            data: [60],
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      };
    },
    formattedImpressions() {
      return this.stats.impressions.toLocaleString("en-US");
    },
    topThreeInterestSummary() {
      return [...this.interestSummary]
        .sort((a, b) => b.impressions - a.impressions)
        .slice(0, 3);
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      console.error("Campaign ID is missing from route params");
      return;
    }
    const platform = this.$route.params.platform;
    const ad_account_id = this.$route.params.ad_account_id;
    const campaignId = this.$route.params.id;
    if (platform === "snapchat") {
      apiClient
        .get(
          `/ad-performance/snapchat/campaign/performance/adaccountID=${ad_account_id}/campaignId=${campaignId}`
        )
        .then((res) => {
          this.firstTimeChart = false;
          this.barChartData = res.data.data.insights;
          console.log("parent", this.barChartData);

          const insights = res.data.data.insights;
          const inserts = res.data.data.interests;
          const countryMapping = {
            US: "United States",
            CA: "Canada",
            DE: "Germany",
            sa: "Saudi Arabia",
            // Add other mappings as necessary
          };
          // console.log(insights[13].dimension_stats[8].country);
          Object.keys(insights).forEach((key) => {
            if (
              insights[key].dimension_stats &&
              Array.isArray(insights[key].dimension_stats)
            ) {
              insights[key].dimension_stats.forEach((item, index) => {
                if (item.country) {
                  // Check if there's a country name mapped to the country_id
                  const countryName = countryMapping[item.country];
                  if (countryName) {
                    console.log(`Country for index ${index}: ${countryName}`);
                    this.countryName = countryName;
                  } else {
                    console.warn(
                      `Country ID ${item.country} not found in mapping`
                    );
                  }
                } else {
                  console.warn(
                    `No country_id found for index ${index} in key: ${key}`
                  );
                }
              });
            } else {
              console.warn(`No dimension_stats found for key: ${key}`);
            }
          });

          Object.keys(inserts).forEach((key) => {
            if (inserts[key].interest_summary) {
              inserts[key].interest_summary.forEach((category_name) => {
                this.interestSummary.push(category_name);
                this.itemsForSelect.push(category_name.interest_category_name);
              });
            } else {
              console.warn(`No interest_summary found for key: ${key}`);
            }
          });
          Object.keys(insights).forEach((key) => {
            const totalPaiedImpressions =
              insights[key].percentages.total_male_impressions +
              insights[key].percentages.total_female_impressions +
              insights[key].percentages.total_female_impressions +
              insights[key].percentages.total_android_impressions;
            this.totalPaiedImprsions = totalPaiedImpressions;
            const malePercentage = insights[key].percentages.male_percentage;
            this.malePercentage = malePercentage;
            const femalePercentage =
              insights[key].percentages.female_percentage;
            this.femalePercentage = femalePercentage;
            const iosPercentage = insights[key].percentages.ios_percentage;
            this.iosPercentage = iosPercentage;
            const andriodPercentage =
              insights[key].percentages.android_percentage;
            this.andriodPercentage = andriodPercentage;
            this.total_male_impressions =
              insights[key].percentages.total_male_impressions;

            this.total_female_impressions =
              insights[key].percentages.total_female_impressions;
            this.total_ios_impressions =
              insights[key].percentages.total_ios_impressions;
            this.total_andriod_impressions =
              insights[key].percentages.total_android_impressions;
          });
        });
    }
  },
};
</script>
