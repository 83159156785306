<template>
    <div class="call-to-action mt-6">
        <div class=" mb-4 d-flex align-center justify-space-between">
            <div class="header">
                <h4>Call to action</h4>
                <p class="description">Choose a call-to-action button that will appear on your ad</p>
            </div>
        </div>
        <v-select v-model="store.selectedCallToAction" :items="store.callToActionOptions" item-title="label"
            item-value="value" variant="outlined" density="comfortable" placeholder="Select call to actions"
            class="call-to-action-select" return-object multiple chips closable-chips hide-details
            @update:model-value="store.getSelectedCount()"
            :menu-props="{ maxHeight: '300px', contentClass: 'call-to-action-menu' }">
            <template v-slot:item="{ props }">
                <v-list-item v-bind="props" class="call-to-action-item">
                    <template v-slot:prepend>
                        <v-checkbox-btn :model-value="props.selected" color="#009995" hide-details></v-checkbox-btn>
                    </template>
                </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2" :text="item.raw.title"></v-chip>

                <span v-if="index === 2" class="text-grey text-caption align-self-center">
                    (+{{ value.length - 2 }} others)
                </span>
            </template>
        </v-select>
        <div class="interactive-add-ons mb-8 mt-7">
            <h4 class="title mb-2">Interactive add-ons</h4>
            <div class="btn-group">
                <button class="create-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                        fill="#121415">
                        <path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" />
                    </svg>
                    <span>Create</span>
                </button>
                <button class="choose-library-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                        fill="#121415">
                        <path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" />
                    </svg>
                    Choose from library
                </button>
            </div>
        </div>
        <v-divider></v-divider>
    </div>
</template>

<script>
import { adCreative } from '@/store/tiktokStore/adCreative';

export default {
    name: 'CallToAction',
    data() {
        return {
            store: adCreative()
        }
    },
}
</script>

<style scoped>
.call-to-action-select {
    max-width: 100%;
}

.call-to-action-select :deep(.v-field__input) {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 40px;
    font-size: 14px;
}

.call-to-action-select :deep(.v-field--variant-outlined .v-field__outline__start) {
    border-color: #e0e0e0;
}

.call-to-action-select :deep(.v-field--variant-outlined .v-field__outline__end) {
    border-color: #e0e0e0;
}

.call-to-action-select :deep(.v-field--variant-outlined:hover .v-field__outline__start) {
    border-color: #bdbdbd;
}

.call-to-action-select :deep(.v-field--variant-outlined:hover .v-field__outline__end) {
    border-color: #bdbdbd;
}

.call-to-action-item {
    padding: 12px 16px;
    min-height: 48px;
    cursor: pointer;
}

.call-to-action-item:hover {
    background-color: #f5f5f5;
}

:deep(.call-to-action-menu) {
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
</style>