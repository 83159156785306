<template>
  <v-col :cols="12" :lg="9">
    <div class="bold-text mb-2">Attachments</div>
    <v-file-upload
      density="default"
      v-model="store.attachments"
      multiple
      clearable
    ></v-file-upload>
  </v-col>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";
// import vueFilePond from 'vue-filepond';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import 'filepond/dist/filepond.min.css';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { VFileUpload } from "vuetify/labs/VFileUpload";
// const FilePond = vueFilePond(
//   FilePondPluginFileValidateType,
//   FilePondPluginImagePreview
// );

export default {
  name: "TaskAttachments",
  components: {
    VFileUpload,
  },
  data() {
    return {
      store: useTaskStore(),
    };
  },
};
</script>
