<template>
  <div class="w-100">
    <GlobalNav />
    <v-container
      class="parent-container"
      id="parent-container"
      style="
        background-color: rgba(46, 152, 158, 0.04);
        border-radius: 24px 24px 0 0;
        margin-top: 0%;
        max-width: 1610px;
      "
    >
      <v-row class="px-15">
        <div class="modules-type">
          <router-link
            :class="{
              'disabled-module': !HavePermission('campaign-ads.create'),
            }"
            :to="
              !HavePermission('campaign-ads.create') ? '#' : '/create-new-camp'
            "
            v-tooltip:top="
              !HavePermission('campaign-ads.create')
                ? `⛔ ${$t('not_authorized')}`
                : $t('CreatAD/Campain')
            "
          >
            <div class="card">
              <div class="header">
                <img src="@/assets/soft-ware-img/asSteDetails.svg" alt="" />
              </div>
              <div class="card-body">
                <p>{{ $t("CreatAD/Campain") }}</p>
              </div>
            </div>
          </router-link>
          <router-link
            :class="{
              'disabled-module': !HavePermission('leads-managment.read'),
            }"
            :to="
              !HavePermission('leads-managment.read')
                ? '#'
                : '/leads-management'
            "
            v-tooltip:top="
              !HavePermission('leads-managment.read')
                ? `⛔ ${$t('not_authorized')}`
                : $t('LeadsManagement')
            "
          >
            <div class="card">
              <div class="header">
                <img src="@/assets/soft-ware-img/leadsMangement.svg" alt="" />
              </div>
              <div class="card-body">
                <p>{{ $t("LeadsManagement") }}</p>
              </div>
            </div>
          </router-link>
          <router-link
            :class="{
              'disabled-module': !HavePermission('message-center.read'),
            }"
            :to="!HavePermission('message-center.read') ? '#' : '/'"
            v-tooltip:top="
              !HavePermission('message-center.read')
                ? `⛔ ${$t('not_authorized')}`
                : $t('message-center')
            "
          >
            <div class="card">
              <div class="header">
                <img src="@/assets/soft-ware-img/massage-center.svg" alt="" />
              </div>
              <div class="card-body">
                <p>{{ $t("MessagesCenter") }}</p>
              </div>
            </div>
          </router-link>
          <router-link
            :class="{
              'disabled-module': !HavePermission('calender.read'),
            }"
            :to="!HavePermission('calender.read') ? '#' : '/calender'"
            v-tooltip:top="
              !HavePermission('calender.read')
                ? `⛔ ${$t('not_authorized')}`
                : $t('calender')
            "
          >
            <div class="card">
              <div class="header">
                <img src="@/assets/soft-ware-img/adsCalender.svg" alt="" />
              </div>
              <div class="card-body">
                <p>{{ $t("calender") }}</p>
              </div>
            </div>
          </router-link>
          <router-link
            :class="{
              'disabled-module': !HavePermission('ad-performance.read'),
            }"
            :to="
              !HavePermission('ad-performance.read') ? '#' : '/ads-performance'
            "
            v-tooltip:top="
              !HavePermission('ad-performance.read')
                ? `⛔ ${$t('not_authorized')}`
                : $t('ADsPerformance')
            "
          >
            <div class="card">
              <div class="header">
                <img src="@/assets/soft-ware-img/adsPerformance.svg" alt="" />
              </div>
              <div class="card-body">
                <p>{{ $t("ADsPerformance") }}</p>
              </div>
            </div>
          </router-link>

          <router-link
            :class="{
              'disabled-module': !HavePermission('publish-posts.read'),
            }"
            :to="!HavePermission('publish-posts.read') ? '#' : '/publish-post'"
            v-tooltip:top="
              !HavePermission('publish-posts.read')
                ? `⛔ ${$t('not_authorized')}`
                : $t('PublishPosts')
            "
          >
            <div class="card">
              <div class="header">
                <img src="@/assets/soft-ware-img/puplishPost.svg" alt="" />
              </div>
              <div class="card-body">
                <p>{{ $t("PublishPosts") }}</p>
              </div>
            </div>
          </router-link>

          <router-link
            :class="{
              'disabled-module': !HavePermission('engagement-insights.read'),
            }"
            :to="
              !HavePermission('engagement-insights.read')
                ? '#'
                : '/engagement-insights'
            "
            v-tooltip:top="
              !HavePermission('engagement-insights.read')
                ? `⛔ ${$t('not_authorized')}`
                : $t('engagement_and_insights')
            "
          >
            <div class="card">
              <div class="header">
                <img
                  src="@/assets/soft-ware-img/Engagement&Insights.svg"
                  alt=""
                />
              </div>
              <div class="card-body">
                <p>{{ $t("engagement_and_insights") }}</p>
              </div>
            </div>
          </router-link>
          <router-link
            :class="{
              'disabled-module': !HavePermission('my-drive.read'),
            }"
            :to="!HavePermission('my-drive.read') ? '#' : '/drive-center'"
            v-tooltip:top="
              !HavePermission('my-drive.read')
                ? `⛔ ${$t('not_authorized')}`
                : $t('MyDrive')
            "
          >
            <div class="card">
              <div class="header">
                <img
                  src="@/assets/soft-ware-img/homePageIconMyDrive.svg"
                  alt=""
                />
              </div>
              <div class="card-body">
                <p>{{ $t("MyDrive") }}</p>
              </div>
            </div>
          </router-link>

          <!-- <router-link to="">
            <div class="card">
              <div class="header">
                <img
                  src="@/assets/soft-ware-img/HomePagecreateLandingPage.svg"
                  alt=""
                />
              </div>
              <div class="card-body">
                <p>{{ $t("CreateLandingPage") }}</p>
              </div>
            </div>
          </router-link> -->

          <!-- <router-link to="">
            <div class="card">
              <div class="header">
                <img
                  src="@/assets/soft-ware-img/createDesign-homepage.svg"
                  alt=""
                />
              </div>
              <div class="card-body">
                <p>{{ $t("CreateDesign") }}</p>
              </div>
            </div>
          </router-link> -->
          <router-link
            :class="{
              'disabled-module': !HavePermission('my-team.read'),
            }"
            :to="!HavePermission('my-team.read') ? '#' : '/my-team'"
            v-tooltip:top="
              !HavePermission('my-team.read')
                ? `⛔ ${$t('not_authorized')}`
                : $t('MyTeam')
            "
          >
            <div class="card">
              <div class="header">
                <img src="@/assets/soft-ware-img/myTeamHomePage.svg" alt="" />
              </div>
              <div class="card-body">
                <p>{{ $t("MyTeam") }}</p>
              </div>
            </div>
          </router-link>

          <!-- <router-link to="">
            <div class="card">
              <div class="header">
                <img src="@/assets/soft-ware-img/keyWordsPlanner.svg" alt="" />
              </div>
              <div class="card-body">
                <p>{{ $t("KeywordsPlanner") }}</p>
              </div>
            </div>
          </router-link> -->

          <router-link
            :class="{
              'disabled-module': !HavePermission('finance-center.read'),
            }"
            :to="!HavePermission('finance-center.read') ? '#' : '/finance-home'"
            v-tooltip:top="
              !HavePermission('finance-center.read')
                ? `⛔ ${$t('not_authorized')}`
                : $t('FinanceCenter')
            "
          >
            <div class="card">
              <div class="header">
                <img src="@/assets/soft-ware-img/financeCenter.svg" alt="" />
              </div>
              <div class="card-body">
                <p>{{ $t("FinanceCenter") }}</p>
              </div>
            </div>
          </router-link>

          <router-link
            :class="{
              'disabled-module': !HavePermission('integration.read'),
            }"
            :to="!HavePermission('integration.read') ? '#' : '/integrations'"
            v-tooltip:top="
              !HavePermission('integration.read')
                ? `⛔ ${$t('not_authorized')}`
                : $t('Integrations')
            "
          >
            <div class="card">
              <div class="header">
                <img src="@/assets/soft-ware-img/integrationsIcon.svg" alt="" />
              </div>
              <div class="card-body">
                <p>{{ $t("Integrations") }}</p>
              </div>
            </div>
          </router-link>

          <router-link
            :class="{
              'disabled-module': !HavePermission('my-account.read'),
            }"
            :to="!HavePermission('my-account.read') ? '#' : '/my-account'"
            v-tooltip:top="
              !HavePermission('my-account.read')
                ? `⛔ ${$t('not_authorized')}`
                : $t('MyAccount')
            "
          >
            <div class="card">
              <div class="header">
                <img
                  src="@/assets/soft-ware-img/myAccountHomePage.svg"
                  alt=""
                />
              </div>
              <div class="card-body">
                <p>{{ $t("MyAccount") }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </v-row>

      <!-- <v-row class="posts-scheduled px-4 py-5 mx-4">
        <v-col cols="12" class="mb-3 header-post">
          <p class="title">
            {{ $t("Poststoday") }}
            <span
              >Wed 16<sub style="color: rgba(179, 179, 179, 1); font-size: 12px"
                >th</sub
              >
              Oct</span
            >
          </p>
          <button type="button">{{ $t("Viewall") }}</button>
        </v-col>
        <v-col cols="12" md="4" lg="3" sm="6">
          <div class="card pa-3">
            <div
              class="social-type mb-2 d-flex align-center justify-space-between"
            >
              <div class="sceduled-time d-flex align-center">
                <img src="@/assets/soft-ware-img/facebookIcon.png" alt="" />
                <p>{{ $t("sceduledAt") }} <span>9:23</span></p>
              </div>
              <img
                src="@/assets/soft-ware-img/badge-dollar_7661360 1.png"
                alt=""
              />
            </div>
            <div class="card-body mt-5">
              <div class="user-info justify-start">
                <div class="user-image position-relative">
                  <img
                    class="user-avatar"
                    src="../assets/soft-ware-img/Shape.png"
                    alt=""
                  />
                  <img
                    class="social-icon"
                    src="../assets/soft-ware-img/facebookIcon.png"
                    alt=""
                  />
                </div>
                <p>Qarea Company</p>
              </div>
              <div class="post-descript mt-5 mb-3">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  lobortis maximus
                </p>
              </div>
              <div class="post-image">
                <img
                  src="../assets/soft-ware-img/6c6aac76b1efa46691789a9f54ce24cc.jpeg"
                  alt=""
                />
              </div>
              <div class="card-footer mt-3">
                <div class="actions">
                  <v-row class="" style="padding: 10px 14px">
                    <v-col cols="9" class="ma-0 pa-0">
                      <v-btn size="large" class="preview-btn" variant="outlined"
                        >{{ $t("preview") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="2" class="ma-0 pa-0">
                      <button class="edite-post">
                        <img
                          src="../assets/soft-ware-img/edit_12108481 1.svg"
                          alt=""
                        />
                      </button>
                    </v-col>
                    <v-col cols="1" class="ma-0 pa-0">
                      <button class="delete-post">
                        <img src="../assets/soft-ware-img/trash.png" alt="" />
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4" lg="3" sm="6">
          <div class="card pa-3">
            <div
              class="social-type mb-2 d-flex align-center justify-space-between"
            >
              <div class="sceduled-time d-flex align-center">
                <img src="@/assets/soft-ware-img/Component 20.png" alt="" />
                <p>Sceduled at <span>9:23</span></p>
              </div>
              <img
                v-if="paied"
                src="@/assets/soft-ware-img/badge-dollar_7661360 1.png"
                alt=""
              />
            </div>
            <div class="card-body mt-5">
              <div class="user-info justify-start">
                <div class="user-image position-relative">
                  <img
                    class="user-avatar"
                    src="../assets/soft-ware-img/Shape.png"
                    alt=""
                  />
                  <img
                    class="social-icon"
                    src="../assets/soft-ware-img/Component 20.png"
                    alt=""
                  />
                </div>
                <p>Qarea Company</p>
              </div>
              <div class="post-descript mt-5 mb-3">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  lobortis maximus
                </p>
              </div>
              <div class="post-image">
                <img
                  src="../assets/soft-ware-img/6c6aac76b1efa46691789a9f54ce24cc.jpeg"
                  alt=""
                />
              </div>
              <div class="card-footer mt-3">
                <div class="actions">
                  <v-row class="" style="padding: 10px 14px">
                    <v-col cols="9" class="ma-0 pa-0">
                      <v-btn size="large" class="preview-btn" variant="outlined"
                        >{{ $t("preview") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="2" class="ma-0 pa-0">
                      <button class="edite-post">
                        <img
                          src="../assets/soft-ware-img/edit_12108481 1.svg"
                          alt=""
                        />
                      </button>
                    </v-col>
                    <v-col cols="1" class="ma-0 pa-0">
                      <button class="delete-post">
                        <img src="../assets/soft-ware-img/trash.png" alt="" />
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4" lg="3" sm="6">
          <div class="card pa-3">
            <div
              class="social-type mb-2 d-flex align-center justify-space-between"
            >
              <div class="sceduled-time d-flex align-center">
                <img src="@/assets/soft-ware-img/Component 20.png" alt="" />
                <p>Sceduled at <span>9:23</span></p>
              </div>
              <img
                src="@/assets/soft-ware-img/badge-dollar_7661360 1.png"
                alt=""
              />
            </div>
            <div class="card-body mt-5">
              <div class="user-info justify-start">
                <div class="user-image position-relative">
                  <img
                    class="user-avatar"
                    src="../assets/soft-ware-img/Shape.png"
                    alt=""
                  />
                  <img
                    class="social-icon"
                    src="../assets/soft-ware-img/Component 20.png"
                    alt=""
                  />
                </div>
                <p>Qarea Company</p>
              </div>
              <div class="post-descript mt-5 mb-3">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  lobortis maximus
                </p>
              </div>
              <div class="post-image">
                <img
                  src="../assets/soft-ware-img/be2e93b49a6624b62b6a5bb8c17a2d08.jpeg"
                  alt=""
                />
              </div>
              <div class="card-footer mt-3">
                <div class="actions">
                  <v-row class="" style="padding: 10px 14px">
                    <v-col cols="9" class="ma-0 pa-0">
                      <v-btn size="large" class="preview-btn" variant="outlined"
                        >{{ $t("preview") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="2" class="ma-0 pa-0">
                      <button class="edite-post">
                        <img
                          src="../assets/soft-ware-img/edit_12108481 1.svg"
                          alt=""
                        />
                      </button>
                    </v-col>
                    <v-col cols="1" class="ma-0 pa-0">
                      <button class="delete-post">
                        <img src="../assets/soft-ware-img/trash.png" alt="" />
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4" lg="3" sm="6">
          <div class="card pa-3">
            <div
              class="social-type mb-2 d-flex align-center justify-space-between"
            >
              <div class="sceduled-time d-flex align-center">
                <img src="@/assets/soft-ware-img/instgram.png" alt="" />
                <p>Sceduled at <span>9:23</span></p>
              </div>
              <img
                src="@/assets/soft-ware-img/badge-dollar_7661360 1.png"
                alt=""
              />
            </div>
            <div class="card-body mt-5">
              <div class="user-info justify-start">
                <div class="user-image position-relative">
                  <img
                    class="user-avatar"
                    src="../assets/soft-ware-img/Shape.png"
                    alt=""
                  />
                  <img
                    class="social-icon"
                    src="../assets/soft-ware-img/instgram.png"
                    alt=""
                  />
                </div>
                <p>Qarea Company</p>
              </div>
              <div class="post-descript mt-5 mb-3">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  lobortis maximus
                </p>
              </div>
              <div class="post-image">
                <img
                  src="../assets/soft-ware-img/a178d53e23e8fd780a132cb891554a02.jpeg"
                  alt=""
                />
              </div>
              <div class="card-footer mt-3">
                <div class="actions">
                  <v-row class="" style="padding: 10px 14px">
                    <v-col cols="9" class="ma-0 pa-0">
                      <v-btn size="large" class="preview-btn" variant="outlined"
                        >{{ $t("preview") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="2" class="ma-0 pa-0">
                      <button class="edite-post">
                        <img
                          src="../assets/soft-ware-img/edit_12108481 1.svg"
                          alt=""
                        />
                      </button>
                    </v-col>
                    <v-col cols="1" class="ma-0 pa-0">
                      <button class="delete-post">
                        <img src="../assets/soft-ware-img/trash.png" alt="" />
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="recent-ads px-4 py-5 mx-1 mb-8 mt-15">
        <v-col
          cols="12"
          md="8"
          lg="8"
          sm="12"
          class="posts-scheduled px-6 py-5"
        >
          <h4 class="title">Recent Adds</h4>
          <div class="card">
            <v-table fixed-header>
              <thead>
                <tr>
                  <th class="text-left">Account</th>
                  <th class="text-left">Ad Name</th>
                  <th class="text-left">Daily Budget</th>
                  <th class="text-left">Add Objective</th>
                  <th class="text-left">From - To</th>
                  <th class="text-left">State</th>
                </tr>
              </thead>
              <tbody>
                <tr class="mb-2">
                  <td
                    class="d-flex align-center"
                    style="gap: 17px; padding: 40px 15px"
                  >
                    <div class="user-image position-relative">
                      <img
                        class="user-avatar"
                        src="../assets/soft-ware-img/Shape.png"
                        alt=""
                      />
                      <img
                        class="social-icon"
                        src="../assets/soft-ware-img/facebookIcon.png"
                        alt=""
                      />
                    </div>
                    <p>Qarea Company</p>
                  </td>
                  <td><p>Product</p></td>
                  <td>
                    <p>256 <span>$</span></p>
                  </td>
                  <td><p>Lorem</p></td>
                  <td><p>20 Oct To 5 Nov</p></td>
                  <td>
                    <a href="#">
                      <img src="../assets/soft-ware-img/editeIcon.png" alt="" />
                    </a>
                  </td>
                </tr>
                <tr class="mb-2">
                  <td
                    class="d-flex align-center"
                    style="gap: 17px; padding: 40px 15px"
                  >
                    <div class="user-image position-relative">
                      <img
                        class="user-avatar"
                        src="../assets/soft-ware-img/Shape.png"
                        alt=""
                      />
                      <img
                        class="social-icon"
                        src="../assets/soft-ware-img/Component 20.png"
                        alt=""
                      />
                    </div>
                    <p>Qarea Company</p>
                  </td>
                  <td><p>Product</p></td>
                  <td>
                    <p>256 <span>$</span></p>
                  </td>
                  <td><p>Lorem</p></td>
                  <td><p>20 Oct To 5 Nov</p></td>
                  <td>
                    <a href="#">
                      <img src="../assets/soft-ware-img/editeIcon.png" alt="" />
                    </a>
                  </td>
                </tr>
                <tr class="mb-2">
                  <td
                    class="d-flex align-center"
                    style="gap: 17px; padding: 40px 15px"
                  >
                    <div class="user-image position-relative">
                      <img
                        class="user-avatar"
                        src="../assets/soft-ware-img/Shape.png"
                        alt=""
                      />
                      <img
                        class="social-icon"
                        src="../assets/soft-ware-img/instgram.png"
                        alt=""
                      />
                    </div>
                    <p>Qarea Company</p>
                  </td>
                  <td><p>Product</p></td>
                  <td>
                    <p>256 <span>$</span></p>
                  </td>
                  <td><p>Lorem</p></td>
                  <td><p>20 Oct To 5 Nov</p></td>
                  <td>
                    <a href="#">
                      <img src="../assets/soft-ware-img/editeIcon.png" alt="" />
                    </a>
                  </td>
                </tr>
                <tr class="mb-2">
                  <td
                    class="d-flex align-center"
                    style="gap: 17px; padding: 40px 15px"
                  >
                    <div class="user-image position-relative">
                      <img
                        class="user-avatar"
                        src="../assets/soft-ware-img/Shape.png"
                        alt=""
                      />
                      <img
                        class="social-icon"
                        src="../assets/soft-ware-img/facebookIcon.png"
                        alt=""
                      />
                    </div>
                    <p>Qarea Company</p>
                  </td>
                  <td><p>Product</p></td>
                  <td>
                    <p>256 <span>$</span></p>
                  </td>
                  <td><p>Lorem</p></td>
                  <td><p>20 Oct To 5 Nov</p></td>
                  <td>
                    <a href="#">
                      <img src="../assets/soft-ware-img/editeIcon.png" alt="" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </v-col>
        <v-col cols="12" md="4" lg="4" sm="12" class="active-ad-acount py-0">
          <div class="card posts-scheduled px-6 py-5">
            <h4 class="title">Active Ad Accounts</h4>
            <div class="social-active-acount">
              <img src="../assets/soft-ware-img/Component 30.svg" alt="" />
              <p>Qurea Company</p>
            </div>
            <div class="social-active-acount">
              <img src="../assets/soft-ware-img/instgrapLogo.png" alt="" />
              <p>Qurea Company</p>
            </div>
            <div class="social-active-acount">
              <img src="../assets/soft-ware-img/tiktokLogo.png" alt="" />
              <p>Qurea Company</p>
            </div>
            <div class="add-new-account">
              <button>
                <img src="../assets/soft-ware-img/Component 23.png" alt="" />
              </button>
              <p>Add Account</p>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="posts-scheduled px-4 py-5 mx-4">
        <v-col cols="12" class="mb-3 header-post">
          <p class="title">Recent Posts</p>
          <button type="button">View all scheduled</button>
        </v-col>
        <v-col cols="12" md="4" lg="3" sm="6" v-for="n in 4" :key="n">
          <div class="card px-1 py-2" style="border: 2px solid #1f666a">
            <div class="card-body border-0">
              <div class="user-info">
                <div class="user-image d-flex align-center">
                  <div class="position-relative">
                    <img
                      class="user-avatar"
                      src="../assets/soft-ware-img/Shape.png"
                      alt=""
                    />
                    <img
                      class="social-icon"
                      src="../assets/soft-ware-img/instgram.png"
                      alt=""
                    />
                  </div>
                  <div class="date-of-publish">
                    <p>Qarea Company</p>
                    <span class="d-block">12/10/2024</span>
                  </div>
                </div>

                <img
                  src="@/assets/soft-ware-img/badge-dollar_7661360 1.png"
                  alt=""
                />
              </div>
              <div class="post-descript mt-5 mb-3">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  lobortis maximus
                </p>
              </div>
              <div class="post-image">
                <img
                  src="../assets/soft-ware-img/626cad1be88687ddaea91af9a8effb8d.jpeg"
                  alt=""
                />
              </div>
              <div class="card-footer mt-3">
                <div class="results">
                  <div class="d-flex align-center mb-2 justify-space-between">
                    <p>Total Impressions</p>
                    <span>340</span>
                  </div>
                  <div class="d-flex align-center mb-2 justify-space-between">
                    <p>Total Engagement</p>
                    <span>520</span>
                  </div>
                  <div class="d-flex align-center mb-2 justify-space-between">
                    <p>Total Clicks</p>
                    <span>1240</span>
                  </div>
                </div>
                <div class="actions">
                  <v-row
                    class=""
                    style="padding: 10px 14px; align-items: center"
                  >
                    <v-col cols="9" class="ma-0 pa-0">
                      <v-btn size="large" class="preview-btn" variant="outlined"
                        >{{ $t("preview") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="2" class="pa-0">
                      <button class="edite-post">
                        <img
                          src="../assets/soft-ware-img/edit_12108481 1.svg"
                          alt=""
                        />
                      </button>
                    </v-col>
                    <v-col cols="1" class="ma-0 pa-0">
                      <button class="delete-post">
                        <img src="../assets/soft-ware-img/trash.png" alt="" />
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row> -->
      <v-footer class="bg-transparent pt-10 d-flex align-center justify-center">
        <p style="color: rgba(103, 116, 142, 1); font-size: 14px">
          {{ $t('promxa_copy_right') }}
        </p>
      </v-footer>
    </v-container>
  </div>
</template>

<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import "../assets/css/style.css";
import { loginForm } from "@/store/logIn";

export default {
  components: { GlobalNav },
  data() {
    return {
      paied: false,
      isDisabled: true,
      store: loginForm(),
    };
  },
  methods: {
    HavePermission(p) {
      return this.store?.permissions?.includes(p) || false;
    },
  },
};
</script>

<style scoped>
.parent-container .v-row:first-child {
  padding-top: 120px;
}
.modules-type {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
  margin: 0 auto;
}
.modules-type a {
  width: calc(95% / 4);
  margin-bottom: 55px;
}
@media (max-width: 975px) {
  .modules-type a {
    width: calc(95% / 4);
  }
}
.modules-type .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modules-type .card .header {
  background: linear-gradient(90deg, #1f666a 0%, #4f979f 69%);
  width: 132px;
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  box-shadow: 0px 0px 24px 0px #808080;
  margin-bottom: 20px;
}
.modules-type a {
  text-decoration: none;
}
.modules-type .card .card-body p {
  color: #333333;
  text-decoration: none;
  text-align: center;
}
</style>
