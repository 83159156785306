import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import EN from "@/locale/en.json";
import AR from "@/locale/ar.json";
import VueGoogleMaps from "@fawmi/vue-google-maps";

// Vuetify
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
const vuetify = createVuetify({
  components,
  directives,
});

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

library.add(faUserSecret);

import { faCoffee } from "@fortawesome/free-solid-svg-icons";
library.add(faCoffee);

import { faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
library.add(faSquareFacebook);

import "vue3-toastify/dist/index.css";
import apiClient from "@/core/buildRequest";

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("lang")?.toLowerCase() || "en",
  fallbackLocale: "en",
  messages: {
    en: EN,
    ar: AR,
  },
});
document.body.style.direction =
  localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
document.documentElement.setAttribute(
  "lang",
  localStorage.getItem("lang") === "ar" ? "ar" : "en"
);

const cleanLocalStorage = () => {
  for (const key of Object.keys(localStorage)) {
    if (key !== "deviceId") {
      localStorage.removeItem(key);
    }
  }
};

const handleCredentialError = (error) => {
  console.error("Error fetching credentials:", error);
  cleanLocalStorage();
};

function storeToken(service, token, expireDate) {
  localStorage.setItem(`${service}_token`, token);
  localStorage.setItem(`${service}TokenExpireAt`, expireDate);
}

async function initializeApp() {
  if (localStorage.getItem("user_token") && localStorage.getItem("user_token") !== "") {
    try {
      const { data } = await apiClient.get("/check-user-token");
      if (data.data.user_id) {
        let c = JSON.parse(localStorage.getItem("user"));
        if (c["data"].id === data.data.user_id) {
          try {
            const { data } = await apiClient.get("/get-credentials");
            const credentials = data.data;
            ["snapchat", "tiktok", "facebook", "instagram", "twitter", "google_ads", "drive"].forEach((service) => {
              if (credentials[service]) {
                storeToken(
                  service,
                  credentials[service].access_token,
                  credentials[service].expire_date
                );
              }
            });
          } catch (error) {
            handleCredentialError(error);
          }
        } else {
          cleanLocalStorage();
        }
      } else {
        cleanLocalStorage();
      }
    } catch (error) {
      handleCredentialError(error);
    }
  } else {
    cleanLocalStorage();
  }
}

const app = createApp(App)
  .use(vuetify)
  .use(router)
  .use(createPinia())
  .use(i18n)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      libraries: "places",
    },
  });

// Initialize the app and then mount it
initializeApp().then(() => {
  app.mount("#app");
}).catch(error => {
  console.error("Failed to initialize app:", error);
  app.mount("#app"); // Mount anyway to allow the app to run
});
