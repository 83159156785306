<template>
  <v-col :cols="12" :lg="9">
    <div class="bold-text mb-2">Description</div>
    <textarea
      v-model="store.description"
      rows="4"
      class="calender-input w-100 d-block"
      placeholder="Task Description"
      style="height: auto"
    ></textarea>
  </v-col>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";

export default {
  name: "TaskDescription",
  data() {
    return {
      store: useTaskStore(),
    };
  },
  watch: {
    "store.description": {
      handler() {
        console.log(this.store.description);
      },
    },
  },
};
</script>
