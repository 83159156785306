<template>
    <div class="library-content">
        <div class="preview-section mt-4" v-if="store.previews.length">
            <div class="preview-grid"
                style="display: grid; grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); gap: 16px;">
                <div v-for="(preview, index) in store.previews" :key="index" class="preview-item position-relative">
                    <button @click="removeFile(index)" class="remove-btn"
                        style="position: absolute; right: 5px; top: 5px; z-index: 1; background: rgba(255,255,255,0.8); border: none; border-radius: 50%; width: 24px; height: 24px; cursor: pointer; display: flex; align-items: center; justify-content: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16"
                            fill="#000">
                            <path
                                d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                        </svg>
                    </button>
                    <button @click="store.toggleSelection(preview)" class="select-btn"
                        style="position: absolute; left: 5px; top: 5px; z-index: 1; background: rgba(255,255,255,0.8); border: none; border-radius: 50%; width: 24px; height: 24px; cursor: pointer; display: flex; align-items: center; justify-content: center;"
                        :style="{ 'background-color': store.isSelected(preview) ? '#1976D2' : 'rgba(255,255,255,0.8)' }">
                        <svg v-if="!store.isSelected(preview)" xmlns="http://www.w3.org/2000/svg" height="16"
                            viewBox="0 -960 960 960" width="16" fill="#000">
                            <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"
                            fill="#fff">
                            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                        </svg>
                    </button>
                    <div class="media-info"
                        style="position: absolute; left: 5px; bottom: 5px; z-index: 1; background: rgba(0,0,0,0.7); color: white; padding: 4px 8px; border-radius: 4px; font-size: 12px;">
                        <span v-if="preview.type === 'video'">Video</span>
                        <span v-else>Image</span>
                        • {{ formatFileSize(store.media[index].size) }}
                    </div>
                    <img v-if="preview.type === 'image'" :src="preview.url" style="width: 100%; height: auto;" />
                    <video v-else-if="preview.type === 'video'" controls style="width: 100%; height: auto;">
                        <source :src="preview.url" :type="store.media[index].type">
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { uploadMedia } from '@/store/tiktokStore/uploadMedia';
export default {
    name: "LibraryContent",
    data() {
        return {
            store: uploadMedia(),
        }
    },
    methods: {
        removeFile(index) {
            this.store.removeFile(index);
        },
        formatFileSize(bytes) {
            const mb = bytes / (1024 * 1024);
            return mb >= 1 ? `${mb.toFixed(1)} MB` : `${(bytes / 1024).toFixed(1)} KB`;
        }
    },

}

</script>