<template>
    <div class="destination edit-inventory my-5">
        <v-expansion-panels v-model="store.panel" variant="accordion">
            <v-expansion-panel>
                <v-expansion-panel-title>Destination</v-expansion-panel-title>
                <v-expansion-panel-text class="my-5 pa-0">
                    <div class="container mb-5">
                        <div class="radio-tile-group justify-start ga-3">
                            <div class="input-container">
                                <input id="Website_des" class="radio-button" type="radio" name="destination"
                                    value="Website" v-model="store.destination" />
                                <div class="radio-tile">
                                    <div class="d-flex align-start ga-2">
                                        <div>
                                            <p class="description mb-0">Website</p>
                                            <label for="Website_des" class="radio-tile-label mb-0">
                                                URL
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="input-container" v-if="!mediaStore.selectedMedia.length > 0">
                                <input id="custom_page_des" class="radio-button" type="radio" name="destination"
                                    value="customPage" v-model="store.destination" />
                                <div class="radio-tile">
                                    <div class="d-flex align-start ga-2">
                                        <div>
                                            <p class="description mb-0">TikTok Instant Page</p>
                                            <label for="custom_page_des" class="radio-tile-label mb-0">
                                                Custom page
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="store.destination === 'Website'" class="destination-URL">
                        <h4 class="mb-3">Destination URL</h4>
                        <p class="text-caption mb-2">
                            By selecting a webpage, you are granting TikTok permission to
                            scan, download, and modify images, videos, and other assets
                            located on that webpage, and you are confirming that you own the
                            necessary legal rights to the images, videos, and assets located
                            on the webpage and have permission to share the assets with TikTok
                            for use on your behalf in advertising or for other commercial
                            purposes.
                        </p>
                        <div class="website-url mb-3 d-flex ga-2 align-canter">
                            <input type="url" v-model="store.url" @input="validateUrl" placeholder="Enter URL"
                                :class="{ invalid: !store.isValid }" />
                            <button @click="handlePreview" :disabled="!store.isValid || !store.url">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16"
                                    fill="#121415">
                                    <path
                                        d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l82.7 0L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3l0 82.7c0 17.7 14.3 32 32 32s32-14.3 32-32l0-160c0-17.7-14.3-32-32-32L320 0zM80 32C35.8 32 0 67.8 0 112L0 432c0 44.2 35.8 80 80 80l320 0c44.2 0 80-35.8 80-80l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112c0 8.8-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32z" />
                                </svg>
                                <span> Preview </span>
                            </button>
                        </div>

                        <div class="direct-user-deeplink mt-5 mb-5">
                            <div class="checked-label mb-3 smart-campaign mb-1 d-flex align-center ga-1">
                                <label class="toggle-switch">
                                    <input  type="checkbox"
                                        v-model="store.automatically_add_url_parameters" id="automatically_add_url_parameters" />
                                    <div class="toggle-switch-background">
                                        <div class="toggle-switch-handle"></div>
                                        <svg class="closemark" xmlns="http://www.w3.org/2000/svg" height="30px"
                                            viewBox="0 -960 960 960" width="30px" fill="">
                                            <path
                                                d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z" />
                                        </svg>
                                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" height="30px"
                                            viewBox="0 -960 960 960" width="30px" fill="">
                                            <path
                                                d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z" />
                                        </svg>
                                    </div>
                                </label>
                                <label for="automatically_add_url_parameters" class="label-for-input">
                                    Automatically add URL parameters
                                </label>
                            </div>
                            <div class="description-parameter">
                                <p>
                                    Adding campaign parameters to destination URLs will help you gauge the performance of your campaigns on third-party platforms.

                                </p>
                            </div>
                        </div>


                        <div class="direct-user-deeplink mt-5">
                            <div class="checked-label mb-5 smart-campaign mb-1 d-flex align-center ga-1">
                                <label class="toggle-switch">
                                    <input @change="directUserStatus" type="checkbox"
                                        v-model="store.direct_users_deeplink" id="direct_users_deeplink_id" />
                                    <div class="toggle-switch-background">
                                        <div class="toggle-switch-handle"></div>
                                        <svg class="closemark" xmlns="http://www.w3.org/2000/svg" height="30px"
                                            viewBox="0 -960 960 960" width="30px" fill="">
                                            <path
                                                d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z" />
                                        </svg>
                                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" height="30px"
                                            viewBox="0 -960 960 960" width="30px" fill="">
                                            <path
                                                d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z" />
                                        </svg>
                                    </div>
                                </label>
                                <label for="direct_users_deeplink_id" class="label-for-input">
                                    Direct users to deeplink first
                                </label>
                            </div>
                            <div v-if="store.direct_users_deeplink">
                                <input @input="validateDirectUserUrl" type="url" class="direct-user-deeplink-input"
                                    :class="{
                                        invalid: isInvalid,
                                        valid: !isInvalid && store.direct_user_url,
                                    }" placeholder="Scheme, Universal, and App Link formats are supported"
                                    v-model="store.direct_user_url" />
                                <p v-if="isInvalid" class="error-message">
                                    Enter deep link URL
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="destination-custom-page mb-5" v-if="store.destination === 'customPage'   ">
                        <h4 class="mb-3">Custom page</h4>
                        <div class="button-group d-flex align-center ga-2">
                            <button class="custome-page-create">
                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960"
                                    width="16px" fill="#121415">
                                    <path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" />
                                </svg>
                                <span>Create</span>
                            </button>
                            <button class="custome-page-create">
                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960"
                                    width="16px" fill="#121415">
                                    <path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" />
                                </svg>
                                <span>Choose from library</span>
                            </button>
                        </div>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import { adCreative } from "@/store/tiktokStore/adCreative";
import { validateUrl } from "@/core/urlValidator";
import { uploadMedia } from "@/store/tiktokStore/uploadMedia";
export default {
    name: "DestinationComponent",
    data() {
        return {
            store: adCreative(),
            mediaStore: uploadMedia(),
        };
    },
    methods: {
        validateUrl() {
            if (!this.store.url) {
                this.store.isValid = true;
                return;
            }
            try {
                new URL(this.store.url);
                this.store.isValid = true;
                localStorage.setItem("deeplink_format_type", this.url);
            } catch {
                this.store.isValid = false;
            }
        },
        handlePreview() {
            if (this.store.isValid && this.store.url) {
                window.open(this.store.url, "_blank");
            }
        },
        directUserStatus() {
            localStorage.setItem(
                "direct_users_deeplink",
                this.store.direct_users_deeplink
            );
        },
        validateDirectUserUrl() {
            this.isInvalid = !validateUrl(this.directUserUrl);
        },
    },
};
</script>
