<template>
  <div class="boost-post-card">
    <div class="card-header mb-4">
      <label for="" class="title d-block">{{ $t('button') }}  </label>
    </div>
    <div class="card-body button-select">
      <v-select
        class="mb-4"
        v-model="store.selected_call_action_type"
        :items="store.select_call_action_type"
        variant="solo"
        return-object
        item-value="id"
        item-title="title"
        hide-details
        label="Button label"
      >
        <template v-slot:selection="{ item }">
          {{ item?.title || "" }}
        </template>
        <template v-slot:item="{ item }">
          <v-list-item @click="store.selectItem(item)">
            <div class="my-form">
              <div>
                <input
                  :checked="
                    store.selected_call_action_type &&
                    store.selected_call_action_type.id === item.raw.id
                  "
                  :id="`${item.id}_call_action_type`"
                  type="radio"
                  name="option"
                  :value="item"
                />
                <label :for="`${item.id}_call_action_type`">{{
                  item.title
                }}</label>
              </div>
            </div>
          </v-list-item>
        </template>
      </v-select>
      <ButtonDestination v-if="store.learn_more_contant"  />
      <div class="website-url my-3" v-if="store.book_now_contant">
        <v-text-field
          v-model="website"
          :rules="[urlRule]"
          hide-details="auto"
          label="Website URL"
          variant="solo"
          elevation="0"
        ></v-text-field>
      </div>
      <div class="crope-image" v-if="store.book_now_contant">
        <div class="crope-image-header" @click="store.toggleImage">
          <img src="@/assets/images/alert.svg" alt="" />
          <label for="" class="d-block">
            {{ $t("Adding_a_button_may_crop_your_image") }}
          </label>
        </div>
        <div class="crope-image-body" v-if="store.imageCropeContant">
          <p>
            {{ $t('you_can_reposition_your_image_before_boosting') }}
          </p>
          <button @click="dialog = true">Crop Your Image</button>
          <v-dialog v-model="dialog" width="800">
            <v-card max-width="800">
              <v-card-text> </v-card-text>
              <template v-slot:actions>
                <v-btn
                  class="ms-auto"
                  text="Ok"
                  @click="dialog = false"
                ></v-btn>
              </template>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { boostPost } from "@/store/publishPost/boostPost";
import ButtonDestination from "./ButtonDestination.vue";
export default {
  components: {
    ButtonDestination,
  },
  data() {
    return {
      store: boostPost(),
      dialog: false,
      website: "getbootstrap.com",
      urlRule: (value) => {
        const urlPattern =
          /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-./?%&=]*)?$/i;
        return urlPattern.test(value) || "Enter a valid URL";
      },
    };
  },
};
</script>

<style scoped>
.v-field {
  box-shadow: none !important;
  border: 1px solid #d6d6d6 !important;
}
</style>
