<template>
  <v-container fluid>
    <v-row no-gutters class="meta-container position-relative h-100">
      <v-col cols="2" class="side-bar h-100"> 
        <ul>
          <li class="pl-2 py-3 " @click="this.$router.push({ name: 'MetaIndex' })" >
            <svg
              width="20"
              height="20"
              fill="#5f6368"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z"
              />
            </svg>
            <span>New Awareness campaign</span>
          </li>
          <li class="pl-6 py-3 active" @click="this.$router.push({ name: 'MetaSecoundStep' })" >
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M144-528v-288h288v288H144Zm0 384v-288h288v288H144Zm384-384v-288h288v288H528Zm0 384v-288h288v288H528ZM216-600h144v-144H216v144Zm384 0h144v-144H600v144Zm0 384h144v-144H600v144Zm-384 0h144v-144H216v144Zm384-384Zm0 240Zm-240 0Zm0-240Z"/></svg>
            <span>New Awareness ad set</span>
          </li>
          <li class="pl-8 py-3" @click="this.$router.push({ name: 'MetaLastStep' })" >
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M264-576h336v-96H264v96Zm-48 432q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h528q29.7 0 50.85 21.15Q816-773.7 816-744v528q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm0-72h528v-528H216v528Zm0-528v528-528Z"/></svg>
            <span> New Awareness ad</span>
          </li>
        </ul>  
      </v-col>
      <v-col cols="8" style="margin-bottom: 60px">
        <div class="content">
          <AdSetName />
          <AwarenessComponent />
          <ConversionComponent />
          <EngagementConversion />
          <AppComponents />
          <DynamicCreative />
          <BudgetAndSchedule />
          <AudienceControls />
          <AdvantageAudience />
          <PlacementComponent />
        </div>
      </v-col>
      <v-col cols="2">  </v-col>
      <v-col
        cols="2"
        class=""
        style="border-right: 1px solid #c6c9cc; background-color: #fff"
      >
      </v-col>
      <v-col
        style="
          background-color: #fff;
          border-top: solid 1px #dadcde;
          position: fixed;
          bottom: 0;
          right: 0;
          z-index: 1000;
        "
        cols="10"
        class="pa-3 footer"
      >
        <footer class="d-flex justify-space-between">
          <button class="close-btn">Close</button>
          <div class="d-flex ga-3 align-center">
            <button class="close-btn" @click="back">Back</button>
            <button class="next-btn" @click="lastStep">Next</button>
          </div>
        </footer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "@/assets/css/meta/style.css";
import AdSetName from "@/components/meta/secound-page-componet/AdSetName.vue";
import AdvantageAudience from "@/components/meta/secound-page-componet/AdvantageAudience.vue";
import AppComponents from "@/components/meta/secound-page-componet/AppComponents.vue";
import AudienceControls from "@/components/meta/secound-page-componet/AudienceControls.vue";
import AwarenessComponent from "@/components/meta/secound-page-componet/AwarenessComponent.vue";
import BudgetAndSchedule from "@/components/meta/secound-page-componet/BudgetAndSchedule.vue";
import DynamicCreative from "@/components/meta/secound-page-componet/DynamicCreative.vue";
import PlacementComponent from "@/components/meta/secound-page-componet/PlacementComponent.vue";
import ConversionComponent from "@/components/meta/secound-page-componet/ConversionComponent.vue";
import EngagementConversion from "@/components/meta/secound-page-componet/EngagementConversion.vue";
export default {
  components: {
    AdSetName,
    AwarenessComponent,
    DynamicCreative,
    BudgetAndSchedule,
    AudienceControls,
    AdvantageAudience,
    AppComponents,
    PlacementComponent,
    ConversionComponent,
    EngagementConversion
  },
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.push({ name: "MetaIndex" });
    },
    lastStep() {
      this.$router.push({ name: "MetaLastStep" });
    },
  },
};
</script>

<style scoped>
.v-container {
  background: rgb(250, 240, 240);
  background: linear-gradient(
    90deg,
    rgba(250, 240, 240, 1) 0%,
    rgba(233, 245, 242, 1) 100%
  );
  min-height: 100vh;
  width: 100%;
  padding: 0;
}
.v-container .meta-container .side-bar {
  background-color: #fff;
  height: 100vh;
  border-right: 1px solid #c6c9cc;
}
.v-container .meta-container .content {
  width: 75%;
  margin: 0 auto;
  padding: 15px 0;
}
</style>
