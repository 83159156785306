import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const useTasksStore = defineStore("useTasksStore", {
  state: () => ({
    tasks: [],
    headers: [
      {
        title: "Task",
        name: "Task",
        align: "start",
        key: "task_number",
        menu: true,
        show: true,
        sortable: true,
      },
      {
        title: "Task Name",
        name: "Task Name",
        align: "start",
        show: true,
        menu: true,
        key: "name",
        sortable: true,
      },
      {
        title: "Description",
        name: "Description",
        align: "start",
        show: true,
        menu: true,
        key: "description",
      },
      {
        title: "Add To Calendar",
        name: "Add To Calendar",
        align: "start",
        show: true,
        menu: true,
        key: "add_to_calendar",
      },
      {
        title: "Assigness",
        name: "Assigness",
        align: "start",
        show: true,
        key: "members",
        menu: true,
      },
      {
        title: "Status",
        name: "Status",
        align: "start",
        show: true,
        menu: true,
        key: "status",
      },
      {
        title: "Start Date",
        name: "Start Date",
        align: "start",
        show: true,
        menu: true,
        key: "start_date",
      },
      {
        title: "Due Date",
        name: "Due Date",
        align: "start",
        show: true,
        menu: true,
        key: "due_date",
      },

      {
        title: "Contacts",
        name: "Contacts",
        align: "start",
        show: true,
        menu: true,
        key: "contacts",
      },
      {
        title: "Account",
        name: "Account",
        align: "start",
        show: true,
        menu: true,
        key: "account",
      },

      {
        title: "Project",
        name: "Project",
        align: "start",
        show: true,
        menu: true,
        key: "project",
      },
      {
        title: "Deal",
        name: "Deal",
        align: "start",
        show: true,
        menu: true,
        key: "deal",
      },
      {
        title: "Cost",
        name: "Cost",
        align: "start",
        show: true,
        menu: true,
        key: "cost",
      },
      {
        title: "Weight",
        name: "Weight",
        align: "start",
        show: true,
        menu: true,
        key: "weight",
      },
      {
        title: "Incentive",
        name: "Incentive",
        align: "start",
        show: true,
        menu: true,
        key: "incentive",
      },
      {
        title: "Comment",
        name: "Comment",
        align: "start",
        show: true,
        menu: true,
        key: "comment",
      },
      {
        title: "Actions",
        name: "Actions",
        align: "center",
        sortable: false,
        key: "actions",
        show: true,
        menu: true,
      },
    ],
    items_per_page: 5,
    current_page: 1,
    total_tasks: 0,
    total_pages: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    search: "",
    filters: [],
    filtersBy: [],
  }),
  getters: {
    getTasks: (state) => state.tasks,
    getHeaders: (state) => state.headers.filter((header) => header.show),
    getPagination: (state) => ({
      currentPage: state.current_page,
      totalPages: state.total_pages,
      itemsPerPage: state.items_per_page,
      totalTasks: state.total_tasks,
    }),
    isLoading: (state) => state.loading,
  },
  actions: {
    hideColumnFromHeader(value) {
      this.headers.map((item) => {
        item.show = !value;
      });
    },
    async getAllTasks() {
      try {
        console.log(
          `/leads-managment/projects?page=${this.current_page}&per_page=${this.items_per_page}&search=${this.search}`,
          {
            params: {
              filters: this.filtersBy.length > 0 ? this.filtersBy : null,
            },
          }
        );
        var response = await apiClient.get(
          `/leads-managment/tasks?page=${this.current_page}&per_page=${this.items_per_page}&search=${this.search}`,
          {
            params: {
              filters: this.filtersBy.length > 0 ? this.filtersBy : null,
            },
          }
        );
        let { data, pagination } = response.data.data;
        this.tasks = data;
        this.current_page = pagination.current_page;
        this.total_tasks = pagination.total_items;
        this.total_pages = pagination.total_pages;
        this.hasNextPage = pagination.next_page_url !== "" ? true : false;
        this.hasPreviousPage = pagination.perv_page_url !== "" ? true : false;
      } catch (err) {
        console.error("Error fetching tasks:", err);
      }
    },
    onPageChange() {
      return this.getAllTasks();
    },
    onItemsPerPageChange() {
      this.current_page = 1;
      return this.getAllTasks();
    },
    previousPage() {
      this.current_page -= 1;
      return this.getAllTasks();
    },
    nextPage() {
      this.current_page += 1;
      return this.getAllTasks();
    },
    pushTasks(tasks) {
      this.tasks.unshift(tasks);
    },
    updateTask(tasks) {
      const index = this.tasks.findIndex((item) => item.id === tasks.id);
      if (index !== -1) {
        this.tasks.splice(index, 1, tasks);
      }
      console.log(tasks);
    },
    deleteTask(tasks) {
      const index = this.tasks.findIndex((item) => item.id === tasks.id);
      if (index !== -1) {
        this.tasks.splice(index, 1);
      }
    },
    updateStatus(id, status) {
      const index = this.tasks.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.tasks[index].status = status;
      }
    },
    updateType(id, type) {
      const index = this.tasks.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.tasks[index].type = type;
      }
    },
    updateAddToCalender(id, value) {
      const index = this.tasks.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.tasks[index].add_to_calendar = value;
      }
    },
    getAllFilters() {
      apiClient.get(`/leads-managment/tasks-attributes`).then((response) => {
        this.filters = response.data.data;
      });
    },
    applyAdvancedFilter() {
      this.getAllTasks();
    },
  },
});
