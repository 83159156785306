<template>
  <v-dialog v-model="dialogModel" width="350" persistent>
    <v-card class="pa-4">
      <v-card-title>Edit User</v-card-title>

      <v-card-text>
        <div class="d-flex flex-column ga-5">
          <div class="d-flex flex-column ga-5">
            <div class="d-flex flex-column ga-1">
              <label for="name"
                >First Name <span class="text-red-accent-4">*</span>
              </label>
              <Input v-model="user_first_name" placeholder="Name" type="text" />
              <span
                class="text-red-accent-4 fs-12"
                v-if="errors.find((e) => e.key === 'user_first_name')"
                >{{
                  errors.find((e) => e.key === "user_first_name").message
                }}</span
              >
            </div>
            <div class="d-flex flex-column ga-1">
              <label for="name"
                >Last Name <span class="text-red-accent-4">*</span>
              </label>
              <Input v-model="user_last_name" placeholder="Name" type="text" />
              <span
                class="text-red-accent-4 fs-12"
                v-if="errors.find((e) => e.key === 'user_last_name')"
                >{{
                  errors.find((e) => e.key === "user_last_name").message
                }}</span
              >
            </div>
            <div class="d-flex flex-column ga-1">
              <label for="name"
                >Email <span class="text-red-accent-4">*</span></label
              >
              <Input v-model="user_email" placeholder="Email" type="email" />
              <span
                class="text-red-accent-4 fs-12"
                v-if="errors.find((e) => e.key === 'user_email')"
                >{{ errors.find((e) => e.key === "user_email").message }}</span
              >
            </div>
            <!-- <div class="d-flex flex-column ga-1">
              <label for="name"
                >Password <span class="text-red-accent-4">*</span></label
              >
              <Input
                v-model="user_password"
                placeholder="Password"
                type="password"
              />
              <span
                class="text-red-accent-4 fs-12"
                v-if="errors.find((e) => e.key === 'user_password')"
                >{{
                  errors.find((e) => e.key === "user_password").message
                }}</span
              >
            </div>
            <div class="d-flex flex-column ga-1">
              <label for="name"
                >Confirm Passowrd
                <span class="text-red-accent-4">*</span></label
              >
              <Input
                v-model="user_password_confirm"
                placeholder="Password"
                type="password"
              />
              <span
                class="text-red-accent-4 fs-12"
                v-if="errors.find((e) => e.key === 'user_password_confirm')"
                >{{
                  errors.find((e) => e.key === "user_password_confirm").message
                }}</span
              >
            </div> -->
            <div class="d-flex flex-column ga-1 select-role">
              <label for="name"
                >Role <span class="text-red-accent-4">*</span></label
              >
              <v-select
                label="Select"
                v-model="user_role"
                :items="roles"
                variant=""
                hide-details=""
                item-title="name"
                item-value="id"
              >
                <template v-slot:item="{ item, props }">
                  <v-list-item v-bind="props" :title="item.raw.name">
                  </v-list-item>
                </template>
              </v-select>
              <span
                class="text-red-accent-4 fs-12"
                v-if="errors.find((e) => e.key === 'user_role')"
                >{{ errors.find((e) => e.key === "user_role").message }}</span
              >
            </div>
            <div class="d-flex flex-column ga-1 select-role">
              <label for="status"
                >Status <span class="text-red-accent-4">*</span></label
              >
              {{ user_status }}
              <v-select
                label="Select"
                v-model="user_status"
                :items="[
                  { id: 1, name: 'Active' },
                  { id: 0, name: 'Inactive' },
                ]"
                variant=""
                hide-details=""
                item-title="name"
                item-value="id"
              >
                <template v-slot:item="{ item, props }">
                  <v-list-item v-bind="props" :title="item.raw.name">
                  </v-list-item>
                </template>
              </v-select>
              <span
                class="text-red-accent-4 fs-12"
                v-if="errors.find((e) => e.key === 'user_status')"
                >{{ errors.find((e) => e.key === "user_status").message }}</span
              >
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="sticky-footer-dialog">
        <v-spacer></v-spacer>
        <span class="cancel-btn cursor-pointer" @click="cancelDialog"
          >Cancel</span
        >
        <span class="choose-btn cursor-pointer" @click="updateUser">Save</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiClient from "@/core/buildRequest";
import Input from "@/components/crm/assets/Input.vue";
import { useGeneralStore } from "@/store/General";
export default {
  name: "CreateUserDialog",
  components: { Input },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      general_store: useGeneralStore(),
      dialogModel: false,
      user_first_name: "",
      user_last_name: "",
      user_email: "",
      user_password: "",
      user_password_confirm: "",
      user_role: "",
      user_status: "",
      user_id: "",
      roles: [],
      errors: [],
    };
  },

  computed: {},

  watch: {
    dialog(val) {
      this.dialogModel = val;
    },
    id(val) {
      if (val) {
        this.getUser(val);
      }
    },
  },

  mounted() {
    this.getRoles();
  },

  methods: {
    cancelDialog() {
      this.$emit("close");
    },
    validate() {
      this.errors = [];
      if (!this.user_first_name) {
        this.errors.push({
          key: "user_first_name",
          message: "first name is required",
        });
      }
      if (!this.user_last_name) {
        this.errors.push({
          key: "user_last_name",
          message: "last name is required",
        });
      }
      if (!this.user_email) {
        this.errors.push({
          key: "user_email",
          message: "email is required",
        });
      }
      // if (!this.user_password) {
      //   this.errors.push({
      //     key: "user_password",
      //     message: "password is required",
      //   });
      // }
      // if (!this.user_password_confirm) {
      //   this.errors.push({
      //     key: "user_password_confirm",
      //     message: "password is required",
      //   });
      // } else if (this.user_password !== this.user_password_confirm) {
      //   this.errors.push({
      //     key: "user_password_confirm",
      //     message: "password does not match",
      //   });
      // }
      if (!this.user_role) {
        this.errors.push({
          key: "user_role",
          message: "role is required",
        });
      }
      // if (!this.user_status) {
      //   this.errors.push({
      //     key: "user_status",
      //     message: "status is required",
      //   });
      // }
      if (this.errors.length > 0) {
        this.general_store.showSnackbar(
          "Please fill all required fields",
          "error"
        );
        return false;
      }
      return true;
    },
    getUser() {
      apiClient
        .get(`/my-team/get-user/${this.id}`)
        .then((response) => {
          console.log(response.data.data);
          this.user_first_name = response.data.data.first_name;
          this.user_last_name = response.data.data.last_name;
          this.user_email = response.data.data.email;
          this.user_role = response.data.data.role_id || "";
          this.user_status = response.data.data.status === "Active" ? 1 : 0;
        })
        .catch((error) => {
          this.general_store.showSnackbar("Failed to fetch users", "error");
        });
    },
    updateUser() {
      if (!this.validate()) return;

      apiClient
        .post(`/my-team/update-user-role`, {
          user_id: this.id,
          first_name: this.user_first_name,
          last_name: this.user_last_name,
          email: this.user_email,
          // password: this.user_password,
          // password_confirmation: this.user_password_confirm,
          front_role_id: this.user_role,
          status: this.user_status,
          lang: "ar",
        })
        .then((response) => {
          this.general_store.showSnackbar(
            "User Updated Successfully",
            "success"
          );
          this.$emit("updated");
          this.$emit("close");
        })
        .catch((error) => {
          this.general_store.showSnackbar("Failed to fetch users", "error");
          this.$emit("close");
        });
    },
    getRoles() {
      apiClient
        .get(`/my-team/get-roles`)
        .then((response) => {
          this.roles = response.data.data;
        })
        .catch((error) => {
          this.general_store.showSnackbar("Failed to fetch users", "error");
        });
    },
  },
};
</script>
