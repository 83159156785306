<template>
  <div class="d-flex flex-column ga-2">
    <span>End Page</span>
    <span class="small-text"
      >This is the message Snapchatters see after they submit your form.
    </span>
  </div>
  <div class="d-flex flex-column ga-2">
    <span>Default Message </span>
    <span class="small-text"
      >Thanks! Your answers were sent to [Brand Name].
    </span>
  </div>
  <div v-if="store.call_to_action" class="single-consent ">
    <div class="consent-title d-flex align-center justify-space-between">
      <span>{{ "Call To Action" }}</span>
      <svg
        @click="RemoveCallToAction()"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="#444546"
        xmlns="http://www.w3.org/2000/svg"
        class="sds-icon sds-icon-trash cursor-pointer"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.19527 3.36191C6.32029 3.23688 6.48986 3.16665 6.66667 3.16665H9.33334C9.51015 3.16665 9.67972 3.23688 9.80474 3.36191C9.92977 3.48693 10 3.6565 10 3.83331H6C6 3.6565 6.07024 3.48693 6.19527 3.36191ZM4.66667 3.83331C4.66667 3.30288 4.87739 2.79417 5.25246 2.4191C5.62753 2.04403 6.13624 1.83331 6.66667 1.83331H9.33334C9.86377 1.83331 10.3725 2.04403 10.7476 2.4191C11.1226 2.79417 11.3333 3.30288 11.3333 3.83331H13.3333V5.16665H2.66667V3.83331H4.66667ZM4.66667 5.83331V12.5C4.66667 12.6768 4.73691 12.8464 4.86193 12.9714C4.98696 13.0964 5.15653 13.1666 5.33334 13.1666H10.6667C10.8435 13.1666 11.0131 13.0964 11.1381 12.9714C11.2631 12.8464 11.3333 12.6768 11.3333 12.5V5.83331H12.6667V12.5C12.6667 13.0304 12.456 13.5391 12.0809 13.9142C11.7058 14.2893 11.1971 14.5 10.6667 14.5H5.33334C4.80291 14.5 4.2942 14.2893 3.91912 13.9142C3.54405 13.5391 3.33334 13.0304 3.33334 12.5V5.83331H4.66667ZM7.33334 7.16665C7.33334 6.79846 7.03486 6.49998 6.66667 6.49998C6.29848 6.49998 6.00001 6.79846 6.00001 7.16665V11.1666C6.00001 11.5348 6.29848 11.8333 6.66667 11.8333C7.03486 11.8333 7.33334 11.5348 7.33334 11.1666V7.16665ZM9.33334 6.49998C9.70153 6.49998 10 6.79846 10 7.16665V11.1666C10 11.5348 9.70153 11.8333 9.33334 11.8333C8.96515 11.8333 8.66667 11.5348 8.66667 11.1666V7.16665C8.66667 6.79846 8.96515 6.49998 9.33334 6.49998Z"
        ></path>
      </svg>
    </div>
    <div class="filed mt-3 px-6">
      <div class="title">Call To Action</div>
      <div class="mt-2">
        <v-select
          variant="solo"
          class="v-select-style"
          v-model="store.call_to_action.button_text"
          :items="items"
          item-title="title"
          item-value="value"
        ></v-select>
      </div>
    </div>
    <div class="consent-body snapchat">
      <div class="filed mt-3">
        <span class="title">Website Url</span>
        <div class="input-container">
          <div class="position-relative">
            <input
              class="w-100"
              type="text"
              v-model="store.call_to_action.website_url"
              placeholder="Description"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    v-else
    style="width: fit-content"
    @click="AddCallToAction"
    class="d-flex align-center justify-space-between upload-span"
  >
    <v-icon icon="mdi-plus" class="Caption"></v-icon>
    <span>Add Call To Action</span>
    <v-icon icon="mdi-chevron-down" class="small-text"></v-icon>
  </button>
</template>

<script>
import { useLeadFormStore } from "@/store/snapchat/LeadForm";
export default {
  name: "EndingForm",
  data() {
    return {
      store: useLeadFormStore(),
      items: [
        { title: "View Website", value: "view_website" },
        { title: "Book Now", value: "book_now" },
        { title: "Learn More", value: "learn_more" },
        { title: "Donate", value: "donate" },
        { title: "Special Offer", value: "special_offer" },
        { title: "Schedule Now", value: "schedule_now" },
        { title: "Buy Tickets", value: "buy_tickets" },
        { title: "Test Drive", value: "test_drive" },
        { title: "Apply Now", value: "apply_now" },
        { title: "Get Coupon", value: "get_coupon" },
        { title: "Claim Sample", value: "claim_sample" },
        { title: "Free Trail", value: "free_trail" },
      ],
    };
  },
  methods: {
    AddCallToAction() {
      this.store.call_to_action = {
        button_text: "",
        website_url: "",
      };
    },
    RemoveCallToAction() {
      this.store.call_to_action = null;
    },
  },
};
</script>
<style scoped>
input {
  border: 2px solid transparent;
  border-radius: 8px !important;
  background: #f9f9fa;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0 0 0 0 transparent;
  box-sizing: border-box;
  font-family: inherit;
  transition: box-shadow 0.15s ease-out 0.15s, border-color 0.15s,
    background 0.15s ease-out 0.15s, color 0.15s ease-out;
  width: 100%;
  border: transparent 2px solid;
  height: 40px;
}
</style>