<template>
  <div class="selectes d-flex ga-2 align-end">
     <div class="d-flex flex-column ga-1">
      <label class="trailsest" for="">Trail 1</label>
      <v-select
        v-model="trail_1"
        :class="[
          trail_1 === 'replied'
            ? 'green-selection'
            : trail_1 === 'no_reply'
            ? 'red-selection'
            : '',
          'rounded-lg',
        ]"
        :items="unreach_items"
        item-title="title"
        item-value="value"
      ></v-select>
    </div>
    <div v-if="trail_1 === 'no_reply'" class="d-flex flex-column ga-1">
      <label class="trailsest" for="">Trail 2</label>
      <v-select
        v-model="trail_2"
        :class="
          trail_2 === 'replied'
            ? 'green-selection'
            : trail_2 === 'no_reply'
            ? 'red-selection'
            : ''
        "
        :items="unreach_items"
        item-title="title"
        item-value="value"
      ></v-select>
    </div>
    <div
      v-if="trail_1 === 'no_reply' && trail_2 === 'no_reply'"
      class="d-flex flex-column ga-1"
    >
      <label class="trailsest" for="">Trail 3</label>
      <v-select
        v-model="trail_3"
        :class="
          trail_3 === 'replied'
            ? 'green-selection'
            : trail_3 === 'no_reply'
            ? 'red-selection'
            : ''
        "
        :items="unreach_items"
        item-title="title"
        item-value="value"
      ></v-select>
    </div>
    <!-- <div
      v-if="
        trail_1 === 'replied' || trail_2 === 'replied' || trail_3 === 'replied'
      "
      :class="['d-flex', 'align-center', 'ga-2']"
      style="position: relative"
    >
       <span
        @click="openCalendar"
        :id="`calendarParent+${item.id}`"
        :class="[
          consultation_date === '' ? 'alert-red' : 'alert-green',
          'date cursor-pointer',
        ]"
      >
        {{ consultation_date || "----/--/--" }}
        <span @click="clearDate">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 3L3 9M3 3L9 9"
              stroke="#027A48"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </span>
      <input type="text" ref="datepicker" style="display: none" />
      <span class="cursor-pointer" @click="reached_status_info_dialog = true">
        <svg
          data-v-faa1dddc=""
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            data-v-faa1dddc=""
            width="24"
            height="24"
            rx="8"
            fill="#237276"
          ></rect>
          <path
            data-v-faa1dddc=""
            d="M12 16H16.5M14.25 7.74999C14.4489 7.55108 14.7187 7.43933 15 7.43933C15.1393 7.43933 15.2772 7.46677 15.4059 7.52007C15.5346 7.57337 15.6515 7.6515 15.75 7.74999C15.8485 7.84848 15.9266 7.96541 15.9799 8.09409C16.0332 8.22278 16.0607 8.3607 16.0607 8.49999C16.0607 8.63928 16.0332 8.7772 15.9799 8.90589C15.9266 9.03457 15.8485 9.1515 15.75 9.24999L9.5 15.5L7.5 16L8 14L14.25 7.74999Z"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </span>
    </div> -->
    <!-- <Confirmation :item="item" :selected_reached_lead_type="selected_item" /> -->
  </div>
  <!-- Dialog -->
  <!-- <v-dialog v-model="reached_status_info_dialog" persistent max-width="500px">
    <v-card class="pa-4">
      <v-card-title
        class="d-flex justify-space-between align-center dialog-title"
      >
        <span class="title">Book Information</span>
        <span
          class="cursor-pointer"
          @click="reached_status_info_dialog = false"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 1L1 11M1 1L11 11"
              stroke="#666666"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column ga-3 mb-4">
          <div class="field d-flex flex-column ga-2">
            <label for="doctor_name">Doctor Name</label>
            <input v-model="doctor_name" type="text" id="doctor_name" />
          </div>
          <div class="field d-flex flex-column ga-2">
            <label for="department_name">Department Name</label>
            <input v-model="department_name" type="text" id="department_name" />
          </div>
          <div class="field d-flex flex-column ga-2">
            <label for="department_name">Service</label>
            <v-autocomplete
              :items="[
                'Emergency Care',
                'Surgery',
                'Pediatrics',
                'Maternity and Obstetrics',
                'Oncology',
                'Orthopedics',
              ]"
              variant="outlined"
              v-model="service"
              hide-details
            ></v-autocomplete>
          </div>
          <div class="field d-flex flex-column ga-2 position-relative">
            <label for="department_name">Consultation Date</label>
            <flatPickrComp
              v-model="consultation_date"
              :config="datePickerConfig"
            ></flatPickrComp>
          </div>
        </div>
        <div class="d-flex justify-end ga-3">
          <span @click="cancelReachedInfo" class="cancel-btn cursor-pointer"
            >Clear</span
          >
          <span @click="selectReachedInfo" class="Choose-btn cursor-pointer"
            >Save</span
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog> -->
</template>

<script>
import flatPickr from "flatpickr";
import Confirmation from "@/components/crm/leads/options/confirmation.vue";
import apiClient from "@/core/buildRequest";
import { useLeadsStore } from "@/store/crm/useLeadsStore";
import CallBackReplied from "@/components/crm/leads/options/CallBackReplied.vue";
import flatPickrComp from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "UnReachedType",
  components: { flatPickrComp, Confirmation, CallBackReplied },
  props: ["item", "selected_item"],
  data() {
    return {
      unreach_items: [
        { title: "Replied", value: "replied" },
        { title: "Not Replied", value: "no_reply" },
      ],
      trail_1: this.item.trails?.["1st_trail"],
      trail_2: this.item.trails?.["2nd_trail"],
      trail_3: this.item.trails?.["3rd_trail"],
      reached_status_info_dialog: false,
      doctor_name: this.item.bookedInfo?.doctorName,
      department_name: this.item.bookedInfo?.departmentName,
      consultation_date: this.item.bookedInfo?.consultationDate,
      service: this.item.bookedInfo?.service,
      datePickerConfig: {
        dateFormat: "Y-m-d",
      },
      flatpickrInstance: null,
    };
  },
  mounted() {

    if (this.consultation_date != "") {
      this.initDatePicker();
    }
  },
  watch: {
    async trail_1(val) {
      await this.handleTrail(1, val);
    },
    async trail_2(val) {
      await this.handleTrail(2, val);
    },
    async trail_3(val) {
      await this.handleTrail(3, val);
    },
    consultation_date() {
      this.selectReachedInfo();
    },
  },
  methods: {
    async postToApi(url, data) {
      try {
        return await apiClient.post(url, data);
      } catch (error) {
        console.error(`Error posting to ${url}:`, error);
        throw error;
      }
    },

    async updateCallStatus(itemId, callStatus) {
      const baseUrl = this.item.haveTrails
        ? "/leads-managment/update-call-status"
        : "/leads-managment/call";
      const url = `${baseUrl}/${itemId}`;
      this.item.haveTrails = true;

      return await this.postToApi(url, callStatus);
    },

    async updateReachability(itemId, status) {
      const url = `/leads-managment/leads/update-reachability/${itemId}`;
      await this.postToApi(url, { reachablitiy: status });

      const store = useLeadsStore();
      store?.updateReachabilityStatus(
        itemId,
        status === "reached" ? "Reached" : "Unreached"
      );
    },

    async handleTrail(trailNumber, val) {
      try {
        const trailKey =
          trailNumber === 1
            ? "1st_trail"
            : trailNumber === 2
            ? "2nd_trail"
            : "3rd_trail";
        if (val === "replied" && this.item.trails?.[trailKey] !== val) {
          const callStatus = {
            "1st_trail": trailNumber === 1 ? "replied" : "no_reply",
            "2nd_trail": trailNumber === 2 ? "replied" : "no_reply",
            "3rd_trail": trailNumber === 3 ? "replied" : "",
          };
          this.item.trails[trailKey] = val;

          await this.updateCallStatus(this.item.id, callStatus);
          await this.updateReachability(this.item.id, "reached");

          this.reached_status_info_dialog = true;
        } else if (val === "no_reply" && this.item.trails?.[trailKey] !== val) {
          const callStatus = {
            "1st_trail": trailNumber >= 1 ? "no_reply" : "no_reply",
            "2nd_trail": trailNumber >= 2 ? "no_reply" : "no_reply",
            "3rd_trail": trailNumber >= 3 ? "no_reply" : "",
          };
          this.item.trails[trailKey] = val;
          await this.updateCallStatus(this.item.id, callStatus);
          await this.updateReachability(this.item.id, "unreached");
        }
      } catch (error) {
        console.error(`Error handling trail ${trailNumber}:`, error);
      }
    },

    async selectReachedInfo() {
      if (
        this.doctor_name &&
        this.consultation_date &&
        this.service &&
        this.department_name &&
        this.doctor_name !== "" &&
        this.consultation_date !== "" &&
        this.service !== "" &&
        this.department_name !== ""
      ) {
        const url = `/leads-managment/booking/${this.item.id}`;
        const payload = {
          doctor_name: this.doctor_name,
          department: this.department_name,
          appointment_date: this.consultation_date,
          service: this.service,
        };
        const response = await this.postToApi(url, payload);
      }

      this.reached_status_info_dialog = false;
    },

    openCalendar() {
      this.initDatePicker();
      this.flatpickrInstance.open();
    },
    initDatePicker() {
      const datepicker = this.$refs.datepicker;
      const calendar = datepicker?.parentNode;

      if (!datepicker) {
        console.error("Datepicker element is not found.");
        return;
      }

      if (!calendar) {
        console.error("Calendar element is not found.");
        return;
      }

      this.flatpickrInstance = flatPickr(datepicker, {
        defaultDate: this.consultation_date,
        dateFormat: "Y-m-d",
        appendTo: calendar,
        onChange: (selectedDates, dateStr) => {
          this.consultation_date = dateStr;
          this.$emit("update_consultation_date", dateStr);
        },
      });
    },
  },
};
</script>

<style scoped>
.flatpickr-calendar {
  top: 10px !important;
}

.date {
  height: 32px;
  padding: 2px 12px;
  border-radius: 16px;
  white-space: nowrap;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #ecfdf3;
  color: #027a48;
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
