<template>
  <v-container fluid class="pa-0 ma-0 notifications-view">
    <v-row :no-gutters="true" class="h-100">
      <v-col cols="12">
        <GlobalNav />
      </v-col>
      <v-col cols="12" class="d-flex h-100">
        <SideBar />
        <v-container>
          <v-row no-gutters class="my-3">
            <v-col cols="3"></v-col>
            <v-col cols="6" class="notifications-title">
              <img @click="this.$router.push({ name: 'HomePage' })" :src="HomeIcon" alt="" />
              <h4>Notifications</h4>
            </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="3"></v-col>
            <v-col cols="6" class="my-3 d-flex align-center justify-space-between">
              <div class="d-flex justify-start align-center ga-2 notifications-tabs">
                <button @click="store.getNotifications('all')" :class="{ active: store.activeTab === 'all' }">
                  All
                </button>
                <button @click="store.getUnreadNotifications('unread')"
                  :class="{ active: store.activeTab === 'unread' }">
                  Unread
                </button>
                <button @click="store.getReadNotifications('read')" :class="{ active: store.activeTab === 'read' }">
                  Read
                </button>
              </div>
              <button @click="store.markAllAsRead()">
                {{ $t('make_all_as_read') }}
              </button>
              <button @click="store.deleteAllNotifications()" class="d-flex align-center ga-1 delete-all">
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px">
                  <path
                    d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-48v-72h192v-48h192v48h192v72h-48v479.57Q720-186 698.85-165T648-144H312Zm336-552H312v480h336v-480ZM384-288h72v-336h-72v336Zm120 0h72v-336h-72v336ZM312-696v480-480Z" />
                </svg>
                <span> Delete All </span>
              </button>

            </v-col>
            <v-col cols="3"></v-col>

            <v-col cols="3"></v-col>
            <v-col cols="6" class="position-relative">
              <div class="arrow-down" @click="store.showMoreNotifications()"  :class="store.current_page === store.total_pages ? 'show' : 'hide'" >
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#237276">
                    <path d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z" />
                  </svg>
                </span>
              </div>
              <v-list-item v-if="store.notificationsArr?.length === 0">
                <p>No new notifications</p>
              </v-list-item>
              <v-list-item class="mb-2" v-for="(item, index) in store.notificationsArr" :key="index" :value="index"
                :style="{
                  backgroundColor: item.read_at == null ? 'rgba(242, 242, 242, 1)' : '#fff',
                  borderRadius: '5px',
                }">
                <v-list-item-title class="d-flex align-center ga-3">
                  <SingleNotifiaction :item="item" />
                </v-list-item-title>

              </v-list-item>


              <!-- <v-infinite-scroll height="80vh" @load="store.load" 
              
              v-if="store.notificationsArr?.length > 0">
                <template >
                  </template>
                    <template v-slot:loading>
                  <v-progress-circular v-if="store.currentPage <= store.total_pages" indeterminate color="primary" size="24"></v-progress-circular>
                    </template>
                 </v-infinite-scroll> -->
            </v-col>
            <v-col cols="3"></v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import HomeIcon from "@/assets/images/home.svg";
// import apiClient from "@/core/buildRequest";
import defaultImage from "@/assets/images/icon-512-512.png";
import SingleNotifiaction from "@/components/layout/SingleNotifiaction.vue";
import { notification } from "@/store/notifications/notification";
import Pagination from "@/components/crm/assets/Pagination.vue";
export default {
  components: { GlobalNav, SideBar, SingleNotifiaction, Pagination },
  data() {
    return {
      HomeIcon,
      defaultImage,
      store: notification(),
      showArrow: true
    };
  },
  methods: {
    handleScroll(event) {
      const container = event.target;
      const scrollPosition = container.scrollTop;

      // Show arrow when scrolled down more than 100px
      if (scrollPosition > 100) {
        this.showArrow = true;
      } else {
        this.showArrow = false;
      }
    },
    scrollToBottom() {
      const scrollContainer = document.querySelector('.v-infinite-scroll');
      if (scrollContainer) {
        scrollContainer.scrollTo({
          top: scrollContainer.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  },
  mounted() {
    this.store.getNotifications("all");
    this.$nextTick(() => {
      const scrollContainer = document.querySelector('.v-infinite-scroll');
      if (scrollContainer) {
        scrollContainer.addEventListener('scroll', this.handleScroll);
        // Initial check
        this.handleScroll({ target: scrollContainer });
      }
    });
  },
  beforeUnmount() {
    const scrollContainer = document.querySelector('.v-infinite-scroll');
    if (scrollContainer) {
      scrollContainer.removeEventListener('scroll', this.handleScroll);
    }
  }
};
</script>



<style scoped>
.arrow-down {
  position: absolute;
  bottom: 5%;
  right: -35px;
  z-index: 10;
  animation: bounce 2s infinite;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

/* .hide-arrow {
  opacity: 0;
  visibility: hidden;
} */

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-15px);
  }

  60% {
    transform: translateY(-7px);
  }
}

.arrow-down span {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  background-color: rgba(35, 114, 118, 0.1);
}

.arrow-down:hover span {
  transform: scale(1.1);
  background-color: rgba(35, 114, 118, 0.2);
}

.delete-all {
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  outline: none;
}

.delete-all svg {
  fill: #EA3323;
}

.delete-all span {
  color: #EA3323;
}

.delete-all:hover {
  background-color: #EA3323;
  color: #fff;
}

.delete-all:hover span,
.delete-all:hover svg {
  fill: #fff;
  color: #fff;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
</style>
