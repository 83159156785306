import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";
import router from "@/router/index.js";
import { v4 as uuidv4 } from "uuid";
import { useGeneralStore } from "@/store/General";

function getDeviceId() {
  let deviceId = localStorage.getItem("deviceId");
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem("deviceId", deviceId);
  }
  return deviceId;
}
if (!localStorage.getItem("deviceId")) {
  getDeviceId();
}
function storeToken(service, token, expireDate) {
  localStorage.setItem(`${service}_token`, token);
  localStorage.setItem(`${service}TokenExpireAt`, expireDate);
}

export const loginForm = defineStore("logIn", {
  state: () => ({
    accounts: [],
    role: "",
    permissions: [],
  }),
  getters: {
    getAccounts: (state) => state.accounts,
  },
  actions: {
    async checkAuth() {
      let status = false;
      if (localStorage.getItem("user_token")) {
        const { data } = await apiClient.get("/check-user-token");
        if (data.data.user_id !== null) {
          let c = JSON.parse(localStorage.getItem("user"));
          status = c["data"].id === data.data.user_id;
        }
      }
      if (!status) {
        for (const key of Object.keys(localStorage)) {
          if (key !== "deviceId" || key !== "lang") {
            localStorage.removeItem(key);
          }
        }
      }
      return status;
    },
    async getAllAccounts() {
      const { data } = await apiClient.get("/get-user-accounts", {
        headers: {
          "device-id": localStorage.getItem("deviceId"),
        },
      });
      this.accounts = data.data;
    },
    async getCredentials() {
      try {
        const { data } = await apiClient.get("/get-credentials");
        const credentials = data.data;

        [
          "snapchat",
          "tiktok",
          "facebook",
          "instagram",
          "twitter",
          "google_ads",
          "drive",
        ].forEach((service) => {
          if (credentials[service]) {
            storeToken(
              service,
              credentials[service].access_token,
              credentials[service].expire_date
            );
          }
        });
      } catch (error) {
        console.error("Error fetching credentials:", error);
      }
    },
    async getRolesPermissions() {
      if (localStorage.getItem("user_token")) {
        try {
          const { data } = await apiClient.get("/get-user-roles");
          this.role = data.data.name;
          this.permissions = data.data.permissions.map((item) => item.prefix);
        } catch (error) {
          console.error("Login failed:", error);
        }
      }
    },
    async login(email, password) {
      try {
        const deviceId = await getDeviceId();

        const { data } = await apiClient.post("/sign-in", {
          email,
          password,
          device_type: "web",

          uuid_device: deviceId,
          device_id: deviceId,
        });
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("user_token", data.data.token);
        useGeneralStore().showSnackbar("Login Successfully", "success");
        this.getRolesPermissions();
        this.getCredentials();
        router.push({ name: "HomePage" });
      } catch (error) {
        console.error("Login failed:", error);
      }
    },
    async LoginByAccountId(id) {
      try {
        const deviceId =  getDeviceId();
        const { data } = await apiClient.post("/login-as-account", {
          device_id: deviceId,
          uuid_device: deviceId,
          user_id: id,
          device_type: "web",
        });
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("user_token", data.data.token);
        useGeneralStore().showSnackbar("Login Successfully", "success");
        this.getRolesPermissions();
        this.getCredentials();
        router.push({ name: "HomePage" });
      } catch (error) {
        console.error("Login failed:", error);
      }
    },
    async logout() {
      try {
        await apiClient.get("/sign-out");
        for (const key of Object.keys(localStorage)) {
          if (key !== "deviceId" && key !== "lang") {
            localStorage.removeItem(key);
          }
        }
        useGeneralStore().showSnackbar("Logout Successfully", "success");
        router.push({ name: "LoginPage" });

      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
    async deleteOldAccount(id) {
      try {
        const { data } = await apiClient.post("/delete-user-device", {
          device_id: localStorage.getItem("deviceId"),
          user_id: id,
        });
        this.getAllAccounts();
        useGeneralStore().showSnackbar("Logout Successfully", "success");
        router.push({ name: "Login" });
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
  },
});
