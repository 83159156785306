<template>
  <div class="engagement-type">
    <label for="" style="font-size: 14px;color: #1c2b33;">Engagement type</label>
    <v-select
      v-model="slectedEngagementType"
      :items="engagementType"
      density="compact"
      variant="outlined"
      return-object
      item-value="value"
      item-title="title"
      hide-details
    >
      <template v-slot:selection="{ item }">
        {{ item?.title || "" }}
      </template>
      <template v-slot:item="{ item, index }">
        <v-list-item @click="selectItemWinner(item)">
          <div class="my-form">
            <div class="ga-3">
              <input
                :checked="
                  slectedEngagementType &&
                  slectedEngagementType.id === item.raw.id
                "
                :id="`${item.raw.id}_engagement_type`"
                type="radio"
                name="option"
                :value="item"
              />
              <div class="d-flex flex-column ga-1">
                <label
                  :for="`${item.raw.id}_engagement_type`"
                  class="d-block"
                  style="font-size: 14px"
                  >{{ item.raw.title }}</label
                >
              </div>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slectedEngagementType: {
        id: 0,
        title: "Video views",
        value:'video_views',
      },
      engagementType: [
        { id: 0, title: "Video views",value:'video_views' },
        { id: 1, title: "Post engagement",value:'post_engagement' },
        { id: 2, title: "Event responses", value:'event_responses'},
        { id: 3, title: "Reminders set",value:'reminders_set' },
      ],
    };
  },
  methods: {
    selectItemWinner(item) {
      this.slectedEngagementType = item.raw;
      localStorage.setItem("enganement_type", item.raw.value);
    },
  },
};
</script>
