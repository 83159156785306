<template>
  {{ direct_traffic_location }}
  <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
    <template v-slot:activator="{ props }">
      <button
        variant="text"
        class="w-100 text-start goal-select"
        v-bind="props"
      >
        {{ selectedGoal }}
      </button>
    </template>
    <v-card min-width="300">
      <v-expansion-panels v-model="expandedPanel">
        <v-expansion-panel
          v-for="(goal, index) in filteredGoals"
          :key="goal.title"
          :value="index"
        >
          <v-expansion-panel-title>
            {{ goal.title }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div
              v-for="option in goal.options"
              :key="option.id"
              class="d-flex flex-column ga-3"
            >
              <div class="d-flex align-center ga-2 option">
                <div class="option-check">
                  <input
                    @change="handleOptionSelect"
                    name="tree-select"
                    :id="'radio' + option.id"
                    class="radio-button-snap"
                    type="radio"
                    :value="option.value"
                    v-model="selectedGoal"
                  />
                </div>
                <label
                  :for="'radio' + option.id"
                  class="option-text d-flex flex-column ga-2"
                >
                  <span class="title">{{ option.title }}</span>
                  <span class="desc">{{ option.description }}</span>
                </label>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "GoalsMenu",
  props: ["selected", "direct_traffic_location"],
  data() {
    return {
      selectedType: localStorage.getItem("selectedOption"),
      menu: false,
      selectedGoal: "",
      expandedPanel: null,
      goals: [
        {
          types: ["Awareness & Engagement"],
          title: "Awareness Goals",
          options: [
            {
              id: 1,
              title: "Maximize number of impressions",
              value: "IMPRESSIONS",
              description:
                "Ads will be shown to Snapchatters as many times as possible.",
            },
          ],
        },
        {
          types: ["Awareness & Engagement"],
          title: "Video View Goals",
          options: [
            {
              id: 2,
              title: "Maximize 15-second video views",
              value: "VIDEO_VIEWS_15_SEC",
              description:
                "Video Ads will be shown to people who are likely to watch 15 continuous seconds or more.",
            },
            {
              id: 3,
              title: "Maximize 2-second video views",
              value: "VIDEO_VIEWS",
              description:
                "Video Ads will be shown to people who are likely to watch 2 continuous seconds or more.",
            },
          ],
        },
        {
          types: ["Awareness & Engagement"],
          title: "Engagement Goals",
          options: [
            {
              id: 4,
              title: "Maximize number of clicks",
              value: "SWIPES",
              description:
                "Ads will be shown to Snapchatters who are most likely to click on them.",
            },
            {
              id: 5,
              title: "Maximize number of shares",
              value: "USES",
              description:
                "Ads will be shown to Snapchatters who are most likely to share them.",
            },
            {
              id: 6,
              title: "Maximize number of Story opens",
              value: "STORY_OPENS",
              description:
                "Story Ads will be shown to Snapchatters who are most likely to open them.",
            },
          ],
        },

        {
          types: ["Leads"],
          title: "Leads Goals",
          location: ["LEAD-FORM"],
          options: [
            {
              id: 1,
              title: "maximize number of leads",
              value: "LEAD_FORM_SUBMISSIONS",
              description:
                "Ads will be shown to people who are most likely to share their contact information with you.",
            },
          ],
        },

        {
          types: ["Traffic"],
          location: ["WEB"],
          title: "Website Traffic Goals",
          options: [
            {
              id: 2,
              title: "Maximize Number of Landing Page Views",
              value: "LANDING_PAGE_VIEWS",
              description:
                "Ads will be shown to Snapchatters who are more likely to view the fully loaded website or app linked in your ad.",
            },
          ],
        },
        {
          types: ["Traffic"],
          location: ["APP"],
          title: "App Traffic Goals",
          options: [
            {
              id: 2,
              title: "Maximize number of in-app events",
              value: "APP_INSTALLS",
              description:
                "Ads will be shown to Snapchatters who are most likely to take a specific action in your app.",
            },
            {
              id: 2,
              title: "Maximize Number of Landing Page Views",
              value: "LANDING_PAGE_VIEWS",
              description:
                "Ads will be shown to Snapchatters who are more likely to view the fully loaded website or app linked in your ad.",
            },
          ],
        },
        {
          types: ["Traffic"],
          location: ["MY_PUBLIC_PROFILE"],
          title: "Profile Visit Goals",
          options: [
            {
              id: 2,
              title: "Maximize number of clicks",
              value: "SWIPES",
              description:
                "Ads will be shown to Snapchatters who are most likely to click on them.",
            },
            {
              id: 2,
              title: "Maximize number of impressions",
              value: "IMPRESSIONS",
              description:
                "Ads will be shown to Snapchatters as many times as possible.",
            },
          ],
        },
        {
          types: ["Traffic", "Leads"],
          location: ["CALL"],
          title: "Call Goals",
          options: [
            {
              id: 2,
              title: "Maximize number of clicks",
              value: "SWIPES",
              description:
                "Ads will be shown to Snapchatters who are most likely to click on them.",
            },
            {
              id: 2,
              title: "Maximize number of impressions",
              value: "IMPRESSIONS",
              description:
                "Ads will be shown to Snapchatters as many times as possible.",
            },
          ],
        },
        {
          types: ["Traffic", "Leads"],
          location: ["TEXT"],
          title: "Call Goals",
          options: [
            {
              id: 2,
              title: "Maximize number of clicks",
              value: "SWIPES",
              description:
                "Ads will be shown to Snapchatters who are most likely to click on them.",
            },
            {
              id: 2,
              title: "Maximize number of impressions",
              value: "IMPRESSIONS",
              description:
                "Ads will be shown to Snapchatters as many times as possible.",
            },
          ],
        },
        {
          types: ["App Promotion"],
          title: "Install Goals",
          options: [
            {
              id: 2,
              title: "Maximize number of app installs",
              value: "INSTALLS",
              description:
                "Ads will be shown to Snapchatters who are most likely to install your app.",
            },
            {
              id: 2,
              title: "Maximize number of in-app events taken after an install",
              value: "INSTALLS",
              description:
                "Ads will be shown to Snapchatters who are most likely to install your app and then take a specific action in your app.",
            },
          ],
        },
        {
          types: ["Sales"],
          title: "App Conversion Goals",
          location: ["APP"],

          options: [
            {
              id: 2,
              title: "Maximize number of in-app events",
              value: "APP_INSTALLS",
              description:
                "Ads will be shown to Snapchatters who are most likely to take a specific action in your app.",
            },
          ],
        },
        {
          types: ["Traffic"],
          location: ["WEB", "APP"],
          title: "Other Goals",
          options: [
            {
              id: 2,
              title: "maximize number of clicks",
              value: "SWIPES",
              description:
                "Ads will be shown to Snapchatters who are most likely to click on them.",
            },
          ],
        },
        {
          types: ["Leads"],
          location: ["WEB"],
          title: "Other Goals",
          options: [
            {
              id: 2,
              title: "maximize number of clicks",
              value: "SWIPES",
              description:
                "Ads will be shown to Snapchatters who are most likely to click on them.",
            },
            {
              id: 2,
              title: "Maximize Number of Landing Page Views",
              value: "LANDING_PAGE_VIEWS",
              description:
                "Ads will be shown to Snapchatters who are more likely to view the fully loaded website or app linked in your ad.",
            },
          ],
        },
        {
          types: ["App Promotion"],
          title: "Other Goals",
          options: [
            {
              id: 2,
              title: "Maximize number of clicks",
              value: "SWIPES",
              description:
                "Ads will be shown to Snapchatters who are most likely to click on them.",
            },
            {
              id: 2,
              title: "Maximize number of impressions",
              value: "IMPRESSIONS",
              description:
                "Ads will be shown to Snapchatters as many times as possible.",
            },
          ],
        },
        {
          types: ["Sales"],
          location: ["APP"],
          title: "Other Goals",
          options: [
            {
              id: 2,
              title: "Maximize number of clicks",
              value: "SWIPES",
              description:
                "Ads will be shown to Snapchatters who are most likely to click on them.",
            },
            {
              id: 2,
              title: "Maximize number of impressions",
              value: "IMPRESSIONS",
              description:
                "Ads will be shown to Snapchatters as many times as possible.",
            },
          ],
        },
        {
          types: ["Sales"],
          title: "Other Goals",
          location: ["WEB"],

          options: [
            {
              id: 2,
              title: "Maximize number of clicks",
              value: "SWIPES",
              description:
                "Ads will be shown to Snapchatters who are most likely to click on them.",
            },
            {
              id: 2,
              title: "Maximize Number of Landing Page Views",
              value: "LANDING_PAGE_VIEWS",
              description:
                "Ads will be shown to Snapchatters who are more likely to view the fully loaded website or app linked in your ad.",
            },
            {
              id: 2,
              title: "Maximize number of Story opens",
              value: "STORY_OPENS",
              description:
                "Story Ads will be shown to Snapchatters who are most likely to open them.",
            },
          ],
        },
      ],
    };
  },
  watch: {
    selectedGoal(v) {
      this.menu = false;
    },
  },
  methods: {
    handleOptionSelect() {
      this.$emit("selectedGoal", this.selectedGoal);
      this.$nextTick(() => {
        this.expandedPanel = null;
      });
    },
  },
  computed: {
    selectedTitle() {
      return this.goals.find(
        (goal) => goal.options.find((o) => o.value === this.selectedGoal).title
      );
    },
    filteredGoals() {
      console.log(this.selectedType, this.direct_traffic_location);
      if (
        this.selectedType === "Awareness & Engagement" ||
        this.selectedType === "App Promotion"
      ) {
        return this.goals.filter(
          (g) => g.types.includes(this.selectedType) || g.types.includes("all")
        );
      }
      return this.goals.filter(
        (g) =>
          (g.types.includes(this.selectedType) || g.types.includes("all")) &&
          (g?.location.includes(this.direct_traffic_location) || !g?.location)
      );
    },
  },
  mounted() {
    this.selectedGoal = this.selected;
  },
};
</script>

<style scoped>
.goal-select {
  align-items: center;
  background: #f9f9fa;
  border-color: transparent;
  border-radius: 8px;
  line-height: 20px;
  min-height: 40px;
  padding-left: 11px;
  padding-right: 11px;
}
.goal-select:hover {
  border: 1px solid #f7d901;
}

.option .option-text .title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 17px;
  grid-area: label;
  cursor: pointer;
  color: rgb(0, 0, 0);
}
.option .option-text .desc {
  color: rgb(84, 85, 87);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #000000;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

input[type="radio"]:checked {
  background-color: #000000;
}

input[type="radio"]:checked::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
