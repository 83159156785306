<template>
  <v-col :cols="12" :lg="6" class="d-flex ga-5 align-center">
    <div class="d-flex ga-1 align-center input-data-text">
      <DateIcon />
      <label class="calender-input-label text-capitalize d-flex"
        >Start Date & Time <span class="text-red-accent-4">*</span></label
      >
    </div>
    <div class="task-calendar-date-input">
      <v-date-input
        v-model="store.from"
        prepend-icon=""
        keyboard-date-time-24h
        variant="solo"
        :model-type="'iso'"
        :model-format="'yyyy-MM-dd HH:mm:ss'"
      ></v-date-input>
    </div>
  </v-col>
  <v-col :cols="12" :lg="6" class="d-flex ga-5 align-center">
    <div class="d-flex ga-1 align-center input-data-text">
      <DateIcon />
      <label class="calender-input-label text-capitalize"
        >End Date & Time <span class="text-red-accent-4">*</span></label
      >
    </div>
    <div class="task-calendar-date-input">
      <v-date-input
        v-model="store.to"
        prepend-icon=""
        variant="solo"
        hide-details
        keyboard-date-time-24h
        :model-type="'iso'"
        :model-format="'yyyy-MM-dd HH:mm:ss'"
      ></v-date-input>
    </div>
  </v-col>
</template>

<script>
import { useEditTaskStore } from "@/store/editTaskStore";
import DateIcon from "./icons/DateIcon.vue";
import { VDateInput } from "vuetify/labs/VDateInput";

export default {
  name: "TaskDates",
  components: {
    DateIcon,
    VDateInput,
  },
  data() {
    return {
      store: useEditTaskStore(),
    };
  },
  watch: {},
  methods: {
    formatDateTime(date) {
      if (!date) return null;
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      const hours = String(d.getHours()).padStart(2, "0");
      const minutes = String(d.getMinutes()).padStart(2, "0");
      const seconds = String(d.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>
