<template>
  <div class="comments-container">
    <div
      @click="toggleComments"
      class="toggle-comments"
      v-if="comments.length > 0"
    >
      <span v-if="showComments" class="d-flex align-center ga-1">
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 5L5 1L1 5"
            stroke="#666666"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Hide
      </span>
      <span v-else class="d-flex align-center ga-1">
        <svg
          style="transform: rotate(180deg)"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 5L5 1L1 5"
            stroke="#666666"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Show
      </span>
    </div>

    <div class="comments" v-if="showComments">
      <TaskComment
        v-for="(comment, index) in comments"
        :key="index"
        :comment="comment"
      />
    </div>

    <div class="create-comments">
      <TaskNewComment @comment-added="handleNewComment" />
    </div>
  </div>
</template>

<script>
import TaskComment from "./TaskComment.vue";
import TaskNewComment from "./TaskNewComment.vue";
import { useTaskStore } from "@/store/taskStore";
export default {
  name: "TaskComments",
  components: {
    TaskComment,
    TaskNewComment,
  },
  data() {
    return {
      showComments: true,
      comments: [],
      store: useTaskStore(),
    };
  },
  watch: {
    "store.comments"(newValue) {
      console.log(this.store.comments);
    },
  },
  methods: {
    toggleComments() {
      this.showComments = !this.showComments;
    },
    handleNewComment(comment) {
      this.comments.push({
        user: {
          name: "Current User",
          avatar: "https://ui-avatars.com/api/?length=1?name=User",
        },
        time: "Just now",
        content: comment.comment,
        attachments: comment.attachments,
        links: comment.links,
      });
      console.log(this.comments);

      this.store.comments = this.comments;
    },
  },
};
</script>

<style scoped>
.toggle-comments {
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
}

.comments {
  margin-top: 1rem;
}
</style>
