<template>
  <div class="profile-box-container w-100">
    <div class="mb-3">
      <label for="" class="profile-box-title"> {{ $t('password') }} </label>
    </div>
    <div class="d-flex flex-column py-5">
      <div class="profile-box-second-text mb-3">{{ $t('current_password') }}</div>
      <Input
        v-model="current_password"
        type="password"
        :placeholder="$t('password')"
      />
      <span
        class="text-red-accent-4 text-caption small-text mt-2"
        v-if="errors.old_password"
        >{{ errors.old_password }}</span
      >
    </div>
    <div class="d-flex flex-column py-5">
      <div class="profile-box-second-text mb-3">{{ $t('new_password') }}</div>
      <Input
        v-model="new_password"
        type="password"
        :placeholder="$t('new_password')"
      />
      <span
        class="text-red-accent-4 text-caption small-text mt-2"
        v-if="errors.password"
        >{{ errors.password }}</span
      >
    </div>
    <div class="d-flex flex-column py-5">
      <div class="profile-box-second-text mb-3">{{ $t('confirm_new_password') }}  </div>
      <Input
        v-model="confirm_new_password"
        type="password"
        :placeholder="$t('confirm_new_password')"
      />
      <span
        class="text-red-accent-4 text-caption small-text mt-2"
        v-if="errors.password_confirmation"
        >{{ errors.password_confirmation }}</span
      >
    </div>
  </div>
</template>
<script>
import Input from "@/components/crm/assets/Input.vue";

export default {
  name: "Password",
  components: { Input },
  props: ["errors"],
  data() {
    return {
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    };
  },
  beforeCreate() {},
  methods: {},
  watch: {
    errors(val) {
      console.log(val);
    },
    current_password() {
      this.$emit("change:currentPassword", this.current_password);
    },
    new_password() {
      this.$emit("change:newPassword", this.new_password);
    },
    confirm_new_password() {
      this.$emit("change:confirmPassword", this.confirm_new_password);
    },
  },
};
</script>
