import Leadsmanagement from "@/views/crm/leads/LeadsView.vue";
import LeadsmanagementDashboard from "@/views/crm/leads/LeadsManagementDashboard.vue";
import Contactsmanagement from "@/views/crm/contacts/ContactsView.vue";
import ShowContact from "@/views/crm/contacts/ShowContact.vue";
import ContactsDashboard from "@/views/crm/contacts/ContactsDashboard.vue";
import Accountsmanagement from "@/views/crm/accounts/AccountsView.vue";
import ShowAccount from "@/views/crm/accounts/ShowAccount.vue";
import AccountsDashboard from "@/views/crm/accounts/AccountsDashboard.vue";
import Projectsmanagement from "@/views/crm/projects/ProjectsView.vue";
import ShowProject from "@/views/crm/projects/ShowProject.vue";
import ProjectsDashboard from "@/views/crm/projects/ProjectsDashboard.vue";
import Dealsmanagement from "@/views/crm/deals/DealsView.vue";
import ShowDeal from "@/views/crm/deals/ShowDeal.vue";
import DealsDashboard from "@/views/crm/deals/DealsDashboard.vue";
import Eventsmanagement from "@/views/crm/events/EventsView.vue";
import ShowEvent from "@/views/crm/events/ShowEvent.vue";
import EventsDashboard from "@/views/crm/events/EventsDashboard.vue";
import Tasksmanagement from "@/views/crm/tasks/TasksView.vue";
import ShowTask from "@/views/crm/tasks/ShowTask.vue";
import TasksDashboard from "@/views/crm/tasks/TasksDashboard.vue";
const crmRoutes = [
  {
    path: "/leads-management",
    name: "Leadsmanagement",
    component: Leadsmanagement,
    meta: {
      title: "Leads management",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/leads-management/dashboard",
    name: "LeadsmanagementDashboard",
    component: LeadsmanagementDashboard,
    meta: {
      title: "Leads management Dashboard",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/contacts-management",
    name: "Contactsmanagement",
    component: Contactsmanagement,
    meta: {
      title: "Contacts management",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/contact/:id",
    name: "ShowContact",
    component: ShowContact,
    meta: {
      title: "Show Contact",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/contact/dashboard",
    name: "ContactsDashboard",
    component: ContactsDashboard,
    meta: {
      title: "Contact Dashboard",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },

  {
    path: "/accounts-management",
    name: "Accountsmanagement",
    component: Accountsmanagement,
    meta: {
      title: "Accounts management",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/account/:id",
    name: "ShowAccount",
    component: ShowAccount,
    meta: {
      title: "Show Contact",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/account/dashboard",
    name: "AccountsDashboard",
    component: AccountsDashboard,
    meta: {
      title: "Account Dashboard",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },

  {
    path: "/projects-management",
    name: "Projectsmanagement",
    component: Projectsmanagement,
    meta: {
      title: "Projects management",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/project/:id",
    name: "ShowProject",
    component: ShowProject,
    meta: {
      title: "Show Project",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/project/dashboard",
    name: "ProjectsDashboard",
    component: ProjectsDashboard,
    meta: {
      title: "Project Dashboard",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },

  //// deals
  {
    path: "/deals-management",
    name: "Dealsmanagement",
    component: Dealsmanagement,
    meta: {
      title: "Deals management",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/deal/:id",
    name: "ShowDeal",
    component: ShowDeal,
    meta: {
      title: "Show Deal",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/deal/dashboard",
    name: "DealsDashboard",
    component: DealsDashboard,
    meta: {
      title: "Deal Dashboard",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },

  /////events
  {
    path: "/events-management",
    name: "Eventsmanagement",
    component: Eventsmanagement,
    meta: {
      title: "Events management",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/event/:id",
    name: "ShowEvent",
    component: ShowEvent,
    meta: {
      title: "Show Event",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/event/dashboard",
    name: "EventsDashboard",
    component: EventsDashboard,
    meta: {
      title: "Event Dashboard",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  /////tasks
  {
    path: "/tasks-management",
    name: "Tasksmanagement",
    component: Tasksmanagement,
    meta: {
      title: "Tasks management",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/task/:id",
    name: "ShowTask",
    component: ShowTask,
    meta: {
      title: "Show Task",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
  {
    path: "/task/dashboard",
    name: "TasksDashboard",
    component: TasksDashboard,
    meta: {
      title: "Task Dashboard",
      description: "",
      auth: "auth",
      
      permissions: [
        "leads-managment.create",
        "leads-managment.read",
        "leads-managment.update",
        "leads-managment.delete",
      ],
    },
  },
];

export default crmRoutes;
