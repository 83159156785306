<template>
  <div class="facebook-page mb-3">
    <label
      for=""
      class="roboto-bold d-block"
      style="font-size: 14px; color: #000000bf"
      >Facebook Page</label
    >
    <v-select
      v-model="store.facebookSelectedId"
      :items="store.facebookPages"
      density="compact"
      variant="outlined"
      return-object
      item-value="id"
      item-title="title"
      hide-details
    >
      <template v-slot:selection="{ item }">
        <img
          style="
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-right: 10px;
          "
          :src="item.raw?.imageUrl"
          alt=""
        />
        <div class="d-flex flex-column">
          <span style="font-size: 12px" class="roboto-black d-block">{{
            item.raw?.name || ""
          }}</span>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item @click="store.selectedfacebookPage(item)">
          <div class="my-form">
            <div class="ga-3">
              <input
                :checked="
                  store.facebookSelectedId &&
                  store.facebookSelectedId.id === item.raw.id
                "
                :id="`${item.raw.id}_conversion_app_goal_qq`"
                type="radio"
                name="selectedPageIdAwarance"
                :value="item"
              />
              <div class="d-flex align-center ga-1">
                <img
                  style="
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin-right: 10px;
                  "
                  :src="item.raw.imageUrl"
                  alt=""
                />
                <label
                  :for="`${item.raw.id}_conversion_app_goal_qq`"
                  class="d-block"
                  style="font-size: 14px"
                >
                  <span class="roboto-black d-block">{{ item.raw.name }}</span>
                  <span class="roboto-regular d-block">
                    ID: {{ item.raw.id }}</span
                  >
                </label>
              </div>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-select>
    
  </div>
</template>

<script>
import { conversionDate } from "@/store/meta/conversionDate/conversions";
export default {
  data() {
    return {
      store: conversionDate(),
    };
  },
  mounted() {
    this.store.getAllPages();
  },
};
</script>
