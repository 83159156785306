<template>
  <div class="d-flex flex-column ga-2">
    <span>End Page</span>
    <span class="small-text"
      >This is the message Snapchatters see after they submit your form.
    </span>
  </div>
  <div class="d-flex flex-column ga-2">
    <span>Default Message </span>
    <span class="small-text"
      >Thanks! Your answers were sent to [Brand Name].
    </span>
  </div>
  <div v-if="store.call_to_action" class="single-consent">
    <div class="consent-title d-flex align-center justify-space-between">
      <span>{{ "Call To Action" }}</span>
    </div>
    <div class="filed mt-3 px-6">
      <div class="title">Call To Action</div>
      <div class="mt-2">
        <v-select
          variant="solo"
          class="v-select-style"
          :value="
            data?.lead_generation_form?.end_page_properties?.call_to_action.toLowerCase()
          "
          :items="items"
          item-title="title"
          item-value="value"
        ></v-select>
      </div>
    </div>
    <div class="consent-body snapchat">
      <div class="filed mt-3">
        <span class="title">Website Url</span>
        <div class="input-container">
          <div class="position-relative">
            <input
              class="w-100"
              type="text"
              :value="data?.lead_generation_form?.end_page_properties?.url"
              placeholder="Description"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    v-else
    style="width: fit-content"
    @click="AddCallToAction"
    class="d-flex align-center justify-space-between upload-span"
  >
    <v-icon icon="mdi-plus" class="Caption"></v-icon>
    <span>Add Call To Action</span>
    <v-icon icon="mdi-chevron-down" class="small-text"></v-icon>
  </button>
</template>

<script>
import { useLeadFormStore } from "@/store/snapchat/LeadForm";
export default {
  name: "EndingForm",
  props: {
    data: Object,
  },
  data() {
    return {
      store: useLeadFormStore(),
      items: [
        { title: "View Website", value: "view_website" },
        { title: "Book Now", value: "book_now" },
        { title: "Learn More", value: "learn_more" },
        { title: "Donate", value: "donate" },
        { title: "Special Offer", value: "special_offer" },
        { title: "Schedule Now", value: "schedule_now" },
        { title: "Buy Tickets", value: "buy_tickets" },
        { title: "Test Drive", value: "test_drive" },
        { title: "Apply Now", value: "apply_now" },
        { title: "Get Coupon", value: "get_coupon" },
        { title: "Claim Sample", value: "claim_sample" },
        { title: "Free Trail", value: "free_trail" },
      ],
    };
  },
  methods: {
    AddCallToAction() {
      this.store.call_to_action = {
        button_text: "",
        website_url: "",
      };
    },
    RemoveCallToAction() {
      this.store.call_to_action = null;
    },
  },
};
</script>
