import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";
import { useI18n } from "vue-i18n"; 
export const useLeadsStore = defineStore("useLeadsStore", {
  state: () => {
    const { t } = useI18n();
    return {
      leads: [],
      headers: [
        {
          title: t("marketing_campaign_time"),
          name: "Marketing Campaign Time",
          align: "start",
          sortable: true,
          key: "campaignTime",
        },
        {
          title: t("marketing_campaign"),
          name: "Marketing Campaign",
          align: "start",
          sortable: true,
          key: "marketingCampaign",
        },
        {
          title: t("marketing_source"),
          align: "start",
          sortable: true,
          key: "platform",
        },
        {
          title: t("first_mame"),
          name: "First Name",
          align: "start",
          sortable: true,
          key: "firstName",
        },
        {
          title: t("last_name"),
          name: "Last Name",
          align: "start",
          sortable: true,
          key: "lastName",
        },
        {
          title: t("phone_number"),
          name: "Phone Number",
          align: "start",
          sortable: true,
          key: "phoneNumber",
        },
        {
          title: t("status"),
          name: "Status",
          align: "start",
          sortable: true,
          key: "status",
        },
        {
          title: t("leads_progress"),
          name: "Leads Progress",
          align: "start",
          sortable: true,
          key: "leadsProgressCol",
        },
        {
          title: t("reachability"),
          name: "Reachability",
          align: "start",
          sortable: true,
          key: "reachability",
        },
        {
          title: t("comments"),
          name: "Comments",
          align: "start",
          sortable: false,
          key: "comments",
        },
        {
          title: t("assignee"),
          name: "Assignee",
          align: "start",
          sortable: true,
          key: "assignee",
        },
      ],
      filters: [],
      search: "",
      activities: [],
    };
  },
  getters: {
    getLeads: (state) => state.leads,
    getActivities: (state) => state.activities,
  },
  actions: {
    async getAllLeads() {
      try {
        var response = await apiClient.get(`/leads-managment/leads`, {
          params: {
            campaign_name: this.search.length > 0 ? this.search : null,
            filters: this.filters.length > 0 ? this.filters : null,
          },
        });
        this.leads = response.data.data.map((el) => this.formatLeadData(el));
      } catch (err) {
        console.error("Error fetching projects:", err);
      }
    },
    formatLeadData(lead) {
      return {
        id: lead.id,
        campaignTime: lead.lead_genration_time,
        marketingCampaign: lead.campaign_name,
        platform: lead.platform,
        firstName: lead.first_name,
        lastName: lead.last_name,
        phoneNumber: lead.phone,
        status: lead.status,
        reachability: lead.reachablitiy,
        leadsProgressCol: "",
        comments: "",
        assignee: lead.assigned_agents,
        touchedStatus: lead.status,
        reachedStatus: lead.progress_status,
        secondReachedStatus: lead.second_progress_status,
        bookedInfo: {
          doctorName: lead.booking?.doctor_name,
          departmentName: lead.booking?.department,
          service: lead.booking?.service,
          consultationDate: lead.booking?.appointment_date,
        },
        attendanceStatus: lead?.attendece_status,
        survey: [],
        activities: [],
        updates: [],
        files: [],
        trails: {
          "1st_trail": lead.call?.["1st_trail"],
          "2nd_trail": lead.call?.["2nd_trail"],
          "3rd_trail": lead.call?.["3rd_trail"],
        },
        haveTrails: lead?.call?.id ? true : false,
        created_at: "2023-06-01T12:00:00.000Z",
        updated_at: "2023-06-01T12:00:00.000Z",
      };
    },
    updateReachabilityStatus(id, val) {
      const index = this.leads.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.leads[index].reachability = val;
      }
    },
    formatDateFromInput(date) {
      if (!date) return "";
      const d = new Date(date);
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(d.getDate()).padStart(2, "0")}`;
    },
    FormattedDateToInput(dateString) {
      if (!dateString) return null;
      const [datePart, timePart] = dateString.split(" ");
      const [year, month, day] = datePart.split("-").map(Number);
      const [hours, minutes, seconds] = timePart.split(":").map(Number);

      return new Date(year, month - 1, day, hours, minutes, seconds);
    },
    getAllActivities(id) {
      apiClient.get(`/leads-managment/overview/${id}`).then((res) => {
        this.activities = res.data.data;
      });
    },
  },
});
