<template>
  <div class="boost-post-card">
    <div class="meta-pixel mb-5">
      <div class="d-flex align-center justify-space-between">
        <div class="left-side-img d-flex align-center ga-6">
          <span>
            <img
              style="width: 20px; height: 20px"
              :src="webDevelopmentIcon"
              alt=""
            />
          </span>
          <div>
            <p
              style="
                color: rgba(102, 102, 102, 1);
                font-size: 14px;
                font-weight: 400;
              "
            >
              Meta pixel
            </p>
            <p
              style="
                color: rgba(13, 13, 13, 1);
                font-size: 18px;
                font-weight: 500;
              "
            >
              Track conversions and create custom audiences
            </p>
          </div>
        </div>
        <div class="right-side">
          <v-switch
            id="meta-pixel"
            v-model="store.metaPixel"
            color="info"
            hide-details
            inset
          ></v-switch>
        </div>
      </div>
    </div>
    <div class="meta-pixel mb-3">
      <div class="d-flex align-center justify-space-between">
        <div class="left-side-img d-flex align-center ga-6">
          <span>
            <img style="width: 20px; height: 20px" :src="CapaIcon" alt="" />
          </span>
          <div>
            <p
              style="
                color: rgba(102, 102, 102, 1);
                font-size: 14px;
                font-weight: 400;
              "
            >
              Payment method
            </p>
            <p
              style="
                color: rgba(13, 13, 13, 1);
                font-size: 18px;
                font-weight: 500;
              "
            >
              Ad credit: $0.01
            </p>
          </div>
        </div>
        <div class="right-side">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="rgba(26, 26, 26, 1)"
          >
            <path d="m291-453-51-51 240-240 240 240-51 51-189-189-189 189Z" />
          </svg>
        </div>
      </div>
    </div>
    <div class="add-credite">
      <div class="image">
        <img :src="discountIcon" alt="" />
      </div>
      <div class="amount-paid">
        <p>Ad credit: $0.01</p>
      </div>
    </div>
  </div>
</template>

<script>
import { boostPost } from "@/store/publishPost/boostPost";
import webDevelopmentIcon from "@/assets/images/web-development_74809311.svg";
import discountIcon from "@/assets/images/discount_14794721.svg";
import CapaIcon from "@/assets/images/Capa_1.svg";
export default {
  data() {
    return {
      store: boostPost(),
      webDevelopmentIcon,
      discountIcon,
      CapaIcon,
    };
  },
};
</script>
