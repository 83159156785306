<template>
  <div class="tiktok-footer mt-4">
    <div class="footer d-flex align-center justify-space-between">
      <div>
        <button class="exit-btn">Exit</button>
      </div>
      <div class="continue">
        <button @click="validateBudget()" class="continue-btn">Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
import { tiktokData } from "@/store/tiktokStore/tiktokData.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      store: tiktokData(),
    };
  },
  
  mounted() {},
  methods: {
    validateBudget() {
      const selectedObjective = localStorage.getItem("selected_objective");

      const budgetSpendLocal = Number(localStorage.getItem("budget_spend"));
      const directObjectives = [
        "TRAFFIC",
        "ENGAGEMENT",
        "APP_PROMOTION",
        "LEAD_GENERATION",
        "WEBSITE_CONVERSIONS",
        "PRODUCT_SALES",
      ];
      if (directObjectives.includes(selectedObjective) && this.store.budgetSpend >= 500) {
        this.$router.push({ name: "TikTokSecoundStep" });
        
      } else if (
        selectedObjective === "REACH" &&
        (selectedObjective === "VIDEO_VIEWS" || this.store.budgetSpend < 500)
      ) {
        alert("Please enter a budget spend of at least 500 for REACH or VIDEO_VIEWS.");
      } else {
        this.$router.push({ name: "TikTokSecoundStep" });
      }
      console.log("Current budget spend:", budgetSpendLocal);
    },
  },
};
</script>
