<template>
  <v-col :cols="12" :lg="9">
    <div class="mt-4 platforms-select">
      <div class="bold-text mb-2">Platform</div>
      <v-select
        readonly
        v-model="store.platforms"
        :items="store?.platforms_list"
        label="Select Platforms"
        multiple
        item-title="name"
        item-value="id"
        return-object
      >
        <template v-slot:selection="{ item }">
          <img
            width="20"
            height="20"
            class="mr-2"
            :src="item.raw.icon"
            :alt="item.raw.name"
          />
          {{ item.raw.name }}
        </template>
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :title="null">
            <template v-slot:prepend>
              <div class="d-flex align-center">
                <v-checkbox-btn
                  :model-value="isPlatformSelected(item.raw)"
                  density="compact"
                  :color="item.raw.color"
                  class="mr-2"
                ></v-checkbox-btn>
                <img
            width="20"
            height="20"
            class="mr-2"
            :src="item.raw.icon"
            :alt="item.raw.name"
          />
                {{ item.raw.name }}
              </div>
            </template>
          </v-list-item>
        </template>
      </v-select>
    </div>
  </v-col>
</template>

<script>
import { useEditTaskStore } from "@/store/editTaskStore";

export default {
  name: "TaskPlatforms",
  data() {
    return {
      store: useEditTaskStore(),
    };
  },

  watch: {
    "store.platforms"(newValue) {
      this.store.getBranches();
    },
  },
  methods: {
    isPlatformSelected(platform) {
      return this.store.platforms.some((p) => p.id === platform.id);
    },
    togglePlatformSelection(platform) {
      const index = this.store.platforms.findIndex((p) => p.id === platform.id);
      if (index === -1) {
        this.store.updatePlatforms([...this.store.platforms, platform]);
      } else {
        const newPlatforms = [...this.store.platforms];
        newPlatforms.splice(index, 1);
        this.store.updatePlatforms(newPlatforms);
      }
    },
  },
};
</script>
