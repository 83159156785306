import { defineStore } from "pinia";
import { useGeneralStore } from "../General";

export const uploadMedia = defineStore("uploadMedia", {
  state: () => ({
    media: [],
    mediaType: "",
    previews: [],
    errors: [],
    selectedMedia: []
  }),
  actions: {
    validateVideo(file) {
      const validFormats = ['.mp4', '.mov', '.mpeg', '.avi'];
      const maxSize = 500 * 1024 * 1024; // 500MB in bytes
      const errors = [];

      // Check file type
      const extension = '.' + file.name.split('.').pop().toLowerCase();
      if (!validFormats.includes(extension)) {
        errors.push(`Invalid format: ${extension}. Allowed formats: ${validFormats.join(', ')}`);
      }

      // Check file size
      if (file.size > maxSize) {
        errors.push(`File size (${(file.size / (1024 * 1024)).toFixed(1)}MB) exceeds maximum allowed size (500MB)`);
      }

      return new Promise((resolve) => {
        if (file.type.startsWith('video/')) {
          const video = document.createElement('video');
          video.preload = 'metadata';

          video.onloadedmetadata = () => {
            const aspectRatio = video.videoWidth / video.videoHeight;
            const validRatios = [
              { ratio: 16/9, name: 'Horizontal (16:9)' },
              { ratio: 1/1, name: 'Square (1:1)' },
              { ratio: 9/16, name: 'Vertical (9:16)' }
            ];

            const isValidRatio = validRatios.some(({ ratio }) => 
              Math.abs(aspectRatio - ratio) < 0.1 // Allow small deviation
            );

            if (!isValidRatio) {
              errors.push(`Invalid aspect ratio (${aspectRatio.toFixed(2)}). Required: Horizontal(16:9) / Square(1:1) / Vertical(9:16)`);
            }

            URL.revokeObjectURL(video.src);
            resolve(errors);
          };

          video.onerror = () => {
            URL.revokeObjectURL(video.src);
            resolve(errors);
          };

          video.src = URL.createObjectURL(file);
        } else {
          resolve(errors);
        }
      });
    },

    async setMedia(files) {
      const newFiles = Array.from(files);
      this.errors = [];

      // Validate each video file
      for (const file of newFiles) {
        if (file.type.startsWith('video/')) {
          const errors = await this.validateVideo(file);
          if (errors.length > 0) {
            this.errors.push({ file: file.name, errors });
            useGeneralStore().showSnackbar("Validation errors for " + file.name + ": " + errors.join(', '), "error");
            return;
          }
        }
      }

      // If all validations pass, add the files
      const newPreviews = newFiles.map(file => ({
        url: URL.createObjectURL(file),
        type: file.type.startsWith('image/') ? 'image' : 'video',
        name: file.name,
        size: file.size,
        id: Date.now() + '_' + Math.random().toString(36).substr(2, 9) // Generate unique ID
      }));

      this.media = [...this.media, ...newFiles];
      this.previews = [...this.previews, ...newPreviews];

      newFiles.forEach((file, index) => {
        console.log(`New File ${index + 1}:`, {
          name: file.name,
          type: file.type,
          size: `${(file.size / 1024 / 1024).toFixed(2)} MB`,
        });
      });
      
      console.log(`Total files in collection: ${this.media.length}`);
    },

    removeFile(index) {
      if (index >= 0 && index < this.media.length) {
        // Remove from selectedMedia if it was selected
        const previewId = this.previews[index].id;
        this.selectedMedia = this.selectedMedia.filter(item => item.id !== previewId);
        
        URL.revokeObjectURL(this.previews[index].url);
        this.media.splice(index, 1);
        this.previews.splice(index, 1);
      }
    },

    toggleSelection(preview) {
      const index = this.selectedMedia.findIndex(item => item.id === preview.id);
      if (index === -1) {
        // Add to selection
        this.selectedMedia.push(preview);
      } else {
        // Remove from selection
        this.selectedMedia.splice(index, 1);
      }
      console.log('Selected media:', this.selectedMedia.map(item => item.name));
    },

    isSelected(preview) {
      return this.selectedMedia.some(item => item.id === preview.id);
    },

    clearSelection() {
      this.selectedMedia = [];
    }
  }
});
