<template>
    <div class="estimate-results mb-3">
    <div class="estimate-results-head mb-3">
      <p class="title">Estimated daily results</p>
      <p class="sub-title">Your ad will run for 7 days.</p>
    </div>
    <div class="estimate-results-body">
      <p class="title">Accounts Centre accounts reached</p>
      <p class="details">1.5K-4.3K</p>
    </div>
  </div>
</template>

