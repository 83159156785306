<template>
  <div class="leads-management-filter">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" :lg="4">
        <span class="d-flex align-center ga-2">
          <Input
            class="w-100"
            v-model="search"
            type="search"
            :icon="SearchIcon"
            :placeholder="$t('search_here') + '...'"
            @keyup.enter="handleInput"
          />
          <button @click="handleInput" class="cancel-btn" style="width: auto">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.9881 4.52007C11.673 4.14956 8.32686 4.14956 5.01172 4.52007C4.78742 4.54514 4.6794 4.80819 4.82135 4.98366L7.7522 8.60662C8.80211 9.90447 9.37491 11.5233 9.37491 13.1926V15.6003L10.6249 16.517V13.1926C10.6249 11.5233 11.1977 9.90446 12.2476 8.60662L15.1785 4.98366C15.3204 4.80819 15.2124 4.54514 14.9881 4.52007ZM4.87288 3.27781C8.2803 2.89698 11.7195 2.89698 15.1269 3.27781C16.3327 3.41257 16.9134 4.82656 16.1503 5.76983L13.2194 9.39279C12.3495 10.4681 11.8749 11.8094 11.8749 13.1926V17.7503C11.8749 17.9859 11.7425 18.2014 11.5324 18.3079C11.3223 18.4143 11.0702 18.3936 10.8803 18.2543L8.38031 16.421C8.21976 16.3033 8.12491 16.1161 8.12491 15.917V13.1926C8.12491 11.8094 7.65031 10.4682 6.78038 9.39279L3.84953 5.76983C3.08646 4.82656 3.66711 3.41257 4.87288 3.27781Z"
                fill="#666666"
              />
            </svg>
          </button>
          <AdvancedFilter />
        </span>
        <!-- <GroupBy /> -->
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <button
          @click="$router.push({ name: 'LeadsmanagementDashboard' })"
          class="d-flex align-center ga-3 dashoard-btn"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.666 14.6668H10.666C9.53268 14.6668 8.66602 13.8002 8.66602 12.6668V3.3335C8.66602 2.20016 9.53268 1.3335 10.666 1.3335H12.666C13.7993 1.3335 14.666 2.20016 14.666 3.3335V12.6668C14.666 13.8002 13.7993 14.6668 12.666 14.6668ZM10.666 2.66683C10.266 2.66683 9.99935 2.9335 9.99935 3.3335V12.6668C9.99935 13.0668 10.266 13.3335 10.666 13.3335H12.666C13.066 13.3335 13.3327 13.0668 13.3327 12.6668V3.3335C13.3327 2.9335 13.066 2.66683 12.666 2.66683H10.666Z"
              fill="white"
            />
            <path
              d="M5.33398 14.6665H3.33398C2.20065 14.6665 1.33398 13.7998 1.33398 12.6665V8.6665C1.33398 7.53317 2.20065 6.6665 3.33398 6.6665H5.33398C6.46732 6.6665 7.33398 7.53317 7.33398 8.6665V12.6665C7.33398 13.7998 6.46732 14.6665 5.33398 14.6665ZM3.33398 7.99984C2.93398 7.99984 2.66732 8.2665 2.66732 8.6665V12.6665C2.66732 13.0665 2.93398 13.3332 3.33398 13.3332H5.33398C5.73398 13.3332 6.00065 13.0665 6.00065 12.6665V8.6665C6.00065 8.2665 5.73398 7.99984 5.33398 7.99984H3.33398Z"
              fill="white"
            />
            <path
              d="M5.33398 5.3335H3.33398C2.20065 5.3335 1.33398 4.46683 1.33398 3.3335C1.33398 2.20016 2.20065 1.3335 3.33398 1.3335H5.33398C6.46732 1.3335 7.33398 2.20016 7.33398 3.3335C7.33398 4.46683 6.46732 5.3335 5.33398 5.3335ZM3.33398 2.66683C2.93398 2.66683 2.66732 2.9335 2.66732 3.3335C2.66732 3.7335 2.93398 4.00016 3.33398 4.00016H5.33398C5.73398 4.00016 6.00065 3.7335 6.00065 3.3335C6.00065 2.9335 5.73398 2.66683 5.33398 2.66683H3.33398Z"
              fill="white"
            />
          </svg>

          <span>{{ $t("dashboard") }}</span>
        </button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Input from "@/components/crm/assets/Input.vue";
import SearchIcon from "@/assets/images/icons/search.svg";
import AdvancedFilter from "@/components/crm/leads/advancedFilter.vue";
import GroupBy from "@/components/crm/leads/GroupBy.vue";
import { useLeadsStore } from "@/store/crm/useLeadsStore";

export default {
  name: "FilterSection",
  components: {
    Input,
    AdvancedFilter,
    GroupBy,
  },
  data() {
    return {
      search: "",
      SearchIcon: SearchIcon,
      store: useLeadsStore(),
    };
  },
  methods: {
    handleInput() {
      this.store.search = this.search;
      this.store.getAllLeads();
    },
  },
};
</script>
