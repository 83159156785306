<template>
  <v-row>
    <v-col cols="12" md="12">
      <div class="project-card">
        <div class="project-title d-flex align-center justify-space-between">
          {{ project.name }}

          <div class="d-flex flex-wrap">
            <div class="d-flex align-center ga-2">
              <span v-for="(member, index) in project.members" :key="index">
                <img class="circle-img" :src="member.image" :alt="member.name" />
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap align-center ga-3 data">
          <div class="element d-flex align-center ga-2">
            <span class="title">Status :</span
            ><span class="value">{{ project.status }}</span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Value :</span
            ><span class="value">{{ project.value }} </span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Currency :</span
            ><span class="value">{{ project.currency }}</span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Probability :</span
            ><span class="value"> {{ project.probability }} %</span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Project Type :</span
            ><span class="value">{{ project.type }}</span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Location :</span
            ><span class="value">{{ project.location }}</span>
          </div>
        </div>
        <div class="d-flex flex-wrap align-center ga-3 data mt-4">
          <div class="element d-flex align-center ga-2">
            <span class="title">Challenges :</span
            ><span class="value"
              >{{ project.challenges.slice(0, 20) + "..." }}
            </span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Description :</span>
            <span class="value">
              {{ project.description.slice(0, 20) + "..." }}
            </span>
          </div>
        </div>
      </div>
    </v-col>

    <v-col cols="12" md="12" class="">
      <div class="project-card h-100">
        <div class="project-title d-flex align-center justify-space-between">
          <span> Related Accounts </span>
          <div>
            <v-btn
              id="show-hidden-columns"
              variant="text"
              class="show-hidden-columns pa-0"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6L8 10L12 6"
                  stroke="#666666"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-columns">
              <v-list>
                <v-list-item
                  v-for="(item, index) in hidden_account_headers"
                  :key="index"
                  :value="index"
                >
                  <v-list-item-title
                    class="d-flex align-center ga-2"
                    @click="showHiddenAccountColumn(item.key)"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3141_74103)">
                        <path
                          d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                          stroke="#666666"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3141_74103">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RelatedAccount :removeSelect="true" :contacts="project.account" />

      </div>
    </v-col>
    <v-col cols="12" md="12" class="">
      <div class="project-card h-100">
        <div class="project-title d-flex align-center justify-space-between">
          <span> Related Contacts </span>
          <div>
            <v-btn
              id="show-hidden-related-deals-columns"
              variant="text"
              class="show-hidden-columns pa-0"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6L8 10L12 6"
                  stroke="#666666"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-related-deals-columns">
              <v-list>
                <v-list-item
                  v-for="(item, index) in hidden_contacts_headers"
                  :key="index"
                  :value="index"
                >
                  <v-list-item-title
                    class="d-flex align-center ga-2"
                    @click="showHiddenContactColumn(item.key)"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3141_74103)">
                        <path
                          d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                          stroke="#666666"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3141_74103">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RelatedContact :removeSelect="true" :contacts="project.contacts" />
      </div>
    </v-col>
    <v-col cols="12" md="12" class="">
      <div class="contact-card h-100">
        <div class="contact-title d-flex align-center justify-space-between">
          <span> Related Events </span>
          <div>
            <v-btn
              id="show-hidden-related-event-columns"
              variant="text"
              class="show-hidden-columns pa-0"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6L8 10L12 6"
                  stroke="#666666"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-related-event-columns">
              <v-list>
                <v-list-item
                  v-for="(item, index) in hidden_events_headers"
                  :key="index"
                  :value="index"
                >
                  <v-list-item-title
                    class="d-flex align-center ga-2"
                    @click="showHiddenEventsColumn(item.key)"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3141_74103)">
                        <path
                          d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                          stroke="#666666"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3141_74103">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RelatedEvents :removeSelect="true" :event="project.events" />
      </div>
    </v-col>
    <v-col cols="12" md="12" class="">
      <div class="contact-card h-100">
        <div class="contact-title d-flex align-center justify-space-between">
          <span> Related Tasks </span>
          <div>
            <v-btn
              id="show-hidden-related-tasks-columns"
              variant="text"
              class="show-hidden-columns pa-0"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6L8 10L12 6"
                  stroke="#666666"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-related-tasks-columns">
              <v-list>
                <v-list-item
                  v-for="(item, index) in hidden_tasks_headers"
                  :key="index"
                  :value="index"
                >
                  <v-list-item-title class="d-flex align-center ga-2">
                    <div
                      class="d-flex align-center ga-2"
                      @click="showHiddenTasksColumn(item.key)"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3141_74103)">
                          <path
                            d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                            stroke="#666666"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3141_74103">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      {{ item.title }}
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RelatedTasks :removeSelect="true" :tasks="project.tasks" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import RelatedEvents from "@/components/crm/events/table.vue";
import RelatedContact from "@/components/crm/contacts/table.vue";
import RelatedTasks from "@/components/crm/tasks/table.vue";
import RelatedAccount from "@/components/crm/accounts/table.vue";
import { useProjectsStore } from "@/store/crm/useProjectsStore";
import { useAccountsStore } from "@/store/crm/useAccountsStore";
import { useContactsStore } from "@/store/crm/useContactsStore";
import { useTasksStore } from "@/store/crm/useTasksStore";
import apiClient from "@/core/buildRequest";

export default {
  name: "ProjectCard",
  components: {
    RelatedAccount,
    RelatedEvents,
    RelatedContact,
    RelatedTasks,
  },
  data() {
    return {
      project: {
        id: 0,
        account_id: 0,
        name: "",
        description: "",
        status: "",
        due_date: "",
        added_date: "",
        add_to_calendar: 0,
        type: "",
        location: "",
        value: 0,
        currency: "",
        probability: 0,
        challenges: "",
        feedback: "",
        project_number: "",
        members: [],
        contacts: [],
        account: {},
        deals: [],
        events: [],
        tasks: [],
      },
      relatedProject: {
        id: "ACC-001",
      },
      project_store: null,
      account_store: null,
      contact_store: null,
      members: [],
    };
  },
  computed: {
    projects() {
      if (this.project_store?.getProjects.lenghth > 0) {
        this.project_store?.getProjects;
      }
      return this.project_store?.getProjects || [];
    },
    accounts() {
      return this.account_store?.accounts || [];
    },
    contacts() {
      return this.contact_store?.contacts || [];
    },
    hidden_project_headers() {
      const headers = this.project_store?.getHeaders.filter(
        (header) => !header.show
      );
      return headers;
    },
    hidden_account_headers() {
      const headers = this.account_store?.getHeaders.filter(
        (header) => !header.show
      );
      return headers;
    },
    hidden_events_headers() {
      const headers = this.events_store?.getHeaders.filter(
        (header) => !header.show
      );
      return headers;
    },
    hidden_contacts_headers() {
      const headers = this.contact_store?.getHeaders.filter(
        (header) => !header.show
      );
      return headers;
    },
    hidden_tasks_headers() {
      const headers = this.tasks_store?.getHeaders.filter(
        (header) => !header.show
      );
      return headers;
    }, 
  },
  created() {
    this.project_store = useProjectsStore();
    this.account_store = useAccountsStore();
    this.contact_store = useContactsStore();
    this.tasks_store = useTasksStore();
  },
  mounted() {
    this.getProject(this.$route.params.id);
  },
  methods: {
    showHiddenTasksColumn(key) {
      const header = this.tasks_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenProjectColumn(key) {
      const header = this.project_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenAccountColumn(key) {
      const header = this.account_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenContactColumn(key) {
      const header = this.contact_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenEventsColumn(key) {
      const header = this.events_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    async getProject(id) {
      try {
        await apiClient
          .get(`/leads-managment/projects/${id}`)
          .then((response) => {
            this.project = response.data.data;
          });
      } catch (error) {
        console.error("Failed to get Project:", error);
      }
    },
  },
};
</script>

<style scoped>
.project-card {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px #dedede80;
}
.project-card .data .element {
  height: 40px;
  padding: 0px 24px 0px 16px;
  background: #f7f8f8;
  border-radius: 8px;
}
.project-card .data .element .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #0d0d0d;
}
.project-card .data .element .value {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #666666;
}
.project-title {
  color: #0d0d0d;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  margin-bottom: 30px;
}
</style>
