<template>
  <v-btn
    elevation="0"
    v-bind="props"
    class="px-1 mr-1"
    style="height: fit-content; min-width: fit-content !important"
    @click="handleItemClick(itemsSelect[0])"
  >
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.03809 13L5.62409 8.414C5.99914 8.03906 6.50776 7.82843 7.03809 7.82843C7.56841 7.82843 8.07703 8.03906 8.45209 8.414L13.0381 13M11.0381 11L12.6241 9.414C12.9991 9.03906 13.5078 8.82843 14.0381 8.82843C14.5684 8.82843 15.077 9.03906 15.4521 9.414L17.0381 11M11.0381 5H11.0481M3.03809 17H15.0381C15.5685 17 16.0772 16.7893 16.4523 16.4142C16.8274 16.0391 17.0381 15.5304 17.0381 15V3C17.0381 2.46957 16.8274 1.96086 16.4523 1.58579C16.0772 1.21071 15.5685 1 15.0381 1H3.03809C2.50765 1 1.99895 1.21071 1.62387 1.58579C1.2488 1.96086 1.03809 2.46957 1.03809 3V15C1.03809 15.5304 1.2488 16.0391 1.62387 16.4142C1.99895 16.7893 2.50765 17 3.03809 17Z"
        stroke="#666666"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  </v-btn>
</template>
<script>
export default {
  props: ["fileSelected", "driveSelect", "editImage"],
  name: "ImageComp",
  data() {
    return {
      itemsSelect: [
        {
          id: 0,
          title: "Upload from desktop",
          svgIcon:
            '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.75 12.5V16C17.75 16.4641 17.5656 16.9092 17.2374 17.2374C16.9092 17.5656 16.4641 17.75 16 17.75H3.75C3.28587 17.75 2.84075 17.5656 2.51256 17.2374C2.18437 16.9092 2 16.4641 2 16V12.5M14.25 6.375L9.875 2M9.875 2L5.5 6.375M9.875 2V12.5" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        },
      ],
    };
  },
  methods: {
    createFileInput() {
      if (this.fileInput) {
        document.body.removeChild(this.fileInput);
      }
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*,video/*"; // Accept both images and videos
      input.multiple = false; // Allow multiple files
      input.style.display = "none";
      document.body.appendChild(input);
      this.fileInput = input;
      return input;
    },
    handleFileSelect(event) {
      const files = Array.from(event.target.files); // Convert FileList to array
      const maxSize = 5 * 1024 * 1024; // 5MB limit
      const validFiles = [];
      const errors = [];
      files.forEach((file) => {
        if (
          !file.type.startsWith("image/") &&
          !file.type.startsWith("video/")
        ) {
          errors.push(`${file.name} is not a supported file type.`);
          return;
        }
        if (file.size > maxSize) {
          errors.push(`${file.name} exceeds the maximum size of 5MB.`);
          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          validFiles.push({ file, preview: e.target.result });
          if (validFiles.length + errors.length === files.length) {
           

            this.$emit("fileSelected", validFiles);
          }
        };
        reader.onerror = () => {
          errors.push(`Error reading file: ${file.name}`);
          if (validFiles.length + errors.length === files.length) {
            
            this.$emit("fileSelected", validFiles);
          }
        };
        reader.readAsDataURL(file);
      });
    },
    handleDriveSelect() {
      this.$emit("driveSelect");
    },
    handleEditImage() {
      this.$emit("editImage");
    },
    handleItemClick(item) {
      switch (item.id) {
        case 0: {
          const input = this.createFileInput();
          input.addEventListener("change", this.handleFileSelect, {
            once: true,
          });
          input.click();
          break;
        }
        case 1: {
          this.handleDriveSelect();
          break;
        }
        case 2: {
          this.handleEditImage();
          break;
        }
      }
    },
  },
  beforeUnmount() {
    // Clean up file input when component is destroyed
    if (this.fileInput && document.body.contains(this.fileInput)) {
      document.body.removeChild(this.fileInput);
    }
  },
};
</script>
