<template>
  <div class="pa-4 text-center">
    <v-dialog v-model="dialog" max-width="70vw">
      <template v-slot:default="{ isActive }">
        <div
          class="bg-white rounded-lg d-flex flex-column"
          style="height: 80vh"
        >
          <div class="pa-5">Design Your Form</div>

          <v-row no-gutters>
            <!-- Sidebar (Steps) -->
            <v-col cols="3">
              <div class="w-100 h-100 d-flex flex-column gap-3 lead-form-steps">
                <div
                  :class="['form-step', active === 'settings' ? 'active' : '']"
                  @click="active = 'settings'"
                >
                  <div class="d-flex ga-2 align-center">
                    <span class="dot"></span>
                    <span>Form Settings</span>
                  </div>
                  <svg
                    v-if="store.settingsValidations?.length > 0"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="red"
                    xmlns="http://www.w3.org/2000/svg"
                    class="sds-icon sds-icon-error sds-input-status styles-1yw183w"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.99997 2.1355C7.6576 2.1355 7.32147 2.22721 7.02654 2.40109C6.73161 2.57497 6.48864 2.82469 6.32289 3.12426L1.91828 11.1205C1.75138 11.4118 1.6639 11.7419 1.66465 12.0776C1.6654 12.4155 1.75547 12.7473 1.92573 13.0391C2.096 13.331 2.3404 13.5727 2.63418 13.7397C2.9271 13.9062 3.25873 13.9925 3.59562 13.99H12.4043C12.7412 13.9925 13.0728 13.9062 13.3658 13.7397C13.6595 13.5727 13.9039 13.331 14.0742 13.0391C14.2445 12.7473 14.3346 12.4155 14.3353 12.0776C14.336 11.7419 14.2486 11.4118 14.0817 11.1205L9.67759 3.12522L9.67706 3.12426C9.5113 2.82469 9.26833 2.57497 8.9734 2.40109C8.67847 2.22721 8.34235 2.1355 7.99997 2.1355Z"
                    ></path>
                    <path
                      d="M7.16671 11.5987C7.16539 11.4336 7.21314 11.2719 7.3039 11.134C7.39466 10.996 7.52435 10.8882 7.6765 10.8241C7.82865 10.76 7.99641 10.7425 8.1585 10.7739C8.32058 10.8053 8.46969 10.8842 8.5869 11.0004C8.70412 11.1167 8.78415 11.2652 8.81685 11.427C8.84955 11.5888 8.83344 11.7567 8.77056 11.9094C8.70769 12.0621 8.60089 12.1926 8.46371 12.2845C8.32653 12.3763 8.16515 12.4254 8.00005 12.4254C7.89093 12.4263 7.78271 12.4055 7.68165 12.3644C7.58058 12.3232 7.48866 12.2625 7.41119 12.1856C7.33372 12.1088 7.27223 12.0174 7.23027 11.9166C7.18831 11.8159 7.16671 11.7079 7.16671 11.5987Z"
                      fill="white"
                    ></path>
                    <path
                      d="M8.00001 4.79333C8.11321 4.7941 8.22508 4.81775 8.32891 4.86284C8.43274 4.90793 8.52638 4.97354 8.60422 5.05574C8.68205 5.13793 8.74247 5.23501 8.78185 5.34114C8.82122 5.44727 8.83874 5.56026 8.83334 5.67333L8.50001 10.04H7.49334L7.16668 5.67333C7.16222 5.56049 7.18038 5.44789 7.22009 5.34217C7.25981 5.23645 7.32027 5.13975 7.39792 5.05775C7.47557 4.97575 7.56884 4.91011 7.67225 4.8647C7.77565 4.81929 7.88709 4.79503 8.00001 4.79333Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <div
                  :class="['form-step', active === 'questions' ? 'active' : '']"
                  @click="active = 'questions'"
                >
                  <div class="d-flex ga-2 align-center">
                    <span class="dot"></span>
                    <span>Questions</span>
                  </div>
                </div>
                <div
                  :class="[
                    'form-step',
                    active === 'disclosures' ? 'active' : '',
                  ]"
                  @click="active = 'disclosures'"
                >
                  <div class="d-flex ga-2 align-center">
                    <span class="dot"></span>
                    <span>Disclosures</span>
                  </div>
                  <svg
                    v-if="store.disclosureValidations?.length > 0"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="red"
                    xmlns="http://www.w3.org/2000/svg"
                    class="sds-icon sds-icon-error sds-input-status styles-1yw183w"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.99997 2.1355C7.6576 2.1355 7.32147 2.22721 7.02654 2.40109C6.73161 2.57497 6.48864 2.82469 6.32289 3.12426L1.91828 11.1205C1.75138 11.4118 1.6639 11.7419 1.66465 12.0776C1.6654 12.4155 1.75547 12.7473 1.92573 13.0391C2.096 13.331 2.3404 13.5727 2.63418 13.7397C2.9271 13.9062 3.25873 13.9925 3.59562 13.99H12.4043C12.7412 13.9925 13.0728 13.9062 13.3658 13.7397C13.6595 13.5727 13.9039 13.331 14.0742 13.0391C14.2445 12.7473 14.3346 12.4155 14.3353 12.0776C14.336 11.7419 14.2486 11.4118 14.0817 11.1205L9.67759 3.12522L9.67706 3.12426C9.5113 2.82469 9.26833 2.57497 8.9734 2.40109C8.67847 2.22721 8.34235 2.1355 7.99997 2.1355Z"
                    ></path>
                    <path
                      d="M7.16671 11.5987C7.16539 11.4336 7.21314 11.2719 7.3039 11.134C7.39466 10.996 7.52435 10.8882 7.6765 10.8241C7.82865 10.76 7.99641 10.7425 8.1585 10.7739C8.32058 10.8053 8.46969 10.8842 8.5869 11.0004C8.70412 11.1167 8.78415 11.2652 8.81685 11.427C8.84955 11.5888 8.83344 11.7567 8.77056 11.9094C8.70769 12.0621 8.60089 12.1926 8.46371 12.2845C8.32653 12.3763 8.16515 12.4254 8.00005 12.4254C7.89093 12.4263 7.78271 12.4055 7.68165 12.3644C7.58058 12.3232 7.48866 12.2625 7.41119 12.1856C7.33372 12.1088 7.27223 12.0174 7.23027 11.9166C7.18831 11.8159 7.16671 11.7079 7.16671 11.5987Z"
                      fill="white"
                    ></path>
                    <path
                      d="M8.00001 4.79333C8.11321 4.7941 8.22508 4.81775 8.32891 4.86284C8.43274 4.90793 8.52638 4.97354 8.60422 5.05574C8.68205 5.13793 8.74247 5.23501 8.78185 5.34114C8.82122 5.44727 8.83874 5.56026 8.83334 5.67333L8.50001 10.04H7.49334L7.16668 5.67333C7.16222 5.56049 7.18038 5.44789 7.22009 5.34217C7.25981 5.23645 7.32027 5.13975 7.39792 5.05775C7.47557 4.97575 7.56884 4.91011 7.67225 4.8647C7.77565 4.81929 7.88709 4.79503 8.00001 4.79333Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <div
                  :class="['form-step', active === 'ending' ? 'active' : '']"
                  @click="active = 'ending'"
                >
                  <div class="d-flex ga-2 align-center">
                    <span class="dot"></span>
                    <span>Ending</span>
                  </div>
                </div>
              </div>
            </v-col>

            <!-- Main Content -->
            <v-col cols="9">
              <v-row class="mx-3 h-100 position-relative" no-gutters>
                <v-col cols="8" class="h-100">
                  <div
                    class="lead-form-contents w-100"
                    style="max-height: 60vh; overflow-y: auto"
                  >
                    <div v-show="active === 'settings'">
                      <div class="d-flex flex-column ga-2">
                        <span
                          v-for="v in store.createValidation"
                          :key="v"
                          class="d-flex align-center ga-2"
                          style="font-size: 12px"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="red"
                            xmlns="http://www.w3.org/2000/svg"
                            class="sds-icon sds-icon-error sds-input-status styles-1yw183w"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.99997 2.1355C7.6576 2.1355 7.32147 2.22721 7.02654 2.40109C6.73161 2.57497 6.48864 2.82469 6.32289 3.12426L1.91828 11.1205C1.75138 11.4118 1.6639 11.7419 1.66465 12.0776C1.6654 12.4155 1.75547 12.7473 1.92573 13.0391C2.096 13.331 2.3404 13.5727 2.63418 13.7397C2.9271 13.9062 3.25873 13.9925 3.59562 13.99H12.4043C12.7412 13.9925 13.0728 13.9062 13.3658 13.7397C13.6595 13.5727 13.9039 13.331 14.0742 13.0391C14.2445 12.7473 14.3346 12.4155 14.3353 12.0776C14.336 11.7419 14.2486 11.4118 14.0817 11.1205L9.67759 3.12522L9.67706 3.12426C9.5113 2.82469 9.26833 2.57497 8.9734 2.40109C8.67847 2.22721 8.34235 2.1355 7.99997 2.1355Z"
                            ></path>
                            <path
                              d="M7.16671 11.5987C7.16539 11.4336 7.21314 11.2719 7.3039 11.134C7.39466 10.996 7.52435 10.8882 7.6765 10.8241C7.82865 10.76 7.99641 10.7425 8.1585 10.7739C8.32058 10.8053 8.46969 10.8842 8.5869 11.0004C8.70412 11.1167 8.78415 11.2652 8.81685 11.427C8.84955 11.5888 8.83344 11.7567 8.77056 11.9094C8.70769 12.0621 8.60089 12.1926 8.46371 12.2845C8.32653 12.3763 8.16515 12.4254 8.00005 12.4254C7.89093 12.4263 7.78271 12.4055 7.68165 12.3644C7.58058 12.3232 7.48866 12.2625 7.41119 12.1856C7.33372 12.1088 7.27223 12.0174 7.23027 11.9166C7.18831 11.8159 7.16671 11.7079 7.16671 11.5987Z"
                              fill="white"
                            ></path>
                            <path
                              d="M8.00001 4.79333C8.11321 4.7941 8.22508 4.81775 8.32891 4.86284C8.43274 4.90793 8.52638 4.97354 8.60422 5.05574C8.68205 5.13793 8.74247 5.23501 8.78185 5.34114C8.82122 5.44727 8.83874 5.56026 8.83334 5.67333L8.50001 10.04H7.49334L7.16668 5.67333C7.16222 5.56049 7.18038 5.44789 7.22009 5.34217C7.25981 5.23645 7.32027 5.13975 7.39792 5.05775C7.47557 4.97575 7.56884 4.91011 7.67225 4.8647C7.77565 4.81929 7.88709 4.79503 8.00001 4.79333Z"
                              fill="white"
                            ></path>
                          </svg>
                          {{ v }}</span
                        >
                      </div>
                      <SettingsForm />
                    </div>
                    <div v-show="active === 'questions'">
                      <QuestionsForm />
                    </div>
                    <div v-show="active === 'disclosures'">
                      <DisclosuresForm />
                    </div>
                    <div v-show="active === 'ending'">
                      <EndingForm />
                    </div>
                  </div>
                </v-col>

                <v-col cols="4" class="h-100">
                  <div class="border w-100 h-100"></div>
                </v-col>
                <div
                  class="position-absolute bottom-0 w-100 d-flex align-center justify-space-between px-8 py-3 bg-white"
                >
                  <button
                    v-if="active !== 'settings'"
                    class="upload-span"
                    @click="getBack"
                  >
                    Back
                  </button>
                  <div v-else></div>
                  <button class="upload-span" @click="getNext">
                    {{ active !== "ending" ? "Next" : "Create" }}
                  </button>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import SettingsForm from "./SettingsForm.vue";
import QuestionsForm from "./QuestionsForm.vue";
import DisclosuresForm from "./DisclosuresForm.vue";
import EndingForm from "./EndingForm.vue";
import { useLeadFormStore } from "@/store/snapchat/LeadForm";

export default {
  components: {
    SettingsForm,
    QuestionsForm,
    DisclosuresForm,
    EndingForm,
  },
  props: {
    local_dialog: Boolean,
  },
  data() {
    return {
      dialog: false,
      active: "settings",
      store: useLeadFormStore(),
    };
  },
  watch: {
    local_dialog(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("update:local_dialog", val);
    },
  },
  methods: {
    getBack() {
      switch (this.active) {
        case "settings":
          break;
        case "questions":
          this.active = "settings";
          break;
        case "disclosures":
          this.active = "questions";
          break;
        case "ending":
          this.active = "disclosures";
          break;
      }
    },
    async getNext() {
      switch (this.active) {
        case "settings":
          this.store.validateSettingStep();
          this.active = "questions";
          break;
        case "questions":
          this.active = "disclosures";
          break;
        case "disclosures":
          this.store.validateDisclosureStep();
          this.active = "ending";
          break;
        case "ending":
          this.store.validateSettingStep();
          this.store.validateDisclosureStep();
          if (
            this.store.settingsValidations?.length === 0 &&
            this.store.disclosureValidations?.length === 0
          ) {
            const data = await this.store.createForm();
            if (data) {
              this.store.resetForm();
              this.dialog = false;
            } else {
              this.active = "settings";
            }
          }
          break;
      }
    },
  },
};
</script>
