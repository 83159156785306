<template>
  <div class="d-flex flex-column ga-3 snapchat">
    <div class="filed mt-3">
      <span class="title">Form Name</span>
      <div class="input-container">
        <input
          type="text"
          v-model="store.form_name"
          placeholder="Form Name"
          class="required_input"
          :style="[
            store.settingsValidations.includes('form_name')
              ? 'border-bottom: solid 2px red !important'
              : '',
            ,
          ]"
        />
      </div>
      <div
        v-if="store.settingsValidations.includes('form_name')"
        class="warning mt-2"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="red"
          xmlns="http://www.w3.org/2000/svg"
          class="sds-icon sds-icon-error sds-input-status styles-1yw183w"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99997 2.1355C7.6576 2.1355 7.32147 2.22721 7.02654 2.40109C6.73161 2.57497 6.48864 2.82469 6.32289 3.12426L1.91828 11.1205C1.75138 11.4118 1.6639 11.7419 1.66465 12.0776C1.6654 12.4155 1.75547 12.7473 1.92573 13.0391C2.096 13.331 2.3404 13.5727 2.63418 13.7397C2.9271 13.9062 3.25873 13.9925 3.59562 13.99H12.4043C12.7412 13.9925 13.0728 13.9062 13.3658 13.7397C13.6595 13.5727 13.9039 13.331 14.0742 13.0391C14.2445 12.7473 14.3346 12.4155 14.3353 12.0776C14.336 11.7419 14.2486 11.4118 14.0817 11.1205L9.67759 3.12522L9.67706 3.12426C9.5113 2.82469 9.26833 2.57497 8.9734 2.40109C8.67847 2.22721 8.34235 2.1355 7.99997 2.1355Z"
          ></path>
          <path
            d="M7.16671 11.5987C7.16539 11.4336 7.21314 11.2719 7.3039 11.134C7.39466 10.996 7.52435 10.8882 7.6765 10.8241C7.82865 10.76 7.99641 10.7425 8.1585 10.7739C8.32058 10.8053 8.46969 10.8842 8.5869 11.0004C8.70412 11.1167 8.78415 11.2652 8.81685 11.427C8.84955 11.5888 8.83344 11.7567 8.77056 11.9094C8.70769 12.0621 8.60089 12.1926 8.46371 12.2845C8.32653 12.3763 8.16515 12.4254 8.00005 12.4254C7.89093 12.4263 7.78271 12.4055 7.68165 12.3644C7.58058 12.3232 7.48866 12.2625 7.41119 12.1856C7.33372 12.1088 7.27223 12.0174 7.23027 11.9166C7.18831 11.8159 7.16671 11.7079 7.16671 11.5987Z"
            fill="white"
          ></path>
          <path
            d="M8.00001 4.79333C8.11321 4.7941 8.22508 4.81775 8.32891 4.86284C8.43274 4.90793 8.52638 4.97354 8.60422 5.05574C8.68205 5.13793 8.74247 5.23501 8.78185 5.34114C8.82122 5.44727 8.83874 5.56026 8.83334 5.67333L8.50001 10.04H7.49334L7.16668 5.67333C7.16222 5.56049 7.18038 5.44789 7.22009 5.34217C7.25981 5.23645 7.32027 5.13975 7.39792 5.05775C7.47557 4.97575 7.56884 4.91011 7.67225 4.8647C7.77565 4.81929 7.88709 4.79503 8.00001 4.79333Z"
            fill="white"
          ></path>
        </svg>
        <p class="small-text muted-text text-red-500">field is required.</p>
      </div>
    </div>
    <div class="filed mt-3">
      <span class="title">Lead Form Description</span>
      <div class="input-container">
        <textarea
          class="w-100 required_input"
          :style="[
            store.settingsValidations.includes('form_description')
              ? 'border-bottom: solid 2px red !important'
              : '',
            ,
          ]"
          v-model="store.form_description"
          placeholder="Lead Form Description"
          rows="5"
        ></textarea>
      </div>
      <div
        v-if="store.settingsValidations.includes('form_description')"
        class="warning mt-2"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="red"
          xmlns="http://www.w3.org/2000/svg"
          class="sds-icon sds-icon-error sds-input-status styles-1yw183w"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99997 2.1355C7.6576 2.1355 7.32147 2.22721 7.02654 2.40109C6.73161 2.57497 6.48864 2.82469 6.32289 3.12426L1.91828 11.1205C1.75138 11.4118 1.6639 11.7419 1.66465 12.0776C1.6654 12.4155 1.75547 12.7473 1.92573 13.0391C2.096 13.331 2.3404 13.5727 2.63418 13.7397C2.9271 13.9062 3.25873 13.9925 3.59562 13.99H12.4043C12.7412 13.9925 13.0728 13.9062 13.3658 13.7397C13.6595 13.5727 13.9039 13.331 14.0742 13.0391C14.2445 12.7473 14.3346 12.4155 14.3353 12.0776C14.336 11.7419 14.2486 11.4118 14.0817 11.1205L9.67759 3.12522L9.67706 3.12426C9.5113 2.82469 9.26833 2.57497 8.9734 2.40109C8.67847 2.22721 8.34235 2.1355 7.99997 2.1355Z"
          ></path>
          <path
            d="M7.16671 11.5987C7.16539 11.4336 7.21314 11.2719 7.3039 11.134C7.39466 10.996 7.52435 10.8882 7.6765 10.8241C7.82865 10.76 7.99641 10.7425 8.1585 10.7739C8.32058 10.8053 8.46969 10.8842 8.5869 11.0004C8.70412 11.1167 8.78415 11.2652 8.81685 11.427C8.84955 11.5888 8.83344 11.7567 8.77056 11.9094C8.70769 12.0621 8.60089 12.1926 8.46371 12.2845C8.32653 12.3763 8.16515 12.4254 8.00005 12.4254C7.89093 12.4263 7.78271 12.4055 7.68165 12.3644C7.58058 12.3232 7.48866 12.2625 7.41119 12.1856C7.33372 12.1088 7.27223 12.0174 7.23027 11.9166C7.18831 11.8159 7.16671 11.7079 7.16671 11.5987Z"
            fill="white"
          ></path>
          <path
            d="M8.00001 4.79333C8.11321 4.7941 8.22508 4.81775 8.32891 4.86284C8.43274 4.90793 8.52638 4.97354 8.60422 5.05574C8.68205 5.13793 8.74247 5.23501 8.78185 5.34114C8.82122 5.44727 8.83874 5.56026 8.83334 5.67333L8.50001 10.04H7.49334L7.16668 5.67333C7.16222 5.56049 7.18038 5.44789 7.22009 5.34217C7.25981 5.23645 7.32027 5.13975 7.39792 5.05775C7.47557 4.97575 7.56884 4.91011 7.67225 4.8647C7.77565 4.81929 7.88709 4.79503 8.00001 4.79333Z"
            fill="white"
          ></path>
        </svg>
        <p class="small-text muted-text text-red-500">field is required.</p>
      </div>
    </div>
    <div class="filed">
      <span class="title">Header Image</span>
    </div>
    <UploadMedia
      :aspectRatio="75 / 23"
      :minAspectRatio="75 / 23"
      :maxAspectRatio="75 / 23"
      :cropWidth="750"
      :cropHeight="230"
      :minCropWidth="750"
      :minCropHeight="230"
      :size="5"
      @uploaded:id="(id) => (store.header_image = id)"
      @uploaded:delete="store.header_image = ''"
      :old_id="store.header_image"
    />
  </div>
</template>
<script>
import UploadMedia from "../uploadMedia.vue";
import { useLeadFormStore } from "@/store/snapchat/LeadForm";
export default {
  name: "SettingsForm",
  components: {
    UploadMedia,
  },
  data() {
    return {
      store: useLeadFormStore(),
    };
  },
  watch: {
    "store.form_name"(value) {
      this.store.validateSettingStep();
    },
    "store.form_description"(value) {
      this.store.validateSettingStep();
    },
  },
};
</script>
<style scoped>
input {
  border: 2px solid transparent;
  border-radius: 8px !important;
  background: #f9f9fa;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0 0 0 0 transparent;
  box-sizing: border-box;
  font-family: inherit;
  transition: box-shadow 0.15s ease-out 0.15s, border-color 0.15s,
    background 0.15s ease-out 0.15s, color 0.15s ease-out;
  width: 100%;
  border: transparent 2px solid;
  height: 40px;
}
</style>
