import IntegrationIndex from "@/views/integrations/IntegrationsIndex.vue";
import SnapchatRedirect from "@/views/integrations/redirectUrl/SnapchatRedirectView.vue";
import TiktokRedirect from "@/views/integrations/redirectUrl/TiktokRedirectView.vue";
import FacebookRedirect from "@/views/integrations/redirectUrl/FacebookRedirectView.vue";
import GoogleRedirect from "@/views/integrations/redirectUrl/GoogleRedirectView.vue";
import XtokenRedirect from "@/views/integrations/redirectUrl/XtokenRedirectView.vue";
import InstagramRedirect from "@/views/integrations/redirectUrl/InstagramRedirectView.vue";
import DriveRedirectView from "@/views/integrations/redirectUrl/DriveRedirectView.vue";
import FacebookInsights from "@/views/integrations/insights/FacebookInsights.vue";
import InstagramInsights from "@/views/integrations/insights/InstagramInsights.vue";
import TikTokInsights from "@/views/integrations/insights/TikTokInsights.vue";
import YouTubeInsights from "@/views/integrations/insights/YouTubeInsights.vue";

const integrationsRoutes = [
  {
    path: "/integrations",
    name: "integrations",
    component: IntegrationIndex,
    meta: {
      title: "Integrations",
      description: "",
      auth: "auth",
      
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
  {
    path: "/snapchat/redirect",
    name: "snapchatRedirect",
    component: SnapchatRedirect,
    meta: {
      title: "snapchatRedirect",
      description: "",
      auth: "auth",
      
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
  {
    path: "/tiktok/redirect",
    name: "TiktokRedirect",
    component: TiktokRedirect,
    meta: {
      title: "TiktokRedirect",
      description: "",
      auth: "auth",
      
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
  {
    path: "/facebook/redirect",
    name: "FacebookRedirect",
    component: FacebookRedirect,
    meta: {
      title: "FacebookRedirect",
      description: "",
      auth: "auth",
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
  {
    path: "/google/redirect",
    name: "GoogleRedirect",
    component: GoogleRedirect,
    meta: {
      title: "GoogleRedirect",
      description: "",
      auth: "auth",
      
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
  {
    path: "/x/redirect",
    name: "XRedirect",
    component: XtokenRedirect,
    meta: {
      title: "XRedirect",
      description: "",
      auth: "auth",
      
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
  {
    path: "/drive/redirect",
    name: "GoogleDriveRedirect",
    component: DriveRedirectView,
    meta: {
      title: "GoogleDriveRedirect",
      description: "",
      auth: "auth",
      
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
  {
    path: "/instagram/redirect",
    name: "InstagramRedirect",
    component: InstagramRedirect,
    meta: {
      title: "InstagramRedirect",
      description: "",
      auth: "auth",
      
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
  {
    path: "/insights/facebook",
    name: "FacebookInsights",
    component: FacebookInsights,
    meta: {
      title: "FacebookInsights",
      description: "Facebook Insights",
      auth: "auth",
      
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
  {
    path: "/insights/instagram",
    name: "InstagramInsights",
    component: InstagramInsights,
    meta: {
      title: "InstagramInsights",
      description: "Instagram Insights",
      auth: "auth",
      
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
  {
    path: "/insights/tiktok",
    name: "TikTokInsights",
    component: TikTokInsights,
    meta: {
      title: "TikTokInsights",
      description: "TikTok Insights",
      auth: "auth",
      
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
  {
    path: "/insights/youtube",
    name: "YouTubeInsights",
    component: YouTubeInsights,
    meta: {
      title: "YouTubeInsights",
      description: "YouTube Insights",
      auth: "auth",
      
      permissions: [
        "integration.create",
        "integration.read",
        "integration.update",
        "integration.delete",
      ],
    },
  },
];
export default integrationsRoutes;
