<template>
  <div class="">getting token for this : {{ code }}</div>
</template>

<script>
import apiClient from "@/core/buildRequest";

export default {
  data() {
    return {
      code: "",
    };
  },
  mounted() {
    this.getSnapChatToken();
  },
  methods: {
    getSnapChatToken() {
      const code = this.$route.query.code;
      this.code = this.$route.query.code;

      if (code) {
        const body = {
          code: code,
          userId: JSON.parse(localStorage.getItem("user")).data.id,
        };
        apiClient
          .post("/auth/snapchat/callback", body)
          .then((response) => {
            const access_token = response.data.data.credentials.access_token;
            const access_token_expire_at =
              response.data.data.credentials.expire_date;
            localStorage.setItem("snapchat_token", access_token);
            localStorage.setItem("snapTokenExpireAt", access_token_expire_at);
            this.$router.push({ name: "CreateNewCamp" });
          })
          .catch((error) => {
            console.log(error);
            
          });
      } else {
        console.error("OAuth code not found in query parameters.");
      }
    },
  },
};
</script>
