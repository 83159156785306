<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="store.CreateAudience"
    width="819"
    class="create-audience"
  >
    <v-card max-width="819">
      <v-card-title class="pa-4">
        <p class="">
          Create audience
          <span
            style="cursor: pointer"
            @click="store.CreateAudience = false"
            v-html="iconSvg"
          ></span>
        </p>
      </v-card-title>

      <v-card-text class="py-2 px-5">
        <h1 class="mb-4">
          Select the location, age, gender and interests of Accounts Centre
          accounts you want to reach with your ad.
        </h1>
        <v-container>
          <v-row class="mb-3">
            <v-col cols="12" class="mb-5 pa-0">
              <v-text-field
                v-model="store.audienceName"
                label="Audience name"
                required
                variant="outlined"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="10" md="10" sm="10" class="pa-0 advantage-audience">
              <p class="mb-2 title">Advantage+ audience</p>
              <p class="sub-title">
                Let our ad technology find your audience and adjust over time to
                reach more people who are likely to respond to your ad. Learn
                more
              </p>
            </v-col>
            <v-col cols="2" md="2" sm="2" class="pa-0 d-flex justify-end">
              <v-switch
                v-model="store.advantagrAudience"
                color="info"
                hide-details
                inset
              ></v-switch>
            </v-col>
          </v-row>
          <v-row class="gender-audience">
            <v-col class="mb-3 pa-0" cols="12">
              <p class="title">Gender</p>
            </v-col>
            <v-col
              v-for="(item, i) in store.genderType"
              :key="i"
              class="py-0 px-1"
              cols="4"
            >
              <div class="d-flex align-center mb-3 ga-2 goals-select pa-2">
                <div
                  class="d-flex align-center w-100 justify-space-between goal-item"
                >
                  <label :for="`${item.id}_gender`" class="d-block">
                    <p
                      class="mb-1 title"
                      style="font-size: 18px; font-weight: 600; color: #0d0d0d"
                    >
                      {{ item.title }}
                    </p>
                  </label>
                  <div class="my-form">
                    <div>
                      <input
                        :checked="item.checked"
                        :id="`${item.id}_gender`"
                        type="radio"
                        name="gooalType"
                        :value="item.id"
                        @change="store.setSelectedGender(item.value)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="my-3">
            <v-col cols="12" class="pa-0">
              <v-range-slider
                v-model="range"
                :max="65"
                :min="18"
                :step="1"
                class="align-center"
                hide-details
                color="primary"
              >
                <template v-slot:prepend>
                  <v-text-field
                    v-model="range[0]"
                    density="compact"
                    style="width: 70px"
                    type="number"
                    variant="outlined"
                    hide-details
                    single-line
                  ></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                    v-model="range[1]"
                    density="compact"
                    style="width: 70px"
                    type="number"
                    variant="outlined"
                    hide-details
                    single-line
                  ></v-text-field>
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div id="map" style="height: 500px; width: 100%"></div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          bg-color="primary"
          text="Save"
          @click="store.CreateAudience = false"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { boostPost } from "@/store/publishPost/boostPost.js";
export default {
  data() {
    return {
      store: boostPost(),
      iconSvg: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="rgba(102, 102, 102, 1)"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>`,
      range: [18, 65],
      map: null,
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      const mapDiv = document.getElementById("map");
      if (mapDiv) {
        this.map = new google.maps.Map(mapDiv, {
          center: { lat: -34.397, lng: 150.644 },
          zoom: 8,
        });
      } else {
        console.error("Map div not found");
      }
    },
  },
  watch: {
    range(newValue) {
      localStorage.setItem("min_range_age", newValue[0]);
      localStorage.setItem("max_range_age", newValue[1]);
    },
  },
};
</script>

<style scoped>
.v-card-title p {
  font-size: 24px;
  font-weight: 700;
  color: rgba(13, 13, 13, 1);
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid rgba(236, 236, 236, 1);
  text-align: center;
  position: relative;
  padding: 0 0 15px 0;
}
.v-card-title p span {
  position: absolute;
  right: 0;
  bottom: 8px;
}
.v-card-text h1 {
  font-size: 18px;
  font-weight: 600;
  color: rgba(34, 34, 34, 1);
}
.advantage-audience p.title {
  color: rgba(13, 13, 13, 1);
  font-size: 18px;
  font-weight: 600;
}
.advantage-audience p.sub-title {
  color: rgba(77, 77, 77, 1);
  font-size: 14px;
  font-weight: 400;
}
</style>
