<template>
  <div class="d-flex align-end ga-2">
    <!-- edit status  -->
    <div class="book-container d-flex align-center ga-2">
      <span
        v-if="selected_reached_lead_type"
        :class="[SelectedReachedLeadTypeClass, 'cursor-pointer']"
        @click="$emit('reached_status_dialog', true)"
      >
        {{ selected_reached_lead_type }}
        <span>
          <svg
            @click="clear"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 3L3 9M3 3L9 9"
              stroke="#027A48"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </span>
    </div>
    <div
      v-if="
        selected_reached_lead_type === 'Booked' ||
        selected_reached_lead_type === 'Cross/Up Selling'
      "
      :class="['d-flex', 'align-center', 'ga-2']"
    >
      <span class="vuetify-date-input-icon cursor-pointer">
        <v-date-input
          hide-details
          variant="plain"
          prepend-icon=""
          prepend-inner-icon="$calendar"
          density="compact"
          clearable
          format="YYYY-MM-DD"
          v-model="selected_consultation_date"
        >
          <template v-slot:text-field="{ value }">
            <v-text-field
              :model-value="formatDateFromInput(value)"
              readonly
              variant="plain"
            ></v-text-field>
          </template>
        </v-date-input>
      </span>
      <span
        :class="[
          selected_consultation_date === '' ? 'alert-red' : 'alert-green',
          'date cursor-pointer',
        ]"
      >
        {{ formatDateFromInput(selected_consultation_date) || "----/--/--" }}
      </span>
      <!-- <input type="text" ref="datepicker" style="display: none" /> -->
      <span
        class="cursor-pointer"
        @click="$emit('book_information_dialog', true)"
      >
        <svg
          data-v-faa1dddc=""
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            data-v-faa1dddc=""
            width="24"
            height="24"
            rx="8"
            fill="#237276"
          ></rect>
          <path
            data-v-faa1dddc=""
            d="M12 16H16.5M14.25 7.74999C14.4489 7.55108 14.7187 7.43933 15 7.43933C15.1393 7.43933 15.2772 7.46677 15.4059 7.52007C15.5346 7.57337 15.6515 7.6515 15.75 7.74999C15.8485 7.84848 15.9266 7.96541 15.9799 8.09409C16.0332 8.22278 16.0607 8.3607 16.0607 8.49999C16.0607 8.63928 16.0332 8.7772 15.9799 8.90589C15.9266 9.03457 15.8485 9.1515 15.75 9.24999L9.5 15.5L7.5 16L8 14L14.25 7.74999Z"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </span>
    </div>
    <UnReached
      v-if="
        selected_reached_lead_type === 'Call Back' ||
        selected_reached_lead_type === 'Outside' ||
        selected_reached_lead_type === 'Transfer / Branch'
      "
      :item="item"
      :selected_item="selected_reached_lead_type"
    />
  </div>
</template>

<script>
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import UnReached from "@/components/crm/leads/options/UnReached.vue";
import { VDateInput } from "vuetify/labs/VDateInput";

export default {
  props: ["item", "selected_reached_lead_type", "consultation_date"],
  name: "BookedType",
  components: { VDateInput, UnReached },

  data() {
    return {
      flatpickrInstance: null,
      selected_consultation_date: null,
    };
  },
  mounted() {
    // if (this.consultation_date != "") {
    //   this.initDatePicker();
    // }
  },
  watch: {
    consultation_date: {
      handler(newVal) {
        if (!newVal) {
          this.selected_consultation_date = null;
          return;
        }
        this.selected_consultation_date = this.FormattedDateToInput(newVal);
      },
      immediate: true,
    },
    selected_consultation_date(newVal) {
      this.$emit("update_consultation_date", this.formatDateFromInput(newVal));
    },
  },

  methods: {
    FormattedDateToInput(dateString) {
      if (!dateString) return null;
      const [datePart, timePart] = dateString.split(" ");
      const [year, month, day] = datePart.split("-").map(Number);
      const [hours, minutes, seconds] = timePart
        ? timePart.split(":").map(Number)
        : ["00", "00", "00"].map(Number);

      return new Date(year, month - 1, day, hours, minutes, seconds);
    },
    formatDateFromInput(date) {
      if (!date) return "";
      const d = new Date(date);
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(d.getDate()).padStart(2, "0")}`;
    },
    openInfoDialog() {
      this.$emit("book_information_dialog", true);
    },
    // openCalendar() {
    //   this.initDatePicker();
    //   this.flatpickrInstance.open();
    // },
    clear() {
      this.$emit("clear_selected_reached_lead_type", "");
    },
    clearDate() {
      if (this.flatpickrInstance) {
        this.flatpickrInstance.clear();
      }
      this.$emit("update:consultation_date", null);
    },
    // initDatePicker() {
    //   const datepicker = this.$refs.datepicker;
    //   const calendar = document.getElementById("calendar");

    //   if (!datepicker) {
    //     console.error("Datepicker element is not found.");
    //     return;
    //   }

    //   if (!calendar) {
    //     console.error("Calendar element is not found.");
    //     return;
    //   }

    //   this.flatpickrInstance = flatPickr(datepicker, {
    //     defaultDate: this.consultation_date,
    //     dateFormat: "Y-m-d",
    //     appendTo: calendar,
    //     onChange: (selectedDates, dateStr) => {
    //       this.$emit("update_consultation_date", dateStr);
    //     },
    //   });
    // },
  },
  computed: {
    SelectedReachedLeadTypeClass() {
      let className = "";
      if (
        this.selected_reached_lead_type === "Booked" ||
        this.selected_reached_lead_type === "Cross/Up Selling" ||
        this.selected_reached_lead_type === "Walk In"
      ) {
        className = "selected-green";
      } else if (
        this.selected_reached_lead_type === "Call Back" ||
        this.selected_reached_lead_type === "Outside" ||
        this.selected_reached_lead_type === "Transfer / Branch"
      ) {
        className = "selected-gray";
      } else if (
        this.selected_reached_lead_type === "N/I Price" ||
        this.selected_reached_lead_type === "N/I Service" ||
        this.selected_reached_lead_type === "Inquiry"
      ) {
        className = "selected-orange";
      } else if (
        this.selected_reached_lead_type === "Wrong Reservation" ||
        this.selected_reached_lead_type === "Language Barrier" ||
        this.selected_reached_lead_type === "Wrong Customer" ||
        this.selected_reached_lead_type === "Duplicated" ||
        this.selected_reached_lead_type === "N/A Number" ||
        this.selected_reached_lead_type === "Closed"
      ) {
        className = "selected-red";
      }
      return className;
    },
  },
};
</script>
<style scoped>
.booked {
  height: 32px;
  padding: 2px 12px;
  border-radius: 16px;
  white-space: nowrap;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #ecfdf3;
  color: #027a48;
  display: flex;
  align-items: center;
  gap: 10px;
}
.date {
  height: 32px;
  padding: 2px 12px;
  border-radius: 16px;
  white-space: nowrap;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #ecfdf3;
  color: #027a48;
  display: flex;
  align-items: center;
  gap: 10px;
}

.calendar-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.alert-red,
.alert-green {
  position: relative;
}
.alert-red::before,
.alert-green::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate(50%, -50%);
  border: solid 2px white;
}
.alert-red::before {
  background: #d91313;
}
.alert-green::before {
  background: #027a48;
}
</style>
