<template>
  <div class="card-pattern budget-and-schedule-card campaign-budget-card">
    <div class="card-header mb-2 d-flex align-center">
      <label for="" class="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Budget & schedule</span>
      </label>
    </div>
    <div class="card-body px-1">
      <div class="budget">
        <p class="roboto-bold mb-1">Budget</p>
        <v-container fluid class="pa-0">
          <v-row no-gutters>
            <v-col cols="4">
              <v-select
                v-model="select"
                :items="budget_items"
                density="compact"
                variant="outlined"
                return-object
                item-value="id"
                item-title="title"
                hide-details
              >
                <template v-slot:selection="{ item }">
                  {{ item?.title || "" }}
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item @click="selectItem(item)">
                    <div class="my-form">
                      <div>
                        <input
                          :checked="select && select.id === item.raw.id"
                          :id="item.id"
                          type="radio"
                          name="option"
                          :value="item"
                        />
                        <label :for="item.id">{{ item.title }}</label>
                      </div>
                    </div>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col v-if="select.value === 'DAILY_BUDGET'" cols="8" class="pl-1">
              <div class="input-budget">
                <span class="dollar-sign">$</span>
                <input
                  type="number"
                  v-model="budget_amount"
                  @input="setValueOfBudget(budget_amount)"
                  class="no-controls"
                  name=""
                  id=""
                />
                <span class="currency-type">USD</span>
              </div>
            </v-col>
            <v-col v-else cols="8" class="pl-1">
              <div class="input-budget">
                <span class="dollar-sign">$</span>
                <input
                  type="number"
                  v-model="budget_amount_max"
                  @input="setValueOfMaxBudget(budget_amount_max)"
                  class="no-controls"
                  name=""
                  id=""
                />
                <span class="currency-type">USD</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="d-flex align-center">
        
        </div>
      </div>
      <div class="schedule mt-5">
        <p class="roboto-bold mb-4">Schedule</p>
        <label class="d-block mb-1 roboto-bold" for="">Start date</label>
        <v-container class="pa-0 ma-0 start-date">
          <v-row no-gutters class="pa-0 ma-0">
            <v-col cols="6" class="ma-0 d-flex ga-1 align-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#000"
              >
                <path
                  d="M216-96q-29.7 0-50.85-21.5Q144-139 144-168v-528q0-29 21.15-50.5T216-768h72v-96h72v96h240v-96h72v96h72q29.7 0 50.85 21.5Q816-725 816-696v528q0 29-21.15 50.5T744-96H216Zm0-72h528v-360H216v360Zm0-432h528v-96H216v96Zm0 0v-96 96Zm264.21 216q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm-156 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm312 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm-156 144q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm-156 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm312 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Z"
                />
              </svg>
              <flat-pickr
                @on-change="setStartDate"
                v-model="startDate"
                :config="dateConfig"
                class="form-control"
                placeholder="Select date"
              />
            </v-col>
            <v-col cols="6" class="d-flex align-center ga-1 ma-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#000"
              >
                <path
                  d="m614-310 51-51-149-149v-210h-72v240l170 170ZM480-96q-79.38 0-149.19-30T208.5-208.5Q156-261 126-330.96t-30-149.5Q96-560 126-630q30-70 82.5-122t122.46-82q69.96-30 149.5-30t149.55 30.24q70 30.24 121.79 82.08 51.78 51.84 81.99 121.92Q864-559.68 864-480q0 79.38-30 149.19T752-208.5Q700-156 629.87-126T480-96Zm0-384Zm.48 312q129.47 0 220.5-91.5Q792-351 792-480.48q0-129.47-91.02-220.5Q609.95-792 480.48-792 351-792 259.5-700.98 168-609.95 168-480.48 168-351 259.5-259.5T480.48-168Z"
                />
              </svg>
              <flat-pickr
                @on-change="setStartTime"
                v-model="startTime"
                :config="timeConfig"
                class="form-control"
                placeholder="Select date"
              />
            </v-col>
          </v-row>
        </v-container>

        <label
          class="d-block mb-1 mt-3 roboto-bold"
          style="font-size: 14px"
          for=""
          >End date</label
        >
        <div class="increase-budget-periods">
          <label class="checkbox">
            <input
              type="checkbox"
              @click="toggleEndDate"
              v-model="containerEndDate"
            />
            <span class="checkmark"></span>
            <span class="text">Set end date</span>
          </label>
        </div>

        <v-container
          class="pa-0 ma-0 start-date end-date"
          v-if="containerEndDate"
        >
          <v-row no-gutters class="pa-0 ma-0">
            <v-vol cols="4" class="mr-5">
              <v-select
                v-model="selectedAgeStartRange_2"
                :items="dayesItems"
                density="compact"
                variant="outlined"
                return-object
                item-value="value"
                item-title="title"
                hide-details
              >
                <template v-slot:selection="{ item }">
                  {{ item?.title || "" }}
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item @click="setDayesOfEnd(item)">
                    <div class="my-form">
                      <div class="ga-3">
                        <input
                          :checked="
                            selectedAgeStartRange_2 &&
                            selectedAgeStartRange_2.id === item.raw.id
                          "
                          :id="item.raw.id"
                          type="radio"
                          name="option"
                          :value="item"
                        />
                        <div class="d-flex flex-column ga-1">
                          <label
                            :for="item.raw.id"
                            class="d-block"
                            style="font-size: 14px"
                            >{{ item.title }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </v-list-item>
                </template>
              </v-select>
            </v-vol>
            <v-col cols="4" class="ma-0 d-flex ga-1 align-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#000"
              >
                <path
                  d="M216-96q-29.7 0-50.85-21.5Q144-139 144-168v-528q0-29 21.15-50.5T216-768h72v-96h72v96h240v-96h72v96h72q29.7 0 50.85 21.5Q816-725 816-696v528q0 29-21.15 50.5T744-96H216Zm0-72h528v-360H216v360Zm0-432h528v-96H216v96Zm0 0v-96 96Zm264.21 216q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm-156 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm312 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm-156 144q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm-156 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Zm312 0q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.29-25.71t25.5-10.5q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.29 25.71t-25.5 10.5Z"
                />
              </svg>
              <flat-pickr
                @on-change="setEndDate"
                v-model="endDate"
                :config="dateConfig"
                class="form-control"
                placeholder="Select date"
              />
            </v-col>
            <v-col cols="4" class="d-flex align-center ga-1 ma-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#000"
              >
                <path
                  d="m614-310 51-51-149-149v-210h-72v240l170 170ZM480-96q-79.38 0-149.19-30T208.5-208.5Q156-261 126-330.96t-30-149.5Q96-560 126-630q30-70 82.5-122t122.46-82q69.96-30 149.5-30t149.55 30.24q70 30.24 121.79 82.08 51.78 51.84 81.99 121.92Q864-559.68 864-480q0 79.38-30 149.19T752-208.5Q700-156 629.87-126T480-96Zm0-384Zm.48 312q129.47 0 220.5-91.5Q792-351 792-480.48q0-129.47-91.02-220.5Q609.95-792 480.48-792 351-792 259.5-700.98 168-609.95 168-480.48 168-351 259.5-259.5T480.48-168Z"
                />
              </svg>
              <flat-pickr
                @on-change="setEndTime"
                v-model="endTime"
                :config="timeConfig"
                class="form-control"
                placeholder="Select date"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-divider class="my-4"></v-divider>
      <button class="show-more" @click="toggleShowMoreOptions">
        <div class="d-flex align-center" v-if="!chargedOptions">
          <span>Show more options</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#0a7abf"
          >
            <path d="M480-384 288-576h384L480-384Z" />
          </svg>
        </div>
        <div class="d-flex align-center" v-else>
          <span>Hide options</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#0a7abf"
          >
            <path d="m288-384 192-192 192 192H288Z" />
          </svg>
        </div>
      </button>
      <section v-if="chargedOptions">
        <BudgetScheduling v-if="!spending_limit" />
        <div v-else class="ad-set-spending-limits">
          <label for="" class="roboto-bold">Ad set spending limits</label>
          <div class="increase-budget-periods">
            <label class="checkbox mb-1">
              <input
                type="checkbox"
                click=""
                @click="toggleminimumMaximum"
                v-model="daily_maximum_minimum"
              />
              <span class="checkmark"></span>
              <span class="text">Ad set spending limits</span>
            </label>
          </div>
          <div v-if="limitedStatus">
            <v-container class="pa-0 mx-0 my-1 ">
              <v-row no-gutters>
                <v-col cols="6">
                  <label for="" class="roboto-regular d-block"
                    >Daily minimum
                  </label>
                  <div class="daily-minimum">
                    <input v-model="daily_minimum" @input="setDailyMinimum()" type="number" class="no-controls" name="" id="" />
                    <span>USD</span>
                  </div>
                </v-col>
                <v-col cols="6">
                  <label for="" class="roboto-regular d-block"
                    >Daily Maximum
                  </label>
                  <div class="daily-minimum">
                    <input type="number" v-model="daily_maximum" @input="setDailyMaximum()" class="no-controls" name="" id="" />
                    <span>USD</span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BudgetScheduling from "../first-page-component/BudgetScheduling.vue";
export default {
  name: "BudgetAndSchedule",
  components: {
    flatPickr,
    BudgetScheduling,
  },

  data() {
    return {
      daily_maximum: 0,
      daily_minimum: 0,
      select: {
        title: "Daily budget",
        id: 1,
        value: "DAILY_BUDGET",
      },
      budget_items: [
        {
          title: "Daily budget",
          id: 1,
          value: "DAILY_BUDGET",
        },
        {
          title: "lifetime budget",
          id: 2,
          value: "LIFETIME_BUDGET",
        },
      ],
      budget_amount_max: 0,

      startDate: new Date().toISOString().split("T")[0],
      endDate: "",
      startTime: "",
      endTime: "",
      budget_amount: 40.0,
      dateConfig: {
        minDate: "today",
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        defaultDate: "00:00",
      },
      containerEndDate: false,
      dayesItems: [
        {
          id: 0,
          title: "14 days",
          value: 14,
        },
        {
          id: 1,
          title: "30 days",
          value: 30,
        },
        {
          id: 2,
          title: "60 days",
          value: 60,
        },
        {
          id: 3,
          title: "Custome",
          value: 0,
        },
      ],
      selectedDuration: {
        id: 0,
        title: "14 days",
        value: 14,
      },
      chargedOptions: false,
      selectedAgeStartRange_2: "",
      spending_limit:
        localStorage.getItem("campaign_budget_status_meta") === "true"
          ? true
          : false,
      limitedStatus: false,
    };
  },
  mounted() {
    localStorage.setItem("meta_start_date", this.startDate);
   
  },
  methods: {
    selectItem(item) {
      this.select = item.raw;
      localStorage.setItem("meta_budget_type", item.raw.value);
    },
    setValueOfBudget(value) {
      localStorage.setItem("value_meta_Budget", value);
      localStorage.setItem("value_meta_Budget_max", null);
    },
    setValueOfMaxBudget(value) {
      localStorage.setItem("value_meta_Budget", null);
      localStorage.setItem("value_meta_Budget_max", value);
    },
    setDayesOfEnd(item) {
      this.selectedDuration = item.raw;
      localStorage.setItem("meta_dayes_end", this.selectedDuration);
    },
    setStartDate() {
      localStorage.setItem("meta_start_date", this.startDate);
    },
    setEndDate() {
      localStorage.setItem("meta_end_date", this.endDate);
    },

    setStartTime() {
      localStorage.setItem("meta_start_time", this.startTime);
    },
    setEndTime() {
      localStorage.setItem("meta_end_time", this.endTime);
    },
    toggleEndDate() {
      this.containerEndDate = !this.containerEndDate;
      if (this.containerEndDate && this.selectedDuration.value !== 0) {
        this.updateEndDateBasedOnDuration();
      }
    },
    setDayesOfEnd(item) {
      this.selectedDuration = item.raw;
      localStorage.setItem(
        "meta_dayes_end",
        JSON.stringify(this.selectedDuration)
      );
      this.updateEndDateBasedOnDuration();
    },
    updateEndDateBasedOnDuration() {
      if (!this.containerEndDate || this.selectedDuration.value === 0) return;

      const startDate = new Date(this.startDate);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + this.selectedDuration.value);
      this.endDate = endDate.toISOString().split("T")[0];
    },
    toggleShowMoreOptions() {
      this.chargedOptions = !this.chargedOptions;
    },
    setBudgetAmount() {
      localStorage.setItem("meta_budget_amount", this.budget_amount);
    },
    toggleminimumMaximum() {
      this.limitedStatus = !this.limitedStatus;
    },
    setDailyMinimum(){
      localStorage.setItem('meta_daily_minimum', this.daily_minimum)
    },
    setDailyMaximum(){
      localStorage.setItem('meta_daily_maximum', this.daily_maximum)
    },
  },
  watch: {
    startDate: {
      handler(newStartDate) {
        if (this.endDate && new Date(this.endDate) < new Date(newStartDate)) {
          this.endDate = newStartDate;
        }
        this.updateEndDateBasedOnDuration();
        this.setStartDate();
      },
    },
    selectedDuration: {
      handler() {
        this.updateEndDateBasedOnDuration();
      },
    },
    endDate: {
      handler(newEndDate) {
        if (newEndDate && new Date(newEndDate) < new Date(this.startDate)) {
          this.endDate = this.startDate;
        }
        this.setEndDate();
      },
    },
    budgetSelected: {
      handler(val) {
        if (val.value === "daily_budget") {
          this.budget_amount = 40;
        } else {
          this.budget_amount = 350;
        }
        localStorage.setItem("meta_budget_amount", this.budget_amount);
      },
    },
  },
};
</script>
