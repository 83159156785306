<template>
  <div class="attribution-setting">
    <label class="d-block mb-1 title roboto-bold" for=""
      >Attribution setting
    </label>
    <p class="roboto-bold">Click-through</p>
    <v-select
      v-model="clickThrowSelected"
      :items="clickThrow"
      density="compact"
      variant="outlined"
      return-object
      item-value="value"
      item-title="title"
      hide-details
      class="mb-3"
    >
      <template v-slot:selection="{ item }">
        {{ item?.title || "" }}
      </template>
      <template v-slot:item="{ item }">
        <v-list-item @click="selectItemClickThrow(item)">
          <div class="my-form">
            <div class="ga-3">
              <input
                :checked="
                  clickThrowSelected && clickThrowSelected.id === item.raw.id
                "
                :id="`${item.raw.id}throw_select`"
                type="radio"
                name="option"
                :value="item"
              />
              <div class="d-flex flex-column ga-1">
                <label
                  :for="`${item.raw.id}throw_select`"
                  class="d-block"
                  style="font-size: 14px"
                  >{{ item.raw.title }}</label
                >
              </div>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-select>
    <p class="roboto-bold">Engaged-view (for video only)</p>
    <v-select
      v-model="engagedViewSelected"
      :items="engagedView"
      density="compact"
      variant="outlined"
      return-object
      item-value="value"
      item-title="title"
      hide-details
      class="mb-3"
    >
      <template v-slot:selection="{ item }">
        {{ item?.title || "" }}
      </template>
      <template v-slot:item="{ item }">
        <v-list-item @click="selectItemEngagedView(item)">
          <div class="my-form">
            <div class="ga-3">
              <input
                :checked="
                  engagedViewSelected && engagedViewSelected.id === item.raw.id
                "
                :id="`${item.raw.id}engaged-view`"
                type="radio"
                name="option"
                :value="item"
              />
              <div class="d-flex flex-column ga-1">
                <label
                  :for="`${item.raw.id}engaged-view`"
                  class="d-block"
                  style="font-size: 14px"
                  >{{ item.raw.title }}</label
                >
              </div>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-select>

    <p class="roboto-bold">View-through</p>
    <v-select
      v-model="viewThrowSelected"
      :items="engagedView"
      density="compact"
      variant="outlined"
      return-object
      item-value="value"
      item-title="title"
      hide-details
      class="mb-3"
    >
      <template v-slot:selection="{ item }">
        {{ item?.title || "" }}
      </template>
      <template v-slot:item="{ item }">
        <v-list-item @click="selectItemViewThrow(item)">
          <div class="my-form">
            <div class="ga-3">
              <input
                :checked="
                  viewThrowSelected && viewThrowSelected.id === item.raw.id
                "
                :id="`${item.raw.id}viewThrow`"
                type="radio"
                name="option"
                :value="item"
              />
              <div class="d-flex flex-column ga-1">
                <label
                  :for="`${item.raw.id}viewThrow`"
                  class="d-block"
                  style="font-size: 14px"
                  >{{ item.raw.title }}</label
                >
              </div>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clickThrow: [
        {
          id: 1,
          title: 1,
          value: 1,
        },
        {
          id: 2,
          title: 7,
          value: 7,
        },
      ],
      clickThrowSelected: { id: 1, title: 1, value: 1 },
      engagedView: [
        {
          id: 1,
          title: "None",
          value: "NONE",
        },
        {
          id: 2,
          title: "1 day",
          value: "1_DAY",
        },
      ],
      engagedViewSelected: { id: 2, title: "1 day", value: "1_DAY" },
      viewThrowSelected:{ id: 2, title: "1 day", value: "1_DAY" },
    };
  },
  methods: {
    selectItemClickThrow(item) {
      this.clickThrowSelected = item.raw;
      localStorage.setItem("click_throw_meta", item.raw.value);
    },
    selectItemEngagedView(item) {
      this.engagedViewSelected = item.raw;
      localStorage.setItem("engaged_view_meta", item.raw.value);
    },
    selectItemViewThrow(item) {
      this.viewThrowSelected = item.raw;
      localStorage.setItem("view_throw_meta", item.raw.value);
    },
  },
};
</script>
