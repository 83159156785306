<template>
  <div class="placement-card targeting-card">
    <div class="card-header">
      <p class="card-title">Targeting</p>
    </div>
    <div class="card-body">
      <div class="saved-audience">
        <label for="" class="audience-label">Saved audience <span>(optional)</span>
        </label>
        <treeselect :options="options" :multiple="true" :flatten-search-results="true"
          placeholder="Select saved audience" />
      </div>
      <DemographicsTargeting />
      <AudienceTargeting />
      <div class="demographics interests-behaviors">
        <v-expansion-panels class="my-4">
          <v-expansion-panel title="Interests & behaviors">
            <v-expansion-panel-text>
              <p class="interests-behaviors">
                Target an audience based on their long-term interests and
                interactions with content on TikTok.
              </p>
              <SelectedPreview v-if="selectedItemsList.length" :selected-items="selectedItemsList"
                @remove="toggleSelect" @clear="clearSelection" />
              <SearchInput v-model="searchQuery" @toggleSearch="toggleSearch" />

              <div class="tree-dropdown" v-if="treeNodeSelect">
                <div class="tabs-container">
                  <div class="tabs">
                    <div v-for="tab in tabs" :key="tab.id" :class="['tab', { active: activeTab === tab.id }]"
                      @click="activeTab = tab.id">
                      {{ tab.name }}
                    </div>
                  </div>
                </div>
                <div class="tree-container">
                  <div class="scrollable">
                    <div v-if="filteredItems.length" class="tree">
                      <TreeNode v-for="item in filteredItems" :key="item.id" :item="item" :selected="selectedItems"
                        @toggle-select="toggleSelect" />
                    </div>
                    <div v-else class="no-results">No results found</div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <DevicesTargeting />
    </div>
  </div>
</template>

<script>
import Treeselect from "vue3-treeselect";
import TreeNode from "@/components/tiktok/SecoundStep/TreeNode.vue";
import SearchInput from "./SearchInput.vue";
import SelectedPreview from "./SelectedPreview.vue";
import apiClient from "@/core/buildRequest";
import DemographicsTargeting from "./targeting/Demographics.vue";
import AudienceTargeting from "./targeting/Audience.vue";
import DevicesTargeting from "./targeting/Devices.vue";
export default {
  components: {
    Treeselect,
    TreeNode,
    SearchInput,
    SelectedPreview,
    DemographicsTargeting,
    AudienceTargeting,
    DevicesTargeting
  },
  data() {
    return {
      options: [
        {
          id: "m",
          label: "Audience 1",
        },
        {
          id: "kfc",
          label: "Audience 2",
        },
        {
          id: "bk",
          label: "Audience 3",
        },
      ],
      multiple: true,
      activeTab: "interests",
      selectedItems: new Set(),
      searchQuery: "",
      tabs: [
        { id: "interests", name: "Interests" },
        { id: "video", name: "Video interactions" },
        { id: "creator", name: "Creator interactions" },
        { id: "hashtag", name: "Hashtag interactions" },
      ],
      interests: [
        {
          id: "1",
          name: "Baby, Kids & Maternity",
          children: [
            { id: "1-1", name: "Baby Care" },
            { id: "1-2", name: "Kids Clothing" },
            { id: "1-3", name: "Maternity Wear" },
          ],
        },
        {
          id: "2",
          name: "Beauty & Personal Care",
          children: [
            { id: "2-1", name: "Skincare" },
            { id: "2-2", name: "Makeup" },
            { id: "2-3", name: "Hair Care" },
          ],
        },
        {
          id: "3",
          name: "Household Products",
          children: [
            { id: "3-1", name: "Cleaning" },
            { id: "3-2", name: "Organization" },
          ],
        },
        // Keep other items, adding sample children to demonstrate structure
        { id: "4", name: "Pets", children: [] },
        { id: "5", name: "Appliances", children: [] },
        { id: "6", name: "Games", children: [] },
        { id: "7", name: "Education", children: [] },
        { id: "8", name: "Travel", children: [] },
        { id: "9", name: "Business Services", children: [] },
        { id: "10", name: "E-Commerce (Non-app)", children: [] },
        { id: "11", name: "Food & Beverage", children: [] },
        { id: "12", name: "Financial Services", children: [] },
        { id: "13", name: "Vehicles & Transportation", children: [] },
        { id: "14", name: "Tech & Electronics", children: [] },
        { id: "15", name: "Life Services", children: [] },
        { id: "16", name: "Apps", children: [] },
        { id: "17", name: "Apparel & Accessories", children: [] },
        { id: "18", name: "Home Improvement", children: [] },
        { id: "19", name: "Sports & Outdoors", children: [] },
        { id: "20", name: "News & Entertainment", children: [] },
      ],
      treeNodeSelect: false,
      advertise_id: "",
    };
  },
  mounted() {
    this.advertise_id = localStorage.getItem("advertiser_id");
  },
  computed: {
    currentItems() {
      return this.interests;
    },
    filteredItems() {
      if (!this.searchQuery) {
        return this.currentItems;
      }
      const query = this.searchQuery.toLowerCase();
      return this.currentItems.filter((item) =>
        item.name.toLowerCase().includes(query)
      );
    },
    selectedItemsList() {
      const findItem = (items, id) => {
        for (const item of items) {
          if (item.id === id) return item;
          if (item.children) {
            const found = findItem(item.children, id);
            if (found) return found;
          }
        }
        return null;
      };

      return Array.from(this.selectedItems)
        .map((id) => findItem(this.interests, id))
        .filter((item) => item !== null);
    },
  },
  methods: {
    toggleSelect(itemId) {
      if (this.selectedItems.has(itemId)) {
        this.selectedItems.delete(itemId);
      } else {
        this.selectedItems.add(itemId);
      }
    },
    clearSelection() {
      this.selectedItems.clear();
    },
    toggleSearch() {
      this.treeNodeSelect = !this.treeNodeSelect;
    },
  },
 
};
</script>

<style scoped>
.tree-dropdown {
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background: white;
  display: flex;
  justify-content: space-between;
  width: 51%;
}

.tabs-container {
  border-bottom: 1px solid #e4e7ed;
}

.tabs {
  display: flex;
  padding: 0 0;
  flex-direction: column;
}

.tab {
  padding: 12px 16px;
  cursor: pointer;
  color: #606266;
  font-size: 14px;
}
.tabs .tab {
  width: auto;
  height: auto; 
  padding: 5px 10px;
  font-size: 14px;
}

.tab.active {
  color: #017976;
}

.tree-container {
  height: 300px;
  overflow: hidden;
}

.scrollable {
  height: 100%;
  overflow-y: auto;
  padding: 8px 0;
}

.tree {
  padding: 0 16px;
}
</style>
