import { loginForm } from "@/store/logIn";

const authMiddleware = async (to, from, next) => {
  try {
    if (window.location.protocol === "http:") {
      window.location.href = window.location.href.replace(
        "http://",
        "https://"
      );
      return; // return what?
    }
    if (to.meta.title) {
      document.title = to.meta.title;
    }

    const store = loginForm();
    const requiresAuth = to.matched.some(
      (record) => record.meta.auth === "auth"
    );
    const requiresUnAuth = to.matched.some(
      (record) => record.meta.auth === "unauth"
    );
    
    if (requiresAuth) {
      const isAuthenticated = await store.checkAuth();
      if (!isAuthenticated) {
        next({ name: "LoginPage" });
        return;
      }
    }
    if (requiresUnAuth && localStorage.getItem("user_token") !== null) {
      next({ name: "HomePage" });
      return;
    }
    
    if (!to.meta.roles && !to.meta.permissions) {
      next();
      return;
    }
    
    if (
      (!store.role || !store.permissions) &&
      localStorage.getItem("user_token")
    ) {
      await store.getRolesPermissions();
    }
    const userRole = store.role;
    const userPermissions = store.permissions;
    // Check role authorization
    const isAuthorizedRole = to.meta.roles
      ? to.meta.roles.some((role) => role === userRole)
      : true;
    // Check permissions authorization
    const isAuthorizedPermissions = to.meta.permissions
      ? to.meta.permissions.some((permission) =>
          userPermissions.includes(permission)
        )
      : true;
    // Redirect if not authorized
    if (!isAuthorizedRole || !isAuthorizedPermissions) {
      next({ name: "AuthorizationError" });
      return;
    }

    next();
  } catch (error) {
    console.error("Navigation guard error:", error);
    next({ name: "HomePage" });
  }
};

export default authMiddleware;
