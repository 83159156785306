import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const useAccountsStore = defineStore("useAccountsStore", {
  state: () => ({
    accounts: [],
    headers: [
      {
        title: "Account",
        name: "Account",
        align: "start",
        key: "account",
        menu: true,
        show: true,
        sortable: true,
      },
      {
        title: "Name",
        name: "Name",
        align: "start",
        show: true,
        menu: true,
        key: "name",
        sortable: true,
      },
      {
        title: "Phone",
        name: "Phone",
        align: "start",
        show: true,
        key: "phone",
        menu: true,
      },
      {
        title: "Email",
        name: "Email",
        align: "start",
        show: true,
        menu: true,

        key: "email",
      },
      {
        title: "Contact Person",
        name: "ContactPerson",
        align: "start",
        show: true,
        menu: true,
        key: "contact_number",
      },
      {
        title: "Business Type",
        name: "Business Type",
        align: "start",
        show: true,
        menu: true,
        key: "business_type.name",
      },
      {
        title: "Country",
        name: "Country",
        align: "start",
        show: true,
        menu: true,

        key: "country.name",
      },
      {
        title: "City",
        name: "City",
        align: "start",
        show: true,
        menu: true,

        key: "city.name",
      },
      {
        title: "Potentiality",
        name: "Potentiality",
        align: "start",
        show: true,
        menu: true,
        key: "potentiality",
      },
      {
        title: "Description",
        name: "Description",
        align: "start",
        show: true,
        menu: true,

        key: "description",
      },
      {
        title: "Actions",
        name: "Actions",
        align: "center",
        sortable: false,
        key: "actions",
        show: true,
        menu: true,
      },
    ],
    items_per_page: 5,
    current_page: 1,
    total_accounts: 0,
    total_pages: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    search: "",
    filters: [],
    filtersBy: [],
  }),
  getters: {
    getAccounts: (state) => state.accounts,
    getHeaders: (state) => state.headers.filter((header) => header.show),
    getPagination: (state) => ({
      currentPage: state.current_page,
      totalPages: state.total_pages,
      itemsPerPage: state.items_per_page,
      totalAccounts: state.total_accounts,
    }),
    isLoading: (state) => state.loading,
  },
  actions: {
    async getAllAccounts() {
      try {
        var response = await apiClient.get(
          `/leads-managment/accounts?page=${this.current_page}&per_page=${this.items_per_page}&search=${this.search}`,
          {
            params: {
              filters: this.filtersBy.length > 0 ? this.filtersBy : null,
            },
          }
        );
        let { data, pagination } = response.data.data;
        this.accounts = data;
        this.current_page = pagination.current_page;
        this.total_accounts = pagination.total_items;
        this.total_pages = pagination.total_pages;
        this.hasNextPage = pagination.next_page_url !== "" ? true : false;
        this.hasPreviousPage = pagination.perv_page_url !== "" ? true : false;
      } catch (err) {
        console.error("Error fetching accounts:", err);
      }
    },
    hideColumnFromHeader(value) {
      this.headers.map((item) => {
        item.show = !value;
      });
    },

    onPageChange() {
      return this.getAllAccounts();
    },
    onItemsPerPageChange() {
      this.current_page = 1;
      return this.getAllAccounts();
    },
    previousPage() {
      this.current_page -= 1;
      return this.getAllAccounts();
    },
    nextPage() {
      this.current_page += 1;
      return this.getAllAccounts();
    },
    pushAccounts(accounts) {
      this.accounts.unshift(accounts);
    },
    updateAccounts(accounts) {
      const index = this.accounts.findIndex((item) => item.id === accounts.id);
      if (index !== -1) {
        this.accounts.splice(index, 1, accounts);
      }
    },
    deleteAccount(accounts) {
      const index = this.accounts.findIndex((item) => item.id === accounts.id);
      if (index !== -1) {
        this.accounts.splice(index, 1);
      }
    },
    updatePotentiality(id, potentiality) {
      const index = this.accounts.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.accounts[index].potentiality = potentiality;
      }
    },
    getAllFilters() {
      apiClient.get(`/leads-managment/accounts-attributes`).then((response) => {
        this.filters = response.data.data;
      });
    },
    applyAdvancedFilter() {
      this.getAllAccounts();
    },
  },
});
