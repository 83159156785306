<template>
    <div class="demographics">
        <v-expansion-panels class="my-4">
            <v-expansion-panel title="Demographics">
                <v-expansion-panel-text>
                    <div class="location mb-10">
                        <p class="card-title">Location</p>
                        <CountrySearch />
                    </div>
                    <div class="age mb-10">
                        <div class="age-form my-3">
                            <label for="age" class="mb-1 age-label d-block"> Age </label>
                            <p class="label-description">
                                In some regions, ad delivery may be subject to additional
                                age targeting restrictions.
                            </p>
                            <div class="mydict">
                                <div>
                                    <label>
                                        <input type="checkbox" value="All" :checked="isAllSelected"
                                            @change="handleAllChecked" />
                                        <span>All</span>
                                    </label>
                                    <label>
                                        <input type="checkbox" value="AGE_13_17" v-model="selectedValues"
                                            @change="handleOptionChecked" />
                                        <span>13-17</span>
                                    </label>
                                    <label>
                                        <input type="checkbox" value="AGE_18_24" v-model="selectedValues"
                                            @change="handleOptionChecked" />
                                        <span>18-24</span>
                                    </label>
                                    <label>
                                        <input type="checkbox" value="AGE_25_34" v-model="selectedValues"
                                            @change="handleOptionChecked" />
                                        <span>25-34</span>
                                    </label>
                                    <label>
                                        <input type="checkbox" value="AGE_35_44" v-model="selectedValues"
                                            @change="handleOptionChecked" />
                                        <span>35-44</span>
                                    </label>
                                    <label>
                                        <input type="checkbox" value="AGE_45_54" v-model="selectedValues"
                                            @change="handleOptionChecked" />
                                        <span>45-54</span>
                                    </label>
                                    <label>
                                        <input type="checkbox" value="AGE_55_100" v-model="selectedValues"
                                            @change="handleOptionChecked" />
                                        <span>+55</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="age mb-10">
                        <div class="age-form my-3">
                            <label for="gender" class="mb-1 age-label d-block">
                                Gender
                            </label>
                            <div class="mydict">
                                <div>
                                    <label>
                                        <input type="radio" value="GENDER_UNLIMITED" v-model="gender"
                                            @change="updateGender" name="gender" />
                                        <span>All</span>
                                    </label>
                                    <label>
                                        <input type="radio" v-model="gender" @change="updateGender" value="GENDER_MALE"
                                            name="gender" />
                                        <span>Male</span>
                                    </label>
                                    <label>
                                        <input type="radio" v-model="gender" @change="updateGender"
                                            value="GENDER_FEMALE" name="gender" />
                                        <span>female</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="language mb-10">
                        <label for="">Languages</label>
                        <div>
                            <v-select density="compact" multiple v-model="selectedLanguages" item-title="name"
                                item-value="code" :items="languagesFromApi" variant="outlined" clearable
                                @update:modelValue="updateLanguages"></v-select>
                        </div>
                    </div>
                    <div class="age spending-power">
                        <label for="">Spending power</label>
                        <div class="mydict">
                            <div>
                                <label>
                                    <input type="radio" value="ALL" v-model="spendPower" @change="updateSpendPower"
                                        name="spendPower" />
                                    <span>All</span>
                                </label>
                                <label>
                                    <input type="radio" v-model="spendPower" @change="updateSpendPower" value="HIGH"
                                        name="spendPower" />
                                    <span> High spending power </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>


<script>
import CountrySearch from "../CountrySearch.vue";
import apiClient from "@/core/buildRequest";
export default {
    name: "DemographicsTargeting",
    components: {
        CountrySearch
    },
    data() {
        return {
            selectedValues: ["All"],
            gender: "GENDER_UNLIMITED",
            selectedLanguages: [
                {
                    code: "ar",
                    name: "Arabic",
                },
            ],
            languagesFromApi: [],
            advertise_id: "",
            spendPower: "ALL",
        }
    },
    methods: {
        handleOptionChecked() {
            if (this.isAllSelected) {
                // Remove "All" if any other option is selected
                this.selectedValues = this.selectedValues.filter(
                    (val) => val !== "All"
                );
            }
            localStorage.setItem(
                "selectedValues",
                JSON.stringify(this.selectedValues)
            );
        },
        handleAllChecked(event) {
            if (event.target.checked) {
                // If "All" is checked, clear other selections and add "All"
                this.selectedValues = ["All"];
                localStorage.setItem(
                    "selectedValues",
                    JSON.stringify(this.selectedValues)
                );
            } else {
                // If "All" is unchecked, clear the array
                this.selectedValues = [];
                localStorage.setItem(
                    "selectedValues",
                    JSON.stringify(this.selectedValues)
                );
            }
        },
        updateGender() {
            localStorage.setItem("gender", this.gender);
        },
        getLanguages() {
            apiClient
                .get(`/campaign-ads/tiktok/get/language/code/${this.advertise_id}`)
                .then((response) => {
                    this.languagesFromApi = response.data.data.data.languages;
                });
        },
        updateLanguages() {
            console.log(this.selectedLanguages);
            localStorage.setItem("languages", JSON.stringify(this.selectedLanguages));
        },
        updateSpendPower() {
            localStorage.setItem("spendPower", this.spendPower);
        },
    },
    computed: {
        isAllSelected() {
            return this.selectedValues.includes("All");
        },
    },
    mounted() {
        this.advertise_id = localStorage.getItem("advertiser_id");
        this.getLanguages();
        if (localStorage.getItem("selectedValues")) {
            this.selectedValues = JSON.parse(localStorage.getItem("selectedValues"));
        } else {
            this.selectedValues = ["All"];
        }
        if (localStorage.getItem("gender")) {
            this.gender = localStorage.getItem("gender");
        } else {
            localStorage.setItem("gender", this.gender);
        }
        if (localStorage.getItem("languages")) {
            this.selectedLanguages = JSON.parse(localStorage.getItem("languages"));
        } else {
            localStorage.setItem("languages", JSON.stringify(this.selectedLanguages));
        }
        const storedGender = localStorage.getItem("gender");
        if (storedGender) {
            this.gender = storedGender;
        }
        localStorage.setItem("spendPower", this.spendPower);
    }
}
</script>