<template>
    <div class="tik-tok-preview-card px-3 py-3 placement-card">
        <div class="card-header d-flex align-center justify-space-between"  >
            <div class="buttons-select">
                <button class="active">
                    <img src="@/assets/TiktokMedia/logo.png" alt="" />
                </button>
                <!-- <button class="">
          <img src="@/assets/tiktokImage/global-app-bundle7e77d90c.png" alt="" />
        </button>
        <button class="">
          <img src="@/assets/tiktokImage/placement-new-pangle498ddcf9.png" alt="" />
        </button> -->
            </div>
            <div class="buttons-preiew">
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                        fill="#009995">
                        <path
                            d="m722-95-51-52 57-57H576v-72h153l-58-58 49-50 146 145L722-95Zm-506-49q-29.7 0-50.85-21.15Q144-186.3 144-216v-168h72v168h168v72H216Zm-72-432v-168q0-29.7 21.15-50.85Q186.3-816 216-816h168v72H216v168h-72Zm600 0v-168H576v-72h168q29.7 0 50.85 21.15Q816-773.7 816-744v168h-72Z" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="card-body py-3" style="height: 670px">
            <!-- <v-select variant="outlined" :items="items" density="compact"></v-select> -->
            <div class="view-card position-relative" style="background: linear-gradient(rgba(0, 0, 0, 0) -0.28%, rgba(0, 0, 0, 0) 7.6%, rgba(0, 0, 0, 0.01) 14.9%, rgba(0, 0, 0, 0.03) 21.7%, rgba(0, 0, 0, 0.05) 28.09%, rgba(0, 0, 0, 0.08) 34.16%, rgba(0, 0, 0, 0.11) 40.01%, rgba(0, 0, 0, 0.14) 45.73%, rgba(0, 0, 0, 0.17) 51.4%, rgba(0, 0, 0, 0.19) 57.11%, rgba(0, 0, 0, 0.22) 62.96%, rgba(0, 0, 0, 0.25) 69.04%, rgba(0, 0, 0, 0.27) 75.43%, rgba(0, 0, 0, 0.29) 82.23%, rgba(0, 0, 0, 0.3) 89.52%, rgba(0, 0, 0, 0.3) 97.4%);" >
                <div class="tiktok-status-bar" style="background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.004) 7.86%, rgba(0, 0, 0, 0.01) 15.13%, rgba(0, 0, 0, 0.024) 21.91%, rgba(0, 0, 0, 0.043) 28.29%, rgba(0, 0, 0, 0.063) 34.35%, rgba(0, 0, 0, 0.086) 40.18%, rgba(0, 0, 0, 0.11) 45.88%, rgba(0, 0, 0, 0.133) 51.53%, rgba(0, 0, 0, 0.157) 57.23%, rgba(0, 0, 0, 0.176) 63.06%, rgba(0, 0, 0, 0.196) 69.12%, rgba(0, 0, 0, 0.216) 75.5%, rgba(0, 0, 0, 0.227) 82.28%, rgba(0, 0, 0, 0.235) 89.55%, rgba(0, 0, 0, 0.24) 97.41%);">
                    <img src="@/assets/tiktokImage/titk_status_bar.png" alt="" />
                </div>
                <div class="video-control" style="height: 584px; display: flex; align-items: center; justify-content: center;" v-if="store.selectedMedia.length == 0 ">
                    <img src="@/assets/tiktokImage/tiktok_background.svg" alt="" />
                </div>
                <div class="video-control" style="height: 584px;" v-if="store.selectedMedia.length > 0 && store.selectedMedia[0].type === 'video'">
                    <video ref="videoPlayer" class="media"   :src="store.selectedMedia[0].url"></video>
                    <button @click="togglePlayPause">
                        <span v-if="isPlaying" v-html="pause"></span>
                        <span v-else v-html="play"> </span>
                    </button>
                </div>

                <v-carousel v-if="store.selectedMedia.length > 0 && store.selectedMedia[0].type === 'image'" show-arrows="hover" cycle hide-delimiter-background>
                    <v-carousel-item v-for="(item, i) in store.selectedMedia" :key="i" cover>
                        <button class="music-control">
                        </button>
                        <div :style="{ backgroundImage: `url(${item.url})` }"  style="
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center; ">
                        </div>
                        <template v-slot:placeholder>
                            <div class="d-flex align-center justify-center fill-height">
                                <v-progress-circular indeterminate color="grey-lighten-4"></v-progress-circular>
                            </div>
                        </template>
                    </v-carousel-item>
                </v-carousel>
                <div class="preiew-actions">
                    <div class="texts-identity-name">
                        <p class="mb-1 your-identity">Your identity</p>
                        <p class="mb-1 your-identity"> {{ adCreativeStore.postText }} </p>
                        <span class="px-2 py-2" style="width: 210px; text-align: center; background-color: rgb(254, 44, 85);" > {{ adCreativeStore.selectedCallToAction[0].label }} </span>
                    </div>
                    <div class="user-image-preview">
                        <div class="user-img mb-2">
                            <img :src="imagePreview" alt="Profile Image" />
                        </div>
                        <div class="d-flex liks flex-column align-center ga-1">
                            <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 -960 960 960" width="22px"
                                fill="#fff">
                                <path
                                    d="m480-144-50-45q-100-89-165-152.5t-102.5-113Q125-504 110.5-545T96-629q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 43-14 83t-51.5 89q-37.5 49-103 113.5T528-187l-48 43Z" />
                            </svg>
                            <span class="text-white fs-8">991K</span>
                        </div>
                        <div class="d-flex liks flex-column align-center ga-1">
                            <svg xmlns="http://www.w3.org/2000/svg" height="22px" width="22px" fill="#fff"
                                viewBox="0 0 512 512">
                                <path
                                    d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c0 0 0 0 0 0s0 0 0 0s0 0 0 0c0 0 0 0 0 0l.3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z" />
                            </svg>
                            <span class="text-white fs-8">991K</span>
                        </div>
                        <div class="d-flex liks flex-column align-center ga-1">
                            <svg height="22px" width="22px" fill="#fff" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512">
                                <path
                                    d="M307 34.8c-11.5 5.1-19 16.6-19 29.2l0 64-112 0C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96l96 0 0 64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z" />
                            </svg>
                            <span class="text-white fs-8">991K</span>
                        </div>
                    </div>
                </div>
                <div style="
            margin: 0;
            background: #000;
            position: absolute;
            padding: 6px;
            bottom: -23px;
            border-radius: 0 0 5px 5px;
          ">
                    <img style="width: 100%; display: block; border-radius: 0 0 5px 5px"
                        src="@/assets/tiktokImage/6431269930350056.png" alt="" />
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { uploadMedia } from '@/store/tiktokStore/uploadMedia';
import { adCreative } from '@/store/tiktokStore/adCreative';
export default {
    name: 'MediaPreview',
    data() {
        return {
            store: uploadMedia(),
            adCreativeStore: adCreative(),
            imagePreview:
        "https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/identity-default-avatar81d7ba6f.png",
      singleVideo: false,
      isPlaying: false,
      play: ` <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              width="40px"
              height="40"
              fill="#fff"
            >
              <path
                d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
              />
            </svg>`,
      pause: `<svg
              width="40px"
              height="40"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                d="M48 64C21.5 64 0 85.5 0 112L0 400c0 26.5 21.5 48 48 48l32 0c26.5 0 48-21.5 48-48l0-288c0-26.5-21.5-48-48-48L48 64zm192 0c-26.5 0-48 21.5-48 48l0 288c0 26.5 21.5 48 48 48l32 0c26.5 0 48-21.5 48-48l0-288c0-26.5-21.5-48-48-48l-32 0z"
              />
            </svg>`,
        }
    },
    methods: {
        togglePlayPause() {
            const video = this.$refs.videoPlayer; // Get video element reference

            if (this.isPlaying) {
                video.pause();
            } else {
                video.play();
            }
            this.isPlaying = !this.isPlaying;
        },
    },

}
</script>


<style scoped>
.v-window{
    height: 584px !important;
}
.v-responsive__content{
    max-width: auto !important;
}
</style>