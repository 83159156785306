<template>
  <v-switch :model-value="add_to_calendar" color="green" @update:model-value="updateAddToCalendar" :true-value="1"
    :false-value="0"></v-switch>
</template>

<script>
import apiClient from "@/core/buildRequest";
import { useProjectsStore } from "@/store/crm/useProjectsStore";
import { useGeneralStore } from "@/store/General";

export default {
  name: "statusCol",
  props: ["item", "id"],
  data() {
    return {
      add_to_calendar: 0,
      project_store: useProjectsStore(),
      general_store: useGeneralStore(),
    };
  },
  mounted() {
    this.add_to_calendar = Number(this.item.add_to_calendar) ?? 0;
  },
  created() {
    this.projectStore = useProjectsStore();
  },
  watch: {
    "item.add_to_calendar"(newValue) {
      this.add_to_calendar = Number(newValue) ?? 0;
    },
  },
  methods: {
    async updateAddToCalendar(value) {
      this.add_to_calendar = value;
      try {
        await apiClient
          .post(`/leads-managment/deals/add_to_calender/${this.id}`, {
            add_to_calendar: value,
          })
          .then((res) => {
            this.project_store.updateAddToCalender(this.id, value);
            this.general_store.showSnackbar(res.data.msg, "success");
          });
      } catch (error) {
        console.error("Error updating add_to_calendar:", error);
      }
    },
  },
};
</script>
