import { defineStore } from "pinia";

export const adCreative = defineStore('adCreative', {
    state: () => ({
        postText: "",
        alert: false,
        selectedCallToAction: [
            { value: 'APPLY_NOW', label: 'Apply now' },
            { value: 'BOOK_NOW', label: 'Book now' },
            { value: 'BUY_NOW', label: 'Buy now' },
        ],
        callToActionOptions: [
            { value: 'APPLY_NOW', label: 'Apply now' },
            { value: 'BOOK_NOW', label: 'Book now' },
            { value: 'BUY_NOW', label: 'Buy now' },
            { value: 'CONTACT_US', label: 'Contact us' },
            { value: 'DOWNLOAD_NOW', label: 'Download now' },
            { value: 'GET_DIRECTIONS', label: 'Get directions' },
            { value: 'GET_QUOTE', label: 'Get quote' },
            { value: 'GET_SHOWTIMES', label: 'Get showtimes' },
            { value: 'INSTALL_NOW', label: 'Install now' },
            { value: 'LEARN_MORE', label: 'Learn more' },
            { value: 'LIKE', label: 'Like' },
            { value: 'MESSAGE_PAGE', label: 'Message page' },
            { value: 'OPEN_APP', label: 'Open app' },
            { value: 'ORDER_NOW', label: 'Order now' },
            { value: 'PLAY_GAME', label: 'Play game' },
            { value: 'REGISTER_NOW', label: 'Register now' },
            { value: 'SEE_MORE', label: 'See more' },
            { value: 'SHOP_NOW', label: 'Shop now' },
            { value: 'SIGN_UP', label: 'Sign up' },
            { value: 'SUBSCRIBE', label: 'Subscribe' },
            { value: 'VIEW_NOW', label: 'View now' },
            { value: 'VIEW_PROFILE', label: 'View profile' },
            { value: 'VISIT_STORE', label: 'Visit store' },
            { value: 'WATCH_LIVE', label: 'Watch LIVE' },
            { value: 'WATCH_NOW', label: 'Watch now' },
            { value: 'JOIN_THIS_HASHTAG', label: 'Join this hashtag' },
            { value: 'SHOOT_WITH_THIS_EFFECT', label: 'Shoot with this effect' },
            { value: 'VIEW_VIDEO_WITH_THIS_EFFECT', label: 'View video with this effect' }
        ],
        destination: 'Website',
        panel: 0,
        direct_users_deeplink: false,
        direct_user_url: "",
        url: "",
        isValid: true,
        automatically_add_url_parameters: false,
        identity: "",
        identityOptions: [
            { value: 'PERSONAL', label: 'Personal',img:'https://p16-sign-sg.tiktokcdn.com/tos-alisg-avt-0068/c8f462bdf96807843d578d48159e3aa4~tplv-tiktokx-cropcenter:100:100.jpeg?dr=14579&refresh_token=1ee095ea&x-expires=1742551200&x-signature=MfTm4Y2s9iQJgDDU4V%2FcxfsF%2F9A%3D&t=4d5b0474&ps=13740610&shp=a5d48078&shcp=8aecc5ac&idc=maliva' },
            { value: 'BUSINESS', label: 'Business',img:'https://p16-sign-sg.tiktokcdn.com/tos-alisg-avt-0068/c8f462bdf96807843d578d48159e3aa4~tplv-tiktokx-cropcenter:100:100.jpeg?dr=14579&refresh_token=1ee095ea&x-expires=1742551200&x-signature=MfTm4Y2s9iQJgDDU4V%2FcxfsF%2F9A%3D&t=4d5b0474&ps=13740610&shp=a5d48078&shcp=8aecc5ac&idc=maliva' },
        ],
    }),
    actions: {
        getSelectedCount() {
            return this.selectedCallToAction.length;
        },
       
    },
    

});
