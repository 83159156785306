<template>
    <div class="identity-account">
        <div class="identity-account-header">
            <h4 class="mb-3 title">Identity</h4>
        </div>
        <div class="identity-account-content">
            <v-select density="compact" hide-details :items="filteredIdentityOptions" item-title="label"
                item-value="value" v-model="store.identity" variant="outlined">

                <template v-slot:prepend-item>
                    <div class="search px-3">
                        <v-text-field v-model="store.search" variant="outlined" density="compact" hide-details
                            class="search-input" @input="filterItems"></v-text-field>
                    </div>
                </template>

                <!-- Items List -->
                <template v-slot:item="{ item, props }">
                    <div v-bind="props" @click="store.identity = item.raw.value" class="d-flex align-center ga-3 mb-3"
                        style="cursor: pointer;">
                        <img width="30" height="30" style="border-radius: 50%;" :src="item.raw.img" alt="">
                        <span>{{ item.raw.label }}</span>
                    </div>
                </template>
            </v-select>

        </div>
    </div>
</template>

<script>
import { adCreative } from "@/store/tiktokStore/adCreative";
export default {
    name: "IdentityComponent",
    data() {
        return {
            store: adCreative(),
        };
    },
    methods: {},
    computed: {
        filteredIdentityOptions() {
            if (!this.store.search) return this.store.identityOptions; // Show all if no search term
            return this.store.identityOptions.filter(option =>
                option.label.toLowerCase().includes(this.store.search.toLowerCase())
            );
        }
    }

};
</script>
