<template>
  <v-locale-provider :rtl="$i18n.locale === 'ar'">
    <v-layout class="position-relative pa-0">
      <router-view />
    </v-layout>
    <v-snackbar
      v-model="store.snackbar.show"
      :timeout="store.snackbar.timeout"
      :color="store.snackbar.color"
      location="top"
      close-on-content-click
    >
      {{ store.snackbar.message }}
    </v-snackbar>
    <v-overlay
      :model-value="store.getLoading"
      class="align-center justify-center"
      :persistent="store.closable"
    >
      <v-progress-circular
        color="primary"
        size="64"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-locale-provider>
  <!-- <button @click="store.showLoading(true)">Show</button> -->
</template>

<script>
import { useGeneralStore } from "@/store/General";
import { loginForm } from "@/store/logIn";

export default {
  data() {
    return {
      store: useGeneralStore(),
      loginStore: loginForm(),
    };
  },
  computed: {
    isRtl() {
      let d = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
      if (d === "rtl") return true;
      return false;
    },
  },
  mounted() {
    if (localStorage.getItem("user")) {
      this.loginStore.getRolesPermissions();
    }
  },
};
</script>

<style scoped>
.mt-20 {
  margin-top: 90px;
}
</style>
