<template>
  <div class="publisher-list-container my-3 px-1">
    <div class="publisher-list-header mb-2">
      <label for="" class="roboto-bold">Publisher block lists</label>
      <button class="">
        <span>Edit</span>
        <span v-html="editeSvg" class="d-flex align-center"></span>
      </button>
    </div>
    <div class="publisher-body">
      <p class="roboto-bold mb-2">Content type exclusions</p>
      <label
        v-for="type in type_exclusions"
        class="checkbox"
        :style="{
          cursor: type.diable ? 'no-drop' : '',
          opacity: type.diable ? '0.5' : '1',
        }"
      >
        <input
          :disabled="type.diable"
          :checked="type.checked"
          type="checkbox"
          :id="`${type.id} + '_' + type.model`"
          :v-model="type.checked"
        />
        <span class="checkmark"></span>
        <span class="text d-flex align-center">
          {{ type.text }}
        </span>
      </label>

      <div class="topic-exclusions">
        <label for="" class="roboto-bold mb-1">
          Topic exclusions for Facebook in-stream videos</label>
        <button>
          <span>Edit</span>
          <span v-html="editeSvg" class="d-flex align-center"></span>
        </button>
      </div>
      <div class="topic-exclusions-body">
        <p style="color: #000000d9;">None selected</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      excludeAll: false,
      type_exclusions: [
        {
          id: 0,
          text: "Exclude all live videos from Facebook in-stream video ads",
          checked: false,
          diable: false,
          model: "live_video",
        },
        {
          id: 1,
          text: "Exclude in-stream video ads from non-partner publishers",
          checked: false,
          diable: true,
          model: "in-stream-video",
        },
        {
          id: 2,
          text: "Exclude ads on Facebook Reels from non-partner publishers",
          checked: false,
          diable: true,
          model: "reels",
        },
      ],
      editeSvg: `<svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#0a7abf"
        >
          <path
            d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
          />
        </svg>`,
    };
  },
};
</script>
