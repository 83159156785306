<template>
  <v-app>
    <v-container
      fluid
      style="margin-top: 0%; padding: 0% 0% 0 0; min-height: calc(100vh - 58px)"
    >
      <v-row style="height: 100%; margin: 0; padding: 0; position: relative">
        <v-col cols="12" class="pa-0">
          <GlobalNav />
        </v-col>
        <v-col
          cols="12"
          style="height: 100%"
          class="pa-0 d-flex align-start ga-6"
        >
          <SideBar />
          <div class="w-100 pa-3 is-light-mode">
            <BreadCrumb :linksData="linksData" class="mb-3" />
            <div class="leads-managements-link d-flex align-center ga-2 my-3">
              <div
                @click="$router.push({ name: 'MyTeam' })"
                class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
                    fill="#1F666A"
                  />
                </svg>
                <span>Roles Permissions</span>
              </div>
            </div>
            <v-row>
              <v-col cols="12" :lg="3">
                <div class="d-flex flex-column ga-1 mb-5">
                  <label for="name"
                    >Role Name <span class="text-red-accent-4">*</span>
                  </label>
                  <Input
                    v-model="role_name"
                    placeholder="Role Name"
                    type="text"
                  />
                  <span
                    class="text-red-accent-4 fs-12"
                    v-if="errors.find((e) => e.key === 'role_name')"
                    >{{
                      errors.find((e) => e.key === "role_name").message
                    }}</span
                  >
                </div>
              </v-col>
            </v-row>
            <div>
              <v-row>
                <v-col cols="12" :lg="3">
                  <div class="d-flex flex-column ga-1 mb-5">
                    <label for="name">Select Role Permissions </label>
                  </div>
                </v-col>
              </v-row>
              <v-table>
                <thead>
                  <tr>
                    <th class="text-left">Module</th>
                    <th class="text-left">Show</th>
                    <th class="text-left">Create</th>
                    <th class="text-left">Edit</th>
                    <th class="text-left">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="module in modules" :key="module.name">
                    <td>
                      <span class="d-flex align-center ga-2">
                        <img
                          :src="module.image"
                          width="32"
                          height="32"
                          :alt="module.name"
                        />
                        {{ module.name }}</span
                      >
                    </td>
                    <td>
                      <v-checkbox
                        :value="
                          module.prefix.find((p) => p.prefix === 'read')?.id
                        "
                        v-model="permissions"
                        color="#237276"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        :value="
                          module.prefix.find((p) => p.prefix === 'create')?.id
                        "
                        v-model="permissions"
                        color="#237276"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        :value="
                          module.prefix.find((p) => p.prefix === 'update')?.id
                        "
                        v-model="permissions"
                        color="#237276"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        :value="
                          module.prefix.find((p) => p.prefix === 'delete')?.id
                        "
                        v-model="permissions"
                        color="#237276"
                      ></v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <div class="d-flex justify-end w-100 mt-5">
                <button class="choose-btn" @click="CreateRole()">Save</button>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import "@/assets/css/team/style.css";
import apiClient from "@/core/buildRequest";
import Input from "@/components/crm/assets/Input.vue";
import { useGeneralStore } from "@/store/General";

export default {
  components: {
    GlobalNav,
    SideBar,
    BreadCrumb,
    Input,
  },
  data() {
    return {
      linksData: [
        { name: "Home", link: "/" },
        { name: "My Team", link: "" },
      ],
      role: {},
      modules: [],
      permissions: [],
      role_name: "",
      general_store: useGeneralStore(),
      errors: [],
    };
  },
  mounted() {
    this.getModules();
  },

  methods: {
    getModules() {
      apiClient
        .get(`/my-team/list-modules`)
        .then((response) => {
          this.modules = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validate() {
      this.errors = [];
      if (!this.role_name) {
        this.errors.push({
          key: "role_name",
          message: "Role name is required",
        });
      }
      if (this.errors.length > 0) {
        this.general_store.showSnackbar(
          "Please fill all required fields",
          "error"
        );
        return false;
      }
      return true;
    },
    CreateRole() {
      if (!this.validate()) return;
      apiClient
        .post(`/my-team/add-new-role`, {
          name: this.role_name,
          permission_id: this.permissions,
        })
        .then((response) => {
          this.general_store.showSnackbar(
            "Role Created Successfully",
            "success"
          );
          this.role_name = "";
          this.permissions = [];
        })
        .catch((error) => {
          this.general_store.showSnackbar("Failed to create role", "error");
        });
    },
  },
};
</script>
