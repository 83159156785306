<template>
  <div class="boost-post-card boost-audience">
    <div class="card-header mb-4">
      <label for="" class="title d-block">Audience</label>
      <p class="sub-title">Who should see your ad?</p>
    </div>
    <div class="card-body">
      <div
        v-for="(item, i) in store.audienceType"
        :key="i"
        class="d-flex align-center mb-3 ga-2 goals-select"
      >
        <div class="d-flex align-start w-100 justify-space-between goal-item">
          <label :for="`${item.id}_audience`" class="d-block">
            <p
              class="mb-1 title"
              style="font-size: 18px; font-weight: 600; color: #0d0d0d"
            >
              {{ item.title }}
            </p>
            <p class="sub-title">{{ item.description }}</p>
          </label>
          <div class="my-form">
            <div>
              <input
                :checked="item.checked"
                :id="`${item.id}_audience`"
                type="radio"
                name="gooalType"
                :value="item.id"
                v-model="selectedAudience"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="selectedAudience === store.audienceType[0].id">
        <div
          v-for="item in store.advantageAudience"
          :key="item.id"
          class="audience-card-details"
        >
          <div class="card-details-header mb-2">
            <p>Audience Details</p>
            <span>
              <img :src="editIcon" alt="" />
            </span>
          </div>
          <div class="card-details-body">
            <p class="title">
              Location: <span>{{ item.loacation }}</span>
            </p>
            <p class="title">
              Minimum age: <span>{{ item.age }}</span>
            </p>
            <p class="title">Advantage+ audience: <span>On</span></p>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="selectedAudience === store.audienceType[1].id">
        <div
          v-for="item in store.throwTargeting"
          :key="item.id"
          class="audience-card-details"
        >
          <div class="card-details-header mb-2">
            <p>Audience Details</p>
            <span>
              <img :src="editIcon" alt="" />
            </span>
          </div>
          <div class="card-details-body">
            <p class="title">
              Location: <span>{{ item.loacation }}</span>
            </p>
            <p class="title">
              Optimize location: <span>{{ item.optimiseLocation }}</span>
            </p>
            <p class="title">
              Minimum age: <span>{{ item.age }}</span>
            </p>
            <p class="title">
              Advantage detailed targeting:
              <span>{{ item.advantagedetailedTagrting }}</span>
            </p>
            <p class="title">
              Advantage+ audience:
              <span> {{ item.advancedAudience }} </span>
            </p>
          </div>
        </div>
      </div>
      <button @click="store.CreateAudience = true" class="create-new">
        Create new
      </button>
      <CreateAudience />
    </div>
  </div>
</template>

<script>
import { boostPost } from "@/store/publishPost/boostPost";
import editIcon from "@/assets/images/edite_w.svg";
import CreateAudience from "./CreateAudience.vue";
export default {
  components: {
    CreateAudience,
  },
  data() {
    return {
      store: boostPost(),
      editIcon,
      selectedAudience: null,
    };
  },
  computed: {
    selectedAudienceTitle() {
      const selectedItem = this.store.audienceType.find(
        (item) => item.id === this.selectedAudience
      );
      return selectedItem ? selectedItem.title : null;
    },
  },
};
</script>
