<template>
  <div class="table-wrapper">
    <div class="table-container">
      <table v-if="store.loading" class="w-100 skeleton-header">
        <thead>
          <tr>
            <th v-for="header in store?.getHeaders" :key="header.value">
              <v-skeleton-loader type="text" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in 10" :key="i">
            <td v-for="header in store?.getHeaders" :key="header.value">
              <v-skeleton-loader type="text" />
            </td>
          </tr>
        </tbody>
      </table>

      <v-data-table
        v-else
        :headers="store?.getHeaders"
        :items="store?.getDrive"
        ref="table"
        :hide-default-footer="true"
        class="data-table-no-border"
      >
        <template v-slot:item.total="{ item }">
          <div
            :class="[
              'd-flex align-center ga-2',
              item.type === 'folder' ? 'cursor-pointer' : '',
            ]"
          >
            <img :src="item.iconLink" width="26" height="26" alt="" />
            <span
              :class="[
                'd-flex flex-column ga-2',
                item.type === 'folder' ? 'cursor-pointer' : '',
              ]"
              @click="
                () =>
                  item.type === 'folder'
                    ? $router.push({
                        name: 'AllFolderFiles',
                        params: { folderId: item.id },
                      })
                    : ''
              "
            >
              <Name
                :idChanged="idChanged"
                :file="item"
                @close:rename="idChanged = null"
              />
              <span class="file-size">{{ item?.size }}</span>
            </span>
          </div>
        </template>
        <template v-slot:item.by="{ item }">
          <div class="d-flex align-center ga-2">
            {{ item.added_by }}
          </div>
        </template>
        <template v-slot:item.for_task="{ item }">
          <div class="d-flex align-center ga-2">
            {{ item.task_name }}
          </div>
        </template>
        <template v-slot:item.assignees="{ item }">
          <div
            v-if="item?.file_type != 'folder'"
            class="d-flex align-center ga-2 assignees-container"
          >
            <img
              v-for="assignee in item?.assignes && item?.assignes.length > 0
                ? item?.assignes.slice(0, 3)
                : []"
              :key="assignee.id"
              :src="assignee.image"
              class="circle-img"
              :alt="assignee.name"
              :title="assignee.name"
              srcset=""
            />
            <svg
              @click="toggleEditFunction(item.file_id)"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer"
            >
              <path
                d="M7.33203 11.333H8.66536V8.66634H11.332V7.33301H8.66536V4.66634H7.33203V7.33301H4.66536V8.66634H7.33203V11.333ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4941 5.3987 14.1497C4.58759 13.7941 3.88203 13.3163 3.28203 12.7163C2.68203 12.1163 2.20425 11.4108 1.8487 10.5997C1.50425 9.78856 1.33203 8.9219 1.33203 7.99967C1.33203 7.07745 1.50425 6.21078 1.8487 5.39967C2.20425 4.58856 2.68203 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.21078 5.3987 1.86634C6.20981 1.51079 7.07648 1.33301 7.9987 1.33301C8.92092 1.33301 9.78759 1.51079 10.5987 1.86634C11.4098 2.21078 12.1154 2.68301 12.7154 3.28301C13.3154 3.88301 13.7876 4.58856 14.132 5.39967C14.4876 6.21078 14.6654 7.07745 14.6654 7.99967C14.6654 8.9219 14.4876 9.78856 14.132 10.5997C13.7876 11.4108 13.3154 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7941 10.5987 14.1497C9.78759 14.4941 8.92092 14.6663 7.9987 14.6663ZM7.9987 13.333C9.48759 13.333 10.7487 12.8163 11.782 11.783C12.8154 10.7497 13.332 9.48856 13.332 7.99967C13.332 6.51079 12.8154 5.24967 11.782 4.21634C10.7487 3.18301 9.48759 2.66634 7.9987 2.66634C6.50981 2.66634 5.2487 3.18301 4.21536 4.21634C3.18203 5.24967 2.66536 6.51079 2.66536 7.99967C2.66536 9.48856 3.18203 10.7497 4.21536 11.783C5.2487 12.8163 6.50981 13.333 7.9987 13.333Z"
                fill="#237276"
              />
            </svg>
          </div>
          <div class="d-flex align-center" v-else>-</div>
        </template>
        <template v-slot:item.options="{ item }">
          <Actions
            :file="item"
            :store="store"
            @rename="toggleRenameFunction"
            @edit:file="toggleEditFunction"
          />
        </template>
      </v-data-table>
    </div>
  </div>
  <Pagination v-if="store?.getDrive.length > 1" :store="store" />
</template>

<script>
import { DriveStore } from "@/store/drive/DriveStore";
import Pagination from "@/components/crm/assets/Pagination.vue";
import Name from "@/components/drive/table/Name.vue";
import Actions from "@/components/drive/table/Actions.vue";

export default {
  name: "CustomDataTable",
  props: {},
  components: {
    Pagination,
    Name,
    Actions,
  },

  data() {
    return {
      store: null,
      idChanged: null,
    };
  },
  watch: {},
  methods: {
    toggleRenameFunction(id) {
      this.idChanged = id;
    },
    formatDate(date) {
      return format(new Date(date), "MMM dd, yyyy HH:mm");
    },
    toggleEditFunction(v) {
      this.$emit("edit:file", v);
    },
  },
  created() {
    this.store = DriveStore();
  },
};
</script>

<style>
.table-container .v-table {
  margin: 0px !important;
}

.table-container .v-table .v-data-table-footer {
  justify-content: space-between !important;
}

.table-container .v-table .v-table__wrapper table thead {
  background: #edf1f1;
  color: #535862;
}

.table-container .v-table .v-table__wrapper table tbody tr:hover {
  background: #adadad27;
}

.table-container .v-table .v-table__wrapper table tbody tr td {
  padding: 16px 24px;
  white-space: nowrap;
}

.table-container thead tr th:hover {
  background-color: #adadad27 !important;
}

.table-container thead tr th div .options .menu {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.table-container thead tr th div .options .menu {
  display: none;
}
.table-container thead tr th:hover > div .options .menu {
  display: flex;
}

.data-table-no-border
  .v-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td,
.data-table-no-border
  .v-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th {
  border: none !important;
}
.data-table-no-border > .v-table__wrapper > table > tbody > tr > th,
.data-table-no-border > .v-table__wrapper > table > thead > tr > th,
.data-table-no-border > .v-table__wrapper > table > tfoot > tr > th {
  border: none !important;
}
</style>

<style scoped>
.table-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
}

.table-container {
  width: 100%;
  position: relative;
  overflow-x: auto;
}

.v-data-table {
  width: 100%;
  margin: 1rem 0;
}

:deep(.v-data-table__wrapper) {
  overflow-x: auto;
  width: 100%;
}

:deep(.v-data-table > .v-data-table__wrapper > table) {
  width: 100%;
  white-space: nowrap;
}

:deep(.v-data-table th) {
  white-space: nowrap;
  min-width: 120px;
  padding: 16px 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.phone-link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #666666;
  text-decoration: none;
}

.phone-link:hover {
  text-decoration: underline;
}

:deep(.v-data-table) {
  text-transform: capitalize;
}

:deep(.v-data-table__checkbox) {
  width: 48px;
  padding-left: 16px !important;
}

.menu-options-list .v-list-item .v-list-item__content .v-list-item-title {
  width: 150px;
  height: 36px;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  margin: 6px 0px;
}

.menu-options-list .v-list-item .v-list-item__content .v-list-item-title:hover {
  background: #c0c0c0;
  color: white;
}
.file-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #333333;
}
.file-size {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.94px;
  color: #4d4d4d;
}
</style>
