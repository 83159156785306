<template>
  <div class="profile-box-container w-100">
    <div class="d-flex justify-space-between align-items-center w-100 mb-3">
      <label for="" class="profile-box-title"> {{ $t('contact_info') }}.</label>
    </div>
    <div class="d-flex flex-column py-5">
      <div class="profile-box-second-text mb-3"> {{ $t('email') }} </div>
      <Input v-model="email" type="email" :placeholder="$t('email')" />
      <span
        class="text-red-accent-4 text-caption small-text mt-2"
        v-if="errors.email"
        >{{ errors.email }}</span
      >
    </div>
    <div class="d-flex flex-column py-5">
      <div class="profile-box-second-text mb-3"> {{ $t('phone_number') }} </div>
      <Input v-model="phone" type="number" :placeholder="$t('phone_number')" />
      <span
        class="text-red-accent-4 text-caption small-text mt-2"
        v-if="errors.mobile"
        >{{ errors.mobile }}</span
      >
    </div>
  </div>
</template>
<script>
import Input from "@/components/crm/assets/Input.vue";

export default {
  name: "Addresses",
  components: { Input },
  props: ["errors", "profile"],

  data() {
    return {
      email: "",
      phone: "",
    };
  },
  beforeCreate() {},
  watch: {
    errors(val) {
      console.log(val);
    },
    email(val) {
      this.$emit("change:email", val);
    },
    phone(val) {
      this.$emit("change:phone", val);
    },
    profile() {
      this.email = this.profile?.email;
      this.phone = this.profile?.mobile;
    },
  },
};
</script>
