<template>
  <div class="sidebar-tiktok" style="position: fixed;width: 17%;top: 7.5%;" >
    <ul class="sidebar-tiktok-list">
      <li>
         <router-link to="/tiktok">{{ store.campaignName }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import "@/assets/css/tiktok/index.css";
import { mapState } from "pinia";
import {tiktokData} from "@/store/tiktokStore/tiktokData.js";
export default {
  data() {
    return {
      store: tiktokData(),
    };
  },
  computed: {
    
  },
};
</script>
