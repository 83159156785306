<template>
  <v-container fluid style="padding: 0; min-height: 100vh">
    <v-row no-gutters>
      <v-col cols="12">
        <GlobalNav></GlobalNav>
      </v-col>
      <v-col cols="12">
        <div class="d-flex h-100">
          <SideBar></SideBar>
          <div class="pa-5 w-100">
            <BreadCrumb :linksData="linksData" class="mb-5" />
            <div
              class="leads-managements-link d-flex align-center ga-2 my-3 py-3"
            >
              <div
                class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
              >
                <span>{{ $t("my_drive") }}</span>
              </div>
            </div>
            <div class="mt-5 mb-5">
              <div class="">
                <Filter
                  :view_type="view_type"
                  @update:view_type="view_type = $event"
                  @open:NewImage="new_image_dialog = true"
                  @open:NewVideo="new_video_dialog = true"
                  @open:NewFolder="new_folder_dialog = true"
                />
              </div>
              <Table v-if="view_type == 'list'" @edit:file="editFile" />
              <Box
                @open:NewFolder="new_folder_dialog = true"
                @edit:file="editFile"
                v-if="view_type == 'box'"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
  <NewFolder
    :dialog="new_folder_dialog"
    @update:dialog="new_folder_dialog = $event"
  />
  <NewImage
    :dialog="new_image_dialog"
    @update:dialog="new_image_dialog = $event"
  />
  <NewVideo
    :dialog="new_video_dialog"
    @update:dialog="new_video_dialog = $event"
  />
  <EditFile :dialog="edit_file_dialog" :fileId="edit_file_id" />
</template>
<script>
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import { DriveStore } from "@/store/drive/DriveStore.js";
import Table from "@/components/drive/Table.vue";
import Box from "@/components/drive/Box.vue";
import Filter from "@/components/drive/Filter.vue";
import "@/assets/css/drive/style.css";
import NewVideo from "@/components/drive/forms/NewVideo.vue";
import NewImage from "@/components/drive/forms/NewImage.vue";
import NewFolder from "@/components/drive/forms/NewFolder.vue";

import EditFile from "@/components/drive/forms/EditFile.vue";

export default {
  name: "DriveIndex",
  components: {
    BreadCrumb,
    GlobalNav,
    SideBar,
    Table,
    Filter,
    Box,
    NewVideo,
    NewImage,
    NewFolder,
    EditFile,
  },
  data() {
    return {
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "My Drive", link: "" },
      ],
      store: DriveStore(),
      view_type: "list",
      new_image_dialog: false,
      new_video_dialog: false,
      new_folder_dialog: false,
      edit_file_dialog: false,
      edit_file_id: null,
    };
  },
  mounted() {
    let access = localStorage.getItem("drive_token") ? true : false;
    if (access) {
      this.store?.getAllDrive();
    } else {
      this.$router.push({ name: "integrations" });
    }
  },
  methods: {
    editFile(file) {
      this.edit_file_id = file;
      this.edit_file_dialog = !this.edit_file_dialog;
    },
  },
};
</script>
