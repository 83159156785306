import TikTokIndex from "@/views/campains/tiktok/TikTokIndex.vue";
import TiktokSecoundStep from "@/views/campains/tiktok/TiktokSecoundStep.vue";
import TiktokThirdStep from "@/views/campains/tiktok/TiktokThirdStep.vue";
import CreatTiktokCampaign from "@/views/campains/tiktok/CreatTiktokCampaign.vue";
import getTiktokToken from "@/views/campains/tiktok/getTiktokToken.vue";
import SelectYourAcount from "@/views/campains/tiktok/SelectYourAcount.vue";
import CreateApp from "@/views/campains/tiktok/CreateApp.vue";

const tiktokRoutes = [
  {
    path: "/tiktok",
    name: "TikTok",
    component: TikTokIndex,
    meta: {
      title: "tiktok",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
  {
    path: "/create/adgroup",
    name: "TikTokSecoundStep",
    component: TiktokSecoundStep,
    meta: {
      title: "tiktok",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
  {
    path: "/create/creative",
    name: "TiktokThirdStep",
    component: TiktokThirdStep,
    meta: {
      title: "tiktok",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
  {
    path: "/create-campaign/tiktok",
    name: "CreatTiktokCampaign",
    component: CreatTiktokCampaign,
    meta: {
      title: "tiktok",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
  {
    path: "/tiktokToken",
    name: "TiktokToken",
    component: getTiktokToken,
    meta: {
      title: "tiktok",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
  {
    path: "/tiktok/select-your-acount",
    name: "SelectYourAcount",
    component: SelectYourAcount,
    meta: {
      title: "tiktok",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
  {
    path: "/tiktok/create-app",
    name: "CreateApp",
    component: CreateApp,
    meta: {
      title: "tiktok",
      description: "",
      
      permissions: [
        "campaign-ads.create",
        "campaign-ads.read",
        "campaign-ads.update",
        "campaign-ads.delete",
      ],
    },
  },
];

export default tiktokRoutes;
