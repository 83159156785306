<template>
  <v-container
    fluid
    style="margin-top: 0%; padding: 0% 0% 0 0; min-height: calc(100vh - 58px)"
  >
    <v-row style="height: 100%; margin: 0; padding: 0; position: relative">
      <v-col cols="12" class="pa-0">
        <GlobalNav />
      </v-col>
      <v-col
        cols="12"
        style="height: 100%"
        class="pa-0 d-flex align-start ga-6"
      >
        <SideBar />
        <div class="w-100 pa-3 is-light-mode" style="height: 87vh">
          <BreadCrumb :linksData="linksData" class="mb-5" />
          <TaskForm />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import TaskForm from "@/components/calender/edit/TaskForm.vue";
export default {
  name: "ShowTask",
  components: {
    GlobalNav,
    SideBar,
    BreadCrumb,
    TaskForm,
  },
  data() {
    return {
      linksData: [
        { name: "Home", link: "/" },
        { name: "Your Calender", link: "/calender" },
        { name: "Edit Task", link: "" },
      ],
    };
  },
  
};
</script>
