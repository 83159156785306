<template>
  <div class="card-pattern awareness-card">
    <div class="card-header mb-4">
      <label for="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Awareness</span>
      </label>
    </div>
    <div class="card-body">
      <label class="d-block roboto-black" for="">Performance goal</label>
      <v-select
        v-model="performanceGoalSelected"
        :items="performanceGoal"
        density="compact"
        variant="outlined"
        return-object
        item-value="value"
        item-title="title"
        hide-details
        :menu-props="{ maxWidth: '596px', minWidth: '100%' }"
      >
        <template v-slot:selection="{ item }">
          {{ item?.title || "" }}
        </template>
        <template v-slot:item="{ item, index }">
          <div v-if="index === 0">
            <p style="font-weight: bold; margin-bottom: 8px">Awareness goals</p>
          </div>
          <div v-if="index === 3">
            <p style="font-weight: bold; margin-top: 16px">Video view goals</p>
          </div>
          <v-list-item @click="selectItemWinner(item)">
            <div class="my-form">
              <div class="ga-3">
                <input
                  :checked="
                    performanceGoalSelected &&
                    performanceGoalSelected.id === item.raw.id
                  "
                  :id="item.raw.id"
                  type="radio"
                  name="option"
                  :value="item"
                />
                <div class="d-flex flex-column ga-1">
                  <label
                    :for="item.raw.id"
                    class="d-block"
                    style="font-size: 14px"
                    >{{ item.raw.title }}</label
                  >
                  <label
                    :for="item.raw.id"
                    style="font-size: 12px; color: #1c2b33"
                  >
                    {{ item.raw.descriptions }}
                  </label>
                </div>
              </div>
            </div>
          </v-list-item>
        </template>
      </v-select>
      <p class="roboto-regular descriptions my-4">
        To help us improve delivery, we may survey a small section of your
        audience.
      </p>
      <div class="facebook-page mb-3">
        <label for="" class="roboto-black d-block">Facebook Page</label>
        <v-select
          v-model="facebookSelectedId"
          :items="facebookPages"
          density="compact"
          variant="outlined"
          return-object
          item-value="id"
          item-title="title"
          hide-details
        >
          <template v-slot:selection="{ item }">
            <img
              style="
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin-right: 10px;
              "
              :src="item.raw?.imageUrl"
              alt=""
            />
            <div class="d-flex flex-column">
              <span style="font-size: 12px" class="roboto-black d-block">{{
                item.raw?.name || ""
              }}</span>
            </div>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item @click="selectedfacebookPage(item)">
              <div class="my-form">
                <div class="ga-3">
                  <input
                    :checked="
                      facebookSelectedId &&
                      facebookSelectedId.id === item.raw.id
                    "
                    :id="item.raw.id"
                    type="radio"
                    name="selectedPageIdAwarance"
                    :value="item"
                  />
                  <div class="d-flex align-center ga-1">
                    <img
                      style="
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        margin-right: 10px;
                      "
                      :src="item.raw.imageUrl"
                      alt=""
                    />
                    <label
                      :for="item.raw.id"
                      class="d-block"
                      style="font-size: 14px"
                    >
                      <span class="roboto-black d-block">{{
                        item.raw.name
                      }}</span>
                      <span class="roboto-regular d-block">
                        ID: {{ item.raw.id }}</span
                      >
                    </label>
                  </div>
                </div>
              </div>
            </v-list-item>
          </template>
        </v-select>
      </div>
      <div class="cost-per-result mb-3">
        <label for="" class="roboto-black d-block mb-2"
          >Cost per result goal - <span class="roboto-regular">optional</span>
        </label>
        <div class="d-flex input-container align-center mb-1">
          <input type="text" placeholder="$X.XX" name="" id="" />
          <span>USD</span>
        </div>
        <p class="roboto-regular text-description">
          Meta will aim to spend your entire budget and get the most ThruPlays
          using the highest-volume bid strategy. If keeping the average cost per
          result around a certain amount is important, enter a cost per result
          goal.
        </p>
      </div>
      <FrequencyControl />
      <v-divider class="my-4"></v-divider>
      <button class="show-more" @click="toggleShowMoreOptions">
        <div class="d-flex align-center" v-if="!chargedOptions">
          <span>Show more options</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#0a7abf"
          >
            <path d="M480-384 288-576h384L480-384Z" />
          </svg>
        </div>
        <div class="d-flex align-center" v-else>
          <span>Hide options</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#0a7abf"
          >
            <path d="m288-384 192-192 192 192H288Z" />
          </svg>
        </div>
      </button>
      <div class="show-more-options-container" v-if="chargedOptions">
        <section class="mb-4">
          <label for="" class="d-block roboto-black"
            >When you are charged
          </label>
          <v-select
            v-model="chargedSelectedItem"
            :items="chargedItems"
            density="compact"
            variant="outlined"
            return-object
            item-value="id"
            item-title="title"
            hide-details
          >
            <template v-slot:selection="{ item }">
              {{ item?.title || "" }}
            </template>
            <template v-slot:item="{ item }">
              <v-list-item @click="setCahrgedItem(item)">
                <div class="my-form">
                  <div class="ga-3">
                    <input
                      :checked="
                        chargedSelectedItem &&
                        chargedSelectedItem.id === item.raw.id
                      "
                      :id="item.raw.id"
                      type="radio"
                      name="option"
                      :value="item"
                    />
                    <div class="d-flex flex-column ga-1">
                      <label
                        :for="item.raw.id"
                        class="d-block"
                        style="font-size: 14px"
                        >{{ item.title }}</label
                      >
                    </div>
                  </div>
                </div>
              </v-list-item>
            </template>
          </v-select>
        </section>
        <section class="delivery-type-container">
          <div class="delivery-type">
            <div
              class="delivery-type-header d-flex align-center justify-space-between"
            >
              <label class="mb-1 roboto-black d-block" for=""
                >Delivery type</label
              >
              <button disabled>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20px"
                  viewBox="0 -960 960 960"
                  width="20px"
                  fill="#000"
                >
                  <path
                    d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
                  />
                </svg>
                <span>Edit</span>
              </button>
            </div>
            <p>Standard</p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/core/buildRequest";
import FrequencyControl from "@/components/meta/secound-page-componet/awareness/FrequencyControl.vue";
export default {
  components: { FrequencyControl },
  data() {
    return {
      performanceGoalSelected: {
        id: 0,
        title: "Maximise reach of ads",
        descriptions:
          "We'll try to show your ads to as many people as possible.",
        value: "REACH",
      },
      performanceGoal: [
        {
          id: 0,
          title: "Maximise reach of ads",
          descriptions:
            "We'll try to show your ads to as many people as possible.",
          value: "REACH",
        },
        {
          id: 1,
          title: "Maximise number of impressions",
          descriptions:
            "We'll try to show your ads to people as many times as possible.",
          value: "IMPRESSIONS",
        },
        {
          id: 2,
          title: "Maximise ad recall lift",
          descriptions:
            "We'll try to show your ads to people who are likely to remember seeing them.",
          value: "AD_RECALL_LIFT",
        },
        {
          id: 3,
          title: "Maximise ThruPlay views",
          descriptions:
            "We'll try to show your video ads to people who will watch the entire video when it’s shorter than 15 seconds. For longer videos, we’ll try to show it to people who are likely to watch at least 15 seconds.",
          value: "THRUPLAY",
        },
        {
          id: 4,
          title: "Maximise 2-second continuous video plays",
          descriptions:
            "We'll try to show your video ads to people  who are likely to watch 2 continuous seconds or more. Most 2-second continuous video plays will have at least 50% of the video pixels on screen.",
          value: "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS",
        },
      ],
      selectedRegions: [],
      chargedOptions: false,
      chargedSelectedItem: {
        id: 0,
        title: "Impressions",
        value: "IMPRESSIONS",
      },
      chargedItems: [
        {
          id: 0,
          title: "Impressions",
          value: "IMPRESSIONS",
        },
        {
          id: 1,
          title: "ThruPlay",
          value: "THRUPLAY",
        },
      ],
      facebookPages: [],
      facebookSelectedId: "",
    };
  },
  methods: {
    selectItemWinner(item) {
      this.performanceGoalSelected = item.raw;
      localStorage.setItem("meta_optimization_goal", item.raw.value);
    },
    toggleShowMoreOptions() {
      this.chargedOptions = !this.chargedOptions;
    },
    setCahrgedItem(item) {
      this.chargedSelectedItem = item.raw;
      localStorage.setItem("charged_item", item.raw.value);
    },
    selectedfacebookPage(item) {
      
      this.facebookSelectedId = item.raw;
      localStorage.setItem("facebook_page_id", item.raw.id);
      localStorage.setItem("facebook_page_data", JSON.stringify(item));
    },
  },
  mounted() {
    apiClient.get("/publish-posts/all/pages").then((response) => {
      let data = response.data.data.data;

      this.facebookPages = data.map((item) => ({
        id: item.id,
        name: item.name,
        imageUrl: item.picture.data.url,
        category: item.category,
        width: item.picture.data.width,
        height: item.picture.data.height,
      }));
      this.facebookSelectedId = this.facebookPages[0];
    });
  },
};
</script>

<style scoped>
.option-image {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
}

.selected-option .option-image {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
}
</style>
