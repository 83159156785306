<template>
  <div class="boost-post-card">
    <div class="card-header mb-4">
      <label for="" class="title d-block"> {{ $t("advantage_creative") }}  </label>
    </div>
    <div class="card-body d-flex align-center justify-space-between">
      <p class="title mb-1 d-block">
        {{ $t("advantage_creative_description") }}
      </p>
      <v-switch
        @change="setAdvantageCreative"
        v-model="model"
        color="info"
        hide-details
        inset
      ></v-switch>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      model: true,
    };
  },
  methods: {
    setAdvantageCreative() {
      localStorage.setItem("setAdvantageCreative", this.model);
    },
  },
};
</script>
