<template>
  <div class="task-activity">
    <div class="activity-title text-center w-100">Activity</div>
    <!-- <div class="activites d-flex flex-column gap-3 mt-5">
      <div
        v-for="(activity, i) in activityStore.activities"
        :key="i"
        class="d-flex justify-space-between align-center ga-3 single"
      >
        <div class="activity-name d-flex align-center ga-2">
          <span class="activity-dot"></span>
          <span class="name">{{ activity.name }}</span>
        </div>
        <span class="time">{{ activity.time }}</span>
      </div>
    </div> -->
    <TaskComments />
  </div>
</template>

<script>
import { useActivityStore } from "@/store/activityStore";
import TaskComments from "./TaskComments.vue";

export default {
  name: "TaskActivity",
  components: {
    TaskComments,
  },
  data() {
    return {
      activityStore: useActivityStore(),
    };
  },
};
</script>

<style scoped>
.activity-title {
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.activity-dot {
  width: 8px;
  height: 8px;
  background-color: #237276;
  border-radius: 50%;
}

.single {
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}

.time {
  color: #666;
  font-size: 0.9rem;
}

.name {
  color: #333;
  font-size: 0.95rem;
}
</style>
