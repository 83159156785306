<template>
  <div class="pa-4 dashboard-card" elevation="2">
    <div class="d-flex justify-space-between">
      <div class="d-flex flex-column ga-2">
        <span class="text-subtitle-1 font-weight-bold"
          >Agents assigned to Leads</span
        >
        <span v-if="date_range.length" class="d-flex align-center date-range"
          >{{ store?.formatDateFromInput(date_range[0]) }} -
          {{
            store?.formatDateFromInput(date_range[date_range.length - 1])
          }}</span
        >
      </div>
      <div class="d-flex align-center ga-2"> 
        <span class="vuetify-date-input-icon cursor-pointer">
          <v-date-input
            hide-details
            variant="plain"
            prepend-icon=""
            prepend-inner-icon="$calendar"
            density="compact"
            multiple="range"
            clearable
            format="YYYY-MM-DD HH:mm:ss"
            v-model="date_range"
          >
            <template v-slot:text-field="{ value }">
              <v-text-field
                :model-value="formatDate(value)"
                readonly
                variant="plain"
              ></v-text-field>
            </template>
          </v-date-input>
        </span>
        <v-select
          v-model="tempId"
          :items="agentsFilterItems"
          @update:model-value="Filter"
          item-title="name"
          item-value="id"
          density=""
        >
          <template #append-item>
            <div
              @click="openCustomDialog"
              class="d-flex ga-2 pa-2 cursor-pointer"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.8268 8.00033C14.8268 11.7743 11.7674 14.8337 7.99349 14.8337C4.21954 14.8337 1.16016 11.7743 1.16016 8.00033C1.16016 4.22638 4.21954 1.16699 7.99349 1.16699C11.7674 1.16699 14.8268 4.22638 14.8268 8.00033Z"
                  stroke="#237276"
                  stroke-opacity="0.3"
                />
                <path
                  d="M11.3665 7.33329H8.66646V4.62663C8.66646 4.44982 8.59622 4.28025 8.4712 4.15522C8.34617 4.0302 8.1766 3.95996 7.99979 3.95996C7.82298 3.95996 7.65341 4.0302 7.52839 4.15522C7.40336 4.28025 7.33313 4.44982 7.33313 4.62663V7.33329H4.61979C4.44298 7.33329 4.27341 7.40353 4.14839 7.52856C4.02336 7.65358 3.95312 7.82315 3.95312 7.99996C3.95312 8.17677 4.02336 8.34634 4.14839 8.47136C4.27341 8.59639 4.44298 8.66663 4.61979 8.66663H7.33313V11.3733C7.33313 11.5501 7.40336 11.7197 7.52839 11.8447C7.65341 11.9697 7.82298 12.04 7.99979 12.04C8.1766 12.04 8.34617 11.9697 8.4712 11.8447C8.59622 11.7197 8.66646 11.5501 8.66646 11.3733V8.66663H11.3731C11.5499 8.66663 11.7195 8.59639 11.8445 8.47136C11.9696 8.34634 12.0398 8.17677 12.0398 7.99996C12.0398 7.82315 11.9696 7.65358 11.8445 7.52856C11.7195 7.40353 11.5499 7.33329 11.3731 7.33329H11.3665Z"
                  fill="#237276"
                />
              </svg>
              <span>Filter by group</span>
            </div>
          </template>
        </v-select>
        <v-dialog v-model="dialog" width="auto">
          <v-card
            max-width="700"
            class="dashboard-card agents-select"
            width="650"
            title="Add Group Of Agents"
          >
            <template v-slot:text>
              <div class="field d-flex flex-column ga-2">
                <input
                  type="text"
                  placeholder="Template Name"
                  v-model="template_name"
                />
              </div>
              <v-select
                v-model="selected_agents"
                class="mt-4"
                label=""
                :items="all_agents"
                item-value="id"
                :item-title="getFullName"
                variant="solo"
                multiple
                clearable
                style="width: 100%"
                filterable
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props">
                    <template #prepend>
                      <div class="d-flex align-center ga-2">
                        <img
                          :src="item.raw.image"
                          :alt="`${item.raw.first_name} avatar`"
                          class="agent-avatar ma-2"
                          style="width: 24px; height: 24px; border-radius: 50%"
                        />
                      </div>
                    </template>
                    <!-- <v-list-item-title>
                      {{ item.raw.first_name }} {{ item.raw.last_name }}
                    </v-list-item-title> -->
                  </v-list-item>
                </template>

                <!-- Selected items -->
                <template v-slot:selection="{ item }">
                  <div class="d-flex align-center ga-2">
                    <img
                      :src="item.raw.image"
                      :alt="`${item.raw.first_name} avatar`"
                      class="agent-avatar"
                      style="
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 8px;
                      "
                    />
                  </div>
                </template>
              </v-select>
            </template>
            <template v-slot:actions>
              <div class="d-flex ga-2 align-center">
                <span class="cancel-btn cursor-pointer" @click="dialog = false"
                  >Cancel</span
                >
                <span class="choose-btn cursor-pointer" @click="addGroup"
                  >Save</span
                >
              </div>
            </template>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div style="min-height: 575px">
      <Bar
        :data="agents_bar"
        :options="{ responsive: true, maintainAspectRatio: false }"
      />
    </div>
  </div>
</template>
<script>
import { Bar } from "vue-chartjs";
import apiClient from "@/core/buildRequest";
import { VDateInput } from "vuetify/labs/VDateInput";
import { useLeadsStore } from "@/store/crm/useLeadsStore";
export default {
  name: "AgentsAssignedToLeads",
  components: { Bar, VDateInput },
  props: {
    data: Array,
  },
  data() {
    return {
      tempId: null,
      agentsFilterItems: [],
      dialog: false,
      agents_bar: {
        labels: ["a"],
        datasets: [
          {
            label: "",
            backgroundColor: "#2E989E",
            data: [45],
          },
        ],
      },
      all_agents: [],
      selected_agents: [],
      template_name: "",
      store: useLeadsStore(),
      date_range: [],
    };
  },
  watch: {
    dialog(val) {
      if (val && this.all_agents.length === 0) {
        this.getAllAgents();
      }
    },
    tempId() {
      this.getData();
    },
  },
  methods: {
    getFullName(item) {
      return `${item.first_name} ${item.last_name}`;
    },
    Filter() {
      this.getData();
    },

    openCustomDialog() {
      this.dialog = true;
    },

    async getData() {
      try {
        await apiClient
          .get("/leads-managment/get/assign-agents", {
            params: {
              id: this.tempId,
            },
          })
          .then((response) => {

            this.agents_bar = {
              labels: response.data.data?.map((el) => el.full_name),
              datasets: [
                {
                  label: "",
                  backgroundColor: "#2E989E",
                  data: response.data.data?.map((el) => el.total),
                },
              ],
            };
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getAllAgents() {
      try {
        await apiClient.get("/leads-managment/all-agents").then((response) => {
          this.all_agents = response.data.data;
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getTemplates() {
      try {
        await apiClient
          .get("/leads-managment/get-templates")
          .then((response) => {
            this.agentsFilterItems = response.data.data;
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async addGroup() {
      try {
        await apiClient
          .post("/leads-managment/create-template", {
            name: this.template_name,
            user_id: this.selected_agents,
          })
          .then(() => {
            this.getTemplates();
            this.selected_agents = [];
            this.template_name = "";
            this.dialog = false;
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
  mounted() {
    this.getData();
    this.getTemplates();
  },
};
</script>
